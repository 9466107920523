import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {ThemeConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/theme_configuration.entity";

@injectable()
export class GetThemeByUniqueCodeCompanyUseCase {
    private _repository: CompanyRepository;

    constructor(@inject(CompanyRepository) repository: CompanyRepository) {
        this._repository = repository;
    }

    public call(uniqueCode: number): Promise<ThemeConfigurationEntity | null> {
        return this._repository.getThemeByUniqueCode(uniqueCode);
    }
}

