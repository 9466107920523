import {CompanyConfigurationEntity} from "../company_configurations/company_configuration.entity";
import {CompanyOrigin, CompanyRegion, CompanySegment} from "@dropDesk/domain/entities/company/company_enum";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {ClientJuridicalNature} from "@dropDesk/domain/entities/client/client_enum";
import {isValidCPF} from "@dropDesk/utils/helpers/validators";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";


export interface CompanyCreateAccount {
    company: CompanyEntity,
    needLogin: boolean
}

export class CompanyEntity {
    id!: string;
    name!: string;
    document!: string;
    relationshipDate?: string;
    region?: CompanyRegion;
    segment?: CompanySegment;
    origin!: CompanyOrigin;
    uniqueCode!: number;
    configurations!: CompanyConfigurationEntity;
    contract!: boolean;
    extraInfo?: CompanyExtraInfoEntity;
    paymentMethod?: PaymentMethodEntity;
    paymentSubscriptions!: PaymentSubscriptionEntity[];
    createdAt?: string;

    public get newSubs(): PaymentSubscriptionEntity {
        const paymentSubscriptionByCreatedAt = this.getEligibleSubscriptionsByCreatedAt;
        return paymentSubscriptionByCreatedAt.length > 1 ? paymentSubscriptionByCreatedAt[paymentSubscriptionByCreatedAt.length - 1] : paymentSubscriptionByCreatedAt[0];
    }

    public get getEligibleSubscriptionsByCreatedAt(): PaymentSubscriptionEntity[] {
        return this.orderByCreatedAt(this.paymentSubscriptions.filter(entry => !entry.deleted));
    }

    public get oldSubs(): PaymentSubscriptionEntity | undefined {
        const paymentSubscriptionByCreatedAt = this.getEligibleSubscriptionsByCreatedAt;
        return paymentSubscriptionByCreatedAt.length > 1 ? paymentSubscriptionByCreatedAt[0] : undefined;
    }

    public orderByCreatedAt(subscriptions: PaymentSubscriptionEntity[]): PaymentSubscriptionEntity[] {
        return subscriptions.sort((a, b) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime());
    }

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    static fromJson(json: Record<string, any>): CompanyEntity {
        return new CompanyEntity({
            id: json['id'],
            name: json['name'],
            document: json['document'] ?? '',
            relationshipDate: json['relationshipDate'],
            region: json['region'],
            segment: json['segment'],
            origin: json['origin'],
            uniqueCode: json['uniqueCode'],
            createdAt: json['createdAt'],
            configurations: CompanyConfigurationEntity.fromJson(json['configurations']),
            extraInfo: json['extraInfo'] ? CompanyExtraInfoEntity.fromJson(json['extraInfo']) : undefined,
            paymentMethod: json['paymentMethod'] ? PaymentMethodEntity.fromJson(json['paymentMethod']) : undefined,
            paymentSubscriptions:
                json['paymentSubscriptions']
                    ? (json['paymentSubscriptions'] as any[]).map((entry) =>
                        PaymentSubscriptionEntity.fromJson(entry),
                    )
                    : [],
            contract: json['contract'] ?? false,
        });
    }

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = ['user', 'sector', 'contract', 'paymentMethod', 'extraInfo', 'configurations', 'paymentSubscriptions'];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    public toUpdateLegacy(newPaymentMethod: PaymentMethodEntity, newExtraInfo: CompanyExtraInfoEntity): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            document: this.document,
            extraInfo: newExtraInfo,
            paymentMethod: newPaymentMethod
        }
    }

    public toCreateAccount(): Record<string, unknown> {
        return {
            name: this.name,
            document: this.document,
            segment: this.segment,
            origin: this.origin,
        }
    }

    constructor({
                    id,
                    name,
                    document,
                    relationshipDate,
                    region,
                    segment,
                    origin,
                    uniqueCode,
                    configurations,
                    contract,
                    extraInfo,
                    paymentMethod,
                    createdAt,
                    paymentSubscriptions
                }: {
        id: string;
        name: string;
        document: string;
        relationshipDate?: string;
        region?: CompanyRegion;
        segment?: CompanySegment;
        origin: CompanyOrigin;
        uniqueCode: number;
        configurations: CompanyConfigurationEntity;
        contract: boolean;
        extraInfo?: CompanyExtraInfoEntity;
        paymentMethod?: PaymentMethodEntity;
        createdAt?: string;
        paymentSubscriptions: PaymentSubscriptionEntity[];

    }) {
        Object.assign(this, {
            id,
            name,
            document,
            relationshipDate,
            region,
            segment,
            origin,
            uniqueCode,
            configurations,
            contract,
            extraInfo,
            paymentMethod,
            createdAt,
            paymentSubscriptions
        });
    }

    private getUniqueList(originalList: PaymentEntity[], newList: PaymentEntity[]): PaymentEntity[] {
        const result = originalList;

        if (newList.length) {
            for (const payment of newList) {
                const index = originalList.findIndex((item) => item.id === payment.id);
                if (index > -1) {
                    result[index] = payment;
                } else {
                    result.push(payment);
                }
            }
        }

        return result;
    }

    copyWith({
                 id,
                 name,
                 document,
                 relationshipDate,
                 region,
                 segment,
                 origin,
                 uniqueCode,
                 configurations,
                 contract,
                 extraInfo,
                 paymentMethod,
                 createdAt,
                 paymentSubscriptions
             }: {
        id?: string;
        name?: string;
        document?: string;
        relationshipDate?: string;
        region?: CompanyRegion;
        segment?: CompanySegment;
        origin?: CompanyOrigin;
        uniqueCode?: number;
        configurations?: CompanyConfigurationEntity;
        contract?: boolean;
        extraInfo?: CompanyExtraInfoEntity;
        paymentMethod?: PaymentMethodEntity;
        createdAt?: string;
        paymentSubscriptions?: PaymentSubscriptionEntity[];
    }): CompanyEntity {


        const newPaymentSubscriptions: PaymentSubscriptionEntity[] = this.paymentSubscriptions;
        if (paymentSubscriptions && paymentSubscriptions.length) {
            for (const paymentSubscriptionEntity of paymentSubscriptions) {
                const index = newPaymentSubscriptions.findIndex(entry => entry.id === paymentSubscriptionEntity.id);
                if (index > -1) {
                    newPaymentSubscriptions[index] = newPaymentSubscriptions[index].copyWith({
                        ...paymentSubscriptionEntity,
                        payments: this.getUniqueList(newPaymentSubscriptions[index].payments ?? [], paymentSubscriptionEntity.payments ?? [])
                    });
                } else {
                    newPaymentSubscriptions.push(paymentSubscriptionEntity);
                }
            }
        }

        return new CompanyEntity({
            id: id ?? this.id,
            name: name ?? this.name,
            document: document ?? this.document,
            relationshipDate: relationshipDate ?? this.relationshipDate,
            region: region ?? this.region,
            segment: segment ?? this.segment,
            origin: origin ?? this.origin,
            uniqueCode: uniqueCode ?? this.uniqueCode,
            configurations: configurations ?? this.configurations,
            contract: contract ?? this.contract,
            extraInfo: extraInfo ?? this.extraInfo,
            paymentMethod: paymentMethod ?? this.paymentMethod,
            createdAt: createdAt ?? this.createdAt,
            paymentSubscriptions: newPaymentSubscriptions,
        })
    }

}

export class CompanyExtraInfoEntity {
    paymentInfo?: CompanyPaymentInfo;

    constructor({paymentInfo}: {
        paymentInfo: CompanyPaymentInfo
    }) {
        Object.assign(this, {
            paymentInfo
        });
    }

    public static fromJson(json: Record<string, any>): CompanyExtraInfoEntity {
        return new CompanyExtraInfoEntity({
            paymentInfo: CompanyPaymentInfo.fromJson(json['paymentInfo'])
        });
    }

    copyWith({
                 paymentInfo,
             }: {
        paymentInfo: CompanyPaymentInfo

    }): CompanyExtraInfoEntity {
        return new CompanyExtraInfoEntity({
            paymentInfo: paymentInfo ?? this.paymentInfo,
        })
    }
}

export class CompanyPaymentInfo {
    billingAddress!: AddressEntity;
    billingInfoHolder!: BillingInfoHolder

    constructor({
                    billingAddress,
                    billingInfoHolder
                }: {
        billingAddress: AddressEntity,
        billingInfoHolder: BillingInfoHolder
    }) {
        Object.assign(this, {
            billingAddress,
            billingInfoHolder
        });
    }

    public static fromJson(json: Record<string, any>): CompanyPaymentInfo {
        return new CompanyPaymentInfo({
            billingAddress: AddressEntity.fromJson(json['billingAddress']),
            billingInfoHolder: BillingInfoHolder.fromJson(json['billingInfoHolder'])
        });
    }

    copyWith({
                 billingAddress,
                 billingInfoHolder
             }: {
        billingAddress?: AddressEntity,
        billingInfoHolder?: BillingInfoHolder

    }): CompanyPaymentInfo {
        return new CompanyPaymentInfo({
            billingAddress: billingAddress ?? this.billingAddress,
            billingInfoHolder: billingInfoHolder ?? this.billingInfoHolder,
        })
    }
}

export class BillingInfoHolder {
    juridicalNature!: ClientJuridicalNature;
    name!: string;
    document!: string;
    email!: string;
    phone!: string;
    receiveInvoices?: boolean;

    constructor({
                    juridicalNature,
                    name,
                    document,
                    email,
                    phone,
                    receiveInvoices
                }: {
        juridicalNature: ClientJuridicalNature;
        name: string;
        document: string;
        email: string;
        phone: string;
        receiveInvoices?: boolean;

    }) {
        Object.assign(this, {
            juridicalNature,
            name,
            document,
            email,
            phone,
            receiveInvoices
        });
    }

    public static fromJson(json: Record<string, any>): BillingInfoHolder {
        return new BillingInfoHolder({
            juridicalNature: json['document'] ? isValidCPF(removeMask(json['document'])) ? ClientJuridicalNature.physical : ClientJuridicalNature.juridical : ClientJuridicalNature.juridical,
            name: json['name'],
            email: json['email'],
            document: json['document'],
            phone: json['phone'],
            receiveInvoices: json['receiveInvoices']
        });
    }

    copyWith({
                 juridicalNature,
                 name,
                 document,
                 email,
                 phone,
                 receiveInvoices
             }: {
        juridicalNature?: ClientJuridicalNature;
        name?: string;
        document?: string;
        email?: string;
        phone?: string;
        receiveInvoices?: boolean;

    }): BillingInfoHolder {
        return new BillingInfoHolder({
            juridicalNature: juridicalNature ?? this.juridicalNature,
            name: name ?? this.name,
            document: document ?? this.document,
            email: email ?? this.email,
            phone: phone ?? this.phone,
            receiveInvoices: receiveInvoices ?? this.receiveInvoices,
        })
    }
}
