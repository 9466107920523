import {
    Button,
    ContentButton,
} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const SendButton = observer((
    {
        onClickSend
    }: {
        onClickSend: () => void
    }) => {

    return (
        <ContentButton>
            <Button
                onClick={() => {
                    onClickSend();
                }}
            >
                <DropDeskIcon
                    icon={ICONS_DROPDESK.sendFill}
                    size={20}
                    style={{cursor: 'pointer'}}
                />
            </Button>
        </ContentButton>
    )
});
export default SendButton;
