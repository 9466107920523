import ApolloLinkTimeout from 'apollo-link-timeout';
import {ApolloClient, createHttpLink, DefaultOptions, InMemoryCache, split} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {getMainDefinition} from "@apollo/client/utilities";
import {getToken} from "@dropDesk/data/data_source/token/token.datasource";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {createClient} from 'graphql-ws';

const timeoutLink = new ApolloLinkTimeout(60000);
const httpLink = createHttpLink({
    uri: process.env.HASURA_API as string
});
const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

const getConnectionParams = async () => {
    const token = await getToken();
    return {
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
            Accept: 'charset=utf-8',
        }
    }
}

const wsLinkNew = new GraphQLWsLink(createClient({
    url: (process.env.HASURA_API as string).replace('https', 'wss'),
    lazy: true,
    shouldRetry: () => true,
    connectionParams: async () => {
        return getConnectionParams();
    },
    on: {
        connected: () => {
            console.log("CONNECTED", displayDateToLocale(new Date().toISOString()));
        },
        closed: () => {
            console.log("CLOSED", displayDateToLocale(new Date().toISOString()));
        },
        error: (e) => {
            console.log('Error', e, displayDateToLocale(new Date().toISOString()));
        },
    },
}));

const timeoutHttpLink = timeoutLink.concat(httpLink);

export const apolloClientWebSocketClose = () => {
    wsLinkNew.client.terminate();
}

const authLink = setContext(async (_, {headers}) => {
    const token = await getToken();
    return {
        headers: {
            ...headers,
            Accept: 'charset=utf-8',
            Authorization: token ? `Bearer ${token}` : null
        }
    }
});

export const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLinkNew,
    authLink.concat(timeoutHttpLink),
);

const mergeTickets = (existing: any, incoming: any[]) => {
    const merged = existing ? [...existing] : [];

    incoming.forEach(incomingTicket => {
        const existingIndex = merged.findIndex(existingTicket => existingTicket.id === incomingTicket.id);

        if (existingIndex === -1) {
            merged.push(incomingTicket);
        } else {
            merged[existingIndex] = incomingTicket;
        }
    });

    return merged;
};

export const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        addTypename: false,
        typePolicies: {
            Subscription: {
                fields: {
                    tickets: {
                        merge(existing, incoming) {
                            return mergeTickets(existing, incoming);
                        }
                    },
                },
            },
        },
    }),
    defaultOptions
});
