import {observer} from "mobx-react";
import React from "react";
import {
    ContentHeaderImportClient,
    TextDescriptionImport,
    TextFileExample
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const Header = observer(
    ({
         downloadFileExampleImportClient
     }: {

        downloadFileExampleImportClient: () => void,
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <ContentHeaderImportClient>
                <TextDescriptionImport color={colors.text}>
                    Aqui você pode importar suas empresas/contatos usando um arquivo Excel(xlsx).
                    Baixe o arquivo de exemplo e preencha os dados. Certifique-se
                    que a linha do cabeçalho seja identica ao
                    <TextFileExample
                        color={colors.text}
                        onClick={() => {
                            downloadFileExampleImportClient();
                        }}>
                        Arquivo de Exemplo
                    </TextFileExample> para que não haja algum erro.
                </TextDescriptionImport>
            </ContentHeaderImportClient>
        )
    })
export default Header;
