import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";

export class NoticesEntity {
    id!: string;
    idCompany!: string;
    idUser?: string | null;
    idClient?: string | null;
    user?: UserEntity | null;
    client?: ClientEntity | null;
    description!: string;
    dateStarted?: string | null;
    dateEnd?: string | null;
    hasTemporary?: boolean;
    deleted!: boolean;
    destiny!: NoticeDestiny;
    createdAt?: string;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = ['user', 'client'];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    constructor({
                    id,
                    idCompany,
                    user,
                    client,
                    idUser,
                    idClient,
                    description,
                    dateStarted,
                    dateEnd,
                    hasTemporary,
                    deleted,
                    destiny,
                    createdAt
                }: {
        id: string;
        idCompany: string;
        idUser?: string | null;
        idClient?: string | null;
        user?: UserEntity | null;
        client?: ClientEntity | null;
        description: string;
        dateStarted?: string | null;
        dateEnd?: string | null;
        hasTemporary?: boolean;
        deleted: boolean;
        destiny: NoticeDestiny;
        createdAt?: string;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            user,
            client,
            idUser,
            idClient,
            description,
            dateStarted,
            dateEnd,
            hasTemporary,
            deleted,
            destiny,
            createdAt
        });
    }

    copyWith({
                 id,
                 idCompany,
                 user,
                 client,
                 idUser,
                 idClient,
                 description,
                 dateStarted,
                 dateEnd,
                 hasTemporary,
                 deleted,
                 replaceDateStartedIfNull,
                 replaceDateEndIfNull,
                 replaceUserIfNull,
                 replaceClientIfNull,
                 replaceIdUserIfNull,
                 replaceIdClientIfNull,
                 destiny,
                 createdAt
             }: {
        id?: string;
        idCompany?: string;
        user?: UserEntity | null;
        client?: ClientEntity | null;
        replaceUserIfNull?: boolean | null;
        replaceClientIfNull?: boolean | null;
        idUser?: string | null;
        idClient?: string | null;
        replaceIdUserIfNull?: boolean;
        replaceIdClientIfNull?: boolean;
        description?: string;
        dateStarted?: string | null;
        replaceDateStartedIfNull?: boolean | null;
        dateEnd?: string | null;
        replaceDateEndIfNull?: boolean;
        hasTemporary?: boolean;
        deleted?: boolean;
        destiny?: NoticeDestiny;
        createdAt?: string;
    }): NoticesEntity {
        return new NoticesEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            idUser: replaceIdUserIfNull ? idUser : this.idUser,
            idClient: replaceIdClientIfNull ? idClient : this.idClient,
            user: replaceUserIfNull ? user : this.user,
            client: replaceClientIfNull ? client : this.client,
            description: description ?? this.description,
            dateStarted: replaceDateStartedIfNull ? dateStarted : this.dateStarted,
            dateEnd: replaceDateEndIfNull ? dateEnd : this.dateEnd,
            hasTemporary: hasTemporary ?? this.hasTemporary,
            deleted: deleted ?? this.deleted,
            destiny: destiny ?? this.destiny,
            createdAt: createdAt ?? this.createdAt,
        })
    }
}
