import {Menu} from 'antd';
import styled, {css} from "styled-components";
import {Layout} from 'antd';

const {Sider} = Layout;

type MenuType = {
    background: string;
    color: string;
    backgroundonprimary: string;
    secondary: string;
}

type SiderType = {
    background: string
    border: string
}

type ContainerType = {
    background: string;
}

type TextType = {
    color: string;
    hovercolor: string;
}


type SubMenuTextType = {
    color: string;
    hovercolor: string;
    background: string;
}

export const Container = styled.div`
`;

export const SubMenuTitle = styled.span<SubMenuTextType>`
  font-size: 14px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  padding-left: 10px;

  &:hover {
    color: ${(props) => props.hovercolor};
  }
`;

export const MenuStyled = styled(Menu)<MenuType>`
  position: relative !important;
  background: ${(props) => props.background} !important;

  .ant-menu-item-selected {
    background: ${(props) => props.backgroundonprimary} !important;
    color: ${(props) => props.secondary} !important;
  }

  &.ant-menu-dark .ant-menu-submenu-title:hover {
    background: ${(props) => props.backgroundonprimary} !important;
    color: ${(props) => props.secondary} !important;
  }

  &.ant-menu-dark .ant-menu-submenu-selected {
    background: ${(props) => props.backgroundonprimary} !important;
    color: ${(props) => props.secondary} !important;
  }

  &.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid ${(props) => props.secondary} !important;
  }

  .ant-menu-item-icon + span {
    margin-left: 20px !important;
    font-weight: 400;
  }

  .ant-menu-item {
    line-height: 45px;
    height: 45px;
    min-height: 45px;
    color: ${(props) => props.color};
    font-size: 16px;
    margin-top: 0px !important;

  }

  .ant-menu-item:hover {
    background: ${(props) => props.backgroundonprimary} !important;
    color: ${(props) => props.secondary} !important;
  }

  &.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 45px;
    height: 45px;
    min-height: 45px;
    font-size: 16px;
    margin: 0 0 4px !important;
    color: ${(props) => props.color};
  }

  &.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 45px;
    height: 45px;
    min-height: 45px;
    font-size: 16px;
    margin: 0 0 4px !important;
  }

  &.ant-menu-vertical > .ant-menu-submenu-selected {
    line-height: 45px;
    height: 45px;
    min-height: 45px;
    font-size: 16px;
    margin: 0 0 4px !important;
    color: ${(props) => props.secondary} !important;
    background: ${(props) => props.backgroundonprimary} !important;
  }

  &.ant-menu-vertical .ant-menu-submenu {
    color: ${(props) => props.color};
  }

  &.ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin: 0 0 4px !important;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    font-size: 16px;
    color: ${(props) => props.color};
  }

  .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
    color: ${(props) => props.color};
  }

  .ant-menu-sub.ant-menu-inline {
    background: ${(props) => props.background} !important;
  }

  .ant-menu-item:active, .ant-menu-submenu-title:active {
    font-size: 16px;
    background: ${(props) => props.backgroundonprimary} !important;
    color: ${(props) => props.secondary} !important;

  }

  &.ant-menu-light .ant-menu-submenu-title:hover {
    background: ${(props) => props.backgroundonprimary} !important;
    color: ${(props) => props.secondary} !important;
  }

  .ant-menu-inline .ant-menu-item::before {
    content: "";
    display: inline-block;
    width: 4px;
    min-width: 4px;
    height: 4px;
    border: 2px solid ${(props) => props.color};
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
    box-shadow: 1px 0px 0px ${(props) => props.color}, 0px -1px 0px ${(props) => props.color}, 0px 1px 0px ${(props) => props.color}, -1px 0px 0px ${(props) => props.color};
  }


  &.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 24px !important;
  }

  &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 24px !important;
  }

`;


export const SiderStyled = styled(Sider)<SiderType>`
  &.ant-layout-sider {
    background: ${(props) => props.background} !important;
    border-bottom-right-radius: 40px !important;
    border-top-right-radius: 40px !important;
    border: 1px solid ${props => props.border};
  }

  position: relative !important;
`;


export const ContentLogoBrand = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: 61px;
  min-height: 61px;
  align-items: center;
  justify-content: flex-start;
  background: ${(props) => props.background};
  border-top-right-radius: 40px;
`;

