import styled from "styled-components";
import {CloseIconType, TextType} from "@dropDesk/presentation/components/headers/header_form/head_form";


export const Container = styled.div`
  padding: 8px 16px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Text = styled.span<TextType>`
  font-size: 18px;
  color: ${(props) => props.color};
  letter-spacing: .8px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

