import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerStatus = {
    background: string;
}

type TextType = {
    color: string;
}

type ButtonType = {
    color: string;
    background: string;
    accent: string;
    size: number;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px;
`;

export const ContainerValue = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ContentValue = styled.div<TextType>`
  display: flex;
  flex-direction: row;
  font-size: 30px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const ContentStatus = styled.div<ContainerStatus>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  color: ${(props) => props.color};
  padding: 0px 6px;
  border-radius: 5px;
  height: 16px;
  margin-left: 10px;
  font-weight: bold;
  letter-spacing: .5px;
  font-size: 10px;
`;

export const ContainerDescription = styled.div<TextType>`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 15px;
  padding: 8px 0;
  color: ${(props) => props.color};
`;

export const Button = styled.button<ButtonType>`
  display: flex;
  flex-direction: column;
  padding: 6px;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
  font-weight: bold;
  width: 110px;
  min-width: 110px;
  height: 80px;
  min-height: 80px;
  border-radius: 5px;
  border: 1px solid ${props => props.accent};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 0;
`;
