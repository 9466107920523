import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {TicketConfig} from "@dropDesk/domain/entities/ticket/ticket_config";
import {CancelTokenSource} from "axios";

@injectable()
export abstract class TicketRepository {

    public abstract set(ticket: TicketEntity): Promise<TicketEntity>;

    public abstract findByPK(id: string, listMessages: boolean, cancelTokenSource?: CancelTokenSource): Promise<TicketEntity>;

    public abstract listTicketsSubscription(
        searchParam: string,
        page: number,
        limit: number,
        listOnlyDeleted: boolean,
        status: string | null,
        priority: string | null,
        listOnlySectorEmpty: boolean
    ): Observable<ListPaginationEntity<TicketEntity>>;


    public abstract restore(tickets: TicketEntity[]): Promise<void>;

    public abstract delete(tickets: TicketEntity[]): Promise<void>;

    public abstract transfer(idTicket: string, newSectorId: string, newAttendantId?: string): Promise<TicketEntity>;

    public abstract changeTimeSpent(idTicket: string, timeSpent: number): Promise<TicketEntity>;

    public abstract changePriority(idTicket: string, priority: string): Promise<TicketEntity>;

    public abstract changeDateDue(idTicket: string, dateDue: string): Promise<TicketEntity>;

    public abstract changeProblemDescription(idTicket: string, description: string): Promise<TicketEntity>;

    public abstract cancel(idTicket: string, cancelDescription?: string): Promise<TicketEntity>;

    public abstract close(idTicket: string, closeDescription?: string): Promise<TicketEntity>;

    public abstract changeResolution(idTicket: string, closeDescription: string): Promise<TicketEntity>;

    public abstract linkAttendant(idTicket: string): Promise<TicketEntity>;

    public abstract exportSubscription(): Observable<ExportDataEntity>;

    public abstract export(period: { startDate: string, endDate: string }): Promise<ExportDataEntity>;

    public abstract exportPdf(idTicket: string): Promise<void>;

    public abstract getConfigs(): Promise<TicketConfig[]>;

}
