import {injectable} from "inversify";
import {Observable} from "rxjs";
import {FileProgress} from "@dropDesk/domain/entities/common/file_progress";
import {AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";

@injectable()
export abstract class IoRepository {
    public abstract createObjectURLBlob(file: File | Blob): string;

    public abstract urlToBlob(url: string): Promise<Blob>;

    public abstract downloadFile(url: string, fileName: string): Observable<FileProgress>;

    public abstract startRecordVoiceListener(audioRecorder: AudioRecordEntity, stream: MediaStream): Observable<AudioRecordEntity>;

    public abstract getMediaStream(): Promise<MediaStream>;

    public abstract getAudioFromMediaRecorder(audioRecorder: AudioRecordEntity): Promise<AudioRecordEntity>;

    public abstract stopRecord(mediaRecorder?: MediaRecorder, stream?: MediaStream): void;
}
