import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TaskRepository} from "@dropDesk/domain/repositories/task/task.repository";
import {TaskRemoteDataSource} from "@dropDesk/data/data_source/task/task_remote.datasource";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {Actions, Orders, Status, Type} from "@dropDesk/domain/entities/task/task_enum";

@injectable()
export class TaskRepositoryImpl implements TaskRepository {
  private _dataSource: TaskRemoteDataSource;

  constructor(@inject(TaskRemoteDataSource) dataSource: TaskRemoteDataSource) {
    this._dataSource = dataSource;
  }

  public list(
    type: Type, order: Orders, status: Status, page: number, limit: number
  ): Promise<ListPaginationEntity<TaskEntity>> {
    try {
      return this._dataSource.list(type, order, status, page, limit);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }


  public set(task: TaskEntity): Promise<TaskEntity> {
    try {
      return this._dataSource.set(task);
    } catch (e: any) {
      throw new ServerError(e);
    }
  }

  public markAsDoneAndUndone(id: string, action: Actions): Promise<Record<string, unknown>> {
    try {
      return this._dataSource.markAsDoneAndUndone(id, action);
    } catch (e: any) {
      throw new ServerError(e);
    }
  }

  public delete(id: string): Promise<void> {
    try {
      return this._dataSource.delete(id);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }
}
