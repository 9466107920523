export const hexToRGBA = (hex: string, opacity: number): string => {
  return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))?.map(function (l) {
    return parseInt(hex.length % 2 ? l + l : l, 16)
  }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
}

export const shadeOrLighten = (colorHex: string, amount: number): string => {
  return '#' + colorHex.replace(/^#/, '').replace(/../g, color =>
    ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}
