import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContainerHeader, Content, TextBold,
} from "./styled";
import React from "react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const Header = (
    {
        backPreviousScreenExternalScreen,
    }: {
        backPreviousScreenExternalScreen?: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerHeader>
            {backPreviousScreenExternalScreen && <Content onClick={() => {
                backPreviousScreenExternalScreen();
            }}>
                <DropDeskIcon
                    icon={ICONS_DROPDESK.arrowLeft}
                    size={23}
                    style={{cursor: 'pointer', marginRight: 5}}
                />
                <TextBold size={16} color={colors.text}>Forma de pagamento</TextBold>
            </Content>}
            {!backPreviousScreenExternalScreen &&
                <TextBold size={16} color={colors.text}>Forma de pagamento</TextBold>}
        </ContainerHeader>
    );
}
export default Header;
