import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type TextType = {
    color: string;
}
export const Container = styled.div`
  display: flex;
  background-color: ${SharedColors.info};
  width: 100%;
  z-index: 99;
  padding: 4px 16px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-size: 14px;
  color: ${SharedColors.black};
`;

export const TextWeight = styled.span`
  font-weight: bold;
`;
export const TextLink = styled.button<TextType>`
  color: ${props => props.color};
  text-decoration: underline;
  border: 0;
  background: transparent;

  &:hover {
    filter: brightness(80%);
  }
`;
