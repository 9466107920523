import {
    ContainerIsDragActive, Content,
    TextDragFile,
    TextMaxSize
} from "@dropDesk/presentation/components/chat_view/chat_message/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const IsDragActive = () => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerIsDragActive background={colors.delicateBackground}>
            <Content bordercolor={colors.accent}>
                <TextDragFile color={colors.text}>
                    Solte Arquivos para Anexar <DropDeskIcon icon={ICONS_DROPDESK.upload} size={21}/>
                </TextDragFile>
                <TextMaxSize color={colors.text}>Tamanho Máximo de 16MB e limite total de arquivos
                    20.</TextMaxSize>
            </Content>
        </ContainerIsDragActive>
    )
}
export default IsDragActive;
