import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    CheckBox,
    ContentCheckBox,
    ContentText,
    Text,
} from "../styled";
import React from "react";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const PaymentInfos = (
    {
        paymentInfo,
    }: {
        paymentInfo: CompanyPaymentInfo,
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (

        <>
            <ContentText>
                <Text size={15}
                      color={colors.text}>{paymentInfo.billingInfoHolder.name},&nbsp;{paymentInfo.billingInfoHolder.document}.</Text>
            </ContentText>

            <ContentText>
                <Text size={15} color={colors.text}>
                    {paymentInfo.billingAddress.street},&nbsp;
                    {paymentInfo.billingAddress.number},&nbsp;
                    {paymentInfo.billingAddress.complement ? `${paymentInfo.billingAddress.complement}, ` : ''}
                    {paymentInfo.billingAddress.city},&nbsp;
                    {paymentInfo.billingAddress.state},&nbsp;
                    {paymentInfo.billingAddress.zipCode}.
                </Text>
            </ContentText>

            <ContentText>
                <ContentCheckBox>
                    {!paymentInfo.billingInfoHolder.receiveInvoices &&
                        <CheckBox color={colors.text}/>}
                    {paymentInfo.billingInfoHolder.receiveInvoices &&
                        <DropDeskIcon icon={ICONS_DROPDESK.select} size={15}/>}
                    <Text size={15} color={colors.text}>
                        Desejo receber nota fiscal
                    </Text>
                </ContentCheckBox>
            </ContentText>
        </>
    );
}
export default PaymentInfos;
