import {Map} from "@dropDesk/domain/entities/ticket/ticket_maps";

export const inactivityMap: Map<number>[] = [
    {value: 30, label: "Entre 30/35 minutos"},
    {value: 45, label: "Entre 45/50 minutos"},
    {value: 60, label: "Entre 60/65 minutos"},
];

export const inactivityTransferSectorMap: Map<number>[] = [
    {value: 15, label: "Entre 15/20 minutos"},
    {value: 20, label: "Entre 20/25 minutos"},
    {value: 30, label: "Entre 30/35 minutos"},
];
