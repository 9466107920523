import styled from 'styled-components';

type TextType = {
    color: string;
}
export const Container = styled.div`
  display: flex;
`;

export const ContentRecording = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TextTimer = styled.span<TextType>`
  color: ${props => props.color};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 3px;
`;

export const ContentTextTimer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
`;
export const ContentAudio = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;



