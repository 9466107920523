import styled from "styled-components";

type ContainerType = {
    background: string;
};

export const ContentSelectAndCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
`;

export const ContentInputAndFilter = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PaddingFilters = styled.div`
  display: flex;
  padding: 0 32px;
  align-items: center;
  justify-content: center;
`;

export const ContentExportImport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;
export const ContentFilter = styled.div<ContainerType>`
  display: flex;
  background: ${(props) => props.background};
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1px;
`;
