import React, {useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Input} from "@dropDesk/presentation/components/pin_field/styled";

const PinItem = React.forwardRef(
    ({
         maxLength,
         handleChange,
         handleBackSpace,
         disabled,
     }: {
         maxLength: number,
         handleChange: (value: string) => void
         handleBackSpace: (value: string) => void
         disabled: boolean
     }, ref: React.LegacyRef<HTMLInputElement>
    ) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
            switch (event.code) {
                case "Backspace": {
                    handleBackSpace && handleBackSpace((event.target as HTMLInputElement).value);
                    break;
                }
                case "ShiftLeft":
                case "ShiftRight":
                case "Tab":
                case "ArrowRight":
                case "ArrowUp":
                case "ArrowLeft":
                case "ArrowDown":
                    break;
                default: {
                    handleChange((event.target as HTMLInputElement).value);
                }
            }
        };

        return (
            <div>
                <Input
                    ref={ref as any}
                    maxLength={maxLength}
                    onKeyUp={(event) => handleKeyUp(event)}
                    disabled={disabled}
                    border={colors.border}
                    background={colors.onBackground}
                    color={colors.text}
                    height={45}
                    width={45}
                    accent={colors.accent}
                />
            </div>
        );
    }
);

export default PinItem;
