import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type TextType = {
    color: string;
    size: number;
}

export const ContainerQRCode = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px 16px;
`;

export const ContentQRCode = styled.div`
  background: ${SharedColors.white};
  display: flex;
  border-radius: 5px;
`;


export const ContentImage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 50px;
  margin-left: 10px;
`;

export const ContainerLabelAndPix = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => `${props.size}px`};
  color: ${props => props.color};
`;

