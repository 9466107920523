import {observer} from "mobx-react";
import React from "react";
import ModalRestore from "@dropDesk/presentation/components/modals/modal_information";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {useModalUsers} from "@dropDesk/presentation/pages/user/ui/search/modals/visible_modal_search";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import ModalExport from "@dropDesk/presentation/components/modals/modal_information";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useNavigate} from "react-router-dom";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsUsers = observer(
    ({
         restoreUser,
         deleteUser,
         removeLineSelectRow,
         loading
     }: {
        restoreUser: () => Promise<void>,
        deleteUser: () => Promise<void>,
        removeLineSelectRow: () => void,
        loading: boolean
    }) => {

        const {
            visibleModalRestore,
            setVisibleModalRestore,
            visibleModalDelete,
            setVisibleModalDelete,
            visibleModalExport, setVisibleModalExport

        } = useBetween(useModalUsers);

        const navigate = useNavigate();

        return (
            <>
                <ModalRestore
                    open={visibleModalRestore}
                    onRequestClose={() => {
                        setVisibleModalRestore(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Restaurar Atendente'}
                    textButton={'Restaurar'}
                    onRequestSave={async () => {
                        restoreUser();
                        setVisibleModalRestore(false);
                    }}
                    nameIcon={ICONS_DROPDESK.restore}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a restauração?  Após a restauração, o atendente volta a ter acesso ao sistema.`
                    }
                    loading={loading}
                />
                <ModalDelete
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        setVisibleModalDelete(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Excluir Atendente'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        deleteUser();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={120}
                    textInformation={
                        `A exclusão deste atendente, não apaga seus atendimentos, avaliações e nenhuma outra ação já feita por ele,
         apenas o atendente é excluído não sendo mais listado e sem acesso ao sistema.`
                    }
                    loading={loading}
                    isActionDelete={true}
                />

                <ModalExport
                    open={visibleModalExport}
                    onRequestClose={() => {
                        setVisibleModalExport(false);
                    }}
                    textHeaderModal={'Exportar atendentes'}
                    textButton={'Exportar'}
                    onRequestSave={async () => {
                        navigate(`${RoutesEnum.exportusers}`);
                        setVisibleModalExport(false);
                    }}
                    nameIcon={ICONS_DROPDESK.excel}
                    sizeIcon={120}
                    textInformation={
                        `Deseja realmente exportar todos os dados dos atendentes para uma planilha?`
                    }
                    loading={loading}
                />

            </>
        )
    })
export default ModalsUsers;
