import styled from "styled-components";
import {ButtonType, ContainerType} from "@dropDesk/presentation/components/modals/modal_time_spent/modal_time_spent";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


export const Container = styled.div<ContainerType>`
  display: flex;
  width: 600px;
  overflow: hidden;
  flex-direction: column;
  border-radius: 7px;
  background: ${(props) => props.background};
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
`;
export const ContentItem = styled.button<ButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  height: 40px;
  min-height: 40px;
  font-size: 15px;
  border-radius: 5px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border: ${(props) => `1px solid ${props.border}`};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
