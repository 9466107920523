import React from "react";
import {Content} from "./styled";
import {HighLightHashTag} from "@dropDesk/presentation/components/high_light_hashtag";
import {observer} from "mobx-react";


const WarningMessage = observer((
    {
        messageText,
        color
    }: {
        messageText: string
        color: string
    }
) => {


    return (
        <Content>
            <HighLightHashTag text={messageText} fontSize={15} color={color}/>
        </Content>
    )
});
export default WarningMessage;
