import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, Content, ContentDescriptions, ContentTextAndIcon, Title, Text} from "./styled";
import React from "react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const Advantage = (
    {
        title,
        background
    }: {
        title?: string
        background?: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <Title size={17} color={colors.text}>{title ?? 'Benefícios do Plano:'}</Title>
            <Content background={background ?? colors.onBackground}>
                <ContentDescriptions>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Acesso via web e
                            aplicativo</Text>
                    </ContentTextAndIcon>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Integração com
                            WhatsApp</Text>
                    </ContentTextAndIcon>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Atendimentos
                            ilimitados</Text>
                    </ContentTextAndIcon>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Empresas e contatos
                            ilimitados</Text>
                    </ContentTextAndIcon>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Sem contrato de
                            fidelidade</Text>
                    </ContentTextAndIcon>


                </ContentDescriptions>
                <ContentDescriptions style={{marginLeft: 20}}>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Suporte técnico via
                            WhatsApp</Text>
                    </ContentTextAndIcon>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Relatórios
                            completos</Text>
                    </ContentTextAndIcon>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Setorização de
                            atendimentos</Text>
                    </ContentTextAndIcon>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Upload de arquivos até
                            16MB</Text>
                    </ContentTextAndIcon>
                    <ContentTextAndIcon>
                        <DropDeskIcon style={{marginTop: 5, marginRight: 5}} icon={ICONS_DROPDESK.tick} size={12}
                                      color={colors.success}/>
                        <Text color={colors.text} size={15}>Sem multa por
                            cancelamento</Text>
                    </ContentTextAndIcon>

                </ContentDescriptions>
            </Content>
        </Container>
    );
}
export default Advantage;
