import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";

@injectable()
export class ListChatMessagesAfterLastSubsUseCase {
  private _repository: ChatRepository;

  constructor(@inject(ChatRepository) repository: ChatRepository) {
    this._repository = repository;
  }

  public call(chatId: string): Promise<TicketMessageEntity[]> {
    return this._repository.listChatMessagesAfterSubscription(chatId);
  }
}

