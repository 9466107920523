import {observer} from "mobx-react";
import logoDropDesk from "@dropDesk/storage/images/logo_dropdesk_portrait.png";
import React from "react";

export const LogoMenu = observer((
    {
        collapsed,
        logoBrandUrl
    }: {
        collapsed: boolean;
        logoBrandUrl: string | null
    }
) => {

    return (
        <img
            src={logoBrandUrl ?? logoDropDesk}
            alt='Logo Marca'
            style={{
                display: "block",
                marginLeft: collapsed == false ? 18 : 'auto',
                marginRight: collapsed == false ? 0 : 'auto',
                maxWidth: collapsed == false ? 180 : "90%",
                maxHeight: 40,
            }}
        />
    )
});
