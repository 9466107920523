import {
    ButtonBack,
    Container,
    Content,
    ContentDescriptions,
    ContentImageNotFound,
    ContentLogo,
    Header,
    ImageNotFound,
    Logo,
    TextNotFound,
    Title
} from './styled';

import logo from "@dropDesk/storage/images/logo_dropdesk_portrait.png";
import pageNotFound from "@dropDesk/storage/images/page_not_found.png";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


export default function NotFound() {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container background={colors.onBackground}>
            {/*<Header/>*/}
            <ContentLogo>
                <Logo src={logo} alt={"Logo DropDesk"}/>
            </ContentLogo>
            <Content>
                <ContentImageNotFound>
                    <TextNotFound color={colors.text}>Página não encontrada</TextNotFound>
                    <ImageNotFound src={pageNotFound} alt={"pagina não encontrada"}/>
                </ContentImageNotFound>
                <ContentDescriptions background={colors.delicateBackground}>
                    <Title color={colors.text}>Desculpe, mas a página que você está tentando visualizar não
                        existe.</Title>
                    <Title color={colors.text}> - parece que isso foi o resultado de:</Title>
                    <li style={{color: colors.text, fontSize: 16}}>um endereço digitado incorretamente</li>
                    <li style={{color: colors.text, fontSize: 16}}>um link desatualizado</li>
                    <ButtonBack
                        background={colors.accent}
                        color={SharedColors.white}
                        to={appController.isUserClient ? RoutesEnum.tickets : RoutesEnum.dashboard}
                    >
                        Pagina inicial
                    </ButtonBack>
                </ContentDescriptions>
            </Content>
        </Container>
    );
}
