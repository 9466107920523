import {SimpleUserInfo, UserInfoEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";

export enum CompanyLogType {
    payment = 'payment',
    account = 'account',
}

export class CompanyLogsEntity {
    id?: string;
    idCompany!: string;
    type!: CompanyLogType;
    loggedBy!: UserInfoEntity;
    log!: Record<string, any>;
    createdAt?: string;
    updatedAt?: string;

    public get description() {
        return this.log?.description ?? 'unknown'
    }

    public get responsible() {
        return this.loggedBy.email === ConstantsKeys.emailQueueDropDesk ? 'Sistema DropDesk' : this.loggedBy?.email ?? 'unknown';
    }

    public static fromJson(json: Record<string, any>): CompanyLogsEntity {
        return new CompanyLogsEntity({
            id: json['id'],
            idCompany: json['idCompany'],
            type: json['type'],
            log: json['log'],
            createdAt: json['createdAt'],
            updatedAt: json['updatedAt'],
            loggedBy: UserInfoEntity.fromJson(json['loggedBy']),
        });
    }

    constructor({
                    id,
                    idCompany,
                    type,
                    loggedBy,
                    log,
                    createdAt,
                    updatedAt,
                }: {
        id?: string;
        idCompany: string;
        type: CompanyLogType;
        loggedBy: UserInfoEntity;
        log: Record<string, any>;
        createdAt?: string;
        updatedAt?: string;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            type,
            loggedBy,
            log,
            createdAt,
            updatedAt,
        });
    }

}

