import React from 'react';
import {LoadingOutlined, Loading3QuartersOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


export const ClipLoading = (
    {
        size,
        color
    }: {
        size?: number
        color?: string
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const antIcon = <Loading3QuartersOutlined style={{fontSize: size ?? 24, color: color ?? colors.accent}} spin/>;

    return (
        <Spin indicator={antIcon} style={{display: 'flex'}}/>
    )
}
export default ClipLoading;
