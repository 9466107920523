import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {Popover} from "antd";

type ContainerReactionsType = {
    shadow: string;
}

type ContainerType = {
    background: string;
}

type ContentReactionType = {
    background: string;
    accent: string;
}

type PopoverType = {
    position: 'left' | 'right';
    marginSize: number;
}

export const ContainerInputReactions = styled.div<ContainerReactionsType>`
  margin: 0;
  padding: 0;
  height: 56px;
  width: 308px;
  max-width: 308px;
  box-shadow: 0 3px 6px -4px ${props => hexToRGBA(props.shadow, 0.12)}, 0 6px 16px 0 ${props => hexToRGBA(props.shadow, 0.08)}, 0 9px 28px 8px ${props => hexToRGBA(props.shadow, 0.05)};
  border-radius: 8px;
`;

export const ContentInputReactions = styled.div<ContainerType>`
  background: ${props => props.background};
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  height: 100%;
`;
export const ContentReaction = styled.div<ContentReactionType>`
  background: ${props => props.background};
  display: flex;
  flex: 1;
  height: 38px;
  min-height: 38px;
  max-height: 38px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 27px;
  border-radius: 20px;
  margin: 0 2px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;


export const PopOver = styled(Popover)<PopoverType>`

`;
