import styled from "styled-components";

type TextType = {
    color: string;
};

export const Container = styled.div`
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: 5px 5px 0 0;
  width: 100%;
`;

export const Text = styled.strong<TextType>`
  font-size: 18px;
  color: ${(props) => props.color};
  letter-spacing: 1.5px;
`;

export const ContentLogo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 235px;
  height: 50px;
  margin-bottom: 16px;
`;
export const Logo = styled.img`
  display: flex;
  width: 100%;
  pointer-events: none;
  margin-right: 20px;
`;
