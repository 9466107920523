import {
    Header,
    RouteName,
    ContentProfile,
    TextName,
    TextEmail,
    Content,
    ContentRouteNameAndBackButton,
    ContentNotification,
    ContentButtonBack,
    TextBack,
    ContainerButtonHeader,
    ContainerProfile,
    ContainerPopover,
    ContentImageProfilePopover,
    ContainerItemsMenu,
    ItemMenu, TextItemMenu,
} from "./styled";

import {useNavigate, useParams} from "react-router-dom";
import NoticesNotification from "@dropDesk/presentation/components/headers/header/notices";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import React, {useState} from "react";
import {getDifferentTheme, isTicketChatRoute} from "@dropDesk/utils/helpers/common";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import ModalsHeader from "@dropDesk/presentation/components/headers/header/modals";
import {useBetween} from "use-between";
import {useModalsHeader} from "@dropDesk/presentation/components/headers/header/modals/visible_modal_subscription";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import PopOver from "@dropDesk/presentation/components/popup/pop_confirm";

type HeaderProps = {
    routeName: string;
    useBackButtonPreviousScreen?: boolean;
    onClickBackButtonPreviousScreen?: () => void;
    onRequestCollapsedMenu: () => void;
    userName: string;
    userImage: string | null;
    userEmail: string;
    currentUserId: string;
    permissionScreenUsers: boolean;
    logout: () => Promise<void>;
    setUserTheme: (theme: string) => void,
    user: UserEntity,
    onUpdateUser: (user: UserEntity) => void,
};
export default function HeaderMain(props: HeaderProps) {
    const userName = props.userName;
    const userImage = props.userImage;
    const userEmail = props.userEmail;
    const navigate = useNavigate();
    const {id} = useParams();
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const [visiblePopover, setVisiblePopover] = useState<boolean>(false);

    const handleClickChange = (open: boolean) => {
        setVisiblePopover(open);
    };

    const handleDarkOrLightTheme = (): void => {
        const newTheme = getDifferentTheme(appController.theme);
        const theme = `${newTheme.colorScheme.brightness}-${newTheme.colorScheme.name}`;
        const _user = props.user.copyWith({
            theme: theme,
            themeObject: newTheme
        });
        props.onUpdateUser(_user);
        appController.setTheme(newTheme);
        props.setUserTheme(theme);
    }

    const {
        setVisibleModalLogout
    } = useBetween(useModalsHeader);

    return (
        <Header paddinghorizontal={isTicketChatRoute() ? 16 : 32} background={colors.onBackground}
                accent={colors.accent}>
            <ContentRouteNameAndBackButton>
                <ContainerButtonHeader
                    style={{marginRight: 10}}
                    accent={colors.accent}
                    background={colors.buttonOnBackground}
                    onClick={props.onRequestCollapsedMenu}
                >
                    <DropDeskIcon
                        color={colors.text}
                        icon={ICONS_DROPDESK.menuOutline}
                        size={20}
                        style={{cursor: 'pointer'}}
                    />
                </ContainerButtonHeader>
                <RouteName color={colors.text}>{props.routeName}</RouteName>
            </ContentRouteNameAndBackButton>
            {id && isTicketChatRoute() && <ContentRouteNameAndBackButton>
                <ContentButtonBack
                    onClick={() => navigate(-1)}
                >
                    <DropDeskIcon
                        color={colors.text}
                        icon={ICONS_DROPDESK.arrowLeft}
                        size={23}
                        style={{marginRight: 10, cursor: 'pointer'}}
                    />
                    <TextBack color={colors.text}>Voltar</TextBack>
                </ContentButtonBack>
            </ContentRouteNameAndBackButton>}
            <ContentNotification>
                <ContainerButtonHeader
                    background={colors.buttonOnBackground}
                    accent={colors.accent}
                    style={{marginRight: 10, marginTop: 2}}
                    onClick={() => handleDarkOrLightTheme()}
                >
                    <DropDeskIcon
                        icon={`${colors.brightness === Brightness.dark ? ICONS_DROPDESK.bulbOff : ICONS_DROPDESK.bulbOn}`}
                        size={24}
                        color={colors.text}
                        style={{cursor: 'pointer'}}
                    />
                </ContainerButtonHeader>
                <ContainerButtonHeader
                    background={colors.buttonOnBackground}
                    accent={colors.accent}
                    style={{marginRight: 16}}
                >
                    < NoticesNotification/>
                </ContainerButtonHeader>
            </ContentNotification>

            <Content>

                <PopOver
                    open={visiblePopover}
                    onOpenChange={(value) => handleClickChange(value)}
                    placement="leftTop"
                    trigger="click"
                    content={
                        <ContainerPopover border={colors.border}
                                          shadow={colors.isDarkTheme ? colors.hint : SharedColors.black}>
                            <ContainerProfile border={colors.border}>
                                <ContentImageProfilePopover>
                                    <AvatarImage
                                        useCursorPointer={false}
                                        title={userName}
                                        url={userImage ?? ''}
                                        email={userEmail}
                                        size={40}
                                        round={true}
                                        isInverseBackground={true}
                                        withBorder={colors.isDarkTheme}
                                    />
                                </ContentImageProfilePopover>
                                <ContentProfile>
                                    <TextName color={colors.text}>
                                        {userName}
                                    </TextName>
                                    <TextEmail color={colors.text}>
                                        {userEmail}
                                    </TextEmail>
                                    <TextEmail
                                        style={{cursor: 'pointer'}}
                                        color={colors.textLink}
                                        onClick={() => {
                                            handleClickChange(false);
                                            navigate(appController.isUserClient ? `${RoutesEnum.profile}` : `${RoutesEnum.users}/${props.currentUserId}`);
                                        }}
                                    >
                                        Acessar meu perfil
                                    </TextEmail>
                                </ContentProfile>
                            </ContainerProfile>
                            <ContainerItemsMenu>
                                <ItemMenu
                                    background={'transparent'}
                                    accent={colors.accent}
                                    onClick={() => {
                                        setVisibleModalLogout(true);
                                        handleClickChange(false);
                                    }}
                                >
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.logout}
                                        size={24}
                                        color={colors.text}
                                    />
                                    <TextItemMenu color={colors.text}>Sair</TextItemMenu>
                                </ItemMenu>
                            </ContainerItemsMenu>
                        </ContainerPopover>
                    }>
                    <AvatarImage
                        useCursorPointer={true}
                        title={userName}
                        url={userImage ?? ''}
                        email={userEmail}
                        size={40}
                        round={true}
                        isInverseBackground={true}
                    />
                </PopOver>
            </Content>
            <ModalsHeader
                logout={() => props.logout()}
            />

        </Header>
    );
}
