import styled from "styled-components";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";

type IconOpsType = {
    color: string;
}

export const ContentSelectSearchLimitMinutes = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
`;

export const ContentPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
  margin-bottom: 16px;
`;
export const TextPermission = styled.span<IconOpsType>`
  font-size: ${`${ConstantsStyles.inputFontSize}px`};
  color: ${props => props.color};
`;
