import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {useModalNotices} from "@dropDesk/presentation/pages/notices/ui/form/modals/handle_change_visible";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import ModalSearchUser from "@dropDesk/presentation/components/modals/modal_search_user";
import ModalSearchClient from "@dropDesk/presentation/components/modals/modal_search_client";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsFormNotices = observer(
    ({
         onUpdate,
         notice,
         loading,
         onDeleteNotices,

     }: {
         onUpdate: (notice: NoticesEntity) => void,
         notice?: NoticesEntity,
         loading: boolean,
         onDeleteNotices: () => Promise<void>,
     }
    ) => {

        const {
            visibleModalSearchUser, setVisibleModalSearchUser,
            visibleModalSearchClient, setVisibleModalSearchClient,
            visibleModalDelete, setVisibleModalDelete
        } = useBetween(useModalNotices);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string }
        }, client?: ClientEntity, user?: UserEntity) => {

            const _notice = notice!.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,

                idClient: client ? client.id : notice!.idClient,
                client: client ?? notice!.client,
                replaceClientIfNull: event.target.name === "client",
                replaceIdClientIfNull: event.target.name === "client",

                idUser: user ? user.id : notice!.idUser,
                user: user ?? notice!.user,
                replaceUserIfNull: event.target.name === "user",
                replaceIdUserIfNull: event.target.name === "user",
            });

            onUpdate(_notice);

        };

        return (
            <>

                {visibleModalSearchClient &&
                    <ModalSearchClient
                        visible={visibleModalSearchClient}
                        onClose={() => setVisibleModalSearchClient(false)}
                        handleSelectClient={async (client) => {
                            handleAllChanges(
                                {
                                    target:
                                        {
                                            name: 'client',
                                            value: client,
                                            checked: false,
                                            type: 'input'
                                        }
                                }, client, undefined);
                            setVisibleModalSearchClient(false);
                        }}
                        textHeaderModal={"Selecione a empresa"}
                        labelNoResults={"Nenhuma empresa encontrada na pesquisa"}
                        inputSearchPlaceholder={"Pesquise por uma empresa"}
                    />}

                <ModalInformation
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        setVisibleModalDelete(false);
                    }}
                    textHeaderModal={'Excluir Aviso'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        onDeleteNotices();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={130}
                    textInformation={`Deseja realmente excluir este aviso?`}
                    loading={loading}
                    isActionDelete={true}
                />

                {visibleModalSearchUser && <ModalSearchUser
                    visible={visibleModalSearchUser}
                    onClose={() => setVisibleModalSearchUser(false)}
                    role={UserRole.attendant}
                    handleSelectUser={async (user) => {
                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'user',
                                        value: user,
                                        checked: false,
                                        type: 'input'
                                    }
                            },
                            undefined, user
                        );

                        setVisibleModalSearchUser(false);
                    }}
                    textHeaderModal={"Pesquisa de Atendentes"}
                    labelNoResults={"Nenhum usuário encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por atendentes"}
                />}

            </>
        )
    })
export default ModalsFormNotices;
