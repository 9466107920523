import {observer} from "mobx-react";
import {Container, ContainerPermission, Content} from "./styled";
import React from "react";
import HeaderConfiguration from "@dropDesk/presentation/components/headers/header_configuration";
import FooterConfiguration from "@dropDesk/presentation/components/footer/footer_configuration";
import Hours from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours";
import InputBusinessHourOperation
    from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/inputs";
import {Divider} from "antd";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    useValidatorHours
} from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/validator";


const BusinessHourOperator = observer(
    ({
         loading,
         handleSubmit,
         companyConfiguration,
         onUpdate
     }: {
        loading: boolean,
        handleSubmit: () => void,
        companyConfiguration: CompanyConfigurationEntity,
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const {isValidDataForm} = useValidatorHours(companyConfiguration.businessOperationHours);

        return (
            <Container heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>
                <Content background={colors.onBackground} border={colors.border}>
                    <HeaderConfiguration
                        letter={"Configurações de Horário Comercial"}
                        disabled={loading}
                    />
                    <ContainerPermission>
                        <InputBusinessHourOperation
                            loading={loading}
                            companyConfiguration={companyConfiguration}
                            onUpdate={onUpdate}
                        />
                        <Divider
                            style={{color: colors.hint, borderColor: colors.border, margin: 0, fontSize: 15}}>Horário
                            de Operação</Divider>
                        < Hours
                            onUpdate={onUpdate}
                            loading={loading}
                            companyConfiguration={companyConfiguration}
                        />
                    </ContainerPermission>
                    <FooterConfiguration
                        disabled={loading || !isValidDataForm}
                        loading={loading}
                        type={"submit"}
                        letter={"Salvar"}
                        onSubmit={handleSubmit}
                    />
                </Content>
            </Container>
        )
    })
export default BusinessHourOperator;
