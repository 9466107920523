import styled from "styled-components";

type ContentType = {
    background: string;
    border: string;
};

type IconOpsType = {
    color: string;
}

type ContainerType = {
    heightShowingHeaderInfo: number;
}


export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  height: calc(100vh - 111px - ${props => `${props.heightShowingHeaderInfo}px`});
`;

export const Content = styled.div<ContentType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
  margin: 0 auto;
  border: 1px solid ${props => props.border};
`;
export const ContentVersion = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;
export const ContainerPermission = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;
  align-items: flex-start;
`;
export const ContentPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
`;
export const TextPermission = styled.span<IconOpsType>`
  font-size: 15px;
  color: ${props => props.color};
  padding-left: 10px;
`;
export const TextVersion = styled.span<IconOpsType>`
  font-size: 16px;
  color: ${props => props.color};
  font-style: italic;
`;

