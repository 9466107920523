import {useState} from "react";

export const useModalsReportTicket = () => {

  const [visibleModalSearchUser, setVisibleModalSearchUser] = useState<boolean>(false);
  const [visibleModalSearchClient, setVisibleModalSearchClient] = useState<boolean>(false);
  const [visibleModalSearchSector, setVisibleModalSearchSector] = useState<boolean>(false);
  const [visibleModalSearchContacts, setVisibleModalSearchContacts] = useState<boolean>(false);
  const [visibleModalInformationReport, setVisibleModalInformationReport] = useState<boolean>(false);
  const [visibleModalExportPdf, setVisibleModalExportPdf] = useState<boolean>(false);
  const [textHeaderModalInformationReport, setTextHeaderModalInformationReport] = useState('');
  const [textInformationHeader, setTextInformationHeader] = useState('');
  const [textInformationBody, setTextInformationBody] = useState('');
  const [visibleModalExport, setVisibleModalExport] = useState<boolean>(false);




  return {
    visibleModalSearchUser,setVisibleModalSearchUser,
    visibleModalSearchClient,setVisibleModalSearchClient,
    visibleModalSearchSector,setVisibleModalSearchSector,
    visibleModalInformationReport,setVisibleModalInformationReport,
    textHeaderModalInformationReport,setTextHeaderModalInformationReport,
    textInformationBody,setTextInformationBody,
    textInformationHeader,setTextInformationHeader,
    visibleModalSearchContacts,setVisibleModalSearchContacts,
    visibleModalExportPdf, setVisibleModalExportPdf,
    visibleModalExport, setVisibleModalExport

  }
}
