import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
}

type InputType = {
    color: string;
    accent: string;
    background: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  min-width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  background: ${props => props.background};
  align-items: center;
  border-radius: 7px;
  padding: 5px 0;
  justify-content: flex-end;
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px;
`;

export const InputTextArea = styled.textarea<InputType>`
  flex: 1;
  height: 40px;
  padding: 5px;
  border: none;
  border-radius: 20px;
  color: ${props => props.color};
  box-sizing: border-box;
  outline: none;
  resize: none;
  justify-items: center;
  font-size: 15px;
  background: ${props => props.background};

  // &:hover {
  //   border-color: transparent;
    //   box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
  //   outline: none;
  //   transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  // }
  //
  // &:focus {
  //   border-color: transparent;
    //   box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
  //   outline: none;
  //   transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  // }
`;
