import {observer} from "mobx-react";
import React from "react";
import {
    ContentSelectSearchLimitMinutes,
    ContentPermission, TextPermission
} from "./styled";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import Switch from "@dropDesk/presentation/components/switch";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {timezoneMap} from "@dropDesk/domain/entities/common/common_maps";


const InputBusinessHourOperation = observer(
    ({
         loading,
         companyConfiguration,
         onUpdate
     }: {
        loading: boolean,
        companyConfiguration: CompanyConfigurationEntity,
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const handleAllChangesHourOperationConfiguration = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const _configurationBusinessOperation = companyConfiguration.copyWith({
                businessOperationHours: companyConfiguration.businessOperationHours.copyWith({
                    [event.target.name]:
                        (event.target.type === "checkbox" || event.target.type === "switch") ?
                            event.target.checked : event.target.value
                })
            });
            onUpdate(_configurationBusinessOperation);
        };

        return (
            <>
                <ContentPermission>
                    <Switch
                        disabled={loading}
                        id={'usesTwoOperationHours'}
                        name={'usesTwoOperationHours'}
                        checked={companyConfiguration.businessOperationHours.usesTwoOperationHours}
                        onChange={handleAllChangesHourOperationConfiguration}
                    />
                    <TextPermission style={{marginLeft: 10}} color={colors.text}>Utilizar dois horários de operação
                        diferente no mesmo
                        dia</TextPermission>

                </ContentPermission>
                <ContentPermission>

                    <ContentSelectSearchLimitMinutes>
                        <SelectSearchMain<string>
                            size={35}
                            options={timezoneMap}
                            onChange={(value) => {

                                handleAllChangesHourOperationConfiguration(
                                    {
                                        target:
                                            {
                                                name: 'timezone',
                                                value: value,
                                                checked: false,
                                                type: 'input'
                                            }
                                    })
                            }}
                            value={companyConfiguration.businessOperationHours.timezone}
                        />
                    </ContentSelectSearchLimitMinutes>
                    {/*<TextPermission style={{marginLeft: 10}} color={colors.letterMain}>Seu fuso horário</TextPermission>*/}
                </ContentPermission>
            </>
        )
    })
export default InputBusinessHourOperation;
