import {observer} from "mobx-react";
import React from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {useNavigate} from "react-router-dom";


const FooterFormEvaluation = observer(
    ({
         handleSubmit,
         loading,
         createdAt
     }: {
        handleSubmit: () => void,
        loading: boolean
        createdAt?: string,
    }) => {

        const navigate = useNavigate();

        return (
            <FooterForm
                disabled={loading}
                loading={loading}
                type={"submit"}
                letter={"Ver atendimento"}
                onSubmit={handleSubmit}
                onRequestClose={() => navigate(-1)}
                createdAt={createdAt}
                widthButton={165}
            />
        )
    })
export default FooterFormEvaluation;
