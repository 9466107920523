import {observer} from "mobx-react";
import React from "react";
import {
    ContentExportImport, ContentFilter,
    ContentInput,
    ContentInputAndListableNoticesDeleted,
    ContentInputSearch,
    ContentInputSearchAndFilter, PaddingFilters
} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useNavigate} from "react-router-dom";
import {useBetween} from "use-between";
import {useModalNotices} from "@dropDesk/presentation/pages/notices/ui/search/modals/visible_modal_search";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {destinyMap} from "@dropDesk/domain/entities/notices/notices_maps";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const isNewRegister = 'new';
const SearchBarNotices = observer(
    ({
         onSearch,
         filterDestiny,
         setFilterDestiny,
         visibleInputSearch,
         visibleButtonsOfActions,
         visibleButtonDeleted,
         visibleButtonRestore,
         loading,
     }: {
        onSearch: (searchParam: string) => void,
        filterDestiny: NoticeDestiny | null,
        setFilterDestiny: (filter: NoticeDestiny | null) => void,
        visibleInputSearch: boolean,
        visibleButtonsOfActions: boolean,
        visibleButtonDeleted: boolean,
        visibleButtonRestore: boolean,
        loading: boolean
    }) => {

        const navigate = useNavigate();
        const {setVisibleModalRestore, setVisibleModalDelete} = useBetween(useModalNotices);

        return (
            <ContentFilter>
                <ContentInputAndListableNoticesDeleted>
                    <ContentInput>
                        {visibleInputSearch &&
                            <ContentInputSearchAndFilter>
                                <ContentInputSearch>
                                    <InputSearchGeneric
                                        inputSearchPlaceholder="Pesquise pela descrição"
                                        loading={loading}
                                        onSearch={(value) => onSearch(value)}
                                    />
                                </ContentInputSearch>

                                <PaddingFilters>
                                    <SelectSearchMain<NoticeDestiny>
                                        size={40}
                                        width={200}
                                        useRemoveFilter={!!filterDestiny}
                                        placeHolder={"Filtrar por Destino"}
                                        options={destinyMap}
                                        onClickRemoveFilter={() => setFilterDestiny(null)}
                                        onChange={(value) => setFilterDestiny(value)}
                                        value={filterDestiny ?? null}
                                    />
                                </PaddingFilters>
                            </ContentInputSearchAndFilter>
                        }
                        {visibleButtonDeleted && <ButtonNew
                            marginLeft={0}
                            label={'Excluir'}
                            onClick={() => setVisibleModalDelete(true)}
                            nameLeftIcon={ICONS_DROPDESK.delete}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}
                        {visibleButtonRestore && <ButtonNew
                            marginLeft={0}
                            label={'Restaurar'}
                            onClick={() => setVisibleModalRestore(true)}
                            nameLeftIcon={ICONS_DROPDESK.restore}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}

                    </ContentInput>

                </ContentInputAndListableNoticesDeleted>
                <ContentExportImport>
                    {visibleButtonsOfActions && <ButtonNew
                        marginLeft={0}
                        label={'Novo Aviso'}
                        onClick={() => navigate(`${RoutesEnum.notices}/${isNewRegister}`)}
                        nameLeftIcon={''}
                        sizeLeftIcon={16}
                        disabled={false}
                        isButtonMain={true}
                    />}
                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBarNotices;
