import styled from "styled-components";
import {Badge} from "antd";
import {hexToRGBA, shadeOrLighten} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
    isDarkenBackground?: boolean;
    disabled: boolean;
}

type TextType = {
    color: string;
}

type LineClampTextType = {
    color: string;
    height: number;
}

type ContainerDateType = {
    height: number;
}

type BorderType = {
    bordercolor: string;
}

type PriorityType = {
    background: string;
}

type ContainerButtonDateDueType = {
    background: string;
    accent: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: 72px;
  min-height: 72px;
  max-height: 72px;
  flex-direction: row;
  cursor: ${(props) => `${props.disabled ? 'no-drop' : 'pointer'}`};
  background: ${props => props.isDarkenBackground ? shadeOrLighten(props.background, -40) : props.background};
  opacity: ${(props) => `${props.disabled ? 0.8 : 1}`};

  &:hover {
    background: ${props => shadeOrLighten(props.background, -40)};
  }
`;

export const ContainerAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 70px;
  max-width: 70px;
  min-width: 70px;
`;
export const ContainerData = styled.div<BorderType>`
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-width: 0;
  padding: 0px 10px 0px 0px;
  border-bottom: 1px solid ${props => props.bordercolor};
`;

export const ContentLineClamp = styled.div<LineClampTextType>`
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: ${props => props.color};
  max-height: ${(props) => `${props.height}px`};
  height: ${(props) => `${props.height}px`};
  max-width: 170px !important;
`;
export const TextClient = styled.p<TextType>`
  font-size: 16px;
  color: ${props => props.color};
  text-align: left;
  font-weight: bold;
  margin: 0 !important;
`;

export const TextSector = styled.p<TextType>`
  font-size: 16px;
  color: ${props => props.color};
  text-align: left;
  margin: 0 !important;
`;

export const TextMessage = styled.span<TextType>`
  display: block;
  font-size: 14px;
  color: ${props => props.color};
  text-align: left;
  margin: 0 !important;
`;

export const Text = styled.span<TextType>`
  font-size: 12px;
  color: ${props => props.color};
`;

export const ContentNameAndHour = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  max-height: 22px;
  height: 22px;
`;

export const ContentSectorDateDue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  max-height: 20px;
  margin: 3px 0;
`;

export const ContentMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  max-height: 22px;
  height: 22px;
`;

export const ContentDate = styled.div<ContainerDateType>`
  min-width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 13px;
  line-height: ${(props) => `${props.height}px`};
  text-align: end;
  margin: 2px 0px 0px 10px;
  position: relative;
`;

export const ContentDateDue = styled.div<ContainerDateType>`
  line-height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.height}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
`;

export const ContentBadge = styled.div`
  min-width: 15px;
  max-width: 32px;
  width: 100%;
  margin-left: 5px;
`;

export const ContentName = styled.div`
  display: flex;
  flex: 1;
  max-height: 20px;
`;

export const ContentSocialMessage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 20px;
  align-items: center;
`;
export const ContentIconStatus = styled.div`
  margin-right: 3px;
`;


export const ContentNameSector = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 20px;
`;

export const BadgeStyled = styled(Badge)`
  .ant-badge-multiple-words {
    padding: 0 4px;
  }

  .ant-badge-count-sm {
    line-height: 15px;
  }
`;

export const ContentPriority = styled.div<PriorityType>`
  max-height: 14px;
  border-right: 5px;
  background: ${props => props.background};
  border-radius: 3px;
  padding: 0px 4px;
  //margin: 0px 3px 0px 0px;
  display: flex;
  font-weight: bold;
  letter-spacing: .7px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 62px;
  min-width: 62px;
`;

export const ContentPriorityAndBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 85px;
`;

export const CirclePriorityDateExpired = styled.div<ContainerType>`
  position: absolute;
  right: -7px;
  top: 0px;
  min-height: 5px;
  min-width: 5px;
  max-height: 5px;
  max-width: 5px;
  background: ${(props) => props.background};
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 2px;
`;
export const Icon = styled.i<TextType>`
  font-size: 7px;
  color: ${props => props.color};
`;

export const ContainerButtonDateDue = styled.div<ContainerButtonDateDueType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
