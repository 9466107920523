import {observer} from "mobx-react";
import React from "react";
import {useNavigate} from "react-router-dom";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {useValidatorInputNotices} from "@dropDesk/presentation/pages/notices/ui/form/inputs/validator";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";


const FooterFormNotices = observer(
    ({
         onUpdate,
         notice,
         loading,
         handleSubmit,
         createdAt
     }: {
        onUpdate: (notice: NoticesEntity) => void,
        notice?: NoticesEntity,
        loading: boolean,
        handleSubmit: () => void,
        createdAt?: string,
    }) => {
        const navigate = useNavigate();

        const {
            isValidDataForm
        } = useValidatorInputNotices(notice);


        return (
            <FooterForm
                disabled={loading || !isValidDataForm}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={handleSubmit}
                onRequestClose={() => navigate(-1)}
                useCheckBox={!!notice}
                labelCheckBox={'Por Faixa Horária'}
                checkedCheckBox={notice?.hasTemporary ?? false}
                disabledCheckBox={loading}
                checkBoxName={'hasTemporary'}
                onChangeCheckBox={(event) => {
                    const _notice = notice!.copyWith({
                        hasTemporary: event.target.checked
                    });
                    onUpdate(_notice);
                }}
                createdAt={createdAt}
            />
        )
    })
export default FooterFormNotices;
