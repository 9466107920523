import "reflect-metadata";
import {inject, injectable} from "inversify";
import {AuthRepository} from "@dropDesk/domain/repositories/auth/auth.repository";
import firebaseAuth from "firebase/auth";


@injectable()
export class GetUserByDecodedTokenUseCase {
    private _repository: AuthRepository;

    constructor(
        @inject(AuthRepository) repository: AuthRepository
    ) {
        this._repository = repository;
    }

    public call(): Record<string, unknown> | null {
        return this._repository.getUserByDecodedToken();
    }
}
