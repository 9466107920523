import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type BorderType = {
    bordercolor: string;
}

type TextType = {
    color: string;
}

type IconType = {
    color: string;
    size: number;
}

type ContainerButtonHeaderType = {
    background: string;
    accent: string;
};

export const Container = styled.div<BorderType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px - 40px);
  flex-direction: column;
  border-right: 1px solid ${props => props.bordercolor};
  overflow: auto;
`;

export const ContainerButtonHeader = styled.div<ContainerButtonHeaderType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ContainerInputSearch = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentInputSearch = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
`;
export const ContentLoading = styled.div`
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
`;
export const ContentTextNoResults = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Text = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  margin-top: -10px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    filter: brightness(80%);
  }

  &::-webkit-scrollbar-thumb:hover {
    filter: brightness(80%);
  }

`;

export const Icon = styled.i<IconType>`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  cursor: pointer;
  height: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
`;
