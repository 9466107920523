import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import {ResponseStandardRemoteDataSource} from "@dropDesk/data/data_source/response_standard/response_standard_remote.datasource";
import {ResponseStandardRepository} from "@dropDesk/domain/repositories/response_standard/response_standard.repository";

@injectable()
export class ResponseStandardRepositoryImpl implements ResponseStandardRepository {
    private _dataSource: ResponseStandardRemoteDataSource;

    constructor(@inject(ResponseStandardRemoteDataSource) dataSource: ResponseStandardRemoteDataSource) {
        this._dataSource = dataSource;
    }

    list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean): Promise<ListPaginationEntity<ResponseStandardEntity>> {
        try {
            return this._dataSource.list(page, searchParam, limit, listOnlyDeleted);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    set(responseStandard: ResponseStandardEntity): Promise<ResponseStandardEntity> {
        try {
            return this._dataSource.set(responseStandard);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public findByPK(id: string): Promise<ResponseStandardEntity> {
        try {
            return this._dataSource.findByPK(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    restore(responseStandard: ResponseStandardEntity[]): Promise<void> {
        try {
            return this._dataSource.restore(responseStandard);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    delete(responseStandard: ResponseStandardEntity[]): Promise<void> {
        try {
            return this._dataSource.delete(responseStandard);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
