export enum ColorsType {
    default = 'green',
    orange = 'orange',
    blue = 'blue',
    red = 'red',
    purple = 'purple',
    grey = 'grey',
    pink = 'pink',
    brown = 'brown',
}
