import {TimezoneEnum} from "@dropDesk/domain/entities/company_configurations/configuration_enum";
import {getDayName, getUtcFromTZ, isNowInRange} from "@dropDesk/utils/helpers/date_helper";

export class BusinessOperationDayEntity {
    open!: boolean;
    initialFirstHour!: string;
    endFirstHour!: string;
    initialSecondHour!: string;
    endSecondHour!: string;

    constructor({
                    open,
                    initialFirstHour,
                    endFirstHour,
                    initialSecondHour,
                    endSecondHour,
                }: {
        open: boolean;
        initialFirstHour: string;
        endFirstHour: string;
        initialSecondHour: string;
        endSecondHour: string;
    }) {
        Object.assign(this, {
            open,
            initialFirstHour,
            endFirstHour,
            initialSecondHour,
            endSecondHour,
        });
    }

    copyWith({
                 open,
                 initialFirstHour,
                 endFirstHour,
                 initialSecondHour,
                 endSecondHour,
             }: {
        open?: boolean;
        initialFirstHour?: string;
        endFirstHour?: string;
        initialSecondHour?: string;
        endSecondHour?: string;
    }): BusinessOperationDayEntity {
        return new BusinessOperationDayEntity({
            open: open ?? this.open,
            initialFirstHour: initialFirstHour ?? this.initialFirstHour,
            endFirstHour: endFirstHour ?? this.endFirstHour,
            initialSecondHour: initialSecondHour ?? this.initialSecondHour,
            endSecondHour: endSecondHour ?? this.endSecondHour,
        })
    }
}

export class BusinessOperationHoursConfigurationEntity {
    usesTwoOperationHours!: boolean;
    timezone!: TimezoneEnum;
    monday!: BusinessOperationDayEntity;
    tuesday!: BusinessOperationDayEntity;
    wednesday!: BusinessOperationDayEntity;
    thursday!: BusinessOperationDayEntity;
    friday!: BusinessOperationDayEntity;
    saturday!: BusinessOperationDayEntity;
    sunday!: BusinessOperationDayEntity;

    public get getBusinessOperationDays(): Record<string, BusinessOperationDayEntity> {
        return {
            monday: this.monday,
            tuesday: this.tuesday,
            wednesday: this.wednesday,
            thursday: this.thursday,
            friday: this.friday,
            sunday: this.sunday
        };
    }

    public companyInOperation(): boolean {
        const businessOperation = this as any;
        const now = new Date();
        const day = getDayName(now);
        const businessTimes = businessOperation[day] as BusinessOperationDayEntity;
        const utcDiff = getUtcFromTZ(this.timezone);

        if (!businessTimes.open) {
            return false;
        }

        const justDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
        const firstStartHour = parseInt(businessTimes.initialFirstHour.split(':')[0]);
        const firstStartMinute = businessTimes.initialFirstHour.split(':')[1];
        const firstStart = new Date(
            `${justDate}T${firstStartHour.toString().padStart(2, '0')}:${firstStartMinute}`,
        );

        const firstEndHour = parseInt(businessTimes.endFirstHour.split(':')[0]);
        const firstEndMinute = businessTimes.endFirstHour.split(':')[1];
        const firstEnd = new Date(`${justDate}T${firstEndHour.toString().padStart(2, '0')}:${firstEndMinute}`);

        const isFirstValid = isNowInRange(firstStart.getTime(), firstEnd.getTime(), utcDiff);
        let isSecondValid = true;

        if (businessTimes.initialFirstHour != businessTimes.initialSecondHour) {
            const secondStartHour = parseInt(businessTimes.initialSecondHour.split(':')[0]);
            const secondStartMinute = businessTimes.initialSecondHour.split(':')[1];
            const secondStart = new Date(
                `${justDate}T${secondStartHour.toString().padStart(2, '0')}:${secondStartMinute}`,
            );

            const secondEndHour = parseInt(businessTimes.endSecondHour.split(':')[0]);
            const secondEndMinute = businessTimes.endSecondHour.split(':')[1];
            const secondEnd = new Date(`${justDate}T${secondEndHour.toString().padStart(2, '0')}:${secondEndMinute}`);

            isSecondValid = isNowInRange(secondStart.getTime(), secondEnd.getTime(), utcDiff);
        }

        return isFirstValid || isSecondValid;
    }

    public timezoneToHuman(): string {
        const timezones: Record<TimezoneEnum, string> = {
            [TimezoneEnum.americaSaoPaulo]: 'Fuso Horário de Brasilia -03:00',
            [TimezoneEnum.americaManaus]: 'Fuso Horário de Amazonas -04:00',
            [TimezoneEnum.brazilDeNoronha]: 'Fuso Horário de Fernando de Noronha -02:00',
            [TimezoneEnum.americaRioBranco]: 'Fuso Horário do Acre -05:00',
        };
        return timezones[this.timezone] ?? '';
    }

    public dayNameToHuman(day: string): string {
        const days: Record<string, string> = {
            ['sunday']: 'Domingo',
            ['monday']: 'Segunda - feira',
            ['tuesday']: 'Terça - feira',
            ['wednesday']: 'Quarta - feira',
            ['thursday']: 'Quinta - feira',
            ['friday']: 'Sexta - feira',
            ['saturday']: 'Sábado',
        };
        return days[day] ?? '';
    }

    constructor({
                    monday,
                    tuesday,
                    wednesday,
                    thursday,
                    friday,
                    saturday,
                    sunday,
                    usesTwoOperationHours,
                    timezone,
                }: {
        monday: BusinessOperationDayEntity;
        tuesday: BusinessOperationDayEntity;
        wednesday: BusinessOperationDayEntity;
        thursday: BusinessOperationDayEntity;
        friday: BusinessOperationDayEntity;
        saturday: BusinessOperationDayEntity;
        sunday: BusinessOperationDayEntity;
        usesTwoOperationHours: boolean;
        timezone: TimezoneEnum;
    }) {
        Object.assign(this, {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            usesTwoOperationHours,
            timezone,
        });
    }

    copyWith({
                 monday,
                 tuesday,
                 wednesday,
                 thursday,
                 friday,
                 saturday,
                 sunday,
                 usesTwoOperationHours,
                 timezone,
             }: {
        monday?: BusinessOperationDayEntity;
        tuesday?: BusinessOperationDayEntity;
        wednesday?: BusinessOperationDayEntity;
        thursday?: BusinessOperationDayEntity;
        friday?: BusinessOperationDayEntity;
        saturday?: BusinessOperationDayEntity;
        sunday?: BusinessOperationDayEntity;
        usesTwoOperationHours?: boolean;
        timezone?: TimezoneEnum;
    }): BusinessOperationHoursConfigurationEntity {
        return new BusinessOperationHoursConfigurationEntity({
            monday: monday ?? this.monday,
            tuesday: tuesday ?? this.tuesday,
            wednesday: wednesday ?? this.wednesday,
            thursday: thursday ?? this.thursday,
            friday: friday ?? this.friday,
            saturday: saturday ?? this.saturday,
            sunday: sunday ?? this.sunday,
            usesTwoOperationHours: usesTwoOperationHours ?? this.usesTwoOperationHours,
            timezone: timezone ?? this.timezone,
        })
    }
}
