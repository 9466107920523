import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
    border: string;
}

type TextType = {
    color: string;
    border: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${props => props.background};
  border-radius: 5px;
  min-width: 700px;
  border: 1px solid ${props => props.border};
`;

export const LabelPayments = styled.div<TextType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  flex: 1;
  padding: 0 16px;
  font-size: ${(props) => `${props.size}px`};
  font-weight: bold;
  color: ${(props) => props.color};
  border: 1px solid ${props => props.border};
  border-radius: 5px;
  margin-top: 20px;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 16px 0 16px;
`;
