import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {useModalsHeader} from "@dropDesk/presentation/components/headers/header/modals/visible_modal_subscription";


const ModalsHeader = observer(
    ({
         logout
     }: {
        logout: () => Promise<void>
    }) => {
        const {
            visibleModalLogout, setVisibleModalLogout
        } = useBetween(useModalsHeader);

        return (
            <ModalInformation
                open={visibleModalLogout}
                onRequestClose={() => {
                    setVisibleModalLogout(false);
                }}
                textHeaderModal={'Sair do sistema'}
                textButton={'Sair'}
                onRequestSave={async () => {
                    setVisibleModalLogout(false);
                    await logout();
                }}
                nameIcon={ICONS_DROPDESK.logout}
                sizeIcon={130}
                textInformation={`Deseja realmente sair do sistema?`}
                loading={false}
            />
        )
    })
export default ModalsHeader;
