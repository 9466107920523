import {observer} from "mobx-react";
import React, {useContext} from "react";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {Tooltip} from "antd";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ButtonDesconnected, ContainerAction} from "./styled";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {useBetween} from "use-between";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";
import {
    useModalConnectionsActive
} from "@dropDesk/presentation/pages/company_active_connections/search/modals/handle_change_visible";

import AvatarImage from "@dropDesk/presentation/components/avatar";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


const TableActiveConnections = observer(
    ({
         searchParam,
         loading,
         tableConnections,
         getDataFromPage,
         setUserActiveConnection
     }: {
        searchParam: string,
        loading: boolean,
        tableConnections: ListPaginationEntity<CompanyConnectionsEntity>,
        getDataFromPage: (page: number) => void,
        setUserActiveConnection: (record: CompanyConnectionsEntity) => void,
    }) => {


        const collapsed = useContext(MenuCollapsedContext);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const {
            setVisibleModalLogoutByAdmin
        } = useBetween(useModalConnectionsActive);

        const setSelectedUserConnected = (record: CompanyConnectionsEntity) => {
            setUserActiveConnection(record);
            setVisibleModalLogoutByAdmin(true);
        }


        const tableColumn = [
            {
                title: 'Nome',
                dataIndex: 'url',
                width: 58,
                render: (text: string, record: CompanyConnectionsEntity) => (
                    <AvatarImage
                        useCursorPointer={record.user?.urlImageProfile ? true : false}
                        title={record.user.name}
                        size={35}
                        url={record.user.urlImageProfile}
                        hasPreviewImage={true}
                        round={true}
                        withBorder={true}
                        onClick={(event) => event.stopPropagation()}
                    />
                ),
            },
            {
                title: '',
                ellipsis: true,
                dataIndex: 'name',
                render: (text: string, record: CompanyConnectionsEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={record.user.name}
                    />
                ),
            },
            {
                title: 'Email',
                ellipsis: true,
                dataIndex: 'email',
                render: (text: string, record: CompanyConnectionsEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={record.user.email ?? ''}
                    />
                ),
            },
            {
                title: 'Conectado em',
                ellipsis: true,
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (text: string) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },
            {
                title: '', key: 'userId', width: 150, render: (text: string, record: CompanyConnectionsEntity) => (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <ContainerAction>

                            <Tooltip placement="left" title={'Desconectar atendente'}
                            >
                                <ButtonDesconnected
                                    background={colors.textError}
                                    color={SharedColors.white}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedUserConnected(record);
                                    }}
                                >
                                    Desconectar
                                </ButtonDesconnected>
                            </Tooltip>
                        </ContainerAction>
                    </div>
                ),
            },
        ];


        return (
            <TableGeneric<CompanyConnectionsEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableConnections.data}
                onSelectedRow={(companyConnection: CompanyConnectionsEntity) => {
                }}
                loading={loading}
                labelNoResults={'Nenhum atendente encontrado na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableConnections.totalRows}
                page={tableConnections.page}
                pageSize={tableConnections.limit}
                rowSelection={null}
                onChange={(page) => getDataFromPage(page - 1)}
                rowKey={"userId"}
            />
        )
    })
export default TableActiveConnections;
