import React, {MutableRefObject, useEffect} from 'react';
import FilerobotImageEditor, {
    TABS,
    TOOLS,
} from 'react-filerobot-image-editor';
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {FileEditionEntity} from "@dropDesk/domain/entities/image_editor/image_editor.entity";
import {ReferenceImageEdited} from "@dropDesk/domain/entities/image_editor/image_editor_enum.entity";
import {observer} from "mobx-react";
import './filerobot.css';

const ImageEditorFileRobot = observer((
    {
        currentFileEdition,
        file,
        referenceImageEdited,
    }: {

        currentFileEdition: FileEditionEntity | null
        file: FileEditionEntity
        referenceImageEdited: MutableRefObject<ReferenceImageEdited | undefined>

    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const saveFile = (urlFile: string, fileName: string): void => {
        const anchor = document.createElement('a');
        const url = urlFile;
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
    }
    return (
        <>
            {currentFileEdition && file.fileMessage.id === currentFileEdition.fileMessage.id && currentFileEdition.fileMessage.blobURL &&
                <FilerobotImageEditor
                    source={currentFileEdition.fileMessage.blobURL}
                    getCurrentImgDataFnRef={referenceImageEdited}
                    onSave={(editedImageObject, imageDesignState) => {
                        saveFile(editedImageObject.imageBase64 ?? '', editedImageObject.name);
                    }}
                    resetOnImageSourceChange={false}
                    observePluginContainerSize={true}
                    showBackButton={false}
                    onBeforeSave={() => false}
                    avoidChangesNotSavedAlertOnLeave={false}
                    language='pt'
                    defaultSavedImageName={currentFileEdition.fileMessage.file.name}
                    loadableDesignState={currentFileEdition.fileMessage.loadableDesignState}
                    defaultSavedImageType='png'
                    forceToPngInEllipticalCrop={true}
                    useBackendTranslations={false}
                    annotationsCommon={
                        {
                            fill: '#33CEFF',
                            stroke: '#33CEFF',
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 0,
                            shadowColor: '#33CEFF',
                            shadowOpacity: 1,
                            opacity: 1,
                            strokeWidth: 1,
                        }
                    }
                    Pen={{
                        strokeWidth: 6,
                        tension: 0.5,
                        lineCap: 'round',
                        selectAnnotationAfterDrawing: false,
                    }}
                    Text={{
                        text: 'escreva aqui'
                    }}
                    Rotate={{angle: 90, componentType: 'slider'}}
                    Crop={{
                        autoResize: true,
                        noPresets: true,
                    }}
                    tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.FINETUNE]}
                    defaultTabId={TABS.ANNOTATE}
                    defaultToolId={TOOLS.ARROW}
                    defaultSavedImageQuality={1}
                    disableZooming={true}
                    noCrossOrigin={false}
                    savingPixelRatio={4}
                    previewPixelRatio={window.devicePixelRatio}
                    //previewPixelRatio={window.devicePixelRatio * 4}
                    translations={{
                        name: 'Nome',
                        save: 'Baixar',
                        saveAs: 'Salvar como',
                        back: 'Voltar',
                        loading: 'Carregando...',
                        resetOperations: 'Redefinir/excluir todas as operações',
                        changesLoseWarningHint:
                            'Se você pressionar o botão “reset” suas alterações serão perdidas. Você gostaria de continuar?',
                        discardChangesWarningHint:
                            'Se você fechar o modal, sua última alteração não será salva.',
                        cancel: 'Cancelar',
                        apply: 'Aplicar',
                        warning: 'Aviso',
                        confirm: 'Confirmar',
                        discardChanges: 'Descartar alterações',
                        undoTitle: 'Desfazer última operação',
                        redoTitle: 'Refazer última operação',
                        showImageTitle: 'Mostrar imagem original',
                        zoomInTitle: 'Aumentar zoom',
                        zoomOutTitle: 'Diminuir zoom',
                        toggleZoomMenuTitle: 'Alternar menu de zoom',
                        adjustTab: 'Recortar',
                        finetuneTab: 'Efeitos',
                        filtersTab: 'Filtros',
                        watermarkTab: 'Marca d\'água',
                        annotateTabLabel: 'Editar',
                        resize: 'Redimensionar',
                        resizeTab: 'Redimensionar',
                        imageName: 'Nome da imagem',
                        invalidImageError: 'Imagem inválida fornecida.',
                        uploadImageError: 'Erro enquanto carregava a imagem.',
                        areNotImages: 'não são imagens',
                        isNotImage: 'não é imagem',
                        toBeUploaded: 'a ser carregado',
                        cropTool: 'Cortar',
                        original: 'Original',
                        custom: 'Personalizado',
                        square: 'Quadrado',
                        landscape: 'Paisagem',
                        portrait: 'Retrato',
                        ellipse: 'Elipse',
                        classicTv: 'TV clássica',
                        cinemascope: 'Cinemascópio',
                        arrowTool: 'Seta',
                        blurTool: 'Desfoque',
                        brightnessTool: 'Brilho',
                        contrastTool: 'Contraste',
                        ellipseTool: 'Elipse',
                        unFlipX: 'Un-Flip X',
                        flipX: 'Flip X',
                        unFlipY: 'Un-Flip Y',
                        flipY: 'Flip Y',
                        hsvTool: 'HSV',
                        hue: 'Matiz',
                        brightness: 'Brilho',
                        saturation: 'Saturação',
                        value: 'Valor',
                        imageTool: 'Imagem',
                        importing: 'Importando...',
                        addImage: '+ Adicionar imagem',
                        uploadImage: 'Carregar imagem',
                        fromGallery: 'Da galeria',
                        lineTool: 'Linha',
                        penTool: 'Caneta',
                        polygonTool: 'Polígono',
                        sides: 'Lados',
                        rectangleTool: 'Retângulo',
                        cornerRadius: 'Raio do canto',
                        resizeWidthTitle: 'Largura em pixels',
                        resizeHeightTitle: 'Altura em pixels',
                        toggleRatioLockTitle: 'Alternar bloqueio de proporção',
                        resetSize: 'Redefinir para o tamanho original da imagem',
                        rotateTool: 'Girar',
                        textTool: 'Texto',
                        textSpacings: 'Espaçamentos de texto',
                        textAlignment: 'Alinhamento de texto',
                        fontFamily: 'Família de fontes',
                        size: 'Tamanho',
                        letterSpacing: 'Espaçamento entre letras',
                        lineHeight: 'Tamanho da linha',
                        warmthTool: 'Calor',
                        addWatermark: '+ Adicionar marca d\'água',
                        addTextWatermark: '+ Adicionar marca d\'água de texto',
                        addWatermarkTitle: 'Escolha o tipo de marca d\'água',
                        uploadWatermark: 'Carregar marca d\'água',
                        addWatermarkAsText: 'Adicionar como texto',
                        padding: 'Preenchimento',
                        paddings: 'Preenchimentos',
                        shadow: 'Sombra',
                        horizontal: 'Horizontal',
                        vertical: 'Vertical',
                        blur: 'Desfoque',
                        opacity: 'Opacidade',
                        transparency: 'Transparência',
                        position: 'Posição',
                        stroke: 'Traço',
                        saveAsModalTitle: 'Salvar como',
                        extension: 'Extensão',
                        format: 'Formato',
                        nameIsRequired: 'Nome é obrigatório.',
                        quality: 'Qualidade',
                        imageDimensionsHoverTitle: 'Tamanho da imagem salva (largura x altura)',
                        cropSizeLowerThanResizedWarning:
                            'Observe que a área de corte selecionada é menor que o redimensionamento aplicado, o que pode causar diminuição da qualidade',
                        actualSize: 'Tamanho atual (100%)',
                        fitSize: 'Tamanho adequado',
                        addImageTitle: 'Selecione a imagem para adicionar...',
                        mutualizedFailedToLoadImg: 'Falha ao carregar imagem.',
                        tabsMenu: 'Menu',
                        download: 'Baixar',
                        width: 'Largura',
                        height: 'Altura',
                        add: '+',
                        cropItemNoEffect: 'Nenhuma visualização disponível para este item de corte',
                    }}
                    // @ts-ignore
                    theme={{
                        palette: {
                            'txt-primary': colors.text,
                            'txt-secondary': colors.hintInverse,
                            'txt-secondary-invert': colors.text,
                            'txt-placeholder': colors.hint,
                            'txt-info': colors.text,
                            'txt-warning': colors.warning,
                            'txt-error': colors.textError,
                            'accent-primary': colors.text,
                            'accent-primary-active': colors.text,
                            'accent-primary-disabled': colors.text,
                            'accent-primary-hover': colors.text,
                            'accent-secondary-disabled': colors.hint,
                            'accent-stateless': colors.text,
                            'accent-stateless_0_4_opacity': colors.text,
                            'accent_0_5_opacity': 'transparent',
                            'accent_1_2_opacity': 'transparent',
                            'accent_1_8_opacity': 'transparent',
                            'accent_2_8_opacity': 'transparent',
                            'accent_4_0_opacity': 'transparent',

                            'bg-primary': colors.onBackground,
                            'bg-primary-hover': colors.onBackground,
                            'bg-primary-active': colors.accent,
                            'bg-primary-0-5-opacity': colors.onBackground,
                            'bg-secondary': colors.onBackground,
                            'bg-grey': colors.onBackground,
                            'bg-stateless': 'transparent',
                            'bg-active': colors.accent,
                            'bg-base-light': colors.onBackground,
                            'bg-base-medium': colors.onBackground,
                            'bg-primary-light': 'blue',
                            'bg-primary-stateless': colors.onBackground,
                            'bg-hover': colors.buttonOnBackground,
                            'bg-green': colors.onBackground,
                            'bg-green-medium': colors.onBackground,
                            'bg-blue': colors.onBackground,
                            'bg-red': colors.onBackground,
                            'background-red-medium': colors.onBackground,
                            'bg-orange': colors.onBackground,
                            'bg-tooltip': colors.onBackground,

                            'icons-primary-opacity-0-6': colors.text,
                            'icons-secondary': colors.text,
                            'icon-primary': colors.text,
                            'icons-placeholder': colors.text,
                            'icons-invert': colors.text,
                            'icons-muted': colors.hint,
                            'icons-primary-hover': colors.accent,
                            'icons-secondary-hover': colors.accent,

                            'btn-primary-text': colors.onBackground,
                            'btn-disabled-text': colors.hint,
                            'btn-primary-text-0-6': colors.text,
                            'btn-primary-text-0-4': colors.text,
                            'btn-secondary-text': colors.text,

                            'link-stateless': colors.text,
                            'link-muted': colors.hint,
                            'link-pressed': colors.text,
                            'link-primary': colors.text,
                            'link-hover': colors.accent,
                            'link-active': colors.text,

                            'borders-primary': colors.border,
                            'borders-secondary': colors.border,
                            'borders-strong': colors.border,
                            'borders-invert': colors.border,
                            'border-active-bottom': colors.accent,
                            'borders-primary-hover': colors.accent,
                            'border-hover-bottom': colors.accent,
                            'border-primary-stateless': colors.border,
                            'borders-disabled': colors.hint,
                            'borders-button': colors.border,
                            'borders-item': colors.border,
                            'borders-base-light': colors.border,
                            'borders-base-medium': colors.border,
                            'borders-green': colors.border,
                            'borders-green-medium': colors.border,
                            'borders-red': colors.border,


                            'active-secondary': colors.accent,
                            'active-secondary-hover': colors.accent,
                            'light-shadow': colors.hint,
                            'medium-shadow': colors.hint,
                            'large-shadow': colors.hint,
                            'x-large-shadow': colors.hint,

                            "error": colors.textError,
                            "error-0-28-opacity": colors.textError,
                            "error-0-12-opacity": colors.textError,
                            "error-hover": colors.textError,
                            "error-active": colors.textError,
                            "success": colors.textError,
                            "success-hover": colors.success,
                            "success-Active": colors.success,

                            "warning": colors.warning,
                            "warning-hover": colors.warning,
                            "warning-active": colors.warning,

                            "info": colors.info,
                        },

                        typography: {
                            fontFamily: 'Poppins, Roboto, Arial',
                        },
                    }}
                />
            }
        </>
    )
});
export default ImageEditorFileRobot;
