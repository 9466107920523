import {evaluationEnum} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation_enum";

type Map = {
    value: string,
    label: string
}

export const evaluationValueMap: Map[] = [
    {value: evaluationEnum.five.toString(), label: "Excelente (5)"},
    {value: evaluationEnum.four.toString(), label: "Ótimo (4)"},
    {value: evaluationEnum.three.toString(), label: "Regular (3)"},
    {value: evaluationEnum.two.toString(), label: "Bom (2)"},
    {value: evaluationEnum.one.toString(), label: "Ruim (1)"},
]
