import {observer} from "mobx-react";
import React from "react";
import ModalRestore from "@dropDesk/presentation/components/modals/modal_information";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {useModalNotices} from "@dropDesk/presentation/pages/notices/ui/search/modals/visible_modal_search";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsNotices = observer(
    ({
         restoreNotice,
         deleteNotice,
         removeLineSelectRow,
         loading
     }: {
        restoreNotice: () => Promise<void>,
        deleteNotice: () => Promise<void>,
        removeLineSelectRow: () => void,
        loading: boolean
    }) => {


        const {
            visibleModalRestore,
            setVisibleModalRestore,
            visibleModalDelete,
            setVisibleModalDelete
        } = useBetween(useModalNotices);


        return (
            <>
                <ModalRestore
                    open={visibleModalRestore}
                    onRequestClose={() => {
                        setVisibleModalRestore(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Restaurar Aviso'}
                    textButton={'Restaurar'}
                    onRequestSave={async () => {
                        restoreNotice();
                        setVisibleModalRestore(false);
                    }}
                    nameIcon={ICONS_DROPDESK.restore}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a restauração deste aviso? Após restaurado o aviso passa a ser listado para o destino escolhido.`
                    }
                    loading={loading}
                />
                <ModalDelete
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        removeLineSelectRow();
                        setVisibleModalDelete(false);
                    }}
                    textHeaderModal={'Excluir Aviso'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        deleteNotice();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a exclusão deste aviso? Após excluído, o aviso não será mais listável.`
                    }
                    loading={loading}
                    isActionDelete={true}
                />
            </>
        )
    })
export default ModalsNotices;
