import styled from "styled-components";
import {ButtonType, ContainerType, TextType} from "@dropDesk/presentation/components/footer/footer_form/footer_form";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export const Container = styled.div<ContainerType>`
  display: flex;
  padding: ${(props) => props.removePadding ? '0px' : '0 16px 16px 16px'};
  align-items: center;
  justify-content: ${(props) => props.useButtonDownload ? 'space-between' : 'flex-start'};
  flex-direction: row;
`;
export const Button = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.letterColor};
  font-size: 14px;
  font-weight: bold;
  min-width: ${props => props.widthButton ?? 110}px;
  padding: 0 6px;
  height: 37px;
  min-height: 37px;
  border-radius: 5px;
  letter-spacing: 1px;
  border: 0;
  margin-right: 16px;


  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const ButtonBack = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.letterColor};
  font-size: 14px;
  font-weight: bold;
  width: 110px;
  height: 37px;
  min-height: 37px;
  border-radius: 5px;
  letter-spacing: 1px;
  border: ${(props) => `1px solid ${props.border}`};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

`;

export const Text = styled.span<TextType>`
  font-size: 14px;
  color: ${props => props.color}
`;
