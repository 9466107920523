import {useState} from "react";

export const useModalContacts = () => {

    const [visibleModalEditClientEntity, setVisibleModalEditClientEntity] = useState<{
        visible: boolean,
        id: string,
        idContact: string | null
    }>({visible: false, id: '', idContact: null});
    const [visibleModalExport, setVisibleModalExport] = useState<boolean>(false);
    const [visibleModalRestore, setVisibleModalRestore] = useState<boolean>(false);


    return {
        visibleModalEditClientEntity, setVisibleModalEditClientEntity,
        visibleModalExport, setVisibleModalExport,
        visibleModalRestore, setVisibleModalRestore
    }
}
