import styled from "styled-components";

type TextType = {
    color: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
`;

export const Text = styled.strong<TextType>`
  font-size: 16px;
  color: ${(props) => props.color};
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px 0 0 0;
`;

export const ContainerCompany = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px 0 0 0;
`;
