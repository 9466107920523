import styled from "styled-components";

type ContainerType = {
  background: string;
};

export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 16px;
`;
export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContentInput = styled.div`
  display: flex;
  width: 350px;
`;


export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;
export const ContainerModalNewResponseStander = styled.div<ContainerType>`
  display: flex;
  width: 500px;
  overflow: auto;
  flex-direction: column;
  border-radius: 7px;
  background: ${(props) => props.background};
`;
export const ContentModalNewResponseStander = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`;
