import {InfoOrigin} from "@dropDesk/domain/entities/generic/info_origin.enum";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";

export enum NotificationType {
    ticket = 'ticket',
    chat = 'chat',
    notice = 'notice',
    exportation = 'exportation',
}

export class NotificationData {
    type!: NotificationType;
    targetId!: string;
    table?: string;
    origin!: InfoOrigin;
    extras?: Record<string, any>;


    constructor({
                    type,
                    targetId,
                    table,
                    origin,
                    extras
                }: {
        type: NotificationType;
        targetId: string;
        table?: string;
        extras?: string;
        origin: InfoOrigin;
    }) {
        Object.assign(this, {
            type,
            targetId,
            origin,
            table,
            extras
        });
    }

    get hasTicketNotification(): boolean {
        return this.origin !== InfoOrigin.chat && !this.hasExportNotification;
    }

    get hasChatNotification(): boolean {
        return this.origin === InfoOrigin.chat && !this.hasExportNotification;
    }

    get hasExportNotification(): boolean {
        return this.type === NotificationType.exportation;
    }

    get parseExtraToReport(): Record<string, any> | undefined {
        if (this.extras?.gte && this.extras?.lte) {
            return {
                state: {
                    startDate: new Date(this.extras.gte as string),
                    endDate: new Date(this.extras.lte as string)
                }
            }
        }
        return;
    }

    get parseTableNameToRoute(): RoutesEnum | undefined {
        if (!this.table) {
            return;
        }
        const tables: Record<string, RoutesEnum> = {
            ['tickets']: RoutesEnum.exportreporttickets,
            ['sectors']: RoutesEnum.exportsectors,
            ['clients']: RoutesEnum.exportclients,
            ['users']: this.extras?.role === UserRole.attendant ? RoutesEnum.exportusers : RoutesEnum.exportcontacts,
        }
        return tables[this.table] ?? undefined;
    }

    static fromJson(json: Record<string, any>): NotificationData {
        return new NotificationData({
            type: json["type"],
            targetId: json["targetId"],
            table: json["table"],
            origin: json["origin"],
            extras: json["extras"],
        });
    }
}
