import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";
import {Observable} from "rxjs";
import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";

@injectable()
export class ImportSubscriptionClientUseCase {
  private _repository: ClientRepository;

  constructor(@inject(ClientRepository) repository: ClientRepository) {
    this._repository = repository;
  }

  public call(idCompany: string, startDate: string, endDate: string): Observable<ImportData> {
    return this._repository.importSubscription(idCompany, startDate, endDate);
  }
}

