import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {ChangePasswordResponse} from "@dropDesk/domain/entities/common/account_action.entity";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export abstract class UserRepository {

    public abstract list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        role: UserRole,
        idClient?: string,
        idSector?: string,
        listOnlyContactWithWhatsApp?: boolean,
    ): Promise<ListPaginationEntity<UserEntity>>;

    public abstract set(user: UserEntity, isNew: boolean): Promise<UserEntity>;

    public abstract restore(users: UserEntity[]): Promise<void>;

    public abstract delete(users: UserEntity[]): Promise<void>;

    public abstract findByPK(id: string, role?: UserRole): Promise<UserEntity>;

    public abstract changePassword(renewToken: string, password?: string): Promise<ChangePasswordResponse>;

    public abstract forgot(email: string): Promise<void>;

    public abstract activateAccount(email: string): Promise<void>;

    public abstract transferUser(idNewClient: string, idUser: string): Promise<void>;

    public abstract changeEmail(oldEmail: string, newEmail: string, isCurrentUserChanged: boolean): Promise<void>;

    public abstract setTheme(theme: string): Promise<void>;

    public abstract exportSubscription(): Observable<ExportDataEntity>;

    public abstract export(role: UserRole): Promise<ExportDataEntity>;
}
