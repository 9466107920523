import styled from "styled-components";
import {ContainerType, DrawerStyledType} from "@dropDesk/presentation/components/modals/modal_drawer/modal_drawer";
import {Drawer} from 'antd';


export const Container = styled.div<ContainerType>`
  display: flex;
  max-width: 1320px;
  background: ${props => props.background};
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`;
export const DrawerStyled = styled(Drawer)<DrawerStyledType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;

  .ant-drawer-title {
    color: ${props => props.color};
  }

  .ant-drawer-close {
    color: ${props => props.color};
  }

  .ant-drawer-header {
    min-height: 61px;
    height: 61px;
    max-height: 61px;
    border-bottom: 1px solid ${props => props.bordercolor};
  }

  .ant-drawer-content {
    background: transparent;
  }

  .ant-drawer-body {
    display: flex;
    padding: 0;
  }

`;
