import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketsDescriptionStaticRepository} from "@dropDesk/domain/repositories/ticket_description_static/ticket_description_static.repository";
import {TicketsDescriptionStaticEntity} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";

@injectable()
export class SetTicketsDescriptionStaticUseCase {
  private _repository: TicketsDescriptionStaticRepository;

  constructor(@inject(TicketsDescriptionStaticRepository) repository: TicketsDescriptionStaticRepository) {
    this._repository = repository;
  }

  public call(ticketsDescriptionStatic: TicketsDescriptionStaticEntity): Promise<TicketsDescriptionStaticEntity> {
    return this._repository.set(ticketsDescriptionStatic);
  }
}

