import React from "react";
import LinkFy from "@dropDesk/presentation/components/linkify";
import {HighLightHashTag} from "@dropDesk/presentation/components/high_light_hashtag";
import {ContentMessageDescription} from "./styled";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {observer} from "mobx-react";


const TextMessage = observer((
    {
        useHighLightHashTag,
        message,
        color
    }: {
        useHighLightHashTag?: boolean
        message: TicketMessageEntity
        color: string
    }
) => {

    return (
        <ContentMessageDescription color={color}>
            {!useHighLightHashTag ?
                <LinkFy text={message.getText} color={color}/>
                :
                <HighLightHashTag text={message.getText} fontSize={15} color={color}/>}
        </ContentMessageDescription>
    )
});
export default TextMessage;
