import styled from "styled-components";

type TextType = {
    color: string;
}

export const Container = styled.div`
  height: 75px;
  min-height: 75px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 16px;
`;

export const TextCommon = styled.span<TextType>`
  font-size: 12px;
  color: ${(props) => props.color};;
`;
