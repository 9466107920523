import styled from "styled-components";
import {
    TextHeaderType,
    ContainerType,
    IconType
} from "@dropDesk/presentation/components/graphics/panel_average_time/panel_average_time";

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.background};
  height: 150px;
  min-height: 150px;
  width: 50%;
  max-width: 50%;
  border-radius: 10px;
  margin-bottom: 16px;
  border: 1px solid ${props => props.bordercolor};

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;
export const ContentTextHeader = styled.div`
  height: 26px;
  min-height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
`;
export const TextHeader = styled.span<TextHeaderType>`
  font-size: 14px;
  color: ${(props) => props.color};
`;

export const ContentAverageTime = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
