import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export class ExportClientsSubscriptionUseCase {
  private _repository: ClientRepository;

  constructor(@inject(ClientRepository) repository: ClientRepository) {
    this._repository = repository;
  }

  public call(): Observable<ExportDataEntity> {
    return this._repository.exportSubscription();
  }
}

