import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {AddressRemoteDataSource} from "@dropDesk/data/data_source/common/localization/localization.datasource";
import {AddressRepository} from "@dropDesk/domain/repositories/common/localization/localization.repository";


@injectable()
export class AddressRepositoryImpl implements AddressRepository {
  private _dataSource: AddressRemoteDataSource;

  constructor(@inject(AddressRemoteDataSource) dataSource: AddressRemoteDataSource) {
    this._dataSource = dataSource;
  }

  public searchAddress(address: string): Promise<AddressEntity> {
    try {
      return this._dataSource.searchAddress(address);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }

}
