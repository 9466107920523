import styled from 'styled-components';


export const ContentInput = styled.div`
  display: flex;
  width: 70%;
`;
export const ContainerInput = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
`;

