import styled from "styled-components";
import backgroundLightDark from "@dropDesk/storage/images/bg-chat-light.png";
import backgroundChatDark from "@dropDesk/storage/images/bg-chat-dark.png";

type ContainerChatViewType = {
    background: string;
    fixHeight: number;
};

type ContainerType = {
    background: string;
};

type ContainerTypeFormTickets = {
    background: string;
    border: string;
    isUserClient?: boolean;
};

type WaterMarkType = {
    background: string;
    isDarkTheme: boolean;
};

export const ContainerSearch = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px);
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: ${(props) => props.background};
`;

export const Container = styled.div<ContainerChatViewType>`
  display: flex;
  flex: 1;
  height: ${props => `calc(100vh - ${props.fixHeight + 61}px)`};
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: ${(props) => props.background};
`;
export const ContainerCreate = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background: ${(props) => props.background};
`;
export const ContentFormTickets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;
export const ContentFormTicketsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
  flex: 1;
  overflow: auto;
`;
export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;
export const ContentFormCreateTicket = styled.div<ContainerTypeFormTickets>`
  max-width: 1060px;
  width: ${props => props.isUserClient ? '768px' : '100%'};
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
  border: 1px solid ${props => props.border};
`;
export const ContentFormViewTicket = styled.div<ContainerTypeFormTickets>`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background: ${props => props.background};
  position: relative;
  overflow: hidden;

`;
export const ContentInputs = styled.div`
  display: flex;
  height: calc(100vh - 220px);
  flex-direction: column;
  padding: 16px;
  margin: 0 auto;
  max-width: 1060px;
  min-height: 400px;
  overflow: auto;
`;

export const ContainerUploadFiles = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100px;
  min-height: 100px;
  flex-direction: row;
  padding: 0 16px 0 16px;
  margin: 5px 0;
`;
export const ContentUploadFiles = styled.div`
`;
export const ContentNameFiles = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100px;
  min-height: 100px;
  overflow: auto;
`;
export const ContentView = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  background: ${props => props.background};
`;

export const Watermark = styled.div<WaterMarkType>`
  position: relative;
  z-index: 5;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${props => props.background};

  &:after {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    background-image: url(${(props) => props.isDarkTheme ? backgroundChatDark : backgroundLightDark});
    opacity: 0.05;
    background-repeat: repeat;
    background-position: center;
    background-attachment: scroll;
    background-color: ${props => props.background};
    content: "";
    height: 100%;
    width: 100%;
  }
`;


