import {useState} from "react";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export const useModalFormClients = () => {

    const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
    const [visibleModalActivateAccount, setVisibleModalActivateAccount] =
        useState<{
            visible: boolean,
            user?: UserEntity
        }>({visible: false});
    const [visibleModalRememberPassword, setVisibleModalRememberPassword]
        = useState<{
        visible: boolean,
        user?: UserEntity
    }>({visible: false});
    const [visibleModalChangeEmail, setVisibleModalChangeEmail] =
        useState<{
            visible: boolean,
            user?: UserEntity
        }>({visible: false});


    return {
        visibleModalDelete,
        setVisibleModalDelete,
        visibleModalActivateAccount, setVisibleModalActivateAccount,
        visibleModalRememberPassword, setVisibleModalChangeEmail,
        setVisibleModalRememberPassword, visibleModalChangeEmail
    }
}
