import DetailSubscription from "@dropDesk/presentation/components/detail_subscription";
import React from "react";
import {
    ContentDetail,
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/styled";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {
    ContentInfos,
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/payment_method/styled";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import {ContentButton} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/payment_info/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {HighLightHashTag} from "@dropDesk/presentation/components/high_light_hashtag";
import {observer} from "mobx-react";

const PaymentMethod = observer((
    {
        paymentMethod,
        loading,
        disabled,
        handleEditPaymentMethod,
    }: {
        paymentMethod: PaymentMethodEntity
        loading: boolean
        disabled: boolean
        handleEditPaymentMethod: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <DetailSubscription
            style={{marginRight: 25}}
            title={'Forma de pagamento'}
            content={
                <ContentDetail>
                    <ContentInfos>
                        <HighLightHashTag fontSize={15} text={paymentMethod.paymentMethodChangeLabel}/>
                    </ContentInfos>
                    <ContentButton>
                        <ButtonMain
                            style={{paddingLeft: 10, paddingRight: 5}}
                            widthPercentage={100}
                            letterFontSize={15}
                            disabled={disabled}
                            loading={loading}
                            height={35}
                            color={colors.accent}
                            type={"submit"}
                            letter={'Trocar pagamento'}
                            onClick={() => handleEditPaymentMethod()}
                        />
                    </ContentButton>
                </ContentDetail>
            }
        />
    );
});
export default PaymentMethod;
