import {observer} from "mobx-react";
import React, {useState} from "react";
import {useBetween} from "use-between";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {
    useModalSectorsLinked
} from "@dropDesk/presentation/pages/user/ui/form/sectors_linked/modals/handle_change_visible";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {SectorUsersEntity} from "@dropDesk/domain/entities/sectors_users/sectors_users.entity";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {ContainerFormSector} from "@dropDesk/presentation/pages/user/ui/form/sectors_linked/modals/styled";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import FormSectors from "@dropDesk/presentation/pages/sector/ui/form_sector";
import ModalSearchSector from "@dropDesk/presentation/components/modals/modal_search_sector";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsSectorsLinked = observer(
    ({
         onChangeSectorsLinked,
         onDeleteSectorsLinked,
         user,
         loading,
         sectorUnlikedSelected,
         usersSectorsEligible,
         sectorsDefault
     }: {
        onChangeSectorsLinked: (sector: SectorEntity) => void,
        onDeleteSectorsLinked: (id: string) => void,
        user?: UserEntity,
        loading: boolean,
        sectorUnlikedSelected: SectorEntity | null,
        usersSectorsEligible: SectorUsersEntity[];
        sectorsDefault: SectorUsersEntity[];
    }) => {

        const {
            visibleModalSearchSector,
            setVisibleModalSearchSector,
            visibleModalUnlinked,
            setVisibleModalUnlinked,
            visibleModalCreateSector,
            setVisibleModalCreateSector
        } = useBetween(useModalSectorsLinked);


        const getSectorsDeleted = (selectedRows: SectorEntity[], sectors: SectorUsersEntity[]) =>
            sectors.filter(sectorUser => selectedRows.map(sector => sector.id)
                .indexOf(sectorUser.idSector) === -1 && sectorUser.action !== BackendAction.delete);

        const getEligibleSectors = (selectedRows: SectorEntity[], sectors: SectorUsersEntity[]) =>
            selectedRows.filter(sector => sectors.filter(sectorUser => sectorUser.action !== BackendAction.delete)
                .map(sectorUser => sectorUser.idSector).indexOf(sector.id) === -1);

        const setSectorsDeleteInBatch = (sectorsUsers: SectorUsersEntity[]): void => {
            for (let iteratorExclude = 0; iteratorExclude < sectorsUsers.length; iteratorExclude++) {
                onDeleteSectorsLinked(sectorsUsers[iteratorExclude].idSector);
            }
        }

        const setSectorsInBatch = (sectors: SectorEntity[]): void => {
            for (let iteratorInclude = 0; iteratorInclude < sectors.length; iteratorInclude++) {
                onChangeSectorsLinked(sectors[iteratorInclude]);
            }
        }

        const restoreSectorsUsersDefault = (): void => {

            for (let i = 0; i < sectorsDefault.length; i++) {
                onChangeSectorsLinked(sectorsDefault[i].sector!);
            }

            const ineligibleSectors: SectorUsersEntity[] = usersSectorsEligible.filter(sectorUsers => !sectorsDefault.some(_sectorUsers => sectorUsers.idSector === _sectorUsers.idSector));
            setSectorsDeleteInBatch(ineligibleSectors);
        }


        const rowSelectionSector = {
            selectedRowKeys: usersSectorsEligible.map(entry => entry.idSector),
            onChange: (selectedRowKeys: React.Key[], selectedRows: SectorEntity[]) => {

                const tempSelectedRows = selectedRows.concat(usersSectorsEligible.map(entry => entry.sector!)).filter(item => item != undefined);
                const totalSelectedRows = selectedRowKeys.map(key => tempSelectedRows.filter(item => item.id == key)[0]);

                const sectorDeleted = getSectorsDeleted(totalSelectedRows, user?.sectors ?? []);
                const sectorsAdd = getEligibleSectors(totalSelectedRows, user?.sectors ?? []);
                setSectorsDeleteInBatch(sectorDeleted);
                setSectorsInBatch(sectorsAdd)

            },
            getCheckboxProps: (record: SectorEntity) => ({
                disabled: false,
            }),
            preserveSelectedRowKeys: true
        };

        return (
            <>

                {visibleModalSearchSector && <ModalSearchSector
                    visible={visibleModalSearchSector}
                    onClose={() => {
                        restoreSectorsUsersDefault();
                        setVisibleModalSearchSector(false);
                    }}
                    onSave={() => {
                        setVisibleModalSearchSector(false);
                    }}
                    useFooter={true}
                    handleSelectSector={(sector: SectorEntity) => {
                        const hasSelected: boolean = user!.sectors.filter((entry) => entry.idSector === sector.id && entry.action !== BackendAction.delete).length > 0;
                        if (hasSelected) {
                            onDeleteSectorsLinked(sector.id);
                        } else {
                            onChangeSectorsLinked(sector)
                        }
                    }}
                    textHeaderModal={"Pesquisa de Setores"}
                    labelNoResults={"Nenhum setor encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por setores"}
                    rowSelection={rowSelectionSector}
                />}

                <ModalInformation
                    open={visibleModalUnlinked}
                    onRequestClose={() => {
                        setVisibleModalUnlinked(false);
                    }}
                    textHeaderModal={'Remover do Setor'}
                    textButton={'Remover'}
                    onRequestSave={() => {
                        onDeleteSectorsLinked(sectorUnlikedSelected?.id ?? '');
                        setVisibleModalUnlinked(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={130}
                    textInformation={`Deseja realmente remover o ${user?.name} deste setor?`}
                    loading={loading}
                    isActionDelete={true}
                />
                <ModalMain
                    open={visibleModalCreateSector}
                    onRequestClose={() => setVisibleModalCreateSector(false)}
                    width={650}
                    renderComponent={
                        <ContainerFormSector>
                            <FormSectors
                                handleSubmitExternalScreen={(sector) => {
                                    onChangeSectorsLinked(sector);
                                    setVisibleModalCreateSector(false);
                                }}
                                backPreviousScreenExternalScreen={() => setVisibleModalCreateSector(false)}
                            />
                        </ContainerFormSector>}
                />
            </>

        )
    })
export default ModalsSectorsLinked;
