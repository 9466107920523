import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import {
    ContainerModalNewResponseStander,
    ContentModalNewResponseStander
} from "./styled";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {useModalTask} from "@dropDesk/presentation/pages/task/ui/modals/handle_change_visible";
import InputsEditTask from "@dropDesk/presentation/pages/task/ui/modals/modal_edit_task/inputs";

const ModalEditTask = observer(
    ({
         task,
         onUpdate,
         loading,
         handleVisibleModalEditTask,
         set
     }: {
        task: TaskEntity
        onUpdate: (task: TaskEntity) => void,
        loading: boolean,
        handleVisibleModalEditTask: (visible: boolean, task?: TaskEntity) => void,
        set: (onSuccess: (key: string) => void) => Promise<void>
    }) => {
        const {t} = useTranslation();

        const handleSubmit = async () => {
            if (!!task.description) {
                await set(onSuccessSave);
            }
        }

        const onSuccessSave = (key: string) => {
            toastMessage.success(t(key));
            handleVisibleModalEditTask(false);
        }

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            visibleModalEditTask
        } = useBetween(useModalTask);

        return (
            <ModalMain
                open={visibleModalEditTask}
                onRequestClose={() => handleVisibleModalEditTask(false)}
                width={500}
                renderComponent={
                    <ContainerModalNewResponseStander background={colors.onBackground}>
                        <HeaderModal
                            disabled={loading}
                            letter={'Editando tarefa'}
                            nameIcon={ICONS_DROPDESK.close}
                            onClick={() => handleVisibleModalEditTask(false)}
                        />
                        <ContentModalNewResponseStander>
                            <InputsEditTask
                                task={task}
                                onUpdate={onUpdate}
                                loading={loading}
                            />
                        </ContentModalNewResponseStander>
                        <FooterModal
                            disabled={loading || !task.description}
                            loading={loading}
                            type={"submit"}
                            letter={'Salvar'}
                            onSubmit={handleSubmit}
                            onRequestClose={() => handleVisibleModalEditTask(false)}
                            createdAt={undefined}
                        />
                    </ContainerModalNewResponseStander>}
            />
        )
    })
export default ModalEditTask
;
