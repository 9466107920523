import React, {useState} from "react";
import {Container, ContentSelect, Label, SelectStyled} from "./styled";
import {SelectSearchMainProps} from "@dropDesk/presentation/components/inputs/select_search_main/select_search";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {removeDiacritics, removeSpecialChars} from "@dropDesk/utils/helpers/string_helper";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import LabelInput from "@dropDesk/presentation/components/inputs/input_with_border/label";
import LeftIcon from "@dropDesk/presentation/components/inputs/input_with_border/left_icon";
import MessageError from "@dropDesk/presentation/components/inputs/input_with_border/message_error";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";
import {Tooltip} from "antd";


export default function SelectSearchMain<T>(props: SelectSearchMainProps<T>) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container
            marginLeft={props.marginLeft ?? 0}
            marginTop={props.marginTop ?? 0}
            marginBottom={props.marginBottom ?? 0}
            useSideLabel={props.useSideLabel ?? false}
            style={{
                maxWidth: props.width ?? '100%',
                width: props.width ?? '100%',
            }}
        >

            {!!props.label &&

                <Tooltip title={props.textTooltip}>
                    <div>
                        <LabelInput required={props.required} label={props.label}/>
                    </div>
                </Tooltip>
            }

            <ContentSelect
                borderColor={colors.border}
                backgroundColor={props.backgroundColor ?? colors.backgroundInput}
                size={props.size ?? 35}
                disabled={props.disabled ?? false}
                accent={colors.accent}
            >
                {props.iconLeftName &&
                    <LeftIcon
                        nameIconLeft={props.iconLeftName}
                        disabled={props.disabled}
                    />}
                <div style={{flex: 1, width: '100%'}}>
                    <SelectStyled
                        bordered={false}
                        allowClear={props.useRemoveFilter ?? false}
                        showSearch={props.search ?? false}
                        disabled={props.disabled}
                        value={!!props.value ? props.value : null}
                        placeholder={props.placeHolder}
                        optionFilterProp="children"
                        onChange={(value) => props.onChange(value as T)}
                        style={
                            {
                                width: props.width ?? '100%',
                                borderColor: colors.border,
                                backgroundColor: 'transparent',
                                borderRadius: 5,
                                fontSize: ConstantsStyles.inputFontSize,
                                color: colors.text
                            }}
                        getPopupContainer={(trigger) => trigger}
                        notFoundContent={(
                            <span
                                style={{
                                    color: colors.text,
                                    fontSize: ConstantsStyles.inputFontSize
                                }}>Nenhum resultado encontrado na pesquisa.</span>)}
                        filterOption={(input, option) => {
                            if (!option) {
                                return false;
                            }
                            return (removeSpecialChars(removeDiacritics(option.label.toLowerCase()))).includes((removeSpecialChars(removeDiacritics(input.toLowerCase()))))
                        }}
                        filterSort={(optionA, optionB) => props.removeSort ? undefined :
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        suffixIcon={(
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.downArrow}
                            />
                        )}
                        options={props.options}
                        color={colors.text}
                        placeholdercolor={colors.hint}
                        focuscolor={colors.text}
                        background={colors.backgroundInput}
                        backgroundonprimary={colors.delicateBackground}
                        shadowcolor={colors.brightness === Brightness.light ? SharedColors.black : colors.hint}
                        backgroundmainbutton={colors.accent}
                    />


                </div>
            </ContentSelect>
            {(!!props.messageError || props.messageError === "") && <MessageError messageError={props.messageError}/>}
        </Container>
    );
}
