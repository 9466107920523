import {observer} from "mobx-react";
import {
    ContentData,
    ContentDataInput,
    ContentInput,
    ContentJuridicalNature,
    RadioButtonLeft,
    RadioButtonRight,
    SubContentInputs,
    ContentImage, SubContentInputAndImage
} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {ClientJuridicalNature} from "@dropDesk/domain/entities/client/client_enum";
import {
    useValidatorInputClients
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_clients/validator";
import {getPhoneValue, isValidCNPJ,} from "@dropDesk/utils/helpers/validators";
import {applyUnderscoreMask, removeMask, removeMaskStaticTelephoneNumber} from "@dropDesk/utils/helpers/string_helper";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import ImageProfile from "@dropDesk/presentation/components/drop_zone/pick_image_profile";
import {Crop} from "@dropDesk/presentation/components/crop";
import React, {useState} from "react";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";

import InputPhoneNumber from "@dropDesk/presentation/components/inputs/input_phone_number";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const InputsFormClients = observer(
    ({
         client,
         onUpdate,
         loading,
         onSearchCNPJ,
         createObjectURLBlobUseCase,
         clearUrlImage,
     }: {
        client?: ClientEntity,
        onUpdate: (client: ClientEntity) => void,
        onSearchCNPJ: (document: string) => void,
        loading: boolean,
        createObjectURLBlobUseCase: (file: File) => string,
        clearUrlImage: () => void,
    }) => {

        const [imageSourceCrop, setImageSourceCrop] = useState<string | null>(null);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            errorMessageSocialName,
            errorMessageName,
            errorMessageEmail,
            errorMessageCNPJ,
            errorMessageCPF,
            errorMessageTelephone,
        } = useValidatorInputClients(client);

        const handleChangeImage = (files: File[] | []): void => {
            if (files && files.length > 0) {
                const path = createObjectURLBlobUseCase(files[0]);
                setImageSourceCrop(path);
            } else {
                toastMessage.error("Error ao Anexar, Selecione uma imagem com no máximo 1MB");
            }
        };

        const removeProfileImage = (): void => {
            setImageSourceCrop(null);
            clearUrlImage();
        };


        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string }
        }, file?: File) => {

            const _client = client!.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ?
                        event.target.checked : event.target.name === "telephone" ?
                            removeMaskStaticTelephoneNumber(event.target.value.replace(/\D/g, "")) :
                            event.target.name === "urlImageProfile" && file ? createObjectURLBlobUseCase(file)
                                : event.target.name === "document" ? removeMask(event.target.value.replace(/\D/g, "")) : event.target.value,
                replaceDocumentIfNull: event.target.name === "document",
                replaceFriendlyNameIfNull: event.target.name === "friendlyName",
                replaceEmailIfNull: event.target.name === "email",
                replaceTelephoneIfNull: event.target.name === "telephone",
                replaceUrlImageProfileIfNull: event.target.name === "urlImageProfile" && !!file,
            });
            onUpdate(_client);

        };

        return (
            <SubContentInputs>
                <SubContentInputAndImage>
                    <ContentData>
                        {client?.juridicalNature && <ContentDataInput>
                            <ContentInput>
                                <ContentJuridicalNature color={colors.border}>
                                    <RadioButtonLeft
                                        background={client.juridicalNature === ClientJuridicalNature.juridical ? colors.accent : colors.onBackground}
                                        color={client.juridicalNature === ClientJuridicalNature.juridical ? SharedColors.white : colors.text}
                                        value={client.juridicalNature}
                                        onClick={(event) => {
                                            handleAllChanges({
                                                target:
                                                    {
                                                        name: 'juridicalNature',
                                                        value: ClientJuridicalNature.juridical,
                                                        checked: false,
                                                        type: 'input'
                                                    }
                                            });
                                        }}
                                    >
                                        Jurídica
                                    </RadioButtonLeft>
                                    <RadioButtonRight
                                        background={client.juridicalNature === ClientJuridicalNature.physical ? colors.accent : colors.onBackground}
                                        color={client.juridicalNature === ClientJuridicalNature.physical ? SharedColors.white : colors.text}
                                        onClick={(event) => {
                                            handleAllChanges({
                                                target:
                                                    {
                                                        name: 'juridicalNature',
                                                        value: ClientJuridicalNature.physical,
                                                        checked: false,
                                                        type: 'input'
                                                    }
                                            });
                                        }}
                                    >
                                        Física
                                    </RadioButtonRight>
                                </ContentJuridicalNature>
                                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                    {client.juridicalNature === ClientJuridicalNature.physical &&
                                        <InputWithBorder
                                            mask={'cpf'}
                                            autoFocus={true}
                                            name='document'
                                            size={35}
                                            disabled={loading}
                                            removeMaskOnCopy={true}
                                            messageError={errorMessageCPF}
                                            placeHolder={'000.000.000-00'}
                                            label={"CPF"}
                                            //value={applyUnderscoreMask('___.___.___-__', client.document ?? '')}
                                            value={client.document ?? ''}
                                            onChange={handleAllChanges}
                                        />
                                    }
                                    {client.juridicalNature === ClientJuridicalNature.juridical &&
                                        <InputWithBorder
                                            mask={'cnpj'}
                                            autoFocus={true}
                                            name='document'
                                            size={35}
                                            disabled={loading}
                                            messageError={errorMessageCNPJ}
                                            label={"CNPJ"}
                                            removeMaskOnCopy={true}
                                            useButtonRight={true}
                                            onClickButtonRight={() => onSearchCNPJ(client.document ?? '')}
                                            labelButtonRight={'Buscar'}
                                            nameIconButtonRight={ICONS_DROPDESK.search}
                                            placeHolder={'00.000.000/0000.00'}
                                            tooltipButtonRight={isValidCNPJ(client.document ?? '') ? '' : 'Informe um CNPJ válido para busca'}
                                            disabledButtonRight={!isValidCNPJ(client.document ?? '')}
                                            //value={applyUnderscoreMask('__.___.___/____-__',client.document ?? '')}
                                            value={client.document ?? ''}
                                            onChange={handleAllChanges}
                                        />
                                    }
                                </div>
                            </ContentInput>

                            <ContentInput style={{marginLeft: 20}}>
                                {client.juridicalNature === ClientJuridicalNature.juridical && <InputWithBorder
                                    required={true}
                                    name='socialName'
                                    size={35}
                                    disabled={loading}
                                    messageError={errorMessageSocialName}
                                    label={"Razão Social"}
                                    value={client.socialName}
                                    onChange={handleAllChanges}
                                />}
                            </ContentInput>

                        </ContentDataInput>
                        }
                        <ContentDataInput>

                            <ContentInput style={{marginRight: 20}}>
                                <InputWithBorder
                                    required={true}
                                    name='name'
                                    size={35}
                                    disabled={loading}
                                    messageError={errorMessageName}
                                    label={client?.juridicalNature === ClientJuridicalNature.juridical ? "Nome Fantasia" : "Nome"}
                                    value={client?.name ?? ''}
                                    onChange={handleAllChanges}
                                />
                            </ContentInput>
                            <ContentInput style={{marginBottom: 25}}>
                                <InputWithBorder
                                    name='friendlyName'
                                    size={35}
                                    disabled={loading}
                                    label={"Nome Amigável"}
                                    value={client?.friendlyName ?? ''}
                                    onChange={handleAllChanges}
                                />
                            </ContentInput>


                        </ContentDataInput>
                        <ContentDataInput>
                            <ContentInput style={{marginRight: 20}}>
                                <InputWithBorder
                                    name='email'
                                    size={35}
                                    disabled={loading}
                                    messageError={errorMessageEmail}
                                    label={"Email"}
                                    value={client?.email ?? ''}
                                    onChange={handleAllChanges}
                                />
                            </ContentInput>
                            <ContentInput>
                                <InputPhoneNumber
                                    name='telephone'
                                    size={35}
                                    disabled={loading}
                                    messageError={errorMessageTelephone}
                                    label={"Telefone"}
                                    value={getPhoneValue(client?.telephone)}
                                    onChange={(value) => {
                                        handleAllChanges({
                                            target:
                                                {
                                                    name: 'telephone',
                                                    value: value ?? '',
                                                    checked: false,
                                                    type: 'input'
                                                }
                                        });
                                    }}
                                />
                            </ContentInput>
                        </ContentDataInput>

                    </ContentData>
                    <ContentImage>
                        <ImageProfile
                            useGarbage={true}
                            radiusImage={false}
                            sizeImage={142}
                            onPick={handleChangeImage}
                            name={''}
                            urlImageProfile={client?.urlImageProfile ?? ''}
                            onRemoveProfileImage={removeProfileImage}
                            marginTopButtonChangeImage={47}
                        />
                        {imageSourceCrop ? (
                            <Crop
                                image={imageSourceCrop}
                                onRequestCrop={(file: File) => {
                                    handleAllChanges({
                                        target:
                                            {
                                                name: 'urlImageProfile',
                                                value: null,
                                                checked: false,
                                                type: 'input'
                                            }
                                    }, file);
                                    setImageSourceCrop(null);
                                }}
                                onRequestClose={() => setImageSourceCrop(null)}
                            />
                        ) : null}
                    </ContentImage>
                </SubContentInputAndImage>
                <ContentInput style={{height: 40}}>
                    <InputWithBorder
                        name='note'
                        size={35}
                        disabled={loading}
                        messageError={''}
                        label={"Observação"}
                        value={client?.note ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentInput>
            </SubContentInputs>

        )
    })
export default InputsFormClients;
