import styled from "styled-components";

type TextType = {
  color: string;
}

type BackgroundType = {
  background: string;
  bordercolor: string;
  boxshadowcolor: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.span<TextType>`
  font-size: 16px;
  color: ${props => props.color};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;
export const ShapeColor = styled.div<BackgroundType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 10px;
  border-radius: 50%;
  background: ${props => props.background};
  border: 3px solid ${props => props.bordercolor};
  cursor: pointer;
  box-shadow: 1px ${props => props.boxshadowcolor};
`;


