import React, {useEffect} from "react";
import ModalSearch from "@dropDesk/presentation/components/modals/modal_search";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import {
    ModalSearchTicketDescriptionStaticType
} from "@dropDesk/presentation/components/modals/modal_search_ticket_description_static/modal_search_ticket_description_static";
import {
    TicketsDescriptionStaticController
} from "@dropDesk/presentation/pages/ticket_description_static/controller/ticket_description_static.controller";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import Tag from "@dropDesk/presentation/components/tag";
import {setBlockUpdateLocalSearchCache} from "@dropDesk/utils/helpers/common";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {useBetween} from "use-between";
import {
    useModalSearchTicketsDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/handle_change_visible";
import ModalCrudTicketsDescriptionStatic
    from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/modal_crud";


const ModalSearchTicketDescriptionStatic = observer((props: ModalSearchTicketDescriptionStaticType) => {

    const ticketsDescriptionStaticController = useInjection(TicketsDescriptionStaticController);
    const appController = useInjection(AppController);
    const {setVisibleModalNewTicketsDescriptionStatic} = useBetween(useModalSearchTicketsDescriptionStatic);

    useEffect(() => {
        setBlockUpdateLocalSearchCache(true);
        ticketsDescriptionStaticController.setFilterListableForClients(appController.isUserClient ? 'true' : null);
        onSearch(ticketsDescriptionStaticController.searchParam);
        return () => {
            setBlockUpdateLocalSearchCache(false);
        }
    }, []);

    const handleVisibleModalDescriptionStatic = (visible: boolean, id: string) => {
        if (visible) {
            ticketsDescriptionStaticController.makeTicketDescriptionStatic();
        } else {
            onSearch(ticketsDescriptionStaticController.searchParam);
        }
        setVisibleModalNewTicketsDescriptionStatic(visible);
    }

    const onSearch = (searchParam: string): void => {
        ticketsDescriptionStaticController.list(searchParam ?? ticketsDescriptionStaticController.searchParam);
    }

    const tableColumnDescriptionStatic = [
        {
            title: 'Descrição do problema',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
            render: (text: string, record: TicketsDescriptionStaticEntity) => (
                <HighLightWords
                    searchWords={ticketsDescriptionStaticController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Listável para clientes',
            key: 'id',
            dataIndex: 'listableForClients',
            render: (text: string, record: TicketsDescriptionStaticEntity) => {
                return (
                    <Tag
                        label={!record.listableForClients ? 'Não' : 'Sim'}
                        width={80}
                    />
                )
            }
        },

        {
            title: 'Criado em', ellipsis: true, dataIndex: 'createdAt', key: 'id', render: (text: string) => (
                <span>{displayDateToLocale(text)}</span>
            ),
        },
    ];

    return (
        <>
            <ModalSearch<TicketsDescriptionStaticEntity>
                inputSearchPlaceholder={props.inputSearchPlaceholder}
                tableColumns={appController.isUserClient ? tableColumnDescriptionStatic.filter(entry => entry.key === 'description') : tableColumnDescriptionStatic}
                open={props.visible}
                onSearch={(value) => {
                    onSearch(value);
                }}
                onSelectedRow={(ticketsDescriptionStatic: TicketsDescriptionStaticEntity) => {
                    props.handleSelectTicketDescriptionStatic(ticketsDescriptionStatic);
                }}
                inputValue={ticketsDescriptionStaticController.searchParam}
                textHeaderModal={props.textHeaderModal}
                loading={ticketsDescriptionStaticController.loading}
                onRequestClose={props.onClose}
                tableDataSource={ticketsDescriptionStaticController.tableTicketsDescriptionStatic.data}
                defaultCurrentPage={1}
                totalRows={ticketsDescriptionStaticController.tableTicketsDescriptionStatic.totalRows}
                page={ticketsDescriptionStaticController.tableTicketsDescriptionStatic.page}
                pageSize={ticketsDescriptionStaticController.tableTicketsDescriptionStatic.limit}
                onChange={(page) => ticketsDescriptionStaticController.getDataFromPage(page - 1)}
                labelNoResults={props.labelNoResults}
                useFooter={props.useFooter}
                labelFooter={props.labelFooter}
                onSave={() => {
                    handleVisibleModalDescriptionStatic(true, 'new');
                }}
            />

            <ModalCrudTicketsDescriptionStatic
                onUpdate={(descriptionStatic) => ticketsDescriptionStaticController.setTicketsDescriptionStatic(descriptionStatic)}
                ticketsDescriptionStatic={ticketsDescriptionStaticController.ticketsDescriptionStatic}
                loading={ticketsDescriptionStaticController.loading}
                handleVisibleModalNewTicketsDescriptionStatic={handleVisibleModalDescriptionStatic}
                set={ticketsDescriptionStaticController.set}
            />
        </>
    )
});
export default ModalSearchTicketDescriptionStatic;
