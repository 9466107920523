import {Tabs} from "antd";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import styled from "styled-components";


type TabStyledType = {
    color: string;
    hint: string;
    border: string;
    focusedcolor: string;
    background: string;
    backgrounddropdown: string;
    colordropdown: string;
    usemargin?: string;
}

export const TabsStyled = styled(Tabs).attrs({})<TabStyledType>`

  &.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    color: ${props => props.hint};
  }

  .anticon {
    color: ${props => props.color};
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: ${props => props.usemargin === 'true' ? `0 0 0 32px !important` : `0px !important`};
  }

  &.ant-tabs-top > .ant-tabs-nav {
    background: ${props => props.background};
  }


  &.ant-tabs-top > .ant-tabs-nav::before {
    border-color: ${props => props.border};
  }

  &.ant-tabs-top > .ant-tabs-nav {
    // border: none !important;
  }


  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => props.color} !important;
    border-radius: 5px !important;

    i {
      color: ${props => props.color};
    }
  }


  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: ${props => props.color} !important;
  }

  .ant-tabs-tab:hover {
    color: ${props => props.focusedcolor} !important;
      //background: ${hexToRGBA(SharedColors.white, 0.20)};
    border-radius: 5px;
  }

  .ant-tabs-ink-bar {
    background-color: ${props => props.color};
  }

  .ant-tabs-dropdown-menu {
    background: ${props => props.backgrounddropdown} !important;
    color: ${props => props.colordropdown} !important;

    i {
      color: ${props => props.colordropdown} !important;
    }
  }

  .ant-tabs-dropdown-menu-item {
    background: ${props => props.backgrounddropdown} !important;
    color: ${props => props.colordropdown} !important;

    i {
      color: ${props => props.colordropdown} !important;
    }
  }

  .ant-tabs-dropdown-menu-item:hover {
    //filter: brightness(80%);
    border-radius: 5px;
  }
`;
