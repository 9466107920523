import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {Container, ContainerButton, ContentButton, Button} from "./styled";
import React from "react";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {HighLightHashTag} from "@dropDesk/presentation/components/high_light_hashtag";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const HeaderSubscriptionInfo = (observer((
    {
        currentUser,
        onClickClose
    }: {
        currentUser: UserEntity
        onClickClose: () => void
    }) => {

    const navigate = useNavigate();

    return (
        <Container
            background={currentUser.company.newSubs.isOverdue ? SharedColors.errorOnPrimary : SharedColors.info}>
            <HighLightHashTag
                text={currentUser.company.newSubs.textDaysRemaining(currentUser.company.oldSubs)}
                fontSize={15}
                color={currentUser.company.newSubs.isOverdue ? SharedColors.white : SharedColors.black}
            />
            <ContainerButton>
                <ContentButton
                    onClick={() => navigate(`${RoutesEnum.subscription}`)}
                    size={15}
                    color={currentUser.company.newSubs.isOverdue ? SharedColors.white : SharedColors.black}
                    background={currentUser.company.newSubs.isOverdue ? SharedColors.errorOnPrimary : SharedColors.info}
                >
                    {currentUser.company.newSubs.isFree ? 'Assinar' : 'Verificar'}
                </ContentButton>
                <Button onClick={() => {
                    onClickClose();
                }}>
                    <DropDeskIcon icon={ICONS_DROPDESK.close} size={15}
                                  color={currentUser.company.newSubs.isOverdue ? SharedColors.white : SharedColors.black}/>
                </Button>
            </ContainerButton>
        </Container>
    )
}));
export default HeaderSubscriptionInfo;
