import {observer} from "mobx-react";
import React from "react";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {useModalUsers} from "@dropDesk/presentation/pages/user/ui/form/modals/handle_change_visible";
import {useBetween} from "use-between";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsFormUsers = observer(
    ({
         onDeleteUser,
         changePermissionAdmin,
         user,
         loading,
         forgot,
         activateAccount,
         handleAllChanges
     }: {

        forgot: () => Promise<void>,
        activateAccount: () => Promise<void>,
        onDeleteUser: () => Promise<void>,
        changePermissionAdmin: (value: boolean) => void,
        user?: UserEntity,
        loading: boolean,
        handleAllChanges: (event: {
            target: { name: string; value: any; checked: any; type: string };
        }) => void
    }) => {

        const {
            visibleModalDelete,
            setVisibleModalDelete,
            visibleModalAdmin,
            setVisibleModalAdmin,
            visibleModalClients,
            setVisibleModalClients,
            visibleModalUsers,
            setVisibleModalUsers,
            visibleModalSectors,
            setVisibleModalSectors,
            visibleModalWarnings,
            setVisibleModalWarnings,
            visibleModalCancelTickets,
            setVisibleModalCancelTickets,
            visibleModalReports,
            setVisibleModalReports,
            visibleModalDeleteData,
            setVisibleModalDeleteData,
            visibleModalActivateAccount, setVisibleModalActivateAccount,
            visibleModalRememberPassword, setVisibleModalRememberPassword,
            visibleModalListJustTicket, setVisibleModalListJustTicket
        } = useBetween(useModalUsers);


        return (
            <>
                <ModalInformation
                    open={visibleModalActivateAccount}
                    onRequestClose={() => {
                        setVisibleModalActivateAccount(false);
                    }}
                    textHeaderModal={'Ativar conta'}
                    textButton={'Enviar'}
                    onRequestSave={async () => {
                        await activateAccount();
                        setVisibleModalActivateAccount(false);
                    }}
                    nameIcon={ICONS_DROPDESK.mail}
                    sizeIcon={130}
                    textInformation={`Deseja realmente enviar um email para ${user?.email} com a instrução de ativação de conta?`}
                    loading={loading}
                />

                <ModalInformation
                    open={visibleModalRememberPassword}
                    onRequestClose={() => {
                        setVisibleModalRememberPassword(false);
                    }}
                    textHeaderModal={'Recuperar senha'}
                    textButton={'Enviar'}
                    onRequestSave={async () => {
                        await forgot();
                        setVisibleModalRememberPassword(false);
                    }}
                    nameIcon={ICONS_DROPDESK.mail}
                    sizeIcon={130}
                    textInformation={`Deseja realmente enviar um email para ${user?.email} com a instrução de recuperação de senha?`}
                    loading={loading}
                />

                <ModalInformation
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        setVisibleModalDelete(false)
                    }}
                    textHeaderModal={'Excluir Atendente'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        onDeleteUser();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={120}
                    textInformation={`A exclusão do atendente ${user?.name} não apaga seus atendimentos, avaliações e nenhuma outra ação já feita por ele, apenas o atendente é excluído não sendo mais listado e sem acesso ao painel do atendente.`}
                    loading={loading}
                    isActionDelete={true}
                />
                <ModalInformation
                    open={visibleModalAdmin}
                    onRequestClose={() => {
                        setVisibleModalAdmin(false)
                    }}
                    textHeaderModal={'Permissões: Administrador'}
                    textButton={'Permitir'}
                    onRequestSave={() => {
                        changePermissionAdmin(true)
                        setVisibleModalAdmin(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Ao tornar ${user?.name} um administrador, ele terá acesso irrestrito ao DropDesk.`}
                    loading={loading}
                />
                <ModalInformation
                    open={visibleModalUsers}
                    onRequestClose={() => {
                        setVisibleModalUsers(false)
                    }}
                    textHeaderModal={'Permissões: Atendentes'}
                    textButton={'Permitir'}
                    onRequestSave={() => {
                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'permissionScreenUsers',
                                        value: true,
                                        checked: true,
                                        type: 'switch'
                                    }
                            });
                        setVisibleModalUsers(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Esta opção permite ${user?.name} cadastrar/alterar e excluir atendentes.`}
                    loading={loading}
                />
                <ModalInformation
                    open={visibleModalClients}
                    onRequestClose={() => {
                        setVisibleModalClients(false)
                    }}
                    textHeaderModal={'Permissões: Clientes'}
                    textButton={'Permitir'}
                    onRequestSave={() => {

                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'permissionScreenClients',
                                        value: true,
                                        checked: true,
                                        type: 'switch'
                                    }
                            });
                        setVisibleModalClients(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Esta opção permite ${user?.name} cadastrar/alterar e excluir clientes.`}
                    loading={loading}
                />
                <ModalInformation
                    open={visibleModalSectors}
                    onRequestClose={() => {
                        setVisibleModalSectors(false)
                    }}
                    textHeaderModal={'Permissões: Setores'}
                    textButton={'Permitir'}
                    onRequestSave={() => {

                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'permissionScreenSectors',
                                        value: true,
                                        checked: true,
                                        type: 'switch'
                                    }
                            });

                        setVisibleModalSectors(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Esta opção permite ${user?.name} cadastrar/alterar e excluir setores.`}
                    loading={loading}
                />
                <ModalInformation
                    open={visibleModalWarnings}
                    onRequestClose={() => {
                        setVisibleModalWarnings(false)
                    }}
                    textHeaderModal={'Permissões: Avisos'}
                    textButton={'Permitir'}
                    onRequestSave={() => {

                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'permissionScreenWarnings',
                                        value: true,
                                        checked: true,
                                        type: 'switch'
                                    }
                            });

                        setVisibleModalWarnings(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Esta opção permite ${user?.name} cadastrar/alterar e excluir avisos.`}
                    loading={loading}
                />
                <ModalInformation
                    open={visibleModalCancelTickets}
                    onRequestClose={() => {
                        setVisibleModalCancelTickets(false)
                    }}
                    textHeaderModal={'Permissões: Cancelar Atendimentos'}
                    textButton={'Permitir'}
                    onRequestSave={() => {

                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'permissionCancelTicket',
                                        value: true,
                                        checked: true,
                                        type: 'switch'
                                    }
                            });

                        setVisibleModalCancelTickets(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Esta opção permite ${user?.name} cancelar atendimentos.`}
                    loading={loading}
                />
                <ModalInformation
                    open={visibleModalReports}
                    onRequestClose={() => {
                        setVisibleModalReports(false)
                    }}
                    textHeaderModal={'Permissões: Relatórios'}
                    textButton={'Permitir'}
                    onRequestSave={() => {

                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'permissionScreenReports',
                                        value: true,
                                        checked: true,
                                        type: 'switch'
                                    }
                            });

                        setVisibleModalReports(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Esta opção permite ${user?.name} visualizar qualquer relatório no DropDesk.`}
                    loading={loading}
                />
                <ModalInformation
                    open={visibleModalDeleteData}
                    onRequestClose={() => {
                        setVisibleModalDeleteData(false)
                    }}
                    textHeaderModal={'Permissões: Exclusão de dados'}
                    textButton={'Permitir'}
                    onRequestSave={() => {

                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'permissionDeleteData',
                                        value: true,
                                        checked: true,
                                        type: 'switch'
                                    }
                            });

                        setVisibleModalDeleteData(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Esta opção permite ${user?.name} excluir qualquer dados no DropDesk.`}
                    loading={loading}
                />

                <ModalInformation
                    open={visibleModalListJustTicket}
                    onRequestClose={() => {
                        setVisibleModalListJustTicket(false)
                    }}
                    textHeaderModal={'Limitar Acesso'}
                    textButton={'Limitar'}
                    onRequestSave={() => {
                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'extraInfo.permissionJustListUserTicket',
                                        value: true,
                                        checked: true,
                                        type: 'switch'
                                    }
                            });
                        setVisibleModalListJustTicket(false);
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={120}
                    textInformation={`Ao limitar o acesso, este usuário só poderá visualizar e ler os chamados/conversa que estão atribuídos
                     a ele mesmo. Isso significa que o usuário somente terá acesso aos chamados que ele mesmo criou ou
                      que foram designados diretamente para ele. Os chamados de outros usuários serão ocultados e inacessíveis..`}
                    loading={loading}
                    isActionDelete={true}
                />

            </>
        )
    })
export default ModalsFormUsers;
