import {observer} from "mobx-react";
import {
    Container,
    Content,
} from "./styled";
import React from "react";
import StatusWhatsApp
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/connection/status_whatsapp";
import InputsWhatsApp
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/connection/inputs";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";

const ConnectionWhatsAppConfiguration = observer(
    ({
         companyConfiguration,
         loading,
         chatConnection,
         startConnection,
         handleAllChangesWhatsAppConfiguration
     }: {
        loading: boolean,
        companyConfiguration: CompanyConfigurationEntity
        chatConnection: ChatIntegrationConnectionEntity
        startConnection: () => Promise<void>
        handleAllChangesWhatsAppConfiguration: (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => void
    }) => {


        return (
            <Container>
                <Content>
                    <StatusWhatsApp
                        chatConnection={chatConnection}
                        startConnection={startConnection}
                        loading={loading}
                    />
                    <InputsWhatsApp
                        handleAllChangesWhatsAppConfiguration={handleAllChangesWhatsAppConfiguration}
                        companyConfiguration={companyConfiguration}
                        loading={loading}
                    />
                </Content>

            </Container>
        )
    })
export default ConnectionWhatsAppConfiguration;
