import styled, {keyframes} from 'styled-components';
import {Link,} from 'react-router-dom';
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
}

type ButtonType = {
    background: string;
    color: string;
}

type TextType = {
    color: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex: 1;
  height: 100vh;
  width: 100%;
  background: ${props => props.background};
`;
export const Header = styled.div`
  display: flex;
  height: 60px;
  min-height: 60px;
  width: 100%;
`;

export const ContentLogo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 120px;
  margin: 0 auto;
  max-width: 80%;
  width: 80%;
`;
export const Logo = styled.img`
  display: flex;
  width: 265px;
  pointer-events: none;
`;
export const ImageNotFound = styled.img`
  display: flex;
  height: 215px;
  width: 235px;
  pointer-events: none;
`;
export const ContentImageNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const TextNotFound = styled.span<TextType>`
  font-size: 23px;
  font-weight: bold;
  color: ${props => props.color};
  text-align: center;
  letter-spacing: 1px;
  margin: 8px 0;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContentDescriptions = styled.div<ContainerType>`
  display: flex;
  height: 300px;
  min-height: 300px;
  width: 450px;
  border: 0;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px;
  padding: 16px;
  background: ${props => props.background};
  -webkit-box-shadow: 0px 0px 1px ${props => props.background};
  -moz-box-shadow: 0px 0px 1px ${props => props.background};
  box-shadow: 0px 0px 1px ${props => props.background};
  border-radius: 10px;
`;

export const ButtonBack = styled(Link)<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  min-width: 100%;
  border: 0;
  height: 50px;
  min-height: 50px;
  border-radius: 7px;
  letter-spacing: 1px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.background, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const Title = styled.span<TextType>`
  font-size: 16px;
  color: ${props => props.color};
`;

