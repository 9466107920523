import {Container, Text, Content} from "./styled";
import {HeaderProps} from "@dropDesk/presentation/components/headers/header_configuration/head_configuration";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


export default function HeaderConfiguration(props: HeaderProps) {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Container color={colors.text} background={colors.onBackground}>
            <Content>
                <Text color={colors.text}>{props.letter}</Text>
            </Content>
        </Container>
    );
}
