import {
    Container, ContainerRating, Content, ContentDescription, ContentRating, ContentTitle, Text, TextBold,
} from "./styled";
import {observer} from "mobx-react";
import React from "react";
import {RatingStars} from "@dropDesk/presentation/components/rating_stars";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";

const Body =
    observer((
        {
            newEvaluation,
            loading,
            handleAllChanges
        }: {
            newEvaluation: TicketEvaluationEntity
            loading: boolean
            handleAllChanges: (event: { target: { name: string; value: any; checked?: any; type: string } }) => void
        }
    ) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container background={colors.onBackground}>
                <Content>
                    {/*<ContentTitle>*/}
                    {/*    <TextBold size={16} color={colors.hintInverse}>O que você achou do atendimento?</TextBold>*/}
                    {/*</ContentTitle>*/}
                    <ContainerRating>
                        <ContentRating>
                            <RatingStars
                                readonly={loading}
                                onClick={(value) => {
                                    handleAllChanges({
                                        target:
                                            {
                                                name: 'value',
                                                value: value,
                                                checked: false,
                                                type: 'input'
                                            }
                                    });
                                }}
                                showTooltip={true}
                                tooltipArray={TicketEvaluationEntity.ratingToolTips()}
                                tooltipDefaultText={'Selecione'}
                                size={30}
                            />
                        </ContentRating>
                    </ContainerRating>
                    <ContentDescription>
                        <InputTextAreaWithBorder
                            required={false}
                            autoFocus={false}
                            name='description'
                            size={65}
                            disabled={loading}
                            placeHolder={"Opcional"}
                            label={"Comentário"}
                            value={newEvaluation.description ?? ''}
                            onChange={handleAllChanges}
                        />
                    </ContentDescription>
                </Content>
            </Container>
        )
    });
export default Body;
