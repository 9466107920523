import {ButtonAddNew, ContentIcon, ContentText} from './styled'
import {ButtonNewType} from "@dropDesk/presentation/components/buttons/button_new/button_new";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";
import {observer} from "mobx-react";


const ButtonNew = observer((props: ButtonNewType) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const _useIcon: boolean = props.useIcon === undefined ? true : props.useIcon;
    const background = props.isButtonMain ? colors.accent : props.background ?? colors.onBackground;
    const letterColor = !!props.color ? props.color : props.isButtonMain ? SharedColors.white : props.isDeleted ? colors.textError : colors.text;
    const realRadius = props.radius ?? 4;

    return (
        <ButtonAddNew
            disabled={props.disabled}
            marginLeft={props.marginLeft ?? 0}
            onClick={props.onClick}
            background={background}
            size={props.size ?? 37}
            colorLetterButton={letterColor}
            isButtonMain={props.isButtonMain}
            widthButton={props.widthButton}
            border={props.isDeleted ? colors.textError : colors.border}
            accent={props.isDeleted ? colors.textError : colors.accent}
            realRadius={realRadius}
            zIndex={props.zIndex}
            focusBorder={props.focusBorder}
            style={props.style}
        >
            {_useIcon &&
                <ContentIcon background={background}>
                    <DropDeskIcon
                        icon={props.nameLeftIcon ? props.nameLeftIcon : ICONS_DROPDESK.add}
                        color={letterColor}
                        size={props.sizeLeftIcon ?? 18}
                    />
                </ContentIcon>}
            <ContentText background={background}>
                <span style={{
                    color: letterColor,
                    fontSize: ConstantsStyles.buttonSaveFontSize,
                    letterSpacing: 1,
                    fontWeight: 'bold',
                }}>{props.label}</span>
            </ContentText>
        </ButtonAddNew>
    );
});
export default ButtonNew;
