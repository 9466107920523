import styled from "styled-components";

type ContainerType = {
    background: string;
    border: string;
    width?: number
}

type TextType = {
    color: string;
}

export const Container = styled.div<ContainerType>`
  background: ${(props) => props.background};
  padding: 1px 7px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(props) => props.width ? `${props.width}px` : '100px'};
  max-width: ${(props) => props.width ? `${props.width}px` : '100px'};
  border: 1px solid ${props => props.border}
`;

export const TextDescription = styled.span<TextType>`
  color: ${(props) => props.color};
  font-size: 13px;
  font-weight: bold;
  letter-spacing: .6px;
`;
