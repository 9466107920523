import {LinkFyType} from "@dropDesk/presentation/components/linkify/link_fy";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {isValidEmail, isValidURL} from "@dropDesk/utils/helpers/validators";


const LinkFy = observer((props: LinkFyType) => {

    const REGEX = /((\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\bwww\.[\S]+(\b|$))|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}\b))/ig;
    const codelinkdropdesk = 'codelinkidentifier';
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const isInvalidPrefix = (link: string): boolean => {
        return !isValidURL(link) && !isValidEmail(link)
    }

    const getRealHref = (link: string) => {
        if (isInvalidPrefix(link)) {
            return `http://${link}`;
        }
        if (isValidEmail(link)) {
            return `mailto:${link}`;
        }
        return link;
    }

    const linkify = (paragraph: string): React.ReactElement => {

        const _paragraph = formatLink(paragraph);
        const paragraphArray = _paragraph.split(codelinkdropdesk).filter(entry => !!entry);
        return (
            <span style={{color: props.color ?? colors.text}}>
                {paragraphArray.map((word: string, index) => {
                    if (word.match(REGEX)) {
                        return (
                            <span key={index} style={{color: props.color ?? colors.text}}>
                             <a href={getRealHref(word)}
                                style={{color: SharedColors.textLink, textDecoration: 'underline'}}
                                target="_blank">{word}</a>
                    </span>
                        )
                    } else {
                        return word + '';
                    }
                })}
      </span>
        );

    };

    const formatLink = (text: string): string => {
        return text.replace(REGEX, (url): string => {
            return `${codelinkdropdesk}${url}${codelinkdropdesk}`;
        });
    }


    return linkify(props.text);
});
export default LinkFy;

