import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Container,
    TextSound
} from "./styled";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {Tooltip} from "antd";


const SoundConfiguration = observer((
    {
        text,
        useSound,
        setSound
    }: {
        text: string
        useSound: boolean
        setSound: (value: boolean) => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Container
            onClick={(event) => {
                event.stopPropagation();
                setSound(!useSound);
            }}
        >
            <Tooltip title={text} placement={'bottom'}>
                {useSound && <DropDeskIcon
                    size={13}
                    icon={ICONS_DROPDESK.speaker}
                    removeColor={true}
                    style={{cursor: 'pointer', marginRight: 5}}
                />}
                {!useSound &&
                    <DropDeskIcon
                        size={13} icon={ICONS_DROPDESK.mute}
                        color={colors.textError}
                        style={{cursor: 'pointer', marginRight: 5}}/>
                }
            </Tooltip>
        </Container>
    )
});
export default SoundConfiguration;
