import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ButtonType = {
    color: string;
    background: string;
    accent: string;
    size: number;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;


export const Button = styled.button<ButtonType>`
  display: flex;
  flex-direction: column;
  padding: 6px;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
  font-weight: bold;
  width: 100px;
  min-width: 100px;
  height: 80px;
  min-height: 80px;
  border-radius: 5px;
  border: 1px solid ${props => props.accent};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
