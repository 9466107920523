import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ResponseStandardRepository} from "@dropDesk/domain/repositories/response_standard/response_standard.repository";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";

@injectable()
export class ListResponseStandardUseCase {
  private _repository: ResponseStandardRepository;

  constructor(@inject(ResponseStandardRepository) repository: ResponseStandardRepository) {
    this._repository = repository;
  }

  public call(page: number, searchParam: string, limit: number,listOnlyDeleted:boolean): Promise<ListPaginationEntity<ResponseStandardEntity>> {
    return this._repository.list(page, searchParam, limit,listOnlyDeleted);
  }
}

