import {observer} from "mobx-react";
import {
    ContentExportImport, ContentFilter, ContentInput,
    ContentInputAndListableClientDeleted
} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useNavigate} from "react-router-dom";
import {useBetween} from "use-between";
import {useModalClients} from "@dropDesk/presentation/pages/client/ui/my_clients/search/modals/visible_modal_search";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ContentInputSearch} from "@dropDesk/presentation/pages/ticket/ui/search/search_bar/styled";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const SearchBar = observer(
    ({
         onSearch,
         visibleInputSearch,
         visibleButtonsOfActions,
         visibleButtonDeleted,
         visibleButtonRestore,
         loading,
     }: {
        onSearch: (searchParam: string) => void,
        visibleInputSearch: boolean,
        visibleButtonsOfActions: boolean,
        visibleButtonDeleted: boolean,
        visibleButtonRestore: boolean,
        loading: boolean
    }) => {

        const navigate = useNavigate();
        const isNewRegister = 'new';
        const appController = useInjection(AppController);
        const {
            setVisibleModalRestore,
            setVisibleModalDelete,
            setVisibleModalExport

        } = useBetween(useModalClients);


        return (
            <ContentFilter>
                <ContentInputAndListableClientDeleted>
                    <ContentInput>
                        {visibleInputSearch &&
                            <ContentInputSearch>
                                <InputSearchGeneric
                                    inputSearchPlaceholder="Pesquise por todos as empresas"
                                    loading={loading}
                                    onSearch={(value) => onSearch(value)}
                                />
                            </ContentInputSearch>}
                        {visibleButtonDeleted && <ButtonNew
                            marginLeft={0}
                            label={'Excluir'}
                            onClick={() => setVisibleModalDelete(true)}
                            nameLeftIcon={ICONS_DROPDESK.delete}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}
                        {visibleButtonRestore && <ButtonNew
                            marginLeft={0}
                            label={'Restaurar'}
                            onClick={() => setVisibleModalRestore(true)}
                            nameLeftIcon={ICONS_DROPDESK.restore}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}

                    </ContentInput>

                </ContentInputAndListableClientDeleted>

                <ContentExportImport>
                    {visibleButtonsOfActions && <ButtonNew
                        marginLeft={0}
                        isButtonMain={true}
                        label={'Nova Empresa'}
                        onClick={() => navigate(`${RoutesEnum.clients}/${isNewRegister}`)}
                        nameLeftIcon={''}
                        sizeLeftIcon={16}
                        disabled={false}
                    />}

                    {visibleButtonsOfActions && appController.user?.permissionAdmin && <ButtonNew
                        marginLeft={10}
                        label={'Exportar'}
                        onClick={() => {
                            setVisibleModalExport(true);
                        }}
                        nameLeftIcon={ICONS_DROPDESK.excel}
                        sizeLeftIcon={15}
                        disabled={loading}
                    />}

                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBar;
