import styled from "styled-components";

export const ContentExportImport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const ContentFilter = styled.div`
  display: flex;
  height: 61px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  margin-top: 1px;
`;
export const ContentInput = styled.div`
  display: flex;
  flex: 1;
`;
export const ContentInputAndListableUserDeleted = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
