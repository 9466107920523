import {useState} from "react";

export const useModalTask = () => {

    const [visibleModalDelete, setVisibleModalDelete] = useState<{ visible: boolean, id: string }>(
        {visible: false, id: ''}
    );

    const [visibleModalEditTask, setVisibleModalEditTask] = useState<boolean>(false);


    return {
        visibleModalDelete,
        setVisibleModalDelete,
        visibleModalEditTask,
        setVisibleModalEditTask
    }
}
