import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {useModalViewTickets} from "@dropDesk/presentation/components/chat_view/modals/visible_modal_view_ticket";
import ModalDrawer from "@dropDesk/presentation/components/modals/modal_drawer";
import InputsMoreInformation from "@dropDesk/presentation/components/chat_view/modals/inputs_more_information";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import ModalTimeSpent from "@dropDesk/presentation/components/modals/modal_time_spent";
import ModalPriority from "@dropDesk/presentation/components/modals/modal_priority";
import ModalTicketClosed from "@dropDesk/presentation/components/modals/modal_closed_ticket";
import ModalCancellationTicket from "@dropDesk/presentation/components/modals/modal_cancellation_ticket";
import TimeLineActivities from "@dropDesk/presentation/components/chat_view/modals/timeline_activities";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import ModalTransferTicket from "@dropDesk/presentation/components/modals/modal_transfer_ticket";
import {timeSpentMap} from "@dropDesk/domain/entities/ticket/ticket_maps";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ContentFormCreate} from "@dropDesk/presentation/pages/ticket/ui/form_create/modals/styled";
import FormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form_client";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import ModalChangeProblemDescription from "@dropDesk/presentation/components/modals/modal_change_problem_description";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import ModalChangeDateDue from "@dropDesk/presentation/components/modals/modal_change_date_due";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import TicketRating from "@dropDesk/presentation/components/chat_view/modals/ticket_rating";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsViewTicket = observer((
    {
        handleTransferTicket,
        handleChangeTimeSpent,
        handleChangePriority,
        handleTicketClosed,
        handleCancellationTicket,
        informCancellationDescription,
        informSolutionDescription,
        loading,
        ticket,
        userLogged,
        listBySectorsByUserLinkedInTransferTicket,
        handleChangeClient,
        handleChangeDescription,
        useProblemStatic,
        handleChangeDateDue,
        attendChat,
        exportPdf,
        currentTicket,
        ticketEvaluation,
        setTicketEvaluation,
        loadingEvaluation,
        loadingMessageEvaluation,
        makeEvaluation,
        changeResolution,
        handleEvaluate
    }: {

        handleTransferTicket: (sector: SectorEntity, message: TicketMessageEntity, user?: UserEntity,) => Promise<void>,
        handleChangeTimeSpent: (timeSpent: number) => void,
        handleChangePriority: (priority: string) => void,
        handleTicketClosed: (description?: string) => void,
        handleCancellationTicket: (description?: string) => void,
        loading: boolean,
        informCancellationDescription: boolean
        informSolutionDescription: boolean
        useProblemStatic: boolean
        ticket?: TicketEntity
        listBySectorsByUserLinkedInTransferTicket?: string
        userLogged: UserEntity;
        handleChangeClient: (client: ClientEntity) => void
        handleChangeDescription: (description?: string, descriptionStatic?: TicketsDescriptionStaticEntity) => void
        handleChangeDateDue: (dateDue: string) => void
        attendChat: () => Promise<void>
        exportPdf: () => Promise<void>
        currentTicket: TicketEntity
        ticketEvaluation?: TicketEvaluationEntity
        setTicketEvaluation: (ticketEvaluation: TicketEvaluationEntity) => void
        loadingEvaluation: boolean
        loadingMessageEvaluation?: string | null
        makeEvaluation: () => void
        handleEvaluate: () => void
        changeResolution: (description: string) => void;
    }) => {

    const appController = useInjection(AppController);

    const {
        visibleModalMoreInformation, setVisibleModalMoreInformation,
        visibleModalTransferTicket, setVisibleModalTransferTicket,
        visibleModalTimeSpent, setVisibleModalTimeSpent,
        visibleModalPriority, setVisibleModalPriority,
        visibleModalCancellationTicket, setVisibleModalCancellationTicket,
        visibleModalTicketClosed, setVisibleModalTicketClosed,
        visibleModalActivities, setVisibleModalActivities,
        visibleModalCreateClient, setVisibleModalCreateClient,
        visibleModalChangeProblemDescription, setVisibleModalChangeProblemDescription,
        visibleModalChangeDateDue, setVisibleModalChangeDateDue,
        visibleModalAttendChat, setVisibleModalAttendChat,
        visibleModalExportPdf, setVisibleModalExportPdf,
        visibleModalTicketRating, setVisibleModalTicketRating,
        visibleModalChangeResolution, setVisibleModalChangeResolution
    } = useBetween(useModalViewTickets);


    return (
        <>
            <ModalInformation
                open={visibleModalAttendChat}
                onRequestClose={() => {
                    setVisibleModalAttendChat(false);
                }}
                textHeaderModal={'Iniciar atendimento'}
                textButton={'Iniciar'}
                onRequestSave={async () => {
                    setVisibleModalAttendChat(false);
                    await attendChat();
                }}
                nameIcon={ICONS_DROPDESK.userChecked}
                sizeIcon={130}
                textInformation={`Deseja realmente iniciar o atendimento com este contato?`}
                loading={loading}
            />

            <ModalInformation
                open={visibleModalExportPdf}
                onRequestClose={() => {
                    setVisibleModalExportPdf(false);
                }}
                textHeaderModal={'Exportar atendimento'}
                textButton={'Exportar'}
                onRequestSave={async () => {
                    setVisibleModalExportPdf(false);
                    await exportPdf();
                }}
                nameIcon={ICONS_DROPDESK.pdf}
                sizeIcon={130}
                textInformation={`Deseja realmente exportar os dados de atendimento e conversas para PDF?`}
                loading={loading}
            />

            <ModalDrawer
                width={600}
                visible={visibleModalMoreInformation}
                onRequestClose={() => setVisibleModalMoreInformation(false)}
                textHeaderModal={'Fechar'}
                onRequestSave={() => setVisibleModalMoreInformation(false)}
                loading={loading}
                renderComponent={() =>
                    <InputsMoreInformation
                        ticket={ticket}
                        loading={loading}
                    />}

            />

            <ModalDrawer
                width={600}
                visible={visibleModalActivities}
                onRequestClose={() => setVisibleModalActivities(false)}
                textHeaderModal={'Fechar'}
                onRequestSave={() => setVisibleModalActivities(false)}
                loading={loading}
                renderComponent={() => <TimeLineActivities ticket={ticket} loading={loading}/>}
            />


            <ModalTimeSpent
                listTimeSpent={timeSpentMap}
                visible={visibleModalTimeSpent}
                onClose={() => setVisibleModalTimeSpent(false)}
                onSelectTimeSpent={(timeSpent) => {
                    handleChangeTimeSpent(timeSpent);
                    setVisibleModalTimeSpent(false);
                }}
                loading={loading}
            />

            <ModalPriority
                listPriority={appController.ticketPriorityConfigs.map((item) => item.toOptionType())}
                visible={visibleModalPriority}
                onClose={() => setVisibleModalPriority(false)}
                onSelectPriority={(priority) => {
                    handleChangePriority(priority);
                    setVisibleModalPriority(false);
                }}
                loading={loading}
            />


            <ModalTicketClosed
                visible={visibleModalTicketClosed}
                textHeader={"Fechar atendimento"}
                onClose={() => setVisibleModalTicketClosed(false)}
                onSelectTicketClosed={(description) => {
                    handleTicketClosed(description);
                    setVisibleModalTicketClosed(false);
                }}
                visibleInputDescriptionClosed={informSolutionDescription}
                loading={loading}
            />

            <ModalTicketClosed
                visible={visibleModalChangeResolution}
                textHeader={"Informar resolução"}
                onClose={() => setVisibleModalChangeResolution(false)}
                onSelectTicketClosed={(description) => {
                    if (description) {
                        changeResolution(description);
                        setVisibleModalChangeResolution(false);
                    }
                }}
                visibleInputDescriptionClosed={true}
                value={currentTicket.realDescriptionClosed}
                loading={loading}
            />

            <ModalCancellationTicket
                visible={visibleModalCancellationTicket}
                onClose={() => setVisibleModalCancellationTicket(false)}
                onSelectTicketClosed={(description) => {
                    handleCancellationTicket(description);
                    setVisibleModalCancellationTicket(false);
                }}
                visibleInputDescriptionClosed={informCancellationDescription}
                loading={loading}
            />

            {visibleModalTransferTicket && ticket && <ModalTransferTicket
                visible={visibleModalTransferTicket}
                onClose={() => setVisibleModalTransferTicket(false)}
                onTransfer={async (sector, user, message) => {
                    await handleTransferTicket(sector, message, user);
                    setVisibleModalTransferTicket(false);
                }}
                loading={loading}
                userLogged={userLogged}
                idTicket={ticket.id}
                listBySectorsByUserLinkedInTransferTicket={listBySectorsByUserLinkedInTransferTicket}
            />}

            <ModalMain
                open={visibleModalTicketRating}
                onRequestClose={() => setVisibleModalTicketRating(false)}
                width={400}
                renderComponent={
                    <TicketRating
                        onClose={() => setVisibleModalTicketRating(false)}
                        ticketEvaluation={ticketEvaluation}
                        setTicketEvaluation={setTicketEvaluation}
                        loading={loadingEvaluation}
                        loadingMessage={loadingMessageEvaluation}
                        makeEvaluation={makeEvaluation}
                        handleEvaluate={handleEvaluate}
                    />
                }
            />

            <ModalMain
                open={visibleModalCreateClient}
                onRequestClose={() => setVisibleModalCreateClient(false)}
                width={1060}
                renderComponent={
                    <ContentFormCreate>
                        <FormClients
                            isEdit={true}
                            idClientExternal={ticket?.idClient ?? ''}
                            handleSubmitExternalScreen={async (client) => {
                                handleChangeClient(client);
                            }}
                            backPreviousScreenExternalScreen={() => setVisibleModalCreateClient(false)}
                        />
                    </ContentFormCreate>}
            />

            {visibleModalChangeProblemDescription && ticket && <ModalChangeProblemDescription
                visible={visibleModalChangeProblemDescription}
                onClose={() => setVisibleModalChangeProblemDescription(false)}
                loading={loading}
                useProblemStatic={useProblemStatic}
                handleChangeDescription={(description, descriptionStatic) => {
                    handleChangeDescription(description, descriptionStatic);
                    setVisibleModalChangeProblemDescription(false);
                }}
                ticket={ticket}
            />}
            {visibleModalChangeDateDue && ticket && <ModalChangeDateDue
                visible={visibleModalChangeDateDue}
                onClose={() => setVisibleModalChangeDateDue(false)}
                loading={loading}
                ticket={ticket}
                handleChangeDateDue={(dateDue) => {
                    handleChangeDateDue(dateDue);
                    setVisibleModalChangeDateDue(false);
                }}
            />}

        </>

    )
});
export default ModalsViewTicket;
