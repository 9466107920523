import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContentTotalMonthly,
    Footer, TextBold,
} from "../styled";
import React from "react";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import {Text} from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan/styled";
import {
    PaymentSubscriptionSimulateEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_simulate.entity";
import {formatDateToDDMMYYYY} from "@dropDesk/utils/helpers/date_helper";

const FooterResumeValue = (
    {
        totalOthersPayment,
        simulate
    }: {
        totalOthersPayment: number
        simulate?: PaymentSubscriptionSimulateEntity
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Footer>
            <ContentTotalMonthly>
                <TextBold size={18} color={colors.hintInverse}>Total mensal</TextBold>
                {simulate?.nextDueDate &&
                    <Text style={{marginTop: -5}} size={13}
                          color={colors.text}>(a partir
                        de {formatDateToDDMMYYYY(new Date(simulate.nextDueDate))})</Text>}
            </ContentTotalMonthly>
            <TextBold size={18} color={colors.text}>{formatCurrency(totalOthersPayment)}</TextBold>
        </Footer>
    );
}
export default FooterResumeValue;
