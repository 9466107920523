// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.ant-menu-submenu-popup > .ant-menu {*/
/*    background: greenyellow !important;*/
/*}*/

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: none !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: none !important;
}

.ant-menu-item:not(.ant-menu-item-disabled):focus-visible {
    box-shadow: none !important;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #808080;
}

.ant-menu-submenu-arrow {
    color: #808080;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item {
    padding: 0px;
    margin: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/presentation/components/menu/styles.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC,0CAA0C;AAC1C,IAAI;;AAEJ;IACI,2BAA2B;AAC/B;;AAEA;AACA;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":["/*.ant-menu-submenu-popup > .ant-menu {*/\n/*    background: greenyellow !important;*/\n/*}*/\n\n.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {\n    background: none !important;\n}\n\n.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {\n}\n\n.ant-menu-item:active, .ant-menu-submenu-title:active {\n    background: none !important;\n}\n\n.ant-menu-item:not(.ant-menu-item-disabled):focus-visible {\n    box-shadow: none !important;\n}\n\n.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {\n    color: #808080;\n}\n\n.ant-menu-submenu-arrow {\n    color: #808080;\n}\n\n.ant-menu-vertical.ant-menu-sub > .ant-menu-item {\n    padding: 0px;\n    margin: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
