import {NoticesRemoteDataSource} from "@dropDesk/data/data_source/notices/notices_remote.datasource";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {NoticesRepository} from "@dropDesk/domain/repositories/notices/notices.repository";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";

@injectable()
export class NoticesRepositoryImpl implements NoticesRepository {
    private _dataSource: NoticesRemoteDataSource;

    constructor(@inject(NoticesRemoteDataSource) dataSource: NoticesRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        filterByDestiny: NoticeDestiny | null
    ): Promise<ListPaginationEntity<NoticesEntity>> {
        try {
            return this._dataSource.list(page, searchParam, limit, listOnlyDeleted, filterByDestiny);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    public set(notice: NoticesEntity): Promise<NoticesEntity> {
        try {
            return this._dataSource.set(notice);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public findByPK(id: string): Promise<NoticesEntity> {
        try {
            return this._dataSource.findByPK(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public restore(notices: NoticesEntity[]): Promise<void> {
        try {
            return this._dataSource.restore(notices);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public delete(notices: NoticesEntity[]): Promise<void> {
        try {
            return this._dataSource.delete(notices);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public listNoticesByUser(page: number, limit: number): Promise<ListPaginationEntity<NoticesEntity>> {
        try {
            return this._dataSource.listNoticesByUser(page, limit);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
