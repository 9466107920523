import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    TextBold,
} from "../styled";
import React from "react";
import {
    ContainerDescriptions,
    ContentDescriptions, ContentResume,
    Text
} from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan/styled";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import {ContentResumeValueText} from "@dropDesk/presentation/pages/subscription/ui/checkout/styled";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";

const BodyResumeValue = (
    {
        valuePlanOnDemand,
        newSubscription
    }: {
        valuePlanOnDemand?: number;
        newSubscription: PaymentSubscriptionEntity
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerDescriptions>
            <ContentResume>
                <TextBold size={11} color={colors.hint}>
                    RESUMO
                </TextBold>
            </ContentResume>
            <ContentDescriptions>
                <TextBold size={13} color={colors.hintInverse}>
                    Recorrência do pagamento:
                </TextBold>
                <ContentResumeValueText>
                    <Text size={13} color={colors.text}>mensal</Text>
                </ContentResumeValueText>
            </ContentDescriptions>

            {valuePlanOnDemand && <ContentDescriptions>
                <TextBold size={13} color={colors.hintInverse}>
                    Valor por atendente:
                </TextBold>
                <ContentResumeValueText>
                    <Text size={13}
                          color={colors.text}>{formatCurrency(valuePlanOnDemand)}</Text>
                </ContentResumeValueText>
            </ContentDescriptions>}

            <ContentDescriptions>
                <TextBold size={13} color={colors.hintInverse}>
                    Quantidade de atendentes:
                </TextBold>
                <ContentResumeValueText>
                    <Text size={13} color={colors.text}>{newSubscription.planUserMultiplier}</Text>
                </ContentResumeValueText>
            </ContentDescriptions>

            <ContentDescriptions>
                <TextBold size={13} color={colors.hintInverse}>
                    Melhor dia para pagamento:
                </TextBold>
                <ContentResumeValueText>
                    <Text size={13} color={colors.text}>dia&nbsp;{newSubscription.dayDue}</Text>
                </ContentResumeValueText>
            </ContentDescriptions>

        </ContainerDescriptions>
    );
}
export default BodyResumeValue;
