import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";

@injectable()
export abstract class CryptoRemoteDataSource {
    public abstract decrypt(value: string): Promise<string>;
}

@injectable()
export class CryptoRemoteDataSourceImpl implements CryptoRemoteDataSource {

    constructor() {
    }

    baseUrl: string = 'crypto/';
    decryptUrl: string = 'decrypt/';


    public async decrypt(value: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await api.post(
                    `${this.baseUrl}${this.decryptUrl}`,
                    {data: value}
                );
                return resolve(response.data.data ?? '');
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

}
