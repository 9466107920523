import {
    ContainerImageEditor,
} from "@dropDesk/presentation/pages/image_editor/ui/styled";
import React, {MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import Footer from "@dropDesk/presentation/pages/image_editor/ui/footer";
import Body from "@dropDesk/presentation/pages/image_editor/ui/body";
import {ReferenceImageEdited} from "@dropDesk/domain/entities/image_editor/image_editor_enum.entity";
import {observer} from "mobx-react";
import {ImageEditorController} from "@dropDesk/presentation/pages/image_editor/controller/image_editor.controller";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";


const ImageEditor = observer((
    {
        onSave,
        imageEditorController,
        idTicket,
        ticketHasActiveStatus,
        widthContainerListChats
    }: {
        onSave: (referenceImageEdited: MutableRefObject<ReferenceImageEdited | undefined>) => void,
        imageEditorController: ImageEditorController
        idTicket: string
        ticketHasActiveStatus: boolean
        widthContainerListChats?: number
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const referenceImageEdited = useRef<ReferenceImageEdited>();
    const referenceInput = useRef<HTMLInputElement | null>(null);
    const collapsed = useContext(MenuCollapsedContext);

    useEffect(() => {
        imageEditorController.setLoadableDesignState(referenceImageEdited);
    }, [idTicket]);

    const handleAllChangesFile = (event: { target: { name: string; value: any; checked: any; type: string }; }) => {
        if (imageEditorController.currentFileEdition) {
            const _currentFileEdition = imageEditorController.currentFileEdition.copyWith({
                fileMessage: imageEditorController.currentFileEdition.fileMessage.copyWith({
                    [event.target.name]:
                        (event.target.type === "checkbox" || event.target.type === "switch") ?
                            event.target.checked : event.target.value,
                })
            });
            imageEditorController.setCurrentFileEdition(_currentFileEdition);
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<Element>) => {
        const enterPressed = event.key === "Enter";
        const shiftPressed = event.shiftKey;

        if (enterPressed && !shiftPressed) {
            event.preventDefault();
            onSave(referenceImageEdited);
        }
    }

    return (
        <>
            {imageEditorController.filesEdition.length > 0 && ticketHasActiveStatus &&
                <ContainerImageEditor
                    background={colors.onBackground}
                    onKeyDown={(event) => onKeyDown(event)}
                    width={((collapsed ? 80 : 250) + (widthContainerListChats ?? 0))}
                >

                    <Body
                        currentFileEdition={imageEditorController.currentFileEdition}
                        handleAllChangesFile={handleAllChangesFile}
                        loading={imageEditorController.loading}
                        files={imageEditorController.filesEdition}
                        referenceImageEdited={referenceImageEdited}
                        onClose={() => imageEditorController.clearFiles()}
                        getNameFileSelected={() => imageEditorController.getNameFileSelected()}
                        referenceInput={referenceInput}
                    />

                    <Footer
                        files={imageEditorController.filesEdition}
                        loading={imageEditorController.loading}
                        addMoreFiles={(files) => imageEditorController.handlePickFile(files)}
                        onSave={() => onSave(referenceImageEdited)}
                        handleSelectFile={(fileMessage) => {
                            imageEditorController.setLoadableDesignState(referenceImageEdited);
                            imageEditorController.setCurrentFileEdition(fileMessage);
                            referenceInput.current?.focus();
                        }}
                        handleRemoveFile={(fileMessage) => imageEditorController.handleRemoveFile(fileMessage)}
                        isCurrentFile={(fileMessage) => imageEditorController.isCurrentFile(fileMessage)}
                    />
                </ContainerImageEditor>
            }</>

    );
});
export default ImageEditor;
