import React from "react";
import {
    ContentButton,
    ContentCancelText,
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {HighLightHashTag} from "@dropDesk/presentation/components/high_light_hashtag";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {observer} from "mobx-react";

const ActiveSubscription = observer((
    {
        disabled,
        loading,
        onClickCancelSubscription,
        nextDueDateDDMMYYYY
    }: {
        disabled: boolean;
        loading: boolean;
        onClickCancelSubscription: () => void
        nextDueDateDDMMYYYY: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <>
            <ContentCancelText>
                <HighLightHashTag fontSize={15}
                                  text={`Cancelando sua assinatura você terá acesso até: #${nextDueDateDDMMYYYY}#`}/>
            </ContentCancelText>
            <ContentButton>
                <ButtonNew
                    isDeleted={true}
                    marginLeft={0}
                    label={'Cancelar'}
                    nameLeftIcon={ICONS_DROPDESK.cancelOutline}
                    onClick={() => onClickCancelSubscription()}
                    sizeLeftIcon={16}
                    disabled={loading || disabled}
                    background={colors.onBackground}
                    useIcon={false}
                />
            </ContentButton>
        </>
    );
});
export default ActiveSubscription;
