import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Container, ContainerDocument, ContentInputDocument, ContentReceiveInvoice,
    ContentDataInput, ContentInput, ContentJuridicalNature, RadioButtonLeft, RadioButtonRight, ContainerReceiveInvoice
} from "./styled";
import React from "react";
import {BillingInfoHolder, CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import {ClientJuridicalNature} from "@dropDesk/domain/entities/client/client_enum";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {getPhoneValue, isValidCNPJ} from "@dropDesk/utils/helpers/validators";
import {removeMask, removeMaskStaticTelephoneNumber} from "@dropDesk/utils/helpers/string_helper";
import {
    useValidatorInputCompanyPaymentInfo
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/validator";
import InputPhoneNumber from "@dropDesk/presentation/components/inputs/input_phone_number";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const InputsInfoHolder = (
    {
        onUpdate,
        loading,
        onSearchCNPJ,
        newPaymentInfo
    }: {
        onUpdate: (newPaymentInfo: CompanyPaymentInfo) => void
        loading: boolean
        onSearchCNPJ: (document: string) => void,
        newPaymentInfo: CompanyPaymentInfo
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const {
        errorMessageName,
        errorMessageEmail,
        errorMessageCNPJ,
        errorMessageCPF,
        errorMessageTelephone,
    } = useValidatorInputCompanyPaymentInfo(newPaymentInfo);

    const handleAllChanges = (event: {
        target: { name: string; value: any; checked: any; type: string }
    }) => {

        const infoHolder = newPaymentInfo.billingInfoHolder.copyWith({
            [event.target.name]:
                (event.target.type === "checkbox" || event.target.type === "switch") ?
                    event.target.checked : event.target.name === "phone" ?
                        removeMaskStaticTelephoneNumber(event.target.value.replace(/\D/g, "")) :
                        event.target.name === "document" ? removeMask(event.target.value.replace(/\D/g, "")) : event.target.value,
        });
        const _newPaymentInfo = newPaymentInfo.copyWith({
            billingInfoHolder: infoHolder
        });
        onUpdate(_newPaymentInfo);
    };

    return (
        <Container>
            <ContainerDocument>
                <ContentInputDocument>
                    <ContentDataInput>
                        <ContentJuridicalNature color={colors.border}>
                            <RadioButtonLeft
                                background={newPaymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.juridical ? colors.accent : colors.onBackground}
                                color={newPaymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.juridical ? SharedColors.white : colors.text}
                                value={newPaymentInfo.billingInfoHolder.juridicalNature}
                                onClick={(event) => {
                                    handleAllChanges({
                                        target:
                                            {
                                                name: 'juridicalNature',
                                                value: ClientJuridicalNature.juridical,
                                                checked: false,
                                                type: 'input'
                                            }
                                    });
                                }}
                            >
                                Jurídica
                            </RadioButtonLeft>
                            <RadioButtonRight
                                background={newPaymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.physical ? colors.accent : colors.onBackground}
                                color={newPaymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.physical ? SharedColors.white : colors.text}
                                onClick={(event) => {
                                    handleAllChanges({
                                        target:
                                            {
                                                name: 'juridicalNature',
                                                value: ClientJuridicalNature.physical,
                                                checked: false,
                                                type: 'input'
                                            }
                                    });
                                }}
                            >
                                Física
                            </RadioButtonRight>
                        </ContentJuridicalNature>
                        <ContentInput>
                            {newPaymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.physical &&
                                <InputWithBorder
                                    required={true}
                                    mask={'cpf'}
                                    autoFocus={true}
                                    name='document'
                                    size={35}
                                    disabled={loading}
                                    removeMaskOnCopy={true}
                                    messageError={errorMessageCPF}
                                    placeHolder={'000.000.000-00'}
                                    label={"CPF"}
                                    value={newPaymentInfo.billingInfoHolder.document ?? ''}
                                    onChange={handleAllChanges}
                                />
                            }
                            {newPaymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.juridical &&
                                <InputWithBorder
                                    required={true}
                                    mask={'cnpj'}
                                    autoFocus={true}
                                    name='document'
                                    size={35}
                                    disabled={loading}
                                    messageError={errorMessageCNPJ}
                                    label={"CNPJ"}
                                    removeMaskOnCopy={true}
                                    useButtonRight={true}
                                    onClickButtonRight={() => onSearchCNPJ(newPaymentInfo.billingInfoHolder.document ?? '')}
                                    labelButtonRight={'Buscar'}
                                    nameIconButtonRight={ICONS_DROPDESK.search}
                                    placeHolder={'00.000.000/0000.00'}
                                    tooltipButtonRight={isValidCNPJ(newPaymentInfo.billingInfoHolder.document ?? '') ? '' : 'Informe um CNPJ válido para busca'}
                                    disabledButtonRight={!isValidCNPJ(newPaymentInfo.billingInfoHolder.document ?? '')}
                                    value={newPaymentInfo.billingInfoHolder.document ?? ''}
                                    onChange={handleAllChanges}
                                />
                            }
                        </ContentInput>

                        <ContentInput style={{marginLeft: 20}}>
                            <InputWithBorder
                                required={true}
                                name={'name'}
                                size={35}
                                disabled={loading}
                                messageError={errorMessageName}
                                label={newPaymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.juridical ? "Razão Social" : "Nome"}
                                value={newPaymentInfo.billingInfoHolder.name}
                                onChange={handleAllChanges}
                            />

                        </ContentInput>

                    </ContentDataInput>
                </ContentInputDocument>
                <ContainerReceiveInvoice>
                    <ContentDataInput>
                        <ContentInput style={{marginRight: 20}}>
                            <InputWithBorder
                                required={true}
                                name='email'
                                size={35}
                                disabled={loading}
                                messageError={errorMessageEmail}
                                label={"Email"}
                                value={newPaymentInfo.billingInfoHolder.email ?? ''}
                                onChange={handleAllChanges}
                            />
                        </ContentInput>
                        <ContentInput>
                            <InputPhoneNumber
                                required={true}
                                name='telephone'
                                size={35}
                                disabled={loading}
                                messageError={errorMessageTelephone}
                                label={"Telefone"}
                                value={getPhoneValue(newPaymentInfo.billingInfoHolder.phone)}
                                onChange={(value) => {
                                    handleAllChanges({
                                        target:
                                            {
                                                name: 'phone',
                                                value: value ?? '',
                                                checked: false,
                                                type: 'input'
                                            }
                                    });
                                }}
                            />
                        </ContentInput>
                    </ContentDataInput>
                </ContainerReceiveInvoice>
            </ContainerDocument>
        </Container>
    );
}
export default InputsInfoHolder;
