import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

@injectable()
export class SetUserUseCase {
    private _repository: UserRepository;

    constructor(@inject(UserRepository) repository: UserRepository) {
        this._repository = repository;
    }

    public call(user: UserEntity, isNew: boolean): Promise<UserEntity> {
        return this._repository.set(user, isNew);
    }
}

