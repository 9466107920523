import styled from "styled-components";

type ContainerType = {
  background: string;
}
export const Container = styled.div<ContainerType>`
  display: flex;
  width: 400px;
  min-height: 350px;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  border-radius: 7px;
  background: ${(props) => props.background};
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;

`;

export const ContentButtons = styled.div`
  display: flex;
  height:45px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
`;
