import {observer} from "mobx-react";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Container,
    ContentDisplayPlan,
    ContentValue, Text
} from "@dropDesk/presentation/pages/subscription/ui/modals/plan_period_value/styled";
import {TextBold} from "@dropDesk/presentation/pages/subscription/ui/checkout/resume_value/styled";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";

const PlanPeriodValue = observer(
    ({
         newSubscription,
         labelNewPeriod
     }: {
        newSubscription: PaymentSubscriptionEntity
        labelNewPeriod: string
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container bordercolor={colors.text}>
                <ContentDisplayPlan>
                    <TextBold size={15} color={colors.text}>{newSubscription.planName}</TextBold>
                    <Text size={14} color={colors.text}>{labelNewPeriod}</Text>
                </ContentDisplayPlan>
                <ContentValue>
                    <TextBold size={18}
                              color={colors.text}>{formatCurrency(newSubscription.lastPayment!.value)}</TextBold>
                </ContentValue>
            </Container>
        )
    })
export default PlanPeriodValue;
