import {observer} from "mobx-react";
import {Container, Content} from "./styled";
import React, {useEffect, useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {TextTab} from "@dropDesk/presentation/pages/company_configurations/ui/styled";
import Tabs from "@dropDesk/presentation/components/tabs/background_style";
import ReportTickets from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/ui";
import ReportTicketsAggregate from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/ui";


export enum TabsTicket {
    default = 'default',
    aggregate = 'aggregate',
}

const ReportTicketInit = observer(
    ({}: {}) => {

        useEffect(() => {

        }, []);

        const [tabsReportTicket, setTabsReportTicket] = useState<TabsTicket>(TabsTicket.default);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container>
                <Content background={colors.onBackground} border={colors.border}>
                    <Tabs
                        defaultActiveKey={tabsReportTicket}
                        withMarginInTabs={true}
                        destroyInactiveTabPane={true}
                        height={50}
                        onChange={(key) => setTabsReportTicket(key as TabsTicket)}
                        items={[
                            {
                                label:
                                    <TextTab>
                                        Atendimentos
                                    </TextTab>,
                                key: TabsTicket.default,
                                children: <ReportTickets/>,
                            },
                            {
                                label:
                                    <TextTab>
                                        Visão Geral
                                    </TextTab>,
                                key: TabsTicket.aggregate,
                                children: <ReportTicketsAggregate/>,
                            },
                        ]}
                    />

                </Content>
            </Container>
        )
    })
export default ReportTicketInit;
