import React, {MouseEvent} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ButtonAddFile, Container, ContainerButton, ContainerIconRemoveImage, ContainerImage,
    ContainerScrollImage, ContentButton,
    ContentImage,
    ContentScroll, IconRemoveImage,
    Image
} from "@dropDesk/presentation/pages/image_editor/ui/footer/styled";
import {isFileImage} from "@dropDesk/utils/helpers/files";
import PickFileGeneric from "@dropDesk/presentation/components/drop_zone/pick_file_generic";
import {Badge} from "antd";
import {observer} from "mobx-react";
import {FileEditionEntity} from "@dropDesk/domain/entities/image_editor/image_editor.entity";
import ClipLoading from "@dropDesk/presentation/components/loadings/loading_clip";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const Footer = observer((
    {
        files,
        loading,
        addMoreFiles,
        onSave,
        handleSelectFile,
        handleRemoveFile,
        isCurrentFile,
    }: {
        files: FileEditionEntity[];
        loading: boolean;
        addMoreFiles: (files: File[]) => void,
        onSave: () => void,
        handleSelectFile: (fileMessage: FileEditionEntity) => void,
        handleRemoveFile: (fileMessage: FileEditionEntity) => void
        isCurrentFile: (fileMessage: FileEditionEntity) => boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;


    return (
        <Container bordercolor={colors.border}>
            <ContainerScrollImage>
                <ContentScroll>
                    {files.map((fileEdition) =>
                        <ContainerImage key={fileEdition.fileMessage.id}>
                            <ContainerIconRemoveImage
                                disabled={loading}
                                onClick={(event: MouseEvent<HTMLElement>) => {
                                    event.stopPropagation();
                                    if (!loading) {
                                        handleRemoveFile(fileEdition);
                                    }
                                }}
                            >
                                <IconRemoveImage
                                    fontsize={11}
                                    className={`icon-${ICONS_DROPDESK.close}`}
                                    color={SharedColors.white}
                                />
                            </ContainerIconRemoveImage>
                            <ContentImage
                                disabled={loading}
                                background={colors.onBackground}
                                key={fileEdition.fileMessage.id}
                                onClick={() => {
                                    if (!loading) {
                                        handleSelectFile(fileEdition);
                                    }
                                }}
                                bordercolor={isCurrentFile(fileEdition) ? colors.accent : colors.border}
                                hasfocus={isCurrentFile(fileEdition)}
                            >
                                {isFileImage(fileEdition.fileMessage.file) &&
                                    <Image src={fileEdition.fileMessage.blobURL} alt={"Edit image"}/>}
                                {!isFileImage(fileEdition.fileMessage.file) &&
                                    <DropDeskIcon
                                        size={28} icon={ICONS_DROPDESK.documentOutline}
                                        style={{pointerEvents: 'none'}}
                                    />}

                            </ContentImage>

                        </ContainerImage>
                    )}
                </ContentScroll>

                <div style={{overflow: "auto"}}>
                    <PickFileGeneric
                        maxSizeInMegaBytes={16}
                        maxFiles={20}
                        onPick={(files: File[]) => addMoreFiles(files)}
                        noClick={false}
                        isMultiple={true}
                        noDrag={true}
                        disabled={loading}
                        renderComponent={
                            <ButtonAddFile
                                disabled={loading}
                                bordercolor={colors.border}
                                background={colors.onBackground}
                            >
                                <DropDeskIcon
                                    size={28}
                                    icon={ICONS_DROPDESK.add}
                                    style={{pointerEvents: 'none'}}
                                />
                            </ButtonAddFile>
                        }
                    />
                </div>

            </ContainerScrollImage>

            <ContentButton>
                <Badge count={files.length} overflowCount={99} size="default" offset={[-15, 10]}>
                    {!loading && <ContainerButton
                        disabled={loading}
                        onClick={() => {
                            onSave();
                        }} background={colors.accent}>
                        <DropDeskIcon
                            size={25}
                            icon={ICONS_DROPDESK.sendFill}
                            color={SharedColors.white}
                            style={{pointerEvents: 'none'}}
                        />
                    </ContainerButton>}

                    {loading && <ContainerButton
                        disabled={loading}
                        background={colors.accent}>
                        <ClipLoading size={20} color={SharedColors.white}/>
                    </ContainerButton>}
                </Badge>
            </ContentButton>

        </Container>
    )
});

export default Footer;
