import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";

export const newNotices = () => new NoticesEntity({
  id: '',
  idCompany: getIdCompanyByLocalStorage(),
  idUser: null,
  idClient: null,
  user: null,
  client: null,
  deleted: false,
  description: '',
  dateStarted: null,
  dateEnd: null,
  hasTemporary: false,
  destiny: NoticeDestiny.user,
  createdAt: undefined,
});
