import styled from "styled-components";
import {ButtonType, ContainerType} from "@dropDesk/presentation/components/modals/modal_time_spent/modal_time_spent";

type TextType = {
  color: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  width: 470px;
  height: 470px;
  min-height: 470px;
  overflow: hidden;
  flex-direction: column;
  border-radius: 7px;
  background: ${(props) => props.background};
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
  justify-content: center;
`;

export const TextExplication = styled.span<TextType>`
  font-size: 15px;
  color: ${(props) => props.color};
`;
export const ContentExplication = styled.div`
  display: flex;
  flex-direction: row;
  height: 90px;
  min-height: 90px;
  max-height: 90px;
  align-items: flex-start;
  justify-content: center;
`;
export const ContentItem = styled.button<ButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  height: 40px;
  min-height: 40px;
  font-weight: bold;
  font-size: 15px;
  border-radius: 4px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border: ${(props) => `1px solid ${props.color}`};

  &:hover {
     filter: brightness(80%);
  }
`;
