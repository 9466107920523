import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {ContainerButtonHeaderType} from "@dropDesk/presentation/components/lists/list_sectors_linked/list_sectors";


export const ContainerButtonHeader = styled.div<ContainerButtonHeaderType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
