import styled from "styled-components";

type ContainerType = {
    background: string;
}

export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: row;
  background: ${props => props.background};
  border-radius: 5px;
  padding: 16px 0;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
  margin: 16px 0 0 0;
`;

export const ContentDescriptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const ContentTextAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const Icon = styled.i<TextType>`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  margin: 4px 5px 0 0;
`;
