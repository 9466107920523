import styled from "styled-components";


type HeaderType = {
    background: string;
    bordercolor: string;
};

export const ContentConfigureNotification = styled.div<HeaderType>`
  display: flex;
  height: 40px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: ${props => props.background};
  border-bottom: 1px solid ${props => props.bordercolor};
  padding: 0 16px;
`;
