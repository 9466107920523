import {
    ButtonPickerDate,
    Container,
    ContainerButtons,
    ContentButtons,
    ContentButtonsUp,
    DateRangePickerStyled
} from "./styled";
import {RangeValue, SelectDatePropsType} from "@dropDesk/presentation/components/dates/select_range_date/select_date";
import React, {useEffect, useRef} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {
    differenceBetweenDatesInDays,
    getCurrentDate,
    setMaxHour
} from "@dropDesk/utils/helpers/date_helper";
import LabelInput from "@dropDesk/presentation/components/inputs/input_with_border/label";
import {getStaticDateFromPeriod, PeriodEnum} from "@dropDesk/utils/helpers/common";

const SelectRangeDate = (props: SelectDatePropsType) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.blur();
        }
    }, []);

    const preventSelectDateLaterToday = (current: Date): boolean => {
        return (current.getTime() > setMaxHour(getCurrentDate()).getTime());
    }

    const preventSelectDateBeforeMinDate = (current: Date, minDateInDays: number): boolean => {
        return (differenceBetweenDatesInDays(current, new Date()) > minDateInDays);
    }

    const disabledDate = (current: Date): boolean => {
        if (props.minDateInDays) {
            return preventSelectDateBeforeMinDate(current, props.minDateInDays) || (preventSelectDateLaterToday(current));
        }

        return (preventSelectDateLaterToday(current));
    }

    const handleOnChange = (range: RangeValue | null, label: PeriodEnum) => {
        props.onChange(range, label);
    }

    const getLabelPtBr = (labelPeriod: PeriodEnum): string => {
        if (labelPeriod === PeriodEnum.now) {
            return "Hoje";
        } else if (labelPeriod === PeriodEnum.yesterday) {
            return "Ontem";
        } else if (labelPeriod === PeriodEnum.currentWeek) {
            return "Semana atual";
        } else if (labelPeriod === PeriodEnum.lastWeek) {
            return "Semana passada";
        } else if (labelPeriod === PeriodEnum.currentMonth) {
            return "Mês atual";
        } else if (labelPeriod === PeriodEnum.lastMonth) {
            return "Mês passado";
        } else {
            return "Customizado";
        }
    }


    return (
        <Container marginTop={props.marginTop ?? 0}>
            {!!props.label && <LabelInput required={props.required} label={getLabelPtBr(props.labelPeriodCustom)}/>}

            <DateRangePickerStyled
                border={colors.border}
                accent={colors.accent}
                ref={inputRef}
                autoFocus={props.autoFocus}
                onChange={(dates) => handleOnChange(dates, PeriodEnum.custom)}
                value={props.value}
                hint={colors.hint}
                disabledDate={disabledDate}
                placeholder={props.placeholder}
                inputReadOnly={false}
                showTime={{format: 'HH:mm'}}
                format={["d/M/y", 'dMy']}
                allowClear={props.allowClear ?? false}
                style={
                    {
                        height: props.size,
                        width: '100%',
                        borderColor: colors.border,
                        backgroundColor: colors.backgroundInput,
                        borderRadius: 5,
                        fontSize: 14
                    }}
                getPopupContainer={(trigger) => trigger}
                color={colors.text}
                placeholdercolor={colors.hint}
                focuscolor={colors.text}
                background={colors.backgroundInput}
                shadowcolor={colors.brightness === Brightness.light ? SharedColors.black : colors.border}
                backgroundmainbutton={colors.accent}
                renderExtraFooter={() => {
                    return (
                        <ContainerButtons>

                            <ContentButtonsUp>
                                <ButtonPickerDate
                                    width={46}
                                    color={colors.text}
                                    border={colors.border}
                                    accent={colors.accent}
                                    background={colors.onBackground}
                                    onClick={() => handleOnChange(getStaticDateFromPeriod(PeriodEnum.now).range as RangeValue, getStaticDateFromPeriod(PeriodEnum.now).label)}>
                                    Hoje
                                </ButtonPickerDate>
                                <ButtonPickerDate
                                    border={colors.border}
                                    accent={colors.accent}
                                    width={106}
                                    color={colors.text}
                                    background={colors.onBackground}
                                    onClick={() => handleOnChange(getStaticDateFromPeriod(PeriodEnum.currentWeek).range as RangeValue, getStaticDateFromPeriod(PeriodEnum.currentWeek).label)}>
                                    Semana atual
                                </ButtonPickerDate>
                                <ButtonPickerDate
                                    width={106}
                                    color={colors.text}
                                    border={colors.border}
                                    accent={colors.accent}
                                    background={colors.onBackground}
                                    onClick={() => handleOnChange(getStaticDateFromPeriod(PeriodEnum.lastWeek).range as RangeValue, getStaticDateFromPeriod(PeriodEnum.lastWeek).label)}>
                                    Semana passada
                                </ButtonPickerDate>
                            </ContentButtonsUp>

                            <ContentButtons>
                                <ButtonPickerDate
                                    width={46}
                                    color={colors.text}
                                    border={colors.border}
                                    accent={colors.accent}
                                    background={colors.onBackground}
                                    onClick={() => handleOnChange(getStaticDateFromPeriod(PeriodEnum.yesterday).range as RangeValue, getStaticDateFromPeriod(PeriodEnum.yesterday).label)}>
                                    Ontem
                                </ButtonPickerDate>
                                <ButtonPickerDate
                                    width={106}
                                    color={colors.text}
                                    border={colors.border}
                                    accent={colors.accent}
                                    background={colors.onBackground}
                                    onClick={() => handleOnChange(getStaticDateFromPeriod(PeriodEnum.currentMonth).range as RangeValue, getStaticDateFromPeriod(PeriodEnum.currentMonth).label)}>
                                    Mês atual
                                </ButtonPickerDate>
                                <ButtonPickerDate
                                    width={106}
                                    color={colors.text}
                                    border={colors.border}
                                    accent={colors.accent}
                                    background={colors.onBackground}
                                    onClick={() => handleOnChange(getStaticDateFromPeriod(PeriodEnum.lastMonth).range as RangeValue, getStaticDateFromPeriod(PeriodEnum.lastMonth).label)}>
                                    Mês passado
                                </ButtonPickerDate>
                            </ContentButtons>

                        </ContainerButtons>
                    )
                }}
            />

        </Container>
    )
}
export default SelectRangeDate;
