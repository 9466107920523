import {Container, ContainerButton} from "./styled";
import React from "react";
import {
    GraphicBarTypes
} from "@dropDesk/presentation/components/graphics/graphic_horizontal_bar/graphic_horizontal_bar";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import NoDataGraphic from "@dropDesk/presentation/components/graphics/graphic_horizontal_bar/no_data";
import {hexToRGBA, shadeOrLighten} from "@dropDesk/utils/helpers/colors";
import {Tooltip as Tip} from "antd";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function GraphicBar(props: GraphicBarTypes) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const ungroupedData = props.data.filter(entry => entry.isNotGrouped);
    const groupedData = props.data.filter(entry => entry.isGrouped);
    const realData = !props.showGroupedData ? props.data : ungroupedData;
    const labelsEligible = realData.map(entry => entry.label);
    const values = realData.map((entry) =>
        (!entry.isMainGrouped || !props.showGroupedData)
            ?
            entry.value
            :
            groupedData.reduce(
                (a, b) => Number(a) + Number(b.value),
                0
            )
    );

    const getPercentage = (quantity: number, total: number): string => {
        return (quantity * 100 / total).toFixed(1);
    }

    const getTotalValue = (): string => {
        const arrayValues = props.data.map((entry) => entry.value);
        const totalValue = arrayValues.reduce((prev, curr) => prev + curr, 0);
        return totalValue.toString();
    }
    return (
        <Container background={colors.background} border={colors.border}>
            {props.showIconInformation &&
                <ContainerButton
                    background={colors.buttonOnBackground}
                    accent={colors.accent}
                    border={colors.border}
                    style={{
                        position: 'absolute',
                        right: 0,
                        cursor: 'pointer',
                        marginRight: 5,
                        marginTop: 5
                    }}
                    onClick={props.onClickIconInformation}
                >
                    <DropDeskIcon
                        icon={ICONS_DROPDESK.info}
                        style={{display: 'flex'}}
                        size={16}
                        color={colors.text}
                    />
                </ContainerButton>
            }
            {props.hasData && <>


                {props.showIconGrouped &&
                    <Tip title={props.toolTipDescriptionGroupedData ?? ''}>
                        <ContainerButton
                            background={colors.buttonOnBackground}
                            accent={colors.accent}
                            border={colors.border}
                            style={{
                                position: 'absolute',
                                right: props.showIconInformation ? 25 : 0,
                                cursor: 'pointer',
                                marginRight: 5,
                                marginTop: 5
                            }}
                            onClick={props.onClickUngroupedData}
                        >
                            <DropDeskIcon
                                icon={props.showGroupedData ? ICONS_DROPDESK.reduce : ICONS_DROPDESK.stretch}
                                size={14}
                                color={colors.text}
                                style={{display: 'flex'}}
                            />
                        </ContainerButton>
                    </Tip>
                }
                <Bar
                    data={{
                        //labels: props.data.map(entry => entry.label),
                        labels: labelsEligible,
                        datasets: [
                            {
                                label: "Quantidade",
                                //data: props.data.map(entry => entry.value),
                                data: values,
                                backgroundColor: props.data.map((entry) => hexToRGBA(colors.accent, .8)),
                                //backgroundColor: props.data.map((entry) => entry.color),
                                //borderColor: shadeOrLighten(colors.accent, -50),
                                // borderWidth: 2,
                                borderRadius: 5,
                            }
                        ],
                    }}
                    options={{
                        layout: {
                            padding: {
                                left: 10,
                                right: 40,
                                top: 0,
                                // bottom: 30
                            }

                        },
                        maintainAspectRatio: false,
                        responsive: true,
                        indexAxis: 'y' as const,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: colors.hintInverse,
                                align: "end",
                                anchor: 'end',
                                formatter: Math.round,
                                font: {
                                    weight: 'bold'
                                }
                            },
                            title: {
                                display: true,
                                text: `${props.description} (${getTotalValue()})`,
                                color: colors.text,
                                font: {
                                    weight: '400',
                                    size: 14,
                                    family: 'Poppins'
                                }
                            },
                            legend: {
                                display: false,
                                position: "right",
                                labels: {
                                    color: SharedColors.white,
                                    font: {}
                                }
                            },
                            tooltip: {
                                callbacks: {
                                    label: (ttItem) =>
                                        `Valor: ${ttItem.parsed.x} - Percentual: ${getPercentage(ttItem.parsed.x, ttItem.dataset.data.reduce(
                                            (a, b) => Number(a) + Number(b),
                                            0
                                        ))}%`
                                },
                            }

                        },
                        scales: {

                            y: {
                                stacked: true,
                                ticks: {
                                    color: colors.hintInverse,
                                    font: {
                                        // size: 18,
                                    },


                                },
                                grid: {
                                    display: false,
                                    color: colors.border,
                                }
                            },
                            x: {
                                stacked: true,
                                ticks: {
                                    color: colors.hintInverse,
                                    font: {
                                        // size: 18,
                                    },
                                },

                                grid: {
                                    color: colors.border,
                                    display: false
                                }
                            },

                        }
                    }}

                />
            </>}
            {!props.hasData &&
                <NoDataGraphic
                    loading={props.loading}
                    description={props.description}
                />
            }
        </Container>
    );
}
