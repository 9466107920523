import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import {
    Container,
    Content,
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/styled";
import React, {useEffect} from "react";
import {useBetween} from "use-between";
import {
    useModalWhatsApp
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/handle_change_visible";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ListExplication
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/read_qr_code/list_explication";
import QrCode
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/read_qr_code/qr_code";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";
import Footer
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/read_qr_code/footer";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const ReadQrCode = (
    {
        setVisibleModalConnect,
        chatConnection,
        dispose,
        startConnection,
    }: {
        setVisibleModalConnect: (value: boolean) => void
        chatConnection: ChatIntegrationConnectionEntity
        dispose: () => void
        startConnection: () => Promise<void>
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    useEffect(() => {
        return () => {
            dispose();
        }
    }, []);

    return (
        <Container>
            <HeaderModal
                disabled={false}
                letter={'Connectar whatsapp'}
                nameIcon={ICONS_DROPDESK.close}
                onClick={() => setVisibleModalConnect(false)}
            />
            <Content background={colors.onBackground}>
                <ListExplication/>
                <QrCode chatConnection={chatConnection} startConnection={startConnection}/>
                <Footer/>
            </Content>

        </Container>
    )
}
export default ReadQrCode;
