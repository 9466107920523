import {removeMask} from "@dropDesk/utils/helpers/string_helper";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";

export const validateAddress = (
  address: AddressEntity,
  setErrorMessageCity?: (text: string) => void,
  setErrorMessageNeighborhood?: (text: string) => void,
  setErrorMessageNumber?: (text: string) => void,
  setErrorMessageShortState?: (text: string) => void,
  setErrorMessageState?: (text: string) => void,
  setErrorMessageStreet?: (text: string) => void,
  setErrorMessageZipCode?: (text: string) => void,
  setIsValidDataFormAddress?: (value: boolean) => void,
): boolean => {
  if (setErrorMessageCity) {
    setErrorMessageCity('');
  }
  if (setErrorMessageNeighborhood) {
    setErrorMessageNeighborhood('');
  }
  if (setErrorMessageNumber) {
    setErrorMessageNumber('');
  }
  if (setErrorMessageShortState) {
    setErrorMessageShortState('');
  }
  if (setErrorMessageState) {
    setErrorMessageState('');
  }
  if (setErrorMessageStreet) {
    setErrorMessageStreet('');
  }
  if (setErrorMessageZipCode) {
    setErrorMessageZipCode('');
  }

  if (removeMask(address.zipCode).length < 8) {
    if (setErrorMessageZipCode) {
      setErrorMessageZipCode('Cep inválido');
    }
    if (setIsValidDataFormAddress) {
      setIsValidDataFormAddress(false);
    }
    return false;
  } else if (address.street.trim().length === 0) {
    if (setErrorMessageStreet) {
      setErrorMessageStreet('Informe o logradouro');
    }
    if (setIsValidDataFormAddress) {
      setIsValidDataFormAddress(false);
    }
    return false;
  } else if (address.number.trim().length === 0) {
    if (setErrorMessageNumber) {
      setErrorMessageNumber('Informe o número');
    }
    if (setIsValidDataFormAddress) {
      setIsValidDataFormAddress(false);
    }
    return false;
  } else if (address.neighborhood.trim().length === 0) {
    if (setErrorMessageNeighborhood) {
      setErrorMessageNeighborhood('Informe o bairro');
    }
    if (setIsValidDataFormAddress) {
      setIsValidDataFormAddress(false);
    }
    return false;
  } else if (address.state.trim().length === 0) {
    if (setErrorMessageState) {
      setErrorMessageState('Selecione o estado');
    }
    if (setIsValidDataFormAddress) {
      setIsValidDataFormAddress(false);
    }
    return false;
  } else if (address.city.trim().length === 0) {
    if (setErrorMessageCity) {
      setErrorMessageCity('Selecione a cidade');
    }
    if (setIsValidDataFormAddress) {
      setIsValidDataFormAddress(false);
    }
    return false;
  } else {
    if (setIsValidDataFormAddress) {
      setIsValidDataFormAddress(true);
    }
    return true;
  }
}
