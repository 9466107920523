import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export class CompanyConnectionsEntity {
  idCompany!: string;
  userIdProvider!: string;
  createdAt?: string;
  userId!: string;
  user!: UserEntity;

  constructor({
                idCompany,
                userId,
                createdAt,
                userIdProvider,
                user,
              }: {
    idCompany: string;
    userId: string;
    createdAt?: string;
    userIdProvider: string;
    user: UserEntity;
  }) {
    Object.assign(this, {
      idCompany,
      userId,
      createdAt,
      userIdProvider,
      user,
    });
  }
}
