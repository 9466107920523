import styled from 'styled-components';

type IconType = {
    color: string;
    fontsize: number;
}

type TextType = {
    color: string;
}


export const ContainerImageEditorNoPreview = styled.div`
  display: flex;
  height: calc(100vh - 210px);
  min-height: calc(100vh - 210px);
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;


export const TextNoPreview = styled.span<TextType>`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.color};
`;

