import styled from "styled-components";

type ContainerType = {
    background: string;
}

type TextType = {
    color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ContentCreate = styled.form`
  width: 430px;
  max-width: 430px;
  flex-direction: column;
  display: flex;
`;

export const Section = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  background: ${(props) => props.background};
  overflow: auto;
  flex-direction: column;
`;

export const ContentImage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 235px;
  height: 50px;
  margin-bottom: 10px;
`;

export const Title = styled.h3<TextType>`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0px;
  color: ${(props) => props.color};
`;
