import {useState} from "react";
import {observer} from "mobx-react";
import {Divider, LinkButton, SectionRememberPassword} from "./styled";
import {ModalForgotEmail} from "@dropDesk/presentation/components/modals/modal_forgot_email";
import {useNavigate} from "react-router-dom";

import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const ActionsAccount = observer(
    ({
         onForgotEmail,
         loading,
     }: {
        onForgotEmail: (email: string) => Promise<void>,
        loading: boolean,
    }) => {

        const [visibleModalRememberPassword, setVisibleModalRememberPassword] = useState(false);
        const navigate = useNavigate();
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const setVisibleModal = () => {
            setVisibleModalRememberPassword(!visibleModalRememberPassword)
        }

        return (
            <SectionRememberPassword>

                <LinkButton
                    color={colors.text}
                    type='button'
                    onClick={() => {
                        setVisibleModalRememberPassword(!visibleModalRememberPassword);
                    }}>
                    Esqueceu sua senha?
                </LinkButton>

                <Divider background={colors.text}></Divider>

                <LinkButton
                    color={colors.text}
                    type='button'
                    onClick={() => {
                        setVisibleModalRememberPassword(!visibleModalRememberPassword);
                        navigate(`${RoutesEnum.firstAccess}?token=`);
                    }}>
                    Ativar conta
                </LinkButton>

                <Divider background={colors.text}></Divider>

                <LinkButton
                    color={colors.text}
                    type='button'
                    onClick={() => {
                        navigate(appController.role ? RoutesEnum.companyCreate : RoutesEnum.role);
                    }}>
                    Criar conta
                </LinkButton>

                <ModalForgotEmail
                    onPressSend={onForgotEmail}
                    onPressHasToken={() => {
                        navigate(`${RoutesEnum.changePassword}?token=`);
                    }}
                    setVisibleModalRememberPassword={setVisibleModal}
                    visibleModalRememberPassword={visibleModalRememberPassword}
                    loading={loading}
                />

            </SectionRememberPassword>
        );
    });

export default ActionsAccount;
