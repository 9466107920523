import {
    BadgeStyled,
    Container,
    ContainerAvatar,
    ContainerData,
    ContentBadge,
    ContentDate,
    ContentLineClamp,
    ContentMessage,
    ContentName,
    ContentNameAndHour,
    ContentNameSector,
    ContentPriority,
    ContentPriorityAndBadge,
    ContentSectorDateDue,
    Text,
    TextMessage,
    CirclePriorityDateExpired, ContentSocialMessage, ContentIconStatus, ContentDateDue, ContainerButtonDateDue
} from "@dropDesk/presentation/components/chat_list/styled";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {displayDateToLocale, formatDateChat, isValidDateFromNowTime} from "@dropDesk/utils/helpers/date_helper";
import {Tooltip} from "antd";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import Status from "@dropDesk/presentation/components/messages/message_box/status_message/status";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {TicketPriorityType} from "@dropDesk/domain/entities/ticket/ticket_maps";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {
    ButtonDropDown,
    ContainerPopover, ContentIconMenu
} from "@dropDesk/presentation/components/chat_view/header_action_buttons/styled";
import PopOver from "@dropDesk/presentation/components/popup/pop_confirm";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {ContentInput} from "@dropDesk/presentation/components/chat_view/modals/inputs_more_information/styled";

const ChatList = (
    {
        ticket,
        searchParam,
        onClick,
        idTicketSelected,
        userLogged,
        disabled
    }: {
        ticket: TicketEntity
        userLogged: UserEntity
        searchParam: string,
        onClick: () => void,
        idTicketSelected?: string | null
        disabled: boolean
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const getBackgroundColorPriority = (priority?: string): string => {
        const priorityConfig = appController.ticketPriorityConfigs.find((item) => item.id === priority);
        const priorityToColor: Record<string, string> = {
            [TicketPriorityType.low]: SharedColors.onCloseStatus,
            [TicketPriorityType.normal]: SharedColors.onOpenStatus,
            [TicketPriorityType.high]: SharedColors.onAttendingStatus,
            [TicketPriorityType.urgent]: SharedColors.onCancelStatus,
            orElse: SharedColors.onCloseStatus,
        };

        const key: string = priorityConfig?.extra?.type ?? 'orElse';
        return priorityToColor[key];
    }


    const getColorByPriority = (priority?: string): string => {
        const priorityObj = appController.ticketPriorityConfigs.find((item) => item.id === priority);
        const priorityType: string | null = priorityObj?.extra?.type ?? null;
        if (priorityType === TicketPriorityType.low) {
            return SharedColors.textCloseStatus;
        }
        if (priorityType === TicketPriorityType.high) {
            return SharedColors.textAttendingStatus;
        }
        if (priorityType === TicketPriorityType.urgent) {
            return SharedColors.textCancelStatus;
        }
        return SharedColors.textOpenStatus;
    }

    return (
        <Container
            background={colors.onBackground}
            onClick={disabled ? () => {
            } : () => onClick()}
            isDarkenBackground={ticket.id === idTicketSelected}
            disabled={disabled}
        >
            <ContainerAvatar>
                <AvatarImage
                    useCursorPointer={!!ticket.userClient?.urlImageProfile}
                    title={ticket.userClient?.name ?? ''}
                    size={49}
                    letterSize={20}
                    url={ticket.userClient?.urlImageProfile}
                    round={true}
                    hasPreviewImage={true}
                    withBorder={true}
                    alertPulseAnimation={!!ticket.lastMessage?.isSystemMessage}
                />
            </ContainerAvatar>
            <ContainerData bordercolor={colors.border}>

                <ContentNameAndHour>
                    <ContentName>
                        <Tooltip title={ticket.userClient?.name ?? ''} placement="right">
                            <ContentLineClamp color={colors.text} height={23}>
                                <HighLightWords
                                    searchWords={searchParam}
                                    textToHighlight={ticket.userClient?.name ?? ''}
                                />
                            </ContentLineClamp>
                        </Tooltip>

                    </ContentName>

                    <ContentDate height={23}>
                        <Text
                            color={colors.hint}>{formatDateChat(ticket.lastMessage?.createdAt ?? new Date().toISOString())}</Text>
                    </ContentDate>
                </ContentNameAndHour>

                {/*<ContentSectorDateDue>*/}
                {/*    <ContentNameSector>*/}
                {/*        <Tooltip title={ticket.sector?.name ?? ''} placement="right">*/}
                {/*            <ContentLineClamp height={23} color={colors.text}>*/}
                {/*                <HighLightWords*/}
                {/*                    searchWords={searchParam}*/}
                {/*                    textToHighlight={ticket.sector?.name ?? ''}*/}
                {/*                />*/}
                {/*            </ContentLineClamp>*/}
                {/*        </Tooltip>*/}

                {/*    </ContentNameSector>*/}
                {/*    <ContentDate height={23}>*/}
                {/*        <TextDate color={ticket.overdue ? colors.textError : colors.hint}>*/}
                {/*            {!!ticket.dateDue &&*/}
                {/*                <Tooltip title={ticket.overdue ? "Prazo vencido" : "Data de vencimento"}*/}
                {/*                         placement="right">*/}
                {/*                    {displayDateToLocale(ticket.dateDue)}*/}
                {/*                </Tooltip>*/}
                {/*            }*/}
                {/*        </TextDate>*/}
                {/*    </ContentDate>*/}
                {/*</ContentSectorDateDue>*/}

                <ContentMessage>
                    <ContentSocialMessage>
                        <ContentIconStatus>
                            {
                                !ticket.lastMessage?.deleted &&
                                !ticket.lastMessage?.isPrivate &&
                                ticket.lastMessage?.senderId !== ticket.userClient?.id &&
                                !ticket.lastMessage?.reactionMessage &&
                                !ticket.lastMessage?.isSystemMessage &&
                                <Status
                                    hint={colors.hint}
                                    status={ticket.lastMessage?.getStatus(ticket.userClient!, userLogged.role, ticket.attendant)}
                                />}

                            {ticket.lastMessage?.deleted &&
                                <DropDeskIcon icon={ICONS_DROPDESK.block} size={11} color={colors.hint}/>}
                        </ContentIconStatus>
                        <ContentLineClamp height={20} color={colors.hint} style={{maxWidth: 200}}>
                            <Tooltip title={ticket.lastMessage?.socialMessage ?? ''} placement="right">
                                <TextMessage
                                    color={colors.hint}>{ticket.lastMessage?.socialMessage ?? ''}</TextMessage>
                            </Tooltip>
                        </ContentLineClamp>
                    </ContentSocialMessage>
                    <ContentDate height={20}>
                        <ContentPriorityAndBadge>
                            {!!ticket.dateDue &&
                                <PopOver
                                    placement="top"
                                    trigger={["hover"]}
                                    content={
                                        <ContentInput style={{padding: 16}}>
                                            <InputWithBorder
                                                name='dateDue'
                                                size={37}
                                                readOnly={true}
                                                sizeRightIcons={12}
                                                marginRightRightIcons={10}
                                                label={`Data de vencimento${ticket.overdue ? ' (Prazo vencido)' : ''}`}
                                                color={ticket.overdue ? colors.textError : undefined}
                                                value={ticket.dateDue ? displayDateToLocale(ticket.dateDue ?? '') : ''}
                                                onChange={() => {
                                                }}
                                            />
                                        </ContentInput>
                                    }
                                    onOpenChange={() => {
                                    }}
                                >
                                    <ContentDateDue
                                        height={23}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}>
                                        <ContainerButtonDateDue
                                            accent={colors.accent}
                                            background={colors.buttonOnBackground}>
                                            <DropDeskIcon
                                                icon={ICONS_DROPDESK.alarmClock}
                                                size={14}
                                                color={ticket.overdue ? SharedColors.textError : SharedColors.success}
                                                style={{cursor: 'pointer', display: 'flex'}}/>
                                        </ContainerButtonDateDue>
                                    </ContentDateDue>
                                </PopOver>
                            }
                            <ContentPriority
                                background={getBackgroundColorPriority(ticket.priorityObject?.id)}>
                                <Text style={{display: "contents", lineHeight: 14}}
                                      color={getColorByPriority(ticket.priorityObject?.id)}>
                                    {ticket.getPriority}
                                </Text>
                            </ContentPriority>
                            {!!ticket.countUnreadNotification && idTicketSelected !== ticket.id && <ContentBadge>
                                <BadgeStyled count={ticket.countUnreadNotification} overflowCount={99} size="small"/>
                            </ContentBadge>}
                        </ContentPriorityAndBadge>
                    </ContentDate>
                </ContentMessage>

            </ContainerData>
        </Container>
    )
}
export default ChatList;
