import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyConfigurationRepository} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";
import {PortalClientConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/portal_client_configuration.entity";
import {CompanyConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";

@injectable()
export class SetPortalClientConfigurationUseCase {
  private _repository: CompanyConfigurationRepository;

  constructor(@inject(CompanyConfigurationRepository) repository: CompanyConfigurationRepository) {
    this._repository = repository;
  }

  public call(configurationPortalClient: PortalClientConfigurationEntity): Promise<CompanyConfigurationEntity> {
    return this._repository.setConfigurationPortalClient(configurationPortalClient);
  }
}
