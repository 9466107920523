import React from "react";
import {ContentArrowDown} from "./styled";
import {Tooltip} from "antd";
import DropDeskIcon from "@dropDesk/storage/icons";

const RightIcon = (
    {
        disabled,
        readOnly,
        onClick,
        onClickRightIcons,
        size,
        nameIconRight,
        sizeRightIcons,
        tooltilRightIcon
    }: {
        disabled?: boolean;
        readOnly?: boolean;
        onClick?: () => void;
        onClickRightIcons?: () => void;
        size: number;
        nameIconRight: string;
        sizeRightIcons?: number;
        tooltilRightIcon?: string;
    }
) => {

    return (
        <Tooltip title={tooltilRightIcon ?? ''}>
            <ContentArrowDown
                style={{
                    cursor: disabled ? "no-drop" : "pointer",
                }}
                onClick={disabled ? () => {
                } : readOnly ? onClick : onClickRightIcons}
                size={size ?? 22}
                iconColor={"transparent"}
            >
                <DropDeskIcon
                    icon={nameIconRight}
                    size={sizeRightIcons ?? 16}
                    style={{
                        cursor: disabled ? "no-drop" : "pointer",
                    }}
                />
            </ContentArrowDown>
        </Tooltip>
    )
}
export default RightIcon;
