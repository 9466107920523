import {
    Container, ContainerItem, ContainerReactions,
    Content, ContentIcon, ContentImageProfile, ContentItem, ContentName, PopOver,
    Reaction, TextReaction, TextRemoveReaction, TextTab
} from "@dropDesk/presentation/components/messages/message_box/react_message/list/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import React, {useState} from "react";
import Tabs, {Items} from "@dropDesk/presentation/components/tabs/background_style";
import TabBorderLine from "@dropDesk/presentation/components/tabs/border_style";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import {Tooltip} from "antd";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {ContentLineClamp} from "@dropDesk/presentation/components/chat_list/styled";
import {observer} from "mobx-react";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";

const ReactMessage = observer((
    {
        position,
        isDarkTheme,
        currentUserId,
        canRemoveReaction,
        handleRemoveReaction,
        reactions
    }: {
        position: 'left' | 'right'
        isDarkTheme: boolean
        canRemoveReaction: boolean
        currentUserId: string;
        handleRemoveReaction: (reaction: TicketMessageEntity) => Promise<void>
        reactions: TicketMessageEntity[]
    }) => {

    const appController = useInjection(AppController);

    const colors = appController.theme.colorScheme;
    const [visiblePopover, setVisiblePopover] = useState<boolean>(false);

    const getReactionGrouped = (): Array<Items> => {
        let reactionsGrouped: Record<string, TicketMessageEntity[]> = {
            'Todos': reactions
        };

        reactions.forEach(reaction => {
            const text = reaction.reactionMessage!.text;

            if (reactionsGrouped[text]) {
                reactionsGrouped[text] = [...reactionsGrouped[text], reaction];
            } else {
                reactionsGrouped[text] = [reaction];
            }
        });

        reactionsGrouped = orderReactionByCount(reactionsGrouped);
        unshiftReactionById(reactionsGrouped);
        return convertReactionGroupedToItemTab(reactionsGrouped);
    }

    const orderReactionByCount = (reactionsGrouped: Record<string, TicketMessageEntity[]>): Record<string, TicketMessageEntity[]> => {
        const keyValuePairs = Object.entries(reactionsGrouped);
        keyValuePairs.sort((a, b) => b[1].length - a[1].length);
        const sortedReactionsGrouped: Record<string, TicketMessageEntity[]> = Object.fromEntries(keyValuePairs);
        return sortedReactionsGrouped;
    }

    const convertReactionGroupedToItemTab = (reactionsGrouped: Record<string, TicketMessageEntity[]>): Array<Items> => {
        const newItems: Array<Items> = Object.keys(reactionsGrouped).map((key, index) => {
            const reaction = reactionsGrouped[key];
            return {
                key: (index + 1).toString(),
                label: <>{key}&nbsp;&nbsp; {reaction.length}</>,
                children: layoutChildrenItem(reaction)
            }
        });

        return newItems;
    }

    const unshiftReactionById = (reactionsGrouped: Record<string, TicketMessageEntity[]>): void => {
        for (const key in reactionsGrouped) {
            const reaction = reactionsGrouped[key];
            const index = reaction.findIndex(item => item.senderId === currentUserId);

            if (index !== -1) {
                const itemRemoved = reaction.splice(index, 1)[0];
                reaction.unshift(itemRemoved);
            }
        }

    }

    const layoutChildrenItem = (reactions: TicketMessageEntity[]): React.ReactNode => {
        return (
            <ContainerItem>
                {reactions.map((reaction) => {
                    const isEnableCurrentUserReaction: boolean = currentUserId === reaction.senderId && canRemoveReaction;
                    return (
                        <ContentItem
                            key={reaction.id}
                            style={{cursor: isEnableCurrentUserReaction ? 'pointer' : 'auto'}}
                            onClick={async () => {
                                if (isEnableCurrentUserReaction) {
                                    setVisiblePopover(false);
                                    await handleRemoveReaction(reaction);
                                }
                            }}>
                            <ContentImageProfile>
                                <AvatarImage
                                    useCursorPointer={!!reaction.sender?.urlImageProfile}
                                    title={reaction.sender?.name ?? ''}
                                    size={35}
                                    letterSize={12}
                                    url={reaction.sender?.urlImageProfile}
                                    round={true}
                                    hasPreviewImage={true}
                                    withBorder={true}
                                />
                            </ContentImageProfile>
                            <ContentName>
                                <ContentLineClamp color={colors.text} height={23}>
                                    <Tooltip title={reaction.sender?.name ?? ''}>
                                        <HighLightWords
                                            searchWords={''}
                                            textToHighlight={reaction.sender?.name ?? ''}
                                        />
                                    </Tooltip>
                                </ContentLineClamp>

                                {isEnableCurrentUserReaction && <TextRemoveReaction color={colors.hint}>
                                    Clique para remover
                                </TextRemoveReaction>}
                            </ContentName>
                            <ContentIcon>
                                {reaction.reactionMessage!.text}
                            </ContentIcon>
                        </ContentItem>
                    )
                })}
            </ContainerItem>
        )
    }


    return (
        <>
            {reactions.length > 0 && <PopOver
                color={colors.backgroundInput}
                position={position}
                marginSize={35}
                placement="top"
                getPopupContainer={trigger => trigger}
                trigger={["click"]}
                title=""
                showArrow={false}
                open={visiblePopover}
                onOpenChange={(value) => {
                    setVisiblePopover(value);
                }}
                content={
                    <ContainerReactions isDarkTheme={isDarkTheme} hint={colors.hint}>
                        <TabBorderLine
                            defaultActiveKey="1"
                            destroyInactiveTabPane={true}
                            height={40}
                            items={getReactionGrouped()}
                        />
                    </ContainerReactions>
                }>
                <Container onClick={() => {
                    setVisiblePopover(true);
                }}>
                    <Content
                        background={colors.backgroundInput}
                        position={position}
                        isDarkTheme={isDarkTheme}
                        spacePosition={10}
                        hint={colors.hint}
                    >
                        <TextReaction color={colors.hint}>{reactions.length}</TextReaction>
                        {reactions.map((entry) => <Reaction key={entry.id}>{entry.reactionMessage!.text}</Reaction>)}
                    </Content>
                </Container>

            </PopOver>
            }
        </>
    )

});
export default ReactMessage;
