import HeaderModal from "../../headers/header_modal";
import {Container, Content, TextInformation, ContentInformation} from "./styled";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import {ModalProps} from "@dropDesk/presentation/components/modals/modal_information_report/modal_information_report";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


export default function ModalInformationReport(props: ModalProps) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ModalMain
            open={props.open}
            onRequestClose={props.onRequestClose}
            width={400}
            renderComponent={<Container background={colors.onBackground}>
                <HeaderModal
                    disabled={props.loading}
                    letter={props.textHeaderModal}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={props.onRequestClose}
                />
                <Content>
                    <ContentInformation>
                        <TextInformation color={colors.text}>{props.textInformationHeader}</TextInformation>
                    </ContentInformation>
                    <ContentInformation>
                        <TextInformation color={colors.text}>{props.textInformationBody}</TextInformation>
                    </ContentInformation>

                </Content>
                <FooterModal
                    disabled={props.loading}
                    loading={props.loading}
                    type={"submit"}
                    letter={props.textButton}
                    onSubmit={props.onRequestSave}
                    onRequestClose={props.onRequestClose}
                />
            </Container>}
        />
    );
}
