import styled from "styled-components";

type ContainerType = {
    background: string;
    border: string;
    isDarkTheme: boolean;
    marginTop: number;
}

export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  height: min-content;
  width: 300px;
  min-width: 300px;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  margin: ${props => `${props.marginTop}px 16px 0 16px`};
  border-radius: 5px;
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled.img`
  display: flex;
  height: 100%;
  pointer-events: none;
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const ContentImage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 130px;
  height: 35px;
`;
export const ContentTotalMonthly = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;
