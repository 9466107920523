import {
    Container, Content, Text
} from "./styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ButtonPay
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/invoice/button_pay";

const InvoicePaymentMethod = (
    {
        loading,
        disabled,
        pay,
        isEditExternal,
        hideButtonPay
    }: {
        loading: boolean
        disabled: boolean
        pay: () => void
        isEditExternal?: boolean
        hideButtonPay?: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Container>
            <Content>
                <Text size={16} color={colors.text}>Pagamentos com Boleto Bancário levam até 72 horas úteis
                    para serem compensados e então terem os serviços liberados.</Text>
            </Content>
            {!hideButtonPay && <ButtonPay
                loading={loading}
                pay={pay}
                isEditExternal={isEditExternal}
                disabled={disabled}
            />}
        </Container>
    );
}
export default InvoicePaymentMethod;
