import {Container, ContainerStar, ContentStar, TextDescription} from './styled'
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import React from "react";

import DropDeskIcon from "@dropDesk/storage/icons";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {isIntegerNumber} from "@dropDesk/utils/helpers/common";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export default function TagEvaluation(
    {
        value,
        fontSize,
        starSize
    }: {
        value: number
        fontSize?: number;
        starSize?: number;
    }
) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const color = SharedColors.rate;

    const getTagStar = (value: number): React.ReactNode => {
        const isIntegerValue = isIntegerNumber(value);
        if (value >= 0 && value < 2) {
            return (
                <ContainerStar>
                    <ContentStar>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        {isIntegerValue &&
                            <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>}
                        {!isIntegerValue &&
                            <DropDeskIcon icon={ICONS_DROPDESK.starHalf} size={starSize ?? 12} color={color}/>}
                        <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>
                    </ContentStar>
                    <TextDescription size={fontSize ?? 13} color={colors.hintInverse}>Ruim</TextDescription>
                </ContainerStar>
            )
        } else if (value >= 2 && value < 3) {
            return (
                <ContainerStar>
                    <ContentStar>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        {isIntegerValue &&
                            <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>}
                        {!isIntegerValue &&
                            <DropDeskIcon icon={ICONS_DROPDESK.starHalf} size={starSize ?? 12} color={color}/>}
                        <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>
                    </ContentStar>
                    <TextDescription size={fontSize ?? 13} color={colors.hintInverse}>Bom</TextDescription>
                </ContainerStar>
            )
        } else if (value >= 3 && value < 4) {
            return (
                <ContainerStar>
                    <ContentStar>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        {isIntegerValue &&
                            <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>}
                        {!isIntegerValue &&
                            <DropDeskIcon icon={ICONS_DROPDESK.starHalf} size={starSize ?? 12} color={color}/>}
                        <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>
                    </ContentStar>
                    <TextDescription size={fontSize ?? 13} color={colors.hintInverse}>Regular</TextDescription>
                </ContainerStar>
            )
        } else if (value >= 4 && value < 5) {
            return (
                <ContainerStar>
                    <ContentStar>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        {isIntegerValue &&
                            <DropDeskIcon icon={ICONS_DROPDESK.starOutline} size={starSize ?? 12} color={color}/>}
                        {!isIntegerValue &&
                            <DropDeskIcon icon={ICONS_DROPDESK.starHalf} size={starSize ?? 12} color={color}/>}
                    </ContentStar>
                    <TextDescription size={fontSize ?? 13} color={colors.hintInverse}>Ótimo</TextDescription>
                </ContainerStar>
            )
        } else {
            return (
                <ContainerStar>
                    <ContentStar>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                        <DropDeskIcon icon={ICONS_DROPDESK.star} size={starSize ?? 12} color={color}/>
                    </ContentStar>
                    <TextDescription size={fontSize ?? 13} color={colors.hintInverse}>Excelente</TextDescription>
                </ContainerStar>
            )
        }
    }

    return (
        <Container>
            {getTagStar(value)}
        </Container>
    )
}
