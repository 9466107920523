import {observer} from "mobx-react";

import React from "react";
import {Fields, TextRequiredFields, ContentNotation, ContentNote, ContentNoteData} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const Notation = observer(
    ({}: {}) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <ContentNotation color={colors.text}>
                <ContentNote>
                    <TextRequiredFields color={colors.text}>Nota Importante</TextRequiredFields>
                    <div style={{display: 'flex', height: 20, marginLeft: 2}}>
                        <DropDeskIcon icon={ICONS_DROPDESK.info}
                                      color={colors.textError}
                                      size={15}
                                      style={{display: 'flex', height: 20}}/>
                    </div>
                </ContentNote>
                <ContentNoteData>
                    <Fields color={colors.text}>Caso deseja importar endereços, é obrigatório informar todos
                        os dados de endereço contido no excel.</Fields>
                    <Fields color={colors.text}>O telefone informado deve conter código do pais, DDD e
                        número, exemplo: 5521988888888.
                    </Fields>
                    <Fields color={colors.text}>Se houver duplicidade no cadastro, baseado no telefone,
                        os dados cadastrais serão atualizadas no DropDesk e não criará um novo cadastro.</Fields>
                    <Fields color={colors.text}>Limite de 5000 linhas.</Fields>
                </ContentNoteData>
            </ContentNotation>
        )
    })
export default Notation;
