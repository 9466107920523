import styled from "styled-components";

type TextType = {
    size: number;
    color: string;
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;
export const ContentText = styled.div`
  display: flex;
  flex-direction: row;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  line-height: 35px;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => `${props.size}px`};
  color: ${props => props.color};
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FieldName = styled.div`
  display: flex;
  flex-direction: row;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
`;

export const FieldValue = styled.div`
  display: flex;
  flex-direction: row;
`;
