import React from "react";
import {ContentIconNoData, ContentNoData, ContentTitleNoData, TitleNoData} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

export default function NoDataGraphic(
    {loading, description}: { loading: boolean, description: string }) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentNoData>
            <ContentTitleNoData>
                <TitleNoData color={colors.text}>{description}</TitleNoData>
            </ContentTitleNoData>
            <ContentIconNoData>
                {loading && <SpinLoading
                    size={"large"}
                    color={colors.hintInverse}
                    tip={"Carregando"}
                />}

                {!loading &&
                    <>
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.emptyFolder}
                            size={100}
                            color={colors.hintInverse}
                        />
                    </>
                }


            </ContentIconNoData>

        </ContentNoData>
    )
}
