import styled from "styled-components";
import {InputDownArrowType} from "@dropDesk/presentation/components/inputs/input_with_border/input_with_border";

export const ContentArrowDown = styled.div<InputDownArrowType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  background: ${(props) => props.iconColor};
  min-height: ${(props) => `${props.size - 2}px`};
  border-radius: 0 4px 4px 0;
  cursor: pointer;
`;
