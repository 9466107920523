type MapTimeSpent = {
    value: number,
    label: string
}

export type Map<T> = {
    value: T,
    label: string
}

export const timeSpentMap: MapTimeSpent[] = [
    {value: 5, label: '+ 5 minutos'},
    {value: 10, label: '+ 10 minutos'},
    {value: 15, label: '+ 15 minutos'},
    {value: 20, label: '+ 20 minutos'},
    {value: 25, label: '+ 25 minutos'},
    {value: 30, label: '+ 30 minutos'},
    {value: 35, label: '+ 35 minutos'},
    {value: 40, label: '+ 40 minutos'},
    {value: 45, label: '+ 45 minutos'},
    {value: 50, label: '+ 50 minutos'},
    {value: 55, label: '+ 55 minutos'},
    {value: 60, label: '+ 1 hora'},
    {value: 75, label: '+ 1 hora e 15 minutos'},
    {value: 90, label: '+ 1 hora e 30 minutos'},
    {value: 105, label: '+ 1 hora e 45 minutos'},
    {value: 120, label: '+ 2 horas'},
    {value: 150, label: '+ 2 horas e 30 minutos'},
    {value: 180, label: '+ 3 horas'},
    {value: 210, label: '+ 3 horas e 30 minutos'},
    {value: 240, label: '+ 4 horas'},
    {value: 360, label: '+ 6 horas'},
    {value: 480, label: '+ 8 horas'},
    {value: 600, label: '+ 10 horas'},
    {value: 720, label: '+ 12 horas'},
    {value: 840, label: '+ 14 horas'},
    {value: 960, label: '+ 16 horas'},
    {value: 1200, label: '+ 20 horas'},
    {value: 1440, label: '+ 1 dia'},
    {value: 2880, label: '+ 2 dias'},
    {value: 4320, label: '+ 3 dias'},
    {value: 5760, label: '+ 4 dias'},
    {value: 7200, label: '+ 5 dias'},
    {value: 8640, label: '+ 6 dias'},
    {value: 10080, label: '+ 7 dias'},
    {value: 11520, label: '+ 8 dias'},
    {value: 12960, label: '+ 9 dias'},
    {value: 14400, label: '+ 10 dias'},
    {value: 15840, label: '+ 11 dias'},
    {value: 17280, label: '+ 12 dias'},
    {value: 18720, label: '+ 13 dias'},
    {value: 20160, label: '+ 14 dias'},
    {value: 21600, label: '+ 15 dias'},
    {value: 23040, label: '+ 16 dias'},
    {value: 24480, label: '+ 17 dias'},
    {value: 25920, label: '+ 18 dias'},
    {value: 27360, label: '+ 19 dias'},
    {value: 28800, label: '+ 20 dias'},
    {value: 30240, label: '+ 21 dias'},
    {value: 31680, label: '+ 22 dias'},
    {value: 33120, label: '+ 23 dias'},
    {value: 34560, label: '+ 24 dias'},
    {value: 36000, label: '+ 25 dias'},
    {value: 37440, label: '+ 26 dias'},
    {value: 38880, label: '+ 27 dias'},
    {value: 40320, label: '+ 28 dias'},
    {value: 41760, label: '+ 29 dias'},
    {value: 43200, label: '+ 30 dias'},
    {value: 44640, label: '+ 31 dias'},
];

export enum TicketStatusType {
    opened = 'opened',
    inProgress = 'inProgress',
    closed = 'closed',
    canceled = 'canceled'
}

export enum TicketStatusSubType {
    byInactive = 'byInactive',
    byAssistant = 'byAssistant',
    byClient = 'byClient',
    byAttendant = 'byAttendant',
    byWaitingReview = 'byWaitingReview',
}

export enum TicketPriorityType {
    urgent = 'urgent',
    low = 'low',
    normal = 'normal',
    high = 'high',
}


