export class ReportRateValueEntity {
  value!: number;
  percentage!: number;

  constructor({
                value,
                percentage,
              }: {
    value: number;
    percentage: number;
  }) {
    Object.assign(this, {
      value,
      percentage,
    });
  }
}

export class ReportTicketRateEntity {
  average!: number;
  totalCountValue!:number;
  oneRating!: ReportRateValueEntity;
  twoRating!: ReportRateValueEntity;
  threeRating!: ReportRateValueEntity;
  fourRating!: ReportRateValueEntity;
  fiveRating!: ReportRateValueEntity;

  constructor({
                average,
                oneRating,
                twoRating,
                threeRating,
                fourRating,
                fiveRating,
                totalCountValue
              }: {
    average: number;
    totalCountValue:number;
    oneRating: ReportRateValueEntity;
    twoRating: ReportRateValueEntity;
    threeRating: ReportRateValueEntity;
    fourRating: ReportRateValueEntity;
    fiveRating: ReportRateValueEntity;
  }) {
    Object.assign(this, {
      average,
      oneRating,
      twoRating,
      threeRating,
      fourRating,
      fiveRating,
      totalCountValue
    });
  }
}

