import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {CryptoRemoteDataSource} from "@dropDesk/data/data_source/common/crypto/crypto.datasource";
import {CryptoRepository} from "@dropDesk/domain/repositories/common/crypto/crypto.repository";

@injectable()
export class CryptoRepositoryImpl implements CryptoRepository {
    private _dataSource: CryptoRemoteDataSource;

    constructor(@inject(CryptoRemoteDataSource) dataSource: CryptoRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public decrypt(value: string): Promise<string> {
        try {
            return this._dataSource.decrypt(value);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

}
