import React from "react";
import './styles.css';
import {
    Container,
    ContentLogoBrand,
    MenuStyled,
    SiderStyled,
    SubMenuTitle
} from "@dropDesk/presentation/components/menu/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useTranslation} from "react-i18next";
import {getItem, MenuItem} from "@dropDesk/presentation/components/menu/get_item";
import type {MenuProps} from 'antd';
import {Layout} from 'antd';
import {useNavigate} from "react-router-dom";
import {getActiveRoute, isTicketChatRoute} from "@dropDesk/utils/helpers/common";
import {routeProtector} from "@dropDesk/presentation/routes/route_protector";
import {LogoMenu} from "@dropDesk/presentation/components/menu/logo";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const Menu = (
    {
        collapsed,
    }: {
        collapsed: boolean;
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const currentUser = appController.user;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const logoBrandUrl: string | null = appController.user?.company.configurations.theme.logoBrandUrl ?? null;


    const getIconMenu = (nameIcon: string, size?: number): React.ReactNode => {
        return (
            <span>
                   <DropDeskIcon icon={nameIcon} size={size ?? 22} removeColor={true}/>
            </span>
        )
    }

    const getTitleSubMenu = (routeName: string, key?: RoutesEnum, useBackgroundTransparent = false): React.ReactNode => {
        return (
            <SubMenuTitle
                style={{display: useBackgroundTransparent ? 'auto' : 'flex'}}
                color={getActiveRoute() === key ? colors.accent : colors.text}
                hovercolor={colors.accent}
                background={colors.background}
            >
                {routeName}
            </SubMenuTitle>
        )
    }

    const items: MenuItem[] = [
        routeProtector(currentUser, RoutesEnum.dashboard, false) ? getItem(t(`menu.${RoutesEnum.dashboard}`), RoutesEnum.dashboard, getIconMenu(ICONS_DROPDESK.dashboard)) : null,
        getItem(t(`menu.${RoutesEnum.tickets}`), RoutesEnum.tickets, getIconMenu(ICONS_DROPDESK.headset)),
        routeProtector(currentUser, RoutesEnum.profile, false) ? getItem(t(`menu.${RoutesEnum.profile}`), RoutesEnum.profile, getIconMenu(ICONS_DROPDESK.account)) : null,
        routeProtector(currentUser, RoutesEnum.chat, false) ? getItem(t(`menu.${RoutesEnum.chat}`), RoutesEnum.chat, getIconMenu(ICONS_DROPDESK.chat)) : null,
        routeProtector(currentUser, RoutesEnum.clients, false) ?
            getItem(t(`menu.${RoutesEnum.clientsdefault}`), RoutesEnum.clientsdefault, getIconMenu(ICONS_DROPDESK.users), [
                getItem(getTitleSubMenu(t(`menu.${RoutesEnum.clients}`), RoutesEnum.clients), RoutesEnum.clients, null, undefined, colors.brightness),
                getItem(getTitleSubMenu(t(`menu.${RoutesEnum.contacts}`), RoutesEnum.contacts), RoutesEnum.contacts, null, undefined, colors.brightness),
                getItem(getTitleSubMenu(t(`menu.${RoutesEnum.importclientsdefault}`), RoutesEnum.importclients), RoutesEnum.importclients, null, undefined, colors.brightness),
            ], colors.brightness) : null,
        routeProtector(currentUser, RoutesEnum.users, false) ? getItem(t(`menu.${RoutesEnum.users}`), RoutesEnum.users, getIconMenu(ICONS_DROPDESK.operator)) : null,
        routeProtector(currentUser, RoutesEnum.activeConnections, false) ? getItem(t(`menu.${RoutesEnum.activeConnections}`), RoutesEnum.activeConnections, getIconMenu(ICONS_DROPDESK.socialNetwork)) : null,
        routeProtector(currentUser, RoutesEnum.sectors, false) ? getItem(t(`menu.${RoutesEnum.sectors}`), RoutesEnum.sectors, getIconMenu(ICONS_DROPDESK.sitemap)) : null,
        routeProtector(currentUser, RoutesEnum.notices, false) ? getItem(t(`menu.${RoutesEnum.notices}`), RoutesEnum.notices, getIconMenu(ICONS_DROPDESK.megaphoneHorizontal)) : null,

        routeProtector(currentUser, RoutesEnum.reporttickets, false) ?
            getItem(t(`menu.${RoutesEnum.reportdefault}`), RoutesEnum.reportdefault, getIconMenu(ICONS_DROPDESK.growth), [
                getItem(getTitleSubMenu(t(`menu.${RoutesEnum.reportticketdefault}`), RoutesEnum.reporttickets), RoutesEnum.reporttickets, null, undefined, colors.brightness),
                getItem(getTitleSubMenu(t(`menu.${RoutesEnum.reportratedefault}`), RoutesEnum.reportrate), RoutesEnum.reportrate, null, undefined, colors.brightness),
                // getItem(getTitleSubMenu(t(`menu.${RoutesEnum.reportratedefault}`), undefined, true), RoutesEnum.reportratedefault, getIconMenu(ICONS_DROPDESK.starOutline), [
                //     getItem(getTitleSubMenu(t(`menu.${RoutesEnum.reportratesdefault}`), RoutesEnum.reportrate), RoutesEnum.reportrate, null, undefined, colors.brightness),
                //     getItem(getTitleSubMenu(t(`menu.${RoutesEnum.reportaggregatedefault}`), RoutesEnum.reportticketsrate), RoutesEnum.reportticketsrate, null, undefined, colors.brightness),
                // ], colors.brightness),
            ], colors.brightness) : null,
        routeProtector(currentUser, RoutesEnum.subscription, false) ? getItem(t(`menu.${RoutesEnum.subscription}`), RoutesEnum.subscription, getIconMenu(ICONS_DROPDESK.subscription)) : null,
        routeProtector(currentUser, RoutesEnum.configuration, false) ? getItem(t(`menu.${RoutesEnum.configuration}`), RoutesEnum.configuration, getIconMenu(ICONS_DROPDESK.setting)) : null,
    ];

    const onClickMenuItem: MenuProps['onClick'] = infoItem => {
        navigate(infoItem.key, {replace: true});
    };

    return (
        <Container style={{display: 'flex'}}>
            {isTicketChatRoute() &&
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    left: 0,
                    height: 100,
                    width: collapsed ? 80 : 250,
                    background: colors.isDarkTheme ? colors.onBackground : colors.delicateBackground,
                    zIndex: 0,
                }}>

                </div>}
            <Layout hasSider={true} style={{background: isTicketChatRoute() ? 'transparent' : colors.onBackground}}>
                <SiderStyled
                    border={colors.border}
                    breakpoint="lg"
                    collapsedWidth={80}
                    width={250}
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    background={colors.background}
                    style={{
                        height: '100%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        zIndex: 1
                    }}

                >
                    <div style={{background: colors.onBackground}}>
                        <ContentLogoBrand
                            background={colors.background}>
                            <LogoMenu logoBrandUrl={logoBrandUrl} collapsed={collapsed}/>
                        </ContentLogoBrand>
                    </div>
                    <MenuStyled
                        defaultSelectedKeys={[getActiveRoute()]}
                        selectedKeys={[getActiveRoute()]}
                        onClick={onClickMenuItem}
                        style={{
                            width: collapsed ? 80 : 250,
                            flex: "auto",
                            flexDirection: 'column',
                            border: 0,
                            fontSize: 15,
                            fontWeight: 'bold',
                            display: 'flex',
                        }}
                        items={items}
                        mode="vertical"
                        // getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                        //getPopupContainer={(trigger) => trigger}
                        background={colors.background}
                        color={colors.text}
                        backgroundonprimary={colors.background}
                        secondary={colors.accent}
                    />
                </SiderStyled>
            </Layout>
        </Container>
    )

}

export default Menu;
