import styled from "styled-components";
import {ButtonType} from "@dropDesk/presentation/components/footer/footer_modal/footer_modal";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type TextType = {
    color: string;
    bold?: boolean;
    size: number;
}

type Container = {
    isDarkTheme: boolean;
    shadow: string;
}

type BorderType = {
    bordercolor: string;
}


export const Container = styled.div`
  padding: 8px 16px;
  max-width: 300px;
  border-radius: 15px;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: ${props => props.bold ? 'bold' : 'auto'};
  color: ${props => props.color};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px 0;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;

export const Button = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: 13px;
  font-weight: bold;
  width: 80px;
  height: 25px;
  min-height: 25px;
  border-radius: 5px;
  letter-spacing: 1px;
  border: 0;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ContentTitle = styled.div<BorderType>`
  display: flex;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.bordercolor};
`;



