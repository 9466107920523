import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";
import {filtersEnum} from "@dropDesk/domain/entities/reports/report_ticket/report_ticket_enum";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";

export class DropDeskFiltersEntity {
    ticketFilterStatus?: string | null;
    ticketFilterPriority?: string | null;
    ticketListEmptySector?: boolean | null;
    sectorFilterListableForClients?: 'true' | 'false' | null;
    descriptionStaticFilterListableForClients?: 'true' | 'false' | null;
    noticeFilterDestiny?: NoticeDestiny | null = null;
    reportTicketFilterPerson?: filtersEnum | null = null;
    reportTicketFilterUser?: UserEntity | null = null;
    reportTicketFilterClient?: ClientEntity | null = null;
    reportTicketFilterSector?: SectorEntity | null = null;
    ticketEvaluationFilterValue?: number | null = null;
    reportTicketRateFilterUser?: UserEntity | null = null;

    public static fromLocalStorage(filtersStringify: string | null): DropDeskFiltersEntity {
        const json = filtersStringify ? JSON.parse(filtersStringify) : null;
        return new DropDeskFiltersEntity({
            ticketFilterStatus: json && json['ticketFilterStatus'] ? json['ticketFilterStatus'] : null,
            ticketFilterPriority: json && json['ticketFilterPriority'] ? json['ticketFilterPriority'] : null,
            ticketListEmptySector: json && json['ticketListEmptySector'] ? json['ticketListEmptySector'] : null,
            sectorFilterListableForClients: json && json['sectorFilterListableForClients'] ? json['sectorFilterListableForClients'] : null,
            descriptionStaticFilterListableForClients: json && json['descriptionStaticFilterListableForClients'] ? json['descriptionStaticFilterListableForClients'] : null,
            noticeFilterDestiny: json && json['noticeFilterDestiny'] ? json['noticeFilterDestiny'] : null,
            reportTicketFilterPerson: json && json['reportTicketFilterPerson'] ? json['reportTicketFilterPerson'] : null,
            reportTicketFilterUser: json && json['reportTicketFilterUser'] ? json['reportTicketFilterUser'] : null,
            reportTicketRateFilterUser: json && json['reportTicketRateFilterUser'] ? json['reportTicketRateFilterUser'] : null,
            reportTicketFilterClient: json && json['reportTicketFilterClient'] ? json['reportTicketFilterClient'] : null,
            reportTicketFilterSector: json && json['reportTicketFilterSector'] ? json['reportTicketFilterSector'] : null,
            ticketEvaluationFilterValue: json && json['ticketEvaluationFilterValue'] ? json['ticketEvaluationFilterValue'] : null,
        });
    }

    public static eraseFilters(): DropDeskFiltersEntity {
        return new DropDeskFiltersEntity({
            ticketFilterStatus: null,
            ticketFilterPriority: null,
            ticketListEmptySector: false,
            sectorFilterListableForClients: null,
            descriptionStaticFilterListableForClients: null,
            noticeFilterDestiny: null,
            reportTicketFilterPerson: null,
            reportTicketFilterUser: null,
            reportTicketRateFilterUser: null,
            reportTicketFilterClient: null,
            reportTicketFilterSector: null,
            ticketEvaluationFilterValue: null
        });
    }

    constructor({
                    ticketFilterStatus,
                    ticketFilterPriority,
                    ticketListEmptySector,
                    sectorFilterListableForClients,
                    descriptionStaticFilterListableForClients,
                    noticeFilterDestiny,
                    reportTicketFilterPerson,
                    reportTicketFilterUser,
                    reportTicketRateFilterUser,
                    reportTicketFilterClient,
                    reportTicketFilterSector,
                    ticketEvaluationFilterValue
                }: {
        ticketFilterStatus?: string | null;
        ticketFilterPriority?: string | null;
        ticketListEmptySector?: boolean | null;
        sectorFilterListableForClients?: 'true' | 'false' | null;
        descriptionStaticFilterListableForClients?: 'true' | 'false' | null;
        noticeFilterDestiny?: NoticeDestiny | null;
        reportTicketFilterPerson?: filtersEnum | null;
        reportTicketFilterUser?: UserEntity | null;
        reportTicketRateFilterUser?: UserEntity | null;
        reportTicketFilterClient?: ClientEntity | null;
        reportTicketFilterSector?: SectorEntity | null;
        ticketEvaluationFilterValue?: number | null;
    }) {
        Object.assign(this, {
            ticketFilterStatus,
            ticketFilterPriority,
            ticketListEmptySector,
            sectorFilterListableForClients,
            descriptionStaticFilterListableForClients,
            noticeFilterDestiny,
            reportTicketFilterPerson,
            reportTicketFilterUser,
            reportTicketRateFilterUser,
            reportTicketFilterClient,
            reportTicketFilterSector,
            ticketEvaluationFilterValue
        });
    }

    copyWith({
                 ticketFilterStatus,
                 ticketFilterPriority,
                 ticketListEmptySector,
                 sectorFilterListableForClients,
                 descriptionStaticFilterListableForClients,
                 noticeFilterDestiny,
                 reportTicketFilterPerson,
                 reportTicketFilterUser,
                 reportTicketRateFilterUser,
                 reportTicketFilterClient,
                 reportTicketFilterSector,
                 ticketEvaluationFilterValue,
                 replaceTicketFilterStatusIfNull,
                 replaceTicketFilterPriorityIfNull,
                 replaceTicketListEmptySectorIfNull,
                 replaceSectorFilterListableForClientsIfNull,
                 replaceDescriptionStaticFilterListableForClientsIfNull,
                 replaceNoticeFilterDestinyIfNull,
                 replaceReportTicketPersonIfNull,
                 replaceReportTicketUserIfNull,
                 replaceReportTicketRateUserIfNull,
                 replaceReportTicketClientIfNull,
                 replaceReportTicketSectorIfNull,
                 replaceTicketEvaluationFilterValueIfNull,
             }: {
        ticketFilterStatus?: string | null;
        ticketFilterPriority?: string | null;
        ticketListEmptySector?: boolean | null;
        sectorFilterListableForClients?: 'true' | 'false' | null;
        descriptionStaticFilterListableForClients?: 'true' | 'false' | null;
        replaceTicketFilterStatusIfNull?: boolean | null,
        replaceTicketFilterPriorityIfNull?: boolean | null,
        replaceTicketListEmptySectorIfNull?: boolean | null,
        replaceSectorFilterListableForClientsIfNull?: boolean | null,
        replaceDescriptionStaticFilterListableForClientsIfNull?: boolean | null,
        replaceNoticeFilterDestinyIfNull?: boolean | null,
        noticeFilterDestiny?: NoticeDestiny | null,
        reportTicketFilterPerson?: filtersEnum | null;
        reportTicketFilterUser?: UserEntity | null;
        reportTicketRateFilterUser?: UserEntity | null;
        reportTicketFilterClient?: ClientEntity | null;
        reportTicketFilterSector?: SectorEntity | null;
        ticketEvaluationFilterValue?: number | null;
        replaceReportTicketPersonIfNull?: boolean | null;
        replaceReportTicketUserIfNull?: boolean | null;
        replaceReportTicketRateUserIfNull?: boolean | null;
        replaceReportTicketClientIfNull?: boolean | null;
        replaceReportTicketSectorIfNull?: boolean | null;
        replaceTicketEvaluationFilterValueIfNull?: boolean | null;
    }): DropDeskFiltersEntity {
        return new DropDeskFiltersEntity({
            ticketFilterStatus: replaceTicketFilterStatusIfNull ? ticketFilterStatus : this.ticketFilterStatus,
            ticketFilterPriority: replaceTicketFilterPriorityIfNull ? ticketFilterPriority : this.ticketFilterPriority,
            ticketListEmptySector: replaceTicketListEmptySectorIfNull ? ticketListEmptySector : this.ticketListEmptySector,
            sectorFilterListableForClients: replaceSectorFilterListableForClientsIfNull ? sectorFilterListableForClients : this.sectorFilterListableForClients,
            descriptionStaticFilterListableForClients: replaceDescriptionStaticFilterListableForClientsIfNull ? descriptionStaticFilterListableForClients : this.descriptionStaticFilterListableForClients,
            noticeFilterDestiny: replaceNoticeFilterDestinyIfNull ? noticeFilterDestiny : this.noticeFilterDestiny,
            reportTicketFilterSector: replaceReportTicketSectorIfNull ? reportTicketFilterSector : this.reportTicketFilterSector,
            reportTicketFilterPerson: replaceReportTicketPersonIfNull ? reportTicketFilterPerson : this.reportTicketFilterPerson,
            reportTicketFilterClient: replaceReportTicketClientIfNull ? reportTicketFilterClient : this.reportTicketFilterClient,
            reportTicketFilterUser: replaceReportTicketUserIfNull ? reportTicketFilterUser : this.reportTicketFilterUser,
            reportTicketRateFilterUser: replaceReportTicketRateUserIfNull ? reportTicketRateFilterUser : this.reportTicketRateFilterUser,
            ticketEvaluationFilterValue: replaceTicketEvaluationFilterValueIfNull ? ticketEvaluationFilterValue : this.ticketEvaluationFilterValue,
        })
    }
}
