import React, {MutableRefObject} from "react";
import {
    ContainerInput, ContentInput,
} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {observer} from "mobx-react";
import CheckBoxPrivateComment from "@dropDesk/presentation/components/check_box/check_box_private_comment";
import {FileEditionEntity} from "@dropDesk/domain/entities/image_editor/image_editor.entity";


const InputAndCheckBox = observer((
    {
        currentFileEdition,
        handleAllChangesFile,
        loading,
        isUserClient,
        referenceInput
    }: {

        currentFileEdition: FileEditionEntity | null
        handleAllChangesFile: (event: { target: { name: string; value: any; checked: any; type: string } }) => void,
        loading: boolean
        isUserClient: boolean
        referenceInput: MutableRefObject<HTMLInputElement | null>

    }
) => {


    return (

        <ContainerInput>
            <ContentInput>
                <InputWithBorder
                    reference={referenceInput}
                    autoFocus={true}
                    name='description'
                    size={35}
                    disabled={loading}
                    placeHolder={"Mensagem"}
                    value={currentFileEdition?.fileMessage.description ?? ''}
                    onChange={handleAllChangesFile}
                />
            </ContentInput>
            {!isUserClient && <CheckBoxPrivateComment
                checked={currentFileEdition?.fileMessage.isPrivate ?? false}
                disabled={loading}
                onChange={(event) => {
                    handleAllChangesFile({
                        target: {
                            name: 'isPrivate',
                            value: event.target.value,
                            checked: event.target.checked,
                            type: 'switch'
                        }
                    });
                }}
            />}
        </ContainerInput>

    )
});

export default InputAndCheckBox;
