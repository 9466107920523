import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {CompanyConfigurationRepository} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";

@injectable()
export class SetCompanyConfigurationsUseCase {
  private _repository: CompanyConfigurationRepository;

  constructor(@inject(CompanyConfigurationRepository) repository: CompanyConfigurationRepository) {
    this._repository = repository;
  }

  public call(companyConfigurations: CompanyConfigurationEntity): Promise<CompanyConfigurationEntity> {
    return this._repository.set(companyConfigurations);
  }
}
