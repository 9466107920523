import {StatusTypes} from "@dropDesk/domain/entities/chat/chat_enum";

export class ConnectionExtraInfoEntity {
    isConnectedToAnotherAccount?: boolean;

    constructor({isConnectedToAnotherAccount}: {
        isConnectedToAnotherAccount?: boolean
    }) {
        Object.assign(this, {
            isConnectedToAnotherAccount
        });
    }

    public static fromJson(json: Record<string, any>): ConnectionExtraInfoEntity {
        return new ConnectionExtraInfoEntity({
            isConnectedToAnotherAccount: json['isConnectedToAnotherAccount'] ?? false
        });
    }
}

export class ChatIntegrationConnectionEntity {
    idCompany!: string;
    qrCode!: string | null;
    idConnection?: string;
    status!: StatusTypes;
    updatedAt?: string;
    createdAt?: string;
    extraInfo?: ConnectionExtraInfoEntity;

    public get hasActiveConnection(): boolean {
        return this.status === StatusTypes.CONNECTED;
    }

    public get duplicated(): boolean {
        return !!this.extraInfo?.isConnectedToAnotherAccount;
    }

    public get hasErrorConnection(): boolean {
        return this.status === StatusTypes.ERROR || this.status === StatusTypes.CRASHED;
    }

    public get hasInactiveConnection(): boolean {
        return this.status === StatusTypes.DISCONNECTED || this.status === StatusTypes.UNAUTHENTICATED ||
            this.status === StatusTypes.LOGGED_OUT;
    }

    public get isDisconnected(): boolean {
        return this.status === StatusTypes.DISCONNECTED;
    }

    public get isConnecting(): boolean {
        return this.status === StatusTypes.CONNECTING || this.status === StatusTypes.RECONNECTING;
    }

    public get isTimeout(): boolean {
        return this.status === StatusTypes.TIMED_OUT;
    }

    constructor({
                    idCompany,
                    qrCode,
                    idConnection,
                    status,
                    updatedAt,
                    createdAt,
                    extraInfo
                }: {
        idCompany: string;
        qrCode: string | null;
        idConnection?: string;
        status: StatusTypes;
        updatedAt?: string;
        createdAt?: string;
        extraInfo?: ConnectionExtraInfoEntity;
    }) {
        Object.assign(this, {
            idCompany,
            qrCode,
            idConnection,
            status,
            updatedAt,
            createdAt,
            extraInfo
        });
    }

    copyWith({
                 idCompany,
                 qrCode,
                 idConnection,
                 status,
                 updatedAt,
                 createdAt,
                 extraInfo
             }: {
        idCompany?: string;
        qrCode?: string | null;
        idConnection?: string;
        status?: StatusTypes;
        updatedAt?: string;
        createdAt?: string;
        extraInfo?: ConnectionExtraInfoEntity;

    }): ChatIntegrationConnectionEntity {
        return new ChatIntegrationConnectionEntity({
            idCompany: idCompany ?? this.idCompany,
            qrCode: qrCode ?? this.qrCode,
            idConnection: idConnection ?? this.idConnection,
            status: status ?? this.status,
            updatedAt: updatedAt ?? this.updatedAt,
            createdAt: createdAt ?? this.createdAt,
            extraInfo: extraInfo ?? this.extraInfo,
        })
    }
}
