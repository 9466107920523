import {observer} from "mobx-react";
import React from "react";
import {
    ContentExportImport, ContentFilter,
    ContentInput,
    ContentInputAndListableTicketsDescriptionStaticDeleted,
    ContentInputSearchAndFilter, ContentInputSearch,
    PaddingFilters
} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useBetween} from "use-between";
import {
    useModalSearchTicketsDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/handle_change_visible";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {listableForClientsMap} from "@dropDesk/domain/entities/sector/sector_maps";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const SearchBar = observer(
    ({
         handleVisibleModalNewTicketsDescriptionStatic,
         onSearch,
         visibleInputSearch,
         visibleButtonsOfActions,
         visibleButtonDeleted,
         visibleButtonRestore,
         loading,
         filterListableForClients,
         setFilterListableForClients,
     }: {
        handleVisibleModalNewTicketsDescriptionStatic: (visible: boolean, id: string) => void,
        onSearch: (searchParam: string) => void,
        visibleInputSearch: boolean,
        visibleButtonsOfActions: boolean,
        visibleButtonDeleted: boolean,
        visibleButtonRestore: boolean,
        loading: boolean,
        setFilterListableForClients: (filter: 'true' | 'false' | null) => void,
        filterListableForClients: 'true' | 'false' | null
    }) => {

        const isNewRegister = 'new';
        const {
            setVisibleModalDelete,
            setVisibleModalRestore,
        } = useBetween(useModalSearchTicketsDescriptionStatic);


        return (
            <ContentFilter>
                <ContentInputAndListableTicketsDescriptionStaticDeleted>
                    <ContentInput>
                        {visibleInputSearch &&
                            <ContentInputSearchAndFilter>
                                <ContentInputSearch>
                                    <InputSearchGeneric
                                        inputSearchPlaceholder="Pesquise pelo problema"
                                        loading={loading}
                                        onSearch={(value) => onSearch(value)}
                                        routeName={RoutesEnum.descriptionstatic}
                                    />
                                </ContentInputSearch>
                                <PaddingFilters>
                                    <SelectSearchMain<'true' | 'false'>
                                        size={40}
                                        width={275}
                                        useRemoveFilter={!!filterListableForClients}
                                        placeHolder={"Filtro de Exibição de Problemas"}
                                        options={listableForClientsMap}
                                        removeSort={true}
                                        onClickRemoveFilter={() => {
                                            setFilterListableForClients(null);
                                        }}
                                        onChange={(value) => {
                                            setFilterListableForClients(value);
                                        }}
                                        value={filterListableForClients}
                                    />
                                </PaddingFilters>
                            </ContentInputSearchAndFilter>
                        }

                        {visibleButtonDeleted && <ButtonNew
                            marginLeft={0}
                            label={'Excluir'}
                            onClick={() => setVisibleModalDelete(true)}
                            nameLeftIcon={ICONS_DROPDESK.delete}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}
                        {visibleButtonRestore && <ButtonNew
                            marginLeft={0}
                            label={'Restaurar'}
                            onClick={() => setVisibleModalRestore(true)}
                            nameLeftIcon={ICONS_DROPDESK.restore}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}


                    </ContentInput>

                </ContentInputAndListableTicketsDescriptionStaticDeleted>
                <ContentExportImport>
                    {visibleButtonsOfActions && <ButtonNew
                        marginLeft={0}
                        label={'Novo Problema'}
                        onClick={() => {
                            handleVisibleModalNewTicketsDescriptionStatic(true, isNewRegister)
                        }}
                        nameLeftIcon={''}
                        sizeLeftIcon={16}
                        disabled={false}
                        isButtonMain={true}
                    />}
                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBar;
