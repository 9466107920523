import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import {TicketEvaluationRemoteDataSource} from "@dropDesk/data/data_source/ticket/evaluation/ticket_evaluation_remote.datasource";
import {TicketEvaluationRepository} from "@dropDesk/domain/repositories/ticket/evaluation/ticket_evaluation.repository";


@injectable()
export class TicketEvaluationRepositoryImpl implements TicketEvaluationRepository {

  private _dataSource: TicketEvaluationRemoteDataSource;

  constructor(@inject(TicketEvaluationRemoteDataSource) dataSource: TicketEvaluationRemoteDataSource) {
    this._dataSource = dataSource;
  }


  public list(
    page: number,
    searchParam: string,
    limit: number,
    searchEvaluationValue: number | null,
  ): Promise<ListPaginationEntity<TicketEvaluationEntity>> {
    try {
      return this._dataSource.list(page, searchParam, limit, searchEvaluationValue);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }


  public set(evaluation: TicketEvaluationEntity): Promise<TicketEvaluationEntity> {
    try {
      return this._dataSource.set(evaluation);
    } catch (e: any) {
      throw new ServerError(e);
    }
  }

  public findByPK(id: string): Promise<TicketEvaluationEntity> {
    try {
      return this._dataSource.findByPK(id);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }

}
