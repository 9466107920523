import "reflect-metadata";
import {inject, injectable} from "inversify";
import { AuthRepository } from "@dropDesk/domain/repositories/auth/auth.repository";


@injectable()
export class RecoveryAccountUseCase {
  private _repository: AuthRepository;

  constructor(
    @inject(AuthRepository) repository: AuthRepository
  ) {
    this._repository = repository;
  }

  public call(email:string): Promise<void> {
    return this._repository.recoveryAccount(email);
  }
}
