import {observer} from "mobx-react";
import React from "react";
import {
    TextPermission, ContentDisplayName, Container, ContentInput, ContainerPopOver
} from "./styled";
import Switch from "@dropDesk/presentation/components/switch";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {
    inactivityMap,
    inactivityTransferSectorMap
} from "@dropDesk/domain/entities/company_configurations/company_configuration_maps";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {Popover, Tooltip} from "antd";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {useBetween} from "use-between";
import {
    useModalWhatsApp
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/handle_change_visible";
import {HighLightHashTag} from "@dropDesk/presentation/components/high_light_hashtag";


const InputsWhatsApp = observer(
    ({
         handleAllChangesWhatsAppConfiguration,
         companyConfiguration,
         loading,
     }:
         {
             handleAllChangesWhatsAppConfiguration: (event: {
                 target: { name: string; value: any; checked?: any; type: string }
             }) => void
             companyConfiguration: CompanyConfigurationEntity,
             loading: boolean
         }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const {setVisibleModalSearchSector} = useBetween(useModalWhatsApp);


        return (
            <Container>

                <ContentDisplayName>
                    <Switch
                        disabled={loading}
                        id={'autoCloseInactiveAttending'}
                        name={'autoCloseInactiveAttending'}
                        checked={companyConfiguration.chat.autoCloseInactiveAttending}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                    <Tooltip
                        title={`Ao marcar esta opção, os atendimentos que estão na fila de 'ATENDENDO' do menu chat,
                         serão fechados automaticamente pela assistente virtual após atingir o tempo limite de
                          inatividade configurado ou então após a data de vencimento informada no atendimento.`}>
                        <TextPermission style={{marginLeft: 10, marginRight: 10}} color={colors.text}>Fechar
                            automaticamente
                            atendimentos
                            na fila 'Atendendo' após período limite de inatividade
                            <DropDeskIcon
                                color={colors.hint}
                                icon={ICONS_DROPDESK.interrogationOutline}/>
                        </TextPermission>
                    </Tooltip>
                    {companyConfiguration.chat.autoCloseInactiveAttending && <SelectSearchMain<number>
                        required={false}
                        size={37}
                        disabled={!companyConfiguration.chat.autoCloseInactiveAttending}
                        name={'inactivityTime'}
                        label={"limite de inatividade"}
                        options={inactivityMap}
                        width={190}
                        removeSort={true}
                        onChange={(value) => {
                            handleAllChangesWhatsAppConfiguration({
                                target: {
                                    name: 'inactivityTime',
                                    value: value,
                                    checked: false,
                                    type: 'input'
                                }
                            })
                        }}
                        value={companyConfiguration.chat.inactivityTime}
                    />}
                </ContentDisplayName>

                <ContentDisplayName>
                    <Switch
                        disabled={loading}
                        id={'autoCloseInactiveWaiting'}
                        name={'autoCloseInactiveWaiting'}
                        checked={companyConfiguration.chat.autoCloseInactiveWaiting}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                    <Popover
                        placement="top"
                        trigger="hover"
                        color={colors.backgroundInput}
                        content={
                            <ContainerPopOver>
                                <HighLightHashTag
                                    text={`#Opção ativada:# os atendimentos que estão na fila de 'ESPERA' do menu chat,
                                          serão fechados automaticamente pela assistente virtual após atingir o tempo limite de
                                         inatividade configurado ou então após a data de vencimento informada no atendimento`}
                                    fontSize={14}/>
                                <HighLightHashTag
                                    text={`#Opção desativada:# os atendimentos serão transferidos para o setor configurado,
                                           após atingir o tempo limite de inatividade`}
                                    fontSize={14}/>
                            </ContainerPopOver>
                        }
                    >
                        <TextPermission style={{marginLeft: 10, marginRight: 10}} color={colors.text}>Fechar
                            automaticamente atendimentos aguardando seleção de setor após período limite de inatividade
                            <DropDeskIcon
                                color={colors.hint}
                                icon={ICONS_DROPDESK.interrogationOutline}/>
                        </TextPermission>
                    </Popover>
                    {!companyConfiguration.chat.autoCloseInactiveWaiting &&
                        <ContentInput style={{width: 165, maxWidth: 165, minWidth: 165, marginRight: 10}}>
                            <InputWithBorder
                                required={false}
                                name='sector'
                                size={37}
                                onClick={() => setVisibleModalSearchSector(true)}
                                readOnly={true}
                                disabled={loading}
                                placeHolder={"Selecione o setor"}
                                nameIconRight={ICONS_DROPDESK.downArrow}
                                sizeRightIcons={14}
                                marginRightRightIcons={10}
                                label={"setor p/ transferência"}
                                value={companyConfiguration.chat?.sector?.realName(appController.user!) ?? ''}
                                useButtonRight={false}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>}
                    <SelectSearchMain<number>
                        required={false}
                        size={37}
                        disabled={false}
                        name={'inactivityTransferSectorTime'}
                        label={"limite de inatividade"}
                        options={inactivityTransferSectorMap}
                        width={190}
                        removeSort={true}
                        onChange={(value) => {
                            handleAllChangesWhatsAppConfiguration({
                                target: {
                                    name: 'inactivityTransferSectorTime',
                                    value: value,
                                    checked: false,
                                    type: 'input'
                                }
                            })
                        }}
                        value={companyConfiguration.chat.inactivityTransferSectorTime}
                    />
                </ContentDisplayName>

                <ContentDisplayName>
                    <Switch
                        disabled={loading}
                        id={'displayNameUserInMessage'}
                        name={'displayNameUserInMessage'}
                        checked={companyConfiguration.chat.displayNameUserInMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                    <TextPermission style={{marginLeft: 10}} color={colors.text}>Exibir nome do Atendente
                        nas mensagens</TextPermission>
                </ContentDisplayName>

            </Container>
        )
    })
export default InputsWhatsApp;
