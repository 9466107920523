import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {DownloadFileUseCase} from "@dropDesk/domain/use_case/io/download_file.usecase";
import {Subscription} from "rxjs";
import {FileProgress} from "@dropDesk/domain/entities/common/file_progress";
import {StartRecordVoiceListenerUseCase} from "@dropDesk/domain/use_case/io/start_record_voice_listener.usecase";
import {GetMediaStreamUseCase} from "@dropDesk/domain/use_case/io/get_media_stream.usecase";
import {
    AudioRecordEntity,
    AudioRecordStatus
} from "@dropDesk/domain/entities/common/audio_record.entity";
import {GetAudioFromMediaRecorderUseCase} from "@dropDesk/domain/use_case/io/get_audio_from_media_recorder.usecase";
import {StopRecordUseCase} from "@dropDesk/domain/use_case/io/stop_record.usecase";

configure({
    enforceActions: "always",
});

@injectable()
export class IoController {

    private readonly _downloadFileUseCase: DownloadFileUseCase;
    private readonly _getMediaStreamUseCase: GetMediaStreamUseCase;
    private readonly _startRecordVoiceListenerUseCase: StartRecordVoiceListenerUseCase;
    private readonly _getAudioFromMediaRecorderUseCase: GetAudioFromMediaRecorderUseCase;
    private readonly _stopRecordUseCase: StopRecordUseCase;

    constructor(
        @inject(DownloadFileUseCase) downloadFileUseCase: DownloadFileUseCase,
        @inject(StartRecordVoiceListenerUseCase) startRecordVoiceListenerUseCase: StartRecordVoiceListenerUseCase,
        @inject(GetMediaStreamUseCase) getMediaStreamUseCase: GetMediaStreamUseCase,
        @inject(GetAudioFromMediaRecorderUseCase) getAudioFromMediaRecorderUseCase: GetAudioFromMediaRecorderUseCase,
        @inject(StopRecordUseCase) stopRecordUseCase: StopRecordUseCase,
    ) {
        makeObservable(this);
        this._downloadFileUseCase = downloadFileUseCase;
        this._getMediaStreamUseCase = getMediaStreamUseCase;
        this._startRecordVoiceListenerUseCase = startRecordVoiceListenerUseCase;
        this._getAudioFromMediaRecorderUseCase = getAudioFromMediaRecorderUseCase;
        this._stopRecordUseCase = stopRecordUseCase;
    }

    newFileProgress = new FileProgress({
        progress: 0,
        inProgress: false,
        url: ''
    });

    newAudioRecorder = new AudioRecordEntity({
        status: AudioRecordStatus.record,
        mediaRecorder: undefined,
        stream: undefined,
        chunks: [],
        duration: 0
    });

    @observable
    loading = false;

    @observable
    audioRecorder: AudioRecordEntity = this.newAudioRecorder;

    @observable
    subscriptionAudioRecorder?: Subscription;


    @observable
    subscriptionFileDownload?: Subscription;

    @observable
    fileProgress: FileProgress = this.newFileProgress;


    @action
    setFileProgress(fileProgress: FileProgress) {
        this.fileProgress = fileProgress;
        if (!fileProgress.inProgress) {
            this.resetFileProgress();
        }
    }

    @action
    resetFileProgress() {
        this.fileProgress = this.newFileProgress;
    }

    @action
    setAudioRecorder(audioRecorder: AudioRecordEntity) {
        this.audioRecorder = audioRecorder;
    }

    @action
    resetAudioRecorder() {
        this.audioRecorder = this.newAudioRecorder;
    }


    @action
    startLoading() {
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
    }

    @action
    async saveAndSendAudio(sendAudio: () => void) {
        await this.stopRecordVoice(true);
        sendAudio();
        this.resetAudioRecorder();
    }

    @action
    async cancelAudio() {
        await this.stopRecordVoice(false);
        this.resetAudioRecorder();
    }

    @action
    async stopRecordVoice(withSaveAudio: boolean): Promise<void> {
        this._stopRecordUseCase.call(this.audioRecorder.mediaRecorder, this.audioRecorder.stream);
        if (withSaveAudio) {
            const audioRecorder = await this._getAudioFromMediaRecorderUseCase.call(this.audioRecorder);
            this.setAudioRecorder(audioRecorder);
        }
    }

    @action
    async observeStartRecordVoice() {
        try {
            this.subscriptionAudioRecorder?.unsubscribe();
            const stream = await this._getMediaStreamUseCase.call();
            this.subscriptionAudioRecorder = this._startRecordVoiceListenerUseCase.call(this.audioRecorder, stream).subscribe({
                next: (response) => {
                    this.setAudioRecorder(response);
                },
                error: (err) => {
                    toastMessage.error(err);
                    this.resetAudioRecorder();
                }
            });
        } catch (err: any) {
            toastMessage.error(err);
        }
    }


    @action
    observeDownloadFile(url: string, fileName: string) {
        this.subscriptionFileDownload?.unsubscribe();
        this.subscriptionFileDownload = this._downloadFileUseCase.call(url, fileName)
            .subscribe({
                next: (response) => {
                    this.setFileProgress(response);
                },
                error: (err: any) => {
                    toastMessage.error(err);
                    this.resetFileProgress();
                }
            });
    }


}
