import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {SectorRepository} from "@dropDesk/domain/repositories/sector/sector.repository";

@injectable()
export class DeleteSectorUseCase {
  private _repository: SectorRepository;

  constructor(@inject(SectorRepository) repository: SectorRepository) {
    this._repository = repository;
  }

  public call(sectors: SectorEntity[]): Promise<void> {
    return this._repository.delete(sectors);
  }
}

