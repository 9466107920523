import styled from "styled-components";

type ContainerType = {
    background: string;
};

type ContentType = {
    background: string;
};

type ContainerTypeFormUser = {
    background: string;
    border: string;
    heightShowingHeaderInfo: number;
    isUserClient?: boolean;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
`;
export const ContentFormUser = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0 32px;
`;

export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
  flex: 1;
  overflow: auto;
`;
export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;
export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  overflow: hidden;
`;

export const ContentHeaderAndInput = styled.div<ContentType>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: ${props => props.background};
  border-radius: 10px;
  min-height: 215px;
`;

export const ContentPermission = styled.div<ContentType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 10px;
  padding: 8px 16px;
  min-height: 150px;
  height: 150px;
  max-height: 150px;
`;

export const ContentNotification = styled.div<ContainerTypeFormUser>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 10px;
  margin: 0 0 8px 0;
  padding: 8px 16px;
`;
export const ContentFooter = styled.div<ContentType>`
  background: ${props => props.background};
  border-radius: 10px;
`;
export const ContentSectorsLinked = styled.div<ContentType>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: ${props => props.background};
  border-radius: 10px;
  padding: 8px 16px;
  margin: 0 0 6px 0;
  min-height: 160px;
  flex: 1;
`;

export const FormUser = styled.div<ContainerTypeFormUser>`
  height: ${props => props.isUserClient ? 'auto' : `calc(100vh - 77px - ${props.heightShowingHeaderInfo}px`});
  display: flex;
  min-width: 768px;
  flex-direction: column;
  overflow: auto;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-radius: 5px;
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`;
