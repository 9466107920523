import {useEffect, useState} from "react";
import {isValidMessageDescriptionOrThrow} from "@dropDesk/utils/helpers/validators";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";

export const useValidatorWhatsApp = (companyConfiguration: CompanyConfigurationEntity) => {

    const [errorChooseDepartmentMessage, setErrorChooseDepartmentMessage] = useState('');
    const [errorWelcomeMessage, setErrorWelcomeMessage] = useState('');
    const [errorClosedTicketMessage, setErrorClosedTicketMessage] = useState('');
    const [errorClosedIdleTicketMessage, setErrorClosedIdleTicketMessage] = useState('');
    const [errorAlertInactivityMessage, setErrorAlertInactivityMessage] = useState('');
    const [errorOutTimeOperatingMessage, setErrorOutTimeOperatingMessage] = useState('');
    const [errorRatingMessage, setErrorRatingMessage] = useState('');
    const [errorQueueTicketMessage, setErrorQueueTicketMessage] = useState('');
    const [errorTransferSectorMessage, setErrorTransferSectorMessage] = useState('');
    const [errorTransferUserMessage, setErrorTransferUserMessage] = useState('');
    const [errorSectorTransferLimitReached, setErrorSectorTransferLimitReached] = useState('');
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);


    useEffect(() => {
        validate();
    }, [companyConfiguration.chat]);

    const validate = (): boolean => {
        setErrorChooseDepartmentMessage('');
        setErrorWelcomeMessage('');
        setErrorClosedTicketMessage('');
        setErrorOutTimeOperatingMessage('');
        setErrorRatingMessage('');
        setErrorQueueTicketMessage('');
        setErrorTransferSectorMessage('');
        setErrorTransferUserMessage('');
        setErrorSectorTransferLimitReached('');
        setErrorClosedIdleTicketMessage('');
        setErrorAlertInactivityMessage('');

        if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.welcomeMessage)) {
            setErrorWelcomeMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.chooseDepartmentMessage)) {
            setErrorChooseDepartmentMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.closedTicketMessage)) {
            setErrorClosedTicketMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.outTimeOperatingMessage)) {
            setErrorOutTimeOperatingMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.queueTicketMessage)) {
            setErrorQueueTicketMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.transferSectorMessage)) {
            setErrorTransferSectorMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.transferUserMessage)) {
            setErrorTransferUserMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.successRatingTicketMessage)) {
            setErrorRatingMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.notificationBeforeCloseIdleTicketMessage)) {
            setErrorAlertInactivityMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidMessageDescriptionOrThrow(companyConfiguration.chat.closedIdleTicketMessage)) {
            setErrorClosedIdleTicketMessage('A descrição da mensagem deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else {
            setIsValidDataForm(true);
            return true;
        }
    }

    return {
        errorChooseDepartmentMessage, setErrorChooseDepartmentMessage,
        errorWelcomeMessage, setErrorWelcomeMessage,
        errorClosedTicketMessage, setErrorClosedTicketMessage,
        errorOutTimeOperatingMessage, setErrorOutTimeOperatingMessage,
        errorRatingMessage, setErrorRatingMessage,
        errorQueueTicketMessage, setErrorQueueTicketMessage,
        errorTransferSectorMessage, setErrorTransferSectorMessage,
        errorTransferUserMessage, setErrorTransferUserMessage,
        errorSectorTransferLimitReached, setErrorSectorTransferLimitReached,
        errorClosedIdleTicketMessage, setErrorClosedIdleTicketMessage,
        errorAlertInactivityMessage, setErrorAlertInactivityMessage,
        validate,
        isValidDataForm
    }
};
