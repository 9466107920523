import styled from "styled-components";

type TextType = {
    color: string;
};

export const ContentRequiredFields = styled.div<TextType>`
  display: flex;
  font-size: 11px;
  color: ${(props) => props.color};
  margin: 8px 0;
  flex-direction: column;
`;


export const TextRequiredFields = styled.span<TextType>`
  font-size: 16px;
  color: ${(props) => props.color};
  font-weight: bold;
  margin-bottom: 5px;
`;
export const Fields = styled.li<TextType>`
  font-size: 15px;
  color: ${(props) => props.color};
  //margin-bottom: 3px;

`;
