import {observer} from "mobx-react";
import {ContainerHeader, SubText, TextHeader} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const HeaderForm = observer((
    {
        textHeader,
        isConfirmation,
        hasDefaultToken
    }: {
        textHeader: string,
        isConfirmation: boolean
        hasDefaultToken: boolean
    }) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerHeader>
            <TextHeader color={colors.text}>{textHeader}</TextHeader>
            <SubText
                color={colors.text}>{isConfirmation ?
                `Obrigado por utilizar o DropDesk, clique em ativar conta para terminar o processo de segurança da sua conta.` :
                `${!hasDefaultToken ? 'Enviamos um código para seu email, insira o código e e' : 'E'}scolha uma senha forte e não a reutilize em outras contas.`}
            </SubText>
        </ContainerHeader>
    );
});

export default HeaderForm;
