import React, {useEffect} from "react";
import ModalSearch from "@dropDesk/presentation/components/modals/modal_search";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import {
    ResponseStandardController
} from "@dropDesk/presentation/pages/response_standard/controller/response_standard.controller";
import {
    ModalSearchResponseStandardType
} from "@dropDesk/presentation/components/modals/modal_search_response_standard/modal_search_response_standard";
import {setBlockUpdateLocalSearchCache} from "@dropDesk/utils/helpers/common";
import ModalCrudResponseStandard from "@dropDesk/presentation/pages/response_standard/modals/modal_crud";
import {useBetween} from "use-between";
import {
    useModalSearchResponseStandard
} from "@dropDesk/presentation/pages/response_standard/modals/handle_change_visible";


const ModalSearchResponseStandard = observer((props: ModalSearchResponseStandardType) => {

    const responseStandardController = useInjection(ResponseStandardController);
    const {setVisibleModalNewResponseStandard} = useBetween(useModalSearchResponseStandard);

    useEffect(() => {
        setBlockUpdateLocalSearchCache(true);
        onSearch(responseStandardController.searchParam);
        return () => {
            setBlockUpdateLocalSearchCache(false);
        }
    }, []);

    const handleVisibleModalNewResponseStandard = (visible: boolean, id: string) => {
        if (visible) {
            responseStandardController.makeResponseStandard();
        } else {
            onSearch(responseStandardController.searchParam);
        }
        setVisibleModalNewResponseStandard(visible);
    }

    const onSearch = (searchParam: string): void => {
        responseStandardController.list(searchParam ?? responseStandardController.searchParam);
    }

    const tableColumn = [
        {
            title: 'Título',
            ellipsis: true,
            dataIndex: 'title',
            key: 'id',
            render: (text: string, record: ResponseStandardEntity) => (
                <HighLightWords
                    searchWords={responseStandardController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Mensagem',
            ellipsis: true,
            dataIndex: 'description',
            key: 'id',
            render: (text: string, record: ResponseStandardEntity) => (
                <HighLightWords
                    searchWords={responseStandardController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Criado em', ellipsis: true, dataIndex: 'createdAt', key: 'id', render: (text: string) => (
                <span>{displayDateToLocale(text)}</span>
            ),
        },
    ];

    return (
        <>
            <ModalSearch<ResponseStandardEntity>
                inputSearchPlaceholder={props.inputSearchPlaceholder}
                tableColumns={tableColumn}
                open={props.visible}
                onSearch={(value) => {
                    onSearch(value);
                }}
                onSelectedRow={(responseStandard: ResponseStandardEntity) => {
                    props.handleSelectResponseStandard(responseStandard);
                }}
                inputValue={responseStandardController.searchParam}
                textHeaderModal={props.textHeaderModal}
                loading={responseStandardController.loading}
                onRequestClose={props.onClose}
                tableDataSource={responseStandardController.tableResponseStandard.data}
                defaultCurrentPage={1}
                totalRows={responseStandardController.tableResponseStandard.totalRows}
                page={responseStandardController.tableResponseStandard.page}
                pageSize={responseStandardController.tableResponseStandard.limit}
                onChange={(page) => responseStandardController.getDataFromPage(page - 1)}
                labelNoResults={props.labelNoResults}
                useFooter={props.useFooter}
                labelFooter={props.labelFooter}
                onSave={() => {
                    handleVisibleModalNewResponseStandard(true, 'new');
                }}
            />

            <ModalCrudResponseStandard
                onUpdate={(responseStandard) => responseStandardController.setResponseStandard(responseStandard)}
                responseStandard={responseStandardController.responseStandard}
                loading={responseStandardController.loading}
                handleVisibleModalNewResponseStandard={handleVisibleModalNewResponseStandard}
                set={responseStandardController.set}
            />
        </>

    )
});
export default ModalSearchResponseStandard;
