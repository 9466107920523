import {FileMessageEntity} from "@dropDesk/domain/entities/common/file_message";

export class ImageEditorEntity {
    filesEdition!: FileEditionEntity[];
    currentFileEdition?: FileEditionEntity | null;

    constructor({
                    filesEdition,
                    currentFileEdition
                }: {
        filesEdition: FileEditionEntity[];
        currentFileEdition?: FileEditionEntity | null;
    }) {
        Object.assign(this, {
            filesEdition,
            currentFileEdition
        });
    }

    copyWith({
                 filesEdition,
                 currentFileEdition,
                 replaceCurrentFileEditionIfNull
             }: {
        filesEdition?: FileEditionEntity[];
        currentFileEdition?: FileEditionEntity | null;
        replaceCurrentFileEditionIfNull?: boolean | null,

    }): ImageEditorEntity {
        return new ImageEditorEntity({
            currentFileEdition: replaceCurrentFileEditionIfNull ? currentFileEdition : this.currentFileEdition,
            filesEdition: filesEdition ?? this.filesEdition,
        })
    }
}


export class FileEditionEntity {
    fileMessage!: FileMessageEntity;

    constructor({
                    fileMessage,
                }: {
        fileMessage: FileMessageEntity;
    }) {
        Object.assign(this, {
            fileMessage,
        });
    }

    copyWith({
                 fileMessage,
             }: {
        fileMessage?: FileMessageEntity;

    }): FileEditionEntity {
        return new FileEditionEntity({
            fileMessage: fileMessage ?? this.fileMessage,
        })
    }
}
