import {observer} from "mobx-react";
import React from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";


const Footer = (observer((
    {
        disabled,
        loading,
        onSubmit
    }: {
        disabled: boolean
        loading: boolean
        onSubmit: () => void
    }) => {

    return (
        <div style={{paddingTop: 16}}>
            <FooterForm
                disabled={loading || disabled}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={() => onSubmit()}
                onRequestClose={() => {
                }}
                hideBackButton={true}
                widthButton={700}
            />
        </div>
    )
}));
export default Footer;
