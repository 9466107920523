import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";

@injectable()
export class ListClientUseCase {
  private _repository: ClientRepository;

  constructor(@inject(ClientRepository) repository: ClientRepository) {
    this._repository = repository;
  }

  public call(page: number, searchParam: string, limit: number,listOnlyDeleted:boolean): Promise<ListPaginationEntity<ClientEntity>> {
    return this._repository.list(page, searchParam, limit,listOnlyDeleted);
  }
}

