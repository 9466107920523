import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, Content, Icon} from "./styled";
import React from "react";
import {observer} from "mobx-react";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {HighLightHashTag} from "@dropDesk/presentation/components/high_light_hashtag";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {hexToRGBA, shadeOrLighten} from "@dropDesk/utils/helpers/colors";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const TicketHeader = observer((
    {
        ticket,
        isInit
    }: {
        ticket: TicketEntity,
        isInit: boolean
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const backgroundInit = SharedColors.onOpenStatus;
    const backgroundWarning = SharedColors.onAttendingStatus;
    const backgroundReal = isInit ? backgroundInit : backgroundWarning;
    const color = isInit ? SharedColors.textOpenStatus : SharedColors.textAttendingStatus;

    return (
        <Container>
            <Content background={backgroundReal} isDarkTheme={colors.isDarkTheme}>
                <DropDeskIcon
                    style={{marginRight: isInit ? 10 : 8}}
                    size={16}
                    color={color}
                    icon={`${isInit ? ICONS_DROPDESK.startFlag : ICONS_DROPDESK.close}`}/>
                <HighLightHashTag
                    text={isInit ? ticket.startingServiceTicketHeaderText : ticket.closingServiceTicketHeaderText}
                    fontSize={14} color={color}/>
            </Content>
        </Container>

    )
});
export default TicketHeader;
