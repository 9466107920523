import {observer} from "mobx-react";
import {
    Container, Content, ContainerInput, ContentInput
} from "./styled";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import React from "react";
import {useBetween} from "use-between";
import {useModalsSubscription} from "@dropDesk/presentation/pages/subscription/ui/modals/visible_modal_subscription";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";

const ViewLog = observer(
    (
        {
            log
        }: {
            log: CompanyLogsEntity
        }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            setVisibleModalViewLog
        } = useBetween(useModalsSubscription);


        return (
            <Container>
                <HeaderModal
                    disabled={false}
                    letter={'Histórico de alteração'}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={() => setVisibleModalViewLog(false)}
                />
                <Content background={colors.onBackground}>
                    <ContainerInput>
                        <ContentInput>
                            <InputWithBorder
                                name='name'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Responsável"}
                                value={log.responsible}
                                useButtonRight={false}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                        {log.createdAt && <ContentInput>
                            <InputWithBorder
                                name='createdAt'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Alterado em"}
                                value={displayDateToLocale(log.createdAt)}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>}
                    </ContainerInput>

                    <ContainerInput>
                        <ContentInput>
                            <InputTextAreaWithBorder
                                name='description'
                                size={65}
                                readOnly={true}
                                disabled={false}
                                label={"Evento"}
                                value={log.description}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContainerInput>
                </Content>
            </Container>
        )
    })
export default ViewLog;
