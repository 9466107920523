import styled from "styled-components";

type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
`;

export const ContainerStar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ContentStar = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextDescription = styled.span<TextType>`
  color: ${(props) => props.color};
  font-size: ${props => `${props.size}px`};
  font-weight: bold;
`;
