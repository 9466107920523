import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ReportTicketRateEntity} from "@dropDesk/domain/entities/reports/report_ticket_rate/report_ticket_rate.entity";

@injectable()
export abstract class ReportTicketRateRemoteDataSource {

  public abstract get(
    period: { startDate: string, endDate: string },
    idUser: string | null,
  ): Promise<ReportTicketRateEntity>;

}

@injectable()
export class ReportTicketRateRemoteDataSourceImpl implements ReportTicketRateRemoteDataSource {
  baseUrl: string = 'reportTickets/rate';

  async get(
    period: { startDate: string, endDate: string },
    idUser: string | null,
  ): Promise<ReportTicketRateEntity> {
    return new Promise<ReportTicketRateEntity>(async (resolve, reject) => {
      try {

        const url = `${this.baseUrl}?startDate=${period.startDate}&endDate=${period.endDate}&idUser=${idUser ?? ''}`;
        const response = await api.get(url);
        return resolve(response.data);

      } catch (error) {
        return reject(parseServerError(error));
      }
    });
  }

}
