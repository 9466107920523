import styled from "styled-components";
import {
    ButtonChangeImageType,
    ContainerType,
    ContentImageDefaultType, GarbageType
} from "@dropDesk/presentation/components/drop_zone/pick_image_profile/pick_image_profile";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


export const Container = styled.div<ContainerType>`
  background: ${(props) => props.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
export const ContentImageDefault = styled.div<ContentImageDefaultType>`
  background: ${(props) => props.background};
  display: flex;
  width: ${(props) => `${props.width ?? props.size}px`};
  height: ${(props) => `${props.size}px`};
  cursor: ${(props) => props.disabled ? 'no-drop' : 'pointer'};
  border: 1px solid ${(props) => `${props.bordercolor}`};
  align-items: center;
  border-radius: 5px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:focus-within {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ButtonChangeImage = styled.button<ButtonChangeImageType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: ${(props) => `1px solid ${props.border}`};
  color: ${(props) => props.letterColor};
  background: ${(props) => props.background};
  font-size: 12px;
  width: 100%;
  cursor: ${(props) => props.disabled ? 'no-drop' : 'pointer'};
  height: 35px;
  min-height: 35px;
  margin-top: ${(props) => `${props.marginTop}px`};
  letter-spacing: 1px;
  border-radius: 5px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const Garbage = styled.button<GarbageType>`
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  cursor: ${(props) => props.disabled ? 'no-drop' : 'pointer'};
  z-index: 10;
  -webkit-box-shadow: 0px 0px 2px 0px ${(props) => props.shadowcolor};
  -moz-box-shadow: 0px 0px 2px 0px ${(props) => props.shadowcolor};
  box-shadow: 0px 0px 2px 0px ${(props) => props.shadowcolor};
`;

