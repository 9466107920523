import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";

@injectable()
export abstract class ContractsRemoteDataSource {
    public abstract getSignUpContract(): Promise<string>;
}

@injectable()
export class ContractsRemoteDataSourceImpl implements ContractsRemoteDataSource {

    constructor() {
    }

    baseUrl: string = 'contracts/toSignUp';

    public async getSignUpContract(): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.baseUrl}`);
                return resolve(response.data);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

}
