import {
    ContainerListUser,
    ContentDataInput,
    ContentInput,
    ContainerDataInputs,
    ContentNameAndImage,
    SubContentInputsListUsers,
    ContentDataLogin, ContentButtonActions, ContainerButtonHeader
} from "./styled";
import React, {useEffect, useRef} from "react";
import {UserListType} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_contacts/list_users";
import {
    ContentData
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_clients/styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import PickImageListUsers from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_contacts/pick_image";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import {
    useValidatorInputContacts
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_contacts/validator";
import {Tooltip} from "antd";
import {getPhoneValue, isValidNumberTelephone} from "@dropDesk/utils/helpers/validators";
import {removeMaskStaticTelephoneNumber} from "@dropDesk/utils/helpers/string_helper";
import {useBetween} from "use-between";
import {useModalFormClients} from "@dropDesk/presentation/pages/client/ui/my_clients/form/modals/handle_change_visible";
import InputPhoneNumber from "@dropDesk/presentation/components/inputs/input_phone_number";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {Text} from "@dropDesk/presentation/components/footer/footer_form/styled";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export default function ListInputsUsers(
    {
        user,
        onChange,
        loading,
        canDelete,
        onDeleteUser,
        onTransferContact,
        createObjectURLBlobUseCase,
        canTransfer,
        canChangeEmail,
        focus,
        onContactFocused,
        isNewChatContact
    }:
        UserListType,
) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const prevProps = useRef(false);
    const contactRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (prevProps.current !== focus && focus === true) {
            if (contactRef) {
                contactRef.current?.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
                onContactFocused();
            }
        }
        prevProps.current = focus;
    }, [focus, prevProps]);

    const {
        errorMessageName,
        errorMessageEmail,
        errorMessageTelephone,
        validate
    } = useValidatorInputContacts(user, isNewChatContact);


    const {
        setVisibleModalActivateAccount,
        setVisibleModalRememberPassword,
        setVisibleModalChangeEmail
    }
        = useBetween(useModalFormClients);

    const handleAllChanges = (event: {
        target: { name: string; value: any; checked: any; type: string }
    }, file?: File) => {
        const _user = user.copyWith({
            [event.target.name]:
                (event.target.type === "checkbox" || event.target.type === "switch") ?
                    event.target.checked : event.target.name === "telephone" ?
                        removeMaskStaticTelephoneNumber(event.target.value.replace(/\D/g, "")) :
                        event.target.name === "urlImageProfile" && file ? createObjectURLBlobUseCase(file)
                            : event.target.value,
            replaceEmailIfNull: (event.target.name === "email" || event.target.name === "isCreateAccount"),
            replaceTelephoneIfNull: event.target.name === "telephone" ? true : false,
            replaceUrlImageProfileIfNull: event.target.name === "urlImageProfile" && file ? true : false,
            replaceIdentityIfNull: event.target.name === "identity" ? true : false,
            replaceIsMyContactIfNull: event.target.name === "isMyContact" ? true : false,
            email: event.target.name === "isCreateAccount" && event.target.checked === false ? null : event.target.name === 'email' ? event.target.value : user.email
        })

        onChange(_user);

    };

    return (
        <ContainerDataInputs key={user.id} borderColor={colors.border} ref={contactRef}>
            <ContainerListUser>
                <ContentButtonActions>
                    {canDelete &&

                        <Tooltip title={'Excluir contato'}>
                            <ContainerButtonHeader
                                background={colors.buttonOnBackground}
                                accent={colors.accent}
                                onClick={() => onDeleteUser(user)}
                            >
                                <DropDeskIcon
                                    icon={ICONS_DROPDESK.delete}
                                    color={colors.textError}
                                    size={18}
                                />
                            </ContainerButtonHeader>
                        </Tooltip>
                    }

                    {canTransfer && user.id && user.idClient &&
                        <Tooltip title={'Transferir Contato'}>
                            <ContainerButtonHeader
                                background={colors.buttonOnBackground}
                                accent={colors.accent}
                                onClick={() => onTransferContact(user)}
                            >
                                <DropDeskIcon
                                    icon={ICONS_DROPDESK.transfer}
                                    size={18}
                                />
                            </ContainerButtonHeader>
                        </Tooltip>}

                </ContentButtonActions>
                <ContentData>
                    <ContentDataInput>
                        <ContentNameAndImage>
                            <SubContentInputsListUsers>
                                <ContentInput style={{marginRight: 15}}>
                                    <InputWithBorder
                                        required={true}
                                        name='name'
                                        size={35}
                                        disabled={loading}
                                        messageError={errorMessageName}
                                        label={"Nome"}
                                        value={user.name}
                                        onChange={handleAllChanges}
                                    />
                                </ContentInput>
                                <ContentInput style={{marginBottom: 25}}>
                                    <InputWithBorder
                                        name='identity'
                                        size={35}
                                        disabled={loading}
                                        placeHolder={"Suporte, diretoria, analista ..."}
                                        label={"Cargo"}
                                        value={user.identity ?? ''}
                                        onChange={handleAllChanges}
                                    />
                                </ContentInput>
                            </SubContentInputsListUsers>
                            <ContentDataInput>
                                <ContentInput style={{marginRight: 15}}>
                                    <InputPhoneNumber
                                        name='telephone'
                                        size={35}
                                        disabled={loading}
                                        messageError={errorMessageTelephone}
                                        label={"Telefone"}
                                        value={getPhoneValue(user.telephone)}
                                        onChange={(value) => {
                                            handleAllChanges({
                                                target:
                                                    {
                                                        name: 'telephone',
                                                        value: value ?? '',
                                                        checked: false,
                                                        type: 'input'
                                                    }
                                            });
                                        }}
                                    />
                                </ContentInput>
                                <div style={{
                                    display: 'flex',
                                    flex: 1,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start'
                                }}>
                                    <CheckBoxWithLabel
                                        label={`Telefone também é whatsapp`}
                                        checked={user.isMyContact ?? false}
                                        name={'isMyContact'}
                                        marginLeft={0}
                                        disabled={loading || !isValidNumberTelephone(user.telephone ?? '') || !user.telephone || !!isNewChatContact}
                                        onChange={(event) => {
                                            handleAllChanges({
                                                target:
                                                    {
                                                        name: 'isMyContact',
                                                        value: event.target.value,
                                                        checked: event.target.checked,
                                                        type: 'switch'
                                                    }
                                            });
                                        }}
                                        tooltip={!isValidNumberTelephone(user.telephone ?? '') ? 'Informe um número de telefone válido' : ''}
                                    />
                                </div>
                            </ContentDataInput>
                            <ContentDataLogin>
                                {(!!user.hasAccountCreated || user.isCreateAccount) &&
                                    <ContentInput style={{marginRight: 15}}>
                                        <InputWithBorder
                                            required={true}
                                            autoFocus={true}
                                            name='email'
                                            size={35}
                                            disabled={loading || (!!user.hasAccountCreated && !!user.createdAt)}
                                            placeHolder={"Email de acesso"}
                                            onClickTwoButtonRight={() => {
                                                setVisibleModalChangeEmail({visible: true, user});
                                            }}
                                            onClickButtonRight={() => {
                                                if (user.verified) {
                                                    setVisibleModalRememberPassword({visible: true, user});
                                                } else {
                                                    setVisibleModalActivateAccount({visible: true, user});
                                                }
                                            }}
                                            labelTwoButtonRight={"Trocar e-mail"}
                                            labelButtonRight={user.verified ? 'Recuperar senha' : 'Ativar conta'}
                                            useTwoButtonRight={canChangeEmail}
                                            useButtonRight={(!!user.hasAccountCreated && !!user.createdAt)}
                                            disabledButtonRight={loading}
                                            disabledTwoButtonRight={loading}
                                            messageError={errorMessageEmail}
                                            label={"Email"}
                                            value={user.email ?? ''}
                                            onChange={handleAllChanges}
                                        />

                                    </ContentInput>}

                                {(!!user.hasAccountCreated && !!user.createdAt) &&
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <CheckBoxWithLabel
                                            label={`Bloquear acesso ao sistema`}
                                            checked={user.blocked ?? false}
                                            marginLeft={0}
                                            disabled={loading}
                                            onChange={(event) => handleAllChanges({
                                                target:
                                                    {
                                                        name: 'blocked',
                                                        value: event.target.value,
                                                        checked: event.target.checked,
                                                        type: 'switch'
                                                    }
                                            })}
                                            tooltip={'Ao bloquear o acesso deste contato, ele perderá acesso ao painel do cliente.'}
                                        />
                                    </div>}

                                {(!user.hasAccountCreated) &&
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <CheckBoxWithLabel
                                            label={`Pode acessar o sistema`}
                                            checked={user.isCreateAccount ?? false}
                                            marginLeft={0}
                                            disabled={loading}
                                            onChange={(event) => {
                                                handleAllChanges({
                                                        target:
                                                            {
                                                                name: 'isCreateAccount',
                                                                value: event.target.value,
                                                                checked: event.target.checked,
                                                                type: 'switch'
                                                            }
                                                    },
                                                    undefined);
                                                validate();
                                            }}
                                        />
                                    </div>}
                            </ContentDataLogin>
                        </ContentNameAndImage>
                        <PickImageListUsers
                            urlImageProfile={user.urlImageProfile ?? ''}
                            createObjectURLBlobUseCase={createObjectURLBlobUseCase}
                            clearImage={() => {
                                handleAllChanges({
                                    target:
                                        {
                                            name: 'removeImage',
                                            value: true,
                                            checked: true,
                                            type: 'switch'
                                        }
                                })
                            }}
                            handleOnCropper={(file) => {
                                handleAllChanges({
                                        target:
                                            {
                                                name: 'urlImageProfile',
                                                value: '',
                                                checked: false,
                                                type: 'input'
                                            }
                                    },
                                    file)
                            }}
                        />
                    </ContentDataInput>


                </ContentData>
                <Text style={{marginTop: 10}}
                      color={colors.text}>{user.createdAt ? `${user.getOrigin} em ${displayDateToLocale(user.createdAt)}` : undefined}</Text>

            </ContainerListUser>
        </ContainerDataInputs>

    );
}
