import React, {useContext, useEffect} from 'react';
import {
    Container, TextTab,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {
    CompanyConfigurationController
} from "@dropDesk/presentation/pages/company_configurations/controller/company_configurations.controller";
import TicketConfiguration from "@dropDesk/presentation/pages/company_configurations/ui/ticket_configuration";
import WhatsAppConfiguration from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration";
import BusinessHourOperator from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator";
import Theme from "@dropDesk/presentation/pages/company_configurations/ui/theme";
import Notifications from "@dropDesk/presentation/pages/company_configurations/ui/notifications";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ResponseStandard from "@dropDesk/presentation/pages/response_standard";
import PortalClient from "@dropDesk/presentation/pages/company_configurations/ui/portal_client";
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import CompanyConfiguration from "@dropDesk/presentation/pages/company_configurations/ui/company";
import TicketsDescriptionStatic from "@dropDesk/presentation/pages/ticket_description_static/ui";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {useTranslation} from "react-i18next";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {ThemeConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/theme_configuration.entity";
import {getThemeEntityByString} from "@dropDesk/utils/helpers/common";
import {ColorsType} from "@dropDesk/domain/entities/theme/colors_enum";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import Tabs from "@dropDesk/presentation/components/tabs/background_style";
import {ChatController} from "@dropDesk/presentation/pages/chat/controller/chat.controller";
import {useBetween} from "use-between";
import {
    useModalWhatsApp
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/handle_change_visible";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const Configuration = (observer(() => {
        const controller = useInjection(CompanyConfigurationController);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const userController = useInjection(UserController);
        const companyController = useInjection(CompanyController);
        const chatController = useInjection(ChatController);
        const {t} = useTranslation();
        const userLogged = appController.user!;
        const collapsed = useContext(MenuCollapsedContext);

        const initialize = async () => {
            await controller.findByPK(appController.user?.company.configurations.id ?? '');
        }

        const {setVisibleModalConnect, setVisibleModalDuplicateConnection} = useBetween(useModalWhatsApp);

        useEffect(() => {
            initialize();
        }, []);


        const handleAllChanges = (companyConfiguration: CompanyConfigurationEntity) => {
            controller.setCompanyConfiguration(companyConfiguration);
        }

        const onSuccess = (key: string) => {
            toastMessage.success(t(key));
        }

        const onSuccessConnection = (key: string) => {
            toastMessage.success(t(key));
            setVisibleModalConnect(false);
        }

        const onDuplicated = () => {
            setVisibleModalConnect(false);
            setVisibleModalDuplicateConnection(true);
        }

        const onExpired = (key: string) => {
            toastMessage.error(t(key));
            setVisibleModalConnect(false);
        }

        const onChangeConfigurationLocal = (configuration: CompanyConfigurationEntity) => {

            const _userLogged = userLogged.copyWith({
                company: userLogged.company.copyWith({
                    configurations: configuration
                })
            })
            appController.setUser(_userLogged);

        }


        const onUpdateCurrentUser = (user: UserEntity) => {
            const _currentUser = userLogged.copyWith({
                ...user,
                company: userLogged.company,
                client: userLogged.client,
            });
            appController.setUser(_currentUser);
        }

        const onUpdateFileAndCurrentUser = (url?: string, name?: string, removeLogo?: boolean) => {
            const defaultCompanyThemeName = userLogged.company.configurations.theme.name;
            const newTheme: ThemeConfigurationEntity = new ThemeConfigurationEntity({
                name: name ?? defaultCompanyThemeName,
                logoBrandUrl: removeLogo ? null : url ?? userLogged.company.configurations.theme.logoBrandUrl,
            });

            const newUser: UserEntity = userLogged.copyWith({
                company: userLogged.company.copyWith({
                    configurations: userLogged.company.configurations.copyWith({
                        theme: newTheme
                    })
                }),
                theme: name ? `${appController.theme.colorScheme.brightness}-${name}` : userLogged.theme,
                themeObject: getThemeEntityByString((name ?? defaultCompanyThemeName) as ColorsType, appController.theme.colorScheme.brightness)
            });
            appController.setUser(newUser);
        }


        return (
            <Container
                background={colors.onBackground}
                collapsedMenu={collapsed}
            >
                <Tabs
                    defaultActiveKey="1"
                    withMarginInTabs={true}
                    destroyInactiveTabPane={true}
                    height={50}
                    items={[
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.operator}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    Atendimentos
                                </TextTab>,
                            key: '1',
                            children: <TicketConfiguration
                                onUpdate={handleAllChanges}
                                handleSubmit={() => controller.setTicketConfiguration(onChangeConfigurationLocal, onSuccess)}
                                companyConfiguration={controller.companyConfiguration}
                                loading={controller.loading}
                            />,
                        },
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.whatsapp}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    WhatsApp
                                </TextTab>,
                            key: '2',
                            children: <WhatsAppConfiguration
                                logout={() => chatController.logout(onSuccess)}
                                onUpdate={(companyConfiguration) => handleAllChanges(companyConfiguration)}
                                handleSubmit={() => controller.setWhatsAppConfiguration(onChangeConfigurationLocal, onSuccess)}
                                companyConfiguration={controller.companyConfiguration}
                                loading={controller.loading || chatController.loading}
                                startConnection={() => chatController.startConnection(onSuccessConnection, onDuplicated, onExpired)}
                                chatConnection={chatController.chatConnection}
                                getConnectionState={() => chatController.connectionState()}
                                dispose={() => chatController.dispose()}
                                resetMessages={() => controller.resetWhatsAppMessages(onChangeConfigurationLocal, onSuccess)}
                            />,
                        },
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.clock}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    Horário Comercial
                                </TextTab>,
                            key: '3',
                            children: <BusinessHourOperator
                                onUpdate={handleAllChanges}
                                handleSubmit={() => controller.setBusinessOperatorConfiguration(onChangeConfigurationLocal, onSuccess)}
                                companyConfiguration={controller.companyConfiguration}
                                loading={controller.loading}
                            />,
                        },
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.theme}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    Tema
                                </TextTab>,
                            key: '4',
                            children: <Theme
                                setUserTheme={(theme) => userController.setTheme(theme, onUpdateCurrentUser)}
                                setTheme={(name) => controller.setTheme(userLogged.company.configurations.theme, onUpdateFileAndCurrentUser, name)}
                                onUpdateUser={(user) => userController.setUser(user)}
                                user={userController.user}
                                listUserThemeConfiguration={() => userController.getUser(appController.user?.id ?? '')}
                                loading={controller.loading}
                                imageLogoBrand={controller.resolveImageLogoBrand}
                                setFileLogoBrand={(file?: File | null) => controller.setFileLogoBrand(file)}
                                createObjectURLBlobUseCase={(file: File) => controller.createObjectURLBlobUseCase(file)}
                            />,
                        },
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.bellOutlined}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    Notificações
                                </TextTab>,
                            key: '5',
                            children: <Notifications
                                handleSubmit={() => controller.setConfigurationNotification(onChangeConfigurationLocal, onSuccess)}
                                onUpdate={(companyConfiguration) => handleAllChanges(companyConfiguration)}
                                companyConfiguration={controller.companyConfiguration}
                                loading={controller.loading}
                            />,
                        },
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.thunder}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    Respostas Rápidas
                                </TextTab>,
                            key: '6',
                            children: < ResponseStandard/>,
                        },
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.staticProblem}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    Problemas Estático
                                </TextTab>,
                            key: '7',
                            children: <TicketsDescriptionStatic/>,
                        },
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.users}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    Portal Cliente
                                </TextTab>,
                            key: '8',
                            children: < PortalClient
                                onUpdate={handleAllChanges}
                                handleSubmit={() => controller.setConfigurationPortalClient(onChangeConfigurationLocal, onSuccess)}
                                companyConfiguration={controller.companyConfiguration}
                                loading={controller.loading}
                            />,
                        },
                        {
                            label:
                                <TextTab>
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.business}
                                        removeColor={true}
                                        size={15}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    />
                                    Empresa
                                </TextTab>,
                            key: '9',
                            children: <CompanyConfiguration
                                onUpdate={(company) => companyController.setCompany(company)}
                                getConfigCompany={() => companyController.getCompany()}
                                handleSubmit={() => companyController.set(onSuccess)}
                                company={companyController.company}
                                loading={companyController.loading}
                            />,
                        },
                    ]}
                />
                {(controller.loading || userController.loading || companyController.loading || chatController.loading)
                    && <DropDeskLoading
                        height={250}
                        description={controller.loadingMessage ?? userController.loadingMessage ?? companyController.loadingMessage
                            ?? chatController.loadingMessage}
                    />}
            </Container>
        )

    }
))
export default Configuration;
