import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";

type HeaderType = {
    background: string;
    accent: string;
    paddinghorizontal: number;
};

type ItemMenuType = {
    background: string;
    accent: string;
};

type RouteNameType = {
    color: string;
};
type ProfileTextsType = {
    color: string;
};

type TextType = {
    color: string;
}

type ContainerProfile = {
    border: string;
}

type ContainerButtonHeaderType = {
    background: string;
    accent: string;
};

type PopoverType = {
    shadowcolor: string;
}

type ContainerPopoverType = {
    border: string;
    shadow: string;
}

export const ContainerButtonHeader = styled.div<ContainerButtonHeaderType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const Header = styled.header<HeaderType>`
  height: 61px;
  min-height: 61px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 16px ${props => `${props.paddinghorizontal}px`} !important;
  align-items: center;
  position: relative;
  background: ${(props) => props.background};
`;
export const RouteName = styled.strong<RouteNameType>`
  font-size: ${`${ConstantsStyles.titleLargeFontSize}px`};
  color: ${(props) => props.color};
  letter-spacing: 1.5px;
`;

export const ContainerPopover = styled.div<ContainerPopoverType>`
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 300px;
`;

export const ContainerProfile = styled.div<ContainerProfile>`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 16px;
  height: 105px;
  max-height: 105px;
  border-bottom: 1px solid ${props => props.border};
`;
export const ContentImageProfilePopover = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  width: 40px;
  max-width: 40px;
`;


export const ContainerItemsMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
`;
export const ItemMenu = styled.div<ItemMenuType>`
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.background};
  border-radius: 5px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;


export const ContentProfile = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  max-width: 240px;
  display: flex;
`;
export const TextName = styled.span<ProfileTextsType>`
  color: ${(props) => props.color};
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  max-height: 20px;
  line-height: 20px;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const TextEmail = styled.span<ProfileTextsType>`
  color: ${(props) => props.color};
  font-size: 11px;
  height: 20px;
  max-height: 20px;
  max-width: 220px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TextItemMenu = styled.span<ProfileTextsType>`
  color: ${(props) => props.color};
  font-size: 16px;
  margin-left: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const ContentRouteNameAndBackButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TextBack = styled.span<TextType>`
  font-size: 14px;
  color: ${(props) => props.color};
  letter-spacing: 1.5px;
`;

export const ContentButtonBack = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 0;
  min-height: 30px;
  min-width: 170px;
  background: transparent;
  border-radius: 5px;
`;
export const ContentNotification = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;


