import styled from 'styled-components';
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type IconType = {
    color: string;
    fontsize: number;
}

type IconCloseType = {
    color: string;
    fontsize: number;
    disabled: boolean;
}

type ContainerType = {
    background: string;
    isDarkTheme: boolean;
    heightShowingHeaderInfo: number;
}

type LoadingType = {
    isDarkTheme: boolean;
}

export type ContainerButtonClose = {
    background: string;
    loading: boolean;
};


export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;


export const ContainerImageEditor = styled.div<ContainerType>`
  display: flex;
  height: calc(100vh - 210px - ${props => `${props.heightShowingHeaderInfo}px`});
  min-height: calc(100vh - 210px - ${props => `${props.heightShowingHeaderInfo}px`});
  flex-direction: column;
  background: ${(props) => props.background};
  position: relative;
`;
export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

export const ContainerLoading = styled.div<LoadingType>`
  position: absolute;
  center: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: ${props => hexToRGBA(SharedColors.loading, props.isDarkTheme ? 0.9 : 0.7)};
  height: 100%;
`;


export const ContainerButtonClose = styled.div<ContainerButtonClose>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  cursor: ${props => props.loading ? 'no-drop' : 'pointer'};
  z-index: 200;
  position: absolute;
  right: 16px;
  top: 26px;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    outline: none;
  }
`;
