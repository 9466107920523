import "reflect-metadata";
import {inject, injectable} from "inversify";
import { AuthRepository } from "@dropDesk/domain/repositories/auth/auth.repository";


@injectable()
export class LogoutUseCase {
    private _repository: AuthRepository;

    constructor(
        @inject(AuthRepository) repository: AuthRepository
    ) {
        this._repository = repository;
    }

    public call(): Promise<void> {
        return this._repository.logout();
    }
}
