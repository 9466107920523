import styled from "styled-components";

type BorderType = {
  bordercolor: string;
}

type TextType = {
  color: string;
}

export const Container = styled.div<BorderType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px - 40px);
  flex-direction: column;
  border-right: 1px solid ${props => props.bordercolor};
  overflow: auto;
`;


export const ContainerInputSearch = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentInputSearch = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
`;
export const ContentLoading = styled.div`
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
`;
export const ContentTextNoResults = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Text = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  margin-top: -10px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    filter: brightness(80%);
  }

  &::-webkit-scrollbar-thumb:hover {
    filter: brightness(80%);
  }

`;
