import {TicketConfig, TicketConfigType} from "@dropDesk/domain/entities/ticket/ticket_config";

export class ChartReportTicketEntity {
    id!: string;
    value!: number;
    color!: string;
    label!: string;
    type?: TicketConfigType;
    extra?: Record<string, unknown>;
    grouped!: boolean;
    defaultValue!: boolean;
    mainGrouped!: boolean;


    static fromJson(json: Record<string, any>): ChartReportTicketEntity {
        return new ChartReportTicketEntity({
            id: json['id'] as string,
            color: json['color'] as string,
            value: json['value'] as number,
            grouped: json['extra'] ? json['extra']['type'] === 'closed' && json['type'] === TicketConfigType.status : false,
            defaultValue: json['defaultValue'] ?? false,
            mainGrouped: json['extra'] ? json['extra']['main'] === true && json['extra']['type'] === 'closed' : false,
            label: TicketConfig.translateStatusToHuman(json['label']),
            type: json['type'] as TicketConfigType | undefined,
            extra: json['extra'] as Record<string, unknown> | undefined,
        });
    }

    public get isNotGrouped(): boolean {
        return this.grouped === false || this.mainGrouped;
    }

    public get isGrouped(): boolean {
        return this.grouped === true;
    }

    public get isMainGrouped(): boolean {
        return this.mainGrouped === true;
    }

    constructor({
                    value,
                    color,
                    label,
                    id,
                    grouped,
                    defaultValue,
                    type,
                    extra,
                    mainGrouped
                }: {
        value: number;
        color: string;
        label: string;
        id: string;
        grouped: boolean;
        defaultValue: boolean;
        type?: TicketConfigType;
        extra?: Record<string, unknown>;
        mainGrouped: boolean;
    }) {
        Object.assign(this, {
            value,
            color,
            label,
            id,
            grouped,
            defaultValue,
            type,
            extra,
            mainGrouped,
        });
    }
}
