import {observer} from "mobx-react";

import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {
    ContainerText, Container,
    ContentText, FieldName, FieldValue,
    Text
} from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/input_payment_info/styled";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYHHMM,
} from "@dropDesk/utils/helpers/date_helper";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {formatCurrency} from "@dropDesk/utils/helpers/common";

const InputPaymentInfo = observer(
    ({
         lastPayment,
         newSubscription,
         labelNewPeriod
     }: {
        lastPayment: PaymentEntity
        newSubscription: PaymentSubscriptionEntity
        labelNewPeriod: string
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;


        return (
            <Container>
                {lastPayment.createdAt &&
                    <ContentText>
                        <DropDeskIcon
                            icon={lastPayment.isBoleto ? ICONS_DROPDESK.billet : ICONS_DROPDESK.pix}
                            size={11}
                            color={colors.hintInverse}
                        />
                        <Text size={14} color={colors.hintInverse} style={{fontWeight: 'bold'}}>
                            &nbsp;Gerado em {formatDateToDDMMYYYYHHMM(new Date(lastPayment.createdAt))}
                        </Text>
                    </ContentText>
                }

                <ContainerText>
                    <FieldName>
                        <ContentText>
                            <Text size={14} color={colors.text}>
                                Valor total:
                            </Text>
                        </ContentText>
                    </FieldName>
                    <FieldValue>
                        <ContentText>
                            <Text size={14} color={colors.text}>
                                {formatCurrency(lastPayment.value)}
                            </Text>
                        </ContentText>
                    </FieldValue>
                </ContainerText>
                <ContainerText>
                    <FieldName>
                        <ContentText>
                            <Text size={14} color={colors.text}>
                                Período:
                            </Text>
                        </ContentText>
                    </FieldName>
                    <FieldValue>
                        <ContentText>
                            <Text size={14} color={colors.text}>
                                {labelNewPeriod}
                            </Text>
                        </ContentText>
                    </FieldValue>
                </ContainerText>
                <ContainerText>
                    <FieldName>
                        <ContentText>
                            <Text size={14} color={colors.text}>
                                Plano:
                            </Text>
                        </ContentText>
                    </FieldName>
                    <FieldValue>
                        <ContentText>
                            <Text size={14} color={colors.text}>
                                {newSubscription.planName}
                            </Text>
                        </ContentText>
                    </FieldValue>
                </ContainerText>

                {lastPayment.expireDate &&
                    <ContainerText>
                        <FieldName>
                            <ContentText>
                                <Text size={14} color={colors.text}>
                                    Vencimento:
                                </Text>
                            </ContentText>
                        </FieldName>
                        <FieldValue>
                            <ContentText>
                                <DropDeskIcon
                                    icon={ICONS_DROPDESK.clock}
                                    size={11}
                                    color={colors.text}
                                />
                                <Text size={14} color={colors.text}>
                                    &nbsp;{formatDateToDDMMYYYY(new Date(lastPayment.expireDate))}
                                </Text>
                            </ContentText>
                        </FieldValue>
                    </ContainerText>}

            </Container>
        )
    })
export default InputPaymentInfo;
