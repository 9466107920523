import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Button, ContentButton, Icon} from "@dropDesk/presentation/components/inputs/input_with_border/styled";
import {Popover} from "antd";
import EmojiPicker from "@dropDesk/presentation/components/emoji_picker";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


export default function EmojiSelect<T>(
    {
        value,
        name,
        onChange,
        disabled,
    }: {
        value: string,
        name?: string;
        onChange: (event: T) => void;
        disabled?: boolean;
    }
) {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Popover
            placement="top"
            trigger="click"
            color={colors.backgroundInput}
            title=""
            content={
                <EmojiPicker onEmojiSelect={(emoji) => {
                    const event = {
                        target: {
                            value: value + emoji,
                            type: 'input',
                            name: name,
                            checked: false,
                        },
                        //} as any as React.ChangeEvent<HTMLInputElement>;
                    } as any as T;
                    onChange(event);
                }}/>
            }>

            <ContentButton disable={disabled ?? false} tabIndex={-1}>
                <Button
                    disabled={disabled}
                    onClick={() => {
                    }}
                    tabIndex={-1}
                >
                    <DropDeskIcon
                        icon={ICONS_DROPDESK.happyFaceOutline}
                        size={18}
                    />
                </Button>
            </ContentButton>
        </Popover>
    )
}
