import styled from "styled-components";


export const ContentData = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Spacer = styled.div`
  margin-bottom: 25px;
`;
export const ContentValueAndDate = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentInput = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 25px;
`;
export const ContentInputsAndImage = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentImage = styled.div`
  display: flex;
  width: 170px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
`;



