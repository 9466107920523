import styled from "styled-components";

type ContainerType = {
    useDarkenInHover: boolean;
}
export const Icon = styled.i<ContainerType>`

  &:hover {
    filter: ${props => props.useDarkenInHover ? 'brightness(80%)' : 'brightness(1)'};
  }
`;
