import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SectorRepository} from "@dropDesk/domain/repositories/sector/sector.repository";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export class ExportDataSectorUseCase {
  private _repository: SectorRepository;

  constructor(@inject(SectorRepository) repository: SectorRepository) {
    this._repository = repository;
  }

  public call(): Promise<ExportDataEntity> {
    return this._repository.export();
  }
}

