import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";

import {PaymentProvider} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {PlanEntity} from "@dropDesk/domain/entities/plan/plan_entity";
import {
    PaymentSubscriptionOrigin, PaymentSubscriptionStatus
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription.enum";
import {PlanPeriodDisplay} from "@dropDesk/domain/entities/plan/plan_enum";

export const newSubscription = () => new PaymentSubscriptionEntity({
    daysToExpiration: 0,
    id: '',
    idPlan: '',
    idCompany: getIdCompanyByLocalStorage(),
    origin: PaymentSubscriptionOrigin.owner,
    status: PaymentSubscriptionStatus.waitingPayment,
    provider: PaymentProvider.iugu,
    dayDue: 10,
    planUserMultiplier: 1,
    plan: new PlanEntity({
        id: '',
        name: 'Plano Mensal DropDesk',
        numberUsers: 1,
        period: 30,
        periodDisplay: PlanPeriodDisplay.monthly,
        value: 25,
        legacy: false
    }),
    nextDueDate: ''
});

