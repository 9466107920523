import styled from "styled-components";

type TextType = {
    color: string
}

type ContentType = {
    color: string;
    useBorder: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContact = styled.span<TextType>`
  color: ${props => props.color};
  font-size: 14px;
  height: 21px;
  max-height: 21px;
  display: flex;
  align-items: center;
`;

export const TextBold = styled.span<TextType>`
  color: ${props => props.color};
  font-size: 14px;
  font-weight: bold;
  height: 21px;
  max-height: 21px;
  display: flex;
  align-items: center;
`;
export const Divider = styled.hr<TextType>`
  color: ${props => props.color};
  margin: 10px 0;
`;



