import styled from "styled-components";

export type ContainerType = {
    background: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: ${props => props.background};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 499;
  padding: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const ContentAllData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  max-width: 768px;
`;
