import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    TextBold,
} from "../styled";
import React from "react";
import {
    ContainerDescriptions,
    ContentDescriptions,
} from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan/styled";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import {ContentResumeValueText} from "@dropDesk/presentation/pages/subscription/ui/checkout/styled";

const TotalFirstPayment = (
    {
        totalFirstPayment,
        labelFirstPayment
    }: {
        totalFirstPayment: number
        labelFirstPayment: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerDescriptions>

            <ContentDescriptions>
                <TextBold size={18} color={colors.hintInverse}>
                    {labelFirstPayment}
                </TextBold>
                <ContentResumeValueText>
                    <TextBold size={18} color={colors.text}>{formatCurrency(totalFirstPayment)}</TextBold>
                </ContentResumeValueText>
            </ContentDescriptions>

        </ContainerDescriptions>
    );
}
export default TotalFirstPayment;
