import React, {useEffect} from "react";
import {
    Container
} from "./styled";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import {
    ReportTicketRateController
} from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/controller/report_ticket_rate.controller";
import SearchBar
    from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui/search_bar";
import ModalsReportTicketRate
    from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui/modals";
import PanelRating
    from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui/panel_rating";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ReportTicketsRate = (observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const controller = useInjection(ReportTicketRateController);

    useEffect(() => {
        controller.initialize();
    }, []);

    return (

        <Container background={colors.onBackground}>

            <SearchBar
                getReportTicketRate={controller.getReportTicketRate}
                removeFilter={() => controller.removeFilter()}
                period={controller.period}
                setPeriod={(dateArray, label) => controller.setPeriod(dateArray, label)}
                loading={controller.loading}
                user={controller.user}
                labelPeriodCustom={controller.labelPeriod}
            />

            <ModalsReportTicketRate
                onSelectUser={(user) => controller.setUser(user)}
                getReportTicketRate={controller.getReportTicketRate}
            />

            <PanelRating
                totalCountValue={controller.totalCountValue}
                average={controller.average}
                fiveRating={controller.fiveRating}
                fourRating={controller.fourRating}
                threeRating={controller.threeRating}
                twoRating={controller.twoRating}
                oneRating={controller.oneRating}
                loading={controller.loading}
            />

        </Container>
    );
}))
export default ReportTicketsRate;
