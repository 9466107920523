import styled from "styled-components";

type TextType = {
  color: string;
}

export const ContentLoadingScroll = styled.div`
  display: flex;
  flex: 1;
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const TextLoading = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
`;
