import {
    TicketMessageEntity,
} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import React from "react";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import MessageBox, {MessageBoxStatus} from "@dropDesk/presentation/components/messages/message_box";
import {differentInMinutesBetweenDates} from "@dropDesk/utils/helpers/date_helper";
import firebaseStorage from "firebase/storage";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {
    MessageChatType,
    MessageStatus,
    ReplyEntity
} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {observer} from "mobx-react";
import TicketHeader from "@dropDesk/presentation/components/chat_view/chat_message/message_box/ticket_header";
import UploadTask = firebaseStorage.UploadTask;
import {orderByCreatedAt} from "@dropDesk/utils/helpers/common";

const MessagesBox = observer(
    ({
         messages,
         userClient,
         attendant,
         idMessageFocused,
         handleClickOnMessageReply,
         cancelUpload,
         onRetrySendMessageError,
         canChange,
         userLogged,
         handleRemoveReaction,
         handleAddReaction,
         lastUsedReactions,
         setMessageToReply,
         handleDeleteMessage,
         handleEditMessage,
         handleResetMessageFocused,
         compactModeMessages,
         idCurrentTicket,
         allReactions,
         isMeAttendingTicket,
         ticketIsOpen
     }: {
         messages: TicketMessageEntity[],
         userClient: UserEntity,
         attendant?: UserEntity,
         idMessageFocused: string | null
         handleClickOnMessageReply: (reply: ReplyEntity) => void
         cancelUpload: (uploadTask?: UploadTask) => void
         onRetrySendMessageError: (message: TicketMessageEntity) => void
         canChange: boolean
         userLogged: UserEntity
         handleRemoveReaction: (reaction: TicketMessageEntity, messageReacted: TicketMessageEntity) => Promise<void>
         handleAddReaction: (reaction: string, messageToReacted: TicketMessageEntity) => Promise<void>
         lastUsedReactions: string[]
         setMessageToReply: (message: ReplyEntity | null) => void
         handleDeleteMessage: (message: TicketMessageEntity) => void
         handleEditMessage: (editText: string, currentMessage: TicketMessageEntity) => void
         handleResetMessageFocused: () => void
         compactModeMessages: boolean,
         idCurrentTicket: string,
         allReactions: TicketMessageEntity[]
         isMeAttendingTicket: boolean
         ticketIsOpen: boolean
     }
    ) => {

        const useDeleteMessageButton = (message: TicketMessageEntity): boolean => {
            return !!message.createdAt
                && !message.deleted
                && differentInMinutesBetweenDates(message.createdAt ?? '') <= ConstantsKeys.visibleButtonDeleteMessageInMinutes
                && canChange
                && message.senderId === userLogged.id
                && message.idTicket === idCurrentTicket
                && !message.automatic;
        }

        const useEditMessageButton = (message: TicketMessageEntity): boolean => {
            return !!message.createdAt && !message.deleted
                && differentInMinutesBetweenDates(message.createdAt ?? '') <= ConstantsKeys.visibleButtonEditMessageInMinutes
                && canChange
                && (
                    message.type === MessageChatType.textMessage ||
                    message.type === MessageChatType.documentMessage && !!message.documentMessage?.caption ||
                    message.type === MessageChatType.imageMessage && !!message.imageMessage?.caption ||
                    message.type === MessageChatType.stickerMessage && !!message.stickerMessage?.caption ||
                    message.type === MessageChatType.videoMessage && !!message.videoMessage?.caption
                )
                && message.senderId === userLogged.id
                && !message.isViewOnceError
                && !message.isLargeMediaError
                && message.idTicket === idCurrentTicket
                && !message.automatic;
        }

        const useReplyButton = (message: TicketMessageEntity): boolean => {
            return !!message.createdAt &&
                !message.deleted &&
                !message.hasSendError &&
                canChange &&
                !message.isViewOnceError &&
                !message.isLargeMediaError
                && message.idTicket === idCurrentTicket
                && (isMeAttendingTicket || ticketIsOpen || userLogged.isUserClient)
                && !message.automatic;
            ;
        }

        const useReactionButton = (message: TicketMessageEntity): boolean => {
            return !!message.createdAt &&
                !message.deleted &&
                !message.hasSendError &&
                canChange &&
                !message.isViewOnceError &&
                !message.isLargeMediaError &&
                message.idTicket === idCurrentTicket &&
                (isMeAttendingTicket || userLogged.isUserClient)
                && !message.automatic;
        }

        const canChangeReaction = (message: TicketMessageEntity): boolean => {
            return !!message.createdAt &&
                !message.deleted &&
                !message.hasSendError &&
                canChange &&
                !message.isViewOnceError &&
                !message.isLargeMediaError
                && message.idTicket === idCurrentTicket
                && !message.automatic;
        }

        const getStatus = (message: TicketMessageEntity): MessageBoxStatus | undefined => {
            if ((userLogged.isUserClient && message.idTicket === idCurrentTicket) || (userLogged.isAttendant)) {
                return message.getStatus(userClient, userLogged.role, attendant);
            }
            return;
        }

        const imagesMessage = (messages: TicketMessageEntity[]): TicketMessageEntity[] => {
            const newImagesMessage = messages.filter((message) => message.isValidPreviewMessage);
            orderByCreatedAt(newImagesMessage);
            return newImagesMessage;
        }

        return (
            <>
                {messages.map((message) => (
                    <div key={message.id} id={message.id}>
                        {((message.isFirstPosition || message.isUniquePosition) && message.ticket) &&
                            <TicketHeader ticket={message.ticket} isInit={true}/>}
                        <MessageBox
                            message={message}
                            useReplyButton={useReplyButton(message)}
                            onReplyClick={() => setMessageToReply(message.toReplyEntity)}
                            useDeleteMessageButton={useDeleteMessageButton(message)}
                            resetMessageFocused={() => setTimeout(() => handleResetMessageFocused(), 1500)}
                            focus={!!idMessageFocused && (idMessageFocused === message.idExternal || idMessageFocused === message.id)}
                            onReplyMessageClick={(messageReply) => handleClickOnMessageReply(messageReply)}
                            onRetrySendMessageError={(message) => onRetrySendMessageError(message)}
                            status={getStatus(message)}
                            cancelUpload={(uploadTask) => cancelUpload(uploadTask)}
                            useEditMessageButton={useEditMessageButton(message)}
                            canRemoveReaction={canChangeReaction(message)}
                            handleRemoveReaction={handleRemoveReaction}
                            handleAddReaction={handleAddReaction}
                            lastUsedReactions={lastUsedReactions}
                            userClient={userClient}
                            handleDeleteMessage={handleDeleteMessage}
                            handleEditMessage={handleEditMessage}
                            compactMode={compactModeMessages}
                            reactions={allReactions.filter(entry => (entry.reactionMessage!.messageReactedId === message.idExternal || entry.reactionMessage!.messageReactedId === message.id) && !entry.deleted) ?? []}
                            useReactionButton={useReactionButton(message)}
                            attendant={attendant}
                            imageMessages={imagesMessage(messages)}
                        />
                        {((message.isLastPosition || message.isUniquePosition) && message.ticket) &&
                            <TicketHeader ticket={message.ticket} isInit={false}/>}
                    </div>
                ))}

            </>
        )
    });
export default MessagesBox;
