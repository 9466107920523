import styled from "styled-components";

type IconOpsType = {
    color: string;
}

export const ContentPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
`;
export const TextPermission = styled.span<IconOpsType>`
  font-size: 16px;
  color: ${props => props.color};
  padding-left: 10px;
`;
