import {observer} from "mobx-react";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContentDescriptions, ContentRadioButton, ContentTitle,
    TextBold,
} from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/styled";
import {RadioButton} from "@dropDesk/presentation/components/radio_button";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";

const RadioButtons = observer(
    (
        {
            reasonSelected,
            setCancellationReason,
            setReasonSelected
        }: {
            reasonSelected: string;
            setCancellationReason: (cancellationReason?: string) => void,
            setReasonSelected: React.Dispatch<React.SetStateAction<string>>
        }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        return (

            <ContentDescriptions>
                <ContentTitle>
                    <TextBold size={15} color={colors.text}>Por favor, nos diga o motivo do
                        cancelamento?</TextBold>
                </ContentTitle>
                <ContentRadioButton>
                    <RadioButton
                        onChange={(event) => {
                            setCancellationReason(event.target.value.toLowerCase() === 'outro' ? '' : event.target.value);
                            setReasonSelected(event.target.value);
                        }}
                        value={reasonSelected}
                        values={PaymentSubscriptionEntity.defaultCancellationReasons()}
                    />
                </ContentRadioButton>
            </ContentDescriptions>

        )
    })
export default RadioButtons;
