import {Container, Content, ContentCreate, ContentImage, Section, Title,} from "./styled";
import HeaderSecondary from "@dropDesk/presentation/components/headers/header_secondary";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import {FormEvent, useEffect} from "react";
import Inputs from "src/presentation/pages/company/ui/form_client/inputs";
import ButtonCreate from "src/presentation/pages/company/ui/form_company/button_create";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Image} from "@dropDesk/presentation/pages/login/ui/styled";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import {useValidatorCreateClientAccount} from "@dropDesk/presentation/pages/company/ui/form_client/inputs/validator";

const FormClientCreateAccount = (observer((
    {
        onSuccessCreateAccount
    }: {
        onSuccessCreateAccount: () => Promise<void>
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const controller = useInjection(CompanyController);
    const clientController = useInjection(ClientController);

    useEffect(() => {
        clientController.makeClientCreateAccount(appController.uniqueCode);
    }, []);

    const {isValidData} = useValidatorCreateClientAccount(clientController.clientCreateAccount);
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (isValidData) {
            await controller.createAccount(onSuccessCreateAccount, undefined, undefined, clientController.clientCreateAccount);
        }
    };


    return (
        <Container>
            <Section background={colors.delicateBackground}>
                <HeaderSecondary background={"transparent"} color={colors.text}/>
                <Content>
                    <ContentCreate onSubmit={handleSubmit}>
                        <ContentImage>
                            <Image src={appController.logoBrand} alt={"Logo Marca"}/>
                        </ContentImage>
                        <Title color={colors.text}>Precisamos apenas de alguns dados.</Title>
                        <Inputs
                            clientCreateAccount={clientController.clientCreateAccount}
                            onUpdate={(clientCreateAccount) => clientController.setClientCreateAccount(clientCreateAccount)}
                            loading={controller.loading}
                            disabledUniqueCode={!!appController.uniqueCode}
                        />
                        <ButtonCreate
                            loading={controller.loading}
                            isValidData={isValidData}
                            handleSubmit={handleSubmit}
                        />
                    </ContentCreate>
                </Content>
            </Section>

            {controller.loading && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}

        </Container>
    );
}))
export default FormClientCreateAccount;
