import styled from "styled-components";

type ContainerType = {
    background: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px);
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
`;

export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
  flex: 1;
  overflow: auto;
`;
export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;
