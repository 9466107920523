import {
    Container, Content
} from "./styled";
import React from "react";
import Header from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/header";
import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import PaymentInfoDetail from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_info_detail";
import PaymentMethod from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method";
import {
    CardEntity,
    TokenizeCardEntity,
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {TabsPayment} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {observer} from "mobx-react";


const PaymentType = observer((
    {
        setActiveRoute,
        paymentInfo,
        handleEditPaymentInfo,
        newTokenizeCard,
        onUpdateTokenizeCard,
        loading,
        pay,
        setActiveTabPayment,
        cardSaved,
        onClickPayCreditCardSaved,
        activeTabPayment,
        makePaymentMethod,
        resetPaymentMethod,
    }: {
        setActiveRoute: (route: RoutesSubscription) => void
        paymentInfo: CompanyPaymentInfo
        handleEditPaymentInfo: () => void
        newTokenizeCard: TokenizeCardEntity | null
        onUpdateTokenizeCard: (newTokenizeCard: TokenizeCardEntity) => void
        loading: boolean
        pay: () => void
        setActiveTabPayment: (value: TabsPayment) => void
        cardSaved?: CardEntity
        onClickPayCreditCardSaved: () => void
        activeTabPayment: TabsPayment | null,
        makePaymentMethod: () => void
        resetPaymentMethod: () => void
    }
) => {

    return (
        <Container>
            <Content>
                <Header setActiveRoute={setActiveRoute}/>
                <PaymentInfoDetail
                    paymentInfo={paymentInfo}
                    handleEditPaymentInfo={handleEditPaymentInfo}
                />
                <PaymentMethod
                    newTokenizeCard={newTokenizeCard}
                    onUpdateTokenizeCard={onUpdateTokenizeCard}
                    loading={loading}
                    pay={pay}
                    setActiveTabPayment={setActiveTabPayment}
                    cardSaved={cardSaved}
                    onClickPayCreditCardSaved={onClickPayCreditCardSaved}
                    activeTabPayment={activeTabPayment}
                    makePaymentMethod={makePaymentMethod}
                    resetPaymentMethod={resetPaymentMethod}
                />
            </Content>
        </Container>
    );
});
export default PaymentType;
