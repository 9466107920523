import {Container, Text, ContentLogo, Logo} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import logo from "@dropDesk/storage/images/logo_dropdesk_portrait.png";


export default function Header(
    {
        title
    }: {
        title: string
    }) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <Text color={colors.text}>{title}</Text>

            <ContentLogo>
                <Logo src={logo} alt={"Logo DropDesk"}/>
            </ContentLogo>
        </Container>
    );
}
