import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
    isDarkTheme: boolean;
}

type IconType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  min-height: 38px;
  max-height: 38px;
  width: 100%;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: row;
  background: ${props => props.background};
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 10px;
  margin: 3px 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
`;
