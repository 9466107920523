import styled from "styled-components";
import {ButtonType} from "@dropDesk/presentation/components/buttons/button_main/button_main";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export const Button = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  color: ${(props) => props.letterColor};
  font-size: ${(props) => `${props.letterFontSize}px`};
  font-weight: bold;
  width: ${(props) => `${props.widthPercentage}%`};
  min-width: ${(props) => `${props.widthPercentage}%`};
  border: 1px solid ${props => props.borderColor ?? 'transparent'};
  height: ${(props) => `${props.height}px`};
  min-height: ${(props) => `${props.height}px`};
  border-radius: ${(props) => `${props.borderRadius}px`};
  letter-spacing: 1px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
