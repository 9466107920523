import styled from "styled-components";

type ContainerHeaderType = {
    bordercolor: string;
    background: string;
}


type TextType = {
    color: string;
}

type IconType = {
    color: string;
    size: number;
}

type ContentEditType = {
    canChange: boolean;
    color: string;
}

type LineClampType = {
    width: number;
    toShare?: boolean;
}

export const Container = styled.div<ContainerHeaderType>`
  padding: 0 16px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
  background: ${(props) => props.background};
`;

export const Text = styled.span<TextType>`
  font-size: 14px;
  color: ${(props) => props.color};
  letter-spacing: 1.5px;
`;
export const ContentProtocol = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const Separator = styled.div`
  display: flex;
  width: 25px;
  min-width: 25px;
  align-items: center;
  justify-content: center;
`;

export const ContentButtonMoreInformation = styled.div`
  display: flex;
`;
export const ContentMoreInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65px;
  flex-direction: column;
  padding: 0 0 5px 0;
`;

export const TextProtocol = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
`;
export const ContainerDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const ContentDescriptionProblem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 48px;
  max-height: 48px;
  padding: 0 10px;
  justify-content: space-between;
`;
export const ContentClientData = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  min-height: 20px;
  max-height: 20px;
`;

export const ContentDataTicket = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const IconEdit = styled.i<IconType>`
  font-size: ${props => props.size}px;
  color: ${props => props.color};
  cursor: pointer;
  display: none;
  pointer-events: none;
  margin-left: 3px;
`;


export const ContentEdit = styled.div<ContentEditType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
  max-height: 20px;
  width: fit-content;

  &:hover ${IconEdit} {
    display: ${(props) => props.canChange ? 'inline' : 'none'};
  }

  &:hover {
    text-decoration: ${(props) => props.canChange ? 'underline' : 'auto'};
    text-decoration-color: ${props => props.color};
    cursor: ${(props) => props.canChange ? 'pointer' : 'auto'};
  }
`;

export const ContentSector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
  max-height: 20px;
  width: fit-content;
`;
export const ContentLineClamp = styled.div<LineClampType>`
  display: block;
  max-width: calc((100vw - ${props => `${props.width}px`}) / ${props => props.toShare ? 2 : 1}) !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
`;

export const ContentDateHour = styled.div`
  display: flex;
  flex-direction: row;
`;


export const TextDescriptionProblem = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  text-align: left;
`;

export const TextClient = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  text-align: left;
`;

