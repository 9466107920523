import styled from "styled-components";

type ContainerType = {
    background: string;
    collapsedMenu: boolean;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 61px);
  background: ${(props) => props.background};
  overflow: auto;
    // width: calc(100vw - ${props => props.collapsedMenu ? `80px` : `250px`});
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0px 32px;
`;

