import {observer} from "mobx-react";
import {
    Button,
    ContainerRightButtons,
    ContentButton,
} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import {Tooltip} from "antd";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import AudioRecorder from "@dropDesk/presentation/components/audio_recorder";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";
import {MessageChatType} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import SendButton from "@dropDesk/presentation/components/chat_view/chat_message/input_message/send_button";
import ModalsInput from "@dropDesk/presentation/components/chat_view/chat_message/input_message/modals";
import React, {useState} from "react";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import {IoController} from "@dropDesk/presentation/pages/io/io.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ContainerButtonInput} from "../styled";


const RightButtons = observer(
    ({
         message,
         onSendMessage,
         setVisibleModalResponseStandard,
         visibleModalResponseStandard,
         handleAllChanges,
         sendAudio,
         ioController,
         handleOnCloseModalResponseStandard
     }: {
         message: TicketMessageEntity
         onSendMessage: (message: TicketMessageEntity) => void,
         setVisibleModalResponseStandard: React.Dispatch<React.SetStateAction<boolean>>
         visibleModalResponseStandard: boolean
         handleAllChanges: (event: { target: { name: string; value: any; checked: any; type: string } }) => void,
         sendAudio: (message: TicketMessageEntity, audioRecorder: AudioRecordEntity) => void
         ioController: IoController
         handleOnCloseModalResponseStandard: () => void

     }
    ) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const handleSelectResponseStandard = (responseStandard: ResponseStandardEntity): void => {
            const currentDescription = message.getText === "/" ? "" : message.getText;
            const newDescription = currentDescription + responseStandard.description;
            handleAllChanges({target: {name: 'textMessage', value: newDescription, checked: false, type: 'text'}});
        }

        return (
            <ContainerRightButtons>

                {message.type === MessageChatType.textMessage && !appController.isUserClient &&
                    <Tooltip title={`Exibir respostas rápidas`}>
                        <ContainerButtonInput
                            accent={colors.accent}
                            background={colors.buttonOnBackground}
                        >
                            <ContentButton>
                                <Button
                                    onClick={() => {
                                        setVisibleModalResponseStandard(true);
                                    }}
                                >
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.thunder}
                                        size={23}
                                        style={{cursor: 'pointer'}}
                                    />
                                </Button>
                            </ContentButton>
                        </ContainerButtonInput>
                    </Tooltip>}
                {message.getText.length === 0 &&

                    <AudioRecorder
                        ioController={ioController}
                        messageId={message.id}
                        sendAudio={(audioRecorder: AudioRecordEntity) => sendAudio(message, audioRecorder)}
                        onStart={() => {
                            handleAllChanges({
                                target: {
                                    name: 'type',
                                    value: MessageChatType.audioMessage,
                                    checked: false,
                                    type: 'text'
                                }
                            });
                        }}
                        onCancel={() => {
                            handleAllChanges({
                                target: {
                                    name: 'type',
                                    value: MessageChatType.textMessage,
                                    checked: false,
                                    type: 'text'
                                }
                            });
                        }}
                    />
                }
                <ModalsInput
                    visibleModalResponseStandard={visibleModalResponseStandard}
                    setVisibleModalResponseStandard={setVisibleModalResponseStandard}
                    handleSelectResponseStandard={(responseStandard) => {
                        handleSelectResponseStandard(responseStandard);
                        setVisibleModalResponseStandard(false);
                    }}
                    handleOnCloseModalResponseStandard={handleOnCloseModalResponseStandard}
                />
                {(message.getText && message.type === MessageChatType.textMessage) &&
                    <ContainerButtonInput
                        accent={colors.accent}
                        background={colors.buttonOnBackground}
                    >
                        <SendButton onClickSend={() => onSendMessage(message)}/>
                    </ContainerButtonInput>}
            </ContainerRightButtons>
        )
    });
export default RightButtons
