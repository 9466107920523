import {observer} from "mobx-react";
import React, {useContext} from "react";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {useNavigate} from "react-router-dom";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ContentOneLineClamp} from "@dropDesk/presentation/pages/ticket/ui/search/table/styled";
import TagEvaluation from "@dropDesk/presentation/components/tag/tag_evaluation";

const TableEvaluations = observer(
    ({
         setUser,
         getDataFromPage,
         tableTicketEvaluation,
         searchParam,
         loading,
     }: {
        setUser: (user: UserEntity) => void,
        getDataFromPage: (page: number) => void,
        tableTicketEvaluation: ListPaginationEntity<TicketEvaluationEntity>,
        searchParam: string,
        loading: boolean,
    }) => {
        const navigate = useNavigate();
        const collapsed = useContext(MenuCollapsedContext);

        const tableColumn = [
            {
                title: 'Protocolo',
                dataIndex: 'number',
                key: 'id',
                width: 150,
                render: (text: string, record: TicketEvaluationEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={`${record.ticket?.number.toString() ?? ''}`}
                    />
                ),
            },
            {
                title: 'Contato',
                dataIndex: 'url',
                render: (text: string, record: TicketEvaluationEntity) => (
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                        <div style={{width: 35}}>
                            <AvatarImage
                                useCursorPointer={!!record.ticket?.userClient?.urlImageProfile}
                                title={record.ticket?.userClient?.name ?? ''}
                                size={35}
                                url={record.ticket?.userClient?.urlImageProfile}
                                round={true}
                                withBorder={true}
                                hasPreviewImage={true}
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            marginLeft: 10
                        }}>

                            <ContentOneLineClamp>
                                <HighLightWords
                                    searchWords={searchParam}
                                    textToHighlight={record.ticket?.userClient?.name ?? ''}
                                />
                            </ContentOneLineClamp>

                        </div>

                    </div>
                ),
            },
            {
                title: 'Empresa',
                ellipsis: true,
                dataIndex: 'socialName',
                key: 'id',
                render: (text: string, record: TicketEvaluationEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={record.ticket?.client ? record.ticket.client.socialName : ''}
                    />
                ),
            },
            {
                title: 'Atendente',
                ellipsis: true,
                dataIndex: 'attendant',
                key: 'id',
                render: (text: string, record: TicketEvaluationEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={record.ticket?.attendant?.name ?? ''}
                    />
                ),
            },

            {
                title: 'Avaliação',
                key: 'id',
                dataIndex: 'value',
                width: 120,
                render: (text: string, record: TicketEvaluationEntity) => {
                    return (
                        <>
                            {record.value && <TagEvaluation
                                value={record.value}
                            />}
                        </>
                    )
                }
            },
            {
                title: 'Avaliado em', ellipsis: true, dataIndex: 'date', key: 'id', render: (text: string) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },
        ];

        return (
            <TableGeneric<TicketEvaluationEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableTicketEvaluation.data}
                onSelectedRow={(ticketEvaluation: TicketEvaluationEntity) => {
                    navigate(`${RoutesEnum.reportrate}/${ticketEvaluation.id}`)
                }}
                loading={loading}
                labelNoResults={'Nenhuma avaliação encontrada na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableTicketEvaluation.totalRows}
                page={tableTicketEvaluation.page}
                pageSize={tableTicketEvaluation.limit}
                rowSelection={null}
                onChange={(page) => getDataFromPage(page - 1)}
                //extraLayout={70}
            />
        )
    })
export default TableEvaluations;
