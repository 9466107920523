import {
    CardBrand,
    PaymentProvider,
    PixType,
    TabsPayment
} from "@dropDesk/domain/entities/payment_method/payment_method.enum";

export class TokenizeCardEntity {
    number!: string;
    expirationDate!: string;
    cvv!: string;
    holderName!: string;

    public getBrand(brand: string): CardBrand {
        const brands: Record<string, CardBrand> = {
            [CardBrand.visa]: CardBrand.visa,
            [CardBrand.amex]: CardBrand.amex,
            [CardBrand.elo]: CardBrand.elo,
            [CardBrand.diners]: CardBrand.diners,
            [CardBrand.discover]: CardBrand.discover,
            [CardBrand.mastercard]: CardBrand.mastercard,
            [CardBrand.unknown]: CardBrand.unknown,
        };
        return brands[brand] ?? CardBrand.unknown;
    }

    constructor({
                    number,
                    expirationDate,
                    cvv,
                    holderName,
                }: {
        number: string;
        expirationDate: string;
        cvv: string;
        holderName: string;
    }) {
        Object.assign(this, {
            number,
            expirationDate,
            cvv,
            holderName,
        });
    }

    copyWith({
                 number,
                 expirationDate,
                 cvv,
                 holderName,
             }: {
        number?: string;
        expirationDate?: string;
        cvv?: string;
        holderName?: string;

    }): TokenizeCardEntity {
        return new TokenizeCardEntity({
            number: number ?? this.number,
            expirationDate: expirationDate ?? this.expirationDate,
            cvv: cvv ?? this.cvv,
            holderName: holderName ?? this.holderName,
        })
    }
}

export class PixEntity {
    type!: PixType;

    constructor({
                    type
                }: {
        type: PixType;
    }) {
        Object.assign(this, {
            type
        });
    }
}

export class BoletoEntity {
    use!: boolean;

    constructor({
                    use
                }: {
        use: boolean;
    }) {
        Object.assign(this, {
            use
        });
    }
}

export class PaymentMethodEntity {
    idCompany!: string;

    provider?: PaymentProvider;

    boleto?: BoletoEntity;

    card?: CardEntity;

    pix?: PixEntity;

    createdAt?: string;

    public get paymentMethodChangeLabel(): string {
        if (!!this.card) {
            return `Sua forma de pagamento é #cartão de crédito#. Você pode trocar a forma de pagamento
            a qualquer momento clicando no botão ao lado.`;
        }

        if (!!this.boleto) {
            return `Sua forma de pagamento é #boleto#. Você pode trocar a forma de pagamento
            a qualquer momento clicando no botão ao lado.`;
        }
        return `Sua forma de pagamento é #pix#. Você pode trocar a forma de pagamento
            a qualquer momento clicando no botão ao lado.`;
    }

    public get convertPaymentMethodToTabPayment(): TabsPayment {
        if (!!this.card) {
            return TabsPayment.creditCard
        }
        if (!!this.boleto) {
            return TabsPayment.boleto;
        }
        return TabsPayment.pix;
    }

    constructor({
                    idCompany,
                    createdAt,
                    provider,
                    boleto,
                    card,
                    pix,
                }: {
        idCompany: string;
        createdAt?: string;
        provider?: PaymentProvider;
        boleto?: BoletoEntity;
        card?: CardEntity;
        pix?: PixEntity;
    }) {
        Object.assign(this, {
            idCompany,
            createdAt,
            provider,
            boleto,
            card,
            pix,
        });
    }

    public static fromJson(json: Record<string, any>): PaymentMethodEntity {
        return new PaymentMethodEntity({
            idCompany: json['idCompany'],
            createdAt: json['createdAt'],
            provider: json['provider'],
            card: json['card'] ? CardEntity.fromJson(json['card']) : undefined,
            pix: json['pix'] ? json['pix'] : null,
            boleto: json['boleto'] ? json['boleto'] : undefined,
        });
    }
}

export class CardEntity {

    expirationDate!: string;
    provider?: PaymentProvider;
    token!: string;
    displayNumber!: string;
    idCompany!: string;
    bin!: string;
    brand?: CardBrand;
    idExternal?: string;
    idCustomer?: string;

    constructor({
                    token,
                    displayNumber,
                    idCompany,
                    provider,
                    expirationDate,
                    bin,
                    brand,
                    idExternal,
                    idCustomer
                }: {
        token: string;
        displayNumber: string;
        provider?: PaymentProvider;
        expirationDate: string;
        idCompany: string;
        bin: string;
        brand: CardBrand;
        idExternal?: string;
        idCustomer?: string;
    }) {
        Object.assign(this, {
            token,
            displayNumber,
            provider,
            expirationDate,
            bin,
            idCompany,
            brand,
            idExternal,
            idCustomer
        });
    }

    public static fromJson(json: Record<string, any>): CardEntity {
        return new CardEntity({
            provider: json['provider'],
            token: json['token'],
            displayNumber: json['displayNumber'],
            expirationDate: json['expirationDate'],
            idCompany: json['idCompany'],
            bin: json['bin'],
            brand: json['brand'],
            idExternal: json['idExternal'],
        });
    }

}

export class ValidateNumberCreditCard {
    isValid!: boolean;
    maxLength!: number;

    constructor({
                    isValid,
                    maxLength,
                }: {
        isValid: boolean;
        maxLength: number;
    }) {
        Object.assign(this, {
            isValid,
            maxLength,
        });
    }
}
