import styled from "styled-components";

export type TextType = {
    color: string;
    size: number;
}

export type InputType = {
    color: string;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
`;

