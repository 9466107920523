import styled, {keyframes} from "styled-components";

type ContainerType = {
    background: string;
    border: string;
};

type ColorType = {
    color: string;
};


export const ContainerRating = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentRating = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  height: 360px;
  min-height: 360px;
  width: 500px;
  border: 1px solid ${props => props.border};
  background-color: ${props => props.background};
  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 60px;
  max-height: 60px;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding-right: 5px;
`;

export const TextRating = styled.span<ColorType>`
  font-size: 15px;
  width: 20%;
  color: ${props => props.color};
  text-align: right;
`;
export const TextDescriptionRate = styled.span<ColorType>`
  font-size: 15px;
  color: ${props => props.color};
  text-align: right;
  padding-right: 5px;
`;

export const TextTotalRating = styled.span<ColorType>`
  font-size: 15px;
  width: 40%;
  color: ${props => props.color};
  text-align: center;
`;

export const Title = styled.span<ColorType>`
  font-size: 16px;
  color: ${props => props.color};
  text-align: center;
`;

export const BodyData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 16px 16px 16px;
`;

export const ContentDataIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 20%;
`;

export const ContentDataTotalRating = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 20%;
`;

export const ContentDataDescriptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 40%;
`;

export const Footer = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  height: 15%;
  background-color: ${props => props.background};
  border-radius: 0px 0px 5px 5px;
`;

export const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex: 1;
`;

export const TextNso = styled.span<ColorType>`
  font-size: 15px;
  color: ${props => props.color};
  text-align: right;
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentRatingLoading = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  height: 350px;
  min-height: 350px;
  width: 500px;
  border-radius: 5px;
  background-color: ${props => props.background};
  justify-content: space-between;
`;

export const HeaderLoading = styled.div<ContainerType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.background};
  height: 10%;
  border-radius: 5px 5px 0px 0px;
`;


export const FooterLoading = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 15%;
  background-color: ${props => props.background};
  border-radius: 0px 0px 5px 5px;
`;
