import {injectable} from "inversify";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import firebaseAuth from "firebase/auth";

@injectable()
export abstract class AuthRepository {
    public abstract getByToken(): Promise<UserEntity | null>;

    public abstract createUser(email: string, password: string): Promise<firebaseAuth.UserCredential>;

    public abstract login(email: string, password: string): Promise<void>;

    public abstract logout(): Promise<void>;

    public abstract recoveryAccount(email: string): Promise<void>;

    public abstract getCurrentUser(): Promise<UserEntity | null>;

    public abstract loginServer(): Promise<Record<string, boolean>>;

    public abstract logoutServer(): Promise<Record<string, boolean>>;

    public abstract logoutByAdmin(idUser: string): Promise<Record<string, boolean>>;

    public abstract getUserByFirebaseAuth(): Promise<firebaseAuth.User | null>;

    public abstract getUserByDecodedToken(): Record<string, unknown> | null;


}

