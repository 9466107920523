import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {SetCompanyUseCase} from "@dropDesk/domain/use_case/company/set_company.usecase";
import {FindByPKCompanyUseCase} from "@dropDesk/domain/use_case/company/findbypk_company.usecase";
import {
    CompanyEntity,
    CompanyExtraInfoEntity,
    CompanyPaymentInfo
} from "@dropDesk/domain/entities/company/company.entity";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {CreateAccountCompanyUseCase} from "@dropDesk/domain/use_case/company/create_account_company.usecase";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {
    ListActiveConnectionsCompanyUseCase
} from "@dropDesk/domain/use_case/company/list_active_connections_company.usecase";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";
import {
    newBoletoEntity,
    newCompany,
    newCompanyPaymentInfo, newPixEntity,
    newTokenizeCardEntity
} from "@dropDesk/presentation/pages/company/controller/new_company";
import {
    newCompanyConfiguration
} from "@dropDesk/presentation/pages/company_configurations/controller/new_configuration";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {
    getIdCompanyByLocalStorage,
    initializeConfigurationLocalStorage,
    preventMissingPage,
    setSearchParamLocalStorage, sleep
} from "@dropDesk/utils/helpers/common";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";
import {SearchCNPJUseCase} from "@dropDesk/domain/use_case/common/cnpj/search_cnpj.usecase";
import {SearchAddressUseCase} from "@dropDesk/domain/use_case/common/localization/search_address.usecase";
import {SetCompanyExtraInfoUseCase} from "@dropDesk/domain/use_case/company/set_extra_info_company.usecase";
import {
    BoletoEntity,
    PaymentMethodEntity,
    PixEntity,
    TokenizeCardEntity
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {PaymentProvider, PixType, TabsPayment} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {
    SetPaymentMethodCompanyUseCase
} from "@dropDesk/domain/use_case/company/set_payment_method_subscription.usecase";
import {ClientCreateAccountEntity} from "@dropDesk/domain/entities/client/client_create_account_entity";
import {PaymentServiceHandler} from "@dropDesk/presentation/app/iugu_service_handler";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {SetUpdateLegacyCompanyUseCase} from "@dropDesk/domain/use_case/company/set_update_legacy_company.usecase";

configure({
    enforceActions: "always",
});

@injectable()
export class CompanyController {

    private readonly _setCompanyUseCase: SetCompanyUseCase;
    private readonly _createAccountCompanyUseCase: CreateAccountCompanyUseCase;
    private readonly _findByPKCompanyUseCase: FindByPKCompanyUseCase;
    private readonly _listActiveConnectionsCompanyUseCase: ListActiveConnectionsCompanyUseCase;
    private readonly _searchCNPJUseCase: SearchCNPJUseCase;
    private readonly _searchAddressUseCase: SearchAddressUseCase;
    private readonly _setCompanyExtraInfoUseCase: SetCompanyExtraInfoUseCase;
    private readonly _setPaymentMethodCompanyUseCase: SetPaymentMethodCompanyUseCase;
    private readonly _setUpdateLegacyCompanyUseCase: SetUpdateLegacyCompanyUseCase;

    constructor(
        @inject(SetCompanyUseCase) setCompanyUseCase: SetCompanyUseCase,
        @inject(CreateAccountCompanyUseCase) createAccountCompanyUseCase: CreateAccountCompanyUseCase,
        @inject(FindByPKCompanyUseCase) findByPKCompanyUseCase: FindByPKCompanyUseCase,
        @inject(SearchCNPJUseCase) searchCNPJUseCase: SearchCNPJUseCase,
        @inject(ListActiveConnectionsCompanyUseCase) listActiveConnectionsCompanyUseCase: ListActiveConnectionsCompanyUseCase,
        @inject(SearchAddressUseCase) searchAddressUseCase: SearchAddressUseCase,
        @inject(SetCompanyExtraInfoUseCase) setCompanyExtraInfoUseCase: SetCompanyExtraInfoUseCase,
        @inject(SetPaymentMethodCompanyUseCase) setPaymentMethodCompanyUseCase: SetPaymentMethodCompanyUseCase,
        @inject(SetUpdateLegacyCompanyUseCase) setUpdateLegacyCompanyUseCase: SetUpdateLegacyCompanyUseCase,
    ) {
        makeObservable(this);
        this._setCompanyUseCase = setCompanyUseCase;
        this._findByPKCompanyUseCase = findByPKCompanyUseCase;
        this._createAccountCompanyUseCase = createAccountCompanyUseCase;
        this._listActiveConnectionsCompanyUseCase = listActiveConnectionsCompanyUseCase;
        this._searchCNPJUseCase = searchCNPJUseCase;
        this._searchAddressUseCase = searchAddressUseCase;
        this._setCompanyExtraInfoUseCase = setCompanyExtraInfoUseCase;
        this._setPaymentMethodCompanyUseCase = setPaymentMethodCompanyUseCase;
        this._setUpdateLegacyCompanyUseCase = setUpdateLegacyCompanyUseCase;
    }

    newCompany: CompanyEntity = newCompany();

    newPaymentInfo: CompanyPaymentInfo = newCompanyPaymentInfo();

    table = new ListPaginationEntity<CompanyConnectionsEntity>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 52),
        totalRows: 0,
        data: observable.array([])
    });

    newTokenizeCard: TokenizeCardEntity = newTokenizeCardEntity();

    newPix: PixEntity = newPixEntity();

    newBoleto: BoletoEntity = newBoletoEntity();

    @observable
    loading = false;

    @observable
    newTokenizeCardEntity: TokenizeCardEntity | null = null;

    @observable
    newPixEntity: PixEntity | null = null;

    @observable
    newBoletoEntity: BoletoEntity | null = null;

    @observable
    activeTabPayment: TabsPayment | null = null;

    @observable
    loadingMessage?: string | null = null;

    @observable
    company?: CompanyEntity;

    @observable
    tableConnections: ListPaginationEntity<CompanyConnectionsEntity> = this.table;

    @observable
    searchParam = '';

    @observable
    userActiveConnection: CompanyConnectionsEntity | null = null;

    @observable
    pagesData: Record<number, CompanyConnectionsEntity[]> = {};

    @observable
    paymentInfo: CompanyPaymentInfo = this.newPaymentInfo;

    @observable
    isEditPaymentInfo: { visible: boolean, canReturnPaymentType: boolean } = {
        visible: false,
        canReturnPaymentType: false
    };

    @action
    resetTable() {
        this.pagesData = {};
        this.setTableConnections(this.table);
    }

    @action
    setActiveTabPayment(value: TabsPayment | null) {
        this.activeTabPayment = value;
    }

    @action
    setUserActiveConnection(connection: CompanyConnectionsEntity) {
        this.userActiveConnection = connection;
    }

    @action
    setTableConnections(value: ListPaginationEntity<CompanyConnectionsEntity>) {
        this.tableConnections = value;
        const isNecessaryReload = preventMissingPage(this.tableConnections);
        if (isNecessaryReload) {
            this.listActiveConnections('');
        } else {
            this.pagesData[this.tableConnections.page] = this.tableConnections.data;
        }
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    setIsEditPaymentInfo(object: { visible: boolean, canReturnPaymentType: boolean }) {
        this.isEditPaymentInfo = object;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }


    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }

    @action
    setCompany(company: CompanyEntity) {
        this.company = company;
    }

    @action
    setPaymentInfo(paymentInfo: CompanyPaymentInfo) {
        this.paymentInfo = paymentInfo;
    }

    @action
    setTokenizeCard(newTokenizeCardEntity: TokenizeCardEntity | null) {
        this.newTokenizeCardEntity = newTokenizeCardEntity;
    }

    @action
    setBoleto(newBoletoEntity: BoletoEntity | null) {
        this.newBoletoEntity = newBoletoEntity;
    }

    @action
    setPix(newPixEntity: PixEntity | null) {
        this.newPixEntity = newPixEntity;
    }

    @action
    createAccount = async (
        onSuccess: () => void,
        sector?: SectorEntity,
        user?: UserEntity,
        clientCreateAccount?: ClientCreateAccountEntity
    ): Promise<void> => {
        try {
            this.startLoading("signIn.create");
            const _user = user ? user.copyWith({
                id: generateUUIDV4(),
                idCompany: this.company!.id,
                role: UserRole.attendant,
            }) : undefined;
            const _sector = sector ? sector.copyWith({
                id: generateUUIDV4(),
                idCompany: this.company!.id,
            }) : undefined;
            const company = !clientCreateAccount ? this.company : undefined;
            await this._createAccountCompanyUseCase.call(company, _sector, _user, clientCreateAccount);
            onSuccess();
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    initializeConnections() {
        const routeName = RoutesEnum.activeConnections;
        const {lastPage, initSearchParam} = initializeConfigurationLocalStorage(routeName);
        this.tableConnections.limit = Math.floor((window.innerHeight - 265) / 52);
        this.tableConnections.page = lastPage;
        this.searchParam = initSearchParam;
        this.listActiveConnections(initSearchParam);
    }

    @action
    initializePaymentInfo(paymentInfo?: CompanyPaymentInfo) {
        this.setIsEditPaymentInfo({visible: true, canReturnPaymentType: false});
        if (paymentInfo) {
            this.paymentInfo = paymentInfo;
        }
    }

    @action
    makePaymentMethod() {
        this.setTokenizeCard(this.newTokenizeCard);
        this.setActiveTabPayment(TabsPayment.creditCard);
        this.setBoleto(this.newBoleto);
        this.setPix(this.newPix);
    }

    @action
    resetPaymentMethod() {
        this.setActiveTabPayment(null);
        this.setTokenizeCard(null);
        this.setBoleto(null);
        this.setPix(null);
    }

    @action
    set = async (onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("company.loadingSave");
            await this._setCompanyUseCase.call(this.company!);
            this.stopLoading();
            onSuccess("company.success.save");
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    onSearchCNPJ = async (cnpj: string): Promise<void> => {
        try {
            this.startLoading("client.loadingDocument");
            const response = await this._searchCNPJUseCase.call(removeMask(cnpj));
            this.setPaymentInfo(this.paymentInfo.copyWith({
                billingInfoHolder: this.paymentInfo.billingInfoHolder.copyWith({
                    name: response.socialName,
                    email: response.email,
                    phone: response.phone,
                })
            }));
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    onSearchZipCode = async (zipCode: string): Promise<void> => {
        try {
            this.startLoading("client.loadingAddress");
            const response = await this._searchAddressUseCase.call(zipCode);
            this.setPaymentInfo(this.paymentInfo.copyWith({
                billingAddress: this.paymentInfo.billingAddress.copyWith({
                    ...response,
                })
            }))
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getPaymentInfo(company: CompanyEntity): CompanyExtraInfoEntity {
        return company.extraInfo?.copyWith({
            paymentInfo: this.paymentInfo
        }) ?? new CompanyExtraInfoEntity({
            paymentInfo: this.paymentInfo
        });
    }

    @action
    savePaymentInfo = async (
        company: CompanyEntity,
        onSuccess: (key: string) => void,
    ): Promise<CompanyEntity | undefined> => {
        try {
            this.startLoading("company.loadingPaymentInfo");
            const extraInfo = this.getPaymentInfo(company);
            const response = await this._setCompanyExtraInfoUseCase.call(extraInfo);
            onSuccess("company.success.paymentInfoSave");
            this.setIsEditPaymentInfo({visible: false, canReturnPaymentType: true});
            this.stopLoading();
            return response;
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getPaymentMethod = async (): Promise<PaymentMethodEntity> => {
        const cardEntity =
            this.activeTabPayment === TabsPayment.creditCard && this.newTokenizeCardEntity
                ?
                await PaymentServiceHandler.getTokenizedCard(this.newTokenizeCardEntity)
                :
                null
        const paymentMethod = new PaymentMethodEntity({
            idCompany: getIdCompanyByLocalStorage(),
            card: cardEntity ?? undefined,
            pix: this.activeTabPayment === TabsPayment.pix ? new PixEntity({
                type: PixType.static
            }) : undefined,
            boleto: this.activeTabPayment === TabsPayment.boleto ? new BoletoEntity({
                use: true
            }) : undefined
        });
        return paymentMethod;
    }

    @action
    setPaymentMethod = async (
        onSuccess?: (key: string, paymentMethod: PaymentMethodEntity) => void
    ): Promise<void> => {
        try {
            this.startLoading("company.loadingPaymentMethod");
            const paymentMethod = await this.getPaymentMethod();
            const response = await this._setPaymentMethodCompanyUseCase.call(paymentMethod);
            await sleep(3);
            this.stopLoading();
            if (onSuccess) {
                onSuccess('company.success.savePaymentMethod', response);
            }
        } catch (err: any) {
            this.stopLoading();
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
        }
    };

    @action
    updateLegacy = async (onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("company.loadingSave");
            const newPaymentMethod = await this.getPaymentMethod();
            const newExtraInfo = this.getPaymentInfo(this.company!);
            const companyUpdateLegacy = this.company!.toUpdateLegacy(newPaymentMethod, newExtraInfo);
            await this._setUpdateLegacyCompanyUseCase.call(companyUpdateLegacy);
            this.stopLoading();
            onSuccess("company.success.save");
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    listActiveConnections = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listActiveConnectionsCompanyUseCase.call(this.tableConnections.page, this.tableConnections.limit, this.searchParam);
            this.setTableConnections(response);
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableConnections(this.tableConnections.copyWith({
                ...this.tableConnections,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableConnections(this.tableConnections.copyWith({
                ...this.tableConnections,
                page: page,
            }))
            return this.listActiveConnections(this.searchParam);
        }
    }

    @action
    makeCompany = (): void => {
        const idCompany = generateUUIDV4();
        const configurations = newCompanyConfiguration();
        this.setCompany(this.newCompany.copyWith({
            id: idCompany,

            configurations: configurations.copyWith({
                id: generateUUIDV4(),
                idCompany: idCompany
            }),
        }));
    }
    @action
    getCompany = async (): Promise<CompanyEntity> => {
        try {
            this.startLoading("company.loadingInit");
            const response = await this._findByPKCompanyUseCase.call();
            this.setCompany(response);
            this.stopLoading();
            return response;
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
            throw new ServerError(err);
        }
    }

    @action
    handleEditPaymentInfo(company: CompanyEntity, isModal = false, visible = true) {
        if (!isModal) {
            this.setIsEditPaymentInfo({
                visible,
                canReturnPaymentType: this.isEditPaymentInfo.canReturnPaymentType
            });
        }
        if (company.extraInfo?.paymentInfo) {
            this.setPaymentInfo(company.extraInfo.paymentInfo);
        }
    }

}
