import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";

@injectable()
export class SendMessageUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(message: TicketMessageEntity, associateSenderToTicket: boolean): Promise<TicketMessageEntity> {
        return this._repository.sendMessage(message, associateSenderToTicket);
    }
}

