import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {InfoOrigin} from "@dropDesk/domain/entities/generic/info_origin.enum";
import {TicketConfig} from "@dropDesk/domain/entities/ticket/ticket_config";

export const newTicket = (status: TicketConfig, priority: TicketConfig): TicketEntity => new TicketEntity({
    id: generateUUIDV4(),
    idSector: "",
    status: status.id,
    idCompany: getIdCompanyByLocalStorage(),
    description: '',
    idClient: '',
    number: -1,
    origin: InfoOrigin.system,
    users: {},
    idOpenedByUser: '',
    messages: [],
    priority: priority.id,
    statusObject: status,
    priorityObject: priority,
    deleted: false,
});

