export enum AudioRecordStatus {
    record = 'record',
    recording = 'recording',
    stop = 'stop'
}

export class AudioCacheEntity {
    audioBlob?: string;
    duration!: number;
    status!: AudioRecordStatus;

    constructor({
                    audioBlob,
                    duration,
                    status
                }: {
        audioBlob?: string;
        duration: number;
        status: AudioRecordStatus;
    }) {
        Object.assign(this, {
            audioBlob,
            duration,
            status
        });
    }
}


export class AudioRecordEntity {
    status!: AudioRecordStatus;
    mediaRecorder?: MediaRecorder;
    chunks!: Blob[];
    stream?: MediaStream;
    audioBlob?: string;
    duration!: number;

    public toCache(): AudioCacheEntity {
        return new AudioCacheEntity({
            audioBlob: this.audioBlob,
            duration: this.duration,
            status: this.status
        });
    }

    static fromCache(cache: AudioCacheEntity): AudioRecordEntity {
        return new AudioRecordEntity({
            audioBlob: cache.audioBlob,
            duration: cache.duration,
            status: cache.status,
            chunks: []
        });
    }

    constructor({
                    status,
                    mediaRecorder,
                    stream,
                    chunks,
                    audioBlob,
                    duration
                }: {
        status: AudioRecordStatus;
        mediaRecorder?: MediaRecorder;
        stream?: MediaStream;
        chunks: Blob[];
        audioBlob?: string;
        duration: number;
    }) {
        Object.assign(this, {
            status,
            mediaRecorder,
            stream,
            chunks,
            audioBlob,
            duration
        });
    }

    copyWith({
                 status,
                 mediaRecorder,
                 stream,
                 chunks,
                 audioBlob,
                 duration
             }: {
        status?: AudioRecordStatus;
        mediaRecorder?: MediaRecorder;
        stream?: MediaStream;
        chunks?: Blob[];
        audioBlob?: string;
        duration?: number;
    }): AudioRecordEntity {
        return new AudioRecordEntity({
            status: status ?? this.status,
            mediaRecorder: mediaRecorder ?? this.mediaRecorder,
            stream: stream ?? this.stream,
            chunks: chunks ?? this.chunks,
            audioBlob: audioBlob ?? this.audioBlob,
            duration: duration ?? this.duration,
        })
    }
}
