import {Container, ContainerSearch} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import React, {useEffect} from "react";
import {ContentList, SubContentList} from "./styled";
import SearchBarTickets from "@dropDesk/presentation/pages/ticket/ui/search/search_bar";
import {TicketController} from "@dropDesk/presentation/pages/ticket/controller/ticket.controller";
import TableSearchTickets from "@dropDesk/presentation/pages/ticket/ui/search/table";
import ModalsTickets from "@dropDesk/presentation/pages/ticket/ui/search/modals";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {useTranslation} from "react-i18next";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import FooterGarbage from "@dropDesk/presentation/components/footer/footer_garbage";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useNavigate} from "react-router-dom";
import {ChatController} from "@dropDesk/presentation/pages/chat/controller/chat.controller";

const Tickets = (observer(() => {

        const {t} = useTranslation();
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const controller = useInjection(TicketController);
        const userController = useInjection(UserController);
        const chatController = useInjection(ChatController);
        const permissionDeleteData = !!appController.user?.permissionDeleteData && !appController.isUserClient;
        const heightGarbage = permissionDeleteData ? 0 : 50;
        const navigate = useNavigate();

        useEffect(() => {
            controller.initialize(heightGarbage - appController.heightShowingHeaderInfo);
            return () => {
                controller.dispose();
            }
        }, []);

        const onSuccess = (key: string) => {
            toastMessage.success(t(key));
        }

        return (
            <ContainerSearch background={colors.onBackground}>

                <ContentList>

                    <SearchBarTickets
                        onSearch={(searchParam) => controller.subscriptionListTickets(searchParam ?? controller.searchParam)}
                        filterStatus={controller.filterStatus}
                        filterPriority={controller.filterPriority}
                        setFilterStatus={(filter, withList) => controller.setFilterStatus(filter, withList)}
                        setFilterPriority={(filter, withList) => controller.setFilterPriority(filter, withList)}
                        visibleInputSearch={controller.rowSelectionTicket.length === 0}
                        loading={controller.loading}
                        visibleButtonsOfActions={!controller.listOnlyDeleted && controller.rowSelectionTicket.length === 0}
                        visibleButtonDeleted={!controller.listOnlyDeleted && controller.rowSelectionTicket.length > 0}
                        visibleButtonRestore={controller.listOnlyDeleted && controller.rowSelectionTicket.length > 0}
                        setListOnlySectorEmpty={(value, withList) => controller.setListOnlySectorEmpty(value, withList)}
                        listOnlySectorEmpty={controller.listOnlySectorEmpty}
                    />

                    <SubContentList>
                        <TableSearchTickets
                            getDataFromPage={(page) => controller.getDataFromPage(page)}
                            tableTicket={controller.tableTicket}
                            rowSelectionTicket={controller.rowSelectionTicket}
                            setRowSelectionTicket={(ticket: TicketEntity[]) => controller.setRowSelectionTicket(ticket)}
                            setUser={(user) => userController.setUser(user)}
                            searchParam={controller.searchParam}
                            loading={controller.loading}
                            permitSelectionRow={controller.listOnlyDeleted}
                            heightGarbage={heightGarbage}
                            permissionDeleteData={permissionDeleteData}
                            onSelectedRow={(ticket) => {
                                chatController.setChat(ticket);
                                navigate(`${RoutesEnum.ticketsView}/${ticket.id}`);
                            }}
                        />

                        {permissionDeleteData &&
                            <FooterGarbage
                                setListOnlyDeleted={(value) => controller.setListOnlyDeleted(value)}
                                visibleListOnlyDeleted={controller.listOnlyDeleted}
                                loading={controller.loading}
                                text={'Atendimentos ativos'}
                                textDelete={'Atendimentos excluídos'}
                            />
                        }

                        <ModalsTickets
                            restoreTicket={() => controller.restore(onSuccess)}
                            deleteTicket={() => controller.delete(onSuccess)}
                            removeLineSelectRow={() => controller.removeLineSelectRow()}
                            userUrlImageProfile={userController.user?.urlImageProfile ?? ''}
                            userName={userController.user?.name ?? ''}
                            ticketNumber={controller.rowSelectionTicket.length > 0 ? controller.rowSelectionTicket[0].number : 1}
                            loading={controller.loading}
                        />
                    </SubContentList>

                </ContentList>

                {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                    height={250}
                    description={controller.loadingMessage}
                />}
            </ContainerSearch>
        )

    }
))
export default Tickets;
