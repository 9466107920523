import styled from "styled-components";

export const ContentInputAndListableTicketsDescriptionStaticDeleted = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

`;
export const ContentExportImport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const ContentInput = styled.div`
  display: flex;
  flex: 1;
`;

export const ContentFilter = styled.div`
  display: flex;
  height: 61px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0px 0px 0px;
  margin-top: 1px;
`;


export const ContentInputSearch = styled.div`
  max-width: 350px;
  width: 100%;
`;
export const ContentInputSearchAndFilter = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const PaddingFilters = styled.div`
  padding: 0 0 0 32px;
`;
