import styled from "styled-components";

export type TexteHeaderType = {
  color: string;
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 120px;
  max-height: 120px;

`;
export const ContentFilterAndInput = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const TextHeader = styled.strong<TexteHeaderType>`
  font-size: 18px;
  color: ${(props) => props.color};
  font-weight: bold;
  letter-spacing: 1px;
`;

export const PaddingFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-of-type) {
    padding: 0 32px 0 0;
  }
`;
export const ContentTextHeader = styled.div`
  display: flex;
  flex: .5;
  align-items: center;
  justify-content: flex-start;
`;

export const ContentInput = styled.form`
  display: flex;
  flex: 1;
  margin-right: 16px;
`;
export const ContentFilter = styled.div`
  display: flex;
  flex-direction: row;
`;


