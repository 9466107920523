import Card from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/card";
import {
    Container, Content, ContentCard, ContentInputs, ContentButton
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/styled";
import InputsCard
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/inputs_card";
import {
    CardEntity,
    TokenizeCardEntity,
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {removeMask, removeMaskStaticTelephoneNumber} from "@dropDesk/utils/helpers/string_helper";
import {Focused} from "react-credit-cards-2";
import React, {useState} from "react";
import ButtonPay
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/button_pay";
import CreditCardSaved
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/credit_card_saved";

const CreditCardPaymentMethod = (
    {
        newTokenizeCard,
        onUpdateTokenizeCard,
        loading,
        pay,
        cardSaved,
        onClickPayCreditCardSaved,
        isEditExternal,
        isReprovedPayment,
        hideButtonPay
    }: {
        newTokenizeCard: TokenizeCardEntity
        onUpdateTokenizeCard: (newTokenizeCard: TokenizeCardEntity) => void
        loading: boolean
        pay: () => void
        cardSaved?: CardEntity
        onClickPayCreditCardSaved: () => void
        isEditExternal?: boolean
        isReprovedPayment?: boolean
        hideButtonPay?: boolean
    }
) => {

    const [focusedCardInput, setFocusedCardInput] = useState<Focused>('number');

    const handleAllChanges = (event: {
        target: { name: string; value: any; checked: any; type: string }
    }, focused: Focused) => {

        const infoCard = newTokenizeCard.copyWith({
            [event.target.name]:
                (event.target.type === "checkbox" || event.target.type === "switch") ?
                    event.target.checked : event.target.name === "phone" ?
                        removeMaskStaticTelephoneNumber(event.target.value.replace(/\D/g, "")) :
                        (event.target.name === "number" || event.target.name === "cvv")
                            ? removeMask(event.target.value.replace(/\D/g, "")) :
                            event.target.value
        });

        onUpdateTokenizeCard(infoCard);
        setFocusedCardInput(focused);
    };

    return (
        <Container>
            {cardSaved &&
                <CreditCardSaved
                    cardSaved={cardSaved}
                    onClickPayCreditCardSaved={onClickPayCreditCardSaved}
                    loading={loading}
                    isEditExternal={isEditExternal}
                    isReprovedPayment={isReprovedPayment}
                />}
            <Content>
                <ContentCard>
                    <Card
                        newTokenizeCard={newTokenizeCard}
                        focused={focusedCardInput}
                    />
                </ContentCard>
                <ContentInputs>
                    <InputsCard
                        handleAllChanges={handleAllChanges}
                        newTokenizeCard={newTokenizeCard}
                        loading={loading}
                    />
                </ContentInputs>
            </Content>
            {!hideButtonPay && <ButtonPay
                loading={loading}
                newTokenizeCard={newTokenizeCard}
                pay={pay}
                isEditExternal={isEditExternal}
            />}
        </Container>
    );
}
export default CreditCardPaymentMethod;
