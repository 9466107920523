import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";

type Map = {
  value: string,
  label: string
}
export const destinyMap: Map[] = [
  {value: NoticeDestiny.user, label: "Atendente"},
  {value: NoticeDestiny.client, label: "Empresa"},
  {value: NoticeDestiny.allUsers, label: "Todos os Atendentes"},
  {value: NoticeDestiny.allClients, label: "Todos as Empresas"},
]
