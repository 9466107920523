import styled from "styled-components";

type ContainerType = {
    background: string;
};

type TextAsType = {
    color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 101px);
  overflow: hidden;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  flex: 1;
  border-radius: 5px;
  width: 100%;
`;
export const ContainerInputRange = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
export const ContainerHourAndTimeRange = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentHourAndTimeRange = styled.div`
  display: flex;
  flex-direction: row;

`;
export const ContentSecondHourAndTimeRange = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContentInputRange = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 330px;
`;
export const ContentSecondTimeRange = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const TextAs = styled.span<TextAsType>`
  font-size: 16px;
  color: ${props => props.color};
  padding: 0 16px;
`;

export const ContainerSecondTimeRange = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const TextSecondHour = styled.span<TextAsType>`
  font-size: 14px;
  color: ${props => props.color};
  padding: 0 16px;
`;
