import {useEffect, useState} from "react";
import {
    isValidCNPJ,
    isValidCPF,
    isValidEmail,
    isValidName,
    isValidNumberTelephone
} from "@dropDesk/utils/helpers/validators";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";
import {ClientJuridicalNature} from "@dropDesk/domain/entities/client/client_enum";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {validateAddress} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/validate_address";
import {BillingInfoHolder, CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";

export const useValidatorInputCompanyPaymentInfo = (
        paymentInfo: CompanyPaymentInfo
    ) => {

        const [errorMessageName, setErrorMessageName] = useState('');
        const [errorMessageEmail, setErrorMessageEmail] = useState('');
        const [errorMessageCNPJ, setErrorMessageCNPJ] = useState('');
        const [errorMessageCPF, setErrorMessageCPF] = useState('');
        const [errorMessageTelephone, setErrorMessageTelephone] = useState('');
        const [errorMessageCity, setErrorMessageCity] = useState('');
        const [errorMessageNeighborhood, setErrorMessageNeighborhood] = useState('');
        const [errorMessageNumber, setErrorMessageNumber] = useState('');
        const [errorMessageShortState, setErrorMessageShortState] = useState('');
        const [errorMessageState, setErrorMessageState] = useState('');
        const [errorMessageStreet, setErrorMessageStreet] = useState('');
        const [errorMessageZipCode, setErrorMessageZipCode] = useState('');
        const [isValidDataFormAddress, setIsValidDataFormAddress] = useState<boolean>(false);
        const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);

        useEffect(() => {
            validate();
        }, [paymentInfo]);

        const validate = () => {
            setErrorMessageName('');
            setErrorMessageEmail('');
            setErrorMessageTelephone('');
            setErrorMessageCNPJ('');
            setErrorMessageCPF('');

            if (!isValidCNPJ(removeMask(paymentInfo.billingInfoHolder.document ?? '')) && paymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.juridical) {
                setErrorMessageCNPJ('CNPJ inválido');
                setIsValidDataForm(false);
            } else if (!isValidCPF(removeMask(paymentInfo.billingInfoHolder.document ?? '')) && paymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.physical) {
                setErrorMessageCPF('CPF inválido');
                setIsValidDataForm(false);

            } else if (!isValidName(paymentInfo.billingInfoHolder.name) && paymentInfo.billingInfoHolder.juridicalNature === ClientJuridicalNature.juridical) {
                setErrorMessageName('A razão social deve possuir mais de 2 letra');
                setIsValidDataForm(false);
            } else if (!isValidName(paymentInfo.billingInfoHolder.name)) {
                setErrorMessageName('Nome deve possuir mais de 2 letra');
                setIsValidDataForm(false);
            } else if (!isValidEmail(paymentInfo.billingInfoHolder.email)) {
                setErrorMessageEmail('Informe um email válido');
                setIsValidDataForm(false);
            } else if (!isValidNumberTelephone(paymentInfo.billingInfoHolder.phone)) {
                setErrorMessageTelephone('Telefone Inválido');
                setIsValidDataForm(false);
            } else if (!isValidAddress([paymentInfo.billingAddress])) {
                validateAddress(paymentInfo.billingAddress, setErrorMessageCity, setErrorMessageNeighborhood, setErrorMessageNumber, setErrorMessageShortState, setErrorMessageState,
                    setErrorMessageStreet, setErrorMessageZipCode, setIsValidDataFormAddress);
                setIsValidDataForm(false);
            } else {
                validateAddress(paymentInfo.billingAddress, setErrorMessageCity, setErrorMessageNeighborhood, setErrorMessageNumber, setErrorMessageShortState, setErrorMessageState,
                    setErrorMessageStreet, setErrorMessageZipCode, setIsValidDataFormAddress);
                setIsValidDataForm(true);
            }
        }

        const isValidAddress = (address?: AddressEntity[]): boolean => {
            if (!address) {
                return true;
            }

            const eligibleAddress = address.filter((user) => user.action != BackendAction.delete);

            if (eligibleAddress.length === 0) {
                return true;
            }

            for (let i = 0; i < eligibleAddress.length; i++) {
                const _address = eligibleAddress[i];
                const isValidDataFormAddress = validateAddress(_address);
                if (isValidDataFormAddress === false) {
                    return false;
                }
            }

            return true;

        }

        return {
            errorMessageName, setErrorMessageName,
            errorMessageEmail, setErrorMessageEmail,
            errorMessageCNPJ, setErrorMessageCNPJ,
            errorMessageCPF, setErrorMessageCPF,
            errorMessageTelephone, setErrorMessageTelephone,
            isValidDataForm, setIsValidDataForm,
            errorMessageCity, setErrorMessageCity,
            errorMessageNeighborhood, setErrorMessageNeighborhood,
            errorMessageNumber, setErrorMessageNumber,
            errorMessageShortState, setErrorMessageShortState,
            errorMessageState, setErrorMessageState,
            errorMessageStreet, setErrorMessageStreet,
            errorMessageZipCode,
            isValidDataFormAddress, setIsValidDataFormAddress,
            validate,
        }
    }
;
