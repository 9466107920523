import {observer} from "mobx-react";
import React from "react";
import {
    ContentInput
} from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/styled";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";

const InputReason = observer(
    (
        {
            cancellationReason,
            setCancellationReason,
            loading,
            errorMessageDescriptionReason
        }: {
            cancellationReason?: string;
            setCancellationReason: (cancellationReason?: string) => void
            loading: boolean
            errorMessageDescriptionReason: string
        }) => {

        return (

            <ContentInput>
                <InputTextAreaWithBorder
                    required={true}
                    autoFocus={true}
                    name='cancellationReason'
                    size={65}
                    disabled={loading}
                    placeHolder={"Informe um motivo de cancelamento"}
                    messageError={errorMessageDescriptionReason}
                    label={"Motivo do cancelamento"}
                    value={cancellationReason ?? ''}
                    onChange={(event) => setCancellationReason(event.target.value)}
                />
            </ContentInput>

        )
    })
export default InputReason;
