import {SimpleUserInfo} from "@dropDesk/domain/entities/user/user.entity";
import {PaymentSubscriptionStatus} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription.enum";

export class PaymentSubscriptionLog {
    planName!: string;
    quantityOfUsers!: number;
    value!: number;
    status?: PaymentSubscriptionStatus;

    constructor({
                    planName,
                    quantityOfUsers,
                    value,
                    status
                }: {
        planName: string;
        quantityOfUsers: number;
        value: number;
        status?: PaymentSubscriptionStatus;
    }) {
        Object.assign(this, {
            planName,
            quantityOfUsers,
            value,
            status
        });
    }
}

export class PaymentSubscriptionLogsEntity {
    id?: string;
    idPaymentSubscription?: string;
    loggedBy!: SimpleUserInfo;
    oldPlan?: PaymentSubscriptionLog;
    newPlan!: PaymentSubscriptionLog;
    createdAt?: string;

    constructor({
                    id,
                    idPaymentSubscription,
                    loggedBy,
                    oldPlan,
                    newPlan,
                    createdAt,
                }: {
        id?: string;
        idPaymentSubscription?: string;
        loggedBy: SimpleUserInfo;
        oldPlan?: PaymentSubscriptionLog;
        newPlan: PaymentSubscriptionLog;
        createdAt?: string;
    }) {
        Object.assign(this, {
            id,
            idPaymentSubscription,
            loggedBy,
            oldPlan,
            newPlan,
            createdAt,
        });
    }

    public static fromJson(json: Record<string, any>): PaymentSubscriptionLogsEntity {
        return new PaymentSubscriptionLogsEntity({
            id: json['id'],
            idPaymentSubscription: json['idPaymentSubscription'],
            newPlan: json['newPlan'],
            oldPlan: json['oldPlan'],
            createdAt: json['createdAt'],
            loggedBy: json['loggedBy'],
        });
    }
}
