import {observer} from "mobx-react";
import React from "react";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";

const Footer = observer(
    (
        {
            loading,
            disabled,
            onRequestSave,
            onRequestClose
        }: {
            loading: boolean
            disabled: boolean
            onRequestSave: () => void
            onRequestClose: () => void
        }) => {

        return (
            <FooterModal
                disabled={loading || disabled}
                loading={loading}
                type={"submit"}
                letter={'Reativar Assinatura'}
                onSubmit={onRequestSave}
                onRequestClose={onRequestClose}
            />
        )
    })
export default Footer;
