import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SegmentsRepository} from "@dropDesk/domain/repositories/common/segments/segments.repository";
import {OptionType} from "@dropDesk/presentation/components/inputs/select_search_main/select_search";

@injectable()
export class SegmentsUseCase {
    private _repository: SegmentsRepository;

    constructor(@inject(SegmentsRepository) repository: SegmentsRepository) {
        this._repository = repository;
    }

    public call(): Promise<Array<OptionType<string>>> {
        return this._repository.getSegments();
    }
}

