import styled from "styled-components";
import {Modal} from 'antd'


export const ModalStyled = styled(Modal)`
  display: flex;

  .ant-modal-content {
    border-radius: 10px !important;
    background: none;
  }

  &.ant-modal-wrap {
    z-index: 2002 !important;
  }

`;
