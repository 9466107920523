import styled from "styled-components";
import generatePicker from "antd/es/date-picker/generatePicker";
import generateConfig from 'rc-picker/lib/generate/dateFns';
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const DatePicker = generatePicker<Date>(generateConfig);

type ContainerType = {
    marginTop: number;
}
type LabelInputType = {
    color: string;
}

type DatePickerType = {
    color: string;
    focuscolor: string;
    placeholdercolor: string;
    background: string;
    shadowcolor: string;
    backgroundmainbutton: string;
    hint: string;
}

export const DatePickerStyled = styled(DatePicker)<DatePickerType>`

  &:hover {
    border: none;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.backgroundmainbutton, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:focus-within {
    border: none;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.backgroundmainbutton, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  .ant-picker-input > input {
    color: ${(props) => props.color};
    font-size: 16px;

    &::placeholder {
      color: ${(props) => props.placeholdercolor};
    }
  }

  a {
    color: ${(props) => props.backgroundmainbutton};
  }

  .ant-picker-cell-in-view {
    color: ${(props) => props.color} !important;
  }

  .ant-picker-cell {
    color: ${props => props.hint};
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-suffix {
    color: ${(props) => props.color};
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
    color: ${(props) => props.hint} !important;
  }

  .ant-picker-clear {
    background: ${(props) => props.background};
    color: ${(props) => props.color};
  }

  .ant-picker-panel {
    border-color: ${(props) => props.color};
  }

  .ant-picker-header {
    border-color: ${(props) => props.color};
  }

  .ant-picker-datetime-panel {
    border-color: ${(props) => props.color};
  }

  .ant-picker-time-panel {
    border-color: ${(props) => props.color};
  }

  .ant-picker-panel .ant-picker-footer {
    border-top-color: ${(props) => props.color};
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    color: ${(props) => props.color};
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-time-panel-column:not(:first-child) {
    border-color: ${(props) => props.color};
  }

  .ant-picker-panel-container {
    background: ${(props) => props.background} !important;

    box-shadow: 0 3px 6px -4px ${(props) => hexToRGBA(props.shadowcolor, 0.1)},
    0 6px 16px 0 ${(props) => hexToRGBA(props.shadowcolor, 0.1)},
    0 9px 28px 8px ${(props) => hexToRGBA(props.shadowcolor, 0.1)} !important;

    .ant-picker-header {
      color: ${(props) => props.color} !important;
    }

    .ant-picker-header-super-prev-btn {
      color: ${(props) => props.color} !important;
    }

    .ant-picker-header-prev-btn {
      color: ${(props) => props.color} !important;
    }

    .ant-picker-header-super-next-btn {
      color: ${(props) => props.color} !important;
    }

    .ant-picker-header-next-btn {
      color: ${(props) => props.color} !important;
    }

  }

  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
    border-color: ${(props) => props.color} !important;
  }

  .ant-btn-primary {
    background-color: ${(props) => props.backgroundmainbutton} !important;
    border-color: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;

    &:disabled {
      color: ${(props) => props.background} !important;
    }
  }

  .ant-picker-content th {
    color: ${(props) => props.color} !important;
  }

  .ant-picker-cell-disabled {
    color: ${props => props.hint} !important;
  }

  .ant-picker-cell-disabled::before {
    background: ${hexToRGBA(SharedColors.disabled, 0.5)} !important;
  }

}


&.ant-picker-focused {
  border: none;
  box-shadow: 0 0 0 2px ${props => hexToRGBA(props.backgroundmainbutton, .58)};
  outline: none;
  transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
}



`;
export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => `${props.marginTop}px`};
  min-width: 100%;
  position: relative;
`;
export const Label = styled.span<LabelInputType>`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.color};
  margin: 0 0 1px 2px;
`;

