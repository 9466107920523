import {Link, useLocation, useNavigate} from "react-router-dom";
import {ButtonBack, Container} from "./styled";
import {HeaderProps} from "@dropDesk/presentation/components/headers/header_secondary/header_sec";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


export default function HeaderSecondary(props: HeaderProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
        const state = location.state;
        if (state?.from) {
            navigate(-1);
        } else {
            navigate(RoutesEnum.login);
        }
    }


    return (
        <Container background={props.background} height={props.height}>
            <ButtonBack
                color={props.color}
                onClick={() => goBack()}>
                <DropDeskIcon
                    icon={ICONS_DROPDESK.arrowLeft}
                    size={23}
                    style={{
                        marginRight: 5,
                        marginLeft: 45,
                    }}
                />
                Voltar
            </ButtonBack>
        </Container>
    );
}
