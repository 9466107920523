import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TaskRepository} from "@dropDesk/domain/repositories/task/task.repository";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {Actions} from "@dropDesk/domain/entities/task/task_enum";

@injectable()
export class MarkDoneUndoneTaskUseCase {
  private _repository: TaskRepository;

  constructor(@inject(TaskRepository) repository: TaskRepository) {
    this._repository = repository;
  }

  public call(id: string, action: Actions): Promise<Record<string, unknown>> {
    return this._repository.markAsDoneAndUndone(id, action);
  }
}

