import {observer} from "mobx-react";
import {useBetween} from "use-between";
import FormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form_client";
import {ContentFormCreate} from "@dropDesk/presentation/pages/ticket/ui/form_create/modals/styled";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {useModalContacts} from "@dropDesk/presentation/pages/client/ui/contacts/search/modals/handle_change_visible";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ModalExport from "@dropDesk/presentation/components/modals/modal_information";
import {useNavigate} from "react-router-dom";
import ModalRestore from "@dropDesk/presentation/components/modals/modal_information";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const ModalContacts = observer(
    ({
         listContacts,
         loading,
         restoreUser,
         removeLineSelectRow
     }: {
        listContacts: () => void,
        loading: boolean;
        restoreUser: () => Promise<void>,
        removeLineSelectRow: () => void,

    }) => {

        const {
            visibleModalEditClientEntity, setVisibleModalEditClientEntity,
            visibleModalExport, setVisibleModalExport,
            visibleModalRestore, setVisibleModalRestore
        } = useBetween(useModalContacts);

        const navigate = useNavigate();

        return (
            <>
                <ModalMain
                    open={visibleModalEditClientEntity.visible}
                    onRequestClose={() => setVisibleModalEditClientEntity({
                        visible: false, id: '',
                        idContact: null
                    })}
                    width={1060}
                    renderComponent={
                        <ContentFormCreate>
                            <FormClients
                                isEdit={true}
                                idClientExternal={visibleModalEditClientEntity.id}
                                idContactExternal={visibleModalEditClientEntity.idContact}
                                handleSubmitExternalScreen={async (client) => {
                                    //onEditClient(client);
                                    listContacts();
                                }}
                                backPreviousScreenExternalScreen={() => setVisibleModalEditClientEntity({
                                    visible: false,
                                    id: '',
                                    idContact: null
                                })}
                            />
                        </ContentFormCreate>}
                />

                <ModalRestore
                    open={visibleModalRestore}
                    onRequestClose={() => {
                        setVisibleModalRestore(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Restaurar Contato'}
                    textButton={'Restaurar'}
                    onRequestSave={async () => {
                        restoreUser();
                        setVisibleModalRestore(false);
                    }}
                    nameIcon={ICONS_DROPDESK.restore}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a restauração?  Após a restauração, o contato volta a ser listado e poderá ser utilizado em todo sistema.`
                    }
                    loading={loading}
                />

                <ModalExport
                    open={visibleModalExport}
                    onRequestClose={() => {
                        setVisibleModalExport(false);
                    }}
                    textHeaderModal={'Exportar contatos'}
                    textButton={'Exportar'}
                    onRequestSave={async () => {
                        navigate(`${RoutesEnum.exportcontacts}`);
                        setVisibleModalExport(false);
                    }}
                    nameIcon={ICONS_DROPDESK.excel}
                    sizeIcon={120}
                    textInformation={
                        `Deseja realmente exportar todos os dados dos contatos para uma planilha?`
                    }
                    loading={loading}
                />
            </>
        )
    })
export default ModalContacts;
