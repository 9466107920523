import ptBR from '../locales/pt/pt-br.json';
import i18n from '../index';

export function translate(key: string): string {
  const keys = JSON.parse(JSON.stringify(ptBR));
  const realPath = `translation.${key}`;

  function isValidKey(path: string, obj = self, separator = '.') {
    const properties = path.split(separator);
    // @ts-ignore
    return properties.reduce((prev, curr) => prev?.[curr], obj)
  }

  if (!isValidKey(realPath, keys, '.')) {
    const root: string = key.split(".")[0]
    key = root + ".unknown";
  }
  return i18n.t(key);
}
