import {observer} from "mobx-react";
import {
    ContentExportImport, ContentFilter,
    ContentInput,
    ContentInputAndListableTicketsDeleted,
    ContentInputSearch,
    ContentInputSearchAndFilter,
} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useNavigate} from "react-router-dom";
import {useBetween} from "use-between";
import {useModalTickets} from "@dropDesk/presentation/pages/ticket/ui/search/modals/visible_modal_search";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import Filters from "@dropDesk/presentation/pages/ticket/ui/search/search_bar/filters";

const isNewRegister = 'new';
const SearchBarTickets = observer(
    ({
         onSearch,
         filterStatus,
         filterPriority,
         setFilterStatus,
         setFilterPriority,
         visibleInputSearch,
         visibleButtonsOfActions,
         visibleButtonDeleted,
         visibleButtonRestore,
         loading,
         setListOnlySectorEmpty,
         listOnlySectorEmpty
     }: {
        onSearch: (searchParam?: string) => void,
        setFilterStatus: (filter: string | null, withList?: boolean) => void,
        setFilterPriority: (filter: string | null, withList?: boolean) => void,
        setListOnlySectorEmpty: (value: boolean, withList?: boolean) => void,
        filterStatus: string | null,
        filterPriority: string | null,
        visibleInputSearch: boolean,
        visibleButtonsOfActions: boolean,
        visibleButtonDeleted: boolean,
        visibleButtonRestore: boolean,
        loading: boolean
        listOnlySectorEmpty: boolean
    }) => {
        const navigate = useNavigate();
        const {setVisibleModalRestore, setVisibleModalDelete} = useBetween(useModalTickets);

        return (
            <ContentFilter>
                <ContentInputAndListableTicketsDeleted>
                    <ContentInput>
                        {visibleInputSearch &&
                            <ContentInputSearchAndFilter>
                                <ContentInputSearch>
                                    <InputSearchGeneric
                                        inputSearchPlaceholder="Pesquise por todos atendimentos"
                                        loading={loading}
                                        onSearch={(value) => onSearch(value)}
                                    />
                                </ContentInputSearch>

                                <Filters
                                    setFilterPriority={setFilterPriority}
                                    setFilterStatus={setFilterStatus}
                                    filterPriority={filterPriority}
                                    filterStatus={filterStatus}
                                    loading={loading}
                                    onSearch={onSearch}
                                    setListOnlySectorEmpty={setListOnlySectorEmpty}
                                    listOnlySectorEmpty={listOnlySectorEmpty}
                                />

                            </ContentInputSearchAndFilter>
                        }
                        {visibleButtonDeleted && <ButtonNew
                            marginLeft={0}
                            label={'Excluir'}
                            onClick={() => setVisibleModalDelete(true)}
                            nameLeftIcon={ICONS_DROPDESK.delete}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}
                        {visibleButtonRestore && <ButtonNew
                            marginLeft={0}
                            label={'Restaurar'}
                            onClick={() => setVisibleModalRestore(true)}
                            nameLeftIcon={ICONS_DROPDESK.restore}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}

                    </ContentInput>

                </ContentInputAndListableTicketsDeleted>
                <ContentExportImport>
                    {visibleButtonsOfActions && <ButtonNew
                        marginLeft={0}
                        label={'Novo Atendimento'}
                        onClick={() => navigate(`${RoutesEnum.tickets}/${isNewRegister}`)}
                        sizeLeftIcon={16}
                        disabled={false}
                        isButtonMain={true}
                    />}
                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBarTickets;
