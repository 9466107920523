import {useRive} from 'rive-react';
import {Container, ContentDescription, Description} from './styled';
import {useTranslation} from "react-i18next";
import {LoadingDropDeskType} from "@dropDesk/presentation/components/loadings/loading_table/loading_dropdesk";
import loadingDropDeskRiv from "@dropDesk/dropdesk.riv";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const DropDeskLoadingDefault = ((props: LoadingDropDeskType) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const {RiveComponent} = useRive({
        src: loadingDropDeskRiv,
        animations: `loading-${colors.name}`,
        autoplay: true,
    });
    const opacity = colors.isDarkTheme ? 0.6 : 0.2;
    const background = colors.isDarkTheme ? colors.background : SharedColors.hintLight;


    const {t} = useTranslation();
    return (
        <Container opacity={opacity} background={background}>
            <RiveComponent style={{height: props.height ?? 80}}/>
            <ContentDescription>
                <Description
                    color={colors.text}>{t(props.description)}</Description>
            </ContentDescription>
        </Container>
    )
});

export default DropDeskLoadingDefault;
