// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-image-preview-operations-wrapper {
    width: 258px !important;
    max-width: 258px !important;
}

.ant-image-preview-operations {
    background: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/presentation/components/messages/message_box/image_message/override_styles_image_preview.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".ant-image-preview-operations-wrapper {\n    width: 258px !important;\n    max-width: 258px !important;\n}\n\n.ant-image-preview-operations {\n    background: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
