import {observer} from "mobx-react";
import React from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {useBetween} from "use-between";
import {
    useValidatorInputClients
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_clients/validator";
import {useModalFormClients} from "@dropDesk/presentation/pages/client/ui/my_clients/form/modals/handle_change_visible";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";


const FooterFormClients = observer(
    ({
         loading,
         handleSubmit,
         backPreviousScreen,
         createdAt,
         isValidDataForm
     }: {
        loading: boolean,
        handleSubmit: () => void,
        backPreviousScreen: () => void,
        createdAt?: string,
        isValidDataForm: boolean

    }) => {


        return (
            <FooterForm
                disabled={loading || !isValidDataForm}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={handleSubmit}
                onRequestClose={backPreviousScreen}
                useCheckBox={false}
                createdAt={createdAt}
            />
        )
    })
export default FooterFormClients;
