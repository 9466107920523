import styled from "styled-components";

type TextType = {
    color: string;
};

export const ContentLoadingImportClient = styled.div`
  display: flex;
  flex: 1;
  min-height: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span<TextType>`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.color}
`;

export const ContainerDescriptions = styled.div`
  display: flex;
  padding: 36px 16px;
  flex-direction: column;
`;

export const TextDescription = styled.li<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  margin: 5px 0px;
`;

