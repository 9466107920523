import {inject} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import {
    ListResponseStandardUseCase
} from "@dropDesk/domain/use_case/response_standard/list_response_standard_pagination.usecase";
import {SetResponseStandardUseCase} from "@dropDesk/domain/use_case/response_standard/set_response_standard.usecase";
import {
    FindByPKResponseStandardUseCase
} from "@dropDesk/domain/use_case/response_standard/findbypk_response_standard.usecase";
import {
    RestoreResponseStandardUseCase
} from "@dropDesk/domain/use_case/response_standard/restore_response_standard.usecase";
import {
    DeleteResponseStandardUseCase
} from "@dropDesk/domain/use_case/response_standard/delete_response_standard.usecase";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {newResponseStandard} from "@dropDesk/presentation/pages/response_standard/controller/new_response_standard";
import {
    initializeConfigurationLocalStorage,
    preventMissingPage,
    setSearchParamLocalStorage
} from "@dropDesk/utils/helpers/common";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";

configure({
    enforceActions: "always",
});

@injectable()
export class ResponseStandardController {

    private readonly _listResponseStandardUseCase: ListResponseStandardUseCase;
    private readonly _setResponseStandardUseCase: SetResponseStandardUseCase;
    private readonly _findByPKResponseStandardUseCase: FindByPKResponseStandardUseCase;
    private readonly _restoreUseCase: RestoreResponseStandardUseCase;
    private readonly _deleteUseCase: DeleteResponseStandardUseCase;

    constructor(
        @inject(ListResponseStandardUseCase) listResponseStandardUseCase: ListResponseStandardUseCase,
        @inject(SetResponseStandardUseCase) setResponseStandardUseCase: SetResponseStandardUseCase,
        @inject(FindByPKResponseStandardUseCase) findByPKResponseStandardUseCase: FindByPKResponseStandardUseCase,
        @inject(RestoreResponseStandardUseCase) restoreUseCase: RestoreResponseStandardUseCase,
        @inject(DeleteResponseStandardUseCase) deleteUseCase: DeleteResponseStandardUseCase,
    ) {
        makeObservable(this);
        this._listResponseStandardUseCase = listResponseStandardUseCase;
        this._setResponseStandardUseCase = setResponseStandardUseCase;
        this._findByPKResponseStandardUseCase = findByPKResponseStandardUseCase;
        this._restoreUseCase = restoreUseCase;
        this._deleteUseCase = deleteUseCase;
    }

    newResponseStandard: ResponseStandardEntity = newResponseStandard();

    table = new ListPaginationEntity<ResponseStandardEntity>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 50),
        totalRows: 0,
        data: observable.array([])
    })
    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    tableResponseStandard: ListPaginationEntity<ResponseStandardEntity> = this.table;

    @observable
    pagesData: Record<number, ResponseStandardEntity[]> = {};

    @observable
    responseStandard?: ResponseStandardEntity;

    @observable
    searchParam = '';

    @observable
    responseStandardNotFound: boolean = false;

    @observable
    listOnlyDeleted = false;

    @observable
    rowSelectionResponseStandard: ResponseStandardEntity[] = [];

    @action
    setRowSelectionResponseStandard(responseStandard: ResponseStandardEntity[]) {
        this.rowSelectionResponseStandard = responseStandard;
    }

    @action
    setListOnlyDeleted(value: boolean) {
        this.listOnlyDeleted = value;
        this.removeLineSelectRow();
        this.resetTable();
        this.list(this.searchParam);
    }

    @action
    resetTable() {
        this.pagesData = {};
        this.setTableResponseStandard(this.table);
    }

    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setResponseStandard(responseStandard?: ResponseStandardEntity) {
        this.responseStandard = responseStandard;
    }


    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableResponseStandard(this.tableResponseStandard.copyWith({
                ...this.tableResponseStandard,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableResponseStandard(this.tableResponseStandard.copyWith({
                ...this.tableResponseStandard,
                page: page,
            }))
            return this.list(this.searchParam);
        }
    }

    @action
    setResponseStandardNotFound(value: boolean) {
        this.responseStandardNotFound = value;
    }

    @action
    removeLineSelectRow() {
        this.setRowSelectionResponseStandard([]);
    }

    @action
    initialize(heightShowingHeaderInfo: number) {
        const routeName = RoutesEnum.rapidresponse;
        const {lastPage, initSearchParam} = initializeConfigurationLocalStorage(routeName);
        this.tableResponseStandard.limit = Math.floor((window.innerHeight - 265 - 60 - heightShowingHeaderInfo) / 50);
        this.tableResponseStandard.page = lastPage;
        this.searchParam = initSearchParam;
        this.list(initSearchParam)
    }

    @action
    set = async (onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("responseStandard.loadingSave");
            if (this.responseStandard!.isUnsaved()) {
                this.setResponseStandard(this.responseStandard!.copyWith({
                    id: generateUUIDV4()
                }))
            }

            await this._setResponseStandardUseCase.call(this.responseStandard!);
            this.stopLoading();
            onSuccess("responseStandard.success.save");
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    delete = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("responseStandard.loadingDelete");
            await this._deleteUseCase.call(this.rowSelectionResponseStandard);
            this.removeLineSelectRow();
            onSuccess("responseStandard.success.delete");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    restore = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("responseStandard.loadingRestore");
            await this._restoreUseCase.call(this.rowSelectionResponseStandard);
            this.removeLineSelectRow();
            onSuccess("responseStandard.success.restore");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    @action
    makeResponseStandard = (): void => {
        this.setResponseStandard(this.newResponseStandard);
    }

    @action
    getResponseStandard = async (id: string) => {
        try {

            this.startLoading("responseStandard.loadingInit");
            this.setResponseStandard(undefined);
            const response = await this._findByPKResponseStandardUseCase.call(id);
            this.setResponseStandard(response);
            this.stopLoading();

        } catch (err: any) {

            this.setResponseStandardNotFound(true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();

        }
    }

    @action
    setTableResponseStandard(value: ListPaginationEntity<ResponseStandardEntity>) {
        this.tableResponseStandard = value;
        const isNecessaryReload = preventMissingPage(this.tableResponseStandard);
        if (isNecessaryReload) {
            this.list('');
        } else {
            this.pagesData[this.tableResponseStandard.page] = this.tableResponseStandard.data;
        }
    }


    @action
    list = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listResponseStandardUseCase.call(this.tableResponseStandard.page, this.searchParam, this.tableResponseStandard.limit, this.listOnlyDeleted);
            this.setTableResponseStandard(response)
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };


}
