import {Text} from "@dropDesk/presentation/components/high_light_hashtag/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import React from "react";
import {observer} from "mobx-react";

export const HighLightHashTag = observer((
    {
        text,
        fontSize,
        color

    }: {
        text: string
        fontSize: number,
        color?: string
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const highlightHashtags = (text: string): React.ReactNode => {
        const regex = /#([^#]+)#/g;
        let match;
        let lastIndex = 0;
        let index = 0;
        const result = [];

        while ((match = regex.exec(text)) !== null) {
            const word = match[0];
            const start = match.index;
            const end = regex.lastIndex;

            if (start > lastIndex) {
                const nonHighlightedWord = text.slice(lastIndex, start);
                result.push(nonHighlightedWord);
            }

            const highlightedWord = (
                <span key={index} style={{fontWeight: 'bold'}}>
                 {word.slice(1, -1)}
               </span>
            );
            result.push(highlightedWord);

            lastIndex = end;
            index++;
        }

        if (lastIndex < text.length) {
            const remainingText = text.slice(lastIndex);
            result.push(remainingText);
        }

        return result;
    }

    return <Text size={fontSize} color={color ?? colors.text}>{highlightHashtags(text)}</Text>

});
