import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import {
    ContainerGraphics,
} from "./styled";
import {
    useModalsReportTicketAggregate
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/ui/modals/handle_change_visible";
import {
    LineChartReportTicketAggregate
} from "@dropDesk/domain/entities/reports/report_ticket_aggregate/line_chart_report_ticket_agreggate";
import GraphicBar from "@dropDesk/presentation/components/graphics/graphic_horizontal_bar";


const GraphicsReportTicketAggregate = observer(
    ({
         loading,
         lineChartClientsAggregate,
         lineChartUsersAggregate,
         lineChartSectorsAggregate,
         lineChartDescriptionsStaticAggregate,
         hasData
     }: {
        lineChartClientsAggregate: LineChartReportTicketAggregate[] | undefined,
        lineChartUsersAggregate: LineChartReportTicketAggregate[] | undefined,
        lineChartSectorsAggregate: LineChartReportTicketAggregate[] | undefined,
        lineChartDescriptionsStaticAggregate: LineChartReportTicketAggregate[] | undefined,
        hasData: (chart?: LineChartReportTicketAggregate[]) => boolean
        loading: boolean
    }) => {


        const {
            setVisibleModalInformationReport,
            setTextHeaderModalInformationReport,
            setTextInformationHeader,
            setTextInformationBody,
        } = useBetween(useModalsReportTicketAggregate);


        return (
            <div>
                <ContainerGraphics>

                    <GraphicBar
                        data={lineChartClientsAggregate?.map((entry) => entry.toReportTicketEntity()) ?? []}
                        description={"Empresas com mais Atendimentos"}
                        showIconInformation={true}
                        onClickIconInformation={() => {
                            setTextHeaderModalInformationReport('Empresas com + Atendimentos')
                            setTextInformationHeader('Com o relatório de empresas com mais atendimentos você consegue acompanhar qual a empresa abriu mais atendimentos no período.')
                            setTextInformationBody('Com estes dados, você consegue analisar qual empresa precisa de mais atenção e por qual motivo esta empresa abre tantos atendimentos. Para que futuramente não haja uma insatisfação por parte da empresa e por fim um rompimento de contrato.')
                            setVisibleModalInformationReport(true)
                        }}
                        loading={loading}
                        hasData={hasData(lineChartClientsAggregate)}
                    />


                    <GraphicBar
                        data={lineChartUsersAggregate?.map((entry) => entry.toReportTicketEntity()) ?? []}
                        description={"Atendentes com mais Atendimentos"}
                        showIconInformation={true}
                        onClickIconInformation={() => {
                            setTextHeaderModalInformationReport('Atendentes com mais Atendimentos')
                            setTextInformationHeader('Com o relatório de atendentes que mais atenderam um chamado, você consegue acompanhar a produtividade de seus atendentes no período.')
                            setTextInformationBody('É sempre importante que você saiba quais são os seus atendentes mais produtivos, qual a média de atendimentos diários da sua equipe e o impacto disso para os seus negócios.')
                            setVisibleModalInformationReport(true)
                        }}
                        loading={loading}
                        hasData={hasData(lineChartUsersAggregate)}
                    />

                </ContainerGraphics>
                <ContainerGraphics>

                    <GraphicBar
                        data={lineChartSectorsAggregate?.map((entry) => entry.toReportTicketEntity()) ?? []}
                        description={"Setores com mais Atendimentos"}
                        showIconInformation={true}
                        onClickIconInformation={() => {
                            setTextHeaderModalInformationReport('Setores com mais Atendimentos')
                            setTextInformationHeader('Ao setorizar os atendimentos é possível obter uma análise mais precisa sobre os atendimentos que anda recebendo. Assim entende-se o comportamento do usuário em cada setor.')
                            setTextInformationBody('Concluindo quais são os setores que andam recebendo mais atendimentos, quais precisam de mais atenção e o que cada usuário espera de determinado setor.')
                            setVisibleModalInformationReport(true)
                        }}
                        loading={loading}
                        hasData={hasData(lineChartSectorsAggregate)}
                    />


                    <GraphicBar
                        data={lineChartDescriptionsStaticAggregate?.map((entry) => entry.toReportTicketEntity()) ?? []}
                        description={"Problemas Estáticos com mais Atendimentos"}
                        showIconInformation={true}
                        onClickIconInformation={() => {
                            setTextHeaderModalInformationReport('Problemas Estáticos + Atendimentos')
                            setTextInformationHeader('Com o relatório de problemas estáticos você consegue analisar qual o problema mais recorrente da sua organização .')
                            setTextInformationBody('Concluindo quais são os Problemas que andam recebendo mais atendimentos, quais precisam de mais atenção.')
                            setVisibleModalInformationReport(true)
                        }}
                        loading={loading}
                        hasData={hasData(lineChartDescriptionsStaticAggregate)}
                    />
                </ContainerGraphics>
            </div>
        )
    })
export default GraphicsReportTicketAggregate;
