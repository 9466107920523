import styled from "styled-components";

type ContainerType = {
    background: string;
}

export const ContainerEditMessage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 175px;
  max-height: 600px;
`;

export const Footer = styled.div<ContainerType>`
  display: flex;
  background: ${props => props.background};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;


export const ContentMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 16px 0px;
`;
