import React from "react";
import {
    SelectDateAndTimePropsType
} from "@dropDesk/presentation/components/dates/select_date_and_time/select_date_time";
import {
    differenceBetweenDatesInDays,
    getCurrentDate,
    setHourZero, setMaxHour
} from "@dropDesk/utils/helpers/date_helper";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, DatePickerStyled, Label} from "@dropDesk/presentation/components/dates/select_date_and_time/styled";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import LabelInput from "@dropDesk/presentation/components/inputs/input_with_border/label";
import MessageError from "@dropDesk/presentation/components/inputs/input_with_border/message_error";


const SelectDateAndTime = (props: SelectDateAndTimePropsType) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const disabledDate = (current: Date) => {
        return props.minDateFromNow ?
            props.minDate && props.maxDate
                ?
                ((current.getTime() >= setMaxHour(new Date(props.maxDate.getTime())).getTime())
                    ||
                    (current.getTime() <= setHourZero(getCurrentDate()).getTime())
                )
                :
                (current.getTime() <= setHourZero(getCurrentDate()).getTime())
            :
            false;
    };
    const disabledTime = (current: Date | null) => {
        if (!current || !props.maxDate) {
            return {};
        }
        const isSameDay = current.toDateString() === props.maxDate.toDateString();
        if (isSameDay) {
            return {
                disabledHours: () => range(0, 24).splice(props.maxDate!.getHours() + 1, 24),
                disabledMinutes: () => range(props.maxDate!.getMinutes(), 60),
                disabledSeconds: () => [],
            };
        } else {
            return {};
        }

    }

    const range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    return (
        <Container
            marginTop={props.marginTop ?? 0}
        >
            {!!props.label && <LabelInput required={props.required} label={props.label}/>}
            <DatePickerStyled
                showTime={{format: 'HH:mm'}}
                onChange={props.onChange}
                format={["d/M/y H:m", 'dMy H:m']}
                hint={colors.hint}
                value={props.value}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                placeholder={props.placeHolder}
                inputReadOnly={false}
                style={
                    {
                        height: props.size,
                        width: '100%',
                        borderColor: colors.border,
                        backgroundColor: colors.backgroundInput,
                        borderRadius: 5,
                        fontSize: 17
                    }}
                getPopupContainer={(trigger) => trigger}
                color={colors.text}
                placeholdercolor={colors.hint}
                focuscolor={colors.text}
                background={colors.backgroundInput}
                shadowcolor={colors.brightness === Brightness.light ? SharedColors.black : colors.border}
                backgroundmainbutton={colors.accent}
            />
            <MessageError messageError={props.messageError}/>

        </Container>
    )
}

export default SelectDateAndTime;
