import {inject} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {CreateObjectUrlBlobUseCase} from "@dropDesk/domain/use_case/io/create_object_url_blob.usecase";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import {
    ListTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/list_tickets_description_static_pagination.usecase";
import {
    SetTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/set_tickets_description_static.usecase";
import {
    FindByPKTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/findbypk_tickets_description_static.usecase";
import {
    RestoreTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/restore_tickets_description_static.usecase";
import {
    DeleteTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/delete_tickets_description_static.usecase";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {
    newTicketDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/controller/new_ticket_description_static";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {
    initializeConfigurationLocalStorage,
    preventMissingPage, setGenericFiltersLocalStorage,
    setSearchParamLocalStorage
} from "@dropDesk/utils/helpers/common";

configure({
    enforceActions: "always",
});

@injectable()
export class TicketsDescriptionStaticController {

    private readonly _listTicketsDescriptionStaticUseCase: ListTicketsDescriptionStaticUseCase;
    private readonly _setTicketsDescriptionStaticUseCase: SetTicketsDescriptionStaticUseCase;
    private readonly _findByPKTicketsDescriptionStaticUseCase: FindByPKTicketsDescriptionStaticUseCase;
    private readonly _createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase;
    private readonly _restoreUseCase: RestoreTicketsDescriptionStaticUseCase;
    private readonly _deleteUseCase: DeleteTicketsDescriptionStaticUseCase;

    constructor(
        @inject(ListTicketsDescriptionStaticUseCase) listTicketsDescriptionStaticUseCase: ListTicketsDescriptionStaticUseCase,
        @inject(SetTicketsDescriptionStaticUseCase) setTicketsDescriptionStaticUseCase: SetTicketsDescriptionStaticUseCase,
        @inject(FindByPKTicketsDescriptionStaticUseCase) findByPKTicketsDescriptionStaticUseCase: FindByPKTicketsDescriptionStaticUseCase,
        @inject(CreateObjectUrlBlobUseCase) createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase,
        @inject(RestoreTicketsDescriptionStaticUseCase) restoreUseCase: RestoreTicketsDescriptionStaticUseCase,
        @inject(DeleteTicketsDescriptionStaticUseCase) deleteUseCase: DeleteTicketsDescriptionStaticUseCase,
    ) {
        makeObservable(this);
        this._listTicketsDescriptionStaticUseCase = listTicketsDescriptionStaticUseCase;
        this._setTicketsDescriptionStaticUseCase = setTicketsDescriptionStaticUseCase;
        this._findByPKTicketsDescriptionStaticUseCase = findByPKTicketsDescriptionStaticUseCase;
        this._createObjectURLBlobUseCase = createObjectURLBlobUseCase;
        this._restoreUseCase = restoreUseCase;
        this._deleteUseCase = deleteUseCase;
    }

    newTicketsDescriptionStatic: TicketsDescriptionStaticEntity = newTicketDescriptionStatic();

    table = new ListPaginationEntity<TicketsDescriptionStaticEntity>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 50),
        totalRows: 0,
        data: observable.array([])
    });

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    tableTicketsDescriptionStatic: ListPaginationEntity<TicketsDescriptionStaticEntity> = this.table;

    @observable
    pagesData: Record<number, TicketsDescriptionStaticEntity[]> = {};

    @observable
    ticketsDescriptionStatic?: TicketsDescriptionStaticEntity;

    @observable
    searchParam = '';

    @observable
    ticketsDescriptionStaticNotFound: boolean = false;

    @observable
    listOnlyDeleted = false;

    @observable
    rowSelectionTicketsDescriptionStatic: TicketsDescriptionStaticEntity[] = [];

    @observable
    filterListableForClients: 'true' | 'false' | null = null;

    @action
    removeLineSelectRow() {
        this.setRowSelectionTicketsDescriptionStatic([]);
    }

    @action
    setRowSelectionTicketsDescriptionStatic(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]) {
        this.rowSelectionTicketsDescriptionStatic = ticketsDescriptionStatic;
    }

    @action
    setFilterListableForClients(filter: 'true' | 'false' | null) {
        this.filterListableForClients = filter;
        setGenericFiltersLocalStorage({descriptionStaticFilterListableForClients: filter});
        //this.resetTable();
        //this.list(this.searchParam);
    }

    @action
    resetTable() {
        this.pagesData = {};
        this.setTableTicketsDescriptionStatic(this.table);
    }

    @action
    setListOnlyDeleted(value: boolean) {
        this.listOnlyDeleted = value;
        this.removeLineSelectRow();
        this.resetTable();
        this.list(this.searchParam);
    }

    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setTicketsDescriptionStatic(ticketsDescriptionStatic?: TicketsDescriptionStaticEntity) {
        this.ticketsDescriptionStatic = ticketsDescriptionStatic;
    }


    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableTicketsDescriptionStatic(this.tableTicketsDescriptionStatic.copyWith({
                ...this.tableTicketsDescriptionStatic,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableTicketsDescriptionStatic(this.tableTicketsDescriptionStatic.copyWith({
                ...this.tableTicketsDescriptionStatic,
                page: page,
            }))
            return this.list(this.searchParam);
        }
    }

    @action
    setTicketsDescriptionStaticNotFound(value: boolean) {
        this.ticketsDescriptionStaticNotFound = value;
    }

    @action
    initialize(heightShowingHeaderInfo: number) {
        const routeName = RoutesEnum.descriptionstatic;
        const {lastPage, initSearchParam, filters} = initializeConfigurationLocalStorage(routeName);
        this.tableTicketsDescriptionStatic.page = lastPage;
        this.tableTicketsDescriptionStatic.limit = Math.floor((window.innerHeight - 250 - 10 - 40 - heightShowingHeaderInfo) / 50);
        this.filterListableForClients = filters.descriptionStaticFilterListableForClients!;
        this.searchParam = initSearchParam;
        this.list(initSearchParam);
    }

    @action
    set = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("problemStatic.loadingSave");
            if (this.ticketsDescriptionStatic!.isUnsaved()) {
                this.setTicketsDescriptionStatic(this.ticketsDescriptionStatic!.copyWith({
                    id: generateUUIDV4()
                }))
            }

            await this._setTicketsDescriptionStaticUseCase.call(this.ticketsDescriptionStatic!);
            this.stopLoading();
            onSuccess("problemStatic.success.save");

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    delete = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("problemStatic.loadingDelete");
            await this._deleteUseCase.call(this.rowSelectionTicketsDescriptionStatic);
            this.removeLineSelectRow();
            onSuccess("problemStatic.success.delete");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    restore = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("problemStatic.loadingRestore");
            await this._restoreUseCase.call(this.rowSelectionTicketsDescriptionStatic);
            this.removeLineSelectRow();
            onSuccess("problemStatic.success.restore");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    @action
    makeTicketDescriptionStatic = (): void => {
        this.setTicketsDescriptionStatic(this.newTicketsDescriptionStatic);
    }

    @action
    getTicketsDescriptionStatic = async (id: string) => {

        try {
            this.startLoading("problemStatic.loadingInit");
            this.setTicketsDescriptionStatic(undefined);
            const response = await this._findByPKTicketsDescriptionStaticUseCase.call(id);
            this.setTicketsDescriptionStatic(response);
            this.stopLoading();

        } catch (err: any) {
            this.setTicketsDescriptionStaticNotFound(true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }

    }

    @action
    setTableTicketsDescriptionStatic(value: ListPaginationEntity<TicketsDescriptionStaticEntity>) {
        this.tableTicketsDescriptionStatic = value;
        const isNecessaryReload = preventMissingPage(this.tableTicketsDescriptionStatic);
        if (isNecessaryReload) {
            this.list('');
        } else {
            this.pagesData[this.tableTicketsDescriptionStatic.page] = this.tableTicketsDescriptionStatic.data;
        }
    }

    @action
    list = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listTicketsDescriptionStaticUseCase.call(
                this.tableTicketsDescriptionStatic.page,
                this.searchParam,
                this.tableTicketsDescriptionStatic.limit,
                this.listOnlyDeleted,
                (this.filterListableForClients !== null && this.filterListableForClients !== undefined)
                    ?
                    this.filterListableForClients === 'true'
                    :
                    undefined
            );
            this.setTableTicketsDescriptionStatic(response);
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

}
