import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {
    MessageChatType,
    MessageStatus,
    SystemMessageEntity
} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketNotificationEntity} from "@dropDesk/domain/entities/ticket/ticket_notifications_entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export const newMessage = (): TicketMessageEntity => {
    return new TicketMessageEntity({
        id: generateUUIDV4(),
        idCompany: getIdCompanyByLocalStorage(),
        idTicket: '',
        ticket: undefined,
        idExternal: undefined,
        type: MessageChatType.textMessage,
        senderId: '',
        deleted: false,
        isPrivate: false,
        fromMe: true,
        status: MessageStatus.PENDING,
        createdAtLocal: new Date().toISOString()
    });
}


export const newSystemMessage = (
    ticket: TicketEntity,
    notification: TicketNotificationEntity,
    user: UserEntity
): TicketMessageEntity => {
    const message =
        user.isAttendant
            ?
            'Este é um aviso do sistema DropDesk, este atendimento será fechado devido à inatividade nos próximos minutos, caso deseja interromper o fechamento, insira uma data de vencimento ou envie alguma mensagem.'
            :
            `Este é um aviso do sistema DropDesk, este atendimento será fechado devido à inatividade nos próximos minutos. Se desejar continuar a conversa, por favor, responda a esta mensagem ou envie qualquer outra mensagem para evitar o fechamento automático.`
    return new TicketMessageEntity({
        id: generateUUIDV4(),
        idCompany: getIdCompanyByLocalStorage(),
        idTicket: ticket.id,
        ticket: undefined,
        idExternal: undefined,
        type: MessageChatType.systemMessage,
        systemMessage: new SystemMessageEntity({
            conversation: message
        }),
        senderId: '',
        deleted: false,
        isPrivate: false,
        fromMe: true,
        status: MessageStatus.PENDING,
        users: ticket.lastMessage?.users ?? {},
        fromSystem: true,
        createdAt: notification.notifiedBeforeClose ?? ticket.lastMessage?.createdAt ?? undefined,
        createdAtLocal: new Date().toISOString()
    });
}
