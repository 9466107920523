import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    BodyInput,
    ContainerInput,
    ContentInput,
    ContentText,
    Input,
    TextIdentityInput,
    TextField,
    ContentSelectSearch,
    ContentInputAndMessageError, TextMessageError, ContentMessageError
} from "./styled";
import React from "react";
import {Tooltip} from "antd";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const Inputs = (
    {
        newSubscription,
        handleAllChanges,
        hasDowngrade,
    }: {

        newSubscription: PaymentSubscriptionEntity
        handleAllChanges: (event: {
            target: { name: string; value: any; checked: any; type: string }
        }) => void
        hasDowngrade: boolean
    }
) => {


    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <BodyInput>
            <ContainerInput>
                <Tooltip
                    title={`O número de atendentes é o numero de total de pessoas ativas que pode ter na sua conta.`}>
                    <ContentText>
                        <TextField color={colors.hintInverse} size={14}>Qual número de atendentes?</TextField>
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.info}
                            style={{marginLeft: 3, pointerEvents: 'none'}}
                            color={colors.hintInverse}
                        />
                    </ContentText>
                </Tooltip>

                <ContentInputAndMessageError>
                    <ContentInput background={colors.backgroundInput} bordercolor={colors.border}
                                  accent={colors.accent}>
                        <Input
                            color={colors.text}
                            name={'planUserMultiplier'}
                            onChange={handleAllChanges}
                            value={!!newSubscription.planUserMultiplier ? newSubscription.planUserMultiplier : ''}
                            maxLength={3}
                        />
                        <TextIdentityInput size={15} color={colors.text}>atendentes</TextIdentityInput>
                    </ContentInput>
                    {(!newSubscription.planUserMultiplier || isNaN(newSubscription.planUserMultiplier) || newSubscription.planUserMultiplier <= 0) &&
                        <ContentMessageError>
                            <TextMessageError size={13} color={colors.textError}>
                                Informe a quantidade de atendentes
                            </TextMessageError>
                        </ContentMessageError>}
                </ContentInputAndMessageError>

            </ContainerInput>
            <ContainerInput style={{marginLeft: 50}}>
                <Tooltip title={`O melhor dia para pagamento é o dia que irá vencer a sua assinatura.`}>
                    <ContentText>
                        <TextField color={colors.hintInverse} size={14}>Melhor dia para pagamento</TextField>
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.info}
                            style={{marginLeft: 3, pointerEvents: 'none'}}
                            color={colors.hintInverse}
                        />
                    </ContentText>
                </Tooltip>
                <Tooltip
                    title={hasDowngrade ? 'No processo de downgrade de plano, não é possível realizar alterações na data de vencimento' : ''}>
                    <ContentSelectSearch background={colors.onBackground} bordercolor={colors.border}>
                        <SelectSearchMain<number>
                            required={false}
                            size={35}
                            name={'dayDue'}
                            options={PaymentSubscriptionEntity.daysValidForDue(process.env.MODE).map((entry) => {
                                return {
                                    label: `dia ${entry}`,
                                    value: entry,
                                }
                            })}
                            disabled={hasDowngrade}
                            removeSort={true}
                            onChange={(dayDue) => {
                                handleAllChanges({
                                    target:
                                        {
                                            name: 'dayDue',
                                            value: dayDue,
                                            checked: false,
                                            type: 'input'
                                        }
                                });
                            }}
                            value={newSubscription.dayDue}
                        />
                    </ContentSelectSearch>
                </Tooltip>
            </ContainerInput>
        </BodyInput>
    );
}
export default Inputs;
