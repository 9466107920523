import {useEffect, useState} from "react";

export const useValidatorInputCancellationReason = (reasonSelected: string, cancellationReason?: string) => {

    const [errorMessageDescriptionReason, setErrorMessageDescriptionReason] = useState('');
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);

    useEffect(() => {
        validate();
    }, [cancellationReason, reasonSelected]);

    const validate = (): boolean => {
        setErrorMessageDescriptionReason('');

        if (!reasonSelected) {
            setIsValidDataForm(false);
            return false;
        }
        if (!cancellationReason || cancellationReason.toLowerCase() === 'outro' || cancellationReason.length < 10) {
            setErrorMessageDescriptionReason('Informe um motivo de cancelamento maior que 10 letras');
            setIsValidDataForm(false);
            return false;
        } else {
            setIsValidDataForm(true);
            return true;
        }
    }

    return {
        errorMessageDescriptionReason,
        isValidDataForm
    }
};
