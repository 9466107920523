import {Button} from "./styled";
import {ButtonMainProps} from "@dropDesk/presentation/components/buttons/button_main/button_main";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


export default function ButtonMain(props: ButtonMainProps) {
    const height = props.height ?? 40;
    const borderRadius = props.borderRadius ?? ConstantsStyles.radiusButton;
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const color = props.letterColor ?? SharedColors.white;
    return (
        <Button
            height={height}
            borderRadius={borderRadius}
            widthPercentage={props.widthPercentage}
            letterFontSize={props.letterFontSize}
            disabled={props.disabled}
            color={props.color}
            type={props.type}
            letterColor={color}
            borderColor={props.borderColor}
            accent={colors.accent}
            style={props.style}
            onClick={props.disabled ? () => {
            } : props.onClick}>
            {!props.loading && props.letter}
            {props.loading &&
                <SpinLoading
                    color={color}
                    size={"small"}
                />}
        </Button>

    );
}
