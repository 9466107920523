import {
    TokenizeCardEntity,
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {
    ContentButton,
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/styled";
import React from "react";
import {
    useValidatorInputCreditCard
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/inputs_card/validator";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ButtonPay = (
    {
        newTokenizeCard,
        loading,
        pay,
        isEditExternal
    }: {
        newTokenizeCard: TokenizeCardEntity
        loading: boolean
        pay: () => void
        isEditExternal?: boolean
    }
) => {

    const {
        isValidDataForm
    } = useValidatorInputCreditCard(newTokenizeCard);

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentButton>
            <ButtonMain
                style={{paddingLeft: 5, paddingRight: 5}}
                widthPercentage={100}
                letterFontSize={16}
                disabled={loading || !isValidDataForm}
                loading={loading}
                color={colors.accent}
                type={"submit"}
                letter={isEditExternal ? 'Trocar' : 'Pagar'}
                onClick={() => pay()}
            />
        </ContentButton>
    );
}
export default ButtonPay;
