import {Container, Content} from "@dropDesk/presentation/components/messages/message_box/video_message/styled";
import React from "react";
import LinkFy from "@dropDesk/presentation/components/linkify";
import {observer} from "mobx-react";

const VideoMessage = observer((
    {
        videoUrl,
        caption,
        color
    }: {
        videoUrl: string | undefined | null
        caption?: string | null;
        color: string;
    }
) => {
    return (
        <Container>
            <Content>
                <video width={300} height={300} controls>
                    <source src={videoUrl ?? undefined} type='video/mp4'/>
                    Seu navegador não tem suporte para video em HTML.
                </video>
            </Content>
            {caption && <LinkFy text={caption} color={color}/>}
        </Container>
    )
});
export default VideoMessage;
