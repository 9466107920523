export enum InfoOrigin {
    chat = 'chat',
    portal = 'portal',
    system = 'system',
    excelImport = 'excelImport'
}

export enum InfoOriginToTranslate {
    chat = 'chat',
    portal = 'portal do cliente',
    system = 'sistema',
    excelImport = 'importação do excel'
}

export enum InfoOriginDetail {
    whatsapp = 'whatsapp',
    chat = 'chat',
    system = 'system',
    portal = 'portal',
}

export enum InfoOriginDetailToTranslate {
    whatsapp = 'whatsapp',
    chat = 'chat',
    system = 'sistema',
    portal = 'portal do cliente',
}
