import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {observable} from "mobx";

export class ListPaginationEntity<T> {
    data!: T[];
    page!: number;
    pages!: number;
    limit!: number;
    totalRows!: number;
    hasMore?: boolean;
    hasMoreBottom?: boolean;
    pagesLoad?: number[];
    date?: string;

    static defaultValue<T>() {
        return new ListPaginationEntity<T>({
            pages: 0,
            page: 0,
            limit: ConstantsKeys.defaultLimit,
            totalRows: 0,
            data: observable.array([]),
            hasMore: false,
            hasMoreBottom: false,
            pagesLoad: undefined,
            date: undefined
        });
    }

    constructor({
                    data,
                    page,
                    pages,
                    totalRows,
                    limit,
                    hasMore,
                    hasMoreBottom,
                    pagesLoad,
                    date,
                }: {
        data: T[];
        page: number;
        pages: number;
        limit: number;
        totalRows: number;
        hasMore?: boolean;
        hasMoreBottom?: boolean;
        pagesLoad?: number[];
        date?: string;
    }) {
        Object.assign(this, {data, page, pages, pagesLoad, limit, totalRows, hasMore, hasMoreBottom, date});
    }

    copyWith({
                 data,
                 page,
                 pages,
                 totalRows,
                 limit,
                 hasMore,
                 hasMoreBottom,
                 date,
                 pagesLoad
             }: {
        data?: T[];
        page?: number;
        pages?: number;
        limit?: number;
        totalRows?: number;
        hasMore?: boolean;
        hasMoreBottom?: boolean;
        pagesLoad?: number[];
        date?: string;
    }): ListPaginationEntity<T> {
        return new ListPaginationEntity({
            data: data ?? this.data,
            page: page ?? this.page,
            pages: pages ?? this.pages,
            limit: limit ?? this.limit,
            totalRows: totalRows ?? this.totalRows,
            hasMore: hasMore ?? this.hasMore,
            hasMoreBottom: hasMoreBottom ?? this.hasMoreBottom,
            date: date ?? this.date,
            pagesLoad: pagesLoad ?? this.pagesLoad,
        })
    }
}
