import {
    Button,
    ContentButton,
    ContentIcon
} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import {Tooltip} from "antd";
import {Container} from "@dropDesk/presentation/components/audio_recorder/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    AudioRecordEntity,
    AudioRecordStatus
} from "@dropDesk/domain/entities/common/audio_record.entity";
import Recording from "@dropDesk/presentation/components/audio_recorder/recording";
import Stopped from "@dropDesk/presentation/components/audio_recorder/stopped";
import {observer} from "mobx-react";
import {IoController} from "@dropDesk/presentation/pages/io/io.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ContainerButtonInput} from "../chat_view/chat_message/styled";

const AudioRecorder = observer((
    {
        onStart,
        onCancel,
        messageId,
        sendAudio,
        ioController
    }: {
        onStart: () => void
        onCancel: () => void
        messageId: string
        sendAudio: (audioRecorder: AudioRecordEntity) => void
        ioController: IoController
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const handleCancelRecordingVoice = () => {
        ioController.cancelAudio().then();
    }

    const handleSaveRecordVoice = () => {
        ioController.saveAndSendAudio(() => sendAudio(ioController.audioRecorder));
    }

    return (
        <Container>

            {(ioController.audioRecorder.status === AudioRecordStatus.record) &&
                <Tooltip title={`Enviar áudio`}>
                    <ContainerButtonInput
                        accent={colors.accent}
                        background={colors.buttonOnBackground}
                    >
                        <ContentButton>
                            <Button onClick={async () => {
                                ioController.observeStartRecordVoice().then();
                                onStart();
                            }}>
                                <ContentIcon>
                                    <DropDeskIcon icon={ICONS_DROPDESK.microphone} size={21}/>
                                </ContentIcon>
                            </Button>
                        </ContentButton>
                    </ContainerButtonInput>
                </Tooltip>}

            {ioController.audioRecorder.status === AudioRecordStatus.recording &&
                <Recording
                    saveRecording={async () => {
                        handleSaveRecordVoice();
                    }}
                    cancelRecording={async () => {
                        handleCancelRecordingVoice();
                        onCancel();
                    }}
                    stopRecording={() => ioController.stopRecordVoice(true)}
                />
            }

            {ioController.audioRecorder.status === AudioRecordStatus.stop && !!ioController.audioRecorder.audioBlob &&
                <Stopped
                    cancelRecording={async () => {
                        handleCancelRecordingVoice();
                        onCancel();
                    }}
                    saveRecording={async () => {
                        handleSaveRecordVoice();
                    }}
                    audioRecorder={ioController.audioRecorder}
                    messageId={messageId}
                />
            }
        </Container>
    )
});

export default AudioRecorder;
