import {SectorRemoteDataSource} from "@dropDesk/data/data_source/sector/sector_remote.datasource";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {SectorRepository} from "@dropDesk/domain/repositories/sector/sector.repository";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export class SectorRepositoryImpl implements SectorRepository {
    private _dataSource: SectorRemoteDataSource;

    constructor(@inject(SectorRemoteDataSource) dataSource: SectorRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        idUser?: string,
        listableForClients?: boolean
    ): Promise<ListPaginationEntity<SectorEntity>> {
        try {
            return this._dataSource.list(page, searchParam, limit, listOnlyDeleted, idUser, listableForClients);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    public set(sector: SectorEntity): Promise<SectorEntity> {
        try {
            return this._dataSource.set(sector);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public findByPK(id: string): Promise<SectorEntity> {
        try {
            return this._dataSource.findByPK(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public restore(sectors: SectorEntity[]): Promise<void> {
        try {
            return this._dataSource.restore(sectors);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public delete(sectors: SectorEntity[]): Promise<void> {
        try {
            return this._dataSource.delete(sectors);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public export(): Promise<ExportDataEntity> {
        try {
            return this._dataSource.export();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public exportSubscription(): Observable<ExportDataEntity> {
        try {
            return this._dataSource.exportSubscription();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
