import {
    ContainerFooter, TextList
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const Footer = () => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerFooter>
            <TextList color={colors.text}>Ao registrar seu número, você concorda com os </TextList>
            <a style={{marginLeft: 5}} href="https://www.whatsapp.com/legal/terms-of-service?lang=pt_br"
               target="_blank"><p onClick={() => {
            }} style={{color: colors.textLink, fontSize: 15, textDecoration: 'underline', cursor: 'pointer'}}>Termos
                de
                banimento do WhatsApp</p></a>
        </ContainerFooter>
    )
}
export default Footer;
