import {observer} from "mobx-react";
import React, {useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import {
    Container,
    Content,
} from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/styled";
import Descriptions from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/descriptions";
import RadioButtons from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/radio_buttons";
import Footer from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/footer";
import {
    useValidatorInputCancellationReason
} from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/validator";
import InputReason from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/input_reason";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const CancelSubscription = observer(
    (
        {
            loading,
            onRequestClose,
            cancellationReason,
            setCancellationReason,
            onRequestSave
        }: {
            loading: boolean
            onRequestClose: () => void
            onRequestSave: () => void
            cancellationReason?: string;
            setCancellationReason: (cancellationReason?: string) => void
        }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const [reasonSelected, setReasonSelected] = useState('');
        const {
            isValidDataForm,
            errorMessageDescriptionReason
        } = useValidatorInputCancellationReason(reasonSelected, cancellationReason);

        return (
            <Container background={colors.onBackground}>
                <HeaderModal
                    disabled={loading}
                    letter={'Que pena que você está indo! 😢'}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={onRequestClose}
                    //background={colors.onBackground}
                    letterColor={colors.text}
                />
                <Content>
                    <Descriptions/>
                    <RadioButtons
                        setCancellationReason={setCancellationReason}
                        setReasonSelected={setReasonSelected}
                        reasonSelected={reasonSelected}
                    />

                    {reasonSelected.toLowerCase() === 'outro' && <InputReason
                        loading={loading}
                        errorMessageDescriptionReason={errorMessageDescriptionReason}
                        setCancellationReason={setCancellationReason}
                        cancellationReason={cancellationReason}
                    />}

                </Content>

                <Footer
                    onRequestClose={onRequestClose}
                    loading={loading}
                    onRequestSave={onRequestSave}
                    disabled={!isValidDataForm}
                />
            </Container>
        )
    })
export default CancelSubscription;
