import {observer} from "mobx-react";
import React, {useState} from "react";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import {ContainerModalPortalClient, ContentInfoPortal, ContentLinkPortal} from "./styled";
import {ContentIcon} from "@dropDesk/presentation/components/modals/modal_information/styled";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {copyToClipboard} from "@dropDesk/utils/helpers/common";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const ModalPortalClient = observer(
    ({
         setVisibleModalPortalClient,
         visibleModalPortalClient,
     }: {
        visibleModalPortalClient: boolean
        setVisibleModalPortalClient: () => void
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const companyUniqueCode = appController.user?.company.uniqueCode ?? 0;

        return (
            <ModalMain
                open={visibleModalPortalClient}
                onRequestClose={() => setVisibleModalPortalClient()}
                width={400}
                renderComponent={
                    <ContainerModalPortalClient background={colors.onBackground}>
                        <HeaderModal
                            disabled={false}
                            letter={'Portal do Cliente'}
                            nameIcon={ICONS_DROPDESK.close}
                            onClick={() => setVisibleModalPortalClient()}
                        />
                        <ContentIcon>
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.users}
                                size={130}
                                color={colors.hintInverse}
                            />
                        </ContentIcon>
                        <ContentInfoPortal>
          <span style={{fontSize: 15, color: colors.text}}>
          Portal do cliente é uma versão Cliente do DropDesk, através
          desta URL de acesso, seus clientes poderão abrir chamados para sua empresa, através de nossa interface.
          </span>
                            <ContentLinkPortal background={colors.delicateBackground}>
          <span style={{
              color: colors.text,
              fontSize: 15,
          }}>{process.env.BASE_DOMAIN}?ref={companyUniqueCode}</span>

                                <DropDeskIcon
                                    onClick={() => {
                                        copyToClipboard(`${process.env.BASE_DOMAIN}?ref=` + companyUniqueCode);
                                        toastMessage.success('Copiado para área de transferência');
                                    }}
                                    icon={ICONS_DROPDESK.copy}
                                    size={14}
                                    style={{cursor: 'pointer'}}
                                />

                            </ContentLinkPortal>
                            <span
                                style={{
                                    fontSize: 14,
                                    color: colors.text,
                                    fontWeight: 'bold',
                                    marginTop: 10
                                }}> Código de
          Referência: {companyUniqueCode} </span>
                        </ContentInfoPortal>
                    </ContainerModalPortalClient>
                }
            />
        )
    });
export default ModalPortalClient;
