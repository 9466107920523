import styled from "styled-components";
import {hexToRGBA, shadeOrLighten} from "@dropDesk/utils/helpers/colors";
import {Popover} from "antd";

type ContainerType = {
    background: string;
    isDarkenBackground?: boolean;
}

type TextType = {
    color: string;
}


type IconType = {
    color: string;
    fontSize: number;
}

type LineClampTextType = {
    color: string;
    height: number;
    width: number;
}

type ContainerAvatarType = {
    width: number;
}

type BorderType = {
    bordercolor: string;
}
type PopoverType = {
    bordercolor: string;
    shadow: string;
}

type ButtonType = {
    background: string;
    color: string;
    accent: string;
};


export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: 72px;
  min-height: 72px;
  max-height: 72px;
  flex-direction: row;
  cursor: pointer;
  background: ${props => props.isDarkenBackground ?
          shadeOrLighten(props.background, -40) : props.background};

  &:hover {
    background: ${props => shadeOrLighten(props.background, -40)};
  }
`;

export const ContainerAvatar = styled.div<ContainerAvatarType>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
`;
export const ContainerData = styled.div<BorderType>`
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  min-width: 0;
  padding: 0px 10px 0px 0px;
  border-bottom: 1px solid ${props => props.bordercolor};
`;

export const ContentLineClamp = styled.div<LineClampTextType>`
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: ${props => props.color};
  max-height: ${(props) => `${props.height}px`};
  height: ${(props) => `${props.height}px`};
  max-width: ${(props) => `${props.width}px`};
  white-space: nowrap;
`;
export const ContentName = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const Icon = styled.i<IconType>`
  font-size: ${props => `${props.fontSize}px`};
  color: ${props => props.color};
`;

export const ContentMenu = styled.div`
  display: flex;
  width: 12px;
  align-items: center;
  justify-content: center;
`;

export const TitlePopover = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  font-weight: bold;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
`;

export const ContentButtonMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;


export const ButtonMenu = styled.button<ButtonType>`
  display: flex;
  background: ${(props) => props.background};
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 4px 0;
  padding: 3px 10px;
  border: 0;
  font-size: 14px;
  color: ${(props) => props.color};

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const PopOver = styled(Popover)<PopoverType>`
  .ant-popover-title {
    border-bottom: 1px solid ${(props) => props.bordercolor};
  }
`;
