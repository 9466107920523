import {observer} from "mobx-react";
import React, {useContext} from "react";
import ModalRestore from "@dropDesk/presentation/components/modals/modal_information";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {useModalClients} from "@dropDesk/presentation/pages/client/ui/my_clients/search/modals/visible_modal_search";
import ModalPortalClient from "@dropDesk/presentation/components/modals/modal_portal_client";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ModalExport from "@dropDesk/presentation/components/modals/modal_information";
import {useNavigate} from "react-router-dom";
import {
    ContainerRestoreContacts,
    ContainerTitlePopover, ContentRestoreContacts
} from "@dropDesk/presentation/pages/client/ui/my_clients/search/modals/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    FooterRestoreContact
} from "@dropDesk/presentation/pages/client/ui/my_clients/search/modals/footer_restore_contact";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsClient = observer(
    ({
         restoreClient,
         deleteClient,
         removeLineSelectRow,
         loading,
     }: {
        restoreClient: (restoreClientWithUser: boolean) => Promise<void>,
        deleteClient: () => Promise<void>,
        removeLineSelectRow: () => void,
        loading: boolean,
    }) => {

        const {
            visibleModalRestore,
            setVisibleModalRestore,
            visibleModalDelete,
            setVisibleModalDelete,
            visibleModalPortalClient,
            setVisibleModalPortalClient,
            visibleModalExport, setVisibleModalExport

        } = useBetween(useModalClients);

        const navigate = useNavigate();
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <>
                <ModalRestore
                    open={visibleModalRestore}
                    onRequestClose={() => {
                        setVisibleModalRestore(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Restaurar Empresa'}
                    textButton={'Restaurar'}
                    onRequestSave={async () => {
                    }}
                    nameIcon={ICONS_DROPDESK.restore}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a restauração desta empresa?  Após a restauração, a empresa/contato vai poder ser utilizado em todo o sistema.`
                    }
                    loading={loading}
                    contentPopover={
                        <ContainerRestoreContacts>
                            <ContentRestoreContacts>
                                <ContainerTitlePopover color={colors.text}>
                                    Deseja restaurar também os contatos desta
                                    empresa ?
                                </ContainerTitlePopover>
                            </ContentRestoreContacts>
                            <FooterRestoreContact
                                loading={loading}
                                disabled={loading}
                                onSubmit={(restoreWithUser) => {
                                    restoreClient(restoreWithUser);
                                    setVisibleModalRestore(false);
                                }}
                            />
                        </ContainerRestoreContacts>
                    }
                />
                <ModalDelete
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        setVisibleModalDelete(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Excluir Empresa'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        deleteClient();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={110}
                    textInformation={
                        `Ao excluir esta empresa, todos os usuários dessa empresa perderam acesso, porém a exclusão não apaga seus atendimentos, avaliações e nenhuma outra ação já feita por ele.`
                    }
                    loading={loading}
                    isActionDelete={true}
                />

                <ModalPortalClient
                    visibleModalPortalClient={visibleModalPortalClient}
                    setVisibleModalPortalClient={() => setVisibleModalPortalClient(!visibleModalPortalClient)}
                />

                <ModalExport
                    open={visibleModalExport}
                    onRequestClose={() => {
                        setVisibleModalExport(false);
                    }}
                    textHeaderModal={'Exportar empresas'}
                    textButton={'Exportar'}
                    onRequestSave={async () => {
                        navigate(`${RoutesEnum.exportclients}`);
                        setVisibleModalExport(false);
                    }}
                    nameIcon={ICONS_DROPDESK.excel}
                    sizeIcon={120}
                    textInformation={
                        `Deseja realmente exportar todos os dados de empresas para uma planilha?`
                    }
                    loading={loading}
                />

            </>
        )
    })
export default ModalsClient;
