import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {TabsStyled} from "@dropDesk/presentation/components/tabs/border_style/styled";

export type Items = {
    key: string;
    label: React.ReactNode;
    children: React.ReactNode;
}
const TabBorderLine = (
    {
        defaultActiveKey,
        items,
        height,
        destroyInactiveTabPane,
    }: {
        defaultActiveKey: string
        items: Array<Items>
        height?: number
        destroyInactiveTabPane?: boolean;
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <TabsStyled
            defaultActiveKey={defaultActiveKey}
            tabBarStyle={{height: height ?? 40}}
            style={{}}
            size={"small"}
            color={colors.text}
            hint={colors.hint}
            focusedcolor={colors.accent}
            background={colors.text}
            backgrounddropdown={colors.text}
            colordropdown={colors.text}
            tabPosition={"top"}
            centered={false}
            getPopupContainer={(trigger) => trigger}
            items={items}
            destroyInactiveTabPane={destroyInactiveTabPane ?? false}
        />
    )
}

export default TabBorderLine;
