import {useEffect, useState} from "react";
import {isInvalidRangeDate, isValidDescription} from "@dropDesk/utils/helpers/validators";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";
import {isValidDate, isValidDateFromNowTime} from "@dropDesk/utils/helpers/date_helper";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";

export const useValidatorInputNotices = (notice?: NoticesEntity) => {

    const [errorMessageUser, setErrorMessageUser] = useState('');
    const [errorMessageClient, setErrorMessageClient] = useState('');
    const [errorMessageDateStarted, setErrorMessageDateStarted] = useState('');
    const [errorMessageDateEnd, setErrorMessageDateEnd] = useState('');
    const [errorMessageDescription, setErrorMessageDescription] = useState('');
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);

    useEffect(() => {
        validate();
    }, [notice]);

    const validate = (): boolean => {
        setErrorMessageUser('');
        setErrorMessageClient('');
        setErrorMessageDescription('');
        setErrorMessageDateStarted('');
        setErrorMessageDateEnd('');
        if (!notice) {
            return false;
        }
        if (notice.destiny === NoticeDestiny.client && !notice.idClient) {
            setErrorMessageClient('Selecione uma empresa');
            setIsValidDataForm(false);
            return false;
        } else if (notice.destiny === NoticeDestiny.user && !notice.idUser) {
            setErrorMessageUser('Selecione um atendente');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidDescription(notice.description)) {
            setErrorMessageDescription('A descrição do aviso deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (notice.hasTemporary && !isValidDate(notice.dateStarted ?? '')) {
            setErrorMessageDateStarted('Data e hora inicial inválida');
            setIsValidDataForm(false);
            return false;
        } else if (notice.hasTemporary && !isValidDateFromNowTime(notice.dateStarted ?? '', true) && notice.isUnsaved()) {
            setErrorMessageDateStarted('Data e hora inicial menor do que data de hoje');
            setIsValidDataForm(false);
            return false;
        } else if (notice.hasTemporary && !isValidDate(notice.dateEnd ?? '')) {
            setErrorMessageDateEnd('Data e hora final inválida');
            setIsValidDataForm(false);
            return false;
        } else if (notice.hasTemporary && !isValidDateFromNowTime(notice.dateEnd ?? '', true)) {
            setErrorMessageDateEnd('Data e hora final menor do que data de hoje');
            setIsValidDataForm(false);
            return false;
        } else if (notice.hasTemporary && isInvalidRangeDate(notice.dateStarted ?? '', notice.dateEnd ?? '')) {
            setErrorMessageDateEnd('Data inicial maior do que a data final');
            setIsValidDataForm(false);
            return false;
        } else {
            setIsValidDataForm(true);
            return true;
        }
    }

    return {

        errorMessageUser, setErrorMessageUser,
        errorMessageClient, setErrorMessageClient,
        errorMessageDateStarted, setErrorMessageDateStarted,
        errorMessageDateEnd, setErrorMessageDateEnd,
        errorMessageDescription, setErrorMessageDescription,
        validate,
        isValidDataForm
    }
};
