import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 1024px;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 500px;
`;
export const ContentResumeValueText = styled.div`
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
