import React, {useState} from "react";
import useInterval from "@dropDesk/presentation/components/audio_recorder/use_interval";
import {formatSecondsToHour} from "@dropDesk/utils/helpers/date_helper";

export default function RecordingCount() {
  const [count, setCount] = useState(0);
  const [delay] = useState(1000);

  useInterval(() => {
    setCount(count + 1);
  }, delay);

  return <>{formatSecondsToHour(count)}</>
}
