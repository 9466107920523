import {ColorsType} from "@dropDesk/domain/entities/theme/colors_enum";
import {
    BlueAppColors, BrownAppColors,
    GreenAppColors, GreyAppColors,
    OrangeAppColors, PinkAppColors,
    PurpleAppColors,
    RedAppColors,
    SharedColors
} from "@dropDesk/domain/entities/theme/app_colors";
import {Brightness, CustomColors} from "@dropDesk/domain/entities/theme/colors.entity";

export class AppCustomColors {
    private static defaultColor = new CustomColors({
        backgroundDark: SharedColors.backgroundDark,
        backgroundInputDark: SharedColors.backgroundInputDark,
        backgroundInputLight: SharedColors.backgroundInputLight,
        backgroundInputChatDark: SharedColors.backgroundInputChatDark,
        backgroundInputChatLight: SharedColors.backgroundInputChatLight,
        backgroundLight: SharedColors.backgroundLight,
        borderDark: SharedColors.borderDark,
        borderLight: SharedColors.borderLight,
        buttonOnBackgroundDark: SharedColors.buttonOnBackgroundDark,
        buttonOnBackgroundLight: SharedColors.buttonOnBackgroundLight,
        disabled: SharedColors.disabled,
        hintDark: SharedColors.hintDark,
        hintTertiaryLight: GreyAppColors.hintTertiary,
        hintTertiaryDark: GreyAppColors.hintTertiaryDark,
        hintInverseDark: SharedColors.hintInverseDark,
        hintInverseLight: SharedColors.hintInverseLight,
        hintLight: SharedColors.hintLight,
        info: SharedColors.info,
        onBackgroundDark: SharedColors.onBackgroundDark,
        onBackgroundLight: SharedColors.onBackgroundLight,
        success: SharedColors.success,
        textDark: SharedColors.textDark,
        textError: SharedColors.textError,
        errorOnPrimary: SharedColors.errorOnPrimary,
        textLight: SharedColors.textLight,
        textOnAccentBackgroundDark: SharedColors.textOnAccentBackgroundDark,
        textOnAccentBackgroundLight: SharedColors.textOnAccentBackgroundLight,
        warning: SharedColors.warning,
        tertiaryLight: GreenAppColors.tertiary,
        tertiaryDark: GreenAppColors.tertiaryDark,
        readMessage: GreenAppColors.readMessage,
        readMessageDark: GreenAppColors.readMessageDark,
        delicateBackgroundDark: GreenAppColors.delicateBackgroundDark,
        accent: GreenAppColors.accent,
        delicateBackgroundLight: GreenAppColors.delicateBackgroundLight,
        textLink: SharedColors.textLink,

        name: "green",
        brightness: Brightness.light
    });
    private static orangeColor = new CustomColors({
        backgroundDark: SharedColors.backgroundDark,
        backgroundInputDark: SharedColors.backgroundInputDark,
        backgroundInputLight: SharedColors.backgroundInputLight,
        backgroundInputChatDark: SharedColors.backgroundInputChatDark,
        backgroundInputChatLight: SharedColors.backgroundInputChatLight,
        backgroundLight: SharedColors.backgroundLight,
        borderDark: SharedColors.borderDark,
        errorOnPrimary: SharedColors.errorOnPrimary,
        borderLight: SharedColors.borderLight,
        buttonOnBackgroundDark: SharedColors.buttonOnBackgroundDark,
        buttonOnBackgroundLight: SharedColors.buttonOnBackgroundLight,
        disabled: SharedColors.disabled,
        hintDark: SharedColors.hintDark,
        hintInverseDark: SharedColors.hintInverseDark,
        hintInverseLight: SharedColors.hintInverseLight,
        hintLight: SharedColors.hintLight,
        info: SharedColors.info,
        onBackgroundDark: SharedColors.onBackgroundDark,
        onBackgroundLight: SharedColors.onBackgroundLight,
        success: SharedColors.success,
        textDark: SharedColors.textDark,
        textError: SharedColors.textError,
        textLight: SharedColors.textLight,
        textOnAccentBackgroundDark: SharedColors.textOnAccentBackgroundDark,
        textOnAccentBackgroundLight: SharedColors.textOnAccentBackgroundLight,
        warning: SharedColors.warning,
        hintTertiaryLight: OrangeAppColors.hintTertiary,
        hintTertiaryDark: OrangeAppColors.hintTertiaryDark,
        tertiaryLight: OrangeAppColors.tertiary,
        tertiaryDark: OrangeAppColors.tertiaryDark,
        readMessage: OrangeAppColors.readMessage,
        readMessageDark: OrangeAppColors.readMessageDark,
        delicateBackgroundDark: OrangeAppColors.delicateBackgroundDark,
        accent: OrangeAppColors.accent,
        delicateBackgroundLight: OrangeAppColors.delicateBackgroundLight,
        textLink: SharedColors.textLink,
        name: "orange",
        brightness: Brightness.light,
    });
    private static blueColor = new CustomColors({
        backgroundDark: SharedColors.backgroundDark,
        backgroundInputDark: SharedColors.backgroundInputDark,
        backgroundInputLight: SharedColors.backgroundInputLight,
        backgroundInputChatDark: SharedColors.backgroundInputChatDark,
        backgroundInputChatLight: SharedColors.backgroundInputChatLight,
        backgroundLight: SharedColors.backgroundLight,
        borderDark: SharedColors.borderDark,
        borderLight: SharedColors.borderLight,
        buttonOnBackgroundDark: SharedColors.buttonOnBackgroundDark,
        buttonOnBackgroundLight: SharedColors.buttonOnBackgroundLight,
        disabled: SharedColors.disabled,
        hintDark: SharedColors.hintDark,
        errorOnPrimary: SharedColors.errorOnPrimary,
        hintInverseDark: SharedColors.hintInverseDark,
        hintInverseLight: SharedColors.hintInverseLight,
        hintLight: SharedColors.hintLight,
        info: SharedColors.info,
        onBackgroundDark: SharedColors.onBackgroundDark,
        onBackgroundLight: SharedColors.onBackgroundLight,
        success: SharedColors.success,
        textDark: SharedColors.textDark,
        textError: SharedColors.textError,
        textLight: SharedColors.textLight,
        textOnAccentBackgroundDark: SharedColors.textOnAccentBackgroundDark,
        textOnAccentBackgroundLight: SharedColors.textOnAccentBackgroundLight,
        warning: SharedColors.warning,
        hintTertiaryLight: BlueAppColors.hintTertiary,
        hintTertiaryDark: BlueAppColors.hintTertiaryDark,
        tertiaryLight: BlueAppColors.tertiary,
        tertiaryDark: BlueAppColors.tertiaryDark,
        readMessage: BlueAppColors.readMessage,
        readMessageDark: BlueAppColors.readMessageDark,
        delicateBackgroundDark: BlueAppColors.delicateBackgroundDark,
        accent: BlueAppColors.accent,
        delicateBackgroundLight: BlueAppColors.delicateBackgroundLight,
        textLink: SharedColors.textLink,
        name: "blue",
        brightness: Brightness.light,
    });
    private static redColor = new CustomColors({
        backgroundDark: SharedColors.backgroundDark,
        backgroundInputDark: SharedColors.backgroundInputDark,
        backgroundInputLight: SharedColors.backgroundInputLight,
        backgroundInputChatDark: SharedColors.backgroundInputChatDark,
        backgroundInputChatLight: SharedColors.backgroundInputChatLight,
        backgroundLight: SharedColors.backgroundLight,
        borderDark: SharedColors.borderDark,
        borderLight: SharedColors.borderLight,
        buttonOnBackgroundDark: SharedColors.buttonOnBackgroundDark,
        buttonOnBackgroundLight: SharedColors.buttonOnBackgroundLight,
        disabled: SharedColors.disabled,
        hintDark: SharedColors.hintDark,
        hintInverseDark: SharedColors.hintInverseDark,
        hintInverseLight: SharedColors.hintInverseLight,
        hintLight: SharedColors.hintLight,
        info: SharedColors.info,
        onBackgroundDark: SharedColors.onBackgroundDark,
        onBackgroundLight: SharedColors.onBackgroundLight,
        success: SharedColors.success,
        errorOnPrimary: SharedColors.errorOnPrimary,
        textDark: SharedColors.textDark,
        textError: SharedColors.textError,
        textLight: SharedColors.textLight,
        textOnAccentBackgroundDark: SharedColors.textOnAccentBackgroundDark,
        textOnAccentBackgroundLight: SharedColors.textOnAccentBackgroundLight,
        warning: SharedColors.warning,
        hintTertiaryLight: RedAppColors.hintTertiary,
        hintTertiaryDark: RedAppColors.hintTertiaryDark,
        tertiaryLight: RedAppColors.tertiary,
        tertiaryDark: RedAppColors.tertiaryDark,
        readMessage: RedAppColors.readMessage,
        readMessageDark: RedAppColors.readMessageDark,
        delicateBackgroundDark: RedAppColors.delicateBackgroundDark,
        accent: RedAppColors.accent,
        delicateBackgroundLight: RedAppColors.delicateBackgroundLight,
        textLink: SharedColors.textLink,
        name: "red",
        brightness: Brightness.light,
    });
    private static purpleColor = new CustomColors({
        backgroundDark: SharedColors.backgroundDark,
        backgroundInputDark: SharedColors.backgroundInputDark,
        backgroundInputLight: SharedColors.backgroundInputLight,
        backgroundInputChatDark: SharedColors.backgroundInputChatDark,
        backgroundInputChatLight: SharedColors.backgroundInputChatLight,
        backgroundLight: SharedColors.backgroundLight,
        borderDark: SharedColors.borderDark,
        borderLight: SharedColors.borderLight,
        buttonOnBackgroundDark: SharedColors.buttonOnBackgroundDark,
        buttonOnBackgroundLight: SharedColors.buttonOnBackgroundLight,
        disabled: SharedColors.disabled,
        hintDark: SharedColors.hintDark,
        hintInverseDark: SharedColors.hintInverseDark,
        hintInverseLight: SharedColors.hintInverseLight,
        hintLight: SharedColors.hintLight,
        info: SharedColors.info,
        onBackgroundDark: SharedColors.onBackgroundDark,
        onBackgroundLight: SharedColors.onBackgroundLight,
        success: SharedColors.success,
        textDark: SharedColors.textDark,
        errorOnPrimary: SharedColors.errorOnPrimary,
        textError: SharedColors.textError,
        textLight: SharedColors.textLight,
        textOnAccentBackgroundDark: SharedColors.textOnAccentBackgroundDark,
        textOnAccentBackgroundLight: SharedColors.textOnAccentBackgroundLight,
        warning: SharedColors.warning,
        hintTertiaryLight: PurpleAppColors.hintTertiary,
        hintTertiaryDark: PurpleAppColors.hintTertiaryDark,
        tertiaryLight: PurpleAppColors.tertiary,
        tertiaryDark: PurpleAppColors.tertiaryDark,
        readMessage: PurpleAppColors.readMessage,
        readMessageDark: PurpleAppColors.readMessageDark,
        delicateBackgroundDark: PurpleAppColors.delicateBackgroundDark,
        accent: PurpleAppColors.accent,
        delicateBackgroundLight: PurpleAppColors.delicateBackgroundLight,
        textLink: SharedColors.textLink,
        name: "purple",
        brightness: Brightness.light,
    });

    private static greyColor = new CustomColors({
        backgroundDark: SharedColors.backgroundDark,
        backgroundInputDark: SharedColors.backgroundInputDark,
        backgroundInputLight: SharedColors.backgroundInputLight,
        backgroundInputChatDark: SharedColors.backgroundInputChatDark,
        backgroundInputChatLight: SharedColors.backgroundInputChatLight,
        backgroundLight: SharedColors.backgroundLight,
        borderDark: SharedColors.borderDark,
        borderLight: SharedColors.borderLight,
        buttonOnBackgroundDark: SharedColors.buttonOnBackgroundDark,
        buttonOnBackgroundLight: SharedColors.buttonOnBackgroundLight,
        disabled: SharedColors.disabled,
        errorOnPrimary: SharedColors.errorOnPrimary,
        hintDark: SharedColors.hintDark,
        hintInverseDark: SharedColors.hintInverseDark,
        hintInverseLight: SharedColors.hintInverseLight,
        hintLight: SharedColors.hintLight,
        info: SharedColors.info,
        onBackgroundDark: SharedColors.onBackgroundDark,
        onBackgroundLight: SharedColors.onBackgroundLight,
        success: SharedColors.success,
        textDark: SharedColors.textDark,
        textError: SharedColors.textError,
        textLight: SharedColors.textLight,
        textOnAccentBackgroundDark: SharedColors.textOnAccentBackgroundDark,
        textOnAccentBackgroundLight: SharedColors.textOnAccentBackgroundLight,
        warning: SharedColors.warning,
        hintTertiaryLight: GreyAppColors.hintTertiary,
        hintTertiaryDark: GreyAppColors.hintTertiaryDark,
        tertiaryLight: GreyAppColors.tertiary,
        tertiaryDark: GreyAppColors.tertiaryDark,
        readMessage: GreyAppColors.readMessage,
        readMessageDark: GreyAppColors.readMessageDark,
        delicateBackgroundDark: GreyAppColors.delicateBackgroundDark,
        accent: GreyAppColors.accent,
        delicateBackgroundLight: GreyAppColors.delicateBackgroundLight,
        textLink: SharedColors.textLink,
        name: "grey",
        brightness: Brightness.light,
    });
    private static pinkColor = new CustomColors({
        backgroundDark: SharedColors.backgroundDark,
        backgroundInputDark: SharedColors.backgroundInputDark,
        backgroundInputLight: SharedColors.backgroundInputLight,
        backgroundInputChatDark: SharedColors.backgroundInputChatDark,
        backgroundInputChatLight: SharedColors.backgroundInputChatLight,
        backgroundLight: SharedColors.backgroundLight,
        borderDark: SharedColors.borderDark,
        errorOnPrimary: SharedColors.errorOnPrimary,
        borderLight: SharedColors.borderLight,
        buttonOnBackgroundDark: SharedColors.buttonOnBackgroundDark,
        buttonOnBackgroundLight: SharedColors.buttonOnBackgroundLight,
        disabled: SharedColors.disabled,
        hintDark: SharedColors.hintDark,
        hintInverseDark: SharedColors.hintInverseDark,
        hintInverseLight: SharedColors.hintInverseLight,
        hintLight: SharedColors.hintLight,
        info: SharedColors.info,
        onBackgroundDark: SharedColors.onBackgroundDark,
        onBackgroundLight: SharedColors.onBackgroundLight,
        success: SharedColors.success,
        textDark: SharedColors.textDark,
        textError: SharedColors.textError,
        textLight: SharedColors.textLight,
        textOnAccentBackgroundDark: SharedColors.textOnAccentBackgroundDark,
        textOnAccentBackgroundLight: SharedColors.textOnAccentBackgroundLight,
        warning: SharedColors.warning,
        hintTertiaryLight: PinkAppColors.hintTertiary,
        hintTertiaryDark: PinkAppColors.hintTertiaryDark,
        tertiaryLight: PinkAppColors.tertiary,
        tertiaryDark: PinkAppColors.tertiaryDark,
        readMessage: PinkAppColors.readMessage,
        readMessageDark: PinkAppColors.readMessageDark,
        delicateBackgroundDark: PinkAppColors.delicateBackgroundDark,
        accent: PinkAppColors.accent,
        delicateBackgroundLight: PinkAppColors.delicateBackgroundLight,
        textLink: SharedColors.textLink,
        name: "pink",
        brightness: Brightness.light,
    });
    private static brownColor = new CustomColors({
        backgroundDark: SharedColors.backgroundDark,
        backgroundInputDark: SharedColors.backgroundInputDark,
        backgroundInputLight: SharedColors.backgroundInputLight,
        backgroundInputChatDark: SharedColors.backgroundInputChatDark,
        backgroundInputChatLight: SharedColors.backgroundInputChatLight,
        backgroundLight: SharedColors.backgroundLight,
        borderDark: SharedColors.borderDark,
        errorOnPrimary: SharedColors.errorOnPrimary,
        borderLight: SharedColors.borderLight,
        buttonOnBackgroundDark: SharedColors.buttonOnBackgroundDark,
        buttonOnBackgroundLight: SharedColors.buttonOnBackgroundLight,
        disabled: SharedColors.disabled,
        hintDark: SharedColors.hintDark,
        hintInverseDark: SharedColors.hintInverseDark,
        hintInverseLight: SharedColors.hintInverseLight,
        hintLight: SharedColors.hintLight,
        info: SharedColors.info,
        onBackgroundDark: SharedColors.onBackgroundDark,
        onBackgroundLight: SharedColors.onBackgroundLight,
        success: SharedColors.success,
        textDark: SharedColors.textDark,
        textError: SharedColors.textError,
        textLight: SharedColors.textLight,
        textOnAccentBackgroundDark: SharedColors.textOnAccentBackgroundDark,
        textOnAccentBackgroundLight: SharedColors.textOnAccentBackgroundLight,
        warning: SharedColors.warning,
        readMessageDark: BrownAppColors.readMessageDark,
        hintTertiaryLight: BrownAppColors.hintTertiary,
        hintTertiaryDark: BrownAppColors.hintTertiaryDark,
        tertiaryLight: BrownAppColors.tertiary,
        tertiaryDark: BrownAppColors.tertiaryDark,
        readMessage: BrownAppColors.readMessage,
        delicateBackgroundDark: BrownAppColors.delicateBackgroundDark,
        accent: BrownAppColors.accent,
        delicateBackgroundLight: BrownAppColors.delicateBackgroundLight,
        textLink: SharedColors.textLink,
        name: "brown",
        brightness: Brightness.light,
    });

    private static availableCustomColors: Record<string, CustomColors> = {
        [ColorsType.default]: AppCustomColors.defaultColor,
        [ColorsType.orange]: AppCustomColors.orangeColor,
        [ColorsType.blue]: AppCustomColors.blueColor,
        [ColorsType.red]: AppCustomColors.redColor,
        [ColorsType.purple]: AppCustomColors.purpleColor,
        [ColorsType.grey]: AppCustomColors.greyColor,
        [ColorsType.pink]: AppCustomColors.pinkColor,
        [ColorsType.brown]: AppCustomColors.brownColor,
    }

    public static getColor(colorType: ColorsType): CustomColors {
        const customColor = AppCustomColors.availableCustomColors[colorType];
        return customColor ?? AppCustomColors.defaultColor;
    }

}
