import {inject, injectable} from "inversify";
import {action, configure, makeObservable} from "mobx";
import {DecryptUseCase} from "@dropDesk/domain/use_case/common/crypto/decrypt.usecase";
import {parseServerError} from "@dropDesk/data/clients/http.client";

configure({
    enforceActions: "always",
});

@injectable()
export class CommonController {

    private readonly _decryptUseCase: DecryptUseCase;

    constructor(
        @inject(DecryptUseCase) decryptUseCase: DecryptUseCase,
    ) {
        makeObservable(this);
        this._decryptUseCase = decryptUseCase;
    }

    @action
    async decrypt(value: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                return resolve(await this._decryptUseCase.call(value));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


}
