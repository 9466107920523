import {useState} from "react";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";

export const useModalChat = () => {

    const [visibleModalEditClient, setVisibleModalEditClient] = useState<{
        visible: boolean,
        idClient: string | null
    }>({visible: false, idClient: null});

    const [
        visibleModalInfoNewTicket,
        setVisibleModalInfoNewTicket] =
        useState<{ visible: boolean, contact: UserEntity | null, client: ClientEntity | null }>({
            visible: false,
            contact: null,
            client: null
        });

    const [visibleModalNewTicket, setVisibleModalNewTicket] = useState<boolean>(false);

    return {
        visibleModalEditClient, setVisibleModalEditClient,
        visibleModalInfoNewTicket, setVisibleModalInfoNewTicket,
        visibleModalNewTicket, setVisibleModalNewTicket
    }
}
