// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-popover-inner {
    border-radius: 8px;
    padding: 0px !important;
}

.ant-popover-inner-content {
    padding: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/presentation/components/messages/message_box/react_message/input/override_styles_input_reaction.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".ant-popover-inner {\n    border-radius: 8px;\n    padding: 0px !important;\n}\n\n.ant-popover-inner-content {\n    padding: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
