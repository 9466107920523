import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {RemoteStorageDatasource} from "@dropDesk/data/data_source/remote_storage/remote_storage_datasource";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";

@injectable()
export abstract class TicketsDescriptionStaticRemoteDataSource {
    public abstract set(ticketsDescriptionStatic: TicketsDescriptionStaticEntity): Promise<TicketsDescriptionStaticEntity>;

    public abstract list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean, listableForClients?: boolean): Promise<ListPaginationEntity<TicketsDescriptionStaticEntity>>;

    public abstract restore(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]): Promise<void>;

    public abstract delete(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]): Promise<void>;

    public abstract findByPK(id: string): Promise<TicketsDescriptionStaticEntity>;

}

@injectable()
export class TicketsDescriptionStaticRemoteDataSourceImpl implements TicketsDescriptionStaticRemoteDataSource {
    private _remoteDataSource: RemoteStorageDatasource;

    constructor(
        @inject(RemoteStorageDatasource) dataSource: RemoteStorageDatasource,
    ) {
        this._remoteDataSource = dataSource;
    }

    baseTicketsDescriptionStaticUrl: string = 'ticketsDescriptionStatic/';
    baseSetTicketsDescriptionStaticUrl: string = `${this.baseTicketsDescriptionStaticUrl}set`;
    baseRestoreTicketsDescriptionStaticUrl: string = `${this.baseTicketsDescriptionStaticUrl}restore/`;


    public async list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        listableForClients?: boolean
    ): Promise<ListPaginationEntity<TicketsDescriptionStaticEntity>> {
        return new Promise<ListPaginationEntity<TicketsDescriptionStaticEntity>>(async (resolve, reject) => {
            try {

                const response = await api.get(`${this.baseTicketsDescriptionStaticUrl}?searchParam=${searchParam ?? ''}&page=${page ?? ''}&limit=${limit ?? ''}&type=${listOnlyDeleted ? 'deleted' : ''}&listableForClients=${listableForClients ?? ''}`
                );

                const result = new ListPaginationEntity<TicketsDescriptionStaticEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: TicketsDescriptionStaticEntity) => new TicketsDescriptionStaticEntity({...entry})),
                });

                return resolve(result);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public set(ticketsDescriptionStatic: TicketsDescriptionStaticEntity): Promise<TicketsDescriptionStaticEntity> {
        return new Promise<TicketsDescriptionStaticEntity>(async (resolve, reject) => {
            try {

                const response = await api.post(this.baseSetTicketsDescriptionStaticUrl, ticketsDescriptionStatic);
                return resolve(response.data);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public restore(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < ticketsDescriptionStatic.length; i++) {
                    await api.patch(this.baseRestoreTicketsDescriptionStaticUrl + ticketsDescriptionStatic[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public delete(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < ticketsDescriptionStatic.length; i++) {
                    await api.delete(this.baseTicketsDescriptionStaticUrl + ticketsDescriptionStatic[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public findByPK(id: string): Promise<TicketsDescriptionStaticEntity> {
        return new Promise<TicketsDescriptionStaticEntity>(async (resolve, reject) => {
            try {

                const response = await api.get(this.baseTicketsDescriptionStaticUrl + id);
                const ticketsDescriptionStatic = new TicketsDescriptionStaticEntity({
                    ...response.data
                });

                return resolve(ticketsDescriptionStatic);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


}
