import React from "react";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContainerLabelAndPix,
    ContainerQRCode, ContentImage,
    ContentQRCode, Text
} from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/qr_code/styled";
import logo from "@dropDesk/storage/images/logo_dropdesk_portrait.png";
import {Image} from "@dropDesk/presentation/pages/subscription/ui/checkout/resume_value/styled";
import CopyAndPastePix from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/copy_and_paste";


const ResumeQrCode = (
    {
        qrCode,
        qrCodeText,
        height,
        width
    }: {
        qrCode?: string;
        qrCodeText?: string;
        height?: number;
        width?: number;
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const realHeight = height ?? 200;
    const realWidth = width ?? 200;
    const renderQrCode = (): React.ReactNode => {
        if (!!qrCode) {
            return (
                <ContentQRCode>
                    <img
                        src={qrCode}
                        // src={`data:image/gif;base64,${qrCode}`}
                        alt="QR Code"
                        style={{height: realHeight, width: realWidth}}
                    />
                </ContentQRCode>
            )
        } else {
            return (
                <div>
                    <SpinLoading
                        size={"large"}
                        color={colors.text}
                        tip={"Gerando QR Code ..."}
                    />
                </div>
            )
        }
    }

    return (
        <ContainerQRCode>
            <ContentImage>
                <Image src={logo} alt={"Logo DropDesk"}/>
            </ContentImage>
            <ContainerLabelAndPix>
                <Text
                    color={colors.text}
                    size={14}
                    style={{marginLeft: 10}}
                >Se preferir, use este QR code para pagar.</Text>
                {renderQrCode()}
            </ContainerLabelAndPix>

            {qrCodeText &&
                <CopyAndPastePix qrcodeText={qrCodeText} isPix={false}/>

            }

        </ContainerQRCode>
    )
}
export default ResumeQrCode;
