import {ModalStyled} from "./styled";
import React from "react";
//import './style.css';


export default function ModalMain(
    {
        width,
        open,
        onRequestClose,
        renderComponent
    }: {
        width: string | number,
        open: boolean,
        onRequestClose: () => void
        renderComponent: React.ReactNode;
    }
) {

    return (
        <ModalStyled
            width={width}
            onCancel={onRequestClose}
            open={open}
            centered
            footer={null}
            mask={true}
            keyboard={true}
            closable={false}
            destroyOnClose={true}
            style={{padding: 0, margin: 0}}
            bodyStyle={{padding: 0, margin: 0}}
            maskStyle={{
                padding: 0, margin: 0,
            }}
        >
            {renderComponent}
        </ModalStyled>
    );
}
