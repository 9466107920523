import HeaderModal from "../../headers/header_modal";
import {Container, Content, TextClosed} from "./styled";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import React, {useEffect, useState} from "react";
import {ModalClosedProps} from "@dropDesk/presentation/components/modals/modal_closed_ticket/modal_closed_ticket";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";
import {isValidDescription} from "@dropDesk/utils/helpers/validators";

import {
    ContentIcon,
    ContentInformation,
    TextInformation
} from "@dropDesk/presentation/components/modals/modal_information/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


export default function ModalTicketClosed(props: ModalClosedProps) {

    const [errorMessageDescriptionClosed, setErrorMessageDescriptionClosed] = useState('');
    const [descriptionClosed, setDescriptionClosed] = useState(props.value ?? '');

    useEffect(() => {
        validate();
    }, [descriptionClosed]);

    const validate = () => {
        setErrorMessageDescriptionClosed('');

        if (!isValidDescription(descriptionClosed) && props.visibleInputDescriptionClosed) {
            setErrorMessageDescriptionClosed('Você deve informar a resolução do problema');
            return false;
        } else {
            return true;
        }
    }

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <ModalMain
            open={props.visible}
            onRequestClose={props.onClose}
            width={400}
            renderComponent={<Container background={colors.onBackground}>
                <HeaderModal
                    disabled={props.loading}
                    letter={props.textHeader}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={props.onClose}
                />
                <Content>

                    {props.visibleInputDescriptionClosed &&
                        <InputTextAreaWithBorder
                            required={true}
                            autoFocus={true}
                            name='descriptionClosed'
                            size={65}
                            disabled={props.loading}
                            placeHolder={"Informe a resolução do atendimento"}
                            messageError={errorMessageDescriptionClosed}
                            label={"Resolução do atendimento"}
                            value={descriptionClosed}
                            onChange={(event) => {
                                setDescriptionClosed(event.target.value);
                            }}
                            onFocus={() => {
                                validate()
                            }}
                            onBlur={() => {
                                validate()
                            }}
                        />
                    }

                    {!props.visibleInputDescriptionClosed &&
                        <Content>
                            <ContentIcon>
                                <DropDeskIcon icon={ICONS_DROPDESK.check} size={120} color={colors.hintInverse}/>
                            </ContentIcon>
                            <ContentInformation>
                                <TextInformation color={colors.text}>Deseja realmente fechar este
                                    atendimento ?</TextInformation>
                            </ContentInformation>

                        </Content>
                    }

                </Content>
                <FooterModal
                    disabled={props.loading || !!errorMessageDescriptionClosed}
                    loading={props.loading}
                    type={"submit"}
                    letter={"Confirmar"}
                    onSubmit={() => {
                        if (!errorMessageDescriptionClosed) {
                            props.onSelectTicketClosed(descriptionClosed)
                        }
                    }}
                    onRequestClose={props.onClose}
                />
            </Container>}
        />
    );
}
