import {observer} from "mobx-react";
import React from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {
    useValidatorInputCompanyPaymentInfo
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/validator";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";


const FooterPaymentInfo = observer(
    ({
         loading,
         onClickSavePaymentInfo,
         newPaymentInfo,
         backPreviousScreen,
         onUpdate
     }: {
        loading: boolean
        onClickSavePaymentInfo: () => void
        newPaymentInfo: CompanyPaymentInfo
        backPreviousScreen: () => void
        onUpdate: (newPaymentInfo: CompanyPaymentInfo) => void
    }) => {
        const {
            isValidDataForm
        } = useValidatorInputCompanyPaymentInfo(newPaymentInfo);
        return (
            <FooterForm
                disabled={loading || !isValidDataForm}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={() => onClickSavePaymentInfo()}
                onRequestClose={() => backPreviousScreen()}
                useCheckBox={true}
                labelCheckBox={'Desejo receber nota fiscal'}
                checkedCheckBox={newPaymentInfo.billingInfoHolder.receiveInvoices ?? false}
                disabledCheckBox={loading}
                checkBoxName={'hasTemporary'}
                onChangeCheckBox={(event) => {
                    const _newPaymentInfo = newPaymentInfo.copyWith({
                        billingInfoHolder: newPaymentInfo.billingInfoHolder.copyWith({
                            receiveInvoices: event.target.checked
                        })
                    });
                    onUpdate(_newPaymentInfo);
                }}
            />
        )
    })
export default FooterPaymentInfo;
