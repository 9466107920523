import styled from "styled-components";

type ContainerType = {
    background: string;
    border: string;
    heightShowingHeaderInfo: number;
};

type ContainerFormType = {
    background: string;
    border: string;
};

type TextType = {
    color: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 126px - ${props => `${props.heightShowingHeaderInfo}px`});
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;


export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;


export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  overflow: auto;
`;


export const FormEvaluation = styled.div<ContainerFormType>`
  display: flex;
  flex: 1;
  min-width: 768px;
  flex-direction: column;
  overflow: auto;
  margin: 15px 0px 15px 0px;
  background: ${props => props.background};
  border-radius: 5px;
  border: 1px solid ${props => props.border};
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`;

export const ContentFormEvaluation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

