import styled from "styled-components";

type TimeLine = {
    color: string;
    size: number;
}

export const Text = styled.span<TimeLine>`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
`;
