import styled from "styled-components";
import {Radio} from 'antd';
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type RadioStyle = {
    color: string;
    accent: string;
    backgroundInput: string;
}
export const RadioStyled = styled(Radio)<RadioStyle>`
  .ant-radio-checked .ant-radio-inner {
    border-color: ${props => props.accent};
  }

  .ant-radio-inner {
    background-color: ${props => props.backgroundInput};
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${props => props.accent};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${props => props.accent};
  }

  .ant-radio-checked .ant-radio-inner:focus {
    border-color: ${props => props.accent};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px ${props => hexToRGBA(props.accent, 0.12)} !important;
    border-color: ${props => props.accent};
  }

  &.ant-radio-wrapper {
    color: ${props => props.color} !important;
    font-size: 15px;
  }
`;

