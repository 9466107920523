import {useState} from "react";

export const useModalSearchResponseStandard = () => {

  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const [visibleModalRestore, setVisibleModalRestore] = useState<boolean>(false);
  const [visibleModalNewResponseStandard, setVisibleModalNewResponseStandard] = useState<boolean>(false);


  return {
    visibleModalDelete,
    setVisibleModalDelete,
    visibleModalRestore,
    setVisibleModalRestore,
    visibleModalNewResponseStandard, setVisibleModalNewResponseStandard,

  }
}
