import "reflect-metadata";
import {inject, injectable} from "inversify";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";

@injectable()
export class SetPaymentMethodCompanyUseCase {
    private _repository: CompanyRepository;

    constructor(@inject(CompanyRepository) repository: CompanyRepository) {
        this._repository = repository;
    }

    public call(input: PaymentMethodEntity): Promise<PaymentMethodEntity> {
        return this._repository.setPaymentMethod(input);
    }
}

