import {injectable} from "inversify";
import {ReportTicketAggregateEntity} from "@dropDesk/domain/entities/reports/report_ticket_aggregate/report_ticket_aggregate.entity";

@injectable()
export abstract class ReportTicketAggregateRepository {
  public abstract get(period: { startDate: string, endDate: string }): Promise<ReportTicketAggregateEntity>;

  public abstract exportPdf(period: { startDate: string, endDate: string }): Promise<void>;

}
