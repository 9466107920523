import {observer} from "mobx-react";
import {ContentCheckbox, ContentInputs, ContentInputsWithBorder} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import React from "react";
import {ClientCreateAccountEntity} from "@dropDesk/domain/entities/client/client_create_account_entity";
import {useValidatorCreateClientAccount} from "@dropDesk/presentation/pages/company/ui/form_client/inputs/validator";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";
import ContractInput from "@dropDesk/presentation/components/inputs/input_contract";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const Inputs = observer(
    ({
         clientCreateAccount,
         onUpdate,
         loading,
         disabledUniqueCode
     }: {
         clientCreateAccount?: ClientCreateAccountEntity
         onUpdate: (clientCreateAccount: ClientCreateAccountEntity) => void,
         loading: boolean
         disabledUniqueCode: boolean
     }
    ) => {

        const appController = useInjection(AppController);

        const {
            passwordVisible, setPasswordVisible,
            errorMessageCompanyName,
            errorMessageCompanySponsorReference,
            errorMessageName,
            errorMessageEmail,
            errorMessagePassword,
        } = useValidatorCreateClientAccount(clientCreateAccount);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string };
        }) => {
            onUpdate(
                clientCreateAccount!.copyWith({
                    [event.target.name]: (event.target.type === "checkbox" || event.target.type === "switch") ?
                        event.target.checked :
                        event.target.name === "companySponsorReference" ? removeMask(event.target.value.replace(/\D/g, ""))
                            :
                            event.target.value,
                })
            );
        };

        return (
            <ContentInputs>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        autoFocus={disabledUniqueCode ? false : true}
                        size={42}
                        marginTop={10}
                        nameIconLeft={ICONS_DROPDESK.numbers}
                        name='companySponsorReference'
                        disabled={disabledUniqueCode || loading}
                        placeHolder={"Código de referência seu fornecedor"}
                        messageError={errorMessageCompanySponsorReference}
                        value={clientCreateAccount?.companySponsorReference?.toString() ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        autoFocus={disabledUniqueCode ? true : false}
                        size={42}
                        nameIconLeft={ICONS_DROPDESK.business}
                        name='companyName'
                        disabled={loading}
                        placeHolder={"Nome Empresa"}
                        messageError={errorMessageCompanyName}
                        value={clientCreateAccount?.companyName ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        size={42}
                        nameIconLeft={ICONS_DROPDESK.account}
                        name={'name'}
                        disabled={loading}
                        placeHolder={"Seu Nome"}
                        messageError={errorMessageName}
                        value={clientCreateAccount?.contact.name ?? ''}
                        onChange={(event) => {
                            onUpdate(clientCreateAccount!.copyWith({
                                contact: clientCreateAccount!.contact.copyWith({
                                    name: event.target.value
                                })
                            }))
                        }}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        size={42}
                        nameIconLeft={ICONS_DROPDESK.atSign}
                        name={"email"}
                        disabled={loading}
                        placeHolder={"E-mail"}
                        messageError={errorMessageEmail}
                        value={clientCreateAccount?.contact.email ?? ''}
                        onChange={(event) => {
                            onUpdate(clientCreateAccount!.copyWith({
                                contact: clientCreateAccount!.contact.copyWith({
                                    email: event.target.value
                                })
                            }))
                        }}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        size={42}
                        nameIconLeft={ICONS_DROPDESK.padlock}
                        disabled={loading}
                        type={passwordVisible ? "text" : "password"}
                        placeHolder={"Senha"}
                        name={'password'}
                        nameIconRight={passwordVisible ? ICONS_DROPDESK.eye : ICONS_DROPDESK.eyeClosed}
                        messageError={errorMessagePassword}
                        value={clientCreateAccount?.contact?.password ?? ''}
                        onChange={(event) => {
                            onUpdate(clientCreateAccount!.copyWith({
                                contact: clientCreateAccount!.contact.copyWith({
                                    password: event.target.value
                                })
                            }))
                        }}
                        onClickRightIcons={() => {
                            setPasswordVisible(!passwordVisible);
                        }}
                    />
                </ContentInputsWithBorder>
                <ContractInput
                    contract={appController.signUpContract}
                    acceptContract={clientCreateAccount?.contract ?? false}
                    onClickAcceptContract={(value) => {
                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'contract',
                                        value: '',
                                        checked: value,
                                        type: 'checkbox'
                                    }
                            })
                    }}
                />
            </ContentInputs>
        );
    });

export default Inputs;
