import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";

@injectable()
export class CancelSubscriptionUseCase {
    private _repository: SubscriptionRepository;

    constructor(@inject(SubscriptionRepository) repository: SubscriptionRepository) {
        this._repository = repository;
    }

    public call(idSubscription: string, cancellationReason: string, isFutureSubscription: boolean): Promise<PaymentSubscriptionEntity> {
        return this._repository.cancel(idSubscription, cancellationReason, isFutureSubscription);
    }
}

