import styled from "styled-components";

type ButtonType = {
    background: string;
    color: string;
};

type TextType = {
    color: string;
}

export const ContentData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;


export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContentDataInput = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ContentJuridicalNature = styled.div<TextType>`
  display: flex;
  height: 35px;
  width: 150px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.color};
  padding: 2px;
  margin-right: 20px;
`;

export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SubContentInputAndImage = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioButtonLeft = styled.button<ButtonType>`
  display: flex;
  background: ${(props) => props.background};
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  width: 80px;
  height: 29px;
  cursor: pointer;
  border: 0;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.color};

  &:hover {
    filter: brightness(80%);
  }
`;

export const RadioButtonRight = styled.button<ButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background};
  border-radius: 0 5px 5px 0;
  width: 80px;
  height: 29px;
  cursor: pointer;
  border: 0;
  font-size: 15px;
  font-weight: bold;
  color: ${(props) => props.color};

  &:hover {
    filter: brightness(80%);
  }
`;

export const ContentImage = styled.div`
  display: flex;
  width: 170px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
`;
