import luhn from "luhn";
import creditCardType, {types as cardTypes} from "credit-card-type";
import {ValidateNumberCreditCard} from "@dropDesk/domain/entities/payment_method/payment_method.entity";

const cardTypesMap = {
    amex: ["amex", "americanexpress", "american-express"],
    dinersclub: ["diners", "dinersclub", "diners-club"],
    visaelectron: ["visaelectron", "visa-electron"],
};
export const sanitizeNumber = (number: string | number) => number.toString().trim().replace(" ", "");

export const getCardType = (cardNumber: string | number) => {
    const potentialCardTypes = creditCardType(sanitizeNumber(cardNumber));

    if (potentialCardTypes.length === 1) {
        const firstResult = potentialCardTypes.shift();

        return firstResult?.type || "unknown";
    }

    return "unknown";
};

const getMaxLength = (number: string | number): number => {
    let updatedIssuer = "unknown";
    if (number) {
        const validatedIssuer = getCardType(number);
        if (Object.values(cardTypes).includes(validatedIssuer as any)) {
            updatedIssuer = validatedIssuer;
        }
    }

    let maxLength = 16;

    if (cardTypesMap.amex.includes(updatedIssuer)) {
        maxLength = 15;
    } else if (cardTypesMap?.dinersclub.includes(updatedIssuer)) {
        maxLength = 14;
    } else if (["hipercard", "mastercard", "visa"].includes(updatedIssuer)) {
        maxLength = 19;
    }

    return maxLength;
};

const isValidCreditCardNumber = (number: string | number): boolean => {
    return luhn.validate(String(number));
}

export const validateCreditCardNumber = (number: string): ValidateNumberCreditCard => {
    const validate = new ValidateNumberCreditCard({
        isValid: isValidCreditCardNumber(number),
        maxLength: getMaxLength(number)
    });
    return validate;
}

export const getFirstNameLastName = (fullName: string): { firstName: string, lastName: string } | null => {
    const nameParts = fullName.split(" ");

    // Check if there are at least two parts in the string (first name and last name)
    if (nameParts.length >= 2) {
        const firstName = nameParts.slice(0, -1).join(" ");
        const lastName = nameParts[nameParts.length - 1];

        return {firstName, lastName};
    }
    return null;
}
