export enum PlanPeriodDisplay {
    monthly = 'Mensal',
    yearly = 'Anual',
}

export enum PlanLevel {
    onlyApp = 1,
    onlyWeb = 2,
    appAndWeb = 3
}

