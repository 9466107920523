import {useDropzone} from 'react-dropzone'
import AvatarImage from "@dropDesk/presentation/components/avatar";
import {ButtonChangeImage, Container, ContentImageDefault, Garbage} from './styled'
import {DropzoneType} from "@dropDesk/presentation/components/drop_zone/pick_image_profile/pick_image_profile";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {LogoMenu} from "@dropDesk/presentation/components/menu/logo";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


export default function DropzonePickImage(props: DropzoneType) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        multiple: false,
        noKeyboard: true,
        maxSize: 2 * 1000000, //1MB
        noDrag: true,
        noClick: props.disabled ?? false,
        onDrop: (acceptedFiles: File[]) => {
            props.onPick(acceptedFiles)
        },
    });

    return (

        <div style={{display: 'flex', position: 'relative'}}>

            {props.useGarbage && props.urlImageProfile &&
                <Garbage shadowcolor={colors.hint} disabled={props.disabled ?? false} background={SharedColors.white}
                         onClick={props.onRemoveProfileImage}>
                    <DropDeskIcon icon={ICONS_DROPDESK.delete} color={colors.textError}/>
                </Garbage>}

            <div  {...getRootProps()}>

                <Container background={colors.onBackground}>

                    <input {...getInputProps()} />
                    {(props.urlImageProfile || props.name) &&
                        <AvatarImage
                            disabled={props.disabled}
                            useCursorPointer={true}
                            title={props.name}
                            url={props.urlImageProfile}
                            size={props.sizeImage}
                            round={props.radiusImage}
                            width={props.width}
                        />
                    }

                    {(!props.urlImageProfile && !props.name) &&
                        <ContentImageDefault
                            disabled={props.disabled}
                            size={props.sizeImage}
                            background={colors.onBackground}
                            bordercolor={colors.border}
                            accent={colors.accent}
                            width={props.width}
                            style={{justifyContent: props.useLogoPlaceHolder ? 'flex-start' : 'center'}}
                        >
                            {props.useLogoPlaceHolder && <LogoMenu logoBrandUrl={null} collapsed={true}/>}
                            {!props.useLogoPlaceHolder &&
                                <DropDeskIcon icon={ICONS_DROPDESK.image} size={35} color={colors.hintInverse}/>}
                        </ContentImageDefault>}

                    <ButtonChangeImage
                        border={colors.border}
                        accent={colors.accent}
                        background={colors.backgroundInput}
                        disabled={props.disabled} marginTop={props.marginTopButtonChangeImage ?? 15}
                        letterColor={colors.text}>
                        Trocar Imagem
                    </ButtonChangeImage>

                </Container>
            </div>
        </div>
    )
}
