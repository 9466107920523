import React, {ChangeEvent} from 'react';
import {SliderStyled} from "@dropDesk/presentation/components/slider/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SliderType} from "@dropDesk/presentation/components/slider/slider";


export const Slider = (props: SliderType) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <div style={{width: props.width}}>
            <SliderStyled
                min={props.min}
                max={props.max}
                step={props.step}
                value={props.value}
                defaultValue={props.value}
                onChange={props.onChange}
                trackcolor={colors.hint}
                buttoncolor={colors.accent}
                trackStyle={{borderColor: colors.hint, background: colors.accent}}
                handleStyle={{color: colors.accent, background: colors.accent, borderColor: colors.accent}}
                tooltip={{open: props.useToolTip ?? false}}
            />
        </div>
    )
};
