import "reflect-metadata";
import {injectable} from "inversify";
import {Observable, Observer} from "rxjs";
import {FileProgress} from "@dropDesk/domain/entities/common/file_progress";
import {AudioRecordEntity, AudioRecordStatus} from "@dropDesk/domain/entities/common/audio_record.entity";
import {parseServerError} from "@dropDesk/data/clients/http.client";
import audioBufferToWav from "@dropDesk/data/data_source/io/audio_convert_wave";

@injectable()
export abstract class IoRemoteDataSource {
    public abstract createObjectURLBlob(file: File | Blob): string;

    public abstract urlToBlob(url: string): Promise<Blob>;

    public abstract downloadFile(url: string, fileName: string): Observable<FileProgress>;

    public abstract startRecordVoiceListener(audioRecorder: AudioRecordEntity, stream: MediaStream): Observable<AudioRecordEntity>;

    public abstract getAudioFromMediaRecorder(audioRecorder: AudioRecordEntity): Promise<AudioRecordEntity>;

    public abstract getMediaStream(): Promise<MediaStream>;

    public abstract stopRecord(mediaRecorder?: MediaRecorder, stream?: MediaStream): void;

}


@injectable()
export class IoRemoteDataSourceImpl implements IoRemoteDataSource {

    public createObjectURLBlob(file: File | Blob): string {
        return URL.createObjectURL(file);
    }

    public async urlToBlob(url: string): Promise<Blob> {
        return await fetch(url).then(response => response.blob());
    }


    public startRecordVoiceListener = (audioRecorder: AudioRecordEntity, stream: MediaStream): Observable<AudioRecordEntity> => {
        return new Observable<AudioRecordEntity>((observer: Observer<AudioRecordEntity>) => {

            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.start(100);
            let chunks: Blob[] = [];

            mediaRecorder.onstart = function (event) {
                observer.next(audioRecorder.copyWith({
                    chunks: chunks,
                    mediaRecorder,
                    stream,
                    status: AudioRecordStatus.recording
                }));
            }

            mediaRecorder.ondataavailable = function (event) {
                chunks.push(event.data);
            };

            mediaRecorder.onstop = function (event) {
                observer.next(audioRecorder.copyWith({
                    chunks,
                    mediaRecorder,
                    stream,
                }));
                observer.complete();
            }

        })
    }


    public stopRecord = (mediaRecorder?: MediaRecorder, stream?: MediaStream): void => {
        if (mediaRecorder && mediaRecorder.state !== "inactive") {
            mediaRecorder?.stop();
        }
        stream?.getAudioTracks().forEach(track => track.stop());
    }

    public async getAudioFromMediaRecorder(audioRecorder: AudioRecordEntity): Promise<AudioRecordEntity> {
        if (audioRecorder.mediaRecorder) {
            const audioWave = await this.chunksToWavBlob(audioRecorder.chunks);
            const audioBlob = window.URL.createObjectURL(audioWave.blob);
            return audioRecorder.copyWith({
                status: AudioRecordStatus.stop,
                audioBlob,
                duration: audioWave.duration
            });
        } else {
            return audioRecorder;
        }
    }


    public getMediaStream = (): Promise<MediaStream> => {
        return new Promise<MediaStream>(async (resolve, reject) => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                return resolve(stream);
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public downloadFile = (url: string, fileName: string): Observable<FileProgress> => {
        return new Observable((observer: Observer<FileProgress>) => {
            let blob: Blob;
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';


            xhr.onload = function (event) {
                blob = new Blob([this.response]);
                return observer.next(new FileProgress({
                    progress: parseFloat(((event.loaded / event.total) * 100).toFixed(0)),
                    inProgress: false,
                    url
                }));
            };

            xhr.onprogress = function (event) {
                return observer.next(new FileProgress({
                    progress: parseFloat(((event.loaded / event.total) * 100).toFixed(0)),
                    inProgress: true,
                    url
                }));
            };

            xhr.onloadend = function (event) {
                const anchor = document.createElement("a");
                document.body.appendChild(anchor);
                url = window.URL.createObjectURL(blob);
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                anchor.remove();
                window.URL.revokeObjectURL(url);
                observer.complete();

            }

            xhr.send();
        })
    }

    private chunksToWavBlob = async (chunk: Blob[]): Promise<{ blob: Blob, duration: number }> => {
        return new Promise<{ blob: Blob, duration: number }>(async (resolve, _) => {
            let fr = new FileReader();
            let wavBlob;
            fr.readAsArrayBuffer(new Blob(chunk, {type: "audio/wav"}));
            const audioContext = new (window.AudioContext)();
            let frOnload = (e: ProgressEvent<FileReader>) => {
                const buffer = e.target?.result;
                audioContext.decodeAudioData(buffer as ArrayBuffer).then((data) => {
                    wavBlob = new Blob([new DataView(audioBufferToWav(data))], {
                        type: "audio/wav",
                    });
                    return resolve({blob: wavBlob, duration: data.duration});
                });
            };
            fr.onload = frOnload;
        });
    }

}
