import {isValidEmail, isValidName, isValidNumberTelephone} from "@dropDesk/utils/helpers/validators";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export const validateContacts = (
  user: UserEntity,
  setErrorMessageName?: (text: string) => void,
  setErrorMessageEmail?: (text: string) => void,
  setErrorMessageTelephone?: (text: string) => void,
  setIsValidDataFormContacts?: (value: boolean) => void,
  isNewChatContact?:boolean
): boolean => {
  if (setErrorMessageName) {
    setErrorMessageName('');
  }
  if (setErrorMessageEmail) {
    setErrorMessageEmail('');
  }
  if (setErrorMessageTelephone) {
    setErrorMessageTelephone('');
  }

  if (!isValidName(user.name)) {
    if (setErrorMessageName) {
      setErrorMessageName('O nome do contato deve possuir mais de 2 letra');
    }
    if (setIsValidDataFormContacts) {
      setIsValidDataFormContacts(false);
    }
    return false;
  } else if (!!user.isCreateAccount && !isValidEmail(user.email ?? '')) {
    if (setErrorMessageEmail) {
      setErrorMessageEmail('Informe um email válido');
    }
    if (setIsValidDataFormContacts) {
      setIsValidDataFormContacts(false);
    }
    return false;
  } else if ((!!user.telephone || !!isNewChatContact) && !isValidNumberTelephone(user.telephone ?? '')) {
    if (setErrorMessageTelephone) {
      setErrorMessageTelephone('Telefone Inválido');
    }
    if (setIsValidDataFormContacts) {
      setIsValidDataFormContacts(false);
    }
    return false;
  } else {
    if (setIsValidDataFormContacts) {
      setIsValidDataFormContacts(true);
    }
    return true;
  }
}
