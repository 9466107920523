import styled, {keyframes} from "styled-components";
import {ContainerType, TextZoomType} from "@dropDesk/presentation/components/crop/crop";
import {ButtonNewType} from "@dropDesk/presentation/components/crop/crop";


export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 80vh;
  width: 60vh;
  background: ${props => props.background};
  border-radius: 7px;
`;
export const ContentCrop = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
export const SubContainerCrop = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 16px 16px 0 16px;
`;

export const ContainerCrop = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${props => props.background};
  overflow: auto;
`;
export const ContainerButton = styled.div`
  display: flex;
  min-height: 70px;
  align-items: center;
  justify-content: space-around;
`;
export const ContentSlider = styled.div`
  display: flex;
  min-height: 70px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const TextZoom = styled.span<TextZoomType>`
  font-size: 17px;
  color: ${props => props.color};
  margin-right: 15px;

`;

export const Button = styled.div<ButtonNewType>`
  display: flex;
  flex-direction: row;
  border: 0;
  justify-content: center;
  align-items: center;
  height: 43px;
  min-height: 43px;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;
  border-radius: 5px;
  width: ${props => props.widthButton}px;
  transition: background-color 0.2s;
  background: ${props => props.background};
  text-decoration: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;

  &:hover {
    filter: brightness(80%);
  }
`;
