import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, TextBold} from "@dropDesk/presentation/pages/select_role/ui/header/styled";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";


const Header = (observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (

        <Container>
            <TextBold size={ConstantsStyles.titleLargeFontSize} color={colors.text}>Quem deseja cadastrar?</TextBold>
        </Container>

    );
}))
export default Header;
