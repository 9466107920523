import {useState} from "react";

export const useModalsReportTicketRate = () => {

  const [visibleModalSearchUser, setVisibleModalSearchUser] = useState<boolean>(false);

  return {
    visibleModalSearchUser,setVisibleModalSearchUser,
  }
}
