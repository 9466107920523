import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";

@injectable()
export class EditMessageUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(idMessage: string, text: string): Promise<void> {
        return this._repository.editMessage(idMessage, text);
    }
}

