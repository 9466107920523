import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import React, {useState} from "react";
import {
    ContainerInputReactions, ContentInputReactions, ContentReaction, PopOver
} from "@dropDesk/presentation/components/messages/message_box/react_message/input/styled";
import './override_styles_input_reaction.css';
import EmojiPicker from "@dropDesk/presentation/components/emoji_picker";
import {Popover} from "antd";
import FullScreenOverlay from "@dropDesk/presentation/components/overlay/full_screen_overlay";
import {observer} from "mobx-react";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const InputReactMessage = observer((
    {
        position,
        isDarkTheme,
        handleAddReaction,
        lastUsedReactions,
        userLoggedId,
        reactComponent,
        reactions
    }: {
        position: 'left' | 'right'
        isDarkTheme: boolean
        handleAddReaction: (reaction: string) => Promise<void>
        lastUsedReactions: string[]
        userLoggedId: string
        reactComponent: React.ReactElement,
        reactions: TicketMessageEntity[]

    }) => {
    const [visibleReactPopover, setVisibleReactPopover] = useState<boolean>(false);
    const [visibleEmojiPickerPopover, setVisibleEmojiPickerPopover] = useState<boolean>(false);
    const [preventDoubleClick, setPreventDoubleClick] = useState<boolean>(false);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <PopOver
            color={colors.backgroundInput}
            style={{padding: 0, borderRadius: 8}}
            position={position}
            marginSize={35}
            placement={'top'}
            trigger={["click"]}
            title=""
            showArrow={false}
            open={visibleReactPopover}
            onOpenChange={(value) => {
                setVisibleReactPopover(value);
            }}
            content={
                <>
                    <ContainerInputReactions
                        shadow={colors.isDarkTheme ? SharedColors.white : SharedColors.black}
                    >
                        <ContentInputReactions background={colors.backgroundInput}>
                            {lastUsedReactions.map((reaction, index) => {
                                    const hasReaction: boolean =
                                        reactions ?
                                            reactions.filter((entry) => entry.reactionMessage!.text === reaction && entry.senderId === userLoggedId).length > 0
                                            :
                                            false;
                                    return (
                                        <ContentReaction
                                            accent={colors.accent}
                                            key={index}
                                            background={hasReaction ? colors.backgroundInput : 'transparent'}
                                            onClick={async () => {
                                                if (!preventDoubleClick) {
                                                    setPreventDoubleClick(true);
                                                    setVisibleReactPopover(false);
                                                    await handleAddReaction(reaction);
                                                    setPreventDoubleClick(false);
                                                }

                                            }}>
                                            {<span>{reaction}</span>}
                                        </ContentReaction>
                                    )
                                }
                            )}
                            <Popover
                                color={colors.backgroundInput}
                                placement={'left'}
                                trigger="click"
                                title=""
                                open={visibleEmojiPickerPopover}
                                onOpenChange={(visible) => setVisibleEmojiPickerPopover(visible)}
                                showArrow={false}
                                content={
                                    <div style={{width: 352}}>
                                        <EmojiPicker
                                            onEmojiSelect={(emoji) => {
                                                handleAddReaction(emoji);
                                                setVisibleEmojiPickerPopover(false);
                                            }}
                                        />
                                    </div>
                                }>
                                <ContentReaction
                                    accent={colors.accent}
                                    background={'transparent'}
                                    onClick={() => {
                                        setVisibleReactPopover(false);
                                        setVisibleEmojiPickerPopover(true);
                                    }}
                                >
                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.plusFill}
                                        size={26} color={colors.text}
                                    />
                                </ContentReaction>

                            </Popover>

                        </ContentInputReactions>


                    </ContainerInputReactions>
                    {(visibleReactPopover || visibleEmojiPickerPopover) &&
                        <FullScreenOverlay onClick={() => {
                            setVisibleReactPopover(false);
                            setVisibleEmojiPickerPopover(false);
                        }}/>
                    }
                </>
            }>
            {reactComponent}

        </PopOver>
    )

});
export default InputReactMessage;
