export class PaymentSubscriptionSimulateChangesEntity {
    description!: string;
    value!: number;
    days!: number;

    constructor({
                    description,
                    value,
                    days,
                }: {
        description: string;
        value: number;
        days: number;
    }) {
        Object.assign(this, {
            description,
            value,
            days,
        });
    }
}

export class PaymentSubscriptionSimulateUpgradeEntity {
    from!: string;

    to!: number;


    constructor({
                    from,
                    to,
                }: {
        from: number;
        to: number;
    }) {
        Object.assign(this, {
            from,
            to,
        });
    }
}

export class PaymentSubscriptionSimulateEntity {
    total!: number;

    dueDate!: string;

    nextDueDate!: string;

    changes!: Array<PaymentSubscriptionSimulateChangesEntity>;

    users?: PaymentSubscriptionSimulateUpgradeEntity;

    dueDay?: PaymentSubscriptionSimulateUpgradeEntity;

    canSubscribe!: boolean;

    upgradeAllowedAfter?: string;

    public get userDescription() {
        return `Mudança de quantidade de atendentes de ${this.users?.from} para`;
    }

    public get dueDayDescription() {
        return `Mudança de melhor dia para pagamento de ${this.dueDay?.from} para`;
    }

    constructor({
                    total,
                    dueDate,
                    nextDueDate,
                    changes,
                    users,
                    dueDay,
                    canSubscribe,
                    upgradeAllowedAfter,
                }: {
        total: number;
        dueDate: string;
        nextDueDate: string;
        changes: Array<PaymentSubscriptionSimulateChangesEntity>;
        users?: PaymentSubscriptionSimulateUpgradeEntity;
        dueDay?: PaymentSubscriptionSimulateUpgradeEntity;
        canSubscribe: boolean;
        upgradeAllowedAfter?: string;
    }) {
        Object.assign(this, {
            total,
            dueDate,
            nextDueDate,
            changes,
            users,
            dueDay,
            canSubscribe,
            upgradeAllowedAfter,
        });
    }
}
