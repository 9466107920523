import {useState} from "react";

export const useModalsSubscription = () => {

    const [visibleModalPay, setVisibleModalPay] = useState<boolean>(false);
    const [visibleModalDisplayDataPix, setVisibleModalDisplayDataPix] = useState<boolean>(false);
    const [visibleModalDisplayBoleto, setVisibleModalDisplayBoleto] = useState<boolean>(false);
    const [visibleModalProcessingCreditCardPayment, setVisibleModalProcessingCreditCardPayment] = useState<boolean>(false);
    const [visibleModalPaymentSuccess, setVisibleModalPaymentSuccess] = useState<boolean>(false);
    const [visibleModalChangePaymentInfo, setVisibleModalChangePaymentInfo] = useState<boolean>(false);
    const [visibleModalChangePaymentMethod, setVisibleModalChangePaymentMethod] = useState<boolean>(false);
    const [visibleModalReactiveSubscription, setVisibleModalReactiveSubscription] = useState<boolean>(false);
    const [visibleModalCancelSubscription, setVisibleModalCancelSubscription] = useState<boolean>(false);
    const [visibleModalCancelLegacySubscription, setVisibleModalCancelLegacySubscription] = useState<boolean>(false);
    const [visibleModalLogout, setVisibleModalLogout] = useState<boolean>(false);
    const [visibleModalCancelFutureSubscription, setVisibleModalCancelFutureSubscription] = useState<boolean>(false);
    const [visibleModalDowngrade, setVisibleModalDowngrade] = useState<boolean>(false);
    const [visibleModalViewLog, setVisibleModalViewLog] = useState<boolean>(false);

    return {
        visibleModalPay, setVisibleModalPay,
        visibleModalDisplayDataPix, setVisibleModalDisplayDataPix,
        visibleModalDisplayBoleto, setVisibleModalDisplayBoleto,
        visibleModalProcessingCreditCardPayment, setVisibleModalProcessingCreditCardPayment,
        visibleModalPaymentSuccess, setVisibleModalPaymentSuccess,
        visibleModalChangePaymentInfo, setVisibleModalChangePaymentInfo,
        visibleModalChangePaymentMethod, setVisibleModalChangePaymentMethod,
        visibleModalReactiveSubscription, setVisibleModalReactiveSubscription,
        visibleModalCancelSubscription, setVisibleModalCancelSubscription,
        visibleModalLogout, setVisibleModalLogout,
        visibleModalCancelFutureSubscription, setVisibleModalCancelFutureSubscription,
        visibleModalDowngrade, setVisibleModalDowngrade,
        visibleModalViewLog, setVisibleModalViewLog,
        visibleModalCancelLegacySubscription, setVisibleModalCancelLegacySubscription
    }
}
