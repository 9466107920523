import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {evaluationEnum} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation_enum";

export class TicketEvaluationEntity {
    id!: string;
    idCompany!: string;
    idTicket!: string;
    ticket?: TicketEntity;
    value?: evaluationEnum;
    description?: string;
    date?: string;
    createdAt?: string;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = ['ticket'];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    public static fromJson(json: Record<string, any>): TicketEvaluationEntity {
        return new TicketEvaluationEntity({
            id: json['id'],
            idCompany: json['idCompany'],
            idTicket: json['idTicket'],
            ticket: json['ticket'] ? TicketEntity.fromJson(json['ticket']) : undefined,
            value: json['value'],
            description: json['description'],
            date: json['date'],
            createdAt: json['createdAt'],
        });
    }

    public static ratingToolTips(): string[] {
        return [
            'Muito insatisfeito',
            'Insatisfeito',
            'Contente',
            'Satisfeito',
            'Muito satisfeito',
        ]
    }

    constructor({
                    id,
                    idCompany,
                    idTicket,
                    ticket,
                    value,
                    description,
                    date,
                    createdAt,
                }: {
        id: string;
        idCompany: string;
        idTicket: string;
        ticket?: TicketEntity;
        value?: evaluationEnum;
        description?: string;
        date?: string;
        createdAt?: string;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            idTicket,
            ticket,
            value,
            description,
            date,
            createdAt
        });
    }

    copyWith({
                 id,
                 idCompany,
                 idTicket,
                 ticket,
                 value,
                 description,
                 date,
                 createdAt
             }: {
        id?: string;
        idCompany?: string;
        idTicket?: string;
        ticket?: TicketEntity;
        value?: evaluationEnum;
        description?: string;
        date?: string;
        createdAt?: string;
    }): TicketEvaluationEntity {
        return new TicketEvaluationEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            idTicket: idTicket ?? this.idTicket,
            ticket: ticket ?? this.ticket,
            value: value ?? this.value,
            description: description ?? this.description,
            date: date ?? this.date,
            createdAt: createdAt ?? this.createdAt,
        })
    }
}
