import {observer} from "mobx-react";
import ModalRestore from "@dropDesk/presentation/components/modals/modal_information";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {useModalTickets} from "@dropDesk/presentation/pages/ticket/ui/search/modals/visible_modal_search";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsTickets = observer(
    ({
         ticketNumber,
         userUrlImageProfile,
         userName,
         restoreTicket,
         deleteTicket,
         removeLineSelectRow,
         loading,

     }: {
        ticketNumber: number
        userUrlImageProfile: string,
        userName: string,
        removeLineSelectRow: () => void,
        restoreTicket: () => Promise<void>,
        deleteTicket: () => Promise<void>,
        loading: boolean
    }) => {

        const {
            visibleModalRestore, setVisibleModalRestore, visibleModalDelete, setVisibleModalDelete,
        } = useBetween(useModalTickets);


        return (
            <>
                <ModalRestore
                    open={visibleModalRestore}
                    onRequestClose={() => {
                        setVisibleModalRestore(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Restaurar Atendimento'}
                    textButton={'Restaurar'}
                    onRequestSave={async () => {
                        restoreTicket();
                        setVisibleModalRestore(false);
                    }}
                    nameIcon={ICONS_DROPDESK.restore}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a restauração deste atendimento? Após restaurado o atendimento passa a ser listado para o destino escolhido.`
                    }
                    loading={loading}
                />
                <ModalDelete
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        removeLineSelectRow();
                        setVisibleModalDelete(false);
                    }}
                    textHeaderModal={'Excluir Atendimento'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        deleteTicket();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a exclusão do atendimento ${ticketNumber} ? Após excluído, o atendimento não será mais listável.`
                    }
                    loading={loading}
                    isActionDelete={true}
                />

            </>
        )
    })
export default ModalsTickets;
