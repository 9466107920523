import styled from "styled-components";
import {Input} from 'antd';
import {InputType} from "@dropDesk/presentation/components/inputs/input_search/input_search";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";

export const InputSearch = styled(Input.Search)<InputType>`

  .ant-input {
    background: ${props => props.background};
    color: ${props => props.lettermaincolor};
  }

  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-left: 1px solid ${props => props.border};
    margin-left: 2px;

    &:hover {
      border-color: transparent;
      box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
      outline: none;
      transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
    }
  }

  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 0px;
  }

  &.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    &:hover {
      border-color: transparent;
      box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
      outline: none;
      transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
    }

    &:focus-within {
      border-color: transparent;
      box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
      outline: none;
      transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
    }
  }

  .ant-input-group {
    border: 1px solid ${props => props.border};
    border-radius: ${`${ConstantsStyles.radiusButton}px`};
    background: ${props => props.background};
  }

  .ant-input-affix-wrapper {
    background: transparent;
    border: 1px solid ${props => props.backgroundmainbutton};
  }

  .anticon {
    &.anticon-close-circle {
      color: ${props => props.lettermaincolor};
    }
  }

  .ant-input-clear-icon {
    font-size: 16px;
  }

  .ant-input-affix-wrapper:hover {
    border: 1px solid ${props => props.backgroundmainbutton};
  }

  .ant-input::placeholder {
    color: ${props => props.hint};
  }

  .ant-input-affix-wrapper:focus {
    border: 1px solid ${props => props.backgroundmainbutton};
  }

  .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 4px 4px 0 !important;
  }

  .ant-input-group-addon {
    background: transparent;
    border: none;
  }

  .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.backgroundmainbutton, 0.20)};
  }

  .ant-btn {
    background: ${props => props.backgroundmainbutton};
    border: 1px solid ${props => props.backgroundmainbutton};
  }

  .anticon {
    color: ${props => props.iconcolor};
  }

  .ant-input-search-button {
    height: 40.14px;
  }

  .ant-btn-lg {
    font-size: 18px;
  }
`;
