import {useEffect, useState} from "react";
import {
    isValidCNPJ,
    isValidCPF,
    isValidEmail,
    isValidName,
    isValidNumberTelephone
} from "@dropDesk/utils/helpers/validators";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";
import {ClientJuridicalNature} from "@dropDesk/domain/entities/client/client_enum";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {validateContacts} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_contacts/validate_user";
import {validateAddress} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/validate_address";

export const useValidatorInputClients = (
        client?: ClientEntity,
        isNewChatContact?: boolean
    ) => {

        const [errorMessageSocialName, setErrorMessageSocialName] = useState('');
        const [errorMessageName, setErrorMessageName] = useState('');
        const [errorMessageEmail, setErrorMessageEmail] = useState('');
        const [errorMessageCNPJ, setErrorMessageCNPJ] = useState('');
        const [errorMessageCPF, setErrorMessageCPF] = useState('');
        const [errorMessageTelephone, setErrorMessageTelephone] = useState('');
        const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);

        useEffect(() => {
            validate();
        }, [client]);

        const validate = () => {
            setErrorMessageSocialName('');
            setErrorMessageName('');
            setErrorMessageEmail('');
            setErrorMessageTelephone('');
            setErrorMessageCNPJ('');
            setErrorMessageCPF('');

            if (!client) {
                setIsValidDataForm(false);
                return;
            }

            if (removeMask(client.document ?? '') && !isValidCNPJ(removeMask(client.document ?? '')) && client.juridicalNature === ClientJuridicalNature.juridical) {
                setErrorMessageCNPJ('CNPJ inválido');
                setIsValidDataForm(false);
            } else if (removeMask(client.document ?? '') && !isValidCPF(removeMask(client.document ?? '')) && client.juridicalNature === ClientJuridicalNature.physical) {
                setErrorMessageCPF('CPF inválido');
                setIsValidDataForm(false);

            } else if (!isValidName(client.socialName) && client.juridicalNature === ClientJuridicalNature.juridical) {
                setErrorMessageSocialName('A razão social deve possuir mais de 2 letra');
                setIsValidDataForm(false);
            } else if (!isValidName(client.name)) {
                setErrorMessageName('Nome deve possuir mais de 2 letra');
                setIsValidDataForm(false);
            } else if (client.email && !isValidEmail(client.email)) {
                setErrorMessageEmail('Informe um email válido');
                setIsValidDataForm(false);
            } else if (client.telephone && !isValidNumberTelephone(client.telephone)) {
                setErrorMessageTelephone('Telefone Inválido');
                setIsValidDataForm(false);
            } else if (!isValidContacts(client.users)) {
                setIsValidDataForm(false);
            } else if (!isValidAddress(client.addresses)) {
                setIsValidDataForm(false);
            } else {
                setIsValidDataForm(true);
            }
        }

        const isValidContacts = (users?: UserEntity[]): boolean => {
            if (!users) {
                return false;
            }

            const eligibleUsers = users.filter((user) => user.action != BackendAction.delete);

            if (eligibleUsers.length === 0) {
                return false;
            }

            for (let i = 0; i < eligibleUsers.length; i++) {
                const _user = eligibleUsers[i];
                const isValidDataFormContacts = validateContacts(
                    _user,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    isNewChatContact
                );
                if (isValidDataFormContacts === false) {
                    return false;
                }
            }

            return true;

        }


        const isValidAddress = (address?: AddressEntity[]): boolean => {
            if (!address) {
                return true;
            }

            const eligibleAddress = address.filter((user) => user.action != BackendAction.delete);

            if (eligibleAddress.length === 0) {
                return true;
            }

            for (let i = 0; i < eligibleAddress.length; i++) {
                const _address = eligibleAddress[i];
                const isValidDataFormAddress = validateAddress(_address);
                if (isValidDataFormAddress === false) {
                    return false;
                }
            }

            return true;

        }

        return {
            errorMessageSocialName, setErrorMessageSocialName,
            errorMessageName, setErrorMessageName,
            errorMessageEmail, setErrorMessageEmail,
            errorMessageCNPJ, setErrorMessageCNPJ,
            errorMessageCPF, setErrorMessageCPF,
            errorMessageTelephone, setErrorMessageTelephone,
            isValidDataForm, setIsValidDataForm,
            validate,
        }
    }
;
