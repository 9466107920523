import styled from "styled-components";

type ContainerType = {
    background: string;
    border: string;
};

type TextType = {
    color: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px);
  flex-direction: column;
  background: ${(props) => props.background};
  border-radius: 5px;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

`;
export const ContentInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex: 1;
`;


export const ContentForm = styled.div<ContainerType>`
  display: flex;
  max-width: 768px;
  flex-direction: column;
  overflow: auto;
  background: ${props => props.background};
  border-radius: 5px;
  border: 1px solid ${props => props.border};
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`;

export const TextExport = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
`;


export const ContainerDone = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
`;

export const Title = styled.span<TextType>`
  font-size: 20px;
  color: ${props => props.color};
  text-align: center;
`;


export const ContainerDescriptions = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
`;

export const Description = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
`;


export const ButtonDownload = styled.button<ContainerType>`
  display: flex;
  flex-direction: row;
  border: 0;
  justify-content: space-between;
  height: 33px;
  min-height: 33px;
  width: 120px;
  margin-top: 16px;
  margin-bottom: 11px;
  align-items: center;
  border-radius: 5px;
  transition: background-color 0.2s;
  background: ${props => props.background};

  &:hover {
    filter: brightness(80%);
  }
`;
export const Icon = styled.i<TextType>`
  color: ${props => props.color};
  font-size: 13px;
  padding: 5px;
`;

export const TextDownload = styled.span<TextType>`
  color: ${props => props.color};
  font-size: 13px;
  font-weight: bold;
`;
export const ContainerNewExport = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionList = styled.li<TextType>`
  color: ${props => props.color};
  font-size: 15px;
  margin: 5px 0;
`;
