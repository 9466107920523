import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import {
    useModalsReportTicket
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/ui/modals/handle_change_visible";
import {
    Container,
    ContainerGraphics
} from "./styled";
import GraphicBar from "@dropDesk/presentation/components/graphics/graphic_horizontal_bar";
import {ChartReportTicketEntity} from "@dropDesk/domain/entities/reports/report_ticket/chart_ticket.entity";

const GraphicsReportTicket = observer(
    ({
         chartStatus,
         chartLocation,
         chartPriority,
         chartResolutionTime,
         loading,
         hasData,
         showGroupedData,
         setShowGroupedData
     }: {
        chartStatus: ChartReportTicketEntity[] | undefined,
        chartLocation: ChartReportTicketEntity[] | undefined,
        chartPriority: ChartReportTicketEntity[] | undefined,
        chartResolutionTime: ChartReportTicketEntity[] | undefined,
        hasData: (chart?: ChartReportTicketEntity[]) => boolean
        loading: boolean
        showGroupedData: boolean
        setShowGroupedData: () => void
    }) => {


        const {
            setVisibleModalInformationReport,
            setTextInformationHeader,
            setTextInformationBody,
            setTextHeaderModalInformationReport
        } = useBetween(useModalsReportTicket);

        return (
            <Container>
                <ContainerGraphics>

                    <GraphicBar
                        data={chartStatus ?? []}
                        description={"Atendimento Por Status"}
                        showIconInformation={true}
                        onClickIconInformation={() => {
                            setTextHeaderModalInformationReport('Volume de Atendimentos')
                            setTextInformationHeader('Com o volume geral de atendimentos que a sua organização recebe, é possível ver o número de atendimentos que foram abertos,que estão em atendimento,fechados e cancelados.')
                            setTextInformationBody('Desta forma é possível analisar se é preciso incluir mais atendentes em sua operação, se o seu contact center está como manda o figurino e se o volume de atendimentos que você recebe condiz com o tamanho do seu negócio.')
                            setVisibleModalInformationReport(true)
                        }}
                        loading={loading}
                        hasData={hasData(chartStatus)}
                        showGroupedData={showGroupedData}
                        showIconGrouped={true}
                        toolTipDescriptionGroupedData={showGroupedData ? 'Clique para desagrupar os dados' : 'Clique para agrupar os dados'}
                        onClickUngroupedData={() => setShowGroupedData()}
                    />


                    <GraphicBar
                        data={chartPriority ?? []}
                        description={"Atendimentos por Prioridade"}
                        showIconInformation={true}
                        onClickIconInformation={() => {
                            setTextHeaderModalInformationReport('Atendimentos por Prioridade')
                            setTextInformationHeader('Este gráfico mostra o detalhamento dos atendimentos criados durante o período selecionado com base na prioridade atual.')
                            setTextInformationBody('Você poderá ver quantos atendimentos de prioridade Baixa, Normal, Alta e Urgente foram criados e pode usar o Relatório para descobrir se elas foram resolvidos.')
                            setVisibleModalInformationReport(true)
                        }}
                        loading={loading}
                        hasData={hasData(chartPriority)}
                    />


                </ContainerGraphics>
                <ContainerGraphics>
                    <GraphicBar
                        data={chartLocation ?? []}
                        description={"Atendimentos Por origem Interno/Externo"}
                        showIconInformation={true}
                        onClickIconInformation={() => {
                            setTextHeaderModalInformationReport('Segmentação de Atendimentos')
                            setTextInformationHeader('Uma boa maneira de quantificar as demandas mais recorrentes no serviço de help desk é segmentando cada tipo de solicitação.')
                            setTextInformationBody('Nesse contexto, a métrica em questão serve de base para apontar os tipos de atendimentos que a empresa recebe.')
                            setVisibleModalInformationReport(true)
                        }}
                        loading={loading}
                        hasData={hasData(chartLocation)}
                    />

                    <GraphicBar
                        data={chartResolutionTime ?? []}
                        description={"Tempo de Solução dos Atendimentos"}
                        showIconInformation={true}
                        onClickIconInformation={() => {
                            setTextHeaderModalInformationReport('Tempo de Solução')
                            setTextInformationHeader('O Tempo de Solução dos Atendimentos define quanto tempo o atendimento fica dentro do seu sistema até ser solucionado permanentemente.')
                            setTextInformationBody('O Tempo de Solução dos Atendimentos é bom para ver o quanto a sua equipe de suporte é responsiva em relação ao volume de atendimentos que recebe.')
                            setVisibleModalInformationReport(true)
                        }}
                        loading={loading}
                        hasData={hasData(chartResolutionTime)}
                    />
                </ContainerGraphics>
            </Container>
        )
    })
export default GraphicsReportTicket;
