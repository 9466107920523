import {observer} from "mobx-react";

import React from "react";
import {ContentRequiredFields, Fields, TextRequiredFields} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const RequiredFields = observer(
    ({}: {}) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <ContentRequiredFields color={colors.text}>
                <TextRequiredFields color={colors.text}>Campos obrigatórios</TextRequiredFields>
                <Fields color={colors.text}>Nos dados gerais da empresa, razão social e nome são requeridos e devem
                    conter mais de 2 letras.</Fields>
                <Fields color={colors.text}>Nos dados de contato, o nome é requerido e deve conter mais de 2
                    letras.</Fields>
            </ContentRequiredFields>
        )
    })
export default RequiredFields;
