import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import React from "react";
import {Rating} from 'react-simple-star-rating';

export const RatingStars = (
    {
        onClick,
        iconsCount,
        readonly,
        showTooltip,
        size,
        tooltipArray,
        titleSeparator,
        tooltipDefaultText,
        style
    }: {
        onClick: (value: number) => void
        iconsCount?: number;
        readonly?: boolean;
        showTooltip?: boolean;
        size?: number;
        tooltipArray?: string[]
        titleSeparator?: string;
        tooltipDefaultText?: string;
        style?: React.CSSProperties
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Rating
            onClick={(value) => onClick(value)}
            iconsCount={iconsCount ?? 5}
            readonly={readonly}
            size={size ?? 25}
            emptyColor={colors.hint}
            showTooltip={showTooltip}
            tooltipArray={tooltipArray}
            titleSeparator={titleSeparator ?? 'de'}
            tooltipDefaultText={tooltipDefaultText}
            tooltipStyle={{background: colors.onBackground, color: colors.text}}
            style={style}
            transition={true}
        />
    )
};
