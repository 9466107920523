import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {displayDateToLocale, displayDateToLocaleUTC, formatDateToDDMMYYYY} from "@dropDesk/utils/helpers/date_helper";

export class ExportDataEntity {
    id!: string;
    idCompany!: string;
    table!: string;
    url?: string;
    status!: StatusImport;
    progress!: number;
    rows!: number;
    createdAt?: string;
    extras?: Record<string, string>;
    responseStatus!: number;

    public get done(): boolean {
        return this.status === StatusImport.done;
    }

    public get hasCache(): boolean {
        return ((this.status === StatusImport.done) && (this.table === 'sectors' || this.table === 'clients' || this.table === 'users'));
    }

    public get inProgress(): boolean {
        return this.status === StatusImport.inProgress;
    }

    public get isInit(): boolean {
        return this.status === StatusImport.init || this.status === StatusImport.stoppedWithError;
    }

    public get period(): string | null {
        if (this.extras && this.extras.gte && this.extras.lte) {
            return `${formatDateToDDMMYYYY(new Date(this.extras.gte))} - ${formatDateToDDMMYYYY(new Date(this.extras.lte))}`
        }
        return null;
    }

    constructor({
                    idCompany,
                    id,
                    table,
                    url,
                    extras,
                    status,
                    progress,
                    rows,
                    createdAt,
                    responseStatus
                }: {
        idCompany: string;
        id: string;
        status: StatusImport;
        progress: number;
        rows: number;
        table: string;
        url?: string;
        createdAt?: string;
        extras?: Record<string, string>;
        responseStatus: number;
    }) {
        Object.assign(this, {
            idCompany,
            id,
            status,
            progress,
            rows,
            createdAt,
            table,
            url,
            extras,
            responseStatus
        });
    }

    copyWith({
                 idCompany,
                 id,
                 status,
                 progress,
                 rows,
                 createdAt,
                 table,
                 url,
                 extras,
                 responseStatus
             }: {
        idCompany?: string;
        id?: string;
        status?: StatusImport;
        progress?: number;
        rows?: number;
        responseStatus?: number;
        table?: string;
        url?: string;
        createdAt?: string;
        extras?: Record<string, string>;
    }): ExportDataEntity {
        return new ExportDataEntity({
            idCompany: idCompany ?? this.idCompany,
            id: id ?? this.id,
            status: status ?? this.status,
            progress: progress ?? this.progress,
            rows: rows ?? this.rows,
            createdAt: createdAt ?? this.createdAt,
            table: table ?? this.table,
            url: url ?? this.url,
            extras: extras ?? this.extras,
            responseStatus: responseStatus ?? this.responseStatus,
        })
    }
}
