import styled from "styled-components";
import {
    ContainerType,
    TextType
} from "@dropDesk/presentation/components/headers/header_configuration/head_configuration";


export const Container = styled.div<ContainerType>`
  padding: 8px 16px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  border-radius: 7px 7px 0 0;
  width: 100%;
  background: ${props => props.background};
`;

export const Text = styled.strong<TextType>`
  font-size: 17px;
  color: ${(props) => props.color};
  letter-spacing: .8px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
`;

