import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";
import {from, Observable, Observer} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {AxiosResponse} from "axios";
import {gql} from "@apollo/client";
import {apolloClient} from "@dropDesk/data/clients/apollo.client";
import {getListSubscriptionResponse} from "@dropDesk/domain/entities/common/get_list_subscription_response";

@injectable()
export abstract class NoticesRemoteDataSource {
    public abstract set(notices: NoticesEntity): Promise<NoticesEntity>;

    public abstract list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        filterByDestiny: NoticeDestiny | null
    ): Promise<ListPaginationEntity<NoticesEntity>>;

    public abstract restore(notices: NoticesEntity[]): Promise<void>;

    public abstract delete(notices: NoticesEntity[]): Promise<void>;

    public abstract findByPK(id: string): Promise<NoticesEntity>;

    public abstract listNoticesByUser(page: number, limit: number): Promise<ListPaginationEntity<NoticesEntity>>;

}

@injectable()
export class NoticesRemoteDataSourceImpl implements NoticesRemoteDataSource {

    constructor() {
    }

    baseNoticesUrl: string = 'notices/';
    baseSetNoticesUrl: string = `${this.baseNoticesUrl}set`;
    baseRestoreNoticesUrl: string = `${this.baseNoticesUrl}restore/`;
    baseListByUserUrl: string = `${this.baseNoticesUrl}listNoticesByUser`;


    public async list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        filterByDestiny: NoticeDestiny | null
    ): Promise<ListPaginationEntity<NoticesEntity>> {
        return new Promise<ListPaginationEntity<NoticesEntity>>(async (resolve, reject) => {
            try {

                const response = await api.get(
                    `${this.baseNoticesUrl}?searchParam=${searchParam ?? ''}&page=${page ?? ''}&limit=${limit ?? ''}&type=${listOnlyDeleted ? 'deleted' : ''}&filterByDestiny=${filterByDestiny ?? ''}`
                );

                const result = new ListPaginationEntity<NoticesEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: NoticesEntity) => new NoticesEntity({...entry})),
                });

                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public set(notice: NoticesEntity): Promise<NoticesEntity> {
        return new Promise<NoticesEntity>(async (resolve, reject) => {
            try {
                const response = await api.post(this.baseSetNoticesUrl, notice.toJson());
                return resolve(response.data);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public restore(notices: NoticesEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < notices.length; i++) {
                    await api.patch(this.baseRestoreNoticesUrl + notices[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public delete(notices: NoticesEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < notices.length; i++) {
                    await api.delete(this.baseNoticesUrl + notices[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public findByPK(id: string): Promise<NoticesEntity> {
        return new Promise<NoticesEntity>(async (resolve, reject) => {
            try {

                const response = await api.get(this.baseNoticesUrl + id);
                const notice = new NoticesEntity({
                    ...response.data
                })
                return resolve(notice);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public listNoticesByUser(page: number, limit: number): Promise<ListPaginationEntity<NoticesEntity>> {
        return new Promise<ListPaginationEntity<NoticesEntity>>(async (resolve, reject) => {
            try {

                const response = await api.get(
                    `${this.baseListByUserUrl}?page=${page ?? ''}&limit=${limit ?? ''}`
                );

                const result = new ListPaginationEntity<NoticesEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: NoticesEntity) => new NoticesEntity({...entry})),
                });

                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


}
