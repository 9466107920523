import styled from "styled-components";

type TextType = {
    color: string;
}

type ContainerType = {
    background: string;
}


export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1.3;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  overflow: auto;
  @media screen and (max-width: 700px) {
    display: none !important;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ContentImage = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const ContentTitle = styled.div`
  display: flex;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 16px;
`;
export const Title = styled.span<TextType>`
  width: 100%;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.color};
`;
export const SubTitle = styled.span<TextType>`
  width: 100%;
  text-align: center;
  margin-top: 15px;
  line-height: 20px;
  font-size: 16px;
  color: ${(props) => props.color};
`;

export const Image = styled.img`
  display: flex;
  width: auto;
  height: 450px;
  pointer-events: none;
`;

