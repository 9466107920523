import DetailSubscription from "@dropDesk/presentation/components/detail_subscription";
import React from "react";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {Container} from "./styled";
import {observer} from "mobx-react";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";
import TableChangeHistory from "src/presentation/pages/subscription/ui/detail_subscription/history_change/table";

const Logs = observer((
    {
        tableSubscriptionLogs,
        loading,
        getDataFromPageLogs,
        onSelectedRowLog
    }: {
        tableSubscriptionLogs: ListPaginationEntity<CompanyLogsEntity>,
        loading: boolean
        getDataFromPageLogs: (page: number) => void,
        onSelectedRowLog: (log: CompanyLogsEntity) => void
    }
) => {

    return (
        <DetailSubscription
            title={'Histórico de alterações'}
            content={
                <Container>
                    <TableChangeHistory
                        tableSubscriptionLogs={tableSubscriptionLogs}
                        loading={loading}
                        getDataFromPageLogs={getDataFromPageLogs}
                        onSelectedRowLog={onSelectedRowLog}
                    />
                </Container>
            }
        />
    );
});
export default Logs;
