import styled from "styled-components";

type ContainerType = {
    background: string;
    heightShowingHeaderInfo: number;
};

type TextAverageTimeType = {
    color: string;
}
export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 101px - ${props => `${props.heightShowingHeaderInfo}px`});
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
  padding-right: 2px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 0;
`;

export const ContainerAverageTimes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;


export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const TextAverageTime = styled.span<TextAverageTimeType>`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.color};
  margin-right: 5px;
`;
export const ContentTextRateScale = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
