export abstract class DropDeskException {
    abstract message: string;
}

export class SignInError extends DropDeskException {
    message: string;

    constructor(messageError: string) {
        super();
        this.message = messageError;
    }
}


export class ServerError extends DropDeskException {
    message: string;

    constructor(messageError: string) {
        super();
        this.message = messageError;
    }
}

export class DropDeskError extends DropDeskException {
    message: string;

    constructor(messageError: string) {
        super();
        this.message = messageError;
    }
}

export class SubscriptionException extends DropDeskException {
    message: string;

    constructor(messageError: string) {
        super();
        this.message = messageError;
    }
}
