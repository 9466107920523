import {injectable} from "inversify";
import {FiltersEditorEntity} from "@dropDesk/domain/entities/image_editor/filters_editor.entity";
import {RotateEditorEntity} from "@dropDesk/domain/entities/image_editor/rotate_editor.entity";
import {CropEditorEntity} from "@dropDesk/domain/entities/image_editor/crop_editor.entity";
import {PencilEditorEntity} from "@dropDesk/domain/entities/image_editor/pencil_editor.entity";

@injectable()
export abstract class ImageEditorRepository {

}


