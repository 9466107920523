import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, Content, FooterRecaptcha, Text, TextLink, TextTab} from "./styled";
import React, {useEffect} from "react";
import Header from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/header";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import TabsLeftStyle from "@dropDesk/presentation/components/tabs/left_style";
import CreditCardPaymentMethod
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card";
import {TabsPayment} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {CardEntity, TokenizeCardEntity,} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import PixPaymentMethod from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/pix";
import InvoicePaymentMethod
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/invoice";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {observer} from "mobx-react";

const PaymentMethod = observer((
    {
        newTokenizeCard,
        onUpdateTokenizeCard,
        loading,
        pay,
        setActiveTabPayment,
        cardSaved,
        onClickPayCreditCardSaved,
        backPreviousScreenExternalScreen,
        isEditExternal,
        isReprovedPayment,
        currentPayment,
        activeTabPayment,
        makePaymentMethod,
        resetPaymentMethod,
    }: {
        newTokenizeCard: TokenizeCardEntity | null
        onUpdateTokenizeCard: (newTokenizeCard: TokenizeCardEntity) => void
        loading: boolean
        pay: () => void
        setActiveTabPayment: (value: TabsPayment) => void
        cardSaved?: CardEntity
        onClickPayCreditCardSaved: () => void
        backPreviousScreenExternalScreen?: () => void,
        activeTabPayment: TabsPayment | null,
        isEditExternal?: boolean
        isReprovedPayment?: boolean
        currentPayment?: TabsPayment,
        makePaymentMethod: () => void
        resetPaymentMethod: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    useEffect(() => {
        makePaymentMethod();
        return () => {
            resetPaymentMethod();
        }
    }, []);

    const tabs = [
        {
            label:
                <TextTab>
                    <DropDeskIcon icon={ICONS_DROPDESK.creditCard} size={15} style={{marginLeft: 5, marginRight: 5}}/>
                    Cartão de Crédito
                </TextTab>,
            key: TabsPayment.creditCard,
            children: <CreditCardPaymentMethod
                newTokenizeCard={newTokenizeCard!}
                onUpdateTokenizeCard={onUpdateTokenizeCard}
                loading={loading}
                pay={pay}
                cardSaved={cardSaved}
                onClickPayCreditCardSaved={onClickPayCreditCardSaved}
                isEditExternal={isEditExternal}
                isReprovedPayment={isReprovedPayment}
            />,
        },
        {
            label:
                <TextTab>
                    <DropDeskIcon icon={ICONS_DROPDESK.pix} size={15} style={{marginLeft: 5, marginRight: 5}}/>
                    Pix
                </TextTab>,
            key: TabsPayment.pix,
            children: <PixPaymentMethod
                loading={loading}
                pay={pay}
                isEditExternal={isEditExternal}
            />,
        },
        {
            label:

                <TextTab>
                    <DropDeskIcon
                        color={colors.text}
                        icon={ICONS_DROPDESK.billet} size={15}
                        style={{marginLeft: 5, marginRight: 5}}
                    />
                    Boleto
                </TextTab>
            ,
            key: TabsPayment.boleto,
            disabled: false,
            children:
                <InvoicePaymentMethod
                    loading={loading}
                    pay={pay}
                    isEditExternal={isEditExternal}
                    disabled={false}
                />
        },
    ];

    return (
        <Container
            background={colors.onBackground}
            isDarkTheme={colors.brightness === Brightness.dark}
            border={colors.border}
        >
            <Header backPreviousScreenExternalScreen={backPreviousScreenExternalScreen}/>
            {newTokenizeCard && activeTabPayment && <Content bordercolor={colors.delicateBackground}>
                <TabsLeftStyle
                    defaultActiveKey={activeTabPayment ?? undefined}
                    withMarginInTabs={true}
                    destroyInactiveTabPane={true}
                    onChange={(key) => setActiveTabPayment(key as TabsPayment)}
                    items={!!isEditExternal && !!currentPayment && currentPayment !== TabsPayment.creditCard ? tabs.filter((entry) => entry.key !== currentPayment) : tabs}
                />
            </Content>}
            {!isEditExternal && <FooterRecaptcha>
                <Text size={14} color={colors.text}>Esse site é protegido por reCAPTCHA e os
                    <TextLink
                        size={13}
                        color={colors.textLink}
                        href={`https://policies.google.com/terms`}
                        target="_blank"
                        style={{color: colors.textLink}}
                    >&nbsp;Termos de Serviço&nbsp;</TextLink>e<TextLink
                        size={13}
                        color={colors.textLink}
                        href={`https://policies.google.com/privacy`}
                        target="_blank"
                        style={{color: colors.textLink}}
                    >&nbsp;Política de Privacidade&nbsp;</TextLink>do Google se aplicam</Text>
            </FooterRecaptcha>}
        </Container>
    );
});
export default PaymentMethod;
