import styled from "styled-components";
import {ContainerType} from "@dropDesk/presentation/components/modals/modal_search/modal_search";
import React from "react";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 90vh;
  max-height: 100vh;
`;
export const Content = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  background: ${props => props.background};
  padding: 16px;
  flex-direction: column;
  border-radius: 0 0 7px 7px;
`;
export const ContentTable = styled.div`
  display: flex;
  flex: 1;
  padding: 16px 0 0 0;
  flex-direction: column;
`;
export const ContentForm = styled.div`
  display: flex;
  padding: 32px 0 0 0;
  align-items: center;
  justify-content: flex-start;
`;

