import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";

@injectable()
export class DeleteTicketsUseCase {
  private _repository: TicketRepository;

  constructor(@inject(TicketRepository) repository: TicketRepository) {
    this._repository = repository;
  }

  public call(ticket: TicketEntity[]): Promise<void> {
    return this._repository.delete(ticket);
  }
}

