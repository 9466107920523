import {CardEntity, TokenizeCardEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {DropDeskError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {DropDeskErrorEnum} from "@dropDesk/domain/entities/exceptions/dropdesk_error_enum";
import {removeSpecialChars} from "@dropDesk/utils/helpers/string_helper";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {getFirstNameLastName} from "@dropDesk/utils/helpers/card_helper";

export class PaymentServiceHandler {

    static async setup(): Promise<void> {
        try {
            const isDevMode = process.env.MODE === 'development' || process.env.MODE === 'uat';
            this.setAccountId();
            if (isDevMode) {
                this.setTestMode(true);
            }
            this.Iugu().setup();
        } catch (error) {
            console.error('IUGU SETUP ERROR', error);
            return Promise.resolve();
        }
    }

    private static setAccountId = () => {
        this.Iugu().setAccountID(process.env.ACCOUNT_ID_IUGU);
    }

    private static setTestMode = (value: boolean) => {
        this.Iugu().setTestMode(value);
    }

    private static Iugu(): any {
        const newWindow = window as any;
        const Iugu = newWindow.Iugu;
        return Iugu;
    }

    public static getTokenizedCard = async (creditCard?: TokenizeCardEntity): Promise<CardEntity | null> => {
        return new Promise<CardEntity | null>(async (resolve, reject) => {
            try {
                if (!creditCard) {
                    return resolve(null);
                }
                this.isValidCreditCardNumberOrThrow(creditCard.number);
                this.isValidExpirationOrThrow(creditCard.expirationDate);
                const brandString = this.getBrand(creditCard.number);
                this.isValidCVV(creditCard.cvv, brandString);
                this.verifyAdBlockError();
                const {token, displayNumber, bin} = await this.getCreditCardToken(creditCard);
                const brand = creditCard.getBrand(brandString);
                const newCardEntity = new CardEntity({
                    bin,
                    brand,
                    displayNumber,
                    expirationDate: creditCard.expirationDate,
                    idCompany: getIdCompanyByLocalStorage(),
                    token
                });
                return resolve(newCardEntity);
            } catch (error) {
                return reject(error);
            }
        })
    }

    private static getCreditCardToken = (creditCard: TokenizeCardEntity): Promise<{
        token: string,
        displayNumber: string,
        bin: string
    }> => {
        return new Promise<{ token: string, displayNumber: string, bin: string }>(async (resolve, reject) => {
            try {
                const iuguClient = this.Iugu();
                const expirationDateArray = creditCard.expirationDate.split('/');
                const month = expirationDateArray[0];
                const year = expirationDateArray[1];
                const objectName = getFirstNameLastName(creditCard.holderName);
                if (!objectName) {
                    return reject(new DropDeskError(DropDeskErrorEnum.creditCardInvalidHolderNome));
                }
                const objectCreditCard = iuguClient.CreditCard(creditCard.number,
                    month, year, objectName!.firstName, objectName!.lastName, creditCard.cvv);

                return iuguClient.createPaymentToken(objectCreditCard, function (response: any) {
                    if (response.errors) {
                        const messageError = !response ? 'unknown' : response.errors['record_invalid'] ?? response.erros;
                        const error = messageError.includes('Number is invalid') ? DropDeskErrorEnum.creditCardInvalidNumber : DropDeskErrorEnum.creditCardInvalid;
                        return reject(new DropDeskError(error));
                    } else {
                        return resolve({
                            token: response.id,
                            displayNumber: response.extra_info.display_number,
                            bin: response.extra_info.bin
                        });
                    }
                });
            } catch (error) {
                return reject(error);
            }
        })

    }

    private static verifyAdBlockError = (): void => {
        if (this.Iugu().utils.isBlockedByAdBlock()) {
            throw new DropDeskError(DropDeskErrorEnum.adBlockError);
        }
    }

    private static getBrand = (number: string): string => {
        return this.Iugu().utils.getBrandByCreditCardNumber(number);
    }

    private static isValidCVV = (cvv: string, brand: string): void => {
        if (!this.Iugu().utils.validateCVV(cvv, brand)) {
            throw new DropDeskError(DropDeskErrorEnum.creditCardInvalidCVV);
        }
    }

    private static isValidCreditCardNumberOrThrow = (number: string): void => {
        if (!this.Iugu().utils.validateCreditCardNumber(number)) {
            throw new DropDeskError(DropDeskErrorEnum.creditCardInvalidNumber);
        }
    }

    private static isValidExpirationOrThrow = (expiration: string): void => {
        if (!this.Iugu().utils.validateExpirationString(expiration)) {
            throw new DropDeskError(DropDeskErrorEnum.creditCardExpired);
        }
    }
}
