import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ResponseStandardRepository} from "@dropDesk/domain/repositories/response_standard/response_standard.repository";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";

@injectable()
export class FindByPKResponseStandardUseCase {
  private _repository: ResponseStandardRepository;

  constructor(@inject(ResponseStandardRepository) repository: ResponseStandardRepository) {
    this._repository = repository;
  }

  public call(id: string): Promise<ResponseStandardEntity> {
    return this._repository.findByPK(id);
  }
}

