import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
    color: string;
}


export const ContainerAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonDesconnected = styled.button<ContainerType>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: ${props => props.background};
  color: ${props => props.color};
  border-radius: 5px;
  padding: 2px 7px;
  margin: 0 5px;
  font-weight: bold;
  font-size: 14px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.background, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  border: 0;
`;
