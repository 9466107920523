import styled from "styled-components";

type ContainerType = {
    background: string;
};

type ContainerGraphicType = {
    background: string;
    bordercolor: string;
};


type TextType = {
    color: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 32px;
`;
export const ContainerGraphics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContentInputAndFilter = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const ContentExportImport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;
export const ContentFilter = styled.div<ContainerType>`
  display: flex;
  background: ${(props) => props.background};
  height: 75px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  position: relative;
  margin-top: 1px;
`;



