import {observer} from "mobx-react";
import {useBetween} from "use-between";
import {useModalFormClients} from "@dropDesk/presentation/pages/client/ui/my_clients/form/modals/handle_change_visible";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const ModalsFormClients = observer((
    {
        onDeleteClient,
        loading,
        client
    }: {
        onDeleteClient: () => Promise<void>,
        loading: boolean,
        client?: ClientEntity
    }
) => {
    const {
        visibleModalDelete,
        setVisibleModalDelete,
    } = useBetween(useModalFormClients);


    return (
        <>
            <ModalDelete
                open={visibleModalDelete}
                onRequestClose={() => {
                    setVisibleModalDelete(false);
                }}
                textHeaderModal={'Excluir Empresa'}
                textButton={'Excluir'}
                onRequestSave={async () => {
                    onDeleteClient();
                    setVisibleModalDelete(false);
                }}
                nameIcon={ICONS_DROPDESK.delete}
                sizeIcon={130}
                textInformation={`A exclusão da empresa ${client?.name} não apaga seus atendimentos, avaliações e nenhuma outra ação já feita por ele, apenas a empresa é excluída não sendo mais listada e sem acesso ao painel do cliente.`}
                loading={loading}
                isActionDelete={true}
            />


        </>
    )
})
export default ModalsFormClients;
