import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
    border: string;
    isDarkTheme: boolean;
}

type TextType = {
    color: string;
    size: number;
}

type iconTab = {
    color: string;
}

type BorderType = {
    bordercolor: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-radius: 5px;
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const Content = styled.div<BorderType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 16px 8px 16px;
  border: 6px solid ${props => props.bordercolor};
  border-radius: 2px;
`;

export const TextTab = styled.span`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px 0 10px;
`;


export const FooterRecaptcha = styled.div`
  padding: 4px 16px;
`;

export const TextLink = styled.a<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${props => props.color};

  &:hover {
    text-decoration: underline;
  }
`;
