import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";

@injectable()
export abstract class ResponseStandardRepository {

  public abstract list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean): Promise<ListPaginationEntity<ResponseStandardEntity>>;

  public abstract set(responseStandard: ResponseStandardEntity): Promise<ResponseStandardEntity>;

  public abstract restore(responseStandard: ResponseStandardEntity[]): Promise<void>;

  public abstract delete(responseStandard: ResponseStandardEntity[]): Promise<void>;

  public abstract findByPK(id: string): Promise<ResponseStandardEntity>;
}
