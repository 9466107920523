import styled from "styled-components";

type BorderType = {
    bordercolor: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const ContentCard = styled.div`
  display: flex;
  width: 290px;
`;
export const ContentInputs = styled.div`
  display: flex;
  flex: 1;
`;

export const ContentForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 12px;
`;

export const ContentDataInput = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentDataInputRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerCreditCardSaved = styled.div<BorderType>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 8px;
  border: 1px solid ${props => props.bordercolor};
  border-radius: 5px;
  height: 70px;
  margin-bottom: 10px;
`;

export const ContentInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentButtonPay = styled.div`
  display: flex;
`;

export const ContentInfoHolder = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentButtonPayCreditCardSave = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
`;
