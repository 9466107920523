import styled from "styled-components";

type ContainerType = {
    background: string;
    border: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 32px;
  overflow: hidden;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
  min-width: 768px;
  overflow: hidden;
`;


export const TextTab = styled.span`
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: .7px;
  font-weight: bold;
  height: 50px;
  padding: 0 10px 0 10px;
`;
