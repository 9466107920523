import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
    borderColor: string;
}

type TextType = {
    color: string;
}

export const Container = styled.div`
  border-radius: 5px;
  box-shadow: 0 3px 8px ${hexToRGBA(SharedColors.loading, 0.6)};
  top: 60px;
  right: 60px;
  position: absolute;
  z-index: 200;
  cursor: auto;
`;
export const Header = styled.div<ContainerType>`
  display: flex;
  height: 50px;
  min-height: 50px;
  align-items: center;
  border-radius: 5px 5px 0 0;
  padding-right: 16px;
  padding-left: 16px;
  flex-direction: row;
  justify-content: space-between;
  background: ${(props) => props.background};
  border-bottom: 1px solid ${props => props.borderColor};
  max-height: 50px;
`;
export const TextNotification = styled.span<TextType>`
  color: ${(props) => props.color};
  font-size: 18px;
  font-weight: bold;
`;
export const Content = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  background: ${(props) => props.background};
  border-radius: 0px 0px 5px 5px;
  padding: 8px;
  overflow: auto;
  max-height: 500px;
`;
export const ContentNotices = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
export const TextWithoutNotification = styled.span<TextType>`
  color: ${(props) => props.color};
  font-size: 13px;
  max-width: 290px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
