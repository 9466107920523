import {observer} from "mobx-react";
import React, {MutableRefObject} from "react";

import {
    TextHeader,
    PaddingFilters,
    ContentTextHeader,
    ContentFilterAndInput,
    ContentInput,
    ContentFilter
} from './styled';
import {Container} from "@dropDesk/presentation/pages/task/ui/header/styled";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {Orders, Status, Type} from "@dropDesk/domain/entities/task/task_enum";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ordersFilterMap, statusFilterMap, typesFilterMap} from "@dropDesk/domain/entities/task/task_maps";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const Header = observer(
    ({
         totalTasks,
         filterStatusValue,
         filterTypeValue,
         filterOrderValue,
         setFilterStatus,
         setOrder,
         setType,
         onUpdate,
         task,
         onCreate,
         disabled,
         referenceInput
     }: {

        totalTasks: number
        filterStatusValue: Status
        filterTypeValue: Type
        filterOrderValue: Orders
        setFilterStatus: (value: Status) => void
        setOrder: (value: Orders) => void
        setType: (value: Type) => void
        onUpdate: (task: TaskEntity) => void
        onCreate: () => void
        task: TaskEntity
        disabled: boolean
        referenceInput: MutableRefObject<HTMLInputElement | null>
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string }
        }) => {

            const _task = task.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,

            });
            onUpdate(_task);

        };

        const _onCreate = () => {
            if (!!task.description) {
                onCreate();
            }
        }

        return (
            <Container>
                <ContentTextHeader>
                    <TextHeader color={colors.text}>Lista de
                        Tarefas {totalTasks > 0 ? `(${totalTasks})` : null}</TextHeader>
                </ContentTextHeader>
                <ContentFilterAndInput>
                    <ContentInput
                        onSubmit={(event) => {
                            event.preventDefault();
                            _onCreate();
                        }}
                    >
                        <InputWithBorder
                            reference={referenceInput}
                            name={"description"}
                            size={40}
                            marginTop={20}
                            usedBorder={false}
                            nameIconLeft={ICONS_DROPDESK.plusFill}
                            disabled={disabled}
                            placeHolder={"Adicione suas tarefas"}
                            value={task.isUnsaved() ? task.description : ''}
                            onChange={handleAllChanges}
                            onClickLeftIcons={() => _onCreate()}
                            sizeLeftIcons={17}
                            iconLeftIsMain={true}
                            backgroundColor={colors.background}
                        />
                    </ContentInput>
                    <ContentFilter>

                        <PaddingFilters>
                            <SelectSearchMain<Status>
                                size={37}
                                width={120}
                                label={"Status"}
                                placeHolder={"Selecione"}
                                options={statusFilterMap}
                                onChange={(value) => {
                                    setFilterStatus(value);
                                }}
                                backgroundColor={colors.background}
                                value={filterStatusValue}
                            />
                        </PaddingFilters>


                        <PaddingFilters>
                            <SelectSearchMain<Type>
                                size={37}
                                width={170}
                                label={"Ordenar por"}
                                placeHolder={"Selecione"}
                                options={typesFilterMap}
                                onChange={(value) => {
                                    setType(value);
                                }}
                                backgroundColor={colors.background}

                                value={filterTypeValue}
                            />
                        </PaddingFilters>

                        <PaddingFilters>
                            <SelectSearchMain<Orders>
                                size={37}
                                width={135}
                                label={"Ordenação"}
                                placeHolder={"Selecione"}
                                options={ordersFilterMap}
                                onChange={(value) => {
                                    setOrder(value);
                                }}
                                backgroundColor={colors.background}
                                value={filterOrderValue}
                            />
                        </PaddingFilters>
                    </ContentFilter>

                </ContentFilterAndInput>
            </Container>
        )
    })
export default Header;
