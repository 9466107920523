import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {RemoteStorageDatasource} from "@dropDesk/data/data_source/remote_storage/remote_storage_datasource";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";

@injectable()
export abstract class AddressRemoteDataSource {

  public abstract searchAddress(address: string): Promise<AddressEntity>;


}

@injectable()
export class AddressRemoteDataSourceImpl implements AddressRemoteDataSource {
  private _remoteDataSource: RemoteStorageDatasource;

  constructor(
    @inject(RemoteStorageDatasource) dataSource: RemoteStorageDatasource,
  ) {
    this._remoteDataSource = dataSource;
  }

  baseAddressUrl: string = '/localization/geocode/';


  public async searchAddress(address: string): Promise<AddressEntity> {
    return new Promise<AddressEntity>(async (resolve, reject) => {
      try {

        const response = await api.get(
          `${this.baseAddressUrl}${address ?? ''}`
        );
        const _address = new AddressEntity({
          ...response.data[0]
        })

        return resolve(_address);
      } catch (error) {
        return reject(parseServerError(error));
      }
    })
  }

}
