import React from 'react';
import {IconPropsTypes} from './icons.d';
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Icon} from "@dropDesk/storage/icons/styled";

const DropDeskIcon = (observer((props: IconPropsTypes) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const realSize = props.size ?? 14;
    const defaultStyle = {...props?.style, fontSize: realSize, color: props.color ?? colors.text};
    const {color, ...restProps} = defaultStyle;
    const realStyle = props.removeColor ? restProps : defaultStyle;

    return (
        <Icon
            className={`icon-${props.icon}`}
            style={realStyle}
            useDarkenInHover={props.useDarkenInHover === true}
            onClick={props.onClick}
        />
    )
}));

export default DropDeskIcon;
