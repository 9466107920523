export enum Type {
  orderbydone = 'orderbydone',
  orderbydate = 'orderbydate',
}

export enum Orders {
  asc = 'asc',
  desc = 'desc',
}

export enum Status {
  done = 'done',
  undone = 'undone',
  all = 'all',
}

export enum Actions {
  done = 'done',
  undone = 'undone',
}
