import styled from 'styled-components';

type IconType = {
    color: string;
    fontsize: number;
    disabled: boolean;
}

type TextType = {
    color: string;
}

type ContainerType = {
    border: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  min-height: 50px;
  height: 50px;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${props => props.border}
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  cursor: ${(props) => `${props.disabled ? 'no-drop' : 'pointer'}`};

  &:hover {
    filter: brightness(80%);
  }
`;
export const ContentEditor = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;


export const TextFileName = styled.span<TextType>`
  font-size: 17px;
  font-weight: bold;
  color: ${props => props.color};
  width: 668px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;




