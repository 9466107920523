import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {PaymentStatus} from "@dropDesk/domain/entities/payment/payment.enum";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";

@injectable()
export class ListInvoicesSubscriptionUseCase {
    private _repository: SubscriptionRepository;

    constructor(@inject(SubscriptionRepository) repository: SubscriptionRepository) {
        this._repository = repository;
    }

    public call(page: number, limit: number, status?: PaymentStatus): Promise<ListPaginationEntity<PaymentEntity>> {
        return this._repository.list(page, limit, status);
    }
}

