import styled, {keyframes} from "styled-components";

type TitleType = {
    color: string;
}

type ReplyType = {
    background: string;
    bordercolor: string;
}

export const ContainerReplyMessage = styled.div<ReplyType>`
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 5px;
  background: ${props => props.background};
  border-left: 3px solid ${props => props.bordercolor};
  padding: 2px 5px;
  margin: 0px 0px 0.3rem 0px;
  font-size: 13px;
  cursor: pointer;
  transition: 200ms;
  user-select: none;

  &:hover {
    filter: brightness(80%);
  }
`;

export const ContentReplyMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3px 0;
  flex: 1;
`;

export const ReplyTitle = styled.div<TitleType>`
  font-size: 13px;
  color: ${props => props.color};
`;

export const ContentDescription = styled.div<TitleType>`
  color: ${props => props.color};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  flex-direction: row;
`;

export const ContentImage = styled.div`
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

