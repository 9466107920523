import {observer} from "mobx-react";
import React from "react";
import ModalRestore from "@dropDesk/presentation/components/modals/modal_information";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import ModalUseProblemStatic from "@dropDesk/presentation/components/modals/modal_information";
import ModalNotUseProblemStatic from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {
    useModalSearchTicketsDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/handle_change_visible";
import ModalCrudTicketsDescriptionStatic
    from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/modal_crud";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsTicketsDescriptionStatic = observer(
    ({
         ticketsDescriptionStatic,
         onUpdate,
         restoreTicketsDescriptionStatic,
         deleteTicketsDescriptionStatic,
         removeLineSelectRow,
         loading,
         handleVisibleModalNewTicketsDescriptionStatic,
         set,
     }: {
        ticketsDescriptionStatic?: TicketsDescriptionStaticEntity
        onUpdate: (ticketDescriptionStatic: TicketsDescriptionStaticEntity) => void,
        restoreTicketsDescriptionStatic: () => Promise<void>,
        deleteTicketsDescriptionStatic: () => Promise<void>,
        removeLineSelectRow: () => void,
        loading: boolean,
        handleVisibleModalNewTicketsDescriptionStatic: (visible: boolean, id: string) => void,
        set: (onSuccess: (key: string) => void) => Promise<void>
    }) => {

        const {
            visibleModalDelete, setVisibleModalDelete,
            visibleModalRestore, setVisibleModalRestore,
        } = useBetween(useModalSearchTicketsDescriptionStatic);

        return (
            <>
                <ModalRestore
                    open={visibleModalRestore}
                    onRequestClose={() => {
                        setVisibleModalRestore(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Restaurar Problema'}
                    textButton={'Restaurar'}
                    onRequestSave={async () => {
                        restoreTicketsDescriptionStatic();
                        setVisibleModalRestore(false);
                    }}
                    nameIcon={ICONS_DROPDESK.restore}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a restauração deste problema?`
                    }
                    loading={loading}
                />
                <ModalDelete
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        setVisibleModalDelete(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Excluir Problema'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        deleteTicketsDescriptionStatic();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a exclusão deste problema?`
                    }
                    loading={loading}
                    isActionDelete={true}
                />
                <ModalCrudTicketsDescriptionStatic
                    onUpdate={onUpdate}
                    ticketsDescriptionStatic={ticketsDescriptionStatic}
                    loading={loading}
                    handleVisibleModalNewTicketsDescriptionStatic={handleVisibleModalNewTicketsDescriptionStatic}
                    set={set}
                />

            </>
        )
    })
export default ModalsTicketsDescriptionStatic;
