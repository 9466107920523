import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {PlanEntity} from "@dropDesk/domain/entities/plan/plan_entity";

@injectable()
export class ListPlansSubscriptionUseCase {
    private _repository: SubscriptionRepository;

    constructor(@inject(SubscriptionRepository) repository: SubscriptionRepository) {
        this._repository = repository;
    }

    public call(listPlansLegacy: boolean): Promise<PlanEntity[]> {
        return this._repository.listPlan(listPlansLegacy);
    }
}

