import HeaderModal from "../../headers/header_modal";
import {Container, Content, ContentExplication, TextExplication} from "./styled";
import React, {useState} from "react";
import {
    ModalTransferTicketProps
} from "@dropDesk/presentation/components/modals/modal_transfer_ticket/modal_transfer_ticket";
import ModalSearchUser from "@dropDesk/presentation/components/modals/modal_search_user";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import ModalSearchSector from "@dropDesk/presentation/components/modals/modal_search_sector";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {
    ContentDataInput,
    ContentInput
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_clients/styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {Tooltip} from "antd";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {newMessage} from "@dropDesk/presentation/pages/chat/controller/new_message";
import {TextMessageEntity} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


export default function ModalTransferTicket(props: ModalTransferTicketProps) {
    const initialStateMessage: TicketMessageEntity = newMessage();
    const [visibleModalSearchUser, setVisibleModalSearchUser] = useState<boolean>(false);
    const [visibleModalSearchSector, setVisibleModalSearchSector] = useState<boolean>(false);
    const [user, setUser] = useState<UserEntity | undefined>(undefined);
    const [sector, setSector] = useState<SectorEntity | undefined>(undefined);
    const [message, setMessage] = useState<TicketMessageEntity>(initialStateMessage);

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ModalMain
            open={props.visible}
            onRequestClose={props.onClose}
            width={470}
            renderComponent={
                <Container background={colors.onBackground}>
                    <HeaderModal
                        disabled={props.loading}
                        letter={"Transferir Atendimento"}
                        nameIcon={ICONS_DROPDESK.close}
                        onClick={props.onClose}
                    />
                    <Content>
                        <ContentExplication>
                            <TextExplication color={colors.text}>
                                Atenção: ao transferir um atendimento sem selecionar o atendente, o atendimento ficará
                                em aberto no setor selecionado,
                                caso selecione um atendente, o atendimento irá direto para o mesmo.
                            </TextExplication>

                        </ContentExplication>

                        <ContentDataInput>
                            <ContentInput style={{marginRight: 20}}>
                                <InputWithBorder
                                    required={true}
                                    name='sector'
                                    size={37}
                                    onClick={() => {
                                        setVisibleModalSearchSector(true);
                                    }}
                                    readOnly={true}
                                    disabled={props.loading}
                                    placeHolder={"Selecione o setor"}
                                    nameIconRight={ICONS_DROPDESK.downArrow}
                                    messageError={!sector ? "Selecione o Setor" : ""}
                                    sizeRightIcons={14}
                                    marginRightRightIcons={10}
                                    label={"Setor"}
                                    value={sector?.realName(appController.user!) ?? ''}
                                    onChange={() => {
                                    }}
                                />
                            </ContentInput>
                            <Tooltip title={!sector ? "Selecione um setor para poder selecionar um atendente." : ""}>
                                <ContentInput style={{marginBottom: 25}}>
                                    <InputWithBorder
                                        autoFocus={true}
                                        name='userAttendant'
                                        size={37}
                                        onClick={() => {
                                            setVisibleModalSearchUser(true);
                                        }}
                                        useRemoveFilter={!!user}
                                        onClickRemoveFilter={() => setUser(undefined)}
                                        readOnly={true}
                                        disabled={props.loading || !sector}
                                        placeHolder={"Opcional"}
                                        nameIconRight={ICONS_DROPDESK.downArrow}
                                        sizeRightIcons={14}
                                        marginRightRightIcons={10}
                                        label={"Atendente"}
                                        value={user?.name ?? ''}
                                        onChange={() => {
                                        }}
                                    />
                                </ContentInput>
                            </Tooltip>
                        </ContentDataInput>
                        <ContentDataInput>
                            <ContentInput>
                                <InputTextAreaWithBorder
                                    required={false}
                                    marginTop={5}
                                    name='description'
                                    size={65}
                                    disabled={props.loading}
                                    placeHolder={"Comentário visível apenas para a equipe de atendimento."}
                                    label={"Comentário interno"}
                                    value={message.getText}
                                    onChange={(event) => {

                                        const value = new TextMessageEntity({
                                            conversation: event.target.value
                                        });
                                        setMessage(message.copyWith({
                                            textMessage: value,
                                            isPrivate: true
                                        }))
                                    }}
                                />
                            </ContentInput>
                        </ContentDataInput>
                        {visibleModalSearchSector && <ModalSearchSector
                            visible={visibleModalSearchSector}
                            onClose={() => setVisibleModalSearchSector(false)}
                            handleSelectSector={(sector) => {
                                setSector(sector);
                                setVisibleModalSearchSector(false);
                            }}
                            textHeaderModal={"Pesquisa de Setores"}
                            idUser={props.listBySectorsByUserLinkedInTransferTicket}
                            labelNoResults={"Nenhum setor encontrado na pesquisa"}
                            inputSearchPlaceholder={"Pesquise por setores"}
                        />}

                        {visibleModalSearchUser && <ModalSearchUser
                            visible={visibleModalSearchUser}
                            onClose={() => setVisibleModalSearchUser(false)}
                            role={UserRole.attendant}
                            idSector={sector ? sector.id : ""}
                            handleSelectUser={async (user) => {
                                setUser(user);
                                setVisibleModalSearchUser(false);
                            }}
                            textHeaderModal={"Pesquisa de Atendentes"}
                            labelNoResults={"Nenhum usuário encontrado na pesquisa"}
                            inputSearchPlaceholder={"Pesquise por atendentes"}
                        />}
                    </Content>
                    <FooterModal
                        disabled={props.loading || !sector}
                        loading={props.loading}
                        type={"submit"}
                        letter={'Confirmar'}
                        onSubmit={() => {
                            if (sector) {
                                props.onTransfer(sector, user, message)
                            }
                        }}
                        onRequestClose={props.onClose}
                    />
                </Container>
            }
        />
    );
}
