import styled from "styled-components";

type TextType = {
    color: string;
};

export const ContentHeaderImportClient = styled.div`
  display: flex;
  font-size: 11px;
  color: ${(props) => props.color};
`;

export const TextDescriptionImport = styled.span<TextType>`
  font-size: 15px;
  color: ${(props) => props.color};
`;


export const TextFileExample = styled.span<TextType>`
  font-size: 15px;
  color: ${(props) => props.color};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`;
