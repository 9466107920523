import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";

export class SectorUsersEntity {
    idCompany!: string;
    idSector!: string;
    idUser!: string;
    sector?: SectorEntity;
    user?: UserEntity | null;
    action?: BackendAction | null;

    public removeCalculatedVariables(): SectorUsersEntity {
        const newObject = this.copyWith(this);
        delete newObject.sector;
        delete newObject.user;
        return newObject;
    }

    public static fromJson(json: Record<string, any>): SectorUsersEntity {
        return new SectorUsersEntity({
            idCompany: json['idCompany'],
            idSector: json['idSector'],
            idUser: json['idUser'],
            sector: json['sector'] ? SectorEntity.fromJson(json['sector']) : undefined,
            user: json['user'] ? UserEntity.fromJson(json['user']) : null,
            action: json['action']
        });
    }

    constructor({
                    idCompany,
                    idSector,
                    idUser,
                    sector,
                    user,
                    action

                }: {
        idCompany: string;
        idSector: string;
        idUser: string;
        sector?: SectorEntity;
        user?: UserEntity | null;
        action?: BackendAction | null;

    }) {
        Object.assign(this, {
            idCompany,
            idSector,
            idUser,
            sector,
            user,
            action,

        });
    }

    copyWith({
                 idCompany,
                 idSector,
                 idUser,
                 sector,
                 user,
                 action,
                 replaceActionIfNull
             }: {
        idCompany?: string;
        idSector?: string;
        idUser?: string;
        sector?: SectorEntity;
        user?: UserEntity | null;
        action?: BackendAction | null;
        replaceActionIfNull?: boolean | null;

    }): SectorUsersEntity {
        return new SectorUsersEntity({

            idCompany: idCompany ?? this.idCompany,
            idSector: idSector ?? this.idSector,
            idUser: idUser ?? this.idUser,
            sector: sector ?? this.sector,
            user: user ?? this.user,
            action: replaceActionIfNull ? action : this.action,
        })
    }
}
