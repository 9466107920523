import React from "react";
import {ModalDrawerProps} from "@dropDesk/presentation/components/modals/modal_drawer/modal_drawer";
import {Container, DrawerStyled} from "@dropDesk/presentation/components/modals/modal_drawer/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


export default function ModalDrawer(props: ModalDrawerProps) {


    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container background={colors.onBackground}>
            <DrawerStyled
                title={props.textHeaderModal}
                placement="right"
                onClose={props.onRequestClose}
                open={props.visible}
                keyboard
                width={props.width}
                contentWrapperStyle={{background: colors.delicateBackground}}
                headerStyle={{background: colors.onBackground, fontWeight: 'bold'}}
                bodyStyle={{background: colors.onBackground}}
                color={colors.text}
                bordercolor={"transparent"}
            >
                {props.renderComponent()}
            </DrawerStyled>
        </Container>
    );
}
