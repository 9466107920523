import {
    ClientJuridicalNature,
} from "@dropDesk/domain/entities/client/client_enum";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {
    InfoOrigin, InfoOriginDetail,
    InfoOriginDetailToTranslate,
    InfoOriginToTranslate
} from "@dropDesk/domain/entities/generic/info_origin.enum";

export class ClientEntity {
    id!: string;
    idCompany!: string;
    name!: string;
    friendlyName?: string | null;
    document?: string | null;
    socialName!: string;
    email?: string | null;
    telephone?: string | null;
    urlImageProfile?: string | null;
    deleted?: boolean;
    users?: UserEntity[];
    origin!: InfoOrigin;
    originDetail?: InfoOriginDetail;
    juridicalNature!: ClientJuridicalNature;
    addresses!: AddressEntity[];
    createdAt?: string;
    note?: string;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    static fromJson(json: Record<string, any>): ClientEntity {
        return new ClientEntity({
            id: json['id'] as string | undefined,
            idCompany: json['idCompany'] as string,
            name: json['name'] as string,
            socialName: json['socialName'] as string,
            friendlyName: json['friendlyName'] as string | null,
            document: json['document'] as string | null,
            email: json['email'] as string | null,
            addresses: json['addresses'] ? (json['addresses'] as Record<string, any>[]).map((address) => AddressEntity.fromJson(address)) : [],
            telephone: json['telephone'] as string | null,
            origin: json['origin'] as InfoOrigin,
            originDetail: json['originDetail'] as InfoOriginDetail,
            urlImageProfile: json['urlImageProfile'] as string | null,
            deleted: (json['deleted'] as boolean | null) ?? false,
            users: json['users'] ? (json['users'] as Record<string, any>[]).map((entry) => UserEntity.fromJson(entry)) : undefined,
            juridicalNature: (json['juridicalNature'] as ClientJuridicalNature | null) ?? ClientJuridicalNature.juridical,
            createdAt: json['createdAt'] as string | undefined,
            note: json['note'] as string | undefined,
        });
    }

    public imagePathReference(): string {
        return `companies/${this.idCompany}${RoutesEnum.clients}/${this.id}/${this.id}.png`;
    }

    public get getOrigin(): string {
        return `criado via ${InfoOriginToTranslate[this.origin as keyof typeof InfoOriginToTranslate]} ${this.originDetail ? ` através do ${InfoOriginDetailToTranslate[this.originDetail as keyof typeof InfoOriginDetailToTranslate]}` : ""}`;
    }

    constructor({
                    id,
                    idCompany,
                    name,
                    document,
                    email,
                    telephone,
                    origin,
                    originDetail,
                    urlImageProfile,
                    deleted,
                    users,
                    socialName,
                    juridicalNature,
                    friendlyName,
                    addresses,
                    createdAt,
                    note
                }: {
        id?: string;
        idCompany: string;
        name: string;
        socialName: string;
        document?: string | null;
        email?: string | null;
        telephone?: string | null;
        origin: InfoOrigin;
        originDetail?: InfoOriginDetail;
        urlImageProfile?: string | null;
        deleted?: boolean;
        users?: UserEntity[];
        juridicalNature: ClientJuridicalNature;
        friendlyName?: string | null;
        addresses: AddressEntity[];
        createdAt?: string;
        note?: string;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            name,
            document,
            email,
            telephone,
            origin,
            originDetail,
            urlImageProfile,
            deleted,
            users,
            socialName,
            juridicalNature,
            friendlyName,
            addresses,
            createdAt,
            note
        });
    }

    copyWith({
                 id,
                 idCompany,
                 name,
                 document,
                 email,
                 telephone,
                 origin,
                 originDetail,
                 urlImageProfile,
                 deleted,
                 users,
                 socialName,
                 juridicalNature,
                 friendlyName,
                 addresses,
                 createdAt,
                 replaceDocumentIfNull,
                 replaceEmailIfNull,
                 replaceTelephoneIfNull,
                 replaceUrlImageProfileIfNull,
                 replaceFriendlyNameIfNull,
                 note
             }: {
        id?: string;
        idCompany?: string;
        name?: string;
        document?: string | null;
        email?: string | null;
        telephone?: string | null;
        origin?: InfoOrigin;
        originDetail?: InfoOriginDetail;
        urlImageProfile?: string | null;
        deleted?: boolean;
        users?: UserEntity[] | null;
        socialName?: string;
        juridicalNature?: ClientJuridicalNature;
        friendlyName?: string;
        addresses?: AddressEntity[];
        createdAt?: string;
        note?: string;
        replaceDocumentIfNull?: boolean | null,
        replaceEmailIfNull?: boolean | null,
        replaceAddressIfNull?: boolean | null,
        replaceTelephoneIfNull?: boolean | null,
        replaceUrlImageProfileIfNull?: boolean | null,
        replaceFriendlyNameIfNull?: boolean | null,
    }): ClientEntity {
        return new ClientEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            name: name ?? this.name,
            document: replaceDocumentIfNull ? document : this.document,
            email: replaceEmailIfNull ? email : this.email,
            telephone: replaceTelephoneIfNull ? telephone : this.telephone,
            origin: origin ?? this.origin,
            originDetail: originDetail ?? this.originDetail,
            urlImageProfile: replaceUrlImageProfileIfNull ? urlImageProfile : this.urlImageProfile,
            deleted: deleted ?? this.deleted,
            users: users ?? this.users,
            socialName: socialName ?? this.socialName,
            juridicalNature: juridicalNature ?? this.juridicalNature,
            friendlyName: replaceFriendlyNameIfNull ? friendlyName : this.friendlyName,
            addresses: addresses ?? this.addresses,
            createdAt: createdAt ?? this.createdAt,
            note: note ?? this.note,
        })
    }
}
