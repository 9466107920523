import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";

@injectable()
export class ListCompanySubscriptionLogsUseCase {
    private _repository: CompanyRepository;

    constructor(@inject(CompanyRepository) repository: CompanyRepository) {
        this._repository = repository;
    }

    public call(page: number, limit: number): Promise<ListPaginationEntity<CompanyLogsEntity>> {
        return this._repository.listCompanySubscriptionLogs(page, limit);
    }
}

