export class TicketNotificationEntity {

    idCompany!: string;
    idTicket!: string;
    notifiedBeforeClose?: string;
    createdAt?: string;


    constructor({
                    idCompany,
                    idTicket,
                    notifiedBeforeClose,
                    createdAt,
                }: {
        idCompany: string;
        idTicket: string;
        notifiedBeforeClose?: string;
        createdAt?: string;
    }) {
        Object.assign(this, {
            idCompany,
            idTicket,
            notifiedBeforeClose,
            createdAt,
        });
    }

    static fromJson(json: Record<string, any>): TicketNotificationEntity {
        return new TicketNotificationEntity({
            idCompany: json['idCompany'],
            idTicket: json['idTicket'],
            notifiedBeforeClose: json['notifiedBeforeClose'],
            createdAt: json['createdAt'],
        });
    }
}
