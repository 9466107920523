import styled from "styled-components";
import {
    ButtonType,
    ContainerType
} from "@dropDesk/presentation/components/footer/footer_configuration/footer_configuration";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";

export const Container = styled.div`
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
`;
export const Button = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.letterColor};
  font-size: ${`${ConstantsStyles.buttonSaveFontSize}px`};
  height: 37px;
  font-weight: bold;
  min-height: 37px;
  border-radius: 5px;
  letter-spacing: 1px;
  border: 0;
  padding: 8px 16px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const ButtonBack = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  color: ${(props) => props.letterColor};
  font-size: ${`${ConstantsStyles.buttonSaveFontSize}px`};
  font-weight: bold;
  padding: 5px 10px;
  margin-left: 16px;
  height: 37px;
  min-height: 37px;
  border-radius: 5px;
  letter-spacing: 1px;
  margin-right: 15px;
  border: ${(props) => `1px solid ${props.border}`};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

`;
