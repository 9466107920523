import {observer} from "mobx-react";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    BusinessOperationDayEntity,
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {
    Container, ContentDay, ContentHour,
    Text, TextBold
} from "./styled";


const Hour = observer(
    ({
         usesTwoOperationHours,
         day,
         businessOperationDayEntity
     }: {
        usesTwoOperationHours: boolean;
        businessOperationDayEntity: BusinessOperationDayEntity
        day: string
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container>
                <ContentDay>
                    <TextBold color={colors.text}>{day}:&nbsp;</TextBold>
                </ContentDay>
                <ContentHour>
                    {!businessOperationDayEntity.open && <Text color={colors.hint}>Fechado</Text>}
                    {businessOperationDayEntity.open && <Text color={colors.text}>
                        {businessOperationDayEntity.initialFirstHour}&nbsp;-&nbsp;{businessOperationDayEntity.endFirstHour}
                        {usesTwoOperationHours &&
                            <Text color={colors.text}>
                                &nbsp;&nbsp;às&nbsp;&nbsp;{businessOperationDayEntity.initialSecondHour}&nbsp;-&nbsp;{businessOperationDayEntity.endSecondHour}
                            </Text>
                        }
                    </Text>}
                </ContentHour>

            </Container>
        );
    });
export default Hour;
