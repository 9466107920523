import {observer} from "mobx-react";
import React from "react";
import {
    ContentExportImport,
    ContentFilter,
    ContentInput,
    ContentInputAndListableSectorDeleted,
    ContentInputSearchAndFilter,
    PaddingFilters
} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useNavigate} from "react-router-dom";
import {useBetween} from "use-between";
import {useModalSearchSector} from "@dropDesk/presentation/pages/sector/ui/search/modals/handle_change_visible";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ContentInputSearch} from "@dropDesk/presentation/pages/ticket/ui/search/search_bar/styled";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {listableForClientsMap} from "@dropDesk/domain/entities/sector/sector_maps";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const SearchBar = observer(
    ({
         onSearch,
         visibleInputSearch,
         visibleButtonsOfActions,
         visibleButtonDeleted,
         visibleButtonRestore,
         loading,
         filterListableForClients,
         setFilterListableForClients,
     }: {
        onSearch: (searchParam: string) => void,
        visibleInputSearch: boolean,
        visibleButtonsOfActions: boolean,
        visibleButtonDeleted: boolean,
        visibleButtonRestore: boolean,
        loading: boolean
        setFilterListableForClients: (filter: 'true' | 'false' | null) => void,
        filterListableForClients: 'true' | 'false' | null
    }) => {

        const isNewRegister = 'new';
        const navigate = useNavigate();
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;


        const {
            setVisibleModalDelete,
            setVisibleModalRestore,
            setVisibleModalExport
        } = useBetween(useModalSearchSector);


        return (
            <ContentFilter>
                <ContentInputAndListableSectorDeleted>
                    <ContentInput>
                        {visibleInputSearch &&
                            <ContentInputSearchAndFilter>
                                <ContentInputSearch>
                                    <InputSearchGeneric
                                        inputSearchPlaceholder="Pesquise por todos os setores"
                                        loading={loading}
                                        onSearch={(value) => onSearch(value)}
                                    />
                                </ContentInputSearch>
                                <PaddingFilters>
                                    <SelectSearchMain<'true' | 'false'>
                                        size={42}
                                        width={250}
                                        backgroundColor={colors.isDarkTheme ? colors.backgroundInput : colors.background}
                                        useRemoveFilter={!!filterListableForClients}
                                        placeHolder={"Filtro de Exibição de Setores"}
                                        options={listableForClientsMap}
                                        removeSort={true}
                                        onClickRemoveFilter={() => {
                                            setFilterListableForClients(null);
                                        }}
                                        onChange={(value) => {
                                            setFilterListableForClients(value);
                                        }}
                                        value={filterListableForClients}
                                    />
                                </PaddingFilters>
                            </ContentInputSearchAndFilter>
                        }
                        {visibleButtonDeleted && <ButtonNew
                            marginLeft={0}
                            label={'Excluir'}
                            onClick={() => setVisibleModalDelete(true)}
                            nameLeftIcon={ICONS_DROPDESK.delete}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}
                        {visibleButtonRestore && <ButtonNew
                            marginLeft={0}
                            label={'Restaurar'}
                            onClick={() => setVisibleModalRestore(true)}
                            nameLeftIcon={ICONS_DROPDESK.restore}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}

                    </ContentInput>

                </ContentInputAndListableSectorDeleted>
                <ContentExportImport>
                    {visibleButtonsOfActions && <ButtonNew
                        marginLeft={0}
                        isButtonMain={true}
                        label={'Novo Setor'}
                        onClick={() => navigate(`${RoutesEnum.sectors}/${isNewRegister}`)}
                        nameLeftIcon={''}
                        sizeLeftIcon={16}
                        disabled={false}
                    />}


                    {visibleButtonsOfActions && appController.user?.permissionAdmin && <ButtonNew
                        marginLeft={10}
                        label={'Exportar'}
                        onClick={() => setVisibleModalExport(true)}
                        nameLeftIcon={ICONS_DROPDESK.excel}
                        sizeLeftIcon={15}
                        disabled={loading}
                    />}


                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBar;
