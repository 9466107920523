import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";
import React from "react";


const Header = (
    {
        backPreviousScreen,
        loading
    }: {
        backPreviousScreen: () => void
        loading: boolean
    }
) => {

    return (
        <HeaderForm
            letter={"Dados de Cobrança"}
            onRequestClose={() => backPreviousScreen()}
            disabled={loading}
        />
    );
}
export default Header;
