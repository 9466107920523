import React from "react";
import generatePicker, {PickerTimeProps, RangePickerDateProps} from "antd/es/date-picker/generatePicker";
import generateConfig from 'rc-picker/lib/generate/dateFns';

const DatePicker = generatePicker<Date>(generateConfig);

export interface RangePickerProps extends Omit<RangePickerDateProps<Date>, 'picker'> {
}

const DateRangePicker = React.forwardRef<any, RangePickerProps>((props, ref) => {
  return (
    <DatePicker.RangePicker {...props} ref={ref}/>
  )
});

export default DateRangePicker;
