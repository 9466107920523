import {observer} from "mobx-react";
import {ContentInputsWithBorder} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {useValidatorLogin} from "@dropDesk/presentation/pages/login/ui/inputs/validator";
import {useBetween} from "use-between";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const Inputs = observer((
    ({
         loading
     }: {
        loading: boolean
    }) => {

        const {
            loginEntity, setLoginEntity,
            setIsEmail,
            errorMessageEmail,
            errorMessagePassword,
            passwordVisible, setPasswordVisible,
            validate
        } = useBetween(useValidatorLogin);


        const onTap = (value: string, isEmail: boolean): void => {
            setIsEmail(isEmail);
            setLoginEntity({
                email: isEmail ? value : loginEntity.email,
                password: isEmail ? loginEntity.password : value,
            });
        };

        return (
            <>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        autoFocus={true}
                        size={45}
                        marginTop={17}
                        nameIconLeft={ICONS_DROPDESK.atSign}
                        disabled={loading}
                        placeHolder={"Informe seu e-mail"}
                        messageError={errorMessageEmail ? !loginEntity.email ? "Informe um e-mail" : "E-mail inválido" : ""}
                        value={loginEntity.email}
                        onChange={(event) => onTap(event.target.value, true)}
                        onBlur={() => validate(true, true)}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        size={45}
                        nameIconLeft={ICONS_DROPDESK.padlock}
                        disabled={loading}
                        type={passwordVisible ? "text" : "password"}
                        placeHolder={"Informe sua senha"}
                        nameIconRight={passwordVisible ? ICONS_DROPDESK.eye : ICONS_DROPDESK.eyeClosed}
                        messageError={errorMessagePassword ? !loginEntity.password ? "Informe uma senha" : "Senha menor que 6 dígitos" : ""}
                        value={loginEntity.password}
                        onChange={(event) => onTap(event.target.value, false)}
                        onBlur={() => validate(false, true)}
                        onClickRightIcons={() => {
                            setPasswordVisible(!passwordVisible)
                        }}
                    />
                </ContentInputsWithBorder>
            </>
        );
    }));

export default Inputs;
