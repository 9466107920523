import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {
    TicketsDescriptionStaticRepository
} from "@dropDesk/domain/repositories/ticket_description_static/ticket_description_static.repository";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";

@injectable()
export class ListTicketsDescriptionStaticUseCase {
    private _repository: TicketsDescriptionStaticRepository;

    constructor(@inject(TicketsDescriptionStaticRepository) repository: TicketsDescriptionStaticRepository) {
        this._repository = repository;
    }

    public call(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean, listableForClients?: boolean): Promise<ListPaginationEntity<TicketsDescriptionStaticEntity>> {
        return this._repository.list(page, searchParam, limit, listOnlyDeleted, listableForClients);
    }
}

