import React from "react";
import ReactDOM from "react-dom/client";
import App from "./presentation/app/index";
import {Provider} from "inversify-react";
import {getIt} from "./injectable";
import {Provider as MobxProvider} from "mobx-react";
import './storage/i18n';

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    // <React.StrictMode>
    <Provider container={getIt()}>
        <MobxProvider>
            <App/>
        </MobxProvider>
    </Provider>
    // </React.StrictMode>
);
