import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";

@injectable()
export class FindByPKUserUseCase {
    private _repository: UserRepository;

    constructor(@inject(UserRepository) repository: UserRepository) {
        this._repository = repository;
    }

    public call(id: string, role?: UserRole): Promise<UserEntity> {
        return this._repository.findByPK(id, role);
    }
}

