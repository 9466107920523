import React from "react";
import {
    Container,
    ContentEditor,
    Icon,
    TextFileName
} from "@dropDesk/presentation/pages/image_editor/ui/body/no_preview/header/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const Header = observer((
    {
        getNameFileSelected,
    }: {
        getNameFileSelected: () => string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container border={colors.border}>
            <ContentEditor>
                <TextFileName color={colors.text}>{getNameFileSelected()}</TextFileName>
            </ContentEditor>
        </Container>
    )
});

export default Header;
