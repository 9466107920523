import styled from "styled-components";

type ContainerType = {
    background: string;
};
type ContainerTypeFormNotices = {
    background: string;
    border: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px);
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
`;
export const ContentFormNotices = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
`;

export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
  flex: 1;
  overflow: auto;
`;
export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;
export const FormNotice = styled.div<ContainerTypeFormNotices>`
  display: flex;
  min-width: 768px;
  flex-direction: column;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-radius: 5px;
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`;


