import styled from "styled-components";
import {Select} from 'antd';
import {
    ContainerType,
    ContentType, LabelInputType, SelectSearchStyledType
} from "@dropDesk/presentation/components/inputs/select_search_main/select_search";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";


export const Container = styled.div<ContainerType>`
  margin-left: ${(props) => `${props.marginLeft}px`};
  margin-top: ${(props) => `${props.marginTop}px`};
  margin-bottom: ${(props) => `${props.marginBottom}px`};
  display: flex;
  flex-direction: ${(props) => props.useSideLabel ? 'row' : 'column'};
  flex: 1;
  align-items: ${(props) => props.useSideLabel ? 'center' : 'auto'};
  justify-content: ${(props) => props.useSideLabel ? 'center' : 'auto'};
  // z-index: 10;
`;
export const ContentSelect = styled.div<ContentType>`
  min-height: ${(props) => `${props.size}px`};
  border: 1px solid ${(props) => props.borderColor};
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 5px;
  align-items: center;
  flex: 1;
  padding: 0 0px 0 5px;
  cursor: ${props => props.disabled ? 'no-drop' : 'auto'};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:focus-within {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const Label = styled.span<LabelInputType>`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.color};
  margin-right: ${props => props.useSideLabel ? `10px` : `0px`};
`;
export const SelectStyled = styled(Select)<SelectSearchStyledType>`
  .ant-picker-input > input {
    color: ${(props) => props.color} !important;
    font-size: ${`${ConstantsStyles.inputFontSize}px`};
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: ${(props) => props.placeholdercolor};
  }

  .ant-select-arrow {
    color: ${(props) => props.color};
  }

  .ant-select-selection-item {
    color: ${(props) => props.color};
  }

  .ant-select-clear {
    color: ${(props) => props.color} !important;
    background: ${(props) => props.background} !important;
    width: 22px;
    font-size: 14px;
    margin-top: -8px !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: ${(props) => props.background} !important;
    background-color: ${(props) => props.backgroundmainbutton} !important;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px 5px;
  }

  .ant-select-dropdown {
    background: ${(props) => props.background} !important;

    box-shadow: 0 3px 6px -4px ${(props) => hexToRGBA(props.shadowcolor, 0.1)},
    0 6px 16px 0 ${(props) => hexToRGBA(props.shadowcolor, 0.1)},
    0 9px 28px 8px ${(props) => hexToRGBA(props.shadowcolor, 0.1)} !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: ${(props) => props.background} !important;
    background: ${(props) => props.backgroundmainbutton} !important;
  }

  .ant-select-item {
    color: ${(props) => props.color} !important;
  }

`;

