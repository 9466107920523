import {TicketsDescriptionStaticEntity} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";

export const newTicketDescriptionStatic = () => new TicketsDescriptionStaticEntity({
  id: '',
  idCompany: getIdCompanyByLocalStorage(),
  description: '',
  listableForClients: true,
  deleted: false,
});
