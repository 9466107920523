import styled from "styled-components";

type ContainerType = {
    background: string;
    heightShowingHeaderInfo: number;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px - 50px - ${props => `${props.heightShowingHeaderInfo}px`});
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
`;
export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 2px;
  flex: 1;
  overflow: auto;
`;
export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;


