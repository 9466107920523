import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
    border: string;
    isDarkTheme: boolean;
}

export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  height: min-content;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  margin: 0 0 0 16px;
  border-radius: 5px;
  padding: 16px;

`;

export const ContentTotalValue = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const ContainerDescriptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const ContentDescriptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
`;

export const ContentResume = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
`;
