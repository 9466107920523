import styled from "styled-components";
import {ButtonType} from "@dropDesk/presentation/components/footer/footer_form/footer_form";
import {InputType} from "@dropDesk/presentation/components/pin_field/pin";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export const DivButtonClear = styled.div`
  height: 45px;
  width: 80px;
`;

export const ButtonClear = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.letterColor};
  font-size: 16px;
  font-weight: bold;
  width: 110px;
  height: 45px;
  min-height: 45px;
  border-radius: 5px;
  letter-spacing: 1px;
  margin-right: 15px;
  border: ${(props) => `1px solid ${props.letterColor}`};

  &:hover {
    filter: brightness(80%);
  }

`;

export const Input = styled.input<InputType>`
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
  font-size: ${`${ConstantsStyles.inputFontSize}px`};
  font-weight: bold;
  color: ${(props) => `${props.color}`};
  background: ${(props) => `${props.background}`};
  border-radius: ${`${ConstantsStyles.radiusButton}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid ${props => props.border};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
