import styled from "styled-components";

type ContentType = {
    background: string;
    border: string;
};
type ContainerType = {
    heightShowingHeaderInfo: number;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 111px - ${props => `${props.heightShowingHeaderInfo}px`});
`;

export const Content = styled.div<ContentType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
  min-width: 768px;
  max-width: 768px;
  overflow: auto;
  border: 1px solid ${props => props.border};
`;

