export enum Brightness {light = 'light', dark = 'dark'}

export class CustomColors {
    backgroundLight !: string;
    backgroundDark !: string;
    onBackgroundLight !: string;
    onBackgroundDark!: string;
    backgroundInputLight !: string;
    backgroundInputDark !: string;
    backgroundInputChatLight !: string;
    backgroundInputChatDark !: string;
    buttonOnBackgroundLight !: string;
    buttonOnBackgroundDark !: string;
    textLight !: string;
    textDark !: string;
    textOnAccentBackgroundLight !: string;
    textOnAccentBackgroundDark!: string;
    borderLight !: string;
    borderDark !: string;
    hintLight !: string;
    hintDark !: string;
    hintTertiaryLight !: string;
    hintTertiaryDark !: string;
    hintInverseLight !: string;
    hintInverseDark !: string;
    textError !: string;
    errorOnPrimary !: string;
    success !: string;
    info !: string;
    warning !: string;
    disabled !: string;
    delicateBackgroundLight !: string;
    delicateBackgroundDark!: string;
    accent !: string;
    tertiaryLight !: string;
    tertiaryDark !: string;
    textLink !: string;
    readMessage !: string;
    readMessageDark !: string;
    brightness!: Brightness;
    name!: string;

    constructor({
                    backgroundLight,
                    backgroundDark,
                    onBackgroundLight,
                    onBackgroundDark,
                    backgroundInputLight,
                    backgroundInputDark,
                    buttonOnBackgroundLight,
                    backgroundInputChatLight,
                    backgroundInputChatDark,
                    buttonOnBackgroundDark,
                    textLight,
                    textDark,
                    textOnAccentBackgroundLight,
                    textOnAccentBackgroundDark,
                    borderLight,
                    borderDark,
                    hintLight,
                    hintDark,
                    hintTertiaryLight,
                    hintTertiaryDark,
                    hintInverseLight,
                    hintInverseDark,
                    textError,
                    errorOnPrimary,
                    success,
                    info,
                    warning,
                    disabled,
                    delicateBackgroundLight,
                    delicateBackgroundDark,
                    accent,
                    tertiaryLight,
                    tertiaryDark,
                    textLink,
                    readMessage,
                    readMessageDark,
                    brightness,
                    name,
                }: {
        backgroundLight: string;
        backgroundDark: string;
        onBackgroundLight: string;
        onBackgroundDark: string;
        backgroundInputLight: string;
        backgroundInputDark: string;
        buttonOnBackgroundLight: string;
        backgroundInputChatLight: string;
        backgroundInputChatDark: string;
        buttonOnBackgroundDark: string;
        textLight: string;
        textDark: string;
        textOnAccentBackgroundLight: string;
        textOnAccentBackgroundDark: string;
        borderLight: string;
        borderDark: string;
        hintLight: string;
        hintDark: string;
        hintTertiaryLight: string;
        hintTertiaryDark: string;
        hintInverseLight: string;
        hintInverseDark: string;
        textError: string;
        errorOnPrimary: string;
        success: string;
        info: string;
        warning: string;
        disabled: string;
        delicateBackgroundLight: string;
        delicateBackgroundDark: string;
        accent: string;
        tertiaryLight: string;
        tertiaryDark: string;
        textLink: string;
        readMessage: string;
        readMessageDark: string;
        brightness: Brightness;
        name: string;

    }) {
        Object.assign(this, {
            backgroundLight,
            backgroundDark,
            onBackgroundLight,
            onBackgroundDark,
            backgroundInputLight,
            backgroundInputDark,
            buttonOnBackgroundLight,
            backgroundInputChatLight,
            backgroundInputChatDark,
            buttonOnBackgroundDark,
            textLight,
            textDark,
            textOnAccentBackgroundLight,
            textOnAccentBackgroundDark,
            borderLight,
            borderDark,
            hintLight,
            hintDark,
            hintTertiaryLight,
            hintTertiaryDark,
            hintInverseLight,
            hintInverseDark,
            textError,
            errorOnPrimary,
            success,
            info,
            warning,
            disabled,
            delicateBackgroundLight,
            delicateBackgroundDark,
            accent,
            tertiaryLight,
            tertiaryDark,
            textLink,
            readMessage,
            readMessageDark,
            brightness,
            name,
        });
    }

    setBrightness(brightness: Brightness): CustomColors {
        return new CustomColors({
            accent: this.accent,
            backgroundDark: this.backgroundDark,
            backgroundInputDark: this.backgroundInputDark,
            backgroundInputLight: this.backgroundInputLight,
            backgroundLight: this.backgroundLight,
            borderDark: this.borderDark,
            borderLight: this.borderLight,
            buttonOnBackgroundDark: this.buttonOnBackgroundDark,
            buttonOnBackgroundLight: this.buttonOnBackgroundLight,
            backgroundInputChatLight: this.backgroundInputChatLight,
            backgroundInputChatDark: this.backgroundInputChatDark,
            delicateBackgroundDark: this.delicateBackgroundDark,
            delicateBackgroundLight: this.delicateBackgroundLight,
            disabled: this.disabled,
            hintDark: this.hintDark,
            hintTertiaryLight: this.hintTertiaryLight,
            hintTertiaryDark: this.hintTertiaryDark,
            hintInverseDark: this.hintInverseDark,
            hintInverseLight: this.hintInverseLight,
            hintLight: this.hintLight,
            info: this.info,
            name: this.name,
            onBackgroundDark: this.onBackgroundDark,
            onBackgroundLight: this.onBackgroundLight,
            readMessage: this.readMessage,
            readMessageDark: this.readMessageDark,
            success: this.success,
            tertiaryLight: this.tertiaryLight,
            tertiaryDark: this.tertiaryDark,
            textDark: this.textDark,
            textError: this.textError,
            errorOnPrimary: this.errorOnPrimary,
            textLight: this.textLight,
            textLink: this.textLink,
            textOnAccentBackgroundDark: this.textOnAccentBackgroundDark,
            textOnAccentBackgroundLight: this.textOnAccentBackgroundLight,
            warning: this.warning,
            brightness
        });
    }
}
