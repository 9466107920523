import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
    isDarkTheme: boolean;
}

type TextType = {
    color: string;
}

export const Container = styled.div`
  flex-direction: row;
`;
export const Button = styled.button<ContainerType>`
  background: ${props => props.background};
  display: flex;
  border-radius: 5px;
  align-items: center;
  min-height: 52px;
  max-width: 400px;
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  box-shadow: ${props => props.isDarkTheme ? `1px 1px 1px 1px ${hexToRGBA(SharedColors.white, 0.2)}` : `1px 1px 1px 1px ${hexToRGBA(SharedColors.black, 0.2)}`};
`;
export const ContentIconFile = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
export const ContentTextFile = styled.div<TextType>`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px;
  color: ${props => props.color}
`;
export const ContentFileProgress = styled.div`
  align-items: center;
  display: flex;
`;
