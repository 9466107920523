import {useEffect, useState} from "react";
import {isValidEmail, isValidName, isValidPassword} from "@dropDesk/utils/helpers/validators";
import {ClientCreateAccountEntity} from "@dropDesk/domain/entities/client/client_create_account_entity";

export const
    useValidatorCreateClientAccount = (clientCreateAccount?: ClientCreateAccountEntity) => {

        const [passwordVisible, setPasswordVisible] = useState(false);
        const [isValidData, setIsValidData] = useState(false);
        const [errorMessageCompanyName, setErrorMessageCompanyName] = useState('');
        const [errorMessageName, setErrorMessageName] = useState('');
        const [errorMessageEmail, setErrorMessageEmail] = useState('');
        const [errorMessagePassword, setErrorMessagePassword] = useState('');
        const [errorMessageContract, setErrorMessageContract] = useState('');
        const [errorMessageCompanySponsorReference, setErrorMessageCompanySponsorReference] = useState('');

        useEffect(() => {
            validate();
        }, [clientCreateAccount]);


        const validate = (): boolean => {

            setErrorMessageName('');
            setErrorMessagePassword('');
            setErrorMessageEmail('');
            setErrorMessageCompanyName('');
            setErrorMessageCompanySponsorReference('');
            setErrorMessageContract('');

            if (!clientCreateAccount) {
                return false;
            }

            if (!clientCreateAccount.companySponsorReference) {
                setErrorMessageCompanySponsorReference('Informe o código do seu fornecedor');
                setIsValidData(false);
                return false;
            } else if (!isValidName(clientCreateAccount.companyName)) {
                setErrorMessageCompanyName('O nome da empresa deve possuir mais de 2 letra');
                setIsValidData(false);
                return false;
            } else if (!isValidName(clientCreateAccount.contact.name)) {
                setErrorMessageName('O seu nome deve possuir mais de 2 letra');
                setIsValidData(false);
                return false;
            } else if (!isValidEmail(clientCreateAccount.contact.email)) {
                setErrorMessageEmail('Informe um email válido');
                setIsValidData(false);
                return false;
            } else if (!isValidPassword(clientCreateAccount.contact.password ?? '')) {
                setErrorMessagePassword('Senha de acesso deve conter pelo menos 6 digitos.');
                setIsValidData(false);
                return false;
            } else if (clientCreateAccount.contract === false) {
                setErrorMessageContract('leia e aceita o contrato.');
                setIsValidData(false);
                return false;
            } else {
                setIsValidData(true);
                return true;
            }

        };

        return {
            passwordVisible, setPasswordVisible,
            errorMessageCompanyName, setErrorMessageCompanyName,
            errorMessageName, setErrorMessageName,
            errorMessageEmail, setErrorMessageEmail,
            errorMessagePassword, setErrorMessagePassword,
            errorMessageCompanySponsorReference, setErrorMessageCompanySponsorReference,
            errorMessageContract,
            validate,
            isValidData
        }
    };
