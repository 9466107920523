import styled from "styled-components";

type ContainerType = {
    background: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const ContentConfirmPlan = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;


