import {ChartReportTicketEntity} from "@dropDesk/domain/entities/reports/report_ticket/chart_ticket.entity";
import {MessageChatType} from "@dropDesk/domain/entities/ticket/message/external/message_entity";

export class ReportTicketEntity {
    chartTicketsPriority!: ChartReportTicketEntity[];
    chartTicketsResolutionTime!: ChartReportTicketEntity[];
    chartTicketsLocation!: ChartReportTicketEntity[];
    chartTicketsStatus!: ChartReportTicketEntity[];

    static fromJson(json: ReportTicketEntity): ReportTicketEntity {
        return new ReportTicketEntity({
            chartTicketsPriority: json['chartTicketsPriority'].map(entry => ChartReportTicketEntity.fromJson({
                ...entry,
            })),
            chartTicketsResolutionTime: json['chartTicketsResolutionTime'].map(entry => ChartReportTicketEntity.fromJson({
                ...entry,
            })),
            chartTicketsLocation: json['chartTicketsLocation'].map(entry => ChartReportTicketEntity.fromJson({
                ...entry,
            })),
            chartTicketsStatus: json['chartTicketsStatus'].map(entry => ChartReportTicketEntity.fromJson({
                ...entry,
            })),
        })
    }

    public byOrder(): ReportTicketEntity {
        this.orderByOrder(this.chartTicketsStatus);
        this.orderByOrder(this.chartTicketsLocation);
        this.orderByOrder(this.chartTicketsPriority);
        this.orderByOrder(this.chartTicketsResolutionTime);
        return this;
    }

    private orderByOrder(charts: ChartReportTicketEntity[]) {
        charts.sort((a, b) => (((a.extra?.order as number | undefined) ?? 0) - ((b.extra?.order as number | undefined) ?? 0)));
    }

    constructor({
                    chartTicketsPriority,
                    chartTicketsResolutionTime,
                    chartTicketsLocation,
                    chartTicketsStatus
                }: {
        chartTicketsPriority: ChartReportTicketEntity[];
        chartTicketsResolutionTime: ChartReportTicketEntity[];
        chartTicketsLocation: ChartReportTicketEntity[];
        chartTicketsStatus: ChartReportTicketEntity[];
    }) {
        Object.assign(this, {
            chartTicketsPriority,
            chartTicketsResolutionTime,
            chartTicketsLocation,
            chartTicketsStatus
        });
    }
}
