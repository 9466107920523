import {SwitchType} from "@dropDesk/presentation/components/switch/switchd";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SwitchButton, SwitchLabel, SwitchWrapper} from "@dropDesk/presentation/components/switch/styled";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


export default function Switch(props: SwitchType) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <SwitchWrapper>
            <SwitchButton
                disabled={props.disabled}
                id={props.id}
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
                name={props.name ?? ''}
                background={colors.accent}
                backgroundChecked={colors.accent}
                backgroundSecondary={colors.hint}
                color={colors.onBackground}
            />

            <SwitchLabel
                backgroundChecked={colors.accent}
                htmlFor={props.id}
                background={colors.hint}
                color={SharedColors.white}
            />
        </SwitchWrapper>
    )
}
