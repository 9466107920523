import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";

@injectable()
export class SetUpdateLegacyCompanyUseCase {
    private _repository: CompanyRepository;

    constructor(@inject(CompanyRepository) repository: CompanyRepository) {
        this._repository = repository;
    }

    public call(body: Record<string, any>): Promise<void> {
        return this._repository.updateLegacy(body);
    }
}

