import styled from "styled-components";

type TextType = {
    color: string;
}

type ContainerType = {
    background: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  justify-content: center;
`;

export const HeaderText = styled.span<TextType>`
  font-size: 19px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  overflow: auto;
  background: ${props => props.background};
`;
