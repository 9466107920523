import {useState} from "react";

export const useModalSector = () => {

  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);

  return {
    visibleModalDelete,
    setVisibleModalDelete,
  }
}
