import styled from 'styled-components';


export const AudioStyled = styled.audio`
  display: flex !important;
  height: 40px;
  min-width: 250px !important;
  width: 100% !important;
  max-width: 250px !important;
`;
