import {observer} from "mobx-react";
import React from "react";
import {useModalUsers} from "@dropDesk/presentation/pages/user/ui/form/modals/handle_change_visible";
import {useBetween} from "use-between";
import {useNavigate} from "react-router-dom";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";


const HeaderFormNotices = observer(
  ({
     loading,
     isUnsaved
   }: {
    loading: boolean
    isUnsaved: boolean
  }) => {

    const navigate = useNavigate();

    return (
      <HeaderForm
        letter={isUnsaved ? "Cadastro de aviso" : "Editando aviso"}
        onRequestClose={() => navigate(-1)}
        disabled={loading}
      />
    )
  })
export default HeaderFormNotices;
