export class ChangePasswordResponse {
  success!: boolean;
  email!: string;

  constructor({
                success,
                email,
              }: {
    success: boolean;
    email: string;
  }) {
    Object.assign(this, {
      success,
      email,
    });
  }
}
