import {ReactTimeRangeSliderType} from "@dropDesk/presentation/components/slider_time_range/slider_time_range";
import {RangeTimeSliderStyled} from "@dropDesk/presentation/components/slider_time_range/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

export function SliderTimeRange(props: ReactTimeRangeSliderType) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const maxNumberMinutes = 1439;

    const minuteToTime = (value: number): string => {
        value = (value > maxNumberMinutes) ? maxNumberMinutes : value;
        const hours = Math.floor(value / 60);
        const minutes = value - (hours * 60);

        let hoursString = hours.toString();
        let minutesString = minutes.toString();

        if (hoursString.length == 1) hoursString = '0' + hoursString;
        if (minutesString.length == 1) minutesString = '0' + minutesString;
        if (minutes == 0) minutesString = '00';

        return `${hoursString}:${minutesString}`;
    }

    const timeToMinute = (time: string): number => {

        const arrayTime = time.split(":");
        let hoursString = arrayTime[0];
        let minutes = parseInt(arrayTime[1]);
        let hours = parseInt(hoursString) * 60;

        const minutesFormated = hours + minutes;
        return (minutesFormated > maxNumberMinutes) ? maxNumberMinutes : minutesFormated;
    }

    return (
        <div style={{width: props.width ?? 300}}>
            <RangeTimeSliderStyled
                range={{draggableTrack: true}}
                min={0}
                max={maxNumberMinutes}
                disabled={props.disabled}
                tooltip={{open: false}}
                step={1}
                defaultValue={[timeToMinute(props.value.start), timeToMinute(props.value.end)]}
                onChange={(value: [number, number]) => {
                    props.onChange({start: minuteToTime(value[0]), end: minuteToTime(value[1])});
                }}
                trackcolor={colors.hint}
                buttoncolor={colors.accent}
                trackStyle={[{borderColor: colors.hint, background: colors.accent}, {borderColor: colors.hint, background: colors.accent}]}
                handleStyle={[{color: colors.accent, background: colors.accent, borderColor: colors.accent}, {
                    color: colors.accent,
                    background: colors.accent,
                    borderColor: colors.accent
                }]}
            />
        </div>
    )
}
