import {observer} from "mobx-react";
import React, {useContext} from "react";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {useBetween} from "use-between";
import {useModalContacts} from "@dropDesk/presentation/pages/client/ui/contacts/search/modals/handle_change_visible";
import {Tooltip} from "antd";
import {ContainerAction} from "@dropDesk/presentation/pages/client/ui/my_clients/search/table/styled";
import ButtonActionTable from "@dropDesk/presentation/components/buttons/button_action_table";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const TableContacts = observer(
    ({
         searchParam,
         loading,
         tableUsers,
         getDataFromPage,
         setRowSelectionUsers,
         rowSelectionUsers,
         permissionDeleteData,
         permitSelectionRow,
         heightGarbage
     }: {
        searchParam: string,
        loading: boolean,
        tableUsers: ListPaginationEntity<UserEntity>,
        getDataFromPage: (page: number) => void,
        setRowSelectionUsers: (user: UserEntity[]) => void,
        rowSelectionUsers: UserEntity[];
        permissionDeleteData: boolean,
        permitSelectionRow: boolean,
        heightGarbage: number

    }) => {

        const collapsed = useContext(MenuCollapsedContext);
        const {
            setVisibleModalEditClientEntity,
            setVisibleModalRestore
        } = useBetween(useModalContacts);


        const setRestore = (user: UserEntity) => {
            setRowSelectionUsers([user]);
            setVisibleModalRestore(true);
        }

        const tableColumn = [
            {
                title: 'Nome', dataIndex: 'url', key: 'url', width: 58, render: (text: string, record: UserEntity) => (
                    <AvatarImage
                        useCursorPointer={record.urlImageProfile ? true : false}
                        title={record.name}
                        size={35}
                        url={record.urlImageProfile}
                        round={true}
                        hasPreviewImage={true}
                        withBorder={true}
                        onClick={(event) => event.stopPropagation()}
                    />
                ),
            },
            {
                title: '',
                ellipsis: true,
                dataIndex: 'name',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Telefone',
                ellipsis: true,
                dataIndex: 'telephone',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Empresa',
                ellipsis: true,
                dataIndex: 'socialName',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={record.client?.socialName ?? ''}
                    />
                ),
            },
            {
                title: 'Email',
                ellipsis: true,
                dataIndex: 'email',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Cargo',
                ellipsis: true,
                dataIndex: 'identity',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Criado em',
                ellipsis: true,
                dataIndex: 'createdAt',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: 155,
                        maxWidth: 155,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        {displayDateToLocale(text)}
                        <Tooltip title={`${record.getOrigin}`}>
                            <span>
                 <DropDeskIcon icon={ICONS_DROPDESK.info} size={14} useDarkenInHover={true}
                               style={{cursor: 'pointer'}}/>
                                 </span>
                        </Tooltip>
                    </div>
                ),
            },
            {
                title: '',
                key: 'action',
                width: permissionDeleteData ? 65 : 0,
                render: (text: string, record: UserEntity) => (
                    <>
                        {record.deleted === true && <div style={{display: 'flex', flexDirection: 'row'}}>
                            <ContainerAction>
                                {record.deleted &&
                                    <Tooltip placement="left" title={'Restaurar Contato'}
                                    > <ButtonActionTable
                                        disabled={false}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setRestore(record);
                                        }}
                                        nameIcon={ICONS_DROPDESK.restore}
                                    /> </Tooltip>

                                }
                            </ContainerAction>
                        </div>}
                    </>
                ),
            },

        ];

        const rowSelection = {
            selectedRowKeys: rowSelectionUsers.map((user) => user.id),
            onChange: (selectedRowKeys: React.Key[], selectedRows: UserEntity[]) => {
                setRowSelectionUsers(selectedRows)
            },
            getCheckboxProps: (record: UserEntity) => ({
                disabled: false,
            }),
            preserveSelectedRowKeys: true
        };

        return (
            <TableGeneric<UserEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableUsers.data}
                onSelectedRow={(contact: UserEntity) => {
                    if (!contact.deleted) {
                        setVisibleModalEditClientEntity({
                            visible: true,
                            id: contact.client?.id ?? '',
                            idContact: contact.id
                        })
                    } else {
                        setRestore(contact);
                    }
                }}
                loading={loading}
                labelNoResults={'Nenhum contato encontrado na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableUsers.totalRows}
                page={tableUsers.page}
                pageSize={tableUsers.limit}
                rowSelection={permitSelectionRow ? rowSelection : null}
                onChange={(page) => getDataFromPage(page - 1)}
                extraLayout={heightGarbage}

            />
        )
    })
export default TableContacts;
