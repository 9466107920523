import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ContentIcon} from "@dropDesk/presentation/components/inputs/input_with_border/left_icon/styled";

const LeftIcon = (
    {
        nameIconLeft,
        iconLeftIsMain,
        onClickLeftIcons,
        sizeLeftIcons,
        disabled
    }: {
        nameIconLeft: string;
        iconLeftIsMain?: boolean;
        onClickLeftIcons?: () => void;
        sizeLeftIcons?: number;
        disabled?: boolean;
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <ContentIcon onClick={disabled ? () => {
        } : onClickLeftIcons}>
            <DropDeskIcon
                icon={nameIconLeft}
                color={iconLeftIsMain ? colors.accent : colors.text}
                size={sizeLeftIcons ?? 16}
                style={{
                    cursor: onClickLeftIcons ? 'pointer' : 'auto',
                }}

            />
        </ContentIcon>
    )
}
export default LeftIcon;
