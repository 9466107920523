export class NotificationsConfigurationEntity {

    allowNewTicketNotification!: boolean;
    allowTransferTicketNotification!: boolean;
    allowMessagesTicketNotification!: boolean;

    constructor({
                    allowNewTicketNotification,
                    allowTransferTicketNotification,
                    allowMessagesTicketNotification
                }: {
        allowNewTicketNotification: boolean;
        allowTransferTicketNotification: boolean;
        allowMessagesTicketNotification: boolean;
    }) {
        Object.assign(this, {
            allowNewTicketNotification,
            allowTransferTicketNotification,
            allowMessagesTicketNotification
        });
    }

    copyWith({
                 allowNewTicketNotification,
                 allowTransferTicketNotification,
                 allowMessagesTicketNotification
             }: {
        allowNewTicketNotification?: boolean;
        allowTransferTicketNotification?: boolean;
        allowMessagesTicketNotification?: boolean;
    }): NotificationsConfigurationEntity {
        return new NotificationsConfigurationEntity({
            allowNewTicketNotification: allowNewTicketNotification ?? this.allowNewTicketNotification,
            allowTransferTicketNotification: allowTransferTicketNotification ?? this.allowTransferTicketNotification,
            allowMessagesTicketNotification: allowMessagesTicketNotification ?? this.allowMessagesTicketNotification,
        })
    }
}
