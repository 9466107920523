import {Container, Span} from "@dropDesk/presentation/components/animations/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const AnimationRecordVoice = () => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Container>
            <Span color={colors.text}/>
            <Span color={colors.text}/>
            <Span color={colors.text}/>
            <Span color={colors.text}/>
            <Span color={colors.text}/>
            <Span color={colors.text}/>
            <Span color={colors.text}/>
            <Span color={colors.text}/>
        </Container>
    )
}

export default AnimationRecordVoice;
