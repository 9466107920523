import {ContentAction} from "./styled";
import React from "react";
import {ButtonActionType} from "@dropDesk/presentation/components/buttons/button_action_table/button_action_table";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskIcon from "@dropDesk/storage/icons";


export default function ButtonActionTable(props: ButtonActionType) {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <div>
            <ContentAction disabled={props.disabled} onClick={props.onClick} background={'transparent'}>
                <DropDeskIcon
                    color={props.isActionDelete ? colors.textError : colors.text}
                    icon={props.nameIcon}
                    size={props.sizeIcon ?? 18}
                />
            </ContentAction>
        </div>
    );
}
