import styled from "styled-components";

export const ContentExportImport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContentFilter = styled.div`
  display: flex;
  height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1px;
`;


export const ContentInput = styled.div`
  max-width: 350px;
  width: 100%;
`;

export const ContentInputAndListableSectorDeleted = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
export const PaddingFilters = styled.div`
  padding: 0 32px;
`;
