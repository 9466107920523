import {observer} from "mobx-react";
import React from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {useNavigate} from "react-router-dom";


const FooterFormImports = observer(
  ({
     handleSubmit,
     loading,
     isValidDataForm
   }: {
    handleSubmit: () => void,
    loading: boolean,
    isValidDataForm: boolean,
  }) => {

    const navigate = useNavigate();

    return (
      <FooterForm
        disabled={loading || !isValidDataForm}
        loading={loading}
        type={"submit"}
        letter={"Importar"}
        onSubmit={() => handleSubmit()}
        onRequestClose={() => navigate(-1)}
      />
    )
  })
export default FooterFormImports;
