import {injectable} from "inversify";
import {ReportTicketEntity} from "@dropDesk/domain/entities/reports/report_ticket/report_ticket_entity";

@injectable()
export abstract class ReportTicketRepository {

    public abstract get(
        period: { startDate: string, endDate: string },
        idUser: string | null,
        idSector: string | null,
        idClient: string | null
    ): Promise<ReportTicketEntity>

    public abstract exportPdf(
        period: { startDate: string, endDate: string },
        idUser: string | null,
        idSector: string | null,
        idClient: string | null
    ): Promise<void>;

}
