import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SectorRepository} from "@dropDesk/domain/repositories/sector/sector.repository";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";

@injectable()
export class RestoreSectorUseCase {
  private _repository: SectorRepository;

  constructor(@inject(SectorRepository) repository: SectorRepository) {
    this._repository = repository;
  }

  public call(sectors: SectorEntity[]): Promise<void> {
    return this._repository.restore(sectors);
  }
}
