import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Button,
    Container,
    ContainerPaymentInfo,
    Content,
    ContentEditPayment,
    ContentPaymentInfo,
    ContentText,
    TextBold
} from "./styled";
import React from "react";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import PaymentInfos from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_info_detail/infos";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const PaymentInfoDetail = (
    {
        paymentInfo,
        handleEditPaymentInfo
    }: {
        paymentInfo: CompanyPaymentInfo,
        handleEditPaymentInfo: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <Content>
                <ContainerPaymentInfo
                    background={colors.onBackground}
                    border={colors.border}
                    isDarkTheme={colors.brightness === Brightness.dark}
                >
                    <ContentPaymentInfo>
                        <ContentText>
                            <TextBold size={16} color={colors.text}>Dados de cobrança</TextBold>
                        </ContentText>

                        <PaymentInfos paymentInfo={paymentInfo}/>
                    </ContentPaymentInfo>
                    <ContentEditPayment>
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.check}
                            color={colors.success}
                            size={19}
                        />
                        <Button size={14} color={colors.text} onClick={() => handleEditPaymentInfo()}>
                            Editar
                        </Button>
                    </ContentEditPayment>
                </ContainerPaymentInfo>
            </Content>
        </Container>
    );
}
export default PaymentInfoDetail;
