import DetailSubscription from "@dropDesk/presentation/components/detail_subscription";
import React from "react";
import {
    ContentDetail,
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/styled";
import DetailFreePlan from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/detail_plan/free_plan";
import DetailActivePlan from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/detail_plan/active_plan";
import DetailCanceledPlan
    from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/detail_plan/canceled_plan";
import DetailExpiredPlan
    from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/detail_plan/expired_plan";
import {observer} from "mobx-react";
import DowngradePlan from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/detail_plan/downgrade_plan";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import DetailUpgradePlan from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/detail_plan/upgrade";


const DetailPlan = observer((
    {
        disabled,
        loading,
        isFreePlan,
        isActivePlan,
        isCanceledScheduled,
        isExpiredPlan,
        onClickSelectPlan,
        onClickChangePlan,
        onClickReactivePlan,
        planName,
        nextDueDateDDMMYYYY,
        planValue,
        visibleButtonChangePlan,
        daysToExpireSubs,
        onClickCancelFutureSubscription,
        visibleCancelButton,
        isWaitingPlan,
        hasDowngrade,
        hasUpgrade,
        quantityUsersNewSubs,
        oldSubs,
        isLegacy,
    }: {
        disabled: boolean;
        loading: boolean;
        isFreePlan: boolean;
        isActivePlan: boolean;
        isCanceledScheduled: boolean;
        isExpiredPlan: boolean;
        onClickSelectPlan: () => void
        onClickChangePlan: () => void
        onClickReactivePlan: () => void
        onClickCancelFutureSubscription: () => void
        planName: string;
        nextDueDateDDMMYYYY: string
        planValue: number
        visibleButtonChangePlan: boolean
        visibleCancelButton: boolean
        daysToExpireSubs: number
        isWaitingPlan: boolean
        hasDowngrade: boolean
        hasUpgrade: boolean
        isLegacy: boolean;
        quantityUsersNewSubs: number,
        oldSubs?: PaymentSubscriptionEntity
    }
) => {

    return (
        <DetailSubscription
            title={'Detalhes do plano'}
            content={
                <ContentDetail>
                    {isFreePlan &&
                        <DetailFreePlan
                            onClickSelectPlan={() => onClickSelectPlan()}
                            loading={loading}
                            disabled={disabled}
                            planName={planName}
                            nextDueDateDDMMYYYY={nextDueDateDDMMYYYY}
                            daysToExpireSubs={daysToExpireSubs}
                        />
                    }
                    {(isActivePlan || isWaitingPlan) && !hasDowngrade && !hasUpgrade &&
                        <DetailActivePlan
                            onClickChangePlan={() => onClickChangePlan()}
                            loading={loading}
                            disabled={disabled}
                            planName={planName}
                            nextDueDateDDMMYYYY={nextDueDateDDMMYYYY}
                            planValue={planValue}
                            visibleButtonChangePlan={visibleButtonChangePlan}
                            isWaitingPlan={isWaitingPlan}
                        />
                    }
                    {isCanceledScheduled &&
                        <DetailCanceledPlan
                            onClickReactivePlan={() => onClickReactivePlan()}
                            loading={loading}
                            disabled={disabled}
                            planName={planName}
                            nextDueDateDDMMYYYY={nextDueDateDDMMYYYY}
                            onClickSelectPlan={() => onClickSelectPlan()}
                            planValue={planValue}
                            isLegacy={isLegacy}
                        />
                    }

                    {isExpiredPlan &&
                        <DetailExpiredPlan
                            onClickSelectPlan={() => onClickSelectPlan()}
                            loading={loading}
                            disabled={disabled}
                            planName={planName}
                            nextDueDateDDMMYYYY={nextDueDateDDMMYYYY}
                            planValue={planValue}
                        />
                    }
                    {isWaitingPlan && hasUpgrade && !oldSubs?.isFree &&
                        <DetailUpgradePlan
                            onClickCancelFutureSubscription={() => onClickCancelFutureSubscription()}
                            loading={loading}
                            disabled={disabled}
                            planName={planName}
                            nextDueDateDDMMYYYY={nextDueDateDDMMYYYY}
                            planValue={planValue}
                            visibleCancelButton={visibleCancelButton}
                            isWaitingPlan={isWaitingPlan}
                        />
                    }

                    {hasDowngrade &&
                        <DowngradePlan
                            onClickCancelFutureSubscription={() => onClickCancelFutureSubscription()}
                            loading={loading}
                            disabled={disabled}
                            planName={planName}
                            nextDueDateDDMMYYYY={nextDueDateDDMMYYYY}
                            planValue={planValue}
                            visibleCancelButton={visibleCancelButton}
                            oldSubs={oldSubs}
                            quantityUsersNewSubs={quantityUsersNewSubs}
                        />
                    }
                </ContentDetail>
            }
        />
    );
});
export default DetailPlan;
