import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ReportTicketAggregateRepository} from "@dropDesk/domain/repositories/reports/report_ticket_aggregate/report_ticket_aggregate.repository";
import {
  ReportTicketAggregateRemoteDataSource
} from "@dropDesk/data/data_source/reports/report_ticket_aggregate/report_ticket_aggregate_remote.datasource";
import {ReportTicketAggregateEntity} from "@dropDesk/domain/entities/reports/report_ticket_aggregate/report_ticket_aggregate.entity";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";

@injectable()
export class ReportTicketAggregateRepositoryImpl implements ReportTicketAggregateRepository {
  private _dataSource: ReportTicketAggregateRemoteDataSource;

  constructor(
    @inject(ReportTicketAggregateRemoteDataSource)
      dataSource: ReportTicketAggregateRemoteDataSource
  ) {
    this._dataSource = dataSource;
  }

  public get(period: { startDate: string, endDate: string }): Promise<ReportTicketAggregateEntity> {
    try {
      return this._dataSource.get(period);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }

  public exportPdf(period: { startDate: string, endDate: string }): Promise<void> {
    try {
      return this._dataSource.exportPdf(period);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }

}
