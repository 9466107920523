import React, {useRef, useState} from "react";
import {Image} from 'antd';
import {
    Container,
    ContainerTitle,
    ContentCaption,
    ContentDownloadButton,
    PlaceHolderImage,
    TextCaption
} from "@dropDesk/presentation/components/messages/message_box/image_message/styled";
import LinkFy from "@dropDesk/presentation/components/linkify";
import corruptFile from "@dropDesk/storage/images/corrupt-file.png"
import {downloadFile} from "@dropDesk/utils/helpers/files";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import './override_styles_image_preview.css';
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";

const ImageMessage = (
    {
        color,
        width,
        alt,
        height,
        hint,
        message,
        imageMessages
    }: {
        color: string,
        width: number;
        height?: number;
        alt?: string;
        hint: string;
        message: TicketMessageEntity
        imageMessages: TicketMessageEntity[]
    }) => {

    const [isPreviewVisible, setPreviewVisible] = useState(false);
    const [current, setCurrent] = useState<number | undefined>(undefined);
    const [images, setImages] = useState<TicketMessageEntity[] | null>(null);

    const currentImage = (): TicketMessageEntity | null => {
        if (!images) {
            return null;
        }
        const newImage = current !== null && current !== undefined ? images[current] : null;
        return newImage;
    }

    return (
        <Container>
            <Image
                preview={{
                    visible: false,
                    onVisibleChange: (visible, prevVisible) => {
                        const current = imageMessages.findIndex(image => image.id === message.id);
                        if (current !== null && current !== undefined && current > -1) {
                            setImages(imageMessages);
                            setCurrent(current);
                            setPreviewVisible(true);
                        }
                    },
                }}
                placeholder={(
                    <PlaceHolderImage hint={hint}>
                        <SpinLoading
                            color={color}
                            size={"default"}
                        />
                    </PlaceHolderImage>
                )}
                width={width}
                src={message.getBlobURL ?? undefined}
                fallback={corruptFile}
                style={{border: 0, maxHeight: height ?? 200, height: height ?? undefined}}
                alt={alt ?? "Imagem"}
            />
            {isPreviewVisible && <div style={{display: 'none'}}>
                <Image.PreviewGroup
                    preview={{
                        visible: isPreviewVisible,
                        onVisibleChange: (visible) => {
                            setPreviewVisible(visible);
                        },
                        keyboard: true,
                        title: (
                            <ContainerTitle>
                                <ContentCaption>
                                    {currentImage()?.imageMessage?.caption &&
                                        < TextCaption>{currentImage()?.imageMessage?.caption}</TextCaption>}
                                </ContentCaption>
                                <>
                                    <TextCaption
                                        style={{
                                            marginRight: 10,
                                            minWidth: 46
                                        }}>{(current ?? 0) + 1}/{images?.length}</TextCaption>
                                    <ContentDownloadButton onClick={() => {
                                        if (currentImage()?.getBlobURL) {
                                            downloadFile(currentImage()!.getBlobURL!, message.getFileName);
                                        }
                                    }}>
                                        <DropDeskIcon
                                            icon={ICONS_DROPDESK.download}
                                            size={16}
                                            style={{marginTop: -3, cursor: 'pointer'}}
                                            color={SharedColors.white}
                                        />
                                    </ContentDownloadButton>
                                </>
                            </ContainerTitle>),

                        countRender: (_nextCurrent: number, total: number) => {
                            const nextCurrent = _nextCurrent - 1;
                            if (isPreviewVisible && nextCurrent !== current && nextCurrent > -1) {
                                setTimeout(() => {
                                    setCurrent(nextCurrent);
                                }, 0);
                            }
                            return ``;
                        },
                        current
                    }}
                >

                    {images?.map((message) => {
                        return (
                            <Image
                                key={message.id}
                                src={message.getBlobURL ?? undefined}
                                preview={{style: {background: hexToRGBA(SharedColors.black, 0.8)}}}
                            />
                        )
                    })}
                </Image.PreviewGroup>
            </div>}
            {message?.imageMessage?.caption && <LinkFy text={message?.imageMessage?.caption} color={color}/>}
        </Container>
    );
};

export default React.memo(ImageMessage);
