import {inject, injectable} from "inversify";
import {action, computed, configure, makeObservable, observable, when} from "mobx";
import {ListChatsAttendingUseCase} from "@dropDesk/domain/use_case/chat/list_chats.usecase";
import {ListChatsWaitingChatUseCase} from "@dropDesk/domain/use_case/chat/list_waiting_chat.usecase";
import {Subscription} from "rxjs";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {SubscribeChatsUseCase} from "@dropDesk/domain/use_case/chat/subscribe_chats.usecase";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {BodyMessage, TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {ListChatMessagesUseCase} from "@dropDesk/domain/use_case/chat/list_chat_messages.usecase";
import {
    ListChatMessagesAfterLastSubsUseCase
} from "@dropDesk/domain/use_case/chat/list_chat_messages_after_last_subs.usecase";
import {StartConnectionChatsUseCase} from "@dropDesk/domain/use_case/chat/start_connection_chats.usecase";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";
import {
    findMaxNumber,
    findMinNumber,
    getIdCompanyByLocalStorage,
    initializeConfigurationLocalStorage,
    sleep
} from "@dropDesk/utils/helpers/common";
import {StatusTypes, TabsChat} from "@dropDesk/domain/entities/chat/chat_enum";
import {LogoutChatsUseCase} from "@dropDesk/domain/use_case/chat/logout_chats.usecase";
import {ConnectionStateChatsUseCase} from "@dropDesk/domain/use_case/chat/connection_state_chats.usecase";
import {SubscribeChatConnectionUseCase} from "@dropDesk/domain/use_case/chat/subscribe_chat_connection.usecase";
import {Howl} from 'howler';
import soundNotificationWaitingList from "@dropDesk/notification_waiting.mp3"
import soundNotificationMessage from "@dropDesk/notification_new_message.mp3"
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {
    AudioMessageEntity,
    DocumentMessageEntity,
    getMessageTypeFromFile,
    ImageMessageEntity,
    MessageChatType,
    MessageStatus,
    ReactionMessageEntity,
    ReplyEntity,
    VideoMessageEntity
} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {NavigateFunction} from "react-router-dom";
import {SendMessageUseCase} from "@dropDesk/domain/use_case/chat/send_message_message.usecase";
import {DeleteMessageUseCase} from "@dropDesk/domain/use_case/chat/delete_message.usecase";
import {SubscriptionUploadFileUseCase} from "@dropDesk/domain/use_case/chat/upload_file_message.usecase";
import {EditMessageUseCase} from "@dropDesk/domain/use_case/chat/edit_message.usecase";
import {FileMessageEntity} from "@dropDesk/domain/entities/common/file_message";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {UploadOptionsEntity} from "@dropDesk/domain/entities/ticket/message/upload_message.entity";
import {AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";
import {fileToBase64} from "@dropDesk/utils/helpers/files";
import firebase from "firebase/compat";
import {NavigateToMessageUseCase} from "@dropDesk/domain/use_case/chat/navigate_to_message.usecase";
import {newMessage, newSystemMessage} from "@dropDesk/presentation/pages/chat/controller/new_message";
import {ReactionMessageUseCase} from "@dropDesk/domain/use_case/chat/reaction_message.usecase";
import {InformReadMessageUseCase} from "@dropDesk/domain/use_case/chat/inform_read_message.usecase";
import {UrlToBlobUseCase} from "@dropDesk/domain/use_case/io/url_to_blob.usecase";
import {ClearTicketsCacheUseCase} from "@dropDesk/domain/use_case/chat/clear_tickets_cache.usecase";
import FirebaseStorageError = firebase.storage.FirebaseStorageError;
import {CancelTokenSource} from "axios";
import {getNowSumIndex} from "@dropDesk/utils/helpers/date_helper";

export enum TypeListChat {waiting = 'waiting', attending = 'attending'}

export enum ActiveRouteEnum {
    ticket = 'ticket',
    chat = 'chat'
}

configure({
    enforceActions: "always",
});

@injectable()
export class ChatController {

    private readonly _listChatsAttendingUseCase: ListChatsAttendingUseCase;
    private readonly _listChatsWaitingChatUseCase: ListChatsWaitingChatUseCase;
    private readonly _subscribeChatsUseCase: SubscribeChatsUseCase;
    private readonly _listChatMessagesUseCase: ListChatMessagesUseCase;
    private readonly _listChatMessagesAfterLastSubsUseCase: ListChatMessagesAfterLastSubsUseCase;
    private readonly _startConnectionChatsUseCase: StartConnectionChatsUseCase;
    private readonly _logoutChatsUseCase: LogoutChatsUseCase;
    private readonly _connectionStateChatsUseCase: ConnectionStateChatsUseCase;
    private readonly _subscribeChatConnectionUseCase: SubscribeChatConnectionUseCase;
    private readonly _sendMessageUseCase: SendMessageUseCase;
    private readonly _deleteMessageUseCase: DeleteMessageUseCase;
    private readonly _subscriptionUploadFileUseCase: SubscriptionUploadFileUseCase;
    private readonly _editMessageUseCase: EditMessageUseCase;
    private readonly _navigateToMessageUseCase: NavigateToMessageUseCase;
    private readonly _reactionMessageUseCase: ReactionMessageUseCase;
    private readonly _informReadMessageUseCase: InformReadMessageUseCase;
    private readonly _urlToBlobUseCase: UrlToBlobUseCase;
    private readonly _clearTicketsCacheUseCase: ClearTicketsCacheUseCase;

    constructor(
        @inject(ListChatsAttendingUseCase) listChatsAttendingUseCase: ListChatsAttendingUseCase,
        @inject(ListChatsWaitingChatUseCase) listChatsWaitingChatUseCase: ListChatsWaitingChatUseCase,
        @inject(SubscribeChatsUseCase) subscribeChatsUseCase: SubscribeChatsUseCase,
        @inject(ListChatMessagesUseCase) listChatMessagesUseCase: ListChatMessagesUseCase,
        @inject(ListChatMessagesAfterLastSubsUseCase) listChatMessagesAfterLastSubsUseCase: ListChatMessagesAfterLastSubsUseCase,
        @inject(StartConnectionChatsUseCase) startConnectionChatsUseCase: StartConnectionChatsUseCase,
        @inject(ConnectionStateChatsUseCase) connectionStateChatsUseCase: ConnectionStateChatsUseCase,
        @inject(LogoutChatsUseCase) logoutChatsUseCase: LogoutChatsUseCase,
        @inject(SubscribeChatConnectionUseCase) subscribeChatConnectionUseCase: SubscribeChatConnectionUseCase,
        @inject(SendMessageUseCase) sendMessageUseCase: SendMessageUseCase,
        @inject(DeleteMessageUseCase) deleteMessageUseCase: DeleteMessageUseCase,
        @inject(SubscriptionUploadFileUseCase) subscriptionUploadFileUseCase: SubscriptionUploadFileUseCase,
        @inject(EditMessageUseCase) editMessageUseCase: EditMessageUseCase,
        @inject(NavigateToMessageUseCase) navigateToMessageUseCase: NavigateToMessageUseCase,
        @inject(ReactionMessageUseCase) reactionMessageUseCase: ReactionMessageUseCase,
        @inject(InformReadMessageUseCase) informReadMessageUseCase: InformReadMessageUseCase,
        @inject(UrlToBlobUseCase) urlToBlobUseCase: UrlToBlobUseCase,
        @inject(ClearTicketsCacheUseCase) clearTicketsCacheUseCase: ClearTicketsCacheUseCase,
    ) {
        makeObservable(this);
        this._listChatsAttendingUseCase = listChatsAttendingUseCase;
        this._listChatsWaitingChatUseCase = listChatsWaitingChatUseCase;
        this._subscribeChatsUseCase = subscribeChatsUseCase;
        this._listChatMessagesUseCase = listChatMessagesUseCase;
        this._listChatMessagesAfterLastSubsUseCase = listChatMessagesAfterLastSubsUseCase;
        this._startConnectionChatsUseCase = startConnectionChatsUseCase;
        this._connectionStateChatsUseCase = connectionStateChatsUseCase;
        this._logoutChatsUseCase = logoutChatsUseCase;
        this._subscribeChatConnectionUseCase = subscribeChatConnectionUseCase;
        this._sendMessageUseCase = sendMessageUseCase;
        this._deleteMessageUseCase = deleteMessageUseCase;
        this._subscriptionUploadFileUseCase = subscriptionUploadFileUseCase;
        this._editMessageUseCase = editMessageUseCase;
        this._navigateToMessageUseCase = navigateToMessageUseCase;
        this._reactionMessageUseCase = reactionMessageUseCase;
        this._informReadMessageUseCase = informReadMessageUseCase;
        this._urlToBlobUseCase = urlToBlobUseCase;
        this._clearTicketsCacheUseCase = clearTicketsCacheUseCase;
    }

    connection = new ChatIntegrationConnectionEntity({
        idCompany: getIdCompanyByLocalStorage(),
        qrCode: null,
        status: StatusTypes.DISCONNECTED
    });

    @observable
    tableListWaiting: ListPaginationEntity<TicketEntity> = ListPaginationEntity.defaultValue<TicketEntity>();

    @observable
    tableListChats: ListPaginationEntity<TicketEntity> = ListPaginationEntity.defaultValue<TicketEntity>();

    @observable
    chatConnection: ChatIntegrationConnectionEntity = this.connection;

    @observable
    currentChat?: TicketEntity | null;

    @observable
    compactModeMessages: boolean = localStorage.getItem(ConstantsKeys.compactModeMessages)
        ?
        JSON.parse(localStorage.getItem(ConstantsKeys.compactModeMessages)!)
        :
        false;

    @observable
    isSearchByMyChats: boolean = true;

    @observable
    activeTabChat: TabsChat = TabsChat.attending;

    @observable
    idMessageFocused: string | null = null;

    @observable
    forceCleanMessagesCache: boolean = false;

    @observable
    currentChatMessages: ListPaginationEntity<TicketMessageEntity> = ListPaginationEntity.defaultValue<TicketMessageEntity>();

    @observable
    soundNewMessageChat: boolean = localStorage.getItem(ConstantsKeys.soundNewMessageChat)
        ?
        JSON.parse(localStorage.getItem(ConstantsKeys.soundNewMessageChat)!)
        :
        true;

    @observable
    soundQueueWaitingChat: boolean = localStorage.getItem(ConstantsKeys.soundQueueWaitingChat)
        ?
        JSON.parse(localStorage.getItem(ConstantsKeys.soundQueueWaitingChat)!)
        :
        false;

    @observable
    cacheChat: Record<string, Record<number, TicketMessageEntity[]>> = {};

    @observable
    scrollPosition: 'top' | 'bottom' | null = null;

    @observable
    notificationWaiting: Howl | null = null;

    @observable
    notificationNewMessage: Howl | null = null;

    @computed
    get filteredListWaiting(): TicketEntity[] {
        if (this.searchParam !== '' && !this.isSearchByMyChats) {
            return this.tableListWaiting.data.filter((ticket) => this.filterPredicate(ticket));
        }
        return this.tableListWaiting.data;
    }

    @computed
    get filteredListChat(): TicketEntity[] {
        if (this.searchParam !== '' && this.isSearchByMyChats) {
            return this.tableListChats.data.filter((ticket) => this.filterPredicate(ticket));
        }

        return this.tableListChats.data;
    }

    @computed
    get countNotificationDocumentHead(): number {
        return (
            (this.tableListWaiting.totalRows) +
            (this.tableListChats.data.filter((entry) => !!entry.countUnreadNotification)).length
        )
    }


    @computed
    get messages(): TicketMessageEntity[] {
        if (this.forceCleanMessagesCache) {
            return [];
        }
        return this.orderMessagesByCreatedAt(
            this.makeStartingAndEndTime(
                this.removeDuplicateMessages(
                    this.currentChatMessages.data.filter((entry) => !this.currentChat?.hasActiveStatus ? !entry.reactionMessage && !entry.isSystemMessage : !entry.reactionMessage))
            )
        );
    }

    @computed
    get reactions(): TicketMessageEntity[] {
        return this.removeDuplicateMessages(
            this.currentChatMessages.data.filter((entry) => !!entry.reactionMessage)
        );

    }

    @computed
    get targetMessagePaginationTop(): TicketMessageEntity | undefined {
        if (
            this.forceCleanMessagesCache
            ||
            this.loading
            //|| (this.messages.length < this.currentChatMessages.limit)
        ) {
            return;
        }
        return this.messages[this.messages.length - 10];
    }

    @computed
    get targetMessageButtonNavigateBottom(): TicketMessageEntity | undefined {
        if (this.forceCleanMessagesCache || this.messages.length === 0) {
            return;
        }
        return this.messages[0];
    }


    @computed
    get targetMessagePaginationBottom(): TicketMessageEntity | undefined {
        if (this.forceCleanMessagesCache || this.loading) {
            return;
        }

        if (this.currentChatMessages.hasMoreBottom) {
            return this.messages[10];
        }
        return;
    }

    @action
    addPagesLoad(value: number) {
        const pagesLoad = this.currentChatMessages.pagesLoad;
        return pagesLoad?.includes(value) ? pagesLoad : [...pagesLoad ?? [], value];
    }

    @observable
    subscriptionChats?: Subscription;

    @observable
    subscriptionConnection?: Subscription;

    @observable
    searchParam = '';

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    subscriptionUploadFile?: Subscription;

    @observable
    lastUsedReactions: string[] =
        (
            (localStorage.getItem(ConstantsKeys.lastUsedReactions as string) as string[] | null)
                ?
                JSON.parse(localStorage.getItem(ConstantsKeys.lastUsedReactions as string)!)
                :
                ['👍', '❤️', '😂', '😮', '😢', '🙏']
        );


    user?: UserEntity;

    activeRoute?: ActiveRouteEnum;

    navigate?: NavigateFunction;

    @action
    setForceCleanMessagesCache(value: boolean) {
        this.forceCleanMessagesCache = value;
    }

    @action
    setActiveTabChat(value: TabsChat) {
        this.activeTabChat = value;
    }

    @action
    setIdMessageFocused(id: string | null) {
        this.idMessageFocused = id;
    }

    @action
    setScrollPosition(value: 'top' | 'bottom' | null) {
        this.scrollPosition = value;
    }

    @action
    filterPredicate(ticket: TicketEntity) {
        return (ticket.usersInfo!.filter((user) =>
                user.name.toLowerCase().normalize('NFD').includes(this.searchParam.toLowerCase().normalize('NFD'))
                ||
                user.telephone?.toLowerCase().normalize('NFD').includes(this.searchParam.toLowerCase().normalize('NFD'))
            )
            ||
            ticket.sector?.name.toLowerCase().normalize('NFD').includes(this.searchParam.toLowerCase().normalize('NFD')));
    }

    @action
    setTableListWaiting(value: ListPaginationEntity<TicketEntity>) {
        this.tableListWaiting = value;
    }

    @action
    setSoundQueueWaitChat(value: boolean) {
        this.soundQueueWaitingChat = value;
        localStorage.setItem(ConstantsKeys.soundQueueWaitingChat, value.toString());
    }

    @action
    setSoundNewMessageChat(value: boolean) {
        this.soundNewMessageChat = value;
        localStorage.setItem(ConstantsKeys.soundNewMessageChat, value.toString());
    }

    @action
    setCompactModeMessage(value: boolean) {
        this.compactModeMessages = value;
        localStorage.setItem(ConstantsKeys.compactModeMessages, value.toString());
    }

    @action
    setIsSearchByMyChats(value: boolean) {
        this.isSearchByMyChats = value;
    }

    @action
    setChatConnection(value: ChatIntegrationConnectionEntity) {
        this.chatConnection = value;
    }

    orderTicketsByUpdateAt(tickets: TicketEntity[]) {
        tickets.sort((a, b) => new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime());
    }

    orderTicketsByCreatedAt(tickets: TicketEntity[]) {
        tickets.sort((a, b) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime());
    }

    orderMessagesByCreatedAt(messages: TicketMessageEntity[]) {
        return messages.sort((a, b) => new Date(b.realCreatedAt).getTime() - new Date(a.realCreatedAt).getTime());
    }

    pushWaitingChatData(value: ListPaginationEntity<TicketEntity>) {
        this.setTableListWaiting(this.tableListWaiting.copyWith({
            ...value,
            data: [...this.tableListWaiting.data, ...value.data]
        }));
    }

    @action
    setTableListChats(value: ListPaginationEntity<TicketEntity>) {
        this.tableListChats = value;
    }

    pushAttendingChatData(value: ListPaginationEntity<TicketEntity>) {
        this.setTableListChats(this.tableListChats.copyWith({
            ...value,
            data: [...this.tableListChats.data, ...value.data]
        }));
    }

    @action
    setSearchParam(value: string) {
        this.searchParam = value;
    }

    @action
    resetTable() {
        if (this.isSearchByMyChats) {
            this.setTableListChats(ListPaginationEntity.defaultValue<TicketEntity>());
        } else {
            this.setTableListWaiting(ListPaginationEntity.defaultValue<TicketEntity>())
        }
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    subscribeConnection = (onSuccess: (key: string) => void, onDuplicated: () => void, onExpired: (key: string) => void): Promise<void> => {
        return new Promise<void>(async (resolve, _) => {
            this.subscriptionConnection?.unsubscribe();
            this.subscriptionConnection = this._subscribeChatConnectionUseCase.call().subscribe({
                next: (connection) => {
                    this.setChatConnection(connection);
                    if (connection.duplicated) {
                        onDuplicated();
                    }
                    if (connection.hasActiveConnection) {
                        onSuccess("chat.success.connection");
                    }
                    if (connection.hasInactiveConnection) {
                        onExpired("chat.expiredConnect")
                    }
                    return resolve();
                },
                error: (err: any) => {
                    toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                    this.stopLoading();
                    this.subscriptionConnection?.unsubscribe();
                    return resolve();
                }
            });
        });
    }
    @action
    startConnection = async (onSuccess: (key: string) => void, onDuplicated: () => void, onExpired: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("chat.initConnection");
            const connection = await this._startConnectionChatsUseCase.call();
            this.setChatConnection(this.chatConnection.copyWith({
                ...connection
            }));
            if (!connection.hasActiveConnection && !connection.duplicated) {
                await this.subscribeConnection(onSuccess, onDuplicated, onExpired);
            }
            if (connection.hasActiveConnection) {
                onSuccess("chat.success.connection");
            }
            if (connection.duplicated) {
                onDuplicated();
            }
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    findPageById(messageId: string) {
        const data = this.cacheChat[this.currentChat?.id ?? 'unknown'];
        for (const page in data) {
            if (data.hasOwnProperty(page)) {
                const objectsOnPage = data[page];
                for (const object of objectsOnPage) {
                    if (object.id === messageId || object.idExternal === messageId) {
                        return parseInt(page);
                    }
                }
            }
        }
        return null;
    }


    @action
    handleMoveCursorToFinal = () => {
        const messages = this.getMessagesCacheFromPage(0);
        this.updateMessage(
            this.currentChat!,
            messages,
            false,
            true,
            this.currentChatMessages.copyWith({
                page: 0,
            }),
            false,
            true
        );
    }

    @action
    handleResetMessageFocused = () => {
        this.setIdMessageFocused(null);
    }

    @action
    logout = async (onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("chat.logout");
            await this._logoutChatsUseCase.call();
            this.setChatConnection(this.chatConnection.copyWith({
                qrCode: null,
                status: StatusTypes.DISCONNECTED
            }));
            onSuccess("chat.success.logout");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    connectionState = async (): Promise<void> => {
        try {
            this.startLoading("chat.connectionState");
            const response = await this._connectionStateChatsUseCase.call();
            this.setChatConnection(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }
    @action
    listChatsAttending = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading();
            this.setIsSearchByMyChats(true);
            this.eraseChat();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listChatsAttendingUseCase.call(searchParam, this.tableListChats.page);
            this.setTableListChats(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    listChatsWaiting = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading();
            this.setIsSearchByMyChats(false);
            this.eraseChat();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listChatsWaitingChatUseCase.call(searchParam, this.tableListWaiting.page);
            this.setTableListWaiting(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    listAllChats = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading("ticket.loadingChatInit");
            this.setSearchParam(searchParam ?? '');
            const response = await Promise.all([
                this._listChatsAttendingUseCase.call(searchParam, this.tableListChats.page),
                this._listChatsWaitingChatUseCase.call(searchParam, this.tableListWaiting.page)
            ]);
            this.setTableListChats(response[0]);
            this.setTableListWaiting(response[1]);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };
    @action
    getDataFromPageChatsWaiting = async (): Promise<void> => {
        if (this.tableListWaiting.hasMore) {
            this.startLoading();
            const newPage = this.tableListWaiting.page + 1;
            const response = await this._listChatsWaitingChatUseCase.call(this.searchParam, newPage);
            this.pushWaitingChatData(response);
            this.stopLoading();
        }
    }
    @action
    getDataFromPageChatsAttending = async (): Promise<void> => {
        if (this.tableListChats.hasMore) {
            this.startLoading();
            const newPage = this.tableListChats.page + 1;
            const response = await this._listChatsAttendingUseCase.call(this.searchParam, newPage);
            this.pushAttendingChatData(response);
            this.stopLoading();
        }
    }

    @action
    private async listChatMessagesAfterLastSubscription(data: TicketEntity[]): Promise<void> {
        try {
            const canFetchMessages: TicketEntity | null = data.find((ticket: TicketEntity) => ticket.users![this.currentChat?.userClient?.id ?? 'noUser']) ?? null;
            const currentChat = this.currentChat!;
            if (canFetchMessages) {
                const response: TicketMessageEntity[] = await this._listChatMessagesAfterLastSubsUseCase.call(currentChat.id);
                this.updateMessage(currentChat, response, true, true);
                this.informReadMessagesIfNeeded(response).then();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
        }
    }

    @action
    async informReadMessagesIfNeeded(messages: TicketMessageEntity[]) {
        const isNecessaryReadMessages =
            messages.filter((entry) => entry.senderId !== this.user!.id).length > 0
            && messages.filter((entry) => !!entry.readers && !entry.readers[this.user!.id]).length > 0;
        if (isNecessaryReadMessages) {
            this.informReadMessages().then();
        }
    }

    @action
    async informReadMessages(cancelTokenSource?: CancelTokenSource) {
        try {
            const currentChat = this.currentChat;
            if (currentChat) {
                this.resetCountNotification(currentChat.id);
                await this._informReadMessageUseCase.call(currentChat.id, cancelTokenSource);
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
        }
    }

    private resetCountNotification(ticketId: string): void {
        let index: number | undefined = this.tableListChats.data.findIndex(item => ticketId == item.id);
        let isTableListChats = true;
        if (index === -1) {
            isTableListChats = false;
            index = this.tableListWaiting.data.findIndex(item => ticketId == item.id);
        }
        if (index > -1) {
            if (isTableListChats) {
                this.tableListChats.data[index] = this.tableListChats.data[index].copyWith({
                    countUnreadNotification: 0
                });
            } else {
                this.tableListWaiting.data[index] = this.tableListWaiting.data[index].copyWith({
                    countUnreadNotification: 0
                });
            }
        }
    }

    @action
    private notificationAudioNewMessage(tickets: TicketEntity[]) {
        const list = tickets.filter((item) => item.hasAttendingStatus && item.attendant?.id === this.user?.id);
        for (const ticketEntity of list) {
            if (this.soundNewMessageChat && this.currentChat?.id !== ticketEntity.id && ticketEntity.soundPlayed) {
                this.notificationNewMessage?.play();
            }
        }
    }

    @action
    playAudioWaitingListDepending(): void {
        if (this.filteredListWaiting.length > 0) {
            if (!this.notificationWaiting?.playing() && this.soundQueueWaitingChat) {
                this.playAudioWaitingList();
            }
            if (this.notificationWaiting?.playing() && !this.soundQueueWaitingChat) {
                this.stopNotificationWaiting();
            }
        } else {
            this.stopNotificationWaiting();
        }
    }

    @action
    playAudioWaitingList(): void {
        this.notificationWaiting?.play();
    }

    @action
    stopNotificationWaiting(): void {
        this.notificationWaiting?.stop();
    }

    private initChatDependant(tickets: TicketEntity[]) {
        for (const ticket of tickets) {
            if (
                ticket.isMeStartingChat &&
                (
                    (!this.currentChat) ||
                    (this.currentChat?.id === ticket.id && this.currentChat?.hasOpenStatus)
                )
            ) {
                this.handleInitChat(ticket);
            }
        }
    }

    @action
    initChatDependantFromContact(ticket: TicketEntity) {
        if (ticket.attendant?.id === this.user?.id) {
            this.handleInitChat(ticket);
        }
    }

    private getUniqueList(originalList: TicketEntity[], newList: TicketEntity[]): TicketEntity[] {
        const result = originalList;

        if (newList.length) {
            for (const ticket of newList) {
                const index = originalList.findIndex((item) => item.id === ticket.id);
                if (index > -1) {
                    result[index] = ticket;
                } else {
                    result.push(ticket);
                }
            }
        }

        return result;
    }

    handleChangeListWaiting(data: TicketEntity[]): void {
        const waitingList = data.filter((item) => item.hasOpenStatus);
        this.removeUnEligibleTable(waitingList, this.tableListWaiting);
        const result = this.getUniqueList(this.tableListWaiting.data, waitingList);
        this.orderTicketsByCreatedAt(result);
        this.setTableListWaiting(this.tableListWaiting.copyWith({
            totalRows: result.length,
            pages: Math.ceil(data.length / ConstantsKeys.defaultLimit),
            data: result,
        }));
    }

    handleChangeListAttending(data: TicketEntity[]): void {
        const attendingList = data.filter((item) => item.hasAttendingStatus && item.attendant?.id === this.user?.id);
        this.removeUnEligibleTable(attendingList, this.tableListChats);
        const result = this.getUniqueList(this.tableListChats.data, attendingList);
        this.orderTicketsByUpdateAt(result);
        this.setTableListChats(this.tableListChats.copyWith({
            totalRows: result.length,
            pages: Math.ceil(data.length / ConstantsKeys.defaultLimit),
            data: result,
        }));
    }

    @action
    handleInitChat(ticket: TicketEntity) {
        this.setActiveTabChat(TabsChat.attending);
        this.setChat(ticket);
        this.handleUpdateTicket(ticket);
    }

    @action
    handleUpdateTicket(ticket: TicketEntity) {
        const data = [...this.tableListWaiting.data, ...this.tableListChats.data];
        const index = data.findIndex((item) => item.id === ticket.id);
        if (index > -1) {
            data[index] = ticket;
        }
        this.handleChangeListWaiting(data);
        this.handleChangeListAttending(data);
    }

    public preventUnauthorizedAccessChat(tickets: TicketEntity[]) {
        for (const ticket of tickets) {
            const canAccess = ticket.canAccess(this.user!);
            if (!canAccess) {
                if (this.currentChat && this.currentChat.id === ticket.id) {
                    this.eraseChat();
                }
                if (this.activeRoute === ActiveRouteEnum.ticket && this.navigate) {
                    this.navigate(RoutesEnum.tickets);
                    toastMessage.warning('Você não tem permissão para acessar este atendimento ou ' +
                        'não faz parte do seu setor de atuação, por favor verifique.');
                }
            }
        }
    }

    private getUniqueCacheMessagesList(originalList: TicketMessageEntity[], newMessages: TicketMessageEntity[], isActionDelete = false): TicketMessageEntity[] {
        const result = originalList;

        if (newMessages.length) {
            for (const message of newMessages) {
                const index = originalList.findIndex((item) => item.id === message.id);
                if (index > -1) {
                    if (isActionDelete) {
                        result.splice(index, 1);
                    } else {
                        result[index] = result[index].getMessagePreventDefaultCache(message);
                    }
                } else {
                    result.push(message);
                }
            }
        }

        return result;
    }

    @action
    removeAllValues(): void {
        this.cacheChat = {};
        this._clearTicketsCacheUseCase.call();
        this.setCurrentChatMessages(ListPaginationEntity.defaultValue<TicketMessageEntity>());
        this.setTableListChats(ListPaginationEntity.defaultValue<TicketEntity>());
        this.setTableListWaiting(ListPaginationEntity.defaultValue<TicketEntity>());
        this.eraseChat();
    }

    @action
    setCacheChat(ticket: TicketEntity, messages: TicketMessageEntity[], page: number, isActionDelete = false): void {
        const cache = this.cacheChat[ticket.id] ?? {};
        const messagesFromPage = cache[page] ?? [];
        cache[page] = this.getUniqueCacheMessagesList(messagesFromPage, messages, isActionDelete);
        this.cacheChat[ticket.id] = cache;
    }

    @action
    updateCacheMessagesFromSubs(tickets: TicketEntity[]): void {
        const keys = Object.keys(this.cacheChat);

        const toRemove: string[] = keys.filter(key =>
            !tickets.some(ticket => ticket.id === key)
        );

        for (const key of toRemove) {
            if (this.activeRoute === ActiveRouteEnum.chat) {
                delete this.cacheChat[key];
            }
        }

        for (const ticket of tickets) {
            if (ticket.lastMessage) {
                this.setCacheChat(ticket, [ticket.lastMessage!], 0);
            }
        }
    }

    @action
    removeUnEligibleTable(tickets: TicketEntity[], table: ListPaginationEntity<TicketEntity>): void {
        const tableData = table.data;
        const toRemove: string[] = tableData.filter(item =>
            !tickets.some(ticket => ticket.id === item.id)
        ).map(item => item.id);

        for (let i = tableData.length - 1; i >= 0; i--) {
            const item = tableData[i];
            const index = toRemove.findIndex((entry) => entry == item.id);
            if (index > -1) {
                if (this.currentChat?.id === item.id) {
                    this.eraseChat();
                }
                tableData.splice(i, 1);
            }
        }

    }

    @action
    private setMessagesToActiveChat(data: TicketEntity[]): void {
        const target: TicketEntity | null = data.find((ticket: TicketEntity) => ticket.users![this.currentChat?.userClient?.id ?? 'noUser']) ?? null;
        if (target && target.lastMessage) {
            this.updateMessage(target, [target.lastMessage], true, true);
        }
    }

    @action
    private setChatToActiveChatFromSubs(data: TicketEntity[]): void {
        const target: TicketEntity | null = data.find((ticket: TicketEntity) => ticket.users![this.currentChat?.userClient?.id ?? 'noUser']) ?? null;
        if (target) {
            this.setChat(target, false);
        }
    }

    @action
    observerChats = (id?: string): void => {
        this.subscriptionChats?.unsubscribe();
        this.subscriptionChats = this._subscribeChatsUseCase.call(id).subscribe({
            next: (tickets) => {
                this.preventUnauthorizedAccessChat(tickets);
                this.updateCacheMessagesFromSubs(tickets);
                this.setChatToActiveChatFromSubs(tickets);
                this.setMessagesToActiveChat(tickets);
                if (this.activeRoute === ActiveRouteEnum.chat) {
                    this.handleChangesByChats(tickets);
                }
                this.listChatMessagesAfterLastSubscription(tickets).catch((_): void => {
                    toastMessage.error(translate('chat.error.error_messages_subs'));
                });

            },
            error: (err: any) => {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                this.stopLoading();
            }
        });
    }

    @action
    handleChangesByChats(tickets: TicketEntity[]) {
        this.handleChangeListWaiting(tickets);
        this.handleChangeListAttending(tickets);
        this.initChatDependant(tickets);
        this.notificationAudioNewMessage(tickets);
    }

    @action
    initialize = async (
        userLogged: UserEntity,
        activeRoute: ActiveRouteEnum,
        id?: string,
        navigate?: NavigateFunction,
    ): Promise<void> => {
        try {
            localStorage.removeItem(ConstantsKeys.draftMessageCache);
            this.user = userLogged;
            this.navigate = navigate;
            this.activeRoute = activeRoute;
            if (this.activeRoute === ActiveRouteEnum.chat) {
                initializeConfigurationLocalStorage(RoutesEnum.chatdefault);
                await this.listAllChats('');
                this.notificationWaiting = new Howl({
                    src: [soundNotificationWaitingList],
                    loop: true,
                    volume: 0.5,
                });

                this.notificationNewMessage = new Howl({
                    src: [soundNotificationMessage],
                    volume: 0.6,
                });
            }
            if (this.activeRoute === ActiveRouteEnum.ticket) {
                this.preventUnauthorizedAccessChat([this.currentChat!]);
            }
            this.observerChats(id);
        } catch (error) {
            console.log('error initialize', error);
        }
    }

    @action
    handleClickPushNotification = async (
        ticket: TicketEntity,
    ): Promise<void> => {
        try {
            const ticketAlreadySelected = ticket.id === this.currentChat?.id;
            this.setChat(ticket, !ticketAlreadySelected);
            this.setActiveTabChat(ticket.hasAttendingStatus ? TabsChat.attending : TabsChat.waiting);
        } catch (error) {
            console.error('error initialize', error);
        }
    }

    @action
    setChat(ticket: TicketEntity, eraseMessages = true): void {
        const ticketFromJson = TicketEntity.fromJson(ticket as Record<string, any>);
        const newTicket = this.currentChat && this.currentChat.id === ticket.id ? ticketFromJson.preventDeleteRelations(this.currentChat) : ticketFromJson;
        this.currentChat = newTicket;
        if (eraseMessages) {
            this.eraseMessages();
        }
    }

    @action
    setCurrentChatMessages(value: ListPaginationEntity<TicketMessageEntity>) {
        this.currentChatMessages = value;
    }

    @action
    updateMessage(
        ticket: TicketEntity,
        message: TicketMessageEntity[],
        unshiftMessage = false,
        sortMessages = false,
        newPaginationMessages: ListPaginationEntity<TicketMessageEntity> | undefined = undefined,
        isActionDelete = false,
        isReplaceMessage = false
    ) {
        const page = newPaginationMessages?.page ?? 0;
        this.setCacheChat(ticket, message, page, isActionDelete);
        this.handleSetCurrentChatMessages(message, unshiftMessage, sortMessages, newPaginationMessages, isActionDelete, isReplaceMessage);
    }

    @action
    eraseChat(): void {
        this.currentChat = null;
    }

    @action
    getMessagesCacheFromPage(page: number): TicketMessageEntity[] {
        const cache = this.cacheChat[this.currentChat?.id ?? 'unknown'] ?? {};
        const messages = cache[page] ?? [];
        return messages.map(message => TicketMessageEntity.fromJson(message));
    }

    @action
    eraseMessages(): void {
        const data = this.getMessagesCacheFromPage(0);
        const initialListPagination = new ListPaginationEntity<TicketMessageEntity>({
            pages: 0,
            page: 0,
            limit: ConstantsKeys.defaultLimit,
            totalRows: 0,
            data,
            hasMore: false,
            hasMoreBottom: false
        });
        if (data && data.length) {
            this.updateMessage(this.currentChat!, data, false, true, initialListPagination, false, true);
        } else {
            this.setCurrentChatMessages(initialListPagination);
        }
    }

    @action
    async loadStaticMessages(cancelTokenSource?: CancelTokenSource): Promise<void> {
        try {
            const currentChat = this.currentChat;
            if (currentChat) {
                this.startLoading();
                const response = await this._listChatMessagesUseCase.call(this.currentChat!.id, this.currentChatMessages.page, cancelTokenSource);
                this.updateMessage(currentChat, response.data, true, true, response);
                this.stopLoading();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    makeStartingAndEndTime(messages: TicketMessageEntity[]): TicketMessageEntity[] {
        const allMessages = [...messages, ...this.reactions];

        for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            if (
                (message.position && !message.ticket)
                ||
                (message.idTicket === this.currentChat?.id && !this.currentChat?.hasActiveStatus && !message.ticket?.dateClosed)
            ) {
                const newTicket: TicketEntity | undefined = allMessages.find((entry) => (entry.idTicket === message.idTicket && !!entry.ticket))?.ticket as TicketEntity | undefined;
                const ticket = newTicket && newTicket ? TicketEntity.fromJson(newTicket) : undefined;
                message.ticket =
                    (ticket && !!ticket.dateClosed)
                        ?
                        ticket
                        :
                        message.idTicket === this.currentChat?.id
                            ?
                            this.currentChat!
                            :
                            undefined;
            }
        }
        const reactionWithPosition = this.reactions.filter((reactionMessage) => !!reactionMessage.position);
        for (let i = 0; i < reactionWithPosition.length; i++) {
            const reaction = reactionWithPosition[i];
            const messagesById = this.orderMessagesByCreatedAt(messages.filter((message) => message.idTicket === reaction.idTicket));
            if ((reaction.isFirstPosition || reaction.isUniquePosition) && reaction.ticket) {
                const firstMessageById = messagesById[messagesById.length - 1];
                if (firstMessageById) {
                    firstMessageById.position = firstMessageById.position ?? reaction.position;
                    firstMessageById.ticket = reaction.ticket;
                }
            }

            if (reaction.isLastPosition || reaction.isUniquePosition) {
                const lastMessageById = messagesById[0];
                if (lastMessageById) {
                    lastMessageById.position = lastMessageById.position ?? reaction.position;
                    if (!reaction.ticket) {
                        const newTicket: TicketEntity | undefined = allMessages.find((entry) => (entry.idTicket === reaction.idTicket && !!entry.ticket))?.ticket as TicketEntity | undefined;
                        lastMessageById.ticket = newTicket;
                    }
                }
            }
        }
        return messages;
    }

    private getNextPage(scrollPosition: 'top' | 'bottom'): number {
        if (!!this.currentChatMessages.pagesLoad) {
            if (scrollPosition === 'top') {
                return findMaxNumber(this.currentChatMessages.pagesLoad) + 1;
            }
            return (findMinNumber(this.currentChatMessages.pagesLoad) - 1) ?? 0;
        } else {
            if (scrollPosition === 'top') {
                return this.currentChatMessages.page + 1;
            }
            return this.currentChatMessages.page - 1;
        }
    }

    @action
    handleClickOnMessageReply = async (reply: ReplyEntity) => {
        try {
            const page = this.findPageById(reply.messageId);
            const currentChat = this.currentChat!;
            if (page !== null) {
                this.startLoading();
                const cacheMessages = this.getMessagesCacheFromPage(page);
                this.updateMessage(currentChat, cacheMessages, false, true,
                    this.currentChatMessages.copyWith({
                        page: page,
                    }), false, true);
                await sleep(.1);
                this.setIdMessageFocused(reply.messageId);
                this.stopLoading();
            } else {
                this.startLoading();
                this.setForceCleanMessagesCache(true);
                const response = await this._navigateToMessageUseCase.call(reply.messageId, this.currentChat!.number);
                this.updateMessage(currentChat, response.data, false, true, response, false, true);
                this.setForceCleanMessagesCache(false);
                await sleep(.5);
                this.setIdMessageFocused(reply.messageId);
                this.stopLoading();
            }

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
            this.setForceCleanMessagesCache(false);
        }
    }

    @action
    loadMoreMessages = async (scrollPosition: 'top' | 'bottom'): Promise<boolean> => {
        try {
            if (!this.loading) {
                this.setScrollPosition(scrollPosition);
                const nextPage = this.getNextPage(scrollPosition);
                const currentChat = this.currentChat!;
                const cacheMessageByNextPage = this.getMessagesCacheFromPage(nextPage);
                this.startLoading();
                if (cacheMessageByNextPage && cacheMessageByNextPage.length > 0) {
                    this.updateMessage(
                        currentChat,
                        cacheMessageByNextPage,
                        false,
                        true,
                        this.currentChatMessages.copyWith({
                            page: nextPage,
                        }),
                    );
                    await sleep(.5);
                    return true;
                } else if (
                    (this.currentChatMessages.hasMore && scrollPosition === 'top') ||
                    (this.currentChatMessages.hasMoreBottom && scrollPosition === 'bottom')
                ) {
                    const response = await this._listChatMessagesUseCase.call(this.currentChat!.id, nextPage);
                    this.updateMessage(currentChat, response.data, false, true, response);
                    await sleep(.5);
                    return true;
                }
            }
            return false;
        } catch (err: any) {
            this.stopLoading();
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            return false;
        }

    }


    @action
    private handleSetCurrentChatMessages(
        newMessages: TicketMessageEntity[],
        unshiftMessage: boolean = false,
        sortMessages: boolean = true,
        newPaginationMessages?: ListPaginationEntity<TicketMessageEntity>,
        isActionDelete = false,
        isReplaceMessage = false
    ): void {
        const canUpdateMessage: boolean = !!newMessages.find((message: TicketMessageEntity) => message.users![this.currentChat?.userClient?.id ?? 'noUser']) ?? false;
        if (canUpdateMessage) {
            const messages: TicketMessageEntity[] = this.currentChatMessages.data;

            for (const message of newMessages) {
                const index = messages.findIndex((element) => element.id === message.id);
                if (index > -1) {
                    if (isActionDelete) {
                        messages.splice(index, 1);
                    } else {
                        messages[index] = messages[index].getMessagePreventDefaultCache(message);
                    }
                } else {
                    if (unshiftMessage) {
                        messages.unshift(message);
                    } else {
                        messages.push(message);
                    }
                }
            }

            if (sortMessages) {
                messages.sort((a, b) => (new Date(b.realCreatedAt).getTime() - (new Date(a.realCreatedAt).getTime())));
            }

            const currentPage = this.currentChatMessages.page;
            const newPage = newPaginationMessages?.page;
            const page = newPage ?? currentPage;
            const hasMoreBottom = !!(isReplaceMessage && (newPage && newPage > 0));

            this.setCurrentChatMessages(this.currentChatMessages.copyWith({
                data: isReplaceMessage ? newMessages : messages,
                page: newPaginationMessages?.page ?? this.currentChatMessages.page,
                pages: newPaginationMessages?.pages ?? this.currentChatMessages.pages,
                totalRows: newPaginationMessages?.totalRows ?? this.currentChatMessages.totalRows,
                limit: newPaginationMessages?.limit ?? this.currentChatMessages.limit,
                hasMore: newPaginationMessages?.hasMore ?? this.currentChatMessages.hasMore,
                hasMoreBottom: hasMoreBottom ? true : newPage === 0 ? false : this.currentChatMessages.hasMoreBottom,
                pagesLoad: isReplaceMessage ? [page] : this.addPagesLoad(page)
            }));
        }
    }

    @action
    async sendMessage(
        body: BodyMessage,
    ): Promise<void> {
        return new Promise<void>(async (resolve, _) => {
            try {
                this.updateMessage(body.ticket, [body.message], true, false);
                if (body.message.isFileMessage && body.message.isUnsaved()) {
                    body.message = await this.uploadFile(body);
                    const messages = this.cacheChat[body.ticket.id][0];
                    await when(
                        () => {
                            const index = messages.findIndex(msg => msg.id === body.message.id);
                            return messages.slice(0, index).every(previousMessage =>
                                previousMessage.createdAt || previousMessage.hasSendError
                            );
                        },
                        {timeout: 60000 * 3}
                    ).catch((error) => console.error(`Mensagem não enviada no tempo limite ${error}`));
                }
                await this.writeMessage(body);
                return resolve();
            } catch (error: any) {
                this.handleWriteMessageError(body.ticket, body.message);
                toastMessage.error(translate(`server_messages.${error?.code ?? error?.message ?? 'unknown'}`));
            }
        });
    }

    handleWriteMessageError(ticket: TicketEntity, message: TicketMessageEntity): void {
        const messageError = message.copyWith({
            status: MessageStatus.ERROR
        });
        this.updateMessage(ticket, [messageError]);
    }


    @action
    writeMessage = async (
        body: BodyMessage,
    ): Promise<TicketMessageEntity> => {
        return new Promise<TicketMessageEntity>(async (resolve, reject) => {
            try {
                let _message = body.message;
                const associateSenderToTicket = !body.ticket.attendant && !_message.isPrivate && body.sender.isAttendant;
                _message = await this._sendMessageUseCase.call(_message, associateSenderToTicket);
                this.updateMessage(body.ticket, [_message], true, false);
                return resolve(_message);
            } catch (error: any) {
                return reject(error);
            }
        });
    }

    @action
    removeReaction = async (
        messageReacted: TicketMessageEntity,
        reaction: TicketMessageEntity,
        currentTicket: TicketEntity,
    ): Promise<void> => {
        try {
            const reactionToRemove = reaction.copyWith({
                deleted: true,
                reactionMessage: new ReactionMessageEntity({
                    text: '',
                    messageReactedId: reaction.reactionMessage!.messageReactedId,
                    fromMe: reaction.reactionMessage!.fromMe,
                    participantId: reaction.reactionMessage?.participantId ?? '',
                })
            });
            this.updateMessage(currentTicket, [reactionToRemove]);
            this.handleUpdateTicket(currentTicket.copyWith({
                lastMessage: this.currentChatMessages.data[0],
            }));
            await this._reactionMessageUseCase.call(messageReacted.id, reactionToRemove.id, '');

        } catch (err: any) {
            this.updateMessage(currentTicket, [reaction], undefined, undefined, undefined, false);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
        }
    }

    @action
    async handleAddReaction(
        messageToReacted: TicketMessageEntity,
        reactionText: string,
        userLogged: UserEntity,
        currentTicket: TicketEntity
    ): Promise<void> {
        this.setLastUsedReactions(reactionText);
        const reactionMessage: TicketMessageEntity | undefined = this.getReactionByUser(userLogged, messageToReacted);
        if (reactionMessage && reactionText === reactionMessage?.reactionMessage?.text) {
            await this.removeReaction(messageToReacted, reactionMessage, currentTicket);
        } else {
            await this.insertReaction(messageToReacted, reactionText, userLogged, currentTicket);
        }
    }


    private setLastUsedReactions = (reaction: string) => {
        const hasReaction: boolean = this.lastUsedReactions.filter((entry) => entry === reaction).length > 0;
        if (!hasReaction) {
            this.lastUsedReactions.pop();
            const lastUsedReactions = [reaction, ...this.lastUsedReactions];
            this.lastUsedReactions = lastUsedReactions;
            localStorage.setItem(ConstantsKeys.lastUsedReactions, JSON.stringify(lastUsedReactions));
        }
    }

    private insertReaction = async (
        messageReacted: TicketMessageEntity,
        reaction: string,
        userLogged: UserEntity,
        currentTicket: TicketEntity
    ): Promise<void> => {
        const body = new BodyMessage({
            message: newMessage(),
            ticket: this.currentChat!,
            sender: userLogged
        });
        const hasReaction = this.getReactionByUser(userLogged, messageReacted);
        body.message = body.message.copyWith({
            ...this.prepareToSend(body),
            id: hasReaction ? hasReaction.id : body.message.id,
            type: MessageChatType.reactionMessage,
            reactionMessage: new ReactionMessageEntity({
                text: reaction,
                messageReactedId: messageReacted.idExternal ?? messageReacted.id,
                fromMe: true,
                participantId: hasReaction && hasReaction.reactionMessage?.participantId ? hasReaction.reactionMessage?.participantId : ''
            })
        });
        try {
            this.updateMessage(body.ticket, [body.message]);
            this.handleUpdateTicket(currentTicket.copyWith({
                lastMessage: body.message,
            }));
            await this._reactionMessageUseCase.call(messageReacted.id, body.message.id, reaction);
        } catch (err: any) {
            this.updateMessage(body.ticket, [body.message], undefined, undefined, undefined, true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
        }
    }

    private getReactionByUser = (userLogged: UserEntity, messageToReacted: TicketMessageEntity): TicketMessageEntity | undefined => {
        return this.reactions.find((entry) => entry.senderId === userLogged.id && (messageToReacted.idExternal === entry.reactionMessage?.messageReactedId || messageToReacted.id === entry.reactionMessage?.messageReactedId));
    }

    @action
    editMessage = async (
        editText: string,
        currentMessage: TicketMessageEntity,
        currentTicket: TicketEntity
    ): Promise<void> => {
        try {
            if (!!editText && currentMessage.getTextEditable !== editText) {
                const editMessage = currentMessage.setEditableText(editText);
                this.updateMessage(currentTicket, [editMessage]);
                await this._editMessageUseCase.call(currentMessage.id, editText);
            }
        } catch (err: any) {
            this.updateMessage(currentTicket, [currentMessage]);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
        }
    }

    @action
    uploadFile = (
        body: BodyMessage,
    ): Promise<TicketMessageEntity> => {
        return new Promise<TicketMessageEntity>(async (resolve, reject) => {
            let _message = body.message;
            this.subscriptionUploadFile = this._subscriptionUploadFileUseCase.call(_message).subscribe({
                next: (response) => {
                    _message = response;
                    this.updateMessage(body.ticket, [response]);
                },
                error: (error: FirebaseStorageError) => {
                    return reject(error);
                },
                complete: () => {
                    this.updateMessage(body.ticket, [_message.removeUploadOptions().getMessagePreventDefaultCache(_message)]);
                    const newBody = new BodyMessage({
                        ticket: body.ticket,
                        sender: body.sender,
                        message: _message
                    });
                    return resolve(newBody.message);
                }
            })
        });
    }

    @action
    delete = async (currentTicket: TicketEntity, message: TicketMessageEntity): Promise<void> => {
        try {
            message.deleted = true;
            this.updateMessage(currentTicket, [message]);
            await this._deleteMessageUseCase.call(message.id);
        } catch (err: any) {
            message.deleted = false;
            this.updateMessage(currentTicket, [message]);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
        }
    }

    @action
    parseFileToMessage = (ticket: TicketEntity, fileMessages: FileMessageEntity[], sender: UserEntity): TicketMessageEntity[] => {
        return fileMessages.map((fileMessage) => {
                const type: MessageChatType = getMessageTypeFromFile(fileMessage);
                const {
                    imageMessage,
                    videoMessage,
                    documentMessage
                } = this.convertFileToMessageEntity(type, fileMessage);


                return new TicketMessageEntity({
                    deleted: false,
                    idCompany: getIdCompanyByLocalStorage(),
                    idTicket: ticket.id,
                    id: generateUUIDV4(),
                    sender,
                    senderId: sender.id,
                    audioMessage: null,
                    videoMessage,
                    imageMessage,
                    documentMessage,
                    blobURL: fileMessage.blobURL,
                    textMessage: null,
                    uploadOptions: new UploadOptionsEntity({
                        file: fileMessage,
                        progress: 0,
                    }),
                    type,
                    isPrivate: fileMessage.isPrivate,
                    fromMe: true,
                    status: MessageStatus.PENDING,
                    createdAtLocal: new Date().toISOString()
                })
            }
        );
    }

    @action
    parseAudioToMessage = async (
        ticket: TicketEntity,
        audioRecorder: AudioRecordEntity,
        sender: UserEntity,
        message: TicketMessageEntity
    ): Promise<TicketMessageEntity> => {
        const fileName = `audio${new Date().getTime()}.wav`;
        const mimeType = "audio/wav";
        const blob = await this._urlToBlobUseCase.call(audioRecorder.audioBlob!);
        const urlDownload = audioRecorder.audioBlob!;
        const file = new File([blob], fileName, {type: mimeType});
        const base64 = await fileToBase64(file);

        return new TicketMessageEntity({
            deleted: false,
            idCompany: getIdCompanyByLocalStorage(),
            idTicket: ticket.id,
            id: generateUUIDV4(),
            sender,
            senderId: sender.id,
            type: MessageChatType.audioMessage,
            blobURL: audioRecorder.audioBlob,
            audioMessage: new AudioMessageEntity({
                buffer: undefined,
                length: file.size,
                mimetype: mimeType,
                ptt: true,
                seconds: audioRecorder.duration
            }),
            uploadOptions: new UploadOptionsEntity({
                file: new FileMessageEntity({
                    base64Url: base64,
                    id: generateUUIDV4(),
                    file,
                    description: '',
                    blobURL: urlDownload,
                    isPrivate: message.isPrivate,
                }),
                progress: 0,
            }),
            createdAtLocal: new Date().toISOString(),
            isPrivate: message.isPrivate,
            fromMe: true,
            status: MessageStatus.PENDING,
            reply: message.reply
        })
    }

    @action
    prepareToSend = (body: BodyMessage, index?: number): TicketMessageEntity => {
        const {ticket, sender, message} = body;
        const senderId = body.sender.id;
        return message.copyWith({
            id: message.id ?? generateUUIDV4(),
            idCompany: getIdCompanyByLocalStorage(),
            idTicket: ticket.id,
            ticket: ticket,
            senderId: sender.id,
            users: ticket.users,
            sender: sender,
            received: {
                [senderId]: true,
            },
            readers: {
                [senderId]: true,
            },
            createdAtLocal: getNowSumIndex(index ?? 0)
        });
    }

    @action
    async retrySendMessage(
        message: TicketMessageEntity,
        currentTicket: TicketEntity,
        userLogged: UserEntity
    ): Promise<void> {
        const messageRetry = message.copyWith({
            status: MessageStatus.PENDING,
        });
        this.updateMessage(currentTicket, [messageRetry]);
        await this.handleSendMessages([messageRetry], currentTicket, userLogged);
    }

    @action
    pushMemoryMessages(messages: TicketMessageEntity[], currentTicket: TicketEntity, userLogged: UserEntity) {
        for (let i = 0; i < messages.length; i++) {
            messages[i] = messages[i].copyWith({
                ...this.prepareToSend(new BodyMessage({
                    message: messages[i],
                    ticket: currentTicket,
                    sender: userLogged
                }), i)
            });
        }
        this.updateMessage(currentTicket, messages, true, false);
        this.handleUpdateTicket(currentTicket.copyWith({
            lastMessage: messages[messages.length - 1],
        }));
    }

    @action
    async handleSendMessages(messages: TicketMessageEntity[], currentTicket: TicketEntity, userLogged: UserEntity): Promise<void> {
        this.pushMemoryMessages(messages, currentTicket, userLogged);
        for (let index = 0; index < messages.length; index++) {
            const body = new BodyMessage({
                message: messages[index],
                ticket: currentTicket!,
                sender: userLogged
            });
            this.sendMessage(body);
        }
    };


    convertFileToMessageEntity = (type: MessageChatType, fileMessage: FileMessageEntity) => {

        const imageMessage = type === MessageChatType.imageMessage ?
            ImageMessageEntity.fromFile(fileMessage)
            :
            null;

        const videoMessage = type === MessageChatType.videoMessage ?
            VideoMessageEntity.fromFile(fileMessage)
            :
            null;

        const documentMessage = (type === MessageChatType.documentMessage) ?
            DocumentMessageEntity.fromFile(fileMessage)
            :
            null;

        return {
            imageMessage,
            videoMessage,
            documentMessage
        };
    }

    @action
    dispose = (): void => {
        this.subscriptionChats?.unsubscribe();
        this.subscriptionConnection?.unsubscribe();
    }

    @action
    removeDuplicateMessages(messages: TicketMessageEntity[]): TicketMessageEntity[] {
        return messages.filter((message, index, _messages) => _messages.findIndex(_ticket => (_ticket.id === message.id)) === index);
    }

}
