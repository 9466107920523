import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import ModalInformationReport from "@dropDesk/presentation/components/modals/modal_information_report";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {
    useModalsReportTicket
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/ui/modals/handle_change_visible";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import ModalSearchUser from "@dropDesk/presentation/components/modals/modal_search_user";
import ModalSearchClient from "@dropDesk/presentation/components/modals/modal_search_client";
import ModalSearchSector from "@dropDesk/presentation/components/modals/modal_search_sector";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ModalExport from "@dropDesk/presentation/components/modals/modal_information";
import {useNavigate} from "react-router-dom";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsReportTicket = observer(
    ({
         onSelectSector,
         onSelectUser,
         onSelectClient,
         loading,
         getReportTicket,
         exportPdf,
         period,
         idUserLogged

     }: {
         onSelectSector: (sector: SectorEntity) => void,
         onSelectUser: (user: UserEntity) => void,
         onSelectClient: (client: ClientEntity) => void,
         getReportTicket: () => void,
         exportPdf: () => void,
         loading: boolean,
         period: Array<Date> | null;
         idUserLogged?: string
     }
    ) => {

        const {
            visibleModalSearchUser, setVisibleModalSearchUser,
            visibleModalSearchClient, setVisibleModalSearchClient,
            visibleModalSearchSector, setVisibleModalSearchSector,
            visibleModalInformationReport, setVisibleModalInformationReport,
            textHeaderModalInformationReport,
            textInformationBody,
            textInformationHeader,
            visibleModalSearchContacts, setVisibleModalSearchContacts,
            visibleModalExportPdf, setVisibleModalExportPdf,
            visibleModalExport, setVisibleModalExport
        } = useBetween(useModalsReportTicket);

        const navigate = useNavigate();

        return (
            <>
                <ModalInformationReport
                    open={visibleModalInformationReport}
                    onRequestClose={() => {
                        setVisibleModalInformationReport(false)
                    }}
                    textHeaderModal={textHeaderModalInformationReport}
                    textButton={'OK'}
                    onRequestSave={async () => {
                        setVisibleModalInformationReport(false);
                    }}
                    textInformationHeader={textInformationHeader}
                    textInformationBody={textInformationBody}
                    loading={loading}
                />

                {visibleModalSearchUser && <ModalSearchUser
                    visible={visibleModalSearchUser}
                    onClose={() => setVisibleModalSearchUser(false)}
                    role={UserRole.attendant}
                    handleSelectUser={async (user) => {
                        onSelectUser(user);
                        getReportTicket();
                        setVisibleModalSearchUser(false);
                    }}
                    textHeaderModal={"Pesquisa de Atendentes"}
                    labelNoResults={"Nenhum usuário encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por atendentes"}
                />}

                {visibleModalSearchContacts && <ModalSearchUser
                    visible={visibleModalSearchContacts}
                    onClose={() => setVisibleModalSearchContacts(false)}
                    role={UserRole.userClient}
                    handleSelectUser={async (user) => {
                        onSelectUser(user);
                        getReportTicket();
                        setVisibleModalSearchContacts(false);
                    }}
                    textHeaderModal={"Pesquisa de Contatos"}
                    labelNoResults={"Nenhum contato encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por contatos"}
                />}

                {visibleModalSearchClient && <ModalSearchClient
                    visible={visibleModalSearchClient}
                    onClose={() => setVisibleModalSearchClient(false)}
                    handleSelectClient={(client) => {
                        onSelectClient(client);
                        getReportTicket();
                        setVisibleModalSearchClient(false);
                    }}
                    textHeaderModal={"Selecione a empresa"}
                    labelNoResults={"Nenhuma empresa encontrada na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por uma empresa"}
                />}

                {visibleModalSearchSector && <ModalSearchSector
                    visible={visibleModalSearchSector}
                    onClose={() => setVisibleModalSearchSector(false)}
                    handleSelectSector={(sector) => {
                        onSelectSector(sector);
                        getReportTicket();
                        setVisibleModalSearchSector(false);
                    }}
                    textHeaderModal={"Pesquisa de Setores"}
                    labelNoResults={"Nenhum setor encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por setores"}
                    idUser={idUserLogged}
                />}
                <ModalInformation
                    open={visibleModalExportPdf}
                    onRequestClose={() => {
                        setVisibleModalExportPdf(false);
                    }}
                    textHeaderModal={'Exportar gráficos'}
                    textButton={'Exportar'}
                    onRequestSave={async () => {
                        exportPdf();
                        setVisibleModalExportPdf(false);
                    }}
                    nameIcon={ICONS_DROPDESK.pdf}
                    sizeIcon={115}
                    textInformation={`Deseja realmente exportar os gráficos do relatório para um arquivo PDF?`}
                    loading={loading}
                />
                <ModalExport
                    open={visibleModalExport}
                    onRequestClose={() => {
                        setVisibleModalExport(false);
                    }}
                    textHeaderModal={'Exportar atendimentos'}
                    textButton={'Exportar'}
                    onRequestSave={() => {
                        if (period && period[0] && period[1]) {
                            navigate(`${RoutesEnum.exportreporttickets}`);
                        } else {
                            toastMessage.error("Você deve informar um período de exportação.")
                        }
                        setVisibleModalExport(false);
                    }}
                    nameIcon={ICONS_DROPDESK.excel}
                    sizeIcon={120}
                    textInformation={
                        `Deseja realmente exportar todos os dados dos atendimentos para uma planilha?`
                    }
                    loading={loading}
                />
            </>
        )
    })
export default ModalsReportTicket;
