import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Container, ContainerInputs, Content, LabelPayments,
} from "./styled";
import React from "react";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import InputAddress from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/inputs_address";
import InputsInfoHolder from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/inputs_info_holder";
import Header from "src/presentation/pages/subscription/ui/checkout/payment_info/header";
import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import FooterPaymentInfo from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/footer";
import {observer} from "mobx-react";

const InputsPaymentInfo = observer((
    {
        newPaymentInfo,
        onUpdate,
        loading,
        onSearchCNPJ,
        onSearchZipCode,
        onClickSavePaymentInfo,
        setActiveRoute,
        backPreviousScreenExternalScreen,
        isEditExternal,
        canReturnPaymentType,
        handleEditPaymentInfo
    }: {
        newPaymentInfo: CompanyPaymentInfo
        onUpdate: (newPaymentInfo: CompanyPaymentInfo) => void
        loading: boolean
        onSearchCNPJ: (document: string) => void,
        onSearchZipCode: (zipCode: string) => void,
        onClickSavePaymentInfo: () => void
        setActiveRoute: (route: RoutesSubscription) => void
        backPreviousScreenExternalScreen?: () => void,
        isEditExternal?: boolean
        canReturnPaymentType: boolean
        handleEditPaymentInfo: (value?: boolean) => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const backPreviousScreen = (isHeaderReturn = true) => {
        if (!backPreviousScreenExternalScreen) {
            if (!canReturnPaymentType || isHeaderReturn) {
                setActiveRoute(RoutesSubscription.selectPlan);
            } else {
                handleEditPaymentInfo(isHeaderReturn);
            }

        } else {
            backPreviousScreenExternalScreen();
        }
    };

    return (
        <Container>
            <Content background={colors.onBackground} border={colors.border}>
                <Header
                    backPreviousScreen={() => backPreviousScreen()}
                    loading={loading}
                />
                <ContainerInputs>
                    <InputsInfoHolder
                        newPaymentInfo={newPaymentInfo}
                        loading={loading}
                        onSearchCNPJ={onSearchCNPJ}
                        onUpdate={onUpdate}
                    />
                    <InputAddress
                        newPaymentInfo={newPaymentInfo}
                        loading={loading}
                        onSearchZipCode={onSearchZipCode}
                        onUpdate={onUpdate}
                    />
                </ContainerInputs>
                <FooterPaymentInfo
                    loading={loading}
                    onClickSavePaymentInfo={onClickSavePaymentInfo}
                    newPaymentInfo={newPaymentInfo}
                    backPreviousScreen={() => backPreviousScreen(false)}
                    onUpdate={onUpdate}
                />
            </Content>
            {!isEditExternal && <LabelPayments color={colors.hint} size={19} border={colors.border}>
                Forma de pagamento
            </LabelPayments>}
        </Container>
    );
});
export default InputsPaymentInfo;
