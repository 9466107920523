import citiesMap from "@dropDesk/utils/cities/cities.json";

export type Map = {
  value: string,
  label: string
}
const states = [
  {"state": "Acre", "shortState": "AC"},
  {"state": "Alagoas", "shortState": "AL"},
  {"state": "Amapá", "shortState": "AP"},
  {"state": "Amazonas", "shortState": "AM"},
  {"state": "Bahia", "shortState": "BA"},
  {"state": "Ceará", "shortState": "CE"},
  {"state": "Distrito Federal", "shortState": "DF"},
  {"state": "Espírito Santo", "shortState": "ES"},
  {"state": "Goiás", "shortState": "GO"},
  {"state": "Maranhão", "shortState": "MA"},
  {"state": "Mato Grosso", "shortState": "MT"},
  {"state": "Mato Grosso do Sul", "shortState": "MS"},
  {"state": "Minas Gerais", "shortState": "MG"},
  {"state": "Pará", "shortState": "PA"},
  {"state": "Paraíba", "shortState": "PB"},
  {"state": "Paraná", "shortState": "PR"},
  {"state": "Pernambuco", "shortState": "PE"},
  {"state": "Piauí", "shortState": "PI"},
  {"state": "Rio de Janeiro", "shortState": "RJ"},
  {"state": "Rio Grande do Norte", "shortState": "RN"},
  {"state": "Rio Grande do Sul", "shortState": "RS"},
  {"state": "Rondônia", "shortState": "RO"},
  {"state": "Roraima", "shortState": "RR"},
  {"state": "Santa Catarina", "shortState": "SC"},
  {"state": "São Paulo", "shortState": "SP"},
  {"state": "Sergipe", "shortState": "SE"},
  {"state": "Tocantins", "shortState": "TO"}
];

export const statesMaps = (): Map[] => {
  return states.map((entry) => {
    return {value: entry.state, label: entry.state}
  })
}


export const getCitiesFromState = (state: string): Map[] | undefined => {
  const _state = !!state ? state : 'Rio de Janeiro';
  const eligibleCities = citiesMap.find(entry => entry.state === _state);

  return eligibleCities?.cities.map((entry) => {
    return {value: entry, label: entry}
  })
}

export const timezoneMap: Map[] = [
  {value: "America/Sao_Paulo", label: "Fuso Horário de Brasilia -03:00"},
  {value: "Brazil/DeNoronha", label: "Fuso Horário de Fernando de Noronha -02:00"},
  {value: "America/Manaus", label: "Fuso Horário de Amazonas -04:00"},
  {value: "America/Rio_Branco", label: "Fuso Horário do Acre -05:00"}
]
