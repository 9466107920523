import React, {useState} from "react";
import ImageProfile from "@dropDesk/presentation/components/drop_zone/pick_image_profile";
import {Crop} from "@dropDesk/presentation/components/crop";
import {ContentImage} from "./styled";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {PickImageType} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_contacts/list_users";


const PickImageListUsers = (props: PickImageType) => {
  const [imageSourceCrop, setImageSourceCrop] = useState<string | null>(null);

  const handleChangeImage = (files: File[] | []): void => {
    if (files && files.length > 0) {
      const path = props.createObjectURLBlobUseCase(files[0]);
      setImageSourceCrop(path);
    } else {
      toastMessage.error("Error ao Anexar, Selecione uma imagem com no máximo 1MB");
    }
  };

  const handleOnCropper = (file: File): void => {
    props.handleOnCropper(file);
    //controller.setFile(file);
    setImageSourceCrop(null);
  };


  const removeProfileImage = (): void => {
    setImageSourceCrop(null);
    props.clearImage();
  };

  return (
    <ContentImage>
      <ImageProfile
        useGarbage={true}
        radiusImage={false}
        sizeImage={140}
        onPick={handleChangeImage}
        name={''}
        urlImageProfile={props.urlImageProfile}
        onRemoveProfileImage={() => {
          removeProfileImage();
        }}
        marginTopButtonChangeImage={15}
      />
      {imageSourceCrop ? (
        <Crop
          image={imageSourceCrop}
          onRequestCrop={handleOnCropper}
          onRequestClose={() => setImageSourceCrop(null)}
        />
      ) : null}
    </ContentImage>
  )
}
export default PickImageListUsers;
