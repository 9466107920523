import React, {MutableRefObject, useEffect} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Container, ContainerButtonClose,
    ContainerImageEditor, ContainerLoading,
} from "@dropDesk/presentation/pages/image_editor/ui/body/styled";
import InputAndCheckBox from "@dropDesk/presentation/pages/image_editor/ui/body/input_and_checkbox";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {FileEditionEntity} from "@dropDesk/domain/entities/image_editor/image_editor.entity";
import {isFileImage} from "@dropDesk/utils/helpers/files";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import NoPreview from "@dropDesk/presentation/pages/image_editor/ui/body/no_preview";
import ImageEditorFileRobot from "@dropDesk/presentation/pages/image_editor/ui/body/filerobot_editor";
import {ReferenceImageEdited} from "@dropDesk/domain/entities/image_editor/image_editor_enum.entity";
import {observer} from "mobx-react";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const Body = observer((
    {
        currentFileEdition,
        handleAllChangesFile,
        loading,
        files,
        referenceImageEdited,
        onClose,
        getNameFileSelected,
        referenceInput
    }: {

        currentFileEdition: FileEditionEntity | null
        handleAllChangesFile: (event: { target: { name: string; value: any; checked: any; type: string } }) => void,
        loading: boolean
        files: FileEditionEntity[];
        referenceImageEdited: MutableRefObject<ReferenceImageEdited | undefined>
        onClose: () => void
        getNameFileSelected: () => string
        referenceInput: MutableRefObject<HTMLInputElement | null>

    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const isDarkTheme = colors.brightness === Brightness.dark;


    return (
        <Container>

            <ContainerButtonClose
                onClick={() => loading ? () => {
                } : onClose()}
                background={colors.buttonOnBackground}
                loading={loading}
            >
                <DropDeskIcon
                    size={18}
                    icon={ICONS_DROPDESK.close}

                />
            </ContainerButtonClose>

            <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                height: '100%',
            }}>
                <ContainerImageEditor
                    background={colors.onBackground}
                    isDarkTheme={isDarkTheme}
                    heightShowingHeaderInfo={appController.heightShowingHeaderInfo}
                >

                    <>
                        {files.map((file) => {
                            if (isFileImage(file.fileMessage.file ?? null)) {
                                return (
                                    <ImageEditorFileRobot
                                        referenceImageEdited={referenceImageEdited}
                                        key={file.fileMessage.id}
                                        currentFileEdition={currentFileEdition}
                                        file={file}
                                    />
                                )
                            }

                        })}
                    </>

                    {!isFileImage(currentFileEdition?.fileMessage.file ?? null) &&
                        <NoPreview getNameFileSelected={getNameFileSelected}/>
                    }


                    {loading &&
                        <ContainerLoading isDarkTheme={isDarkTheme}>
                            <SpinLoading
                                size={"large"}
                                color={SharedColors.white}
                                tip={"Carregando"}
                            />
                        </ContainerLoading>
                    }

                </ContainerImageEditor>

                <InputAndCheckBox
                    referenceInput={referenceInput}
                    currentFileEdition={currentFileEdition}
                    handleAllChangesFile={handleAllChangesFile}
                    loading={loading}
                    isUserClient={appController.isUserClient}
                />
            </div>


        </Container>
    )
});

export default Body;
