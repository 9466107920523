import {observer} from "mobx-react";
import React from "react";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {useModalTask} from "@dropDesk/presentation/pages/task/ui/modals/handle_change_visible";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import ModalEditTask from "@dropDesk/presentation/pages/task/ui/modals/modal_edit_task";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";


const ModalsTask = observer(
    ({
         handleDeleteTask,
         handleVisibleModalEditTask,
         task,
         onUpdate,
         loading,
         set
     }: {
        handleDeleteTask: (id: string) => void
        handleVisibleModalEditTask: (visible: boolean, task?: TaskEntity) => void
        task: TaskEntity
        onUpdate: (task: TaskEntity) => void,
        loading: boolean,
        set: (onSuccess: (key: string) => void) => Promise<void>
    }) => {

        const {
            visibleModalDelete,
            setVisibleModalDelete
        } = useBetween(useModalTask);

        return (
            <>
                <ModalDelete
                    open={visibleModalDelete.visible}
                    onRequestClose={() => {
                        setVisibleModalDelete({visible: false, id: ''})
                    }}
                    textHeaderModal={'Excluir Tarefa'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        handleDeleteTask(visibleModalDelete.id);
                        setVisibleModalDelete({visible: false, id: ''})
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente excluir definitivamente esta tarefa?`
                    }
                    loading={false}
                    isActionDelete={true}
                />
                <ModalEditTask
                    task={task}
                    handleVisibleModalEditTask={handleVisibleModalEditTask}
                    onUpdate={onUpdate}
                    loading={loading}
                    set={set}
                />
            </>
        )
    })
export default ModalsTask;
