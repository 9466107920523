import styled from "styled-components";
import {
    ContainerType,
    ContainerButtonHeaderType,
    ContainerListTaskType,
    TextTaskType
} from "@dropDesk/presentation/components/lists/list_sectors_linked/list_sectors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.background};
  height: 300px;
  min-height: 300px;
  width: 100%;
  max-width: 100%;
  -webkit-box-shadow: 0px 0px 4px 1px ${SharedColors.black};
  -moz-box-shadow: 0px 0px 4px 1px ${SharedColors.black};
  box-shadow: 0px 0px 4px 1px ${SharedColors.black};
  border-radius: 7px;
  padding: 16px;
`;

export const ContainerListTask = styled.div<ContainerListTaskType>`
  height: 40px;
  min-height: 40px;
  border-bottom: 0.5px solid ${props => props.borderColor};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px 16px 16px 0;
`;
export const TextTask = styled.span<TextTaskType>`
  display: flex;
  background-Color: transparent;
  flex: 1;
  height: 40px;
  min-height: 40px;
  margin-left: 10px;
  border: 0;
  align-items: center;
  font-size: 16px;
  color: ${props => props.color};

`;

export const ContainerButtonHeader = styled.div<ContainerButtonHeaderType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  margin: 0 2px;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
