import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ContractsRemoteDataSource} from "@dropDesk/data/data_source/common/contracts/contracts.datasource";
import {ContractsRepository} from "@dropDesk/domain/repositories/common/contracts/contracts.repository";

@injectable()
export class ContractsRepositoryImpl implements ContractsRepository {
    private _dataSource: ContractsRemoteDataSource;

    constructor(@inject(ContractsRemoteDataSource) dataSource: ContractsRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public getSignUpContract(): Promise<string> {
        try {
            return this._dataSource.getSignUpContract();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

}
