import React, {useEffect, useRef, useState} from "react";
import {
    Container, Input, Content, ContentLabel, ContentButtons
} from "./styled";
import {InputProps} from "@dropDesk/presentation/components/inputs/input_with_border/input_with_border";

import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import LabelInput from "@dropDesk/presentation/components/inputs/input_with_border/label";
import ButtonTopTwo from "@dropDesk/presentation/components/inputs/input_with_border/button_top_two";
import ButtonTopOne from "@dropDesk/presentation/components/inputs/input_with_border/button_top_one";
import LeftIcon from "@dropDesk/presentation/components/inputs/input_with_border/left_icon";
import EmojiSelect from "@dropDesk/presentation/components/inputs/input_with_border/emoji_picker";
import RightIcon from "@dropDesk/presentation/components/inputs/input_with_border/right_icon";
import MessageError from "@dropDesk/presentation/components/inputs/input_with_border/message_error";
import RemoveFilter from "@dropDesk/presentation/components/inputs/input_with_border/remove_filter";
import {
    setMaskCep,
    setMaskCnpj,
    setMaskCpf, setMaskExpirationDateCreditCard
} from "@dropDesk/presentation/components/inputs/input_with_border/masks";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";

export default function InputWithBorder(props: InputProps) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const useBorder = props.usedBorder === false ? false : true;
    const ref = props.reference ?? useRef<HTMLInputElement | null>(null);
    const maxLength = props.maxLength;
    const color = props.color ?? colors.text;

    useEffect(() => {

        if (ref.current && props.autoFocus) {
            ref.current?.focus();
            setMaxLength();
        }

    }, []);

    const setMaxLength = (): void => {
        if (props.mask === "cep" && ref.current) {
            ref.current.maxLength = 9;
        }
        if (props.mask === "cnpj" && ref.current) {
            ref.current.maxLength = 18;
        }
        if (props.mask === "cpf" && ref.current) {
            ref.current.maxLength = 14;
        }
        if (props.mask === "expirationDate" && ref.current) {
            ref.current.maxLength = 5;
        }

        if (maxLength && ref.current) {
            ref.current.maxLength = maxLength;
        }
    }


    return (
        <Container marginLeft={props.marginLeft ?? 0} marginTop={props.marginTop ?? 0}>
            <ContentLabel>
                {!!props.label && <LabelInput required={props.required} label={props.label}/>}
                <ContentButtons>
                    {props.useTwoButtonRight &&
                        <ButtonTopTwo
                            tooltipTwoButtonRight={props.tooltipTwoButtonRight}
                            onClickTwoButtonRight={props.onClickTwoButtonRight}
                            disabledButtonRight={props.disabledButtonRight}
                            nameIconButtonRight={props.nameIconButtonRight}
                            labelTwoButtonRight={props.labelTwoButtonRight}
                        />
                    }

                    {props.useButtonRight &&
                        <ButtonTopOne
                            tooltipButtonRight={props.tooltipButtonRight}
                            onClickButtonRight={props.onClickButtonRight}
                            disabledButtonRight={props.disabledButtonRight}
                            nameIconButtonRight={props.nameIconButtonRight}
                            labelButtonRight={props.labelButtonRight}
                        />}
                </ContentButtons>
            </ContentLabel>

            <Content
                onClick={props.onClick && !props.disabled ? props.onClick : () => {
                }}
                background={props.backgroundColor ?? colors.backgroundInput}
                usedBorder={useBorder}
                size={props.size}
                accent={colors.accent}
                disabled={props.disabled ?? false}
                border={colors.border}
            >

                {props.nameIconLeft &&
                    <LeftIcon
                        nameIconLeft={props.nameIconLeft}
                        iconLeftIsMain={props.iconLeftIsMain}
                        onClickLeftIcons={props.onClickLeftIcons}
                        sizeLeftIcons={props.sizeLeftIcons}
                        disabled={props.disabled}
                    />}

                {props.useEmojiPicker && !props.disabled &&
                    <EmojiSelect<React.ChangeEvent<HTMLInputElement>>
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        disabled={props.disabled}
                    />}

                <Input
                    size={props.size}
                    ref={ref}
                    autoFocus={props.autoFocus ?? false} //not working in modal antd
                    name={props.name}
                    onBlur={() => {
                        if (props.onBlur) {
                            props.onBlur();
                        }
                    }}
                    onCopy={(event: any) => {
                        if (props.removeMaskOnCopy) {
                            const value = event.target?.value ?? '';
                            event.clipboardData.setData('text/plain', removeMask(value));
                            event.preventDefault();
                        }
                    }}
                    maxLength={props.maxLength}
                    style={{cursor: props.readOnly && !props.disabled ? 'pointer' : props.disabled ? 'no-drop' : 'auto'}}
                    readOnly={props.readOnly ?? false}
                    color={color}
                    placeholdercolor={colors.hint}
                    disabled={props.disabled}
                    type={props.type ?? 'text'}
                    placeholder={props.placeHolder ?? ''}
                    value={
                        props.mask === 'cnpj' ? setMaskCnpj(props.value) :
                            props.mask === 'cpf' ? setMaskCpf(props.value) :
                                props.mask === 'cep' ? setMaskCep(props.value) :
                                    props.mask === 'expirationDate' ? setMaskExpirationDateCreditCard(props.value) :
                                        props.value
                    }
                    onChange={(event) => {
                        setMaxLength();
                        props.onChange(event);
                    }}
                    autoComplete='off' //valor passado para não pegar preenchimento automatico do navegador
                />

                {props.useRemoveFilter && !props.disabled &&
                    <RemoveFilter
                        onClickRemoveFilter={props.onClickRemoveFilter}
                    />}

                {props.nameIconRight && (
                    <RightIcon
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        onClick={props.onClick}
                        onClickRightIcons={props.onClickRightIcons}
                        size={props.size}
                        nameIconRight={props.nameIconRight}
                        sizeRightIcons={props.sizeRightIcons}
                        tooltilRightIcon={props.tooltilRightIcon}
                    />
                )}


            </Content>

            {(!!props.messageError || props.messageError === "") && <MessageError messageError={props.messageError}/>}


        </Container>
    );
}
