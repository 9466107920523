import {useState} from "react";

export const useModalUsers = () => {

  const [visibleModalRestore, setVisibleModalRestore] = useState<boolean>(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const [visibleModalExport, setVisibleModalExport] = useState<boolean>(false);


  return {
    visibleModalRestore, setVisibleModalRestore,
    visibleModalDelete, setVisibleModalDelete,
    visibleModalExport, setVisibleModalExport

  }
}
