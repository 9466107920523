import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export type ButtonPayType = {
    background: string;
    color: string;
    accent: string;
    size: number;
}
export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const ContentButtonPay = styled.span<ButtonPayType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  padding: 2px 16px;
  border: 1px solid ${props => props.color};
  border-radius: 5px;
  background: ${(props) => props.background};
  cursor: pointer;

  &:hover {
    border: none;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
