import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {ChangePasswordResponse} from "@dropDesk/domain/entities/common/account_action.entity";

@injectable()
export class ChangePasswordUserUseCase {
  private _repository: UserRepository;

  constructor(@inject(UserRepository) repository: UserRepository) {
    this._repository = repository;
  }

  public call(renewToken: string, password?: string): Promise<ChangePasswordResponse> {
    return this._repository.changePassword(renewToken, password);
  }
}

