import React from "react";
import {TextMessageDeleted} from "@dropDesk/presentation/components/messages/message_box/styled";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const DeletedMessage = observer((
    {
        hint
    }: {
        hint: string
    }
) => {

    return (
        <>
            <DropDeskIcon icon={ICONS_DROPDESK.block} color={hint}/>
            <TextMessageDeleted color={hint}>Mensagem
                apagada</TextMessageDeleted>
        </>
    )
});
export default DeletedMessage;
