import {
    TokenizeCardEntity,
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {removeMask, removeMaskStaticTelephoneNumber} from "@dropDesk/utils/helpers/string_helper";
import {
    ContainerInputs, ContentDataInput, ContentDataInputRow,
    ContentForm, ContentInput
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import React from "react";
import {validateCreditCardNumber} from "@dropDesk/utils/helpers/card_helper";
import {
    useValidatorInputCreditCard
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/inputs_card/validator";
import {Focused} from "react-credit-cards-2";

const InputsCard = (
    {
        newTokenizeCard,
        loading,
        handleAllChanges
    }: {
        newTokenizeCard: TokenizeCardEntity
        loading: boolean
        handleAllChanges: (event: {
            target: { name: string; value: any; checked: any; type: string }
        }, focused: Focused) => void
    }
) => {

    const {
        errorMessageNumber, errorMessageHolderName,
        errorMessageCVV, errorMessageExpirationDate
    } = useValidatorInputCreditCard(newTokenizeCard);

    return (
        <ContentForm onSubmit={(event) => event.preventDefault()}>
            <ContainerInputs>
                <ContentDataInput>
                    <ContentInput>
                        <InputWithBorder
                            autoFocus={true}
                            required={true}
                            name='number'
                            size={35}
                            disabled={loading}
                            maxLength={validateCreditCardNumber(newTokenizeCard.number).maxLength}
                            messageError={errorMessageNumber}
                            label={"Número do cartão"}
                            value={newTokenizeCard.number}
                            onChange={(event) => handleAllChanges(event, 'number')}
                        />
                    </ContentInput>
                    <ContentInput>
                        <InputWithBorder
                            required={true}
                            name='holderName'
                            size={35}
                            disabled={loading}
                            messageError={errorMessageHolderName}
                            label={"Nome do titular do cartão"}
                            value={newTokenizeCard.holderName}
                            onChange={(event) => handleAllChanges(event, 'name')}
                        />
                    </ContentInput>
                </ContentDataInput>

                <ContentDataInputRow>
                    <ContentInput style={{marginRight: 20}}>
                        <InputWithBorder
                            required={true}
                            name='expirationDate'
                            size={35}
                            disabled={loading}
                            messageError={errorMessageExpirationDate}
                            label={"Data de validade"}
                            mask={'expirationDate'}
                            placeHolder={'MM/YY'}
                            value={newTokenizeCard.expirationDate}
                            onChange={(event) => handleAllChanges(event, 'expiry')}
                        />
                    </ContentInput>
                    <ContentInput>
                        <InputWithBorder
                            required={true}
                            name='cvv'
                            size={35}
                            disabled={loading}
                            messageError={errorMessageCVV}
                            maxLength={4}
                            label={"Código segurança"}
                            value={newTokenizeCard.cvv}
                            onChange={(event) => handleAllChanges(event, 'cvc')}
                        />
                    </ContentInput>
                </ContentDataInputRow>
            </ContainerInputs>
        </ContentForm>
    );
}
export default InputsCard;
