import {Container} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {useEffect} from "react";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import FormCompany from "@dropDesk/presentation/pages/company/ui/form_company";
import FormClientCreateAccount from "@dropDesk/presentation/pages/company/ui/form_client";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";

const CreateCompany = (observer(() => {

    const appController = useInjection(AppController);
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        preventsAccessWithoutRole();
        appController.initializeCreateAccount().then();
    }, []);

    const preventsAccessWithoutRole = () => {
        if (!appController.role) {
            navigate(RoutesEnum.role);
        }
    }
    const onSuccessCreateAccount = async () => {
        const route = await appController.getCurrentUser();
        navigate(route, {replace: true});
        onSuccess("auth.success.create_account");
    }

    const onSuccess = (key: string) => {
        toastMessage.success(t(key));
    }

    return (
        <Container>
            {!appController.isUserClient &&
                <FormCompany
                    onSuccessCreateAccount={onSuccessCreateAccount}
                    segments={appController.segments}
                />}

            {appController.isUserClient && <FormClientCreateAccount
                onSuccessCreateAccount={onSuccessCreateAccount}
            />}
            {appController.loading && <DropDeskLoading
                height={250}
                description={appController.loadingMessage}
            />}
        </Container>
    );
}))
export default CreateCompany;
