import {
    ContentButtonPayCreditCardSave,
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/styled";
import React from "react";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ButtonPay = (
    {
        loading,
        onClickPayCreditCardSaved
    }: {
        loading: boolean
        onClickPayCreditCardSaved: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentButtonPayCreditCardSave>
            <ButtonMain
                style={{paddingLeft: 5, paddingRight: 5}}
                widthPercentage={100}
                letterFontSize={15}
                disabled={loading}
                loading={loading}
                color={colors.accent}
                type={"submit"}
                letter={'Pagar'}
                onClick={() => onClickPayCreditCardSaved()}
            />
        </ContentButtonPayCreditCardSave>
    );
}
export default ButtonPay;
