import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export abstract class ClientRepository {
    public abstract set(client: ClientEntity): Promise<ClientEntity>;

    public abstract restore(clients: ClientEntity[], restoreWithUsers: boolean): Promise<void>;

    public abstract delete(clients: ClientEntity[]): Promise<void>;

    public abstract findByPK(id: string): Promise<ClientEntity>;

    public abstract list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean): Promise<ListPaginationEntity<ClientEntity>>;

    public abstract listOnlyUsersLinked(id: string): Promise<ClientEntity>;

    public abstract listImportSubscription(
        page: number,
        period: { startDate: string; endDate: string; },
        limit: number
    ): Observable<ListPaginationEntity<ImportData>>;

    public abstract import(file: File): Promise<Record<string, unknown>>;

    public abstract importSubscription(idCompany: string, startDate: string, endDate: string): Observable<ImportData>;

    public abstract revertImport(id: string): Promise<void>;

    public abstract exportSubscription(): Observable<ExportDataEntity>;

    public abstract export(): Promise<ExportDataEntity>;

    public abstract downloadFileExample(): Promise<void>;
}
