import {useState, FormEvent, useEffect} from "react";
import {Container, Content, ContentButtons} from "./styled";
import {isValidEmail} from "@dropDesk/utils/helpers/validators";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


export const ModalForgotEmail = observer(
    ({
         onPressSend,
         onPressHasToken,
         visibleModalRememberPassword,
         setVisibleModalRememberPassword,
         loading,
     }: {
        setVisibleModalRememberPassword: () => void,
        onPressSend: (email: string) => Promise<void>,
        onPressHasToken: () => void,
        visibleModalRememberPassword: boolean,
        loading: boolean,
    }) => {
        const [email, setEmail] = useState("");
        const [errorMessageEmail, setErrorMessageEmail] = useState("");
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        useEffect(() => {
            verifyIsValidEmail()
        }, [email]);

        function verifyIsValidEmail() {
            let verifyEmail = isValidEmail(email);
            if (!verifyEmail) {
                setErrorMessageEmail("E-mail inválido");
            } else {
                setErrorMessageEmail("");
            }
        }

        async function handleSubmitRecoveryPasswordByEmail(event: FormEvent) {
            event.preventDefault();
            if (isValidEmail(email)) {
                await onPressSend(email.trim());
                setVisibleModalRememberPassword();
            }
        }

        return (
            <ModalMain
                open={visibleModalRememberPassword}
                onRequestClose={setVisibleModalRememberPassword}
                width={400}
                renderComponent={<Container background={colors.onBackground}>
                    <HeaderModal
                        disabled={false}
                        letter={"Esqueceu a Senha?"}
                        nameIcon={ICONS_DROPDESK.close}
                        onClick={setVisibleModalRememberPassword}
                    />
                    <Content>
            <span
                style={{
                    color: colors.text, fontSize: 16,
                }}>
              Informe seu e-mail de acesso que enviaremos instruções para recuperação de senha:
            </span>
                        <InputWithBorder
                            autoFocus={true}
                            size={45}
                            marginTop={15}
                            nameIconLeft={ICONS_DROPDESK.atSign}
                            disabled={loading}
                            placeHolder={"E-mail"}
                            messageError={errorMessageEmail}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            onFocus={() => verifyIsValidEmail()}
                            onBlur={() => verifyIsValidEmail()}
                        />
                        <ContentButtons>
                            <div style={{display: 'flex', flex: 1, marginRight: 10}}>
                                <ButtonMain
                                    borderColor={colors.border}
                                    widthPercentage={100}
                                    letterFontSize={15}
                                    disabled={loading}
                                    loading={loading}
                                    color={'transparent'}
                                    type={"submit"}
                                    letter={"Já possuo código"}
                                    letterColor={colors.text}
                                    onClick={onPressHasToken}
                                />
                            </div>
                            <div style={{display: 'flex', flex: 1}}>
                                <ButtonMain
                                    widthPercentage={100}
                                    letterFontSize={15}
                                    disabled={loading || !!errorMessageEmail}
                                    loading={loading}
                                    color={colors.accent}
                                    type={"submit"}
                                    letter={"Enviar"}
                                    onClick={handleSubmitRecoveryPasswordByEmail}
                                />
                            </div>
                        </ContentButtons>
                    </Content>
                </Container>}
            />
        );
    });
