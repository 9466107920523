import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";

@injectable()
export class DeleteClientUseCase {
  private _repository: ClientRepository;

  constructor(@inject(ClientRepository) repository: ClientRepository) {
    this._repository = repository;
  }

  public call(clients: ClientEntity[]): Promise<void> {
    return this._repository.delete(clients);
  }
}

