import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type IconOpsType = {
    color: string;
    accent: string;
}

type TitleType = {
    color: string;
}

type ContainerSectorLinked = {
    height: number;
}

export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;
export const TextTitle = styled.h3<TitleType>`
  color: ${props => props.color};
  font-weight: bold;
`;
export const TextError = styled.span<TitleType>`
  color: ${props => props.color};
  margin: 0 0 18px;
  font-size: 15px;
  font-style: italic;
`;
export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTitleAndAddSector = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentButtonSectors = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const ButtonAddSector = styled.button<IconOpsType>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  background: transparent;
  border: 0;
  font-size: 16px;
  cursor: pointer;
  color: ${props => props.color};
  text-decoration: underline;
  padding: 2px 5px;
  margin: 0 2px;
  border-radius: 5px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const ContentListSectors = styled.div<ContainerSectorLinked>`
  flex-direction: column;
  display: flex;
  flex: 1;
  max-height: 400px;
  min-height: ${props => `${props.height}px`};
  height: ${props => `${props.height}px`};
  overflow: auto;
`;



