import styled from "styled-components";

type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => `${props.size}px`};
  color: ${props => props.color};
  font-weight: bold;
`;

