import styled from "styled-components";

type ContainerType = {
    background: string;
    border: string;
};

type IconOpsType = {
    color: string;
}


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  align-items: center;
  overflow: hidden;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${props => props.border};
`;

