import {observer} from "mobx-react";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {
    Container,
    ContainerInputSearch,
    Content,
    ContentInputSearch,
    ContentLoading, ContentTextNoResults,
    Text
} from "@dropDesk/presentation/pages/chat/ui/chat_list/styled";
import ChatList from "@dropDesk/presentation/components/chat_list";
import LoadingInfinityScroll from "@dropDesk/presentation/components/loadings/loading_infinity_scroll";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

export const ChatLists = (observer((
    {
        tickets,
        loading,
        hasMore,
        loadMore,
        useSearch,
        onSearch,
        searchParam,
        loadingMore,
        onClick,
        idTicketSelected,
        userLogged,
        disabled
    }: {
        tickets: TicketEntity[]
        loading: boolean
        loadingMore: boolean
        hasMore?: boolean
        useSearch?: boolean
        loadMore: () => void
        onSearch?: (value: string) => void
        searchParam: string
        idTicketSelected?: string | null
        onClick: (ticket: TicketEntity) => void
        userLogged: UserEntity
        disabled: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Container bordercolor={colors.border}>
            {useSearch &&
                <ContainerInputSearch>
                    <ContentInputSearch>
                        <InputSearchGeneric
                            inputSearchPlaceholder="Pesquise por conversas"
                            loading={loading}
                            onSearch={(value) => onSearch ? onSearch(value) : () => {
                            }}
                        />
                    </ContentInputSearch>
                </ContainerInputSearch>
            }
            <Content>
                {loading && tickets.length === 0 &&
                    <ContentLoading>
                        <SpinLoading
                            size={"large"}
                            color={colors.text}
                            tip={"Carregando ..."}
                        />
                    </ContentLoading>
                }
                {!loading && tickets.length === 0 &&
                    <ContentTextNoResults>
                        <DropDeskIcon icon={ICONS_DROPDESK.emptyFolder} size={55} color={colors.hintInverse}/>
                        <Text color={colors.text}>Nenhuma conversa encontrada.</Text>
                    </ContentTextNoResults>
                }

                {tickets.map((ticket) => {
                    return (
                        <ChatList
                            key={ticket.id}
                            onClick={() => onClick(ticket)}
                            ticket={ticket}
                            searchParam={searchParam}
                            idTicketSelected={idTicketSelected}
                            userLogged={userLogged}
                            disabled={disabled}
                        />
                    )
                })}
                {hasMore &&
                    <LoadingInfinityScroll
                        loading={loadingMore}
                        loadMore={() => loadMore()}
                        label={'Carregar mais conversas'}
                        labelLoading={'Carregando mais conversas...'}
                    />}

            </Content>
        </Container>
    )
}));
