import styled from "styled-components";
import {TextType} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/header/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0 22px 0;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;
