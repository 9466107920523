import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {
    PaymentSubscriptionSimulateEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_simulate.entity";

@injectable()
export class GetSimulateSubscriptionUseCase {
    private _repository: SubscriptionRepository;

    constructor(@inject(SubscriptionRepository) repository: SubscriptionRepository) {
        this._repository = repository;
    }

    public call(subscription: PaymentSubscriptionEntity): Promise<PaymentSubscriptionSimulateEntity> {
        return this._repository.simulate(subscription);
    }
}

