import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, ContentCards, TextBold} from "./styled";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import Card from "@dropDesk/presentation/pages/select_role/ui/body/card";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const Body = (observer((
    {
        onClickCardRole
    }: {
        onClickCardRole: (role: UserRole) => void
    }
) => {

    const appController = useInjection(AppController);

    return (

        <Container>
            <ContentCards>
                {Object.keys(UserRole).map((entry) => {
                    return (
                        <Card
                            key={entry}
                            description={entry === UserRole.attendant ? 'Empresa' : 'Contato'}
                            isActive={entry === appController.role}
                            nameIcon={entry === UserRole.attendant ? ICONS_DROPDESK.business : ICONS_DROPDESK.account}
                            onClickCard={onClickCardRole}
                            value={entry as UserRole}
                        />
                    )
                })}
            </ContentCards>
        </Container>

    );
}))
export default Body;
