export enum RoutesEnum {
    loginDefault = '/login',
    login = '/',
    connectionsExceeded = '/connections-exceeded',
    subscriptionExpired = '/subscription-expired',
    companyCreate = '/company',
    changePassword = '/change-password',
    confirmEmail = '/confirm-email',
    firstAccess = '/first-access',
    welcome = '/welcome',
    dashboard = '/dashboard',
    chat = '/chat',
    chatdefault = '/chatdefault',
    tickets = '/tickets',
    ticketsView = '/tickets/view',
    clients = '/clients',
    contacts = '/contacts',
    importclients = '/importclients',
    importrevert = '/importrevert',
    importclientsdefault = '/importclientsdefault',
    exportclients = '/exportclients',
    exportcontacts = '/exportcontacts',
    clientsdefault = '/clientsdefault',
    rapidresponse = '/rapidresponse',
    descriptionstatic = '/descriptionstatic',
    users = '/users',
    exportusers = '/exportusers',
    activeConnections = '/activeConnections',
    sectors = '/sectors',
    exportsectors = '/exportsectors',
    notices = '/notices',
    reporttickets = '/reporttickets',
    exportreporttickets = '/exportreporttickets',
    reportticketsaggregate = '/reportticketsaggregate',
    reportticketsrate = '/reportticketsrate',
    reportrate = '/reportrate',
    reportdefault = '/reportdefault',
    reportticketdefault = "/reportticketdefault",
    reportticketsdefault = "/reportticketsdefault",
    reportaggregatedefault = "/reportaggregatedefault",
    reportratedefault = '/reportratedefault',
    reportratesdefault = '/reportratesdefault',
    profile = '/profile',
    configuration = '/configuration',
    subscription = '/subscription',
    role = '/role',
    invoices = '/invoices',
}
