import React, {useEffect} from "react";
import {useInjection} from "inversify-react";
import {NoticesController} from "@dropDesk/presentation/pages/notices/controller/notices.controller";
import {useNavigate, useParams} from "react-router-dom";
import {
    Container,
    ContentFormNotices,
    FormNotice,
} from "./styled";
import {observer} from "mobx-react";
import GenericScreenNotFound from "@dropDesk/presentation/components/generic_screen_not_found";
import HeaderFormNotices from "@dropDesk/presentation/pages/notices/ui/form/header_form";
import FooterFormNotices from "@dropDesk/presentation/pages/notices/ui/form/footer_form";
import InputsFormNotices from "@dropDesk/presentation/pages/notices/ui/form/inputs";
import ModalsFormNotices from "@dropDesk/presentation/pages/notices/ui/form/modals";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {useValidatorInputNotices} from "@dropDesk/presentation/pages/notices/ui/form/inputs/validator";
import {AppController} from "@dropDesk/presentation/app/app.controller";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";

const FormNotices = observer(() => {

    const controller = useInjection(NoticesController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const {id} = useParams();
    const navigate = useNavigate();
    const isNewRegister = 'new';
    const {t} = useTranslation();

    const initialize = async () => {

        if (id === isNewRegister) {
            controller.makeNotices();
        } else {
            controller.getNotices(id ?? isNewRegister);
        }

    }
    const {
        isValidDataForm
    } = useValidatorInputNotices(controller.notice);

    useEffect(() => {
        initialize();
    }, []);

    const handleSubmit = async () => {
        if (isValidDataForm) {
            await controller.set(onSuccess);
        }
    };

    const onSuccess = (key: string) => {
        toastMessage.success(t(key));
        navigate(-1);
    }

    return (
        <Container background={colors.onBackground}>


            <ContentFormNotices>
                {!controller.noticesNotFound &&

                    <FormNotice background={colors.onBackground} border={colors.border}>
                        <HeaderFormNotices
                            loading={controller.loading}
                            isUnsaved={id === isNewRegister}
                        />
                        <InputsFormNotices
                            onUpdate={(notice) => controller.setNotices(notice)}
                            notice={controller.notice}
                            loading={controller.loading}
                        />
                        <FooterFormNotices
                            onUpdate={(notice) => controller.setNotices(notice)}
                            notice={controller.notice}
                            loading={controller.loading}
                            handleSubmit={handleSubmit}
                            createdAt={controller.notice?.createdAt ? `Criado em ${displayDateToLocale(controller.notice.createdAt)}` : undefined}

                        />
                        <ModalsFormNotices
                            onUpdate={(notice) => controller.setNotices(notice)}
                            onDeleteNotices={async () => {
                                controller.setRowSelectionNotices([controller.notice!]);
                                await controller.delete(onSuccess);
                            }}
                            notice={controller.notice}
                            loading={controller.loading}
                        />
                    </FormNotice>}

                {controller.noticesNotFound &&
                    <GenericScreenNotFound
                        textNotFound={'Oops, Aviso não encontrado ...'}
                        textHeaderForm={"Voltar"}
                    />}
            </ContentFormNotices>
            {controller.loading && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    );
});

export default FormNotices;
