export class ResponseStandardEntity {
    id!: string;
    idCompany!: string;
    description!: string;
    title!: string;
    deleted!: boolean;
    createdAt?: string;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    constructor({
                    id,
                    idCompany,
                    description,
                    title,
                    deleted,
                    createdAt
                }: {
        id: string;
        idCompany: string;
        description: string;
        title: string;
        deleted: boolean;
        createdAt?: string;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            description,
            title,
            deleted,
            createdAt
        });
    }

    copyWith({
                 id,
                 idCompany,
                 description,
                 title,
                 deleted,
                 createdAt,
             }: {
        id?: string;
        idCompany?: string;
        description?: string;
        title?: string;
        deleted?: boolean;
        createdAt?: string;
    }): ResponseStandardEntity {
        return new ResponseStandardEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            description: description ?? this.description,
            title: title ?? this.title,
            deleted: deleted ?? this.deleted,
            createdAt: createdAt ?? this.createdAt,
        })
    }
}
