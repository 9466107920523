export class ThemeConfigurationEntity {
    name!: string;
    logoBrandUrl!: string | null;

    public static fromJson(json: Record<string, any>): ThemeConfigurationEntity {
        return new ThemeConfigurationEntity({
            name: json['name'],
            logoBrandUrl: json['logoBrandUrl'],
        });
    }

    constructor({
                    name,
                    logoBrandUrl,
                }: {
        name: string;
        logoBrandUrl: string | null;
    }) {
        Object.assign(this, {
            name,
            logoBrandUrl,
        });
    }

    copyWith({
                 name,
                 logoBrandUrl,
                 replaceLogoIfNull
             }: {
        name?: string;
        logoBrandUrl: string | null;
        replaceLogoIfNull?: boolean | null;
    }): ThemeConfigurationEntity {
        return new ThemeConfigurationEntity({
            name: name ?? this.name,
            logoBrandUrl: replaceLogoIfNull ? logoBrandUrl : this.logoBrandUrl,
        })
    }
}
