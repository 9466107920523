import styled from "styled-components";

type ContainerType = {
    background: string;
};

export const ContainerDetailAndTitle = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.background};
  border-radius: 3px;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 17px;
  color: ${(props) => props.color};
  letter-spacing: .1px;
  font-weight: 600;
`;

export const ContentTitle = styled.div`
  padding: 4px 5px 0px 5px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const ContainerDetail = styled.div<ContainerType>`
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  background: ${(props) => props.background};
  margin: 5px;
  height: 100%;
`;
