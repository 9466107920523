import {observer} from "mobx-react";
import React from "react";
import {
    ContentFilter, ContentInput, ContentInputAndListableSectorDeleted, PaddingFilters
} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {evaluationValueMap} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation_maps";
import {evaluationEnum} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation_enum";
import {ContentInputSearch} from "@dropDesk/presentation/pages/ticket/ui/search/search_bar/styled";


const SearchBar = observer((
    {
        onSearch,
        setSearchEvaluationValue,
        loading,
        searchEvaluationValue,
    }:
        {
            onSearch: (searchParam: string) => void,
            setSearchEvaluationValue: (searchEvaluationValue: number | null) => void,
            loading: boolean,
            searchEvaluationValue: number | null
        }
) => {


    return (
        <ContentFilter>
            <ContentInputAndListableSectorDeleted>
                <ContentInput>
                    <ContentInputSearch>
                        <InputSearchGeneric
                            inputSearchPlaceholder="Pesquise por todas avaliações"
                            loading={loading}
                            onSearch={(value) => onSearch(value)}
                        />
                    </ContentInputSearch>
                </ContentInput>
                <PaddingFilters>
                    <SelectSearchMain<evaluationEnum>
                        size={40}
                        width={265}
                        useRemoveFilter={!!searchEvaluationValue}
                        placeHolder={"Filtrar por valor da avaliação"}
                        options={evaluationValueMap}
                        removeSort={true}
                        onClickRemoveFilter={() => {
                            setSearchEvaluationValue(null);
                        }}
                        onChange={(value) => {
                            setSearchEvaluationValue(value);
                        }}
                        value={searchEvaluationValue ? searchEvaluationValue : null}
                    />
                </PaddingFilters>

            </ContentInputAndListableSectorDeleted>
        </ContentFilter>
    )
})
export default SearchBar;
