import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


type ContainerType = {
    background: string;
    backgroundChecked: string;
    backgroundSecondary?: string;
    color?: string;
}

export const SwitchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const SwitchLabel = styled.label<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  width: 39px;
  height: 20px;
  border-radius: 15px;
  background: ${(props) => props.background};
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: 3px;
    background: ${(props) => props.color};
    box-shadow: 1px 3px 3px 1px ${hexToRGBA(SharedColors.black, 0.20)};
    transition: 0.2s;
  }
`;
export const SwitchButton = styled.input<ContainerType>`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 39px;
  height: 23px;
  cursor: pointer;


  &:checked + ${SwitchLabel} {
    background: ${(props) => props.backgroundChecked};

    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      margin-left: 21px;
      transition: 0.2s;
    }

  }

  &:disabled + ${SwitchLabel} {
    filter: brightness(80%);
    cursor: no-drop;

  }
`;
