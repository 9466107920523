import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {Observable} from "rxjs";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";

@injectable()
export class GetPaymentSubscriptionUseCase {
    private _repository: SubscriptionRepository;

    constructor(@inject(SubscriptionRepository) repository: SubscriptionRepository) {
        this._repository = repository;
    }

    public call(id: string): Observable<PaymentEntity> {
        return this._repository.getPaymentSubscription(id);
    }
}

