import React from "react";
import {TabsStyled} from "@dropDesk/presentation/components/tabs/background_style/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

export type Items = {
    key: string;
    label: React.ReactNode;
    children: React.ReactNode;
}
const Tabs = (
    {
        defaultActiveKey,
        items,
        height,
        withMarginInTabs,
        destroyInactiveTabPane,
        activeKey,
        onChange
    }: {
        defaultActiveKey: string
        activeKey?: string
        items: Array<Items>
        height?: number
        withMarginInTabs?: boolean;
        destroyInactiveTabPane?: boolean;
        onChange?: (key: string) => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <TabsStyled
            defaultActiveKey={defaultActiveKey}
            tabBarStyle={{margin: 0, padding: 0, border: 0, height: height ?? 40}}
            style={{margin: 0, padding: 0, border: 0}}
            size={"small"}
            color={colors.text}
            hint={colors.hint}
            border={colors.border}
            focusedcolor={colors.accent}
            background={colors.onBackground}
            backgrounddropdown={colors.onBackground}
            colordropdown={colors.text}
            tabPosition={"top"}
            centered={false}
            onChange={(value) => onChange ? onChange(value) : () => {

            }}
            getPopupContainer={(trigger) => trigger}
            items={items}
            usemargin={String(withMarginInTabs)}
            destroyInactiveTabPane={destroyInactiveTabPane ?? false}
            activeKey={activeKey}
        />
    )
}

export default Tabs;
