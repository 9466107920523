import "reflect-metadata";
import {inject, injectable} from "inversify";
import {IoRepository} from "@dropDesk/domain/repositories/io/io.repository";

@injectable()
export class StopRecordUseCase {
  private _repository: IoRepository;

  constructor(@inject(IoRepository) repository: IoRepository) {
    this._repository = repository;
  }

  public call(mediaRecorder?: MediaRecorder, stream?: MediaStream): void {
    return this._repository.stopRecord(mediaRecorder, stream);
  }
}
