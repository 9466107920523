import {observer} from "mobx-react";
import {ContentExportImport, ContentFilter, ContentInput, ContentInputAndListableUserDeleted} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useBetween} from "use-between";
import {useModalContacts} from "@dropDesk/presentation/pages/client/ui/contacts/search/modals/handle_change_visible";
import {ContentInputSearch} from "@dropDesk/presentation/pages/ticket/ui/search/search_bar/styled";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const SearchBar = observer(
    ({
         onSearch,
         loading,
         visibleInputSearch,
         visibleButtonsOfActions,
         visibleButtonRestore,
     }: {
        onSearch: (searchParam: string) => void,
        loading: boolean
        visibleInputSearch: boolean,
        visibleButtonsOfActions: boolean,
        visibleButtonRestore: boolean,
    }) => {

        const {setVisibleModalExport, setVisibleModalRestore} = useBetween(useModalContacts);
        const appController = useInjection(AppController);
        return (
            <ContentFilter>
                <ContentInputAndListableUserDeleted>
                    <ContentInput>
                        {visibleInputSearch && <ContentInputSearch>
                            <InputSearchGeneric
                                inputSearchPlaceholder="Pesquise por todos os contatos"
                                loading={loading}
                                onSearch={(value) => onSearch(value)}
                            />
                        </ContentInputSearch>}

                        {visibleButtonRestore && <ButtonNew
                            marginLeft={0}
                            label={'Restaurar'}
                            onClick={() => setVisibleModalRestore(true)}
                            nameLeftIcon={ICONS_DROPDESK.restore}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}
                    </ContentInput>

                </ContentInputAndListableUserDeleted>

                <ContentExportImport>

                    {visibleButtonsOfActions && appController.user?.permissionAdmin && <ButtonNew
                        marginLeft={10}
                        label={'Exportar'}
                        onClick={() => setVisibleModalExport(true)}
                        nameLeftIcon={ICONS_DROPDESK.excel}
                        sizeLeftIcon={15}
                        disabled={loading}
                    />}

                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBar;
