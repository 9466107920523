import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {
    GetReportTicketAggregateUseCase
} from "@dropDesk/domain/use_case/reports/report_ticket_aggregate/get_report_ticket_aggregate.usecase";
import {
    LineChartReportTicketAggregate
} from "@dropDesk/domain/entities/reports/report_ticket_aggregate/line_chart_report_ticket_agreggate";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {getEndHourCurrentDate, getInitialHourCurrentDate} from "@dropDesk/utils/helpers/date_helper";
import {
    ExportPdfReportTicketAggregateUseCase
} from "@dropDesk/domain/use_case/reports/report_ticket_aggregate/export_pdf_report_ticket_aggregate.usecase";
import {
    getPeriodAndLabelDisplay,
    PeriodEnum,
    setPeriodAndLabelDisplay
} from "@dropDesk/utils/helpers/common";

configure({
    enforceActions: "always",
});

@injectable()
export class ReportTicketAggregateController {
    private readonly _getReportTicketAggregate: GetReportTicketAggregateUseCase;
    private readonly _exportPdfReportTicketAggregateUseCase: ExportPdfReportTicketAggregateUseCase;

    constructor(
        @inject(GetReportTicketAggregateUseCase) getReportTicketAggregate: GetReportTicketAggregateUseCase,
        @inject(ExportPdfReportTicketAggregateUseCase) exportPdfReportTicketAggregateUseCase: ExportPdfReportTicketAggregateUseCase,
    ) {
        makeObservable(this);
        this._getReportTicketAggregate = getReportTicketAggregate;
        this._exportPdfReportTicketAggregateUseCase = exportPdfReportTicketAggregateUseCase;

    }


    @observable
    lineChartClientsAggregate: LineChartReportTicketAggregate[] | undefined

    @observable
    lineChartSectorsAggregate: LineChartReportTicketAggregate[] | undefined

    @observable
    lineChartUsersAggregate: LineChartReportTicketAggregate[] | undefined

    @observable
    lineChartDescriptionsStaticAggregate: LineChartReportTicketAggregate[] | undefined

    @observable
    timeFirstResponseAggregate: number = 0;

    @observable
    averageRating: number = 5;

    @observable
    period: Array<Date> | null = getPeriodAndLabelDisplay().range;

    @observable
    labelPeriod: PeriodEnum = getPeriodAndLabelDisplay().label;

    @observable
    loading = false;

    @action
    setPeriod(period: Array<Date> | null, label: PeriodEnum) {
        this.period = period;
        this.labelPeriod = label;
        setPeriodAndLabelDisplay({
            range: period!,
            label: label
        });
    }

    @action
    hasData(chart?: LineChartReportTicketAggregate[]): boolean {
        return !!chart && chart.filter(entry => entry.y > 0).length > 0;
    }


    @action
    setLineChartClientsAggregate(lineChartClientsAggregate: LineChartReportTicketAggregate[]) {
        this.lineChartClientsAggregate = lineChartClientsAggregate;
    }


    @action
    setLineChartSectorsAggregate(lineChartSectorsAggregate: LineChartReportTicketAggregate[]) {
        this.lineChartSectorsAggregate = lineChartSectorsAggregate;
    }

    @action
    setLineChartUsersAggregate(lineChartUsersAggregate: LineChartReportTicketAggregate[]) {
        this.lineChartUsersAggregate = lineChartUsersAggregate;
    }

    @action
    setLineChartDescriptionsStaticAggregate(lineChartDescriptionsStaticAggregate: LineChartReportTicketAggregate[]) {
        this.lineChartDescriptionsStaticAggregate = lineChartDescriptionsStaticAggregate;
    }

    @action
    setTimeFirstResponseAggregate(timeFirstResponseAggregate: number) {
        this.timeFirstResponseAggregate = timeFirstResponseAggregate;
    }

    @action
    setAverageRating(averageRating: number) {
        this.averageRating = averageRating;
    }

    @observable
    loadingMessage?: string | null = null;

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    initialize() {
        this.getDataReportTicketAggregate();
    }

    @action
    setAllDataReportTicketAggregate(
        lineChartClientsAggregate: LineChartReportTicketAggregate[], lineChartSectorsAggregate: LineChartReportTicketAggregate[],
        lineChartUsersAggregate: LineChartReportTicketAggregate[], lineChartDescriptionsStaticAggregate: LineChartReportTicketAggregate[],
        timeFirstResponse: number, averageRating: number
    ) {
        this.setLineChartClientsAggregate(lineChartClientsAggregate);
        this.setLineChartSectorsAggregate(lineChartSectorsAggregate);
        this.setLineChartUsersAggregate(lineChartUsersAggregate);
        this.setLineChartDescriptionsStaticAggregate(lineChartDescriptionsStaticAggregate);
        this.setTimeFirstResponseAggregate(timeFirstResponse);
        this.setAverageRating(averageRating);
    }

    @action
    removeAllData() {
        this.setLineChartClientsAggregate([]);
        this.setLineChartSectorsAggregate([]);
        this.setLineChartUsersAggregate([]);
        this.setLineChartDescriptionsStaticAggregate([]);
        this.setTimeFirstResponseAggregate(0);
        this.setAverageRating(0);
    }

    @action
    getDataReportTicketAggregate = async (): Promise<void> => {
        try {
            if (this.period && this.period[0] && this.period[1]) {
                this.removeAllData();
                this.startLoading();
                const response = await this._getReportTicketAggregate.call({
                    startDate: getInitialHourCurrentDate(this.period[0]),
                    endDate: getEndHourCurrentDate(this.period[1])
                });
                this.setAllDataReportTicketAggregate(response.lineChartClientsAggregate, response.lineChartSectorsAggregate, response.lineChartUsersAggregate,
                    response.lineChartDescriptionsStaticAggregate, response.timeFirstResponseAggregate, response.averageRating)
                this.stopLoading();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };


    @action
    exportPdf = async (onSucess: (key: string) => void): Promise<void> => {
        try {
            if (this.period && this.period[0] && this.period[1]) {
                this.startLoading("report_ticket.export_pdf");
                await this._exportPdfReportTicketAggregateUseCase.call(
                    {
                        startDate: getInitialHourCurrentDate(this.period[0]),
                        endDate: getEndHourCurrentDate(this.period[1])
                    },
                );
                onSucess("report_ticket.success.export_pdf");
                this.stopLoading();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

}
