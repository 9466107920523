import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketNotificationEntity} from "@dropDesk/domain/entities/ticket/ticket_notifications_entity";

export class ShortTicketEntity {
    id!: string;
    notifications?: TicketNotificationEntity[];

    constructor({
                    id,
                    notifications,
                }: {
        id: string;
        notifications?: TicketNotificationEntity[];
    }) {
        Object.assign(this, {
            id,
            notifications
        });
    }

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = [
            'notifications',
        ];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    public static fromTicket(ticket: TicketEntity): ShortTicketEntity {
        return new ShortTicketEntity({
            id: ticket.id,
        });
    }

    public static fromJson(json: Record<string, any>): ShortTicketEntity {
        return new ShortTicketEntity({
            id: json['id'],
            notifications: json['notifications'] ? (json['notifications'] as any[]).map((notifications) => TicketNotificationEntity.fromJson(notifications)) : undefined,
        });
    }

    copyWith({
                 id,
                 notifications,
             }: {
        id?: string;
        notifications?: TicketNotificationEntity[];

    }): ShortTicketEntity {
        return new ShortTicketEntity({
            id: id ?? this.id,
            notifications: notifications ?? this.notifications
        })
    }
}
