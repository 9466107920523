import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {CancelTokenSource} from "axios";

@injectable()
export class InformReadMessageUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(ticketId: string, cancelTokenSource?: CancelTokenSource): Promise<void> {
        return this._repository.informReadMessages(ticketId, cancelTokenSource);
    }
}

