import styled from "styled-components";

export type ContainerType = {
    background: string;
}

export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 7px;
  background: ${(props) => props.background};
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 16px;
`;

export const ContentDescriptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 16px 0;
`;


export const TextDescription = styled.li<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  margin: 3px 0px 3px 15px;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

export const ContentRadioButton = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 16px 0;
`;

