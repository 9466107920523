import {observer} from "mobx-react";
import React from "react";
import whatsAppBackground from "@dropDesk/storage/images/whatsapp_connection.png";
import ImageStatus
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/connection/status_whatsapp/image_status";
import {useBetween} from "use-between";
import {
    useModalWhatsApp
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/handle_change_visible";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";


const StatusWhatsApp = observer(
    ({
         chatConnection,
         startConnection,
         loading
     }: {
        chatConnection: ChatIntegrationConnectionEntity,
        startConnection: () => Promise<void>
        loading: boolean
    }) => {

        const {setVisibleModalLogout, setVisibleModalConnect} = useBetween(useModalWhatsApp);

        return (
            <div>
                {!chatConnection.hasActiveConnection &&
                    <ImageStatus
                        textButton={'Conectar'}
                        status={'Desconectado'}
                        imageUrl={whatsAppBackground}
                        onClickButton={async () => {
                            await startConnection();
                            setVisibleModalConnect(true);
                        }}
                        loading={loading}
                    />
                }
                {chatConnection.hasActiveConnection &&
                    <ImageStatus
                        textButton={'Desconectar'}
                        isDisconnected={true}
                        status={'Conectado'}
                        imageUrl={null}
                        onClickButton={() => setVisibleModalLogout(true)}
                        loading={loading}
                    />
                }
            </div>
        )
    })
export default StatusWhatsApp;
