import {Container, ContainerCompany, ContainerInputs, Content} from "./styled";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import React from "react";
import {CompanyEntity, CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import InputsInfoHolder from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/inputs_info_holder";
import InputAddress from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/inputs_address";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import {TabsPayment} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import PaymentMethod from "@dropDesk/presentation/routes/update_company_info_from_migration/body/payment_method";
import CompanyConfiguration from "@dropDesk/presentation/pages/company_configurations/ui/company";
import {TokenizeCardEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {Divider} from "antd";


const Body = observer((
    {
        company,
        loading,
        paymentInfo,
        setPaymentInfo,
        setCompany,
        onSearchCNPJ,
        onSearchZipCode,
        newTokenizeCardEntity,
        setTokenizeCard,
        activeTabPayment,
        setActiveTabPayment
    }: {
        company?: CompanyEntity
        loading: boolean
        paymentInfo: CompanyPaymentInfo
        setPaymentInfo: (paymentInfo: CompanyPaymentInfo) => void
        setCompany: (company: CompanyEntity) => void
        onSearchCNPJ: (document: string) => Promise<void>,
        onSearchZipCode: (zipCode: string) => Promise<void>,
        newTokenizeCardEntity: TokenizeCardEntity | null
        setTokenizeCard: (newTokenizeCardEntity: TokenizeCardEntity | null) => void
        activeTabPayment: TabsPayment | null
        setActiveTabPayment: (value: TabsPayment | null) => void
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>

            <Content>
                <Divider style={{color: colors.hint, borderColor: colors.border}}>Dados da empresa</Divider>
                <ContainerCompany>
                    <CompanyConfiguration
                        onUpdate={(company) => setCompany(company)}
                        getConfigCompany={() => {
                        }}
                        handleSubmit={() => {
                        }}
                        company={company}
                        loading={loading}
                        fromMigration={true}
                    />
                </ContainerCompany>
                <Divider style={{color: colors.hint, borderColor: colors.border}}>Dados de cobrança</Divider>
                <ContainerInputs>
                    <InputsInfoHolder
                        newPaymentInfo={paymentInfo}
                        loading={loading}
                        onSearchCNPJ={(cnpj) => onSearchCNPJ(cnpj)}
                        onUpdate={(paymentInfo) => setPaymentInfo(paymentInfo)}
                    />
                    <InputAddress
                        newPaymentInfo={paymentInfo}
                        loading={loading}
                        onSearchZipCode={(zipCode) => onSearchZipCode(zipCode)}
                        onUpdate={(paymentInfo) => setPaymentInfo(paymentInfo)}
                    />
                    <CheckBoxWithLabel
                        height={21}
                        useDisplayInLineBlock={true}
                        label={'Desejo receber nota fiscal'}
                        checked={paymentInfo.billingInfoHolder.receiveInvoices ?? false}
                        disabled={loading}
                        onChange={(event) => {
                            const newPaymentInfo = paymentInfo.copyWith({
                                billingInfoHolder: paymentInfo.billingInfoHolder.copyWith({
                                    receiveInvoices: event.target.checked
                                })
                            });
                            setPaymentInfo(newPaymentInfo);
                        }}
                        name={'receiveInvoices'}
                        marginLeft={0}
                    />
                </ContainerInputs>
                <Divider style={{color: colors.hint, borderColor: colors.border}}>Forma de pagamento</Divider>
                {newTokenizeCardEntity && <PaymentMethod
                    newTokenizeCard={newTokenizeCardEntity}
                    onUpdateTokenizeCard={(card) => setTokenizeCard(card)}
                    loading={loading}
                    activeTabPayment={activeTabPayment}
                    setActiveTabPayment={(key) => setActiveTabPayment(key as TabsPayment)}
                />}

            </Content>

        </Container>
    );
});
export default Body;
