import styled from "styled-components";

export type ContainerType = {
    background: string;
}

export type TextType = {
    color: string;
}

export type IconType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
`;

export const Text = styled.span<TextType>`
  font-size: 13.5px;
  color: ${props => props.color};
  font-weight: normal;
`;

export const TextBold = styled.span<TextType>`
  font-size: 14px;
  color: ${props => props.color};
  font-weight: bold;
`;

export const ContentHour = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0;
`;

export const ContentDay = styled.div`
  width: 100%;
`;
