import "reflect-metadata";
import {inject, injectable} from "inversify";
import {
    CompanyConfigurationRepository
} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";

@injectable()
export class ResetChatMessagesCompanyUseCase {
    private _repository: CompanyConfigurationRepository;

    constructor(@inject(CompanyConfigurationRepository) repository: CompanyConfigurationRepository) {
        this._repository = repository;
    }

    public call(): Promise<CompanyConfigurationEntity> {
        return this._repository.resetChatMessages();
    }
}

