import React, {useEffect, useRef, useState} from "react";
import PinItem from "./pin_item";
import {ButtonClear, DivButtonClear} from "@dropDesk/presentation/components/pin_field/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {removeLetters} from "@dropDesk/utils/helpers/string_helper";

function PinInput(
    {
        noOfBoxes,
        maxLength = 1,
        onChange,
        disabled,
        defaultValue,
        autoFocus,
        useButtonClear,
        acceptOnlyNumbers
    }: {
        noOfBoxes: number
        maxLength?: number
        onChange: (pin: string) => void
        disabled?: boolean
        defaultValue?: string
        autoFocus?: boolean
        useButtonClear?: boolean
        acceptOnlyNumbers?: boolean
    }) {
    const [values, setValues] = useState(() => new Array(noOfBoxes).fill(""));
    const arrayBoxes = new Array(noOfBoxes).fill(0);
    const ref = useRef<HTMLInputElement[]>([]);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;


    useEffect(() => {
        initialize();
    }, []);


    const handleClear = (): void => {
        setValues([]);
        ref.current.map((value, index) => {
            ref.current[index].value = '';
        });
        onChange('');
        ref.current[0].focus();
    }

    const initialize = () => {
        if (defaultValue && defaultValue.length > 0) {
            const _values = defaultValue.split('').filter((a, i) => i < maxLength * noOfBoxes);
            _values.map((value, index) => {
                setPasteValue(value, index);
            });
        } else {
            if (autoFocus) {
                ref.current[0].focus();
            }
        }
    }


    const setPasteValue = (value: string, index: number): void => {
        const _value = acceptOnlyNumbers ? removeLetters(value) : value;
        if (!_value) {
            ref.current[index].value = '';
            return;
        }
        values[index] = _value;
        ref.current[index].value = _value;
        if (index < noOfBoxes - 1) {
            ref.current[index + 1].focus();
        }
        setValues([...values]);
        onChange && onChange(values.join(""));
    }

    const handleChange = (value: string, index: number) => {
        const _value = acceptOnlyNumbers ? removeLetters(value) : value;
        if (!_value) {
            ref.current[index].value = '';
            return;
        }
        values[index] = _value;
        setValues([...values]);
        if (_value.length === maxLength && index < noOfBoxes - 1) {
            ref.current[index + 1].focus();
        }
        onChange && onChange(values.join(""));
    };

    const handleBackSpace = (val: string, index: number) => {
        let tmp = values[index];
        values[index] = val;
        if (index > 0 && tmp.length === 0) {
            ref.current[index - 1].focus();
        }
        setValues([...values]);
        onChange(values.join(""));
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        const pasteValue = event.clipboardData
            .getData("text")
            .split("")
            .filter((a, i) => i < maxLength * noOfBoxes);
        pasteValue.forEach((char, index) => {
            setPasteValue(char, index);
        });
    };


    return (
        <div
            style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                flexDirection: 'row'
            }}
            onPaste={handlePaste}
        >
            {arrayBoxes.map((_, index: number) => (
                <PinItem
                    key={index}
                    ref={(el) => (ref.current[index] = el!)}
                    handleChange={(value) => handleChange(value, index)}
                    handleBackSpace={(value) => handleBackSpace(value, index)}
                    maxLength={maxLength}
                    disabled={disabled ?? false}
                />
            ))}
            {useButtonClear &&
                <DivButtonClear>
                    <ButtonClear
                        disabled={false}
                        background={colors.onBackground}
                        type={'submit'}
                        letterColor={colors.text}
                        onClick={() => handleClear()}
                        border={colors.border}
                        shadow={colors.accent}
                    >
                        Limpar
                    </ButtonClear>
                </DivButtonClear>
            }
        </div>
    );
}

export default PinInput;
