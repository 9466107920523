import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {ListNoticesUseCase} from "@dropDesk/domain/use_case/notices/list_notices_pagination.usecase";
import {SetNoticesUseCase} from "@dropDesk/domain/use_case/notices/set_notices.usecase";
import {FindByPKNoticesUseCase} from "@dropDesk/domain/use_case/notices/findbypk_notices.usecase";
import {RestoreNoticesUseCase} from "@dropDesk/domain/use_case/notices/restore_notices.usecase";
import {DeleteNoticesUseCase} from "@dropDesk/domain/use_case/notices/delete_notices.usecase";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {newNotices} from "@dropDesk/presentation/pages/notices/controller/new_notices";
import {Subscription} from "rxjs";
import {ListNoticesByUserUseCase} from "@dropDesk/domain/use_case/notices/list_notices_by_user_notices.usecase";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {
    initializeConfigurationLocalStorage, preventMissingPage, setGenericFiltersLocalStorage,
    setSearchParamLocalStorage
} from "@dropDesk/utils/helpers/common";

configure({
    enforceActions: "always",
});

@injectable()
export class NoticesController {

    private readonly _listNoticesUseCase: ListNoticesUseCase;
    private readonly _setNoticesUseCase: SetNoticesUseCase;
    private readonly _findByPKNoticesUseCase: FindByPKNoticesUseCase;
    private readonly _restoreUseCase: RestoreNoticesUseCase;
    private readonly _deleteUseCase: DeleteNoticesUseCase;
    private readonly _listNoticesByUserUseCase: ListNoticesByUserUseCase;

    constructor(
        @inject(ListNoticesUseCase) listNoticesUseCase: ListNoticesUseCase,
        @inject(SetNoticesUseCase) setNoticesUseCase: SetNoticesUseCase,
        @inject(FindByPKNoticesUseCase) findByPKNoticesUseCase: FindByPKNoticesUseCase,
        @inject(RestoreNoticesUseCase) restoreUseCase: RestoreNoticesUseCase,
        @inject(DeleteNoticesUseCase) deleteUseCase: DeleteNoticesUseCase,
        @inject(ListNoticesByUserUseCase) listNoticesByUserUseCase: ListNoticesByUserUseCase,
    ) {
        makeObservable(this);
        this._listNoticesUseCase = listNoticesUseCase;
        this._setNoticesUseCase = setNoticesUseCase;
        this._findByPKNoticesUseCase = findByPKNoticesUseCase;
        this._restoreUseCase = restoreUseCase;
        this._deleteUseCase = deleteUseCase;
        this._listNoticesByUserUseCase = listNoticesByUserUseCase;
    }

    newNotices: NoticesEntity = newNotices();

    table = new ListPaginationEntity<NoticesEntity>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 50),
        totalRows: 0,
        data: observable.array([])
    });

    tableByUser = new ListPaginationEntity<NoticesEntity>({
        pages: 0,
        page: 0,
        limit: 5,
        totalRows: 0,
        data: observable.array([])
    });

    @observable
    loadingMessage?: string | null = null;

    @observable
    loading = false;

    @observable
    tableNotices: ListPaginationEntity<NoticesEntity> = this.table;

    @observable
    tableNoticesByUser: ListPaginationEntity<NoticesEntity> = this.tableByUser;

    @observable
    pagesData: Record<number, NoticesEntity[]> = {};

    @observable
    pagesDataByUser: Record<number, NoticesEntity[]> = {};

    @observable
    notice?: NoticesEntity;

    @observable
    searchParam = '';

    @observable
    noticesNotFound: boolean = false;

    @observable
    listOnlyDeleted = false;

    @observable
    rowSelectionNotices: NoticesEntity[] = [];

    @observable
    filterDestiny: NoticeDestiny | null = null;

    @observable
    subscriptionListByUserNotice?: Subscription;

    @action
    setFilterDestiny(destiny: NoticeDestiny | null) {
        this.filterDestiny = destiny;
        setGenericFiltersLocalStorage({noticeFilterDestiny: destiny});
        this.resetTable();
        this.list(this.searchParam);
    }

    @action
    setRowSelectionNotices(notices: NoticesEntity[]) {
        this.rowSelectionNotices = notices;
    }

    @action
    resetTable() {
        this.pagesData = {};
        this.setTableNotices(this.table);
    }

    @action
    resetTableByUser() {
        this.pagesDataByUser = {};
        this.setTableNoticesByUser(this.tableByUser);
    }

    @action
    setListOnlyDeleted(value: boolean) {
        this.listOnlyDeleted = value;
        this.removeLineSelectRow();
        this.resetTable();
        this.list(this.searchParam);
    }

    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setNotices(notice: NoticesEntity) {
        this.notice = notice;
    }


    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableNotices(this.tableNotices.copyWith({
                ...this.tableNotices,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableNotices(this.tableNotices.copyWith({
                ...this.tableNotices,
                page: page,
            }))
            return this.list(this.searchParam);
        }
    }

    @action
    getDataFromPageByUser = async (page: number): Promise<void> => {
        if (this.pagesDataByUser[page]) {
            this.setTableNoticesByUser(this.tableNoticesByUser.copyWith({
                ...this.tableNoticesByUser,
                page: page,
                data: this.pagesDataByUser[page],
            }))

        } else {
            this.setTableNoticesByUser(this.tableNoticesByUser.copyWith({
                ...this.tableNoticesByUser,
                page: page,
            }))
            return this.listNoticesByUser();
        }
    }


    @action
    setNoticesNotFound(value: boolean) {
        this.noticesNotFound = value;
    }

    @action
    removeLineSelectRow() {
        this.setRowSelectionNotices([]);
    }

    @action
    initialize(heightGarbage: number) {
        const routeName = RoutesEnum.notices;
        const {lastPage, initSearchParam, filters} = initializeConfigurationLocalStorage(routeName);
        this.tableNotices.limit = Math.floor((window.innerHeight - 265 + heightGarbage) / 50);
        this.tableNotices.page = lastPage;
        this.filterDestiny = filters.noticeFilterDestiny!;
        this.searchParam = initSearchParam;
        this.list(initSearchParam);
    }

    @action
    set = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("notices.loadingSave");
            if (this.notice?.isUnsaved()) {
                this.setNotices(this.notice!.copyWith({
                    id: generateUUIDV4()
                }))
            }

            await this._setNoticesUseCase.call(this.notice!);
            onSuccess("notices.success.save");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    delete = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("notices.loadingDelete");
            await this._deleteUseCase.call(this.rowSelectionNotices);
            this.removeLineSelectRow();
            onSuccess("notices.success.delete");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    restore = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("notices.loadingRestore");
            await this._restoreUseCase.call(this.rowSelectionNotices);
            this.removeLineSelectRow();
            onSuccess("notices.success.restore");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();

        }
    }
    @action
    makeNotices = (): void => {
        this.setNotices(this.newNotices);
    }

    @action
    getNotices = async (id: string) => {
        try {

            this.startLoading("notices.loadingInit")
            const response = await this._findByPKNoticesUseCase.call(id);
            this.setNotices(response);
            this.stopLoading()

        } catch (err: any) {

            this.setNoticesNotFound(true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();

        }

    }

    @action
    setTableNotices(value: ListPaginationEntity<NoticesEntity>) {
        this.tableNotices = value;
        const isNecessaryReload = preventMissingPage(this.tableNotices);
        if (isNecessaryReload) {
            this.list('');
        } else {
            this.pagesData[this.tableNotices.page] = this.tableNotices.data;
        }
    }

    @action
    setTableNoticesByUser(value: ListPaginationEntity<NoticesEntity>) {
        this.tableNoticesByUser = value;
        this.pagesDataByUser[this.tableNoticesByUser.page] = this.tableNoticesByUser.data;
    }


    @action
    list = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listNoticesUseCase.call(this.tableNotices.page, this.searchParam, this.tableNotices.limit, this.listOnlyDeleted, this.filterDestiny);
            this.setTableNotices(response);
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    listNoticesByUser = async (forceResetTable = false): Promise<void> => {
        try {
            this.startLoading();
            if (forceResetTable) {
                this.resetTableByUser();
            }
            const response = await this._listNoticesByUserUseCase.call(this.tableNoticesByUser.page, this.tableNoticesByUser.limit);
            this.setTableNoticesByUser(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    dispose(): void {
        this.subscriptionListByUserNotice?.unsubscribe();
    }

}
