import {observer} from "mobx-react";
import {
    Button,
    Container,
    Content, ContentCheckBox, ContentPortalClient, TextCheckBox
} from "./styled";
import React, {useState} from "react";
import Switch from "@dropDesk/presentation/components/switch";
import {Tooltip} from "antd";

import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";

import ModalPortalClient from "@dropDesk/presentation/components/modals/modal_portal_client";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const ConfigurationPortalClient = observer(
    ({
         loading,
         companyConfiguration,
         onUpdate
     }: {
        loading: boolean,
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
        companyConfiguration: CompanyConfigurationEntity
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const [visibleModalPortalClient, setVisibleModalPortalClient] = useState<boolean>(false);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const _configuration = companyConfiguration.copyWith({
                portalClient: companyConfiguration.portalClient.copyWith({
                    [event.target.name]:
                        (event.target.type === "checkbox" || event.target.type === "switch") ?
                            event.target.checked : event.target.value
                })
            });
            onUpdate(_configuration);
        };


        return (
            <Container>
                <Content>

                    <ContentCheckBox>
                        <Switch
                            disabled={loading}
                            id={'useChatOnline'}
                            name={'useChatOnline'}
                            checked={companyConfiguration.portalClient.useChatOnline}
                            onChange={handleAllChanges}
                        />
                        <Tooltip title={`Ao selecionar esta opção, o atendimento aberto através do portal do cliente, será tratado como um chat, passando 
            pela fila de espera e podendo ser fechado por inatividade.`}>
                            <TextCheckBox onClick={() => {
                            }} color={colors.text}>Utilizar chat para atendimento online

                                <DropDeskIcon
                                    color={colors.hint}
                                    icon={ICONS_DROPDESK.interrogationOutline}
                                />
                            </TextCheckBox>
                        </Tooltip>
                    </ContentCheckBox>

                    <ContentPortalClient>
                        <Button
                            accent={colors.accent}
                            border={colors.border}
                            background={colors.onBackground}
                            color={colors.text}
                            onClick={() => setVisibleModalPortalClient(!visibleModalPortalClient)}
                        >
                            Ver
                        </Button>
                        <TextCheckBox color={colors.text}>
                            Acesso ao portal do cliente
                        </TextCheckBox>
                    </ContentPortalClient>

                </Content>
                <ModalPortalClient
                    visibleModalPortalClient={visibleModalPortalClient}
                    setVisibleModalPortalClient={() => setVisibleModalPortalClient(!visibleModalPortalClient)}
                />
            </Container>
        )
    })
export default ConfigurationPortalClient;
