import styled from "styled-components";

type IconOpsType = {
    color: string;
}

export const ContentPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
export const TextPermission = styled.span<IconOpsType>`
  font-size: 15px;
  color: ${props => props.color};
`;

export const ContentDisplayName = styled.div`
  display: flex;
  align-items: center;
  margin: 22px 0;
`;

export const ContainerPopOver = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-height: 250px;
  min-height: 250px;
  height: 250px;
  max-width: 400px;
  min-width: 400px;
  width: 400px;
  padding: 16px;
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
