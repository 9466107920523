import styled from "styled-components";


export const ContentData = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
`;

export const ContentImage = styled.div`
  display: flex;
  width: 170px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
`;

export const ContentInputNameIdentity = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
