import {observer} from "mobx-react";
import React from "react";
import {
    ContentPermission, TextPermission
} from "./styled";
import Switch from "@dropDesk/presentation/components/switch";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";


const InputsNotification = observer(
    ({
         companyConfiguration,
         loading,
         onUpdate
     }: {
        companyConfiguration: CompanyConfigurationEntity
        loading: boolean,
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const handleAllChangesNotificationConfiguration = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const _configurationWhatsApp = companyConfiguration.copyWith({
                notifications: companyConfiguration.notifications.copyWith({
                    [event.target.name]:
                        (event.target.type === "checkbox" || event.target.type === "switch") ?
                            event.target.checked : event.target.value
                })
            });
            onUpdate(_configurationWhatsApp);
        };

        return (
            <>
                <ContentPermission>
                    <Switch
                        disabled={loading}
                        id={'allowNewTicketNotification'}
                        name={'allowNewTicketNotification'}
                        checked={companyConfiguration.notifications.allowNewTicketNotification}
                        onChange={handleAllChangesNotificationConfiguration}
                    />
                    <TextPermission color={colors.text}>Novo Atendimento </TextPermission>
                </ContentPermission>
                {!appController.isUserClient && <ContentPermission>
                    <Switch
                        disabled={loading}
                        id={'allowTransferTicketNotification'}
                        name={'allowTransferTicketNotification'}
                        checked={companyConfiguration.notifications.allowTransferTicketNotification}
                        onChange={handleAllChangesNotificationConfiguration}
                    />
                    <TextPermission color={colors.text}>Atendimentos atribuídos a mim</TextPermission>
                </ContentPermission>}
                <ContentPermission>
                    <Switch
                        disabled={loading}
                        id={'allowMessagesTicketNotification'}
                        name={'allowMessagesTicketNotification'}
                        checked={companyConfiguration.notifications.allowMessagesTicketNotification}
                        onChange={handleAllChangesNotificationConfiguration}
                    />
                    <TextPermission color={colors.text}>Respostas nos atendimentos</TextPermission>

                </ContentPermission>
            </>
        )
    })
export default InputsNotification;
