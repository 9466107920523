import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {Observable} from "rxjs";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";

@injectable()
export class SubscriptionUploadFileUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(message: TicketMessageEntity): Observable<TicketMessageEntity> {
        return this._repository.subscriptionUploadFile(message);
    }
}

