import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {NoticesRepository} from "@dropDesk/domain/repositories/notices/notices.repository";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";

@injectable()
export class ListNoticesUseCase {
  private _repository: NoticesRepository;

  constructor(@inject(NoticesRepository) repository: NoticesRepository) {
    this._repository = repository;
  }

  public call(
    page: number,
    searchParam: string,
    limit: number,
    listOnlyDeleted: boolean,
    filterByDestiny: NoticeDestiny | null
  ): Promise<ListPaginationEntity<NoticesEntity>> {
    return this._repository.list(page, searchParam, limit, listOnlyDeleted, filterByDestiny);
  }
}

