import {useState} from "react";

export const useModalsReportTicketAggregate = () => {

  const [visibleModalInformationReport, setVisibleModalInformationReport] = useState<boolean>(false);
  const [textHeaderModalInformationReport, setTextHeaderModalInformationReport] = useState('');
  const [textInformationHeader, setTextInformationHeader] = useState('');
  const [textInformationBody, setTextInformationBody] = useState('');
  const [visibleModalExportPdf, setVisibleModalExportPdf] = useState<boolean>(false);
  const [visibleModalExport, setVisibleModalExport] = useState<boolean>(false);


  return {

    visibleModalInformationReport, setVisibleModalInformationReport,
    textHeaderModalInformationReport, setTextHeaderModalInformationReport,
    textInformationHeader, setTextInformationHeader,
    textInformationBody, setTextInformationBody,
    visibleModalExportPdf, setVisibleModalExportPdf,
    visibleModalExport, setVisibleModalExport

  }
}
