import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import type {RadioChangeEvent} from 'antd';
import {Input, Radio, Space} from 'antd';
import {RadioStyled} from "@dropDesk/presentation/components/radio_button/styled";
import React from "react";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export type ValuesButtonType = {
    key: string | number;
    value: string | number | React.ReactNode;
}

export const RadioButton = (
    {
        onChange,
        values,
        value,
        direction
    }: {
        onChange: (e: RadioChangeEvent) => void;
        values: Array<ValuesButtonType>;
        value?: string | number
        direction?: 'horizontal' | 'vertical';
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Radio.Group onChange={onChange} value={value}>
            <Space direction={direction ?? 'vertical'}>
                {values.map((data, index) => {
                    return (
                        <RadioStyled
                            key={index}
                            value={data.key}
                            color={colors.text}
                            accent={colors.accent}
                            backgroundInput={SharedColors.white}
                        >
                            {data.value}
                        </RadioStyled>
                    )
                })}
            </Space>
        </Radio.Group>
    )
};
