import {Container, Content, TableSearch, ContentTable, Empty, PaginationTable, ButtonGo} from "./styled";
import React, {FormEvent, useEffect, useRef} from "react";
import {ScreenSearchProps} from "@dropDesk/presentation/components/tables/table_generic/table_generic";

import DropDeskLoadingDefault from "@dropDesk/presentation/components/loadings/loading_table";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export default function SearchScreen<T extends object>(props: ScreenSearchProps<T>) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const letterColor = colors.text;

    const tableLoading = {
        spinning: props.loading,
        indicator: <DropDeskLoadingDefault
            height={80}
            description={"Carregando ..."}
        />,
    }
    const padding = props.isChildContainer ? 2 * 64 : 64;
    const menu = props.hideMenu ? 0 : props.collapsedMenu ? 80 : 50;
    const decreaseWidth = padding + menu;

    return (

        <Container
            background={props.size === 'small' ? 'transparent' : colors.onBackground}
            backgroundhover={colors.background}
        >
            <Content>
                <ContentTable extraLayout={props.extraLayout ?? 0}>
                    {/*// @ts-ignore*/}
                    <TableSearch
                        size={props.size ?? 'middle'}
                        sticky={props.sticky}
                        locale={{
                            emptyText:
                                <Empty
                                    color={letterColor}>
                                    {props.loading ? 'Carregando ...' : props.labelNoResults}
                                </Empty>

                        }}
                        expandable={props.expandable}
                        rowKey={props.rowKey ?? "id"}
                        columns={props.tableColumns}
                        dataSource={props.tableDataSource}
                        loading={tableLoading}
                        backgroundmainbutton={colors.accent}
                        backgroundlistableitems={props.background ?? colors.onBackground}
                        border={colors.border}
                        backgroundtitle={props.background ?? colors.onBackground}
                        backgroundsecondarycolor={colors.text}
                        lettermaincolor={letterColor}
                        hintInverse={colors.hintInverse}
                        backgroundhover={colors.background}
                        letterbuttoncolor={letterColor}
                        checkedlettercolor={SharedColors.white}
                        pagination={false}
                        hideMenu={props.hideMenu}
                        decreaseWidth={decreaseWidth}
                        rowSelection={props.rowSelection}
                        extraLayout={props.extraLayout ?? 0}
                        onRow={(record: T, rowIndex: number) => {
                            return {
                                onClick: (event: FormEvent) => {
                                    event.stopPropagation();
                                    props.onSelectedRow(record);
                                },
                            };
                        }}
                        hideColumn={props.hideColumn ?? false}
                        heightTableRow={props.heightTableRow ?? 60}
                    >
                    </TableSearch>

                </ContentTable>
                {!props.removePagination && <PaginationTable
                    size="small"
                    disabled={props.loading}
                    accent={colors.accent}
                    current={props.page + 1}
                    onChange={props.onChange}
                    total={props.totalRows}
                    defaultPageSize={props.pageSize}
                    pageSize={props.pageSize}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    showQuickJumper={{
                        goButton: <ButtonGo
                            accent={colors.accent}
                            backgroundsecondarybutton={colors.onBackground}
                            colorletterbutton={colors.text}
                            border={colors.border}
                        ><span>Ir</span></ButtonGo>
                    }}
                    locale={{jump_to: "Página:", page: "",}}
                    style={{color: letterColor, fontSize: 14, background: colors.onBackground}}
                    backgroundmainbutton={colors.accent}
                    backgroundlistableitems={colors.onBackground}
                    backgroundsecondarycolor={colors.onBackground}
                    colorellipsis={colors.text}
                    lettermaincolor={colors.text}
                    letterbuttoncolor={colors.text}
                    border={colors.border}
                />}
            </Content>
        </Container>
    );
}
