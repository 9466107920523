import React, {useEffect} from 'react';
import {
    Container,
    ContentList,
    SubContentList,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {NoticesController} from "@dropDesk/presentation/pages/notices/controller/notices.controller";
import TableSearchNotices from "@dropDesk/presentation/pages/notices/ui/search/table";
import ModalsNotices from "@dropDesk/presentation/pages/notices/ui/search/modals";
import SearchBarNotices from "@dropDesk/presentation/pages/notices/ui/search/search_bar";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import FooterGarbage from "@dropDesk/presentation/components/footer/footer_garbage";

const Notices = (observer(() => {

        const controller = useInjection(NoticesController);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const heightGarbage = appController.user?.permissionDeleteData ? 0 : 50;
        const {t} = useTranslation();

        useEffect(() => {
            controller.initialize(heightGarbage - appController.heightShowingHeaderInfo);
        }, []);

        const onSuccessDeleteOrRestore = (key: string) => {
            controller.list(controller.searchParam);
            toastMessage.success(t(key));
        }

        return (
            <Container background={colors.onBackground}>
                <ContentList>

                    <SearchBarNotices
                        onSearch={(searchParam) => controller.list(searchParam)}
                        setFilterDestiny={(filter) => controller.setFilterDestiny(filter)}
                        filterDestiny={controller.filterDestiny}
                        visibleInputSearch={controller.rowSelectionNotices.length === 0}
                        loading={controller.loading}
                        visibleButtonsOfActions={!controller.listOnlyDeleted && controller.rowSelectionNotices.length === 0}
                        visibleButtonDeleted={!controller.listOnlyDeleted && controller.rowSelectionNotices.length > 0}
                        visibleButtonRestore={controller.listOnlyDeleted && controller.rowSelectionNotices.length > 0}
                    />

                    <SubContentList>
                        <TableSearchNotices
                            setRowSelectionNotices={(users: NoticesEntity[]) => controller.setRowSelectionNotices(users)}
                            getDataFromPage={(page) => controller.getDataFromPage(page)}
                            rowSelectionNotices={controller.rowSelectionNotices}
                            tableNotices={controller.tableNotices}
                            searchParam={controller.searchParam}
                            loading={controller.loading}
                            permitSelectionRow={controller.listOnlyDeleted}
                            heightGarbage={heightGarbage}
                            permissionDeleteData={appController.user?.permissionDeleteData ?? false}
                        />

                        {appController.user?.permissionDeleteData && <FooterGarbage
                            setListOnlyDeleted={(value) => controller.setListOnlyDeleted(value)}
                            visibleListOnlyDeleted={controller.listOnlyDeleted}
                            loading={controller.loading}
                            text={'Avisos ativos'}
                            textDelete={'Avisos excluídos'}
                        />}

                        <ModalsNotices
                            removeLineSelectRow={() => controller.removeLineSelectRow()}
                            restoreNotice={() => controller.restore(onSuccessDeleteOrRestore)}
                            deleteNotice={() => controller.delete(onSuccessDeleteOrRestore)}
                            loading={controller.loading}
                        />
                    </SubContentList>

                </ContentList>
                {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                    height={250}
                    description={controller.loadingMessage}
                />}

            </Container>
        )

    }
))
export default Notices;
