import {Container} from "./styled";
import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import Header from "@dropDesk/presentation/pages/subscription/ui/select_plan/confirm_plan/header";
import Body from "@dropDesk/presentation/pages/subscription/ui/select_plan/confirm_plan/body";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";


const ConfirmPlan = (
    {
        setActiveRoute,
        loading,
        currentSubscription,
        newSubscription,
        handleAllChanges,
        hasDowngrade,
    }: {
        setActiveRoute: (route: RoutesSubscription) => void
        loading: boolean
        currentSubscription: PaymentSubscriptionEntity
        newSubscription: PaymentSubscriptionEntity
        handleAllChanges: (event: {
            target: {
                name: string;
                value: any;
                checked: any;
                type: string
            }
        }) => void
        hasDowngrade: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container
            background={colors.onBackground}
            isDarkTheme={colors.brightness === Brightness.dark}
            border={colors.border}
        >
            <Header
                setActiveRoute={setActiveRoute}
                loading={loading}
            />
            <Body
                newSubscription={newSubscription}
                handleAllChanges={handleAllChanges}
                currentSubscription={currentSubscription}
                hasDowngrade={hasDowngrade}
            />
        </Container>
    );
}
export default ConfirmPlan;
