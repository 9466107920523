import styled from "styled-components";

type TextType = {
    color: string;
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
