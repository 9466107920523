import {inject, injectable} from "inversify";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {from, mergeMap, Observable, Observer} from "rxjs";
import {apolloClient} from "@dropDesk/data/clients/apollo.client";
import {gql} from "@apollo/client";
import {StorageKeys} from "@dropDesk/domain/entities/constants/storage_keys";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {AxiosResponse, CancelTokenSource} from "axios";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {VoidSuccess} from "@dropDesk/domain/entities/response/response";
import {
    ChatIntegrationConnectionEntity,
    ConnectionExtraInfoEntity
} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {StatusTypes} from "@dropDesk/domain/entities/chat/chat_enum";
import {ShortTicketEntity} from "@dropDesk/domain/entities/ticket/short_ticket";
import {RemoteStorageDatasource} from "@dropDesk/data/data_source/remote_storage/remote_storage_datasource";
import firebase from "firebase/compat";
import FirebaseStorageError = firebase.storage.FirebaseStorageError;
import {ListPaginationChatEntity} from "@dropDesk/domain/entities/chat/list_pagination_chat.entity";
import {TypeListChat} from "@dropDesk/presentation/pages/chat/controller/chat.controller";
import {newSystemMessage} from "@dropDesk/presentation/pages/chat/controller/new_message";
import {DropDeskErrorEnum} from "@dropDesk/domain/entities/exceptions/dropdesk_error_enum";

@injectable()
export abstract class ChatRemoteDataSource {
    public abstract listChatsAttending(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>>;

    public abstract listChatsWaiting(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>>;

    public abstract subscribe(id?: string): Observable<TicketEntity[]>;

    public abstract loadMessages(chatId: string, page: number, cancelTokenSource?: CancelTokenSource): Promise<ListPaginationEntity<TicketMessageEntity>>;

    public abstract listChatMessagesAfterSubscription(chatId: string): Promise<TicketMessageEntity[]>;

    public abstract updateUsersInfoData(tickets: TicketEntity[]): Promise<void>;

    public abstract getUsersFromCache(input: Record<string, boolean>): UserEntity[];

    public abstract startConnection(): Promise<ChatIntegrationConnectionEntity>;

    public abstract logOut(): Promise<VoidSuccess>;

    public abstract connectionState(): Promise<ChatIntegrationConnectionEntity>;

    public abstract subscribeConnection(): Observable<ChatIntegrationConnectionEntity>;

    public abstract sendMessage(message: TicketMessageEntity, associateSenderToTicket: boolean): Promise<TicketMessageEntity>;

    public abstract delete(id: string): Promise<void>;

    public abstract subscriptionUploadFile(message: TicketMessageEntity): Observable<TicketMessageEntity>;

    public abstract editMessage(idMessage: string, text: string): Promise<void>;

    public abstract navigateToMessage(id: string, ticketNumber: number): Promise<ListPaginationEntity<TicketMessageEntity>>;

    public abstract reactMessage(
        messageId: string,
        reactionId: string,
        reaction: string,
    ): Promise<VoidSuccess>;

    public abstract informReadMessages(ticketId: string, cancelTokenSource?: CancelTokenSource): Promise<void>;

    public abstract updateUsersCache(users: UserEntity[]): Promise<void>;

    public abstract clearTicketsCache(): void;
}

@injectable()
export class ChatRemoteDataSourceImpl implements ChatRemoteDataSource {

    private _remoteDataSource: RemoteStorageDatasource;


    constructor(
        @inject(RemoteStorageDatasource) dataSource: RemoteStorageDatasource,
    ) {
        this._remoteDataSource = dataSource;
    }

    table: string = 'chat';

    querySubs?: string | null | undefined;
    lastQueryId = '';

    usersInfo: Record<string, UserEntity> = {};

    localCache: TicketEntity[] = [];

    waitingReadResponse: boolean = false;

    private get user(): UserEntity {
        return UserEntity.fromJson(JSON.parse(localStorage.getItem(process.env.STORAGE_USER as string)!));
    }

    private setWaitingReadResponse() {
        this.waitingReadResponse = true;
    }

    private releaseWaitingReadResponse() {
        this.waitingReadResponse = false;
    }

    private registerLastTimeSearch = (date: Date, fromSingleTicket?: string | null) => {
        if (fromSingleTicket) {
            localStorage.setItem(fromSingleTicket, (new Date(date.getTime() - 1000)).toISOString());
        } else {
            localStorage.setItem(StorageKeys.lastTimeChatSubscription, (new Date(date.getTime() - 1000)).toISOString());
        }
    }

    private updateUsersInfoCache(data: UserEntity[]): void {
        for (const user of data) {
            this.usersInfo[user.id] = user;
        }
    }

    private resolveListChatResponse(response: AxiosResponse<any, any>, type: TypeListChat): ListPaginationEntity<TicketEntity> {
        const listPaginationChatEntity: ListPaginationChatEntity = response.data;
        const tickets = listPaginationChatEntity.data;
        let data: TicketEntity[] = [];
        if (tickets.length > 0) {
            data = tickets.map((entry: TicketEntity) => TicketEntity.fromJson(entry).copyWith({
                countUnreadNotification: listPaginationChatEntity.unreadMessages?.[type]?.data?.find((ticket) => ticket.idTicket === entry.id)?.total
            }));
            this.updateUsersInfoCache(data.map((ticket) => ticket.usersInfo!).reduce((prev, curr) => [...prev, ...curr]));
        }

        const result = new ListPaginationEntity<TicketEntity>({
            page: listPaginationChatEntity.page,
            limit: ConstantsKeys.defaultLimit,
            totalRows: listPaginationChatEntity.totalRows,
            hasMore: listPaginationChatEntity.page < (listPaginationChatEntity.pages - 1),
            pages: listPaginationChatEntity.pages,
            data,
        });

        this.updateLocalCache(result.data, null, false, false);
        return result;
    }


    public async startConnection(): Promise<ChatIntegrationConnectionEntity> {
        return new Promise<ChatIntegrationConnectionEntity>(async (resolve, reject) => {
            try {
                const response = await api.get<ChatIntegrationConnectionEntity>(`${this.table}/startConnection`);
                return resolve(new ChatIntegrationConnectionEntity({
                    ...response.data
                }));
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public logOut(): Promise<VoidSuccess> {
        return new Promise<VoidSuccess>(async (resolve, reject) => {
            try {
                const response = await api.get<VoidSuccess>(`${this.table}/logout`);
                return resolve(response.data);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public connectionState(): Promise<ChatIntegrationConnectionEntity> {
        return new Promise<ChatIntegrationConnectionEntity>(async (resolve, reject) => {
            try {
                const response = await api.get<ChatIntegrationConnectionEntity>(`${this.table}/connectionState`);
                const chatConnection = new ChatIntegrationConnectionEntity({
                    idCompany: response.data.idCompany,
                    qrCode: response.data.qrCode,
                    status: response.data.status,
                    updatedAt: response.data.updatedAt,
                    createdAt: response.data.createdAt,
                })
                return resolve(chatConnection);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public subscribeConnection(): Observable<ChatIntegrationConnectionEntity> {
        const idCompany = getIdCompanyByLocalStorage();
        const table = 'chat_integration_connection';
        const queryString =
            `subscription ChatConnection {
              ${table}(limit: 1, where: {idCompany: {_eq: "${idCompany}"}}) {
                status
                idCompany
                qrCode
                extraInfo
              }
            }`;
        const query = gql`${queryString}`;

        return new Observable<ChatIntegrationConnectionEntity>((observer: Observer<ChatIntegrationConnectionEntity>) => {
            return apolloClient.subscribe({
                query
            }).subscribe({
                next: (response) => {
                    const connection = response.data[table][0] as ChatIntegrationConnectionEntity | undefined;
                    const chatConnection = new ChatIntegrationConnectionEntity({
                        idCompany: idCompany,
                        qrCode: connection && connection.qrCode ? connection.qrCode : null,
                        status: connection && connection.status ? connection.status : StatusTypes.DISCONNECTED,
                        updatedAt: connection && connection.updatedAt ? connection.updatedAt : undefined,
                        createdAt: connection && connection.createdAt ? connection.createdAt : undefined,
                        extraInfo: connection && connection.extraInfo ? ConnectionExtraInfoEntity.fromJson(connection.extraInfo) : undefined,
                    });
                    observer.next(chatConnection);

                },
                error(err: any) {
                    observer.error(err);
                },
            });
        });
    }

    public listChatsAttending(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>> {
        return new Promise<ListPaginationEntity<TicketEntity>>(async (resolve, reject) => {
            try {
                const response =
                    await api.get(`${this.table}/list?page=${page}&type=myChats&searchParam=${searchParam ?? ''}`);
                return resolve(this.resolveListChatResponse(response, TypeListChat.attending));
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public listChatsWaiting(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>> {
        return new Promise<ListPaginationEntity<TicketEntity>>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.table}/list?page=${page}&type=waiting&searchParam=${searchParam ?? ''}`);
                return resolve(this.resolveListChatResponse(response, TypeListChat.waiting));
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public clearTicketsCache(): void {
        this.updateLocalCache([], null, false, true);
    }

    private setQuerySubs(value: string): void {
        this.querySubs = value;
    }

    private resolveQueryCache(id?: string): void {
        if (id && id != this.lastQueryId) {
            this.querySubs = null;
        }

        if (this.querySubs && !this.querySubs.includes(this.user.idCompany)) {
            this.querySubs = null;
        }


        if (!id && this.lastQueryId) {
            this.querySubs = null;
        }

        this.lastQueryId = id ?? '';
    }

    private getSubscription(id?: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                this.resolveQueryCache(id);
                const idParam = id ? `?id=${id}` : '';
                if (!this.querySubs) {
                    const response = await api.get(`${this.table}/getSubscription${idParam}`);
                    this.setQuerySubs(response.data['data']);
                }
                return resolve(this.querySubs!);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });

    }

    private async getUsersFromServer(ids: string[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const response = await api.get(`users/byIds?ids=${ids.join(',')}`);
                const users = response.data as UserEntity[];
                this.updateUsersInfoCache(users);
                return resolve();
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public async updateUsersCache(users: UserEntity[]): Promise<void> {
        const newUsers: string[] = [];
        for (const user of users) {
            const findId = Object.keys(this.usersInfo).find((item) => item == user.id);
            if (findId) {
                newUsers.push(user.id);
            }
        }
        if (newUsers.length > 0) {
            await this.getUsersFromServer(newUsers);
        }
    }

    public async updateUsersInfoData(tickets: TicketEntity[]): Promise<void> {
        if (tickets.length > 0) {
            const usersId: string[] = Object.keys(tickets.map((ticket) => ticket.users!).reduce((prev, curr) => Object.assign({}, prev, curr)));
            const newUsers: string[] = [];
            for (const userId of usersId) {
                const findId = Object.keys(this.usersInfo).find((item) => item == userId);
                if (!findId && userId) {
                    newUsers.push(userId);
                }
            }

            if (newUsers.length > 0) {
                await this.getUsersFromServer(newUsers);
            }
        }
    }

    public getUsersFromCache(input: Record<string, boolean>): UserEntity[] {
        const keys = Object.keys(input);
        const users: UserEntity[] = [];
        for (const key of keys) {
            users.push(this.usersInfo[key]);
        }
        return users;
    }

    private makeDecodedTickets(): TicketEntity[] {
        return this.localCache.map((entry: TicketEntity) => TicketEntity.fromJson(new TicketEntity({
            ...entry,
            usersInfo: this.getUsersFromCache(entry.users!),
        })));
    }

    private async getTicketsAfterSubs(tickets: ShortTicketEntity[], fromSingleTicket: string | null, fromSubscription: boolean): Promise<TicketEntity[]> {
        await this.processReceivedMessages(tickets, fromSingleTicket, fromSubscription);
        await this.updateUsersInfoData(this.localCache);
        return this.makeDecodedTickets();
    }

    private getLocalCacheDeepCopy(): TicketEntity[] {
        const copy = JSON.parse(JSON.stringify(this.localCache)) as any[];
        return copy.map((entry) => TicketEntity.fromJson(entry));
    }

    private updateLocalCache(list: TicketEntity[], fromSingleTicket: string | null, fromSubscription: boolean, clearIfEmpty = true) {
        if (list.length === 0 && clearIfEmpty) {
            this.localCache = [];
        }
        if (list.length) {
            const deepLocalCacheCopy = this.getLocalCacheDeepCopy();

            for (const item of list) {
                const index = deepLocalCacheCopy.findIndex((ticketCache) => ticketCache.id === item.id);
                if (index > -1) {
                    if (item.hasEligibleChat(this.user.id) || fromSingleTicket || item.lastMessage?.isSystemMessage) {
                        deepLocalCacheCopy[index] = item.copyWith({
                            soundPlayed: this.getSoundPlayed(item, deepLocalCacheCopy[index]),
                            isMeStartingChat: this.getIsMeStartingChat(item, deepLocalCacheCopy[index]),
                            countUnreadNotification: this.getCountUnreadNotification(item, deepLocalCacheCopy[index]),
                        });
                    } else {
                        deepLocalCacheCopy.splice(index, 1);
                    }
                } else {
                    deepLocalCacheCopy.push(
                        fromSubscription ?
                            item.copyWith({
                                countUnreadNotification: this.getCountUnreadNotification(item),
                            })
                            :
                            item
                    );

                }
            }

            this.localCache = deepLocalCacheCopy;
        }
    }

    subscribe(id?: string): Observable<TicketEntity[]> {
        return from(this.getSubscription(id))
            .pipe<TicketEntity[]>(
                mergeMap<string, Observable<TicketEntity[]>>((queryString: string, _) => {
                    return new Observable<TicketEntity[]>((observer: Observer<TicketEntity[]>) => {
                        const query = gql`${queryString}`;
                        return apolloClient.subscribe({
                            query,
                        }).subscribe({
                            next: async (response) => {
                                const recordTickets = ((response.data['tickets']) as Array<any>).map((entry) => ShortTicketEntity.fromJson(entry));
                                const tickets = await this.getTicketsAfterSubs(recordTickets, id ?? null, true);
                                observer.next(tickets);
                            },
                            error(err: any) {
                                observer.error(err);
                            },
                        });
                    });
                })
            );
    }

    public sendMessage(message: TicketMessageEntity, associateSenderToTicket: boolean): Promise<TicketMessageEntity> {
        return new Promise<TicketMessageEntity>(async (resolve, reject) => {
            try {
                message = message.copyWith({
                    pushName: this.user.name
                }).prepareToSend();

                const result = await api.post(`chat/writeMessage?associateSenderToTicket=${associateSenderToTicket}`, message);

                message = message.copyWith({
                    id: result.data.id,
                    createdAt: result.data.createdAt
                });


                return resolve(message);
            } catch (error) {
                if (message.isFileMessage) {
                    const path = message.getPathToUpload;
                    await this._remoteDataSource.removeFile(path);
                }
                return reject(parseServerError(error));
            }
        });
    }

    public subscriptionUploadFile(message: TicketMessageEntity): Observable<TicketMessageEntity> {
        return new Observable<TicketMessageEntity>((observer: Observer<TicketMessageEntity>) => {
            const path = message.getPathToUpload;
            this._remoteDataSource.uploadFileListener(path, message.uploadOptions!).subscribe({
                next: (response) => {
                    if (response.urlDownload) {
                        message.setUrlDownloadInFileMessage(response.urlDownload);
                    }
                    observer.next(message.copyWith({
                        uploadOptions: response,
                    }));
                },
                error: (err: FirebaseStorageError) => {
                    observer.error(err);
                },
                complete: () => {
                    observer.complete();
                }
            });
        })
    }


    public editMessage(idMessage: string, text: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                return resolve(await api.patch(`chat/editMessage?id=${idMessage}`, {text}));
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public delete(id: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await api.delete(`chat/deleteMessage?id=${id}`);
                return resolve();
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public loadMessages(chatId: string, page: number, cancelTokenSource?: CancelTokenSource): Promise<ListPaginationEntity<TicketMessageEntity>> {
        return new Promise<ListPaginationEntity<TicketMessageEntity>>(async (resolve, reject) => {
            try {
                const response = await api.get(`chat/messages?id=${chatId}&page=${page}`, {cancelToken: cancelTokenSource?.token});
                const data: TicketMessageEntity[] = response.data.data.map((entry: TicketMessageEntity) => TicketMessageEntity.fromJson(entry));
                const result = new ListPaginationEntity<TicketMessageEntity>({
                    page: response.data.page,
                    limit: response.data.limit,
                    totalRows: response.data.totalRows,
                    hasMore: response.data.page < (response.data.pages - 1),
                    pages: response.data.pages,
                    data,
                });
                return resolve(result);
            } catch (error) {
                const errorParsed = parseServerError(error);
                if (errorParsed.message === DropDeskErrorEnum.operationCanceledDueToNewRequest) {
                    return;
                }
                return reject(errorParsed);
            }
        });
    }

    public navigateToMessage(id: string, ticketNumber: number): Promise<ListPaginationEntity<TicketMessageEntity>> {
        return new Promise<ListPaginationEntity<TicketMessageEntity>>(async (resolve, reject) => {
            try {

                const response = await api.get(`chat/navigate-to-message?id=${id}&ticketNumber=${ticketNumber}`);
                const data: TicketMessageEntity[] = response.data.data.map((entry: TicketMessageEntity) => TicketMessageEntity.fromJson(entry));
                const result = new ListPaginationEntity<TicketMessageEntity>({
                    page: response.data.page,
                    limit: response.data.limit,
                    totalRows: response.data.totalRows,
                    hasMore: response.data.page < (response.data.pages - 1),
                    pages: response.data.pages,
                    data,
                });
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public listChatMessagesAfterSubscription(chatId: string): Promise<TicketMessageEntity[]> {
        return new Promise<TicketMessageEntity[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`chat/messagesAfter?idTicket=${chatId}`);
                const data: TicketMessageEntity[] = response.data.map((entry: Record<string, any>) => TicketMessageEntity.fromJson(entry));
                return resolve(data);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }


    public informReadMessages(ticketId: string, cancelTokenSource?: CancelTokenSource): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                this.setWaitingReadResponse();
                this.resetCountNotification(ticketId);
                return resolve(await api.patch(`chat/informReadMessages?ticketId=${ticketId}`, {}, {cancelToken: cancelTokenSource?.token}));
            } catch (error) {
                const errorParsed = parseServerError(error);
                if (errorParsed.message === DropDeskErrorEnum.operationCanceledDueToNewRequest) {
                    return;
                }
                return reject(errorParsed);
            }
        });
    }

    public reactMessage(
        messageId: string,
        reactionId: string,
        reaction: string
    ): Promise<VoidSuccess> {
        return new Promise<VoidSuccess>(async (resolve, reject) => {
            try {
                return resolve(await api.post(`chat/reactMessage?id=${messageId}&reactionId=${reactionId}&reaction=${reaction}`));
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    private processReceivedMessages(data: ShortTicketEntity[], fromSingleTicket: string | null, fromSubscription: boolean): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (this.waitingReadResponse) {
                    this.releaseWaitingReadResponse();
                }

                const cacheShortTicket = this.localCache.map(item => new ShortTicketEntity({id: item.id}));
                const shortTickets = [...cacheShortTicket, ...data].reduceRight((acc: ShortTicketEntity[], obj) => {
                    if (!acc.some((ticket) => ticket.id === obj.id)) {
                        acc.unshift(obj);
                    }
                    return acc;
                }, []);
                const result = await api.patch(`chat/processReceivedMessages`, {tickets: shortTickets.map((entry) => entry.toJson())});
                this.registerLastTimeSearch(new Date(result.data['gte'] as string), fromSingleTicket);
                const tickets = (result.data['data'] as Record<string, any>[]).map((json) => TicketEntity.fromJson(json));
                const newTickets = this.mergeNotifications(data, tickets);
                this.addNotificationsDependant(newTickets);
                this.updateLocalCache(newTickets, fromSingleTicket, fromSubscription);
                return resolve();
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    private mergeNotifications(shortTickets: ShortTicketEntity[], tickets: TicketEntity[]): TicketEntity[] {
        const shortTicketsWithNotifications = shortTickets.filter((shortTicket) => shortTicket.notifications);
        shortTicketsWithNotifications.forEach((shortTicket) => {
            const matchingTicket = tickets.find((ticket) => ticket.id === shortTicket.id);
            if (matchingTicket) {
                matchingTicket.notifications = shortTicket.notifications;
            }
        });

        return tickets;
    }

    private addNotificationsDependant(tickets: TicketEntity[]) {
        const oldTickets = this.localCache;
        for (let i = 0; i < tickets.length; i++) {
            const hasNotificationBeforeClose = tickets[i]?.notifications?.find(entry => !!entry.notifiedBeforeClose);
            if (hasNotificationBeforeClose && tickets[i].hasAttendingStatus) {
                const oldTicket = oldTickets.find(oldTicket => oldTicket.id === tickets[i].id);
                const newLastMessage: TicketMessageEntity | undefined = !oldTicket?.lastMessage?.isSystemMessage ? newSystemMessage(tickets[i], hasNotificationBeforeClose, this.user) : oldTicket.lastMessage;
                tickets[i].lastMessage = newLastMessage;
                tickets[i].updatedAt = newLastMessage?.isSystemMessage && hasNotificationBeforeClose.notifiedBeforeClose
                    ?
                    hasNotificationBeforeClose.notifiedBeforeClose
                    :
                    tickets[i].updatedAt;
            }
        }
    }

    private resetCountNotification(ticketId: string): void {
        let index: number | undefined = this.localCache.findIndex(item => ticketId == item.id);
        if (index > -1) {
            this.localCache[index] = this.localCache[index].copyWith({
                countUnreadNotification: 0
            });
        }
    }

    private getSoundPlayed(newTicket: TicketEntity, oldTicket: TicketEntity): boolean {
        return !!newTicket.lastMessage &&
            oldTicket.lastMessage?.id !== newTicket.lastMessage.id &&
            newTicket.hasAttendingStatus &&
            (newTicket.lastMessage.sender?.isUserClient === true || newTicket.lastMessage.isSystemMessage)
    }

    private getIsMeStartingChat(newTicket: TicketEntity, oldTicket: TicketEntity): boolean {
        return (
            (oldTicket.hasOpenStatus && newTicket.hasAttendingStatus) &&
            (Object.keys(newTicket.users).filter((entry) => entry === this.user.id).length > 0)
        )
    }

    private getCountUnreadNotification(newTicket: TicketEntity, ticketCached?: TicketEntity) {
        if (

            (
                (newTicket.lastMessage?.sender?.isUserClient === true && !newTicket.lastMessage?.readers?.[this.user.id])
                ||
                (newTicket.lastMessage?.isSystemMessage)
            )
            &&
            (newTicket.lastMessage?.id !== ticketCached?.lastMessage?.id)
        ) {
            return ((ticketCached?.countUnreadNotification ?? 0) + 1)
        }

        return ticketCached?.countUnreadNotification;
    }
}
