import {ContentConfigureNotification} from "./styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import PopConfirmListChats from "@dropDesk/presentation/pages/chat/ui/header_notification/popconfirm_list_chat";
import {observer} from "mobx-react";

const HeaderNotification = observer((
    {}: {}
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <ContentConfigureNotification background={colors.onBackground} bordercolor={colors.border}>

            <PopConfirmListChats/>

        </ContentConfigureNotification>
    )
});
export default HeaderNotification;
