import {observer} from "mobx-react";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import {
    Container,
    Content,
    ContentDescription,
    Text
} from "@dropDesk/presentation/pages/subscription/ui/modals/reactive_subscription/styled";
import Advantage from "@dropDesk/presentation/pages/subscription/ui/select_plan/confirm_plan/body/advantage";
import Footer from "@dropDesk/presentation/pages/subscription/ui/modals/reactive_subscription/footer";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const ReactiveSubscription = observer(
    (
        {
            loading,
            onRequestClose,
            onRequestSave
        }: {
            loading: boolean
            onRequestClose: () => void
            onRequestSave: () => void
        }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container background={colors.onBackground}>
                <HeaderModal
                    disabled={loading}
                    letter={'Que bom te ver de volta! 😃'}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={onRequestClose}
                    letterColor={colors.text}
                />
                <Content>
                    <Advantage title={'Seus Benefícios de volta:'} background={colors.onBackground}/>

                    <ContentDescription>
                        <Text size={16} color={colors.text}>Queremos aproveitar esta oportunidade para
                            agradecer por sua contínua
                            confiança em nossos serviços. Esperamos continuar a atender às suas necessidades da melhor
                            maneira possível, seu apoio é o que nos impulsiona a oferecer um serviço excepcional.</Text>
                    </ContentDescription>

                </Content>
                <Footer
                    onRequestClose={onRequestClose}
                    loading={loading}
                    onRequestSave={onRequestSave}
                    disabled={loading}
                />
            </Container>
        )
    })
export default ReactiveSubscription;
