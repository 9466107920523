import {
    Container, Image, Content, ContentImage, Title, ContentTitle, ContentDescription, Description, ContentButton,
    ButtonClosed, ContainerButtonClosed, TextClosed
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import blockAccess from "@dropDesk/storage/images/access_blocked.png"
import React from "react";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useNavigate} from "react-router-dom";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const ConnectionsExceeded = observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const navigate = useNavigate();

    return (
        <Container background={colors.onBackground}>
            <ContainerButtonClosed background={colors.onBackground}>
                <ButtonClosed
                    color={colors.text}
                    onClick={() => navigate(RoutesEnum.login, {replace: true})}
                    disabled={false}
                    border={colors.border}
                    accent={colors.accent}
                >
                    <TextClosed>
                        Fechar
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.close}
                            size={13}
                            style={{cursor: 'pointer', marginLeft: 10, marginTop: 2}}
                        />
                    </TextClosed>
                </ButtonClosed>
            </ContainerButtonClosed>
            <Content>
                <ContentTitle>
                    <Title color={colors.text}>Limite de conexões simultâneas excedido</Title>
                </ContentTitle>

                <ContentImage>
                    <Image
                        src={blockAccess}
                        alt={'connection exceeded'}
                    />
                </ContentImage>

                <ContentDescription>
                    <Description color={colors.text} size={19}>
                        Ops! O número máximo de conexões simultâneas foi atingido. Por favor, encerre algumas
                        sessões ou aguarde antes de fazer uma nova tentativa.
                    </Description>
                </ContentDescription>
                <ContentButton>
                    <ButtonNew
                        marginLeft={0}
                        isButtonMain={true}
                        label={'Tentar novamente'}
                        onClick={() => navigate(RoutesEnum.login, {replace: true})}
                        nameLeftIcon={ICONS_DROPDESK.refresh}
                        sizeLeftIcon={16}
                        disabled={false}
                    />
                </ContentButton>
            </Content>
        </Container>
    );
});
export default ConnectionsExceeded;
