import {
    Container, ContainerTime,
    Content,
    ContentText,
    Text, TextItalic
} from "@dropDesk/presentation/components/messages/message_box/system_message/styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";


const SystemMessage = observer((
    {
        message,
        isDarkTheme
    }: {
        message: TicketMessageEntity
        isDarkTheme: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <Content isDarkTheme={isDarkTheme} background={colors.backgroundLeftChatBox}>
                <ContentText>
                    <Text color={colors.text}>{message.getText}</Text>
                </ContentText>
                <ContainerTime>
                    <TextItalic
                        color={colors.hint}>{displayDateToLocale(message.realCreatedAt)}</TextItalic>
                </ContainerTime>
            </Content>
        </Container>
    )
});
export default SystemMessage;
