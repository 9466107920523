import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyConfigurationRepository} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";
import {
  BusinessOperationHoursConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {CompanyConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";

@injectable()
export class SetBusinessOperatorConfigurationsUseCase {
  private _repository: CompanyConfigurationRepository;

  constructor(@inject(CompanyConfigurationRepository) repository: CompanyConfigurationRepository) {
    this._repository = repository;
  }

  public call(configurationBusinessOperator: BusinessOperationHoursConfigurationEntity): Promise<CompanyConfigurationEntity> {
    return this._repository.setConfigurationBusinessOperator(configurationBusinessOperator);
  }
}
