import styled from "styled-components";

type TextTabType = {
    color: string;
}

type ContainerType = {
    background: string;
    collapsedMenu: boolean;
};


export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px);
  flex-direction: column;
  background: ${(props) => props.background};
  width: calc(100vw - ${props => props.collapsedMenu ? `80px` : `250px`});
  padding: 0px 32px;
`;

export const TextTab = styled.span`
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: .7px;
  font-weight: bold;
  height: 50px;
  padding: 0 10px 0 10px;
`;

