import {
    Container,
    Header,
    TextNotification,
    Content,
} from "./styled";

import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import React, {useContext} from "react";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {Tooltip} from "antd";


export default function PanelNoticesNotification(
    {
        loading,
        tableNotices,
        getDataFromPage,
    }: {
        loading: boolean,
        tableNotices: ListPaginationEntity<NoticesEntity>,
        getDataFromPage: (page: number) => void,
    }) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const collapsed = useContext(MenuCollapsedContext);

    const tableColumn = [
        {
            title: 'Descrição',
            dataIndex: 'description',
            ellipsis: false,
            key: 'id',
            render: (text: string, record: NoticesEntity) => (
                <span>{text}</span>
            ),
        },
    ]
    return (
        <Container>
            <Header background={colors.onBackground} borderColor={colors.border}>
                <TextNotification color={colors.text}>Avisos</TextNotification>
            </Header>
            <Content background={colors.onBackground} borderColor={colors.border}>
                <TableGeneric<NoticesEntity>
                    size={'small'}
                    sticky={true}
                    tableColumns={tableColumn}
                    tableDataSource={tableNotices.data}
                    onSelectedRow={(notices: NoticesEntity) => {

                    }}
                    loading={loading}
                    labelNoResults={'Nenhum aviso encontrado'}
                    collapsedMenu={collapsed}
                    defaultCurrentPage={1}
                    totalRows={tableNotices.totalRows}
                    page={tableNotices.page}
                    pageSize={tableNotices.limit}
                    rowSelection={null}
                    onChange={(page) => getDataFromPage(page - 1)}
                    hideColumn={true}
                />
            </Content>
        </Container>
    );
}
