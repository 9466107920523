import "reflect-metadata";
import {inject, injectable} from "inversify";
import {IoRepository} from "@dropDesk/domain/repositories/io/io.repository";

@injectable()
export class CreateObjectUrlBlobUseCase {
  private _repository: IoRepository;

  constructor(@inject(IoRepository) repository: IoRepository) {
    this._repository = repository;
  }

  public call(file: File | Blob):string {
    return this._repository.createObjectURLBlob(file);
  }
}
