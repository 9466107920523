import {PaymentProvider} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {PlanEntity} from "@dropDesk/domain/entities/plan/plan_entity";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {
    PaymentSubscriptionOrigin,
    PaymentSubscriptionStatus
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription.enum";
import {
    PaymentSubscriptionLogsEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_logs/payment_subscription_logs.entity";
import {ValuesButtonType} from "@dropDesk/presentation/components/radio_button";
import {
    createUtcDateFromIsoString,
    formatDateToDDMMYYYY, formatIsoDateToDDMMYYYY, getDateDifferenceInDays,
    getDateWithDropdeskTz, isAfterNow, isAfterOrEqualNow,
    isBeforeNow, isBeforeOrEqualNow, newDateWithOutTimeZone, resetHours
} from "@dropDesk/utils/helpers/date_helper";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export class PaymentSubscriptionEntity {
    id!: string;
    idPlan!: string;
    idCompany!: string;
    origin!: PaymentSubscriptionOrigin;
    status?: PaymentSubscriptionStatus;
    provider!: PaymentProvider;
    lastPaymentDate?: string;
    expirationDate?: string;
    nextDueDate!: string;
    dayDue!: number;
    cancellationReason?: string;
    planUserMultiplier!: number;
    plan!: PlanEntity;
    payments?: PaymentEntity[];
    logs?: PaymentSubscriptionLogsEntity[];
    createdAt?: string;
    deleted?: boolean;
    daysToExpiration!: number;
    company?: CompanyEntity;

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = [
            'daysToExpiration', 'logs', 'payments', 'plan', 'id', 'status',
            'provider', 'lastPaymentDate', 'expirationDate', 'nextDueDate',
            'cancellationReason', 'createdAt', 'deleted', 'idCompany', 'origin',
            'company'
        ];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    public get nextDueDateDDMMYYYY(): string {
        const nextDueDate = resetHours(new Date(this.nextDueDate)).toISOString();
        return formatIsoDateToDDMMYYYY(nextDueDate);
    }

    public get nextChargeDateDDMMYYYY(): string {
        return formatIsoDateToDDMMYYYY(this.nextChargeDateIsoString);
    }

    public get nextChargeDateIsoString(): string {
        const hasPaymentPending = this.isWaitingPayment && !!this.lastPayment && !!this.lastPayment.expireDate;

        const nextDueDate =
            this.expirationDate ?
                resetHours(new Date(this.expirationDate)).toISOString()
                :
                hasPaymentPending
                    ?
                    resetHours(new Date(this.lastPayment!.expireDate!)).toISOString()
                    :
                    resetHours(new Date(this.nextDueDate)).toISOString();
        return nextDueDate;
    }

    public get expirationDateDDMMYYYY(): string | undefined {
        if (this.expirationDate) {
            const expirationDate = resetHours(new Date(this.expirationDate)).toISOString();
            return formatIsoDateToDDMMYYYY(expirationDate);
        }
        return;
    }

    public textDaysRemaining(oldSubs?: PaymentSubscriptionEntity): string {
        if (this.daysToExpireSubs(oldSubs) < 0) {
            return `Sua assinatura está com #pagamento atrasado#, evite o cancelamento dos serviços, por favor verifique.`;
        }
        if (this.isFree) {
            return `Restam #${this.daysToExpireSubs(oldSubs)} DIAS# de teste`;
        }
        return `Restam #${this.daysToExpireSubs(oldSubs)} DIAS# para o vencimento da assinatura`;
    }

    public daysToExpireSubs(oldSubs?: PaymentSubscriptionEntity): number {
        const hasUpgrade = oldSubs?.hasUpgrade(this) ?? false;
        const nextDueDate = !hasUpgrade && this.isWaitingPayment ? this.nextChargeDateIsoString : resetHours(new Date(this.nextDueDate)).toISOString();
        return getDateDifferenceInDays(nextDueDate, resetHours(getDateWithDropdeskTz(new Date())).toISOString());
    }

    public get lastPayment(): PaymentEntity | undefined {
        const payments = this.orderPaymentsByCreatedAt(this.payments);
        return payments ? payments[payments.length - 1] : undefined;
    }

    public get isWaitingCreditCardPayment(): boolean {
        return !!this.lastPayment && this.lastPayment.isCreditCard && this.lastPayment.isPending;
    }

    public get isWaitingCreditCard(): boolean {
        return this.isPending && !!this.lastPayment?.isCreditCard;
    }

    public orderPaymentsByCreatedAt(payments: PaymentEntity[] | undefined): PaymentEntity[] | undefined {
        return payments ? payments.sort((a, b) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime()) : undefined;
    }

    public get totalUsers(): number {
        return this.plan.numberUsers * this.planUserMultiplier;
    }

    public hasUpgrade(other: PaymentSubscriptionEntity): boolean {
        return other.plan.legacy || this.totalValue < other.totalValue || this.dayDue != other.dayDue || this.isExpired();
    }

    public hasDowngrade(other: PaymentSubscriptionEntity): boolean {
        return !this.plan.legacy && (this.totalValue > other.totalValue) && !this.isExpired();
    }

    public get isFree(): boolean {
        return this.plan.trial === true;
    }

    public textDowngrade(oldSubs: PaymentSubscriptionEntity): string {
        return `Deseja realmente fazer o downgrade do seu plano para ${this.planName}? Ao fazer o downgrade
        permanecerá ativo o plano ${oldSubs.planName} até dia ${oldSubs.nextDueDateDDMMYYYY}, após isso
        entrará em vigor o downgrade e sua quantidade de usuário será reduzida
         de ${oldSubs.quantityOfUsers} para ${this.quantityOfUsers}.`;
    }

    get planName() {
        if (this.plan.value === 0 && !this.plan.legacy) {
            return `Plano Teste ${this.planUserMultiplier} atendentes`
        }
        if (this.plan.legacy) {
            return `Plano Mensal DropDesk ${this.plan.numberUsers} atendentes`
        }
        return `Plano Mensal DropDesk ${this.planUserMultiplier} atendentes`
    }

    public planValue(oldSubs?: PaymentSubscriptionEntity) {
        const hasUpgrade = oldSubs?.hasUpgrade(this) ?? false;
        if (hasUpgrade && this.lastPayment && this.lastPayment.isPending) {
            return this.lastPayment.value;
        }
        if (this.plan.legacy) {
            return this.plan.value;
        }
        return this.planUserMultiplier * this.plan.value;
    }

    public get labelFirstPayment(): string {
        return this.isFree ? 'Total 1ª Parcela' : 'Valor do ajuste';
    }

    public get quantityOfUsers(): number {
        return this.plan.legacy ? this.plan.numberUsers : this.planUserMultiplier;
    }

    static daysValidForDue(mode?: string): Array<number> {
        return mode === 'uat' || mode === 'development' ?
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
            :
            [5, 10, 15, 20, 25];
    }

    public get isFreeActiveSubscription(): boolean {
        return this.plan.isFreePlan && this.status === PaymentSubscriptionStatus.active;
    }

    public get isPremiumActiveSubscription(): boolean {
        return !this.plan.isFreePlan && this.status === PaymentSubscriptionStatus.active;
    }

    public get isPending(): boolean {
        return this.status === PaymentSubscriptionStatus.waitingPayment;
    }

    public get isCancelled(): boolean {
        return this.status === PaymentSubscriptionStatus.canceled;
    }

    public get isEligiblePayment(): boolean {
        return (
            (this.lastPayment &&
                (this.lastPayment.isPending && !this.lastPayment.isCreditCard)
                ||
                (this.lastPayment && this.lastPayment.isReproved)
            )
            ||
            this.isFree
        );
    }

    public get isOverdue(): boolean {
        if (this.nextDueDate) {
            return isBeforeNow(new Date(this.nextDueDate), false);
        }
        return false;
    }

    public isExpired(): boolean {
        return this.status === PaymentSubscriptionStatus.canceled && !!this.expirationDate && isBeforeNow(new Date(this.expirationDate), false);
    }

    public isCanceledScheduled(currentUser?: UserEntity | null): boolean {
        if (this.expirationDate && this.status === PaymentSubscriptionStatus.canceled && currentUser) {
            return isAfterOrEqualNow(new Date(this.expirationDate));
        }
        return false;
    }

    public get isWaitingPayment(): boolean {
        return this.status === PaymentSubscriptionStatus.waitingPayment;
    }

    public get totalValue(): number {
        if (this.plan.legacy) {
            return this.plan.total;
        }
        return this.planUserMultiplier * this.plan.value;
    }

    public static defaultCancellationReasons(): Array<ValuesButtonType> {
        return [
            {key: 'Encontrei outro produto que me atende', value: 'Encontrei outro produto que me atende'},
            {key: 'Preciso cortar custos', value: 'Preciso cortar custos'},
            {key: 'Não estou usando o produto', value: 'Não estou usando o produto'},
            {key: 'Não consigo usar ou ver vantagem no produto', value: 'Não consigo usar ou ver vantagem no produto'},
            {key: 'Outro', value: 'Outro'},
        ];
    }

    constructor({
                    id,
                    idCompany,
                    idPlan,
                    origin,
                    status,
                    provider,
                    nextDueDate,
                    dayDue,
                    daysToExpiration,
                    company,
                    planUserMultiplier,
                    lastPaymentDate,
                    expirationDate,
                    cancellationReason,
                    payments,
                    logs,
                    plan,
                    createdAt,
                    deleted
                }: {
        id: string;
        idPlan: string;
        idCompany?: string;
        origin: PaymentSubscriptionOrigin;
        status?: PaymentSubscriptionStatus;
        provider: PaymentProvider;
        lastPaymentDate?: string;
        expirationDate?: string;
        nextDueDate: string;
        dayDue: number;
        daysToExpiration: number;
        planUserMultiplier: number;
        cancellationReason?: string;
        payments?: PaymentEntity[];
        logs?: PaymentSubscriptionLogsEntity[];
        plan?: PlanEntity;
        company?: CompanyEntity;
        createdAt?: string;
        deleted?: boolean;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            idPlan,
            origin,
            status,
            provider,
            dayDue,
            daysToExpiration,
            company,
            planUserMultiplier,
            nextDueDate,
            lastPaymentDate,
            expirationDate,
            cancellationReason,
            payments,
            logs,
            plan,
            createdAt,
            deleted
        });
    }

    public get total(): number {
        return this.plan.total * this.planUserMultiplier;
    }

    static fromJson(json: Record<string, any>): PaymentSubscriptionEntity {
        return new PaymentSubscriptionEntity({
            id: json['id'],
            idCompany: json['idCompany'],
            dayDue: json['dayDue'],
            daysToExpiration: json['daysToExpiration'],
            company: json['company'],
            deleted: json['deleted'],
            idPlan: json['idPlan'],
            origin: json['origin'],
            planUserMultiplier: json['planUserMultiplier'],
            provider: json['provider'],
            status: json['status'],
            cancellationReason: json['cancellationReason'],
            nextDueDate: json['nextDueDate'],
            lastPaymentDate: json['lastPaymentDate'],
            expirationDate: json['expirationDate'],
            logs: json['logs']
                ? (json['logs'] as any[]).map((entry) =>
                    PaymentSubscriptionLogsEntity.fromJson(entry),
                )
                : [],
            plan: json['plan'] ? PlanEntity.fromJson(json['plan']) : undefined,
            payments: json['payments']
                ? (json['payments'] as any[]).map((entry) =>
                    PaymentEntity.fromJson(entry),
                )
                : [],
            createdAt: json['createdAt'],
        });
    }

    copyWith({
                 id,
                 idCompany,
                 idPlan,
                 origin,
                 status,
                 provider,
                 dayDue,
                 daysToExpiration,
                 company,
                 planUserMultiplier,
                 nextDueDate,
                 lastPaymentDate,
                 expirationDate,
                 cancellationReason,
                 payments,
                 logs,
                 plan,
                 createdAt,
                 deleted
             }: {
        id?: string;
        idPlan?: string;
        idCompany?: string;
        origin?: PaymentSubscriptionOrigin;
        status?: PaymentSubscriptionStatus;
        provider?: PaymentProvider;
        lastPaymentDate?: string;
        expirationDate?: string;
        nextDueDate?: string;
        dayDue?: number;
        daysToExpiration?: number;
        planUserMultiplier?: number;
        cancellationReason?: string;
        payments?: PaymentEntity[];
        logs?: PaymentSubscriptionLogsEntity[];
        plan?: PlanEntity
        createdAt?: string;
        deleted?: boolean;
        company?: CompanyEntity;

    }): PaymentSubscriptionEntity {
        return new PaymentSubscriptionEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            idPlan: idPlan ?? this.idPlan,
            origin: origin ?? this.origin,
            status: status ?? this.status,
            provider: provider ?? this.provider,
            dayDue: dayDue ?? this.dayDue,
            daysToExpiration: daysToExpiration ?? this.daysToExpiration,
            company: company ?? this.company,
            planUserMultiplier: planUserMultiplier ?? this.planUserMultiplier,
            nextDueDate: nextDueDate ?? this.nextDueDate,
            lastPaymentDate: lastPaymentDate ?? this.lastPaymentDate,
            expirationDate: expirationDate ?? this.expirationDate,
            cancellationReason: cancellationReason ?? this.cancellationReason,
            payments: payments && payments.length > 0 ? payments : this.payments,
            logs: logs && logs.length > 0 ? logs : this.logs,
            plan: plan ?? this.plan,
            createdAt: createdAt ?? this.createdAt,
            deleted: deleted ?? this.deleted,
        })
    }
}

