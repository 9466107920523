import {observer} from "mobx-react";
import React from "react";
import ModalUndoImports from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {useModalImportClients} from "@dropDesk/presentation/pages/client/ui/import/search/modals/visible_modal_search";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useNavigate} from "react-router-dom";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const ModalsRevertImportClient = observer(
    ({
         loading,
         revertImport
     }: {
        loading: boolean,
        revertImport: (id: string) => Promise<void>
    }) => {

        const {
            setVisibleModalUndoImport, visibleModalUndoImport
        } = useBetween(useModalImportClients);

        return (
            <>

                <ModalUndoImports
                    open={visibleModalUndoImport.visible}
                    onRequestClose={() => {
                        setVisibleModalUndoImport({visible: false, id: ''});
                    }}
                    textHeaderModal={'Desfazer importação'}
                    textButton={'Desfazer'}
                    onRequestSave={() => {
                        revertImport(visibleModalUndoImport.id);
                        setVisibleModalUndoImport({visible: false, id: ''});
                    }}
                    nameIcon={ICONS_DROPDESK.warning}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente desfazer este importação? ao desfazer esta importação, as empresas/contatos importados serão excluídos
             definitivamente, exceto a empresa que já fez uma abertura de chamado.`
                    }
                    isActionDelete={true}
                    loading={loading}
                />
            </>
        )
    })
export default ModalsRevertImportClient;
