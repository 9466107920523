import styled from "styled-components";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";

type TextType = {
    color: string;
}

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // margin: 0 0 5px 0;
`;

export const TextHeader = styled.span<TextType>`
  font-size: ${`${ConstantsStyles.titleLargeFontSize}px`};
  font-weight: bold;
  color: ${(props) => props.color};
`;
export const SubText = styled.span<TextType>`
  font-size: 16px;
  color: ${(props) => props.color};
`;
