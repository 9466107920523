import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";

export type ContainerType = {
    isActive: boolean;
    isDarkTheme: boolean;
    borderColor: string;
    accent: string;
}
export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  height: 200px;
  width: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${props => props.isActive ? '1' : '.5'};

`;

export const ContentImage = styled.div<ContainerType>`
  display: flex;
  height: 100%;
  width: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${props => props.isActive ? 'none' : `1px solid ${props.borderColor}`};
  border-radius: ${`${ConstantsStyles.radiusButton}px`};
  box-shadow: ${props => props.isActive ? `0 0 0 2px ${hexToRGBA(props.accent, .58)}` : 'none'};
  outline: none;
  transition: ${props => props.isActive ? 'box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out' : 'auto'};
`;


export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  margin-top: 10px;
`;

export const Icon = styled.i<TextType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
  cursor: pointer;
`;
