export enum CompanyOrigin {
  ads = 'Anúncio',
  organic = 'Orgânica'
}

export enum CompanySegment {
  agencyMarketing = "Agência de Marketing e Publicidade",
  autoParts = "Auto peças",
  commercialAutomation = "Automação Comercial",
  consumerProductionAssets = "Bens de Consumo e Produção",
  consultingProfessionalServices = "Consultoria e Serviços Profissionais",
  alarmCentral = "Central de Alarme",
  education = "Educação",
  energy = "Energia",
  pharmaceuticalsAndBiotechnology = "Farmacêuticos, Biotecnologia e Ciências da Vida",
  financial = "Financeiro",
  hardware = "Hardware",
  realEstate = "Imobiliárias",
  industry = "Indústria",
  machineMaintenance = "Manuntenção de Maquinás",
  electronicMonitoring = "Monitoramento Eletrônico",
  HumanResources = "Recursos Humanos",
  health = "Saúde",
  insurance = "Seguros",
  miscellaneousServices = "Serviços Diversos",
  softwareAndCloud = "Software & Cloud",
  supermarkets = "Supermercados",
  informationTechnology = "Tecnologia da Informação (TI)",
  telecommunications = "Telecomunicações",
  transportLogistics = "Transportes e Logística",
  textile = "Têxtil",
  publicUtilities = "Utilidades Públicas",
  retail = "Varejo",
  clothing = "Vestuário",
  others = "Outros",
}
export enum CompanyRegion {
AC = "AC",
AL = "AL",
AP = "AP",
AM = "AM",
BA = "BA",
CE = "CE",
DF = "DF",
ES = "ES",
GO = "GO",
MA = "MA",
MT = "MT",
MS = "MS",
MG = "MG",
PA = "PA",
PB = "PB",
PR = "PR",
PE = "PE",
PI = "PI",
RJ = "RJ",
RN = "RN",
RS = "RS",
RO = "RO",
RR = "RR",
SC = "SC",
SP = "SP",
SE = "SE",
TO = "TO"
}
