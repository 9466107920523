import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SectorRepository} from "@dropDesk/domain/repositories/sector/sector.repository";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";

@injectable()
export class SetSectorUseCase {
  private _repository: SectorRepository;

  constructor(@inject(SectorRepository) repository: SectorRepository) {
    this._repository = repository;
  }

  public call(sector: SectorEntity): Promise<SectorEntity> {
    return this._repository.set(sector);
  }
}

