import styled from "styled-components";

export const ContentExportImport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContentFilter = styled.div`
  display: flex;
  height: 61px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  position: relative;
  margin-top: 1px;
`;


export const ContentInput = styled.div`
  display: flex;
  width: 350px;
`;

export const ContentInputAndListableSectorDeleted = styled.div`
  display: flex;
  flex-direction: row;
`;
