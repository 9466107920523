import styled from "styled-components";

type TextType = {
    color: string;
    size: number;
}

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
  letter-spacing: .8px;
`;

export const ContainerHeader = styled.div`
  padding: 8px 16px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;
