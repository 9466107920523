import {observer} from "mobx-react";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {Tooltip} from "antd";
import {ContainerAction} from "./styled";
import ButtonActionTable from "@dropDesk/presentation/components/buttons/button_action_table";
import {useBetween} from "use-between";
import {useModalClients} from "@dropDesk/presentation/pages/client/ui/my_clients/search/modals/visible_modal_search";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const TableClients = observer(
    ({
         searchParam,
         loading,
         tableClients,
         getDataFromPage,
         setRowSelectionClients,
         rowSelectionClients,
         heightGarbage,
         permissionDeleteData,
         permitSelectionRow
     }: {
        searchParam: string
        loading: boolean,
        tableClients: ListPaginationEntity<ClientEntity>,
        getDataFromPage: (page: number) => void,
        setRowSelectionClients: (client: ClientEntity[]) => void,
        rowSelectionClients: ClientEntity[],
        permissionDeleteData: boolean,
        permitSelectionRow: boolean,
        heightGarbage: number
    }) => {

        const navigate = useNavigate();
        const collapsed = useContext(MenuCollapsedContext);

        const {
            setVisibleModalDelete,
            setVisibleModalRestore
        } = useBetween(useModalClients);

        const setRestore = (client: ClientEntity) => {
            setRowSelectionClients([client]);
            setVisibleModalRestore(true);
        }

        const tableColumn = [
            {
                title: 'Nome',
                dataIndex: 'url',
                key: 'url',
                width: 58,
                render: (text: string, record: ClientEntity) => (
                    <AvatarImage
                        useCursorPointer={record.urlImageProfile ? true : false}
                        title={record.socialName}
                        size={35}
                        url={record.urlImageProfile}
                        round={true}
                        hasPreviewImage={true}
                        withBorder={true}
                        onClick={(event) => event.stopPropagation()}
                    />
                ),
            },
            {
                title: '',
                ellipsis: true,
                dataIndex: 'socialName',
                key: 'id',
                render: (text: string, record: ClientEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text ?? ''}
                    />
                ),
            },
            {
                title: 'Nome Fantasia',
                ellipsis: true,
                dataIndex: 'name',
                key: 'id',
                render: (text: string, record: ClientEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text ?? ''}
                    />
                ),
            },
            {
                title: 'CNPJ/CPF',
                ellipsis: true,
                dataIndex: 'document',
                key: 'id',
                render: (text: string, record: ClientEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text ?? ''}
                    />
                ),
            },
            {
                title: 'Telefone',
                ellipsis: true,
                dataIndex: 'telephone',
                key: 'id',
                render: (text: string, record: ClientEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text ?? ''}
                    />
                ),
            },

            {
                title: 'Criado em',
                ellipsis: true,
                dataIndex: 'createdAt',
                key: 'id',
                render: (text: string, record: ClientEntity) => (

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: 155,
                        maxWidth: 155,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        {displayDateToLocale(text)}
                        <Tooltip title={`${record.getOrigin}`}>
                            <span>
                                 <DropDeskIcon
                                     icon={ICONS_DROPDESK.info}
                                     size={14}
                                     style={{cursor: 'pointer'}}
                                     useDarkenInHover={true}
                                 />
                             </span>
                        </Tooltip>
                    </div>
                ),
            },

            {
                title: '',
                key: 'action',
                width: permissionDeleteData ? 65 : 40,
                render: (text: string, record: ClientEntity) => (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <ContainerAction>
                            {!record.deleted && permissionDeleteData &&
                                <Tooltip placement="left" title={'Excluir Empresa'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRowSelectionClients([record]);
                                        setVisibleModalDelete(true);
                                    }}
                                    nameIcon={ICONS_DROPDESK.delete}
                                    isActionDelete={true}
                                /> </Tooltip>}
                            {record.deleted &&
                                <Tooltip placement="left" title={'Restaurar Empresa'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRestore(record);
                                    }}
                                    nameIcon={ICONS_DROPDESK.restore}
                                /> </Tooltip>

                            }
                        </ContainerAction>
                    </div>
                ),
            },
        ];
        const rowSelection = {
            selectedRowKeys: rowSelectionClients.map((client) => client.id),
            onChange: (selectedRowKeys: React.Key[], selectedRows: ClientEntity[]) => {
                setRowSelectionClients(selectedRows)
            },
            getCheckboxProps: (record: ClientEntity) => ({
                disabled: false,
            }),
            preserveSelectedRowKeys: true
        };


        return (
            <TableGeneric<ClientEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableClients.data}
                onSelectedRow={(client: ClientEntity) => {
                    if (!client.deleted) {
                        navigate(`${RoutesEnum.clients}/${client.id}`);
                    } else {
                        setRestore(client);
                    }
                }}
                loading={loading}
                labelNoResults={'Nenhuma empresa encontrada na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableClients.totalRows}
                rowSelection={permitSelectionRow ? rowSelection : null}
                page={tableClients.page}
                pageSize={tableClients.limit}
                onChange={(page) => getDataFromPage(page - 1)}
                extraLayout={heightGarbage}
            />
        )
    })
export default TableClients;
