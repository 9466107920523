import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CNPJRepository} from "@dropDesk/domain/repositories/common/cnpj/cnpj.repository";
import {CNPJResponseEntity} from "@dropDesk/domain/entities/common/cnpj_response.entity";

@injectable()
export class SearchCNPJUseCase {
  private _repository: CNPJRepository;

  constructor(@inject(CNPJRepository) repository: CNPJRepository) {
    this._repository = repository;
  }

  public call(cnpj: string): Promise<CNPJResponseEntity> {
    return this._repository.searchCNPJ(cnpj);
  }
}

