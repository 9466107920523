import {
    LineChartReportTicketAggregate
} from "@dropDesk/domain/entities/reports/report_ticket_aggregate/line_chart_report_ticket_agreggate";

export class ReportTicketAggregateEntity {
    lineChartClientsAggregate !: LineChartReportTicketAggregate[];
    lineChartSectorsAggregate!: LineChartReportTicketAggregate[];
    lineChartUsersAggregate!: LineChartReportTicketAggregate[];
    lineChartDescriptionsStaticAggregate!: LineChartReportTicketAggregate[];
    timeFirstResponseAggregate!: number;
    averageRating!: number;

    static fromJson(json: Record<string, any>): ReportTicketAggregateEntity {
        return new ReportTicketAggregateEntity({
            lineChartClientsAggregate: json['lineChartClientsAggregate'].map((entry: any) => LineChartReportTicketAggregate.fromJson({
                ...entry,
            })),
            lineChartSectorsAggregate: json['lineChartSectorsAggregate'].map((entry: any) => LineChartReportTicketAggregate.fromJson({
                ...entry,
            })),
            lineChartUsersAggregate: json['lineChartUsersAggregate'].map((entry: any) => LineChartReportTicketAggregate.fromJson({
                ...entry,
            })),
            lineChartDescriptionsStaticAggregate: json['lineChartDescriptionsStaticAggregate'].map((entry: any) => LineChartReportTicketAggregate.fromJson({
                ...entry,
            })),
            timeFirstResponseAggregate: json['timeFirstResponseAggregate'] as number,
            averageRating: json['averageRating'] as number,
        })
    }

    constructor({
                    lineChartClientsAggregate,
                    lineChartSectorsAggregate,
                    lineChartUsersAggregate,
                    lineChartDescriptionsStaticAggregate,
                    timeFirstResponseAggregate,
                    averageRating
                }: {
        lineChartClientsAggregate: LineChartReportTicketAggregate[];
        lineChartSectorsAggregate: LineChartReportTicketAggregate[];
        lineChartUsersAggregate: LineChartReportTicketAggregate[];
        lineChartDescriptionsStaticAggregate: LineChartReportTicketAggregate[];
        timeFirstResponseAggregate: number;
        averageRating: number;
    }) {
        Object.assign(this, {
            lineChartClientsAggregate,
            lineChartSectorsAggregate,
            lineChartUsersAggregate,
            lineChartDescriptionsStaticAggregate,
            timeFirstResponseAggregate,
            averageRating
        });
    }
}

