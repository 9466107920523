import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {
    GetReportTicketRateUseCase
} from "@dropDesk/domain/use_case/reports/report_ticket_rate/get_report_ticket_rate.usecase";
import {
    ReportRateValueEntity,
    ReportTicketRateEntity
} from "@dropDesk/domain/entities/reports/report_ticket_rate/report_ticket_rate.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {DropDeskError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {getEndHourCurrentDate, getInitialHourCurrentDate} from "@dropDesk/utils/helpers/date_helper";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {
    getOnlyLabelFromStorage, getPeriodAndLabelDisplay,
    initializeConfigurationLocalStorage,
    PeriodEnum, setGenericFiltersLocalStorage,
    setPeriodAndLabelDisplay
} from "@dropDesk/utils/helpers/common";

configure({
    enforceActions: "always",
});

@injectable()
export class ReportTicketRateController {
    private readonly _getReportTicketRate: GetReportTicketRateUseCase;

    constructor(
        @inject(GetReportTicketRateUseCase) getReportTicketRate: GetReportTicketRateUseCase,
    ) {
        makeObservable(this);
        this._getReportTicketRate = getReportTicketRate;
    }

    @observable
    average: number = 0;

    @observable
    totalCountValue: number = 0;

    @observable
    oneRating: ReportRateValueEntity | undefined;

    @observable
    twoRating: ReportRateValueEntity | undefined;

    @observable
    threeRating: ReportRateValueEntity | undefined;

    @observable
    fourRating: ReportRateValueEntity | undefined;

    @observable
    fiveRating: ReportRateValueEntity | undefined;

    @observable
    user: UserEntity | null = null;

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    period: Array<Date> | null = getPeriodAndLabelDisplay().range;

    @observable
    labelPeriod: PeriodEnum = getPeriodAndLabelDisplay().label;

    @action
    setPeriod(period: Array<Date> | null, label: PeriodEnum) {
        this.period = period;
        this.labelPeriod = label;
        setPeriodAndLabelDisplay({
            range: period!,
            label: label
        });
    }

    @action
    setUser(user: UserEntity | null) {
        setGenericFiltersLocalStorage({reportTicketRateFilterUser: user});
        this.user = user;
    }


    @action
    removeFilter() {
        this.setUser(null);
    }


    @action
    setAverage(average: number) {
        this.average = average;
    }

    @action
    setTotalCountValue(totalCountValue: number) {
        this.totalCountValue = totalCountValue;
    }

    @action
    setOneRating(oneRating: ReportRateValueEntity) {
        this.oneRating = oneRating;
    }

    @action
    setTwoRating(twoRating: ReportRateValueEntity) {
        this.twoRating = twoRating;
    }

    @action
    setThreeRating(threeRating: ReportRateValueEntity) {
        this.threeRating = threeRating;
    }

    @action
    setFourRating(fourRating: ReportRateValueEntity) {
        this.fourRating = fourRating;
    }

    @action
    setFiveRating(fiveRating: ReportRateValueEntity) {
        this.fiveRating = fiveRating;
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    initialize() {
        const routeName = RoutesEnum.reporttickets;
        const {filters} = initializeConfigurationLocalStorage(routeName, false);
        this.user = filters.reportTicketRateFilterUser!;
        this.getReportTicketRate();
    }

    @action
    setAllDataReportTicketRate(
        average: number, totalCountValue: number,
        oneRating: ReportRateValueEntity, twoRating: ReportRateValueEntity, threeRating: ReportRateValueEntity,
        fourRating: ReportRateValueEntity, fiveRating: ReportRateValueEntity,
    ) {
        this.setAverage(average);
        this.setTotalCountValue(totalCountValue);
        this.setOneRating(oneRating);
        this.setTwoRating(twoRating);
        this.setThreeRating(threeRating);
        this.setFourRating(fourRating);
        this.setFiveRating(fiveRating);
    }

    @action
    getReportTicketRate = async (): Promise<void> => {
        try {
            if (this.period && this.period[0] && this.period[1]) {
                this.startLoading("report_ticket_rate.loading");
                const response = await this._getReportTicketRate.call(
                    {
                        startDate: getInitialHourCurrentDate(this.period[0]),
                        endDate: getEndHourCurrentDate(this.period[1])
                    },
                    this.user?.id ?? null,
                );
                this.setAllDataReportTicketRate(
                    response.average, response.totalCountValue,
                    response.oneRating, response.twoRating, response.threeRating,
                    response.fourRating, response.fiveRating
                )
                this.stopLoading();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

}
