export class PortalClientConfigurationEntity {
    useChatOnline!: boolean;

    constructor({
                    useChatOnline,
                }: {
        useChatOnline: boolean;
    }) {
        Object.assign(this, {
            useChatOnline,
        });
    }

    copyWith({
                 useChatOnline,
             }: {
        useChatOnline?: boolean;
    }): PortalClientConfigurationEntity {
        return new PortalClientConfigurationEntity({
            useChatOnline: useChatOnline ?? this.useChatOnline,
        })
    }
}
