import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";

@injectable()
export abstract class TicketsDescriptionStaticRepository {

    public abstract list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean, listableForClients?: boolean): Promise<ListPaginationEntity<TicketsDescriptionStaticEntity>>;

    public abstract set(ticketsDescriptionStatic: TicketsDescriptionStaticEntity): Promise<TicketsDescriptionStaticEntity>;

    public abstract restore(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]): Promise<void>;

    public abstract delete(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]): Promise<void>;

    public abstract findByPK(id: string): Promise<TicketsDescriptionStaticEntity>;
}
