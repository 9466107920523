import {observer} from "mobx-react";
import React from "react";
import {useNavigate} from "react-router-dom";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";


const HeaderImport = observer(
    ({
         loading
     }: {
        loading: boolean
    }) => {

        const navigate = useNavigate();

        return (
            <HeaderForm
                letter={"Importações de empresas e contatos"}
                onRequestClose={() => navigate(-1)}
                disabled={loading}
            />
        )
    })
export default HeaderImport;
