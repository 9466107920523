import styled, {keyframes} from 'styled-components';

type ContainerType = {
  background: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
  overflow: auto;
  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
  }
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background};
`;

export const ContainerChangePassword = styled.form<ContainerType>`
  
  max-height: 90%;
  width: 80%;
  max-width: 512px;
  flex-direction: column;
  display: flex;
  background: ${(props) => props.background};
  border-radius: 10px;
  padding: 16px;
`;
export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Section = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  background: ${(props) => props.background};
  overflow: auto;
  flex-direction: column;
`;
