import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";

export const newResponseStandard = () => new ResponseStandardEntity({
  id: '',
  idCompany: getIdCompanyByLocalStorage(),
  description: '',
  title: '',
  deleted: false,
});
