import {useEffect, useState} from "react";
import {TokenizeCardEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {validateCreditCardNumber} from "@dropDesk/utils/helpers/card_helper";
import {isValidExpirationDate, isValidName} from "@dropDesk/utils/helpers/validators";

export const useValidatorInputCreditCard = (
        newTokenizeCard: TokenizeCardEntity | null
    ) => {

        const [errorMessageNumber, setErrorMessageNumber] = useState('');
        const [errorMessageExpirationDate, setErrorMessageExpirationDate] = useState('');
        const [errorMessageCVV, setErrorMessageCVV] = useState('');
        const [errorMessageHolderName, setErrorMessageHolderName] = useState('');
        const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);


        useEffect(() => {
            validate();
        }, [newTokenizeCard]);

        const validate = () => {
            setErrorMessageNumber('');
            setErrorMessageExpirationDate('');
            setErrorMessageCVV('');
            setErrorMessageHolderName('');

            if (!newTokenizeCard) {
                setIsValidDataForm(false);
                return;
            }

            if (newTokenizeCard.number.trim().length === 0 || !validateCreditCardNumber(newTokenizeCard.number).isValid) {
                setErrorMessageNumber('Número do cartão inválido');
                setIsValidDataForm(false);
            } else if (!isValidName(newTokenizeCard.holderName)) {
                setErrorMessageHolderName('Nome inválido');
                setIsValidDataForm(false);
            } else if (!isValidExpirationDate(newTokenizeCard.expirationDate)) {
                setErrorMessageExpirationDate('Data de válidade inválido');
                setIsValidDataForm(false);
            } else if (newTokenizeCard.cvv.length <= 2) {
                setErrorMessageCVV('Código inválido');
                setIsValidDataForm(false);
            } else {
                setIsValidDataForm(true);
            }
        }

        return {
            errorMessageNumber, setErrorMessageNumber,
            errorMessageExpirationDate, setErrorMessageExpirationDate,
            errorMessageCVV, setErrorMessageCVV,
            errorMessageHolderName, setErrorMessageHolderName,
            validate, isValidDataForm
        }
    }
;
