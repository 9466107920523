import styled from "styled-components";
import {ButtonType, ContainerType} from "@dropDesk/presentation/components/footer/footer_modal/footer_modal";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export const Container = styled.div<ContainerType>`
  display: flex;
  padding: 16px;
  align-items: center;
  flex-direction: row;
  background-color: ${(props) => props.background};
  border-radius: 0px 0px 5px 5px;
`;
export const Button = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: ${`${ConstantsStyles.buttonSaveFontSize}px`};
  font-weight: bold;
  min-width: 110px;
  padding: 0 6px;
  height: 35px;
  min-height: 35px;
  border-radius: 5px;
  letter-spacing: 1px;
  border: 0;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const ButtonBack = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: ${`${ConstantsStyles.buttonSaveFontSize}px`};
  font-weight: bold;
  width: 110px;
  height: 35px;
  min-height: 35px;
  border-radius: 5px;
  letter-spacing: 1px;
  margin-left: 16px;
  border: ${(props) => `1px solid ${props.border}`};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

`;
