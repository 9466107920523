import {Button, Container, ButtonBack} from "./styled";
import {FooterModalProps} from "@dropDesk/presentation/components/footer/footer_modal/footer_modal";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {Text} from "@dropDesk/presentation/components/footer/footer_form/styled";
import {Popover} from "antd";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


export default function FooterModal(props: FooterModalProps) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const background = props.isActionDelete ? colors.errorOnPrimary : props.backgroundButton ?? colors.accent;

    const buttonOK = <Button
        disabled={props.disabled}
        background={background}
        type={props.type}
        color={SharedColors.white}
        border={colors.border}
        shadow={background}
        onClick={props.disabled ? () => {
        } : props.onSubmit}>
        {!props.loading && props.letter}
        {props.loading &&
            <SpinLoading
                color={SharedColors.white}
                size={"small"}
            />}
    </Button>;

    return (
        <Container borderColor={colors.border} background={props.background ?? colors.onBackground}>
            <div style={{display: 'flex', flexDirection: 'row'}}>

                {props.contentPopover && <Popover
                    placement="top"
                    trigger="click"
                    color={colors.backgroundInput}
                    title={''}
                    style={{padding: 0}}
                    content={props.contentPopover}>
                    {buttonOK}
                </Popover>}

                {!props.contentPopover && buttonOK}

                {!props.hideBackButton &&

                    <ButtonBack
                        disabled={false}
                        background={props.backgroundBackButton ?? colors.onBackground}
                        type={props.type}
                        color={colors.text}
                        border={colors.border}
                        shadow={colors.accent}
                        onClick={props.onRequestClose}>
                        {props.letterButtonBack ?? 'Voltar'}
                    </ButtonBack>}

            </div>


            {!!props.useCheckBox &&
                <CheckBoxWithLabel
                    label={props.labelCheckBox ?? ''}
                    checked={props.checkedCheckBox ?? false}
                    disabled={props.disabledCheckBox ?? false}
                    marginLeft={16}
                    onChange={props.onChangeCheckBox ? props.onChangeCheckBox : () => {
                    }}
                />
            }

            {props.createdAt &&
                <Text style={{marginLeft: 16}} color={colors.text}>{props.createdAt}</Text>
            }
        </Container>
    );
}
