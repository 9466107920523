import "reflect-metadata";
import {inject, injectable} from "inversify";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {NoticesRepository} from "@dropDesk/domain/repositories/notices/notices.repository";

@injectable()
export class DeleteNoticesUseCase {
  private _repository: NoticesRepository;

  constructor(@inject(NoticesRepository) repository: NoticesRepository) {
    this._repository = repository;
  }

  public call(notices: NoticesEntity[]): Promise<void> {
    return this._repository.delete(notices);
  }
}

