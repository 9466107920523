import React from 'react';
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ProgressBarStyled} from "@dropDesk/presentation/components/loadings/progress_bar/styled";


export const Progress = (
    {
        progress,
        width,
        type,
        height,
        size,
        color
    }: {
        progress: number,
        width: number,
        type: 'circle' | 'line',
        height?: number,
        size?: 'default' | 'small',
        color?: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ProgressBarStyled
            color={color ?? colors.text}
            percent={progress}
            size={size ?? "default"}
            status={'active'}
            type={type}
            width={width}
            strokeColor={colors.accent}
            trailColor={colors.hint}
            style={{maxWidth: width, width: width, height: height, marginRight: 2}}/>
    );

};
