import styled from "styled-components";

type ContainerPaymentMethodType = {
    border: string;
}

export const ContainerPaymentMethod = styled.div<ContainerPaymentMethodType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 16px 0 0 0;
  border: 6px solid ${props => props.border};
  border-radius: 2px
`;
