import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {Badge, Popover} from "antd";

type ContainerType = {
    position: 'left' | 'right';
    background: string;
    isDarkTheme: boolean;
    spacePosition: number;
    hint: string;
}


type ContainerReactionsType = {
    isDarkTheme: boolean;
    hint: string;
}

type TextType = {
    color: string;
}

type PopoverType = {
    position: 'left' | 'right';
    marginSize: number;
}

export const Container = styled.div`
  margin: -5px 0 0 0;
  padding: 0;
  position: relative;
  display: inline-block;
  max-width: 125px;
  display: flex;
`;

export const Content = styled.div<ContainerType>`
  border-radius: 25px;
  padding: 1px 5px;
  background: ${props => props.background};
  position: relative;
  flex-direction: row-reverse;
  box-shadow: ${props => props.isDarkTheme ? `0px 1px 2px 0px ${hexToRGBA(props.hint, 1)}` : `0px 1px 2px 0px ${hexToRGBA(SharedColors.black, 1)}`};
  cursor: pointer;
  transition: all 0.3s ease;
  top: -4.6px;
  bottom: 0;
  visibility: visible;
  margin: auto;
  left: ${props => props.position === 'left' ? 'auto' : `-${props.spacePosition}px`};
  right: ${props => props.position === 'left' ? `-${props.spacePosition}px` : 'auto'};
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &:hover {
    filter: brightness(80%);
  }
`;

export const PopOver = styled(Popover)<PopoverType>`
  .ant-popover-inner {
    margin-right: ${props => props.position === 'left' ? 'auto' : `${props.marginSize}px`};
    margin-left: ${props => props.position === 'left' ? `${props.marginSize}px` : 'auto'};
    border-radius: 8px;
    padding: 0px !important;
  }

  .ant-popover-inner-content {
    padding: 0px !important;
  }
`;

export const Reaction = styled.span`
  margin-right: 4px;
`;

export const TextReaction = styled.span<TextType>`
  font-size: 14px;
  color: ${props => props.color};
  font-weight: bold;
`;

export const ContainerReactions = styled.div<ContainerReactionsType>`
  margin: 0;
  padding: 0;
  height: 200px;
  width: 275px;
  box-shadow: ${props => props.isDarkTheme ? `0px 1px 2px 0px ${hexToRGBA(props.hint, 1)}` : `none`};
  border-radius: 8px;
`;

export const TextTab = styled.span`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 10px 0 10px;
`;
export const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 155px;
  max-height: 155px;
  overflow: auto;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  //border: none;
  //background: transparent;
  //align-items: center;
  //justify-content: center;
`;
export const ContentImageProfile = styled.div`
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: center;
`;

export const ContentName = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const ContentIcon = styled.div`
  display: flex;
  flex-direction: column;
  width: 40px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const TextRemoveReaction = styled.span<TextType>`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: ${props => props.color};
  text-align: left;
`;
