import "reflect-metadata";
import {injectable} from "inversify";
import {Observable, Observer} from "rxjs";
import {gql} from "@apollo/client";
import {apolloClient} from "@dropDesk/data/clients/apollo.client";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";

@injectable()
export abstract class ExportRemoteDataSource {
    public abstract exportSubscription(table: string): Observable<ExportDataEntity>;
}

@injectable()
export class ExportRemoteDataSourceImpl implements ExportRemoteDataSource {

    constructor() {
    }

    public exportSubscription(table: string): Observable<ExportDataEntity> {

        const idCompany = getIdCompanyByLocalStorage();
        const tableName = 'export_data_status';

        const queryString = `subscription ExportSubscription {
                         ${tableName}(
                         limit: 1, where: {idCompany: {_eq: "${idCompany}"},table: {_eq: "${table}"}}, 
                         order_by: {createdAt: desc}
                         ) {
                          status
                          progress
                          createdAt
                          id
                          idCompany
                          url
                          table
                          extras
                      }
                    }`;

        const query = gql`${queryString}`;

        return new Observable<ExportDataEntity>((observer: Observer<ExportDataEntity>) => {
            return apolloClient.subscribe({query}).subscribe({
                next: (response) => {

                    const exports = (response.data![tableName] as ExportDataEntity[])[0] as ExportDataEntity | undefined;
                    observer.next(new ExportDataEntity({
                        id: exports ? exports.id : '',
                        idCompany: exports ? exports.idCompany : '',
                        table: exports ? exports.table : '',
                        url: exports ? exports.url : '',
                        status: exports ? exports.status : StatusImport.done,
                        progress: exports ? parseFloat((exports.progress * 100).toFixed(2)) : 0,
                        rows: exports ? exports.rows : 0,
                        createdAt: exports ? exports.createdAt : '',
                        extras: exports && exports.extras ? exports.extras : undefined,
                        responseStatus: 200
                    }));

                },
                error(err: any) {
                    observer.error(err);
                }
            })
        });
    }
}
