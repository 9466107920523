import {
    CardEntity,
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {
    ContentButtonPay,
    ContainerCreditCardSaved, ContentInfoCard, ContentInfoHolder,
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Text, TextBold} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/styled";
import ButtonPay
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/credit_card_saved/button_pay";

const CreditCardSaved = (
    {
        cardSaved,
        loading,
        onClickPayCreditCardSaved,
        isEditExternal,
        isReprovedPayment
    }: {
        cardSaved: CardEntity
        loading: boolean
        onClickPayCreditCardSaved: () => void
        isEditExternal?: boolean
        isReprovedPayment?: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerCreditCardSaved bordercolor={colors.text}>
            <ContentInfoCard>
                <TextBold size={15} color={colors.text}>
                    Dados do cartão salvo:
                </TextBold>
                <ContentInfoHolder>
                    <TextBold size={14} color={colors.text}>
                        Número do cartão:
                    </TextBold>
                    <Text size={14}
                          color={colors.text}>&nbsp;{cardSaved.displayNumber}</Text>
                    <TextBold size={14} color={colors.text}>
                        &nbsp;&nbsp;&nbsp;Vencimento:
                    </TextBold>
                    <Text size={14} color={colors.text}>&nbsp;{cardSaved.expirationDate}</Text>

                    <TextBold size={14} color={colors.text}>
                        &nbsp;&nbsp;&nbsp;Bandeira:
                    </TextBold>
                    <Text size={14}
                          color={colors.text}>&nbsp;{cardSaved.brand?.toUpperCase() ?? ''}</Text>
                </ContentInfoHolder>
            </ContentInfoCard>
            {/*{(!isEditExternal || (isEditExternal && isReprovedPayment)) &&*/}
            {!isEditExternal &&
                <ContentButtonPay>
                    <ButtonPay
                        loading={loading}
                        onClickPayCreditCardSaved={onClickPayCreditCardSaved}
                    />
                </ContentButtonPay>}
        </ContainerCreditCardSaved>
    );
}
export default CreditCardSaved;
