import {notification} from 'antd';
import {getColors} from "@dropDesk/utils/toast_message/get_colors";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import './style.css';

notification.config({
    placement: 'top',
    top: 25,
    duration: 5,
    rtl: false,
});

const error = (text: string) => {
    const {theme, background, isDarkTheme} = getColors();
    const colorText = '#FF4D4F';
    notification['error']({
        message: <span style={{fontSize: 17, color: colorText}}>Erro</span>,
        description: text,
        style: {
            background: theme.colorScheme.onBackground,
            border: `1px solid ${theme.colorScheme.border}`,
            borderRadius: 5,
            color: theme.colorScheme.text,
        },
        closeIcon: <i className={`icon-${ICONS_DROPDESK.close}`} style={{fontSize: 14, color: theme.colorScheme.text}}/>
    });
}

const success = (text: string) => {
    const {theme, background, isDarkTheme} = getColors();
    const colorText = '#52C41A';
    notification['success']({
        message: <span style={{fontSize: 17, color: colorText}}>Sucesso</span>,
        description: text,
        style: {
            background: theme.colorScheme.onBackground,
            border: `1px solid ${theme.colorScheme.border}`,
            borderRadius: 5,
            color: theme.colorScheme.text,
        },
        closeIcon: <i className={`icon-${ICONS_DROPDESK.close}`} style={{fontSize: 14, color: theme.colorScheme.text}}/>

    });
}


const info = (text: string) => {
    const {theme, background, isDarkTheme} = getColors();
    const colorText = '#1890FF';
    notification['info']({
        message: <span style={{fontSize: 17, color: colorText}}>Aviso</span>,
        description: text,
        style: {
            background: theme.colorScheme.onBackground,
            border: `1px solid ${theme.colorScheme.border}`,
            borderRadius: 5,
            color: theme.colorScheme.text,
        },
        closeIcon: <i className={`icon-${ICONS_DROPDESK.close}`} style={{fontSize: 14, color: theme.colorScheme.text}}/>

    });
}

const warning = (text: string) => {
    const {theme, background, isDarkTheme} = getColors();
    const colorText = '#FAAD14';
    notification['warning']({
        message: <span style={{fontSize: 17, color: colorText}}>Aviso</span>,
        description: text,
        style: {
            background: theme.colorScheme.onBackground,
            border: `1px solid ${theme.colorScheme.border}`,
            borderRadius: 5,
            color: theme.colorScheme.text,
        },
        closeIcon: <i className={`icon-${ICONS_DROPDESK.close}`} style={{fontSize: 14, color: theme.colorScheme.text}}/>

    });
}


export default {error, success, info, warning};
