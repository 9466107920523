import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {
    useModalsReportTicketRate
} from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui/modals/handle_change_visible";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import ModalSearchUser from "@dropDesk/presentation/components/modals/modal_search_user";


const ModalsReportTicketRate = observer(
    ({
         onSelectUser,
         getReportTicketRate,
     }: {
         onSelectUser: (user: UserEntity) => void,
         getReportTicketRate: () => void,

     }
    ) => {

        const {
            visibleModalSearchUser, setVisibleModalSearchUser,
        } = useBetween(useModalsReportTicketRate);


        return (
            <>

                {visibleModalSearchUser && <ModalSearchUser
                    visible={visibleModalSearchUser}
                    onClose={() => setVisibleModalSearchUser(false)}
                    role={UserRole.attendant}
                    handleSelectUser={async (user) => {
                        onSelectUser(user);
                        getReportTicketRate();
                        setVisibleModalSearchUser(false);
                    }}
                    textHeaderModal={"Pesquisa de Atendentes"}
                    labelNoResults={"Nenhum usuário encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por atendentes"}
                />}

            </>
        )
    })
export default ModalsReportTicketRate;
