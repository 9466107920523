import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {

    ContentButton
} from "../styled";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import React from "react";
import {observer} from "mobx-react";
import {Tooltip} from "antd";


const Footer = observer((
    {

        disabled,
        loading,
        onClickConfirmPlan,
        hasDowngrade,
        disabledText
    }: {
        disabled: boolean;
        loading: boolean;
        hasDowngrade: boolean;
        onClickConfirmPlan: () => void
        disabledText: string;
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Tooltip title={disabledText}>
            <ContentButton>
                <ButtonMain
                    style={{paddingLeft: 5, paddingRight: 5}}
                    widthPercentage={100}
                    letterFontSize={16}
                    disabled={disabled}
                    loading={loading}
                    color={colors.accent}
                    type={"submit"}
                    letter={hasDowngrade ? 'Reduzir plano' : 'Avançar'}
                    onClick={() => onClickConfirmPlan()}
                />
            </ContentButton>
        </Tooltip>
    );
});
export default Footer;
