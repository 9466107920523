import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";

export class CNPJResponseEntity {
  name!: string;
  socialName!: string;
  friendlyName?: string;
  doc!: string;
  email?: string;
  phone?: string;
  address?: AddressEntity;

  constructor({
                name,
                socialName,
                friendlyName,
                doc,
                email,
                phone,
                address,
              }: {
    name: string;
    socialName: string;
    friendlyName?: string;
    doc: string;
    email?: string;
    phone?: string;
    address?: AddressEntity;
  }) {
    Object.assign(this, {
      name,
      socialName,
      doc,
      friendlyName,
      email,
      phone,
      address,
    });
  }

  copyWith({
             name,
             socialName,
             doc,
             friendlyName,
             email,
             phone,
             address,
           }: {
    name?: string;
    socialName?: string;
    friendlyName?: string;
    doc?: string;
    email?: string;
    phone?: string;
    address?: AddressEntity;
  }): CNPJResponseEntity {
    return new CNPJResponseEntity({
      name: name ?? this.name,
      socialName: socialName ?? this.socialName,
      friendlyName: friendlyName ?? this.friendlyName,
      doc: doc ?? this.doc,
      email: email ?? this.email,
      phone: phone ?? this.phone,
      address: address ?? this.address,
    })
  }

}
