import {PaymentProvider} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {PlanPeriodDisplay} from "@dropDesk/domain/entities/plan/plan_enum";

export class ProviderPlan {
    id!: string;
    provider!: PaymentProvider;
}


export class PlanEntity {
    id!: string;

    name!: string;

    period!: number;

    periodDisplay?: PlanPeriodDisplay;

    value!: number;

    numberUsers!: number;

    legacy!: boolean;

    trial?: boolean;

    createdAt?: string;

    public get total(): number {
        return this.legacy ? this.value : this.numberUsers * this.value;
    }

    static fromJson(json: Record<string, any>): PlanEntity {
        return new PlanEntity({
            id: json['id'],
            name: json['name'],
            numberUsers: json['numberUsers'],
            period: json['period'],
            periodDisplay: json['periodDisplay'],
            value: json['value'],
            legacy: json['legacy'],
            trial: json['trial'],
            createdAt: json['createdAt'],
        });
    }

    static freePlan(): PlanEntity {
        return new PlanEntity({
            id: '',
            name: 'Plano Teste 50 atendentes',
            numberUsers: 50,
            period: 7,
            periodDisplay: PlanPeriodDisplay.monthly,
            value: 0,
            legacy: false,
            trial: true
        });
    }

    public get isFreePlan(): boolean {
        return this.value === 0;
    }

    constructor({
                    id,
                    name,
                    period,
                    periodDisplay,
                    value,
                    numberUsers,
                    legacy,
                    trial,
                    createdAt
                }: {
        id?: string;
        name: string;
        period: number;
        periodDisplay: PlanPeriodDisplay;
        value: number;
        numberUsers: number;
        legacy: boolean;
        trial?: boolean;
        createdAt?: string;
    }) {
        Object.assign(this, {
            id,
            name,
            period,
            periodDisplay,
            value,
            numberUsers,
            legacy,
            trial,
            createdAt
        });
    }
}
