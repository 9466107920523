import React from "react";
import CheckBoxPrivateComment from "@dropDesk/presentation/components/check_box/check_box_private_comment";
import {observer} from "mobx-react";

const PrivateComment = observer((
    {
        messageIsPrivate,
        handleAllChanges,
        canSendMessage
    }: {
        messageIsPrivate: boolean
        canSendMessage: boolean
        handleAllChanges: (event: { target: { name: string; value: any; checked: any; type: string } }) => void,
    }) => {
    return (

        <CheckBoxPrivateComment
            checked={messageIsPrivate}
            disabled={!canSendMessage}
            onChange={(event) => {
                handleAllChanges({
                    target: {
                        name: 'isPrivate',
                        value: event.target.value,
                        checked: event.target.checked,
                        type: 'switch'
                    }
                });
            }}
        />
    )
});
export default PrivateComment;
