import React from "react";
import {vcardParser, vcardType} from "@dropDesk/utils/helpers/vcard_parser";
import {
    Container,
    Content, Divider, TextBold,
    TextContact
} from "@dropDesk/presentation/components/messages/message_box/vcard_message/styled";
import {ContactMessageEntity} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {observer} from "mobx-react";


const VcardMessage = observer((
    {
        contactMessage,
        color,
        hint
    }: {
        contactMessage?: ContactMessageEntity[] | null
        color: string;
        hint: string
    }
) => {

    const cards: vcardType[] | undefined = contactMessage?.map((entry) => vcardParser(entry.vcard));

    return (
        <Container>
            <Content>
                {cards?.map((card, index) => {
                    return <div key={index} style={{display: 'flex', flexDirection: 'column'}}>
                        {index > 0 && <Divider color={hint}/>}
                        {card.fn && <TextBold color={color}>{card.fn}</TextBold>}
                        {card.tel && card.tel.map((item) => item.value.map((value, index) => {
                            return <TextContact color={color} key={index}>{value}</TextContact>
                        }))}
                    </div>
                })}

            </Content>
        </Container>
    )
});
export default VcardMessage;
