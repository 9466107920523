import {useState} from "react";

export const useModalNotices = () => {

  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const [visibleModalRestore, setVisibleModalRestore] = useState<boolean>(false);


  return {
    visibleModalRestore,
    setVisibleModalRestore,
    visibleModalDelete,
    setVisibleModalDelete,
  }
}
