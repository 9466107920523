import styled from "styled-components";

type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => `${props.size}px`};
  color: ${props => props.color};
  width: 100%;
`;

export const ContainerQrCodeText = styled.div`
  display: flex;
  padding: 16px 0;
`;
