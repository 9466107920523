import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";

@injectable()
export class StartConnectionChatsUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(): Promise<ChatIntegrationConnectionEntity> {
        return this._repository.startConnection();
    }
}

