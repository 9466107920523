import styled from "styled-components";

export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
export const ContentCards = styled.div`
  display: flex;
  width: 350px;
  justify-content: space-between;
  align-items: center;
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;
