import {useState} from "react";

export const useModalSearchSector = () => {

  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const [visibleModalRestore, setVisibleModalRestore] = useState<boolean>(false);
  const [visibleModalExport, setVisibleModalExport] = useState<boolean>(false);

  return {
    visibleModalDelete, setVisibleModalDelete,
    visibleModalRestore, setVisibleModalRestore,
    visibleModalExport, setVisibleModalExport
  }
}
