import styled from "styled-components";

type IconType = {
    color: string;
    fontsize: number;
}

export type TextType = {
    color: string;
    size: number;
}

export const ContentBack = styled.div`
  cursor: pointer;
  width: max-content;
  margin-right: 5px;

  &:hover {
    filter: brightness(80%);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  cursor: pointer;
`;

export const ContentPlan = styled.div`
  display: flex;
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  cursor: pointer;
`;

export const TextBack = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
  letter-spacing: .8px;
  margin-left: 5px;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const TextPlan = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
  letter-spacing: .8px;
`;
