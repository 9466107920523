import "reflect-metadata";
import {inject, injectable} from "inversify";
import {AuthRepository} from "@dropDesk/domain/repositories/auth/auth.repository";


@injectable()
export class LogoutByAdminUseCase {
  private _repository: AuthRepository;

  constructor(
    @inject(AuthRepository) repository: AuthRepository
  ) {
    this._repository = repository;
  }

  public call(idUser: string): Promise<Record<string, boolean>> {
    return this._repository.logoutByAdmin(idUser);
  }
}
