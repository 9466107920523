import {observer} from "mobx-react";
import {
    Container,
    ContentInput,
    ContentInputAndCommentPrivate,
} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from "react";
import RightButtons from "@dropDesk/presentation/components/chat_view/chat_message/input_message/right_buttons";
import LeftButtons from "@dropDesk/presentation/components/chat_view/chat_message/input_message/left_buttons";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import InputChat from "@dropDesk/presentation/components/inputs/input_chat";
import MessageReplyInfo
    from "@dropDesk/presentation/components/chat_view/chat_message/input_message/message_reply_info";
import PrivateComment from "@dropDesk/presentation/components/chat_view/chat_message/input_message/private_comment";
import {dataTransferIsFile} from "@dropDesk/utils/helpers/validators";
import {newMessage} from "@dropDesk/presentation/pages/chat/controller/new_message";
import {
    MessageChatType,
    ReplyEntity,
    TextMessageEntity
} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {AudioRecordEntity, AudioRecordStatus} from "@dropDesk/domain/entities/common/audio_record.entity";
import {getMessageDraftCacheById, setDraftMessageCache} from "@dropDesk/utils/helpers/common";
import {IoController} from "@dropDesk/presentation/pages/io/io.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const InputMessage = observer(forwardRef(
    ({
         onSendMessage,
         canSendMessage,
         userLogged,
         handlePickFile,
         sendAudio,
         moveScrollToBottom,
         idCurrentTicket
     }: {
         onSendMessage: (message: TicketMessageEntity) => Promise<void>,
         canSendMessage: boolean,
         userLogged: UserEntity
         handlePickFile: (files: File[]) => void,
         sendAudio: (message: TicketMessageEntity, audioRecorder: AudioRecordEntity) => void
         moveScrollToBottom: () => void
         idCurrentTicket: string
     },
     ref: any
    ) => {

        const [message, setMessage] = useState<TicketMessageEntity>(newMessage());
        const [previousIdTicket, setPreviousIdTicket] = useState<string | null>(null);
        const ioController = useInjection(IoController);
        const visibleInput = message.type !== MessageChatType.audioMessage;

        useLayoutEffect(() => {
            restoreCacheValues(idCurrentTicket);
        }, [idCurrentTicket]);

        useEffect(() => {
            setInputFocus();
        }, [message.reply]);

        const setInputFocus = () => {
            inputRef?.current?.focus();
        }

        useImperativeHandle(ref, () => ({
            handleAllChangesImperative(replyMessage: ReplyEntity) {
                handleAllChanges({target: {name: 'reply', value: replyMessage, checked: false, type: 'input'}});
            },
            getCurrentMessage() {
                const _message = message;
                resetMessage();
                moveScrollToBottom();
                return _message;
            }
        }));

        const inputRef = useRef<HTMLInputElement>(null);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const [visibleModalResponseStandard, setVisibleModalResponseStandard] = useState(false);

        const handleAllChanges = (event: { target: { name: string; value: any; checked: any; type: string } }) => {
            let field = event.target.name;
            let value = event.target.value;
            if (field === 'textMessage') {
                value = new TextMessageEntity({
                    conversation: event.target.value
                });
            }

            setMessage(message.copyWith({
                [field]: (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : value,
                replaceReplyIfNull: event.target.name === "reply"
            }));
        };

        const handleSelectEmoji = (emoji: string): void => {
            const newDescription = message.getText + emoji;
            handleAllChanges({target: {name: 'textMessage', value: newDescription, checked: false, type: 'text'}});
        }

        const verifyIfOpenModalResponseRapid = (value: string) => {
            if (value === "/" && value.length === 1) {
                setVisibleModalResponseStandard(true);
            }
        }

        const onChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleAllChanges({target: {name: 'textMessage', value: event.target.value, checked: false, type: 'text'}});
            verifyIfOpenModalResponseRapid(event.target.value);
        }

        const removeReplyingMessage = () => {
            handleAllChanges({target: {name: 'reply', value: null, checked: false, type: 'input'}});
        }

        const resetMessage = () => {
            setMessage(newMessage());
        }

        const handleSendAudio = (_message: TicketMessageEntity, audioRecorder: AudioRecordEntity) => {
            _message.reply = message.reply;
            resetMessage();
            sendAudio(_message, audioRecorder);
            moveScrollToBottom();
        }

        const handleSendMessage = (message: TicketMessageEntity) => {
            resetMessage();
            onSendMessage(message);
            moveScrollToBottom();
        }

        const onKeyDown = (event: React.KeyboardEvent<Element>) => {
            const enterPressed = event.key === "Enter";
            const escPressed = event.key === "Escape";
            const shiftPressed = event.shiftKey;

            if (enterPressed && !shiftPressed) {
                event.preventDefault();
                if (message.getText) {
                    handleSendMessage(message);
                }
            }

            if (escPressed && message.reply) {
                event.preventDefault();
                removeReplyingMessage();
            }
        }

        const handlePasteEvent = (event: ClipboardEvent) => {
            const files = event.clipboardData?.items;
            if (files && dataTransferIsFile(files)) {
                event.preventDefault();
            }
        }

        const restoreCacheValues = async (idCurrentTicket: string) => {
            moveScrollToBottom();
            const draftMessage = getMessageDraftCacheById(idCurrentTicket);
            setMessage(draftMessage?.message ? draftMessage.message : newMessage());
            if (ioController.audioRecorder.status === AudioRecordStatus.recording) {
                await ioController.stopRecordVoice(true)
            }
            const audioCache = ioController.audioRecorder.toCache();
            ioController.setAudioRecorder(draftMessage?.audio ? AudioRecordEntity.fromCache(draftMessage.audio) : ioController.newAudioRecorder);
            setInputFocus();
            if (previousIdTicket) {
                setDraftMessageCache(previousIdTicket, message, audioCache);
            }
            setPreviousIdTicket(idCurrentTicket);
        }
        const handleOnCloseModalResponseStandard = () => {
            setInputFocus();
        }
        return (
            <ContentInputAndCommentPrivate>
                <Container
                    background={colors.backgroundInputChatBox}
                    isUserClient={appController.isUserClient}
                    border={colors.border}
                    shadow={SharedColors.black}
                >
                    <ContentInput>
                        {message.reply &&
                            <MessageReplyInfo
                                messageReply={message.reply}
                                idUserLogged={userLogged.id}
                                removeReplyingMessage={removeReplyingMessage}
                            />
                        }
                        <InputChat
                            autoFocus={true}
                            onChange={(event) => onChangeMessage(event)}
                            value={message.getText}
                            minHeight={40}
                            maxHeight={400}
                            placeholder={"Digite sua mensagem aqui"}
                            reference={inputRef}
                            onKeyDown={(event) => onKeyDown(event)}
                            onPaste={(event) => handlePasteEvent(event)}
                            inputStyle={{display: visibleInput ? 'flex' : 'none'}}
                            visibleInput={visibleInput}
                            rightButtons={
                                <RightButtons
                                    message={message}
                                    onSendMessage={handleSendMessage}
                                    visibleModalResponseStandard={visibleModalResponseStandard}
                                    setVisibleModalResponseStandard={setVisibleModalResponseStandard}
                                    handleAllChanges={handleAllChanges}
                                    sendAudio={handleSendAudio}
                                    ioController={ioController}
                                    handleOnCloseModalResponseStandard={handleOnCloseModalResponseStandard}
                                />
                            }
                            leftButtons={
                                <LeftButtons
                                    handleSelectEmoji={handleSelectEmoji}
                                    handlePickFile={handlePickFile}
                                    visible={visibleInput}/>
                            }
                        />

                    </ContentInput>
                </Container>
                {!appController.isUserClient && <PrivateComment
                    messageIsPrivate={message.isPrivate}
                    handleAllChanges={handleAllChanges}
                    canSendMessage={canSendMessage}
                />}
            </ContentInputAndCommentPrivate>
        )
    }));
export default InputMessage;
