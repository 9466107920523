import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Label} from "@dropDesk/presentation/components/inputs/input_with_border/label/styled";

const LabelInput = (
    {
        label,
        required
    }: {
        label: string,
        required?: boolean
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Label color={colors.hintInverse}>
            {label} {required ?
            <span style={{color: colors.hintInverse, fontSize: 14, marginLeft: 1}}>*</span> : null}</Label>
    )
}
export default LabelInput;
