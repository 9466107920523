import {observer} from "mobx-react";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContentDescriptions, ContentTitle,
    TextBold, TextDescription
} from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription/styled";

const Descriptions = observer(
    (
        {}: {}) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <ContentDescriptions>
                <ContentTitle>
                    <TextBold size={15} color={colors.text}>Lembre-se que ao cancelar voce deixa de
                        ter
                        acesso a:
                    </TextBold>
                </ContentTitle>
                <ul style={{marginLeft: 10}}>
                    <TextDescription size={15} color={colors.text}>Base de atendimentos e
                        relatórios</TextDescription>
                    <TextDescription size={15} color={colors.text}>Base de contatos
                        cadastrados</TextDescription>
                    <TextDescription size={15} color={colors.text}>Base de atendentes
                        cadastrados</TextDescription>
                    <TextDescription size={15} color={colors.text}>E todas as demais informacoes da
                        sua conta.</TextDescription>
                </ul>
            </ContentDescriptions>
        )
    })
export default Descriptions;
