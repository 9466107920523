import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";

@injectable()
export class DownloadFileExampleClientUseCase {
    private _repository: ClientRepository;

    constructor(@inject(ClientRepository) repository: ClientRepository) {
        this._repository = repository;
    }

    public call(): Promise<void> {
        return this._repository.downloadFileExample();
    }
}

