import {Body, Container, Content} from "./styled";
import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import React, {useEffect} from "react";
import ResumeValue from "@dropDesk/presentation/pages/subscription/ui/checkout/resume_value";
import InputsPaymentInfo from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info";
import PaymentType from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type";
import {
    CardEntity,
    TokenizeCardEntity,
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {TabsPayment} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {observer} from "mobx-react";
import {
    PaymentSubscriptionSimulateEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_simulate.entity";


const Checkout = observer((
    {
        setActiveRoute,
        paymentInfo,
        newPaymentInfo,
        initializePaymentInfo,
        onUpdate,
        loading,
        onSearchCNPJ,
        onSearchZipCode,
        onClickSavePaymentInfo,
        valuePlanOnDemand,
        isEditPaymentInfo,
        handleEditPaymentInfo,
        newTokenizeCard,
        onUpdateTokenizeCard,
        pay,
        setActiveTabPayment,
        cardSaved,
        onClickPayCreditCardSaved,
        newSubscription,
        totalFirstPayment,
        totalOthersPayment,
        activeTabPayment,
        makePaymentMethod,
        resetPaymentMethod,
        labelFirstPayment,
        simulate,
        oldSubs
    }: {
        setActiveRoute: (route: RoutesSubscription) => void
        paymentInfo?: CompanyPaymentInfo
        initializePaymentInfo: () => void
        newPaymentInfo: CompanyPaymentInfo
        onUpdate: (paymentInfo: CompanyPaymentInfo) => void
        loading: boolean
        onSearchCNPJ: (document: string) => void,
        onSearchZipCode: (zipCode: string) => void,
        onClickSavePaymentInfo: () => void
        valuePlanOnDemand?: number;
        isEditPaymentInfo: { visible: boolean, canReturnPaymentType: boolean }
        handleEditPaymentInfo: (value?: boolean) => void
        newTokenizeCard: TokenizeCardEntity | null
        onUpdateTokenizeCard: (newTokenizeCard: TokenizeCardEntity) => void
        pay: () => void
        setActiveTabPayment: (value: TabsPayment) => void
        cardSaved?: CardEntity
        onClickPayCreditCardSaved: () => void
        newSubscription: PaymentSubscriptionEntity
        oldSubs: PaymentSubscriptionEntity
        totalFirstPayment: number
        totalOthersPayment: number
        activeTabPayment: TabsPayment | null,
        makePaymentMethod: () => void
        resetPaymentMethod: () => void
        labelFirstPayment: string
        simulate?: PaymentSubscriptionSimulateEntity
    }
) => {

    const showSelectPaymentMethod = !!paymentInfo && !isEditPaymentInfo.visible;
    const showSelectPaymentInfo = !paymentInfo || isEditPaymentInfo.visible;

    useEffect(() => {
        initializePaymentInfo();
    }, []);

    return (
        <Container>
            <Content>
                <Body>
                    {showSelectPaymentMethod &&
                        <PaymentType
                            setActiveRoute={setActiveRoute}
                            paymentInfo={paymentInfo}
                            handleEditPaymentInfo={handleEditPaymentInfo}
                            newTokenizeCard={newTokenizeCard}
                            onUpdateTokenizeCard={onUpdateTokenizeCard}
                            loading={loading}
                            pay={pay}
                            setActiveTabPayment={setActiveTabPayment}
                            cardSaved={cardSaved}
                            onClickPayCreditCardSaved={onClickPayCreditCardSaved}
                            activeTabPayment={activeTabPayment}
                            makePaymentMethod={makePaymentMethod}
                            resetPaymentMethod={resetPaymentMethod}
                        />}
                    {showSelectPaymentInfo &&
                        <InputsPaymentInfo
                            newPaymentInfo={newPaymentInfo}
                            loading={loading}
                            onUpdate={onUpdate}
                            onSearchCNPJ={onSearchCNPJ}
                            onSearchZipCode={onSearchZipCode}
                            onClickSavePaymentInfo={onClickSavePaymentInfo}
                            setActiveRoute={setActiveRoute}
                            canReturnPaymentType={isEditPaymentInfo.canReturnPaymentType}
                            handleEditPaymentInfo={handleEditPaymentInfo}
                        />
                    }
                </Body>
                <ResumeValue
                    valuePlanOnDemand={valuePlanOnDemand}
                    newSubscription={newSubscription}
                    totalFirstPayment={totalFirstPayment}
                    totalOthersPayment={totalOthersPayment}
                    labelFirstPayment={labelFirstPayment}
                    showSelectPaymentMethod={showSelectPaymentMethod}
                    simulate={simulate}
                    oldSubs={oldSubs}
                />
            </Content>
        </Container>
    );
});
export default Checkout;
