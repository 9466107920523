import {InputSearch} from "./styled";
import React, {useEffect, useRef, useState} from "react";
import {useDebouncedCallback} from "use-debounce";
import {InputSearchProps} from "@dropDesk/presentation/components/inputs/input_search/input_search";
import {useLocation} from "react-router-dom";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {InputRef} from "antd";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {getActiveRouteLocalStorage, getSearchParamLocalStorage} from "@dropDesk/utils/helpers/common";

export default function InputSearchGeneric(props: InputSearchProps) {
    const location = useLocation();
    const activeRoute = getActiveRouteLocalStorage();
    const initialSearchParam = (location.pathname.includes(activeRoute) || activeRoute === props.routeName)
        ?
        getSearchParamLocalStorage()
        :
        '';
    const [value, setValue] = useState(props.defaultValue ?? initialSearchParam);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const ref = useRef<InputRef | null>(null);
    useEffect(() => {
        if (ref.current && props.autoFocus) {
            ref.current?.focus();
        }
    }, []);

    const debounced = useDebouncedCallback(
        async (value) => {
            if (value.length !== ConstantsKeys.defaultLimitInputSearch) {
                setValue(value);
                props.onSearch(value);
            }
        },
        500
    );

    return (
        <InputSearch
            ref={ref}
            autoFocus={props.autoFocus ?? false} //not working in modal antd
            defaultValue={props.defaultValue ?? value}
            enterButton
            size='large'
            loading={false}
            onPressEnter={() => {
                props.onSearch(value);
            }}
            onSearch={(value) => debounced(value)}
            allowClear
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => debounced(e.target.value)}
            placeholder={props.inputSearchPlaceholder}
            letterbuttoncolor={colors.text}
            backgroundmainbutton={colors.onBackground}
            lettermaincolor={colors.text}
            iconcolor={colors.hint}
            accent={colors.accent}
            hint={colors.hint}
            background={colors.isDarkTheme ? colors.backgroundInput : colors.background}
            border={colors.border}
            name="inputValue"
            prefix={props.prefix}
            suffix={props.suffix}
            autoComplete='off'
        />
    );
}
