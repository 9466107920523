import {
    Bold, ContentButton,
    ContentDescription, ContentSubDescription,
    ContentSubTitle, Description, StatusSubscription, SubTitle
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/styled";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const DetailActivePlan = (
    {
        disabled,
        loading,
        onClickChangePlan,
        planName,
        nextDueDateDDMMYYYY,
        planValue,
        visibleButtonChangePlan,
        isWaitingPlan,
    }: {
        disabled: boolean;
        loading: boolean;
        onClickChangePlan: () => void
        planName: string
        nextDueDateDDMMYYYY: string
        planValue: number
        visibleButtonChangePlan: boolean
        isWaitingPlan: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <>
            <ContentDescription>
                <ContentSubTitle>
                    <SubTitle color={colors.text}>{planName}</SubTitle>
                    <StatusSubscription
                        background={colors.success}
                        color={SharedColors.white}
                    >ATIVO</StatusSubscription>
                    {isWaitingPlan &&
                        <StatusSubscription
                            background={SharedColors.onAttendingStatus}
                            color={SharedColors.textAttendingStatus}
                        >Aguardando pagamento</StatusSubscription>
                    }
                </ContentSubTitle>
                <ContentSubDescription>
                    <Description size={15}
                                 color={colors.text}> {isWaitingPlan ? 'Pagamento válido até' : 'Próxima cobrança'}:
                        <Bold>&nbsp;{nextDueDateDDMMYYYY}</Bold></Description>
                    <Description size={15} color={colors.text}>Valor:
                        <Bold>&nbsp;{formatCurrency(planValue)}</Bold></Description>
                </ContentSubDescription>
            </ContentDescription>
            {visibleButtonChangePlan && <ContentButton>
                <ButtonMain
                    style={{paddingLeft: 5, paddingRight: 5}}
                    widthPercentage={100}
                    letterFontSize={15}
                    disabled={disabled}
                    loading={loading}
                    height={35}
                    color={colors.accent}
                    type={"submit"}
                    letter={'Trocar plano'}
                    onClick={() => onClickChangePlan()}
                />
            </ContentButton>}
        </>
    )
}
export default DetailActivePlan;
