import {ContentLoadingScroll, TextLoading} from './styled';
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import React from "react";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const LoadingInfinityScroll = (
    {
        loading,
        loadMore,
        label,
        labelLoading
    }: {
        loading: boolean
        loadMore: () => void
        label: string
        labelLoading: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <ContentLoadingScroll>
            {!loading &&
                <ButtonNew
                    size={37}
                    label={label}
                    onClick={() => {
                        loadMore();
                    }}
                    nameLeftIcon={ICONS_DROPDESK.refresh}
                    sizeLeftIcon={15}
                    disabled={false}
                    isButtonMain={true}
                />
            }

            {loading && <SpinLoading
                size={"large"}
                color={colors.text}
                tip={labelLoading}
            />}
        </ContentLoadingScroll>
    )
};

export default LoadingInfinityScroll;
