import {useEffect, useState} from "react";
import {isValidCNPJ, isValidCPF, isValidName} from "@dropDesk/utils/helpers/validators";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";

export const useValidatorInputCompany = (company?: CompanyEntity | null) => {

    const [errorMessageName, setErrorMessageName] = useState('');
    const [errorMessageDocument, setErrorMessageDocument] = useState('');
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);


    useEffect(() => {
        validate();
    }, [company]);

    const validate = (): boolean => {
        setErrorMessageName('');
        setErrorMessageDocument('');

        if (!company) {
            setIsValidDataForm(false);
            return false;
        }

        const isCPF = company.document.length <= 11;
        const isCNPJ = !isCPF;

        if (isCPF && !isValidCPF(company.document)) {
            setErrorMessageDocument('CPF inválido');
            setIsValidDataForm(false);
            return false;
        } else if (isCNPJ && !isValidCNPJ(company.document)) {
            setErrorMessageDocument('CNPJ inválido');
            setIsValidDataForm(false);
            return false;
        } else if (!company.name.trim()) {
            setErrorMessageName('Informe o nome da empresa');
            setIsValidDataForm(false);
            return false;
        } else if (company.name.trim().length < 5) {
            setIsValidDataForm(false);
            setErrorMessageName('O nome da empresa deve possuir mais de 4 letras');
            return false;
        } else {
            setIsValidDataForm(true);
            return true;
        }
    }

    return {
        errorMessageName,
        errorMessageDocument,
        isValidDataForm
    }
};
