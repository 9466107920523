import {
    Container,
    Content,
    Header,
    HeaderText
} from "@dropDesk/presentation/components/chat_view/modals/timeline_activities/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import MessageBox from "@dropDesk/presentation/components/messages/message_box";
import DropDeskLoadingDefault from "@dropDesk/presentation/components/loadings/loading_table";
import React from "react";


const TimeLineActivities =
    observer((
        {
            ticket,
            loading
        }: {
            ticket?: TicketEntity,
            loading: boolean
        }
    ) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        return (
            <Container>
                {(!ticket || loading) &&
                    <DropDeskLoadingDefault
                        height={80}
                        description={"Carregando ..."}
                    />
                }
                {!!ticket && !loading &&
                    <Content background={colors.backgroundChatBox}>
                        <Header>
                            <HeaderText color={colors.text}>Atividades no atendimento</HeaderText>
                        </Header>
                        <>
                            {ticket.logs && ticket.logs.map((log) => {
                                const message = log.toMessage();
                                return (
                                    <div key={message.id}>
                                        <MessageBox
                                            message={message}
                                            useHighLightHashTag={true}
                                            useReplyButton={false}
                                            onReplyClick={() => {
                                            }}
                                            useDeleteMessageButton={false}
                                            handleDeleteMessage={() => {
                                            }}
                                            resetMessageFocused={() => {
                                            }}
                                            focus={false}
                                            onReplyMessageClick={(messageReply) => {
                                            }}
                                            onRetrySendMessageError={(message) => {
                                            }}
                                            status={undefined}
                                            cancelUpload={(uploadTask) => {
                                            }}
                                            handleRemoveReaction={async () => {

                                            }}
                                            canRemoveReaction={false}
                                            maxWidth={445}
                                            useEditMessageButton={false}
                                            handleEditMessage={() => {
                                            }}
                                            handleAddReaction={async () => {

                                            }}
                                            lastUsedReactions={[]}
                                            reactions={[]}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    </Content>}
            </Container>
        )
    });
export default TimeLineActivities;
