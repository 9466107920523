import {useEffect, useState} from "react";
import {isValidDescription} from "@dropDesk/utils/helpers/validators";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";

export const useValidatorInputTicketsDescriptionStatic = (ticketsDescriptionStatic?: TicketsDescriptionStaticEntity, loading?: boolean) => {

    const [errorMessageDescription, setErrorMessageDescription] = useState('');


    useEffect(() => {
        validate();
    }, [ticketsDescriptionStatic]);

    const validate = (): boolean => {
        setErrorMessageDescription('');

        if (!ticketsDescriptionStatic) {
            return false;
        }
        if (!isValidDescription(ticketsDescriptionStatic.description) && !loading) {
            setErrorMessageDescription('A descrição do problema deve possuir mais de 2 letras')
            return false;
        } else {
            return true;
        }
    }

    return {
        errorMessageDescription,
        setErrorMessageDescription,
        validate
    }
};
