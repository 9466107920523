import styled from "styled-components";
import {GreenAppColors, SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type IconOpsType = {
    color: string;
}

type ButtonConnectionType = {
    background: string;
    color: string;
}

type ContainerType = {
    background: string;
    border: string;

}

export const ContentConnectionStatus = styled.div`
  display: flex;
  height: 200px;
  width: 150px;
  flex-direction: column;
`;
export const TextConnectionStatus = styled.span<IconOpsType>`
  font-size: ${`${ConstantsStyles.inputFontSize}px`};
  text-align: center;
  color: ${props => props.color};
`;
export const ButtonConnection = styled.button<ButtonConnectionType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.background};
  color: ${props => props.color};
  letter-spacing: 1px;
  font-size: ${`${ConstantsStyles.buttonSaveFontSize}px`};
  font-weight: bold;
  width: 150px;
  border: 0;
  transition: background-color 0.2s;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 40px;
  min-height: 40px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.background, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

`;
export const ContainerConnection = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContentTextStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
`;
export const ContentImageApproved = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 150px;
  border-radius: 6px 6px 0 0;
  background: ${props => props.background};
  border: 1px solid ${props => props.border}
`;

export const Image = styled.img`
  width: 150px;
  border-radius: 3px 3px 0 0;
`;
