import {observer} from "mobx-react";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import {Tooltip} from "antd";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ContainerAction} from "./styled";
import ButtonActionTable from "@dropDesk/presentation/components/buttons/button_action_table";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {useBetween} from "use-between";
import {useModalUsers} from "@dropDesk/presentation/pages/user/ui/search/modals/visible_modal_search";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import Tag from "@dropDesk/presentation/components/tag";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const TableUsers = observer(
    ({
         setUser,
         permitDeleteUser,
         searchParam,
         loading,
         permitSelectionRow,
         tableUsers,
         getDataFromPage,
         setRowSelectionUsers,
         rowSelectionUsers,
         heightGarbage,
         permissionDeleteData
     }: {
        setUser: (user: UserEntity) => void,
        permitDeleteUser: (user: UserEntity) => boolean,
        searchParam: string,
        loading: boolean,
        permitSelectionRow: boolean,
        tableUsers: ListPaginationEntity<UserEntity>,
        getDataFromPage: (page: number) => void,
        setRowSelectionUsers: (user: UserEntity[]) => void,
        rowSelectionUsers: UserEntity[]
        permissionDeleteData: boolean,
        heightGarbage: number
    }) => {

        const navigate = useNavigate();
        const collapsed = useContext(MenuCollapsedContext);

        const setRestore = (user: UserEntity) => {
            setRowSelectionUsers([user]);
            setVisibleModalRestore(true);
        }

        const {
            setVisibleModalRestore,
            setVisibleModalDelete,
        } = useBetween(useModalUsers);
        const tableColumn = [
            {
                title: 'Nome', dataIndex: 'url', key: 'url', width: 58, render: (text: string, record: UserEntity) => (
                    <AvatarImage
                        useCursorPointer={record.urlImageProfile ? true : false}
                        title={record.name}
                        size={35}
                        url={record.urlImageProfile}
                        round={true}
                        hasPreviewImage={true}
                        withBorder={true}
                        onClick={(event) => event.stopPropagation()}
                    />
                ),
            },
            {
                title: '',
                ellipsis: true,
                dataIndex: 'name',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Email',
                ellipsis: true,
                dataIndex: 'email',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Identificação',
                ellipsis: true,
                dataIndex: 'identity',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Permissões',
                //   width: 160,
                key: 'id',
                dataIndex: 'permissionAdmin',
                render: (permissionAdmin: boolean, record: UserEntity) => {
                    return (
                        <Tag
                            label={record.userMain ? 'Conta principal' : permissionAdmin ? 'Admin' : 'Padrão'}
                            width={135}
                        />
                    )
                }
            },
            {
                title: 'Criado em',
                ellipsis: true,
                dataIndex: 'createdAt',
                key: 'id',
                render: (text: string, record: UserEntity) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },
            {
                title: '',
                key: 'action',
                width: permissionDeleteData ? 65 : 40,
                render: (text: string, record: UserEntity) => (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <ContainerAction>
                            {!record.deleted && permissionDeleteData &&
                                <Tooltip placement="left" title={!permitDeleteUser(record) ? '' : 'Excluir Atendente'}
                                > <ButtonActionTable
                                    disabled={!permitDeleteUser(record)}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRowSelectionUsers([record]);
                                        setVisibleModalDelete(true);
                                    }}
                                    nameIcon={ICONS_DROPDESK.delete}
                                    isActionDelete={true}
                                /> </Tooltip>}
                            {record.deleted &&
                                <Tooltip placement="left" title={'Restaurar Atendente'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRestore(record);
                                    }}
                                    nameIcon={ICONS_DROPDESK.restore}
                                /> </Tooltip>

                            }
                        </ContainerAction>
                    </div>
                ),
            },
        ];
        const rowSelection = {
            selectedRowKeys: rowSelectionUsers.map((user) => user.id),
            onChange: (selectedRowKeys: React.Key[], selectedRows: UserEntity[]) => {
                setRowSelectionUsers(selectedRows)
            },
            getCheckboxProps: (record: UserEntity) => ({
                disabled: !permitDeleteUser(record),
            }),
            preserveSelectedRowKeys: true
        };


        return (
            <TableGeneric<UserEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableUsers.data}
                onSelectedRow={(user: UserEntity) => {
                    if (!user.deleted) {
                        navigate(`${RoutesEnum.users}/${user.id}`);
                    } else {
                        setRestore(user);
                    }
                }}
                loading={loading}
                labelNoResults={'Nenhum usuário encontrado na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableUsers.totalRows}
                page={tableUsers.page}
                pageSize={tableUsers.limit}
                rowSelection={permitSelectionRow ? rowSelection : null}
                onChange={(page) => getDataFromPage(page - 1)}
                extraLayout={heightGarbage}
            />
        )
    })
export default TableUsers;
