import {observer} from "mobx-react";
import React from "react";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";

const HeaderFormCreateTicket = observer(
  ({
     loading,
     backPreviousScreen
   }: {
    loading: boolean,
    backPreviousScreen: () => void,
  }) => {


    return (
      <HeaderForm
        letter={"Cadastro de Atendimento"}
        onRequestClose={backPreviousScreen}
        disabled={loading}
      />
    )
  })
export default HeaderFormCreateTicket;
