import styled from 'styled-components';
import {ContainerType} from "@dropDesk/presentation/components/headers/header_secondary/header_sec";

type TextType = {
    color: string;
};

export const Container = styled.div<ContainerType>`
  flex-direction: row;
  display: flex;
  padding: 16px;
  max-height: ${(props) => props.height ? `${props.height}px` : `90px`};
  background: ${(props) => props.background};
`;

export const ButtonBack = styled.button<TextType>`
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${props => props.color};
  font-size: 16px;

  &:hover {
    filter: brightness(80%);
  }
`;
