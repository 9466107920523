import "reflect-metadata";
import {inject, injectable} from "inversify";
import {
    CompanyConfigurationRepository
} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {ChatConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/chat_configuration.entity";

@injectable()
export class SetChatConfigurationsUseCase {
    private _repository: CompanyConfigurationRepository;

    constructor(@inject(CompanyConfigurationRepository) repository: CompanyConfigurationRepository) {
        this._repository = repository;
    }

    public call(configurationChat: ChatConfigurationEntity): Promise<CompanyConfigurationEntity> {
        return this._repository.setConfigurationChat(configurationChat);
    }
}
