import {
    Button,
    ContentButton,
    ContentIcon,
} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import {ContentRecording, ContentTextTimer, TextTimer} from "@dropDesk/presentation/components/audio_recorder/styled";
import AnimationRecordVoice from "@dropDesk/presentation/components/animations/audio_recorder_animation";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import RecordingCount from "@dropDesk/presentation/components/audio_recorder/timer";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ContainerButtonInput} from "../chat_view/chat_message/styled";

const Recording = (
    {
        cancelRecording,
        saveRecording,
        stopRecording
    }: {
        cancelRecording: () => void
        saveRecording: () => void
        stopRecording: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentRecording>
            <ContainerButtonInput
                accent={colors.accent}
                background={colors.buttonOnBackground}
            >
                <ContentButton>
                    <Button
                        onClick={() => {
                            cancelRecording();
                        }}
                    >
                        <ContentIcon>
                            <DropDeskIcon icon={ICONS_DROPDESK.delete} size={21} color={colors.textError}/>
                        </ContentIcon>
                    </Button>
                </ContentButton>
            </ContainerButtonInput>

            <ContentTextTimer>
                <TextTimer color={colors.text}>
                    <RecordingCount/>
                </TextTimer>
            </ContentTextTimer>

            <AnimationRecordVoice/>
            <ContainerButtonInput
                accent={colors.accent}
                background={colors.buttonOnBackground}
            >
                <ContentButton>
                    <Button
                        onClick={() => {
                            stopRecording();
                        }}
                    >
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.stop}
                            size={23}
                            color={colors.textError}
                        />
                    </Button>
                </ContentButton>
            </ContainerButtonInput>

            <ContainerButtonInput
                accent={colors.accent}
                background={colors.buttonOnBackground}
            >
                <ContentButton>
                    <Button
                        onClick={() => {
                            saveRecording();
                        }}
                    >
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.sendFill}
                            size={21}
                            color={colors.success}
                            style={{paddingLeft: 4}}
                        />
                    </Button>
                </ContentButton>
            </ContainerButtonInput>

        </ContentRecording>
    )
}
export default Recording;
