import {observer} from "mobx-react";
import React, {useContext, useState} from "react";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import LabelStatus
    from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/invoices_history/table/label_status";
import InvoiceView
    from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/invoices_history/table/invoice_view";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";

const TableInvoicesHistory = observer(
    ({
         tableInvoices,
         loading,
         getDataFromPageInvoices,
         handleClickPayPending,
         onClickViewInvoice,
         currentSubscription
     }: {

        tableInvoices: ListPaginationEntity<PaymentEntity>,
        loading: boolean
        getDataFromPageInvoices: (page: number) => void,
        handleClickPayPending: (payment: PaymentEntity) => void
        onClickViewInvoice: (url: string) => void
        currentSubscription: PaymentSubscriptionEntity

    }) => {
        const collapsed = useContext(MenuCollapsedContext);
        const appController = useInjection(AppController);

        const tableColumn = [

            {
                title: 'Código', ellipsis: true, dataIndex: 'id', key: 'id',
                render: (text: string, record: PaymentEntity) => (
                    <HighLightWords
                        searchWords={''}
                        textToHighlight={record.secureCode}
                    />
                ),
            },
            {
                title: 'Valor',
                ellipsis: true,
                dataIndex: 'value',
                key: 'id',
                render: (text: string, record: PaymentEntity) => (
                    <span>{formatCurrency(record.value)}</span>
                ),
            },

            {
                title: 'Pagamento', ellipsis: true, dataIndex: 'billingType', key: 'id',
                render: (text: string, record: PaymentEntity) => (
                    <HighLightWords
                        searchWords={''}
                        textToHighlight={record.paymentLabel}
                    />
                ),
            },
            {
                title: 'Situação',
                ellipsis: true,
                dataIndex: 'status',
                key: 'id',
                render: (text: string, record: PaymentEntity) => (
                    <LabelStatus payment={record} currentSubscription={currentSubscription}/>
                ),
            },
            {
                title: 'Período',
                ellipsis: true,
                dataIndex: 'period',
                key: 'id',
                render: (text: string, record: PaymentEntity) => (
                    <HighLightWords
                        searchWords={''}
                        textToHighlight={record.periodFormatted}
                    />
                ),
            },
            {
                title: 'Criado em', ellipsis: true, dataIndex: 'createdAt', key: 'id', render: (text: string) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },
            {
                title: 'Pago em', ellipsis: true, dataIndex: 'paymentDate', key: 'id', render: (text: string) => (
                    <>
                        {text && <span>{displayDateToLocale(text)}</span>}
                    </>
                ),
            },
            {
                title: 'Fatura',
                ellipsis: true,
                dataIndex: 'id',
                key: 'id',
                width: 115,
                render: (text: string, record: PaymentEntity) => (
                    <InvoiceView
                        payment={record}
                        handleClickPayPending={handleClickPayPending}
                        onClickViewInvoice={onClickViewInvoice}
                        currentSubscription={currentSubscription}
                    />
                ),
            },
        ];


        return (
            <TableGeneric<PaymentEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableInvoices.data}
                loading={loading}
                labelNoResults={'Nenhuma fatura encontrada na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableInvoices.totalRows}
                page={tableInvoices.page}
                pageSize={tableInvoices.limit}
                rowSelection={null}
                onChange={(page) => getDataFromPageInvoices(page - 1)}
                onSelectedRow={(payment) => {
                }}
                hideMenu={!appController.user}
                isChildContainer={true}
                
            />
        )
    })
export default TableInvoicesHistory;
