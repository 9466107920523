import {
    ButtonClosed,
    Container,
    ContainerButtonClosed,
    Content,
    ContentButtonSend,
    ContentConfirmEmail,
    ContentPin,
    ContentReSend,
    ContentSubTitle,
    ContentWelcomeAndClosedButton,
    DivButtonSend,
    Section,
    SubTitleConfirmEmail,
    TextClosed,
    TextLink,
    TextSubTitle,
    TextWeight,
    TextWelcome,
    TitleConfirmEmail
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PinField} from "@dropDesk/presentation/components/pin_field";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {isDateOnInterval} from "@dropDesk/utils/helpers/date_helper";
import {ModalChangeEmail} from "@dropDesk/presentation/pages/change_email/ui";
import activateImage from "@dropDesk/storage/images/activate.png";
import AnimatedImage from "@dropDesk/presentation/pages/change_password/ui/animate_image";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const Welcome = observer(() => {

    const [token, setToken] = useState("");
    const [hasSendEmail, setHasSendEmail] = useState(false);
    const [visibleModalChangeEmail, setVisibleModalChangeEmail] = useState(false);
    const [visibleModalLogout, setVisibleModalLogout] = useState(false);

    const appController = useInjection(AppController);
    const loginController = useInjection(LoginController);
    const colors = appController.theme.colorScheme;
    const userController = useInjection(UserController);
    const userName = appController.user?.name ?? '';
    const userEmail = appController.user?.email ?? '';
    const navigate = useNavigate();
    const {t} = useTranslation();
    const isValidInterval: boolean = isDateOnInterval(new Date(appController.user?.createdAt ?? ''),
        parseFloat(process.env.TIME_TO_EXPIRE_ACTIVE_ACCOUNT as string));

    useEffect(() => {
        if (!appController.user) {
            navigate(RoutesEnum.login, {replace: true});
        }
        if (appController.user && appController.user.verified) {
            navigate(appController.user.role === UserRole.userClient ? RoutesEnum.tickets : RoutesEnum.dashboard, {replace: true});
        }
    }, []);

    const setVisibleModal = () => {
        setVisibleModalChangeEmail(!visibleModalChangeEmail)
    }

    const onSuccessSendEmail = async (key: string) => {
        setHasSendEmail(true);
        toastMessage.success(t(key));
    }

    const onSuccess = async (key: string) => {
        toastMessage.success(t(key));
        setVerifyCurrentUser();
        navigate(appController.user!.role === UserRole.userClient ? RoutesEnum.tickets : RoutesEnum.dashboard, {replace: true});
    }

    const onSuccessLogout = () => {
        appController.setUser(null);
    }

    const setVerifyCurrentUser = () => {
        const userLogged = appController.user!.copyWith({
            verified: true
        });
        appController.setUser(userLogged);
    }

    const handleSendToken = async () => {
        if (token.length === 6) {
            await userController.firstAccess(token, onSuccess);
        }
    }

    return (
        <Container background={colors.delicateBackground}>

            <AnimatedImage
                imageSource={activateImage}
                title={"Ativação de conta"}
                subTitle={`Para que você tenha toda segurança dentro de nossa plataforma, precisamos que confirme seu email.`}
            />

            <Content background={colors.delicateBackground}>

                <ContainerButtonClosed background={colors.delicateBackground}>
                    {isValidInterval &&
                        <ButtonClosed
                            color={colors.text}
                            onClick={() => navigate(appController.user?.role === UserRole.userClient ? RoutesEnum.tickets : RoutesEnum.dashboard, {replace: true})}
                            disabled={userController.loading}
                            border={colors.border}
                            accent={colors.accent}
                        >
                            <TextClosed>
                                Fechar
                                <DropDeskIcon
                                    icon={ICONS_DROPDESK.close} size={13}
                                    style={{cursor: 'pointer', marginLeft: 10, marginTop: 2}}/>
                            </TextClosed>
                        </ButtonClosed>
                    }
                </ContainerButtonClosed>

                <Section>
                    <ContentWelcomeAndClosedButton>
                        <TextWelcome color={colors.text}>Bem-Vindo
                            (a), {userName.toUpperCase()}.</TextWelcome>
                    </ContentWelcomeAndClosedButton>

                    <ContentSubTitle>
                        <TextSubTitle color={colors.text}>Estamos muitos felizes em contar com você, para
                            sua total segurança, siga o passo
                            abaixo.</TextSubTitle>
                    </ContentSubTitle>

                    <ContentConfirmEmail background={colors.tertiary}>
                        <TitleConfirmEmail color={colors.text}>
                            Confirme seu e-mail
                        </TitleConfirmEmail>
                        <SubTitleConfirmEmail color={colors.text}>
                            Enviamos um <TextWeight>código</TextWeight> para o
                            e-mail <TextWeight>{userEmail}</TextWeight>, para verificação. Consulte seu e-mail e
                            informe o código para
                            confirmar e ativar sua conta:
                        </SubTitleConfirmEmail>

                        <ContentPin>
                            <PinField
                                noOfBoxes={6}
                                autoFocus={true}
                                disabled={userController.loading}
                                onChange={(token) => {
                                    setToken(token);
                                }}
                                useButtonClear={false}
                                acceptOnlyNumbers={true}
                            />
                        </ContentPin>

                        <ContentButtonSend>

                            <DivButtonSend>

                                <ButtonMain
                                    letterFontSize={16}
                                    widthPercentage={100}
                                    disabled={token.length < 6 || userController.loading}
                                    loading={userController.loading}
                                    color={colors.accent}
                                    height={45}
                                    type={"submit"}
                                    letter={"Enviar"}
                                    onClick={() => handleSendToken()}
                                />

                            </DivButtonSend>

                        </ContentButtonSend>

                    </ContentConfirmEmail>

                    <ContentReSend>
                        <SubTitleConfirmEmail color={colors.text}>
                            Se você não tiver recebido o e-mail, procure na pasta de spam ou&nbsp;
                            {!hasSendEmail && <TextLink
                                disabled={userController.loading}
                                onClick={async () => {
                                    await userController.activateAccount(userEmail, onSuccessSendEmail);
                                }}
                                color={colors.text}
                            >reenvie o e-mail</TextLink>}
                            {hasSendEmail && <SubTitleConfirmEmail color={colors.text}>
                                e-mail já foi enviado...
                            </SubTitleConfirmEmail>
                            }
                        </SubTitleConfirmEmail>

                        <SubTitleConfirmEmail color={colors.text}>
                            Quer alterar o seu endereço de e-mail?<TextLink
                            onClick={setVisibleModal}
                            color={colors.text}>&nbsp;alterar</TextLink>
                        </SubTitleConfirmEmail>

                        <SubTitleConfirmEmail color={colors.text}>
                            Deseja sair do sistema?
                            <TextLink
                                onClick={() => setVisibleModalLogout(true)}
                                color={colors.text}>&nbsp;sair do sistema</TextLink>
                        </SubTitleConfirmEmail>
                    </ContentReSend>
                </Section>
            </Content>

            {visibleModalChangeEmail && appController.user?.email && <ModalChangeEmail
                visibleModalChangeEmail={visibleModalChangeEmail}
                setVisibleModalChangeEmail={setVisibleModal}
                oldEmail={appController.user.email}
                isCurrentUserChanged={true}
            />}

            <ModalInformation
                open={visibleModalLogout}
                onRequestClose={() => {
                    setVisibleModalLogout(false);
                }}
                textHeaderModal={'Sair do sistema'}
                textButton={'Sair'}
                onRequestSave={async () => {
                    await loginController.logout(onSuccessLogout);
                    navigate(RoutesEnum.login, {replace: true});
                    setVisibleModalLogout(false);
                }}
                nameIcon={ICONS_DROPDESK.logout}
                sizeIcon={130}
                textInformation={`Deseja realmente sair do sistema?`}
                loading={loginController.loading}
            />

            {(userController.loading || appController.loading || loginController.loading) && <DropDeskLoading
                height={250}
                description={userController.loadingMessage ?? appController.loadingMessage ?? loginController.loadingMessage}
            />}
        </Container>
    );
});
export default Welcome;
