import {AvatarType} from "@dropDesk/presentation/components/avatar/avatar";
import {
    ContainerHasPreviewImage,
    ContainerImage,
    ContainerLetters,
    ImagePreview,
    Letter
} from "@dropDesk/presentation/components/avatar/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {getInitials} from "@dropDesk/utils/helpers/string_helper";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {useEffect, useState} from "react";


export default function AvatarImage(props: AvatarType) {

    const [loading, setLoading] = useState(false);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    let loadedAvatars: string[] = [];
    let src = props.url;
    let lazyLoadingImage = props.url;
    const useLetterSpacing = props.useLetterSpacing === undefined ? true : props.useLetterSpacing;

    useEffect(() => {
        if (!!lazyLoadingImage) {

            if (!!src && !isLoaded(src)) {
                src = lazyLoadingImage;

                if (!loading && props.url) {
                    requestImage(props.url);
                }
            }
        }
    }, []);

    const isLoaded = (src: string) => {
        return loadedAvatars.indexOf(src) !== -1
    }

    const requestImage = (src: string) => {
        setLoading(true);

        const loaded = () => {
            loadedAvatars.push(src);
            setLoading(false);
        }

        const img: HTMLImageElement = document.createElement('img');
        img.src = src;
        img.onload = loaded;
        img.onerror = loaded;
    }

    const initials: string = getInitials(props.title);
    return !!src ? (

        <div>
            {props.hasPreviewImage &&
                <ContainerHasPreviewImage
                    shadow={!props.useCursorPointer ? 'transparent' : colors.accent}
                    alertPulseAnimation={props.alertPulseAnimation ? 'true' : 'false'}
                    style={{
                        //border: props.withBorder ? `1px solid ${colors.text}` : 'none',
                        border: 'none',
                        borderRadius: props.round ? `50%` : 'auto',
                        ...props.style,
                        width: props.width ?? props.size
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.onClick ? props.onClick(event) : () => {
                        };
                    }}>
                    <ImagePreview
                        shadow={!props.useCursorPointer ? 'transparent' : colors.accent}
                        preview={{
                            style: {
                                background: hexToRGBA(SharedColors.black, 0.8),
                            },
                            mask: null
                        }}
                        islazyloading={loading === true ? 'true' : 'false'}//dom parse props not accept type boolean
                        width={props.width ?? props.size}
                        height={props.size}
                        src={src}
                        alt={'img'}
                        style={{
                            background: hexToRGBA(SharedColors.loading, colors.brightness === Brightness.light ? 0.6 : 1),
                            borderRadius: props.round ? '50%' : 5,
                            cursor: props.useCursorPointer ? 'pointer' : 'auto',
                            maxHeight: props.size
                        }}
                    />
                </ContainerHasPreviewImage>
            }

            {!props.hasPreviewImage &&
                <ContainerImage
                    // withBorder={props.withBorder ?? false}
                    withBorder={false}
                    style={{...props.style}}
                    bordercolor={colors.text}
                    src={src}
                    islazyloading={loading ? 'true' : 'false'}//dom parse props not accept type boolean
                    alt={props.title ?? 'imagem'}
                    background={hexToRGBA(SharedColors.loading, colors.brightness === Brightness.light ? 0.6 : 1)}
                    size={props.size}
                    round={props.round ?? false}
                    useCursorPointer={props.useCursorPointer}
                    disabled={props.disabled ?? false}
                    onClick={props.onClick}
                    width={props.width}
                    shadow={!props.useCursorPointer ? 'transparent' : colors.accent}
                    alertPulseAnimation={props.alertPulseAnimation ? 'true' : 'false'}
                />}
        </div>
    ) : (
        <ContainerLetters
            withBorder={props.withBorder ?? false}
            bordercolor={colors.border}
            size={props.size}
            round={props.round ?? false}
            background={props.isInverseBackground ? colors.delicateBackground : props.isBackgroundChat ? colors.backgroundInput : colors.background}
            useCursorPointer={props.useCursorPointer}
            disabled={props.disabled ?? false}
            onClick={props.onClick}
            islazyloading={'false'}
            shadow={!props.useCursorPointer ? 'transparent' : colors.accent}
            alertPulseAnimation={props.alertPulseAnimation ? 'true' : 'false'}
        >
            <Letter
                color={props.isInverseBackground ? colors.text : colors.text}
                letterSize={props.letterSize}
                useLetterSpace={initials.length > 1 && useLetterSpacing}
            >{initials}</Letter>
        </ContainerLetters>
    )
}
