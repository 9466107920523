import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {

    ContentTotalValue,
    Text,
    TextBold,
} from "../styled";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import React from "react";
import {observer} from "mobx-react";


const Header = observer((
    {
        totalValue,

    }: {
        totalValue: number
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentTotalValue>
            <TextBold size={39} color={colors.text}>
                {formatCurrency(totalValue)}
                <Text size={16} color={colors.text}>&nbsp;/&nbsp;mês</Text>
            </TextBold>
        </ContentTotalValue>
    );
});
export default Header;
