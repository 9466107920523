import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";

@injectable()
export class FindByPKCompanyUseCase {
    private _repository: CompanyRepository;

    constructor(@inject(CompanyRepository) repository: CompanyRepository) {
        this._repository = repository;
    }

    public call(): Promise<CompanyEntity> {
        return this._repository.findByPK();
    }
}

