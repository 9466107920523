import {observer} from "mobx-react";
import React from "react";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";

const HeaderFormSectors = observer(
  ({
     loading,
     backPreviousScreen,
     isUnsaved
   }: {
    loading: boolean,
    backPreviousScreen: () => void
    isUnsaved: boolean
  }) => {


    return (
      <HeaderForm
        letter={isUnsaved ?"Cadastro de setor" : "Editando setor"}
        onRequestClose={backPreviousScreen}
        disabled={loading}
      />
    )
  })
export default HeaderFormSectors;
