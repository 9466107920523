import React, {useEffect} from 'react';
import {
    Container,
    ContentList,
    SubContentList,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalsClient from "@dropDesk/presentation/pages/client/ui/my_clients/search/modals";
import SearchBar from "@dropDesk/presentation/pages/client/ui/my_clients/search/search_bar";
import TableClients from "@dropDesk/presentation/pages/client/ui/my_clients/search/table";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import FooterGarbage from "@dropDesk/presentation/components/footer/footer_garbage";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";

const Clients = (observer(() => {

    const {t} = useTranslation();
    const controller = useInjection(ClientController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const heightGarbage = appController.user?.permissionDeleteData ? 0 : ConstantsKeys.defaultHeightGarbage;

    useEffect(() => {
        controller.initializeClients(heightGarbage - appController.heightShowingHeaderInfo);
    }, []);

    const onSuccess = (key: string) => {
        controller.list(controller.searchParam);
        toastMessage.success(t(key));
    }

    return (
        <Container background={colors.onBackground}>

            <ContentList>
                <SearchBar
                    onSearch={(searchParam) => controller.list(searchParam)}
                    visibleInputSearch={controller.rowSelectionClients.length === 0}
                    loading={controller.loading}
                    visibleButtonsOfActions={!controller.listOnlyDeleted && controller.rowSelectionClients.length === 0}
                    visibleButtonDeleted={!controller.listOnlyDeleted && controller.rowSelectionClients.length > 0}
                    visibleButtonRestore={controller.listOnlyDeleted && controller.rowSelectionClients.length > 0}
                />

                <SubContentList>
                    <TableClients
                        rowSelectionClients={controller.rowSelectionClients}
                        setRowSelectionClients={(client: ClientEntity[]) => controller.setRowSelectionClients(client)}
                        getDataFromPage={(page) => controller.getDataFromPage(page)}
                        tableClients={controller.tableClients}
                        searchParam={controller.searchParam}
                        loading={controller.loading}
                        heightGarbage={heightGarbage}
                        permitSelectionRow={false}
                        permissionDeleteData={appController.user?.permissionDeleteData ?? false}
                    />
                    {appController.user?.permissionDeleteData && <FooterGarbage
                        setListOnlyDeleted={(value) => controller.setListOnlyDeleted(value)}
                        visibleListOnlyDeleted={controller.listOnlyDeleted}
                        loading={controller.loading}
                        text={'Empresas ativas'}
                        textDelete={'Empresas excluídas'}
                    />}
                    <ModalsClient
                        removeLineSelectRow={() => controller.removeLineSelectRow()}
                        restoreClient={(restoreClientWithUser) => controller.restore(onSuccess, restoreClientWithUser)}
                        deleteClient={() => controller.delete(onSuccess)}
                        loading={controller.loading}
                    />

                </SubContentList>

            </ContentList>
            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    );
}))
export default Clients;
