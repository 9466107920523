import styled from "styled-components";
import {TextType} from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan/styled";

export const ContainerAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const TextLink = styled.a<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${props => props.color};

  &:hover {
    text-decoration: underline;
  }
`;
