import styled from "styled-components";

type ContainerType = {
    background: string;
}

export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const ContainerDescriptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;


export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const ContentDescriptions = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;
