import {observer} from "mobx-react";
import React from "react";
import {
    ContentExportImport,
    ContentFilter,
    ContentInput,
    ContentInputAndFilter
} from "./styled";
import SelectRangeDate from "@dropDesk/presentation/components/dates/select_range_date";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Tooltip} from "antd";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useNavigate} from "react-router-dom";
import {useBetween} from "use-between";
import {
    useModalsReportTicketAggregate
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/ui/modals/handle_change_visible";
import {PeriodEnum} from "@dropDesk/utils/helpers/common";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const SearchBar = observer(
    ({
         loading,
         getReportTicketsAggregate,
         period,
         setPeriod,
         hasDataInGraphics,
         labelPeriodCustom
     }: {
         loading: boolean,
         getReportTicketsAggregate: () => void
         period: Array<Date> | null;
         hasDataInGraphics: boolean,
         setPeriod: (period: Array<Date> | null, label: PeriodEnum) => void
         labelPeriodCustom: PeriodEnum
     }
    ) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            setVisibleModalExportPdf,
            setVisibleModalExport
        } = useBetween(useModalsReportTicketAggregate);

        return (
            <ContentFilter background={colors.onBackground}>
                <ContentInputAndFilter>
                    <ContentInput>
                        <SelectRangeDate
                            size={37}
                            label={'Período'}
                            value={period}
                            allowClear={true}
                            onChange={(arrayDate, label) => {
                                setPeriod(arrayDate, label);
                                getReportTicketsAggregate();
                            }}
                            labelPeriodCustom={labelPeriodCustom}
                        />
                    </ContentInput>
                </ContentInputAndFilter>

                <ContentExportImport>
                    <Tooltip title={!hasDataInGraphics ? 'Não possui dados' : ''}>
                        <div>
                            <ButtonNew
                                marginLeft={10}
                                size={37}
                                label={'Exportar gráficos'}
                                onClick={() => {
                                    setVisibleModalExportPdf(true);
                                }}
                                nameLeftIcon={ICONS_DROPDESK.pdf}
                                sizeLeftIcon={15}
                                disabled={loading || !hasDataInGraphics}
                            />
                        </div>
                    </Tooltip>
                    {appController.user?.permissionAdmin &&
                        <Tooltip title={!hasDataInGraphics ? 'Não possui dados' : ''}>
                            <div>
                                <ButtonNew
                                    marginLeft={10}
                                    size={37}
                                    label={'Exportar dados'}
                                    onClick={() => {
                                        setVisibleModalExport(true);
                                    }}
                                    nameLeftIcon={ICONS_DROPDESK.excel}
                                    sizeLeftIcon={15}
                                    disabled={loading || !hasDataInGraphics}
                                />
                            </div>
                        </Tooltip>}
                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBar;
