import {observer} from "mobx-react";
import React, {useContext} from "react";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {Tooltip} from "antd";
import {
    ContainerAction, ContainerExpanded, ContentOptionExpanded, TextExpanded, TitleExpanded,
} from "./styled";
import ButtonActionTable from "@dropDesk/presentation/components/buttons/button_action_table";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {useBetween} from "use-between";
import {useModalTickets} from "@dropDesk/presentation/pages/ticket/ui/search/modals/visible_modal_search";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import {ColumnsType} from "antd/lib/table/interface";
import Tag from "@dropDesk/presentation/components/tag";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {TicketPriorityType} from "@dropDesk/domain/entities/ticket/ticket_maps";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {TicketConfig} from "@dropDesk/domain/entities/ticket/ticket_config";

const TableSearchTickets = observer((
    {
        tableTicket,
        getDataFromPage,
        setRowSelectionTicket,
        rowSelectionTicket,
        setUser,
        searchParam,
        loading,
        permitSelectionRow,
        heightGarbage,
        permissionDeleteData,
        onSelectedRow
    }: {
        tableTicket: ListPaginationEntity<TicketEntity>,
        getDataFromPage: (page: number) => void,
        setRowSelectionTicket: (ticket: TicketEntity[]) => void,
        rowSelectionTicket: TicketEntity[],
        setUser: (user: UserEntity) => void,
        searchParam: string,
        loading: boolean,
        permitSelectionRow: boolean,
        permissionDeleteData: boolean,
        heightGarbage: number,
        onSelectedRow: (ticket: TicketEntity) => void
    }) => {

    const collapsed = useContext(MenuCollapsedContext);
    const {setVisibleModalRestore, setVisibleModalDelete} = useBetween(useModalTickets);

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const setRestore = (ticket: TicketEntity) => {
        setRowSelectionTicket([ticket]);
        setVisibleModalRestore(true);
    }

    const getColorByPriority = (priority: string): string => {
        const priorityObj = appController.ticketPriorityConfigs.find((item) => item.id === priority);
        const priorityType: string | null = priorityObj?.extra?.type ?? null;
        if (priorityType === TicketPriorityType.low) {
            return SharedColors.success;
        }
        if (priorityType === TicketPriorityType.high) {
            return SharedColors.warning;
        }
        if (priorityType === TicketPriorityType.urgent) {
            return SharedColors.textError;
        }
        return colors.text;
    }


    const tableColumn: ColumnsType<TicketEntity> = [
        {
            title: 'Protocolo',
            dataIndex: 'number',
            ellipsis: false,
            width: 120,
            key: 'number',
            render: (text: string, record: TicketEntity) => (
                <Tooltip title={`Prioridade ${record.getPriority}`}>
                    <>
                        <HighLightWords
                            searchWords={searchParam}
                            textToHighlight={`${record.number.toString()}`}
                            color={record.priorityObject?.id ? getColorByPriority(record.priorityObject.id) : undefined}
                        />
                    </>
                </Tooltip>
            ),
        },
        {
            title: '',
            dataIndex: 'url',
            ellipsis: false,
            width: 45,
            render: (text: string, record: TicketEntity) => (
                <div style={{width: 35}}>
                    <AvatarImage
                        useCursorPointer={!!record.userClient?.urlImageProfile}
                        title={record.userClient?.name ?? ''}
                        size={35}
                        url={record.userClient?.urlImageProfile}
                        round={true}
                        hasPreviewImage={true}
                        withBorder={true}
                    />
                </div>
            ),
        },
        {
            title: 'Contato',
            dataIndex: 'users',
            ellipsis: true,
            render: (text: string, record: TicketEntity) => (
                <Tooltip title={record.userClient?.name ?? ''}>
                    <>
                        <HighLightWords
                            searchWords={searchParam}
                            textToHighlight={record.userClient?.name ?? ''}
                        />
                    </>
                </Tooltip>
            ),
        },
        {
            title: 'Telefone',
            dataIndex: 'users',
            ellipsis: true,
            render: (text: string, record: TicketEntity) => (
                <Tooltip title={record.userClient?.telephone ?? ''}>
                    <>
                        <HighLightWords
                            searchWords={searchParam}
                            textToHighlight={record.userClient?.telephone ?? ''}
                        />
                    </>
                </Tooltip>
            ),
        },

        {
            title: 'Atendente',
            dataIndex: 'attendant',
            ellipsis: true,
            render: (text: string, record: TicketEntity) =>
                <Tooltip
                    title={record.attendant?.name ?? ''}
                >
                    <>
                        <HighLightWords
                            searchWords={searchParam}
                            textToHighlight={record.attendant?.name ?? ''}
                        />
                    </>
                </Tooltip>
            ,
        },
        {
            title: 'Setor',
            dataIndex: 'sector',
            ellipsis: true,
            render: (text: string, record: TicketEntity) => (
                <Tooltip title={record.sector?.realName(appController.user!) ?? ''}>
                    <>
                        <HighLightWords
                            searchWords={searchParam}
                            textToHighlight={record.sector?.realName(appController.user!) ?? ''}
                        />
                    </>
                </Tooltip>
            ),
        },

        {
            title: 'Problema',
            dataIndex: 'description',
            ellipsis: true,
            render: (text: string, record: TicketEntity) => {
                return (
                    <Tooltip
                        title={record.realDescription(appController.user?.company.configurations.ticket.useProblemStatic ?? false)}>
                        <>
                            <HighLightWords
                                searchWords={searchParam}
                                textToHighlight={record.realDescription(appController.user?.company.configurations.ticket.useProblemStatic ?? false)}
                            />
                        </>
                    </Tooltip>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 120,
            render: (text: string, record: TicketEntity) => {
                return (
                    <Tag
                        label={record.getStatus}
                        isDeleted={false}
                        status={record.statusObject?.id}
                        width={95}
                    />
                )
            }
        },
        {
            title: '',
            width: permissionDeleteData ? 65 : 40,
            render: (text: string, record: TicketEntity) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <ContainerAction>
                        {!record.deleted && permissionDeleteData &&
                            <Tooltip placement="left" title={`Excluir Atendimento`}
                            > <ButtonActionTable
                                disabled={false}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setRowSelectionTicket([record]);
                                    setVisibleModalDelete(true)
                                }}
                                nameIcon={ICONS_DROPDESK.delete}
                                isActionDelete={true}
                            /> </Tooltip>}
                        {record.deleted &&
                            <Tooltip placement="left" title={'Restaurar Atendimento'}
                            > <ButtonActionTable
                                disabled={false}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setRestore(record);
                                }}
                                nameIcon={ICONS_DROPDESK.restore}
                            /> </Tooltip>

                        }
                    </ContainerAction>
                </div>
            ),
        },
    ].filter((entry) => appController.isUserClient ? (entry.dataIndex !== 'priority' && entry.dataIndex !== 'dateDue') : entry) as ColumnsType<TicketEntity>;

    const defaultExpandable = {
        expandedRowRender: (record: TicketEntity) => {
            return (
                <ContainerExpanded>
                    <ContentOptionExpanded>
                        <TitleExpanded color={colors.hintInverse}>Prioridade:</TitleExpanded>
                        <TextExpanded
                            color={record.priorityObject?.id ? getColorByPriority(record.priorityObject.id) : colors.text}>{record.getPriority}</TextExpanded>
                    </ContentOptionExpanded>
                    <ContentOptionExpanded>
                        <TitleExpanded color={colors.hintInverse}>Vencimento:</TitleExpanded>
                        <Tooltip title={record.overdue ? 'Prazo vencido' : ''}>
                            <TextExpanded color={record.overdue ? colors.textError : colors.text}>
                                {record.dateDue &&
                                    <span style={{fontSize: 14}}>
                                        {displayDateToLocale(record.dateDue)}
                                     </span>
                                }
                            </TextExpanded>
                        </Tooltip>
                    </ContentOptionExpanded>
                    <ContentOptionExpanded>
                        <TitleExpanded color={colors.hintInverse}>Criado em:</TitleExpanded>
                        <TextExpanded
                            color={colors.text}> {record.createdAt ? displayDateToLocale(record.createdAt) : ''}</TextExpanded>
                    </ContentOptionExpanded>

                    <ContentOptionExpanded>
                        <TitleExpanded color={colors.hintInverse}>Origem:</TitleExpanded>
                        <TextExpanded color={colors.text}>{record.getOrigin}</TextExpanded>
                    </ContentOptionExpanded>
                    <ContentOptionExpanded>
                        <TitleExpanded color={colors.hintInverse}>Status:</TitleExpanded>
                        <TextExpanded
                            color={colors.text}>{TicketConfig.translateStatusToHuman(record.statusObject?.description ?? '')}</TextExpanded>
                    </ContentOptionExpanded>
                </ContainerExpanded>
            )
        }
    };
    const rowSelection = {

        selectedRowKeys: rowSelectionTicket.map((ticket) => ticket.id),
        onChange:
            (selectedRowKeys: React.Key[], selectedRows: TicketEntity[]) => {
                setRowSelectionTicket(selectedRows)
            },
        getCheckboxProps:
            () => ({
                disabled: false,
            }),

        preserveSelectedRowKeys:
            true
    };


    return (
        <TableGeneric<TicketEntity>
            size={'small'}
            sticky={true}
            tableColumns={tableColumn}
            tableDataSource={tableTicket.data}
            onSelectedRow={(ticket: TicketEntity) => {
                if (!ticket.deleted) {
                    onSelectedRow(ticket);
                } else {
                    setRestore(ticket);
                }
            }}
            expandable={appController.isUserClient ? undefined : defaultExpandable}
            loading={loading}
            labelNoResults={'Nenhum resultado encontrado na pesquisa'}
            collapsedMenu={collapsed}
            defaultCurrentPage={1}
            totalRows={tableTicket.totalRows}
            page={tableTicket.page}
            pageSize={tableTicket.limit}
            rowSelection={permitSelectionRow ? rowSelection : null}
            onChange={(page) => getDataFromPage(page - 1)}
            extraLayout={heightGarbage}
            heightTableRow={75}
        />
    )
})
export default TableSearchTickets;
