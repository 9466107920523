import {useEffect, useState} from "react";
import {
  BusinessOperationDayEntity,
  BusinessOperationHoursConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {timeToFloat} from "@dropDesk/utils/helpers/date_helper";

export const useValidatorHours = (hours: BusinessOperationHoursConfigurationEntity) => {

  const [errorMonday, setErrorMonday] = useState('');
  const [errorTuesday, setErrorTuesday] = useState('');
  const [errorWednesday, setErrorWednesday] = useState('');
  const [errorThursday, setErrorThursday] = useState('');
  const [errorFriday, setErrorFriday] = useState('');
  const [errorSaturday, setErrorSaturday] = useState('');
  const [errorSunday, setErrorSunday] = useState('');
  const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);

  useEffect(() => {
    validate();
  }, [hours]);

  const isConflictTimeRange = (day: BusinessOperationDayEntity, usesTwoOperationHours: boolean): boolean => {
    const intFirstHour = timeToFloat(day.endFirstHour);
    const intSecondHour = timeToFloat(day.initialSecondHour);
    return day.open === true && usesTwoOperationHours === true ? (intSecondHour < intFirstHour) : false;
  }

  const validate = (): void => {

    setErrorMonday('');
    setErrorTuesday('');
    setErrorWednesday('');
    setErrorThursday('');
    setErrorFriday('');
    setErrorSaturday('');
    setErrorSunday('');

    if (isConflictTimeRange(hours.monday, hours.usesTwoOperationHours)) {
      setErrorMonday('Faixa horária conflitante');
      setIsValidDataForm(false);
    }
    if (isConflictTimeRange(hours.tuesday, hours.usesTwoOperationHours)) {
      setErrorTuesday('Faixa horária conflitante');
      setIsValidDataForm(false);

    }
    if (isConflictTimeRange(hours.wednesday, hours.usesTwoOperationHours)) {
      setErrorWednesday('Faixa horária conflitante');
      setIsValidDataForm(false);

    }
    if (isConflictTimeRange(hours.thursday, hours.usesTwoOperationHours)) {
      setErrorThursday('Faixa horária conflitante');
      setIsValidDataForm(false);

    }
    if (isConflictTimeRange(hours.friday, hours.usesTwoOperationHours)) {
      setErrorFriday('Faixa horária conflitante');
      setIsValidDataForm(false);

    }
    if (isConflictTimeRange(hours.saturday, hours.usesTwoOperationHours)) {
      setErrorSaturday('Faixa horária conflitante');
      setIsValidDataForm(false);

    }
    if (isConflictTimeRange(hours.sunday, hours.usesTwoOperationHours)) {
      setErrorSunday('Faixa horária conflitante');
      setIsValidDataForm(false);

    }
    if(!isConflictTimeRange(hours.monday, hours.usesTwoOperationHours) &&
      !isConflictTimeRange(hours.tuesday, hours.usesTwoOperationHours) &&
      !isConflictTimeRange(hours.wednesday, hours.usesTwoOperationHours) &&
      !isConflictTimeRange(hours.thursday, hours.usesTwoOperationHours) &&
      !isConflictTimeRange(hours.friday, hours.usesTwoOperationHours) &&
      !isConflictTimeRange(hours.saturday, hours.usesTwoOperationHours) &&
      !isConflictTimeRange(hours.sunday, hours.usesTwoOperationHours)) {
      setIsValidDataForm(true);
    }
  }

  return {
    errorMonday, setErrorMonday,
    errorTuesday, setErrorTuesday,
    errorWednesday, setErrorWednesday,
    errorThursday, setErrorThursday,
    errorFriday, setErrorFriday,
    errorSaturday, setErrorSaturday,
    errorSunday, setErrorSunday,
    isValidDataForm, setIsValidDataForm,
    validate
  }
};
