import styled from "styled-components";

type TextType = {
  color: string;
};


export const ContentInputsWithBorder = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContentPin = styled.div`
  margin: 25px 0;
  display: flex;
`;

export const TitleInformToken = styled.span<TextType>`
  display: flex;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.color};
  margin: 0 0 5px 0;
`;
