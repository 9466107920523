import styled from "styled-components";

export const ContentData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 26px 16px 0px 16px;
`;

export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


export const ContentDataInput = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
