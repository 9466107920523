import {useState} from "react";

export const useModalNotices = () => {

  const [visibleModalSearchUser, setVisibleModalSearchUser] = useState<boolean>(false);
  const [visibleModalSearchClient, setVisibleModalSearchClient] = useState<boolean>(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);


  return {
    visibleModalSearchUser,setVisibleModalSearchUser,
    visibleModalSearchClient,setVisibleModalSearchClient,
    visibleModalDelete,setVisibleModalDelete
  }
}
