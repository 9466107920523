import {observer} from "mobx-react";
import React, {useState} from "react";
import {ContentData, ContentImage, ContentInput, ContentInputNameIdentity, SubContentInputs} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {useValidatorInputUsers} from "@dropDesk/presentation/pages/user/ui/form/inputs/validator";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import ImageProfile from "@dropDesk/presentation/components/drop_zone/pick_image_profile";
import {Crop} from "@dropDesk/presentation/components/crop";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useBetween} from "use-between";
import {useModalUsers} from "@dropDesk/presentation/pages/user/ui/form/modals/handle_change_visible";
import {ModalChangeEmail} from "@dropDesk/presentation/pages/change_email/ui";

const InputsForm = observer(
    ({
         onUpdate,
         user,
         loading,
         createObjectURLBlobUseCase,
         clearUrlImage,
         isCurrentUser,
         isNewUser,
         permitChangeEmail,
         isUserClient
     }: {
        onUpdate: (client: UserEntity) => void,
        user?: UserEntity,
        loading: boolean,
        createObjectURLBlobUseCase: (file: File) => string,
        clearUrlImage: () => void
        isCurrentUser: boolean
        isNewUser: boolean
        permitChangeEmail: boolean
        isUserClient: boolean
    }) => {

        const [imageSourceCrop, setImageSourceCrop] = useState<string | null>(null);

        const {
            errorMessageName,
            errorMessageEmail,
        } = useValidatorInputUsers(user);

        const {
            setVisibleModalActivateAccount,
            setVisibleModalRememberPassword,
            changeVisibleModalChangeEmail,
            visibleModalChangeEmail
        } = useBetween(useModalUsers);


        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string };
        }, file?: File) => {
            const _user = user!.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ?
                        event.target.checked :
                        event.target.name === "urlImageProfile" && file ? createObjectURLBlobUseCase(file)
                            : event.target.value,
                replaceUrlImageProfileIfNull: event.target.name === "urlImageProfile" && !!file,
                replaceEmailIfNull: event.target.name === "email",
                replaceIdentityIfNull: event.target.name === "identity",
            });
            onUpdate(_user);

        };

        const handleChangeImage = (files: File[] | []): void => {
            if (files && files.length > 0) {
                const path = createObjectURLBlobUseCase(files[0]);
                setImageSourceCrop(path);
            } else {
                toastMessage.error("Error ao Anexar, Selecione uma imagem com no máximo 1MB");
            }
        };

        const removeProfileImage = (): void => {
            setImageSourceCrop(null);
            clearUrlImage();
        };

        return (
            <SubContentInputs>
                <ContentData autoComplete='off'>
                    <ContentInputNameIdentity>
                        <ContentInput style={{marginRight: isUserClient ? 0 : 20}}>
                            <InputWithBorder
                                autoFocus={true}
                                required={true}
                                name='name'
                                size={35}
                                disabled={loading || (!isCurrentUser && !user?.isUnsaved())}
                                label={"Nome"}
                                messageError={errorMessageName}
                                value={user?.name ?? ''}
                                onChange={handleAllChanges}
                            />
                        </ContentInput>
                        {!isUserClient && <ContentInput style={{marginBottom: 25}}>
                            <InputWithBorder
                                autoFocus={false}
                                required={false}
                                name='identity'
                                size={35}
                                disabled={loading}
                                label={"Identificação"}
                                value={user?.identity ?? ''}
                                onChange={handleAllChanges}
                            />
                        </ContentInput>}
                    </ContentInputNameIdentity>
                    <InputWithBorder
                        required={true}
                        name='email'
                        size={35}
                        disabled={loading || !user?.isUnsaved()}
                        placeHolder={"Email de acesso do atendente"}
                        onClickTwoButtonRight={() => {
                            changeVisibleModalChangeEmail();
                        }}
                        onClickButtonRight={() => {
                            if (user?.verified) {
                                setVisibleModalRememberPassword(true);
                            } else {
                                setVisibleModalActivateAccount(true);
                            }
                        }}
                        labelTwoButtonRight={"Trocar e-mail"}
                        labelButtonRight={user?.verified ? 'Recuperar senha' : 'Ativar conta'}
                        useTwoButtonRight={permitChangeEmail}
                        useButtonRight={!isNewUser}
                        disabledButtonRight={loading}
                        disabledTwoButtonRight={loading}
                        messageError={errorMessageEmail}
                        label={"Email"}
                        value={user?.email ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentData>
                <ContentImage>
                    <ImageProfile
                        useGarbage={true}
                        radiusImage={false}
                        sizeImage={91}
                        width={135}
                        onPick={handleChangeImage}
                        disabled={loading || (!isCurrentUser && !user?.isUnsaved())}
                        name={''}
                        urlImageProfile={user?.urlImageProfile ?? ''}
                        onRemoveProfileImage={removeProfileImage}
                        marginTopButtonChangeImage={15}
                    />
                    {imageSourceCrop ? (
                        <Crop
                            image={imageSourceCrop}
                            onRequestCrop={(file) => {
                                handleAllChanges({
                                    target:
                                        {
                                            name: 'urlImageProfile',
                                            value: null,
                                            checked: false,
                                            type: 'input'
                                        }
                                }, file);
                                setImageSourceCrop(null);
                            }}
                            onRequestClose={() => setImageSourceCrop(null)}
                        />
                    ) : null}
                </ContentImage>
                <ModalChangeEmail
                    visibleModalChangeEmail={visibleModalChangeEmail}
                    setVisibleModalChangeEmail={changeVisibleModalChangeEmail}
                    oldEmail={user?.email!}
                    isCurrentUserChanged={isCurrentUser}
                    onSuccessChangeEmail={(newEmail) => {
                        const _user = user!.copyWith({
                            email: newEmail,
                            verified: false,
                            replaceEmailIfNull: true,
                        });
                        onUpdate(_user);
                    }}
                />
            </SubContentInputs>
        )
    })
export default InputsForm;
