import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import React, {useEffect} from "react";
import ModalSearch from "@dropDesk/presentation/components/modals/modal_search";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import {ModalSearchSectorType} from "@dropDesk/presentation/components/modals/modal_search_sector/modal_search_sector";
import {SectorController} from "@dropDesk/presentation/pages/sector/controller/sector.controller";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {setBlockUpdateLocalSearchCache} from "@dropDesk/utils/helpers/common";


const ModalSearchSector = observer((props: ModalSearchSectorType<SectorEntity>) => {

    const sectorController = useInjection(SectorController);
    const appController = useInjection(AppController);
    const idUser = appController.isUserClient ? undefined : props.idUser;

    const initialize = () => {
        setBlockUpdateLocalSearchCache(true);
        sectorController.setFilterListableForClients(appController.isUserClient ? 'true' : null);
        sectorController.tableSectors.limit = Math.floor((window.innerHeight - 265 - (props.useFooter ? 74 : 0)) / 50);
        onSearch(sectorController.searchParam);

    }

    const onSearch = (searchParam: string): void => {
        sectorController.list(searchParam, idUser);
    }

    useEffect(() => {
        initialize();
        return () => {
            setBlockUpdateLocalSearchCache(false);
        }
    }, []);
    const tableColumnSector = [

        {
            title: 'Nome',
            dataIndex: 'name',
            ellipsis: true,
            key: 'name',
            render: (text: string, record: SectorEntity) => (
                <HighLightWords
                    searchWords={sectorController.searchParam}
                    textToHighlight={appController.isUserClient && !!record.chatLabel ? record.chatLabel : text}
                />
            ),
        },
        {
            title: 'Observação',
            dataIndex: 'note',
            ellipsis: true,
            key: 'id',
            render: (text: string, record: SectorEntity) => (
                <HighLightWords
                    searchWords={sectorController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
    ];


    return (
        <ModalSearch<SectorEntity>
            inputSearchPlaceholder={props.inputSearchPlaceholder}
            tableColumns={appController.isUserClient ? tableColumnSector.filter(entry => entry.key === 'name') : tableColumnSector}
            open={props.visible}
            onSearch={(value) => {
                onSearch(value);
            }}
            onSelectedRow={(sector: SectorEntity) => {
                props.handleSelectSector(sector);
            }}
            inputValue={sectorController.searchParam}
            textHeaderModal={props.textHeaderModal}
            loading={sectorController.loading}
            rowSelection={props.rowSelection}
            onRequestClose={props.onClose}
            tableDataSource={sectorController.tableSectors.data}
            defaultCurrentPage={1}
            totalRows={sectorController.tableSectors.totalRows}
            page={sectorController.tableSectors.page}
            pageSize={sectorController.tableSectors.limit}
            onChange={(page) => sectorController.getDataFromPage(page - 1)}
            labelNoResults={props.labelNoResults}
            useFooter={props.useFooter}
            onSave={props.onSave}
            extraLayout={props.extraLayout}
        />
    )
});
export default ModalSearchSector;
