import {
    Container, Content, Text
} from "./styled";
import React from "react";
import ButtonPay
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/pix/button_pay";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const PixPaymentMethod = (
    {
        loading,
        pay,
        isEditExternal,
        hideButtonPay
    }: {
        loading: boolean
        pay: () => void
        isEditExternal?: boolean
        hideButtonPay?: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Container>
            <Content>
                <Text size={16} color={colors.text}>Com a forma de pagamento Pix, você garante praticidade e
                    agilidade, assinando o DropDesk de forma instantânea e segura.</Text>
            </Content>
            {!hideButtonPay && <ButtonPay
                loading={loading}
                pay={pay}
                isEditExternal={isEditExternal}
            />}
        </Container>
    );
}
export default PixPaymentMethod;
