import {Orders, Status, Type} from "@dropDesk/domain/entities/task/task_enum";

type Map = {
  value: string,
  label: string
}

export const statusFilterMap: Map[] = [
  {value: Status.undone, label: "Não feito"},
  {value: Status.done, label: "Feito"},
  {value: Status.all, label: "Todos"},
]
export const typesFilterMap: Map[] = [
  {value: Type.orderbydate, label: "Data de criação"},
  {value: Type.orderbydone, label: "Data de conclusão"},
]
export const ordersFilterMap: Map[] = [
  {value: Orders.asc, label: "Crescente"},
  {value: Orders.desc, label: "Decrescente"},
]
