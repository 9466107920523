import styled from "styled-components";

export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
`;
export const ContentButton = styled.div`
  width: 200px;
`;

