export class TicketsDescriptionStaticEntity {
    id!: string;
    idCompany!: string;
    description!: string;
    deleted!: boolean;
    createdAt?: string;
    listableForClients!: boolean;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    constructor({
                    id,
                    idCompany,
                    description,
                    deleted,
                    createdAt,
                    listableForClients
                }: {
        id: string;
        idCompany: string;
        description: string;
        deleted: boolean;
        createdAt?: string;
        listableForClients: boolean;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            description,
            deleted,
            createdAt,
            listableForClients
        });
    }

    copyWith({
                 id,
                 idCompany,
                 description,
                 deleted,
                 createdAt,
                 listableForClients
             }: {
        id?: string;
        idCompany?: string;
        description?: string;
        deleted?: boolean;
        createdAt?: string;
        listableForClients?: boolean;
    }): TicketsDescriptionStaticEntity {
        return new TicketsDescriptionStaticEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            description: description ?? this.description,
            deleted: deleted ?? this.deleted,
            createdAt: createdAt ?? this.createdAt,
            listableForClients: listableForClients ?? this.listableForClients
        })
    }
}
