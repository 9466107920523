import {observer} from "mobx-react";
import React from "react";

import FooterConfiguration from "@dropDesk/presentation/components/footer/footer_configuration";
import {
    useValidatorWhatsApp
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/messages/validator";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {useBetween} from "use-between";
import {
    useModalWhatsApp
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/handle_change_visible";
import {TabsWhatsApp} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration";

const FooterWhatsApp = observer(
    ({
         loading,
         handleSubmit,
         companyConfiguration,
         activeKey
     }: {
        companyConfiguration: CompanyConfigurationEntity,
        loading: boolean,
        handleSubmit: () => void,
        activeKey: TabsWhatsApp
    }) => {

        const {
            isValidDataForm,
        } = useValidatorWhatsApp(companyConfiguration);

        const {
            setVisibleModalRestoreMessage
        } = useBetween(useModalWhatsApp);

        return (
            <FooterConfiguration
                disabled={loading || !isValidDataForm}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={() => {
                    if (isValidDataForm) {
                        handleSubmit();
                    }
                }}
                letterLeftButton={activeKey === TabsWhatsApp.messages ? 'Redefinir mensagens' : undefined}
                osRequestLeftButton={() => setVisibleModalRestoreMessage(true)}
            />
        )
    })
export default FooterWhatsApp;
