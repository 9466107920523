import {addDays, convertSQLISOToDate} from "@dropDesk/utils/helpers/date_helper";
import SelectDateAndTime from "@dropDesk/presentation/components/dates/select_date_and_time";
import React from "react";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";

const InputDateDue = (
    {
        ticket,
        errorMessageDateDue,
        handleAllChanges,
        minDate
    }: {
        ticket: TicketEntity
        errorMessageDateDue: string
        handleAllChanges: (event: { target: { name: string; value: any; checked?: any; type: string } }) => void
        minDate: Date
    }
) => {
    return (
        <SelectDateAndTime
            value={convertSQLISOToDate(ticket.dateDue ?? null)}
            label={"Data de vencimento"}
            placeHolder={'Selecione a data de vencimento'}
            minDateFromNow={true}
            minDate={minDate}
            maxDate={addDays(new Date(ticket.createdAt ?? new Date()), 7)}
            messageError={errorMessageDateDue}
            size={35}
            onChange={(date) => {
                const dateToString = date ? date.toISOString() : null;
                handleAllChanges(
                    {
                        target:
                            {
                                name: 'dateDue',
                                value: dateToString,
                                checked: false,
                                type: 'input'
                            }
                    })
            }}
        />
    )
}
export default InputDateDue;
