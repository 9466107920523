import {observer} from "mobx-react";
import React, {useContext} from "react";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";
import {Tooltip} from "antd";
import {
    ContentButtonPay
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/invoices_history/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const TableChangeHistory = observer(
    ({
         loading,
         tableSubscriptionLogs,
         getDataFromPageLogs,
         onSelectedRowLog
     }: {

        tableSubscriptionLogs: ListPaginationEntity<CompanyLogsEntity>,
        loading: boolean
        getDataFromPageLogs: (page: number) => void,
        onSelectedRowLog: (log: CompanyLogsEntity) => void
    }) => {
        const collapsed = useContext(MenuCollapsedContext);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const tableColumn = [

            {
                title: 'Evento', ellipsis: true, dataIndex: 'description', key: 'description',
                render: (text: string, record: CompanyLogsEntity) => (
                    <Tooltip title={record.log.description}>
                        <div>
                            <HighLightWords
                                searchWords={''}
                                textToHighlight={record.log.description}
                            />
                        </div>
                    </Tooltip>
                ),
            },
            {
                title: 'Responsável',
                ellipsis: true,
                dataIndex: 'id',
                width: 250,
                key: 'id',
                render: (text: string, record: CompanyLogsEntity) => (
                    <Tooltip title={record.loggedBy.email}>
                        <span>{record.responsible}</span>
                    </Tooltip>

                ),
            },
            {
                title: 'Alterado em',
                ellipsis: true, dataIndex: 'createdAt',
                key: 'id',
                width: 250,
                render: (text: string) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },
            {
                title: '',
                ellipsis: false,
                dataIndex: 'id',
                key: 'id',
                width: 115,
                render: (text: string, record: CompanyLogsEntity) => {
                    return (
                        <ContentButtonPay
                            onClick={() => onSelectedRowLog(record)}
                            size={15}
                            color={colors.text}
                            background={colors.onBackground}
                            accent={colors.accent}
                        >
                            Abrir
                        </ContentButtonPay>
                    )
                }
            }
        ];


        return (
            <TableGeneric<CompanyLogsEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableSubscriptionLogs.data}
                loading={loading}
                labelNoResults={'Nenhuma alteração encontrado na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableSubscriptionLogs.totalRows}
                page={tableSubscriptionLogs.page}
                pageSize={tableSubscriptionLogs.limit}
                rowSelection={null}
                onChange={(page) => getDataFromPageLogs(page - 1)}
                onSelectedRow={(log) => onSelectedRowLog(log)}
                hideMenu={!appController.user}
                isChildContainer={true}
            />
        )
    })
export default TableChangeHistory;
