import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {SectorRepository} from "@dropDesk/domain/repositories/sector/sector.repository";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";

@injectable()
export class ListSectorUseCase {
    private _repository: SectorRepository;

    constructor(@inject(SectorRepository) repository: SectorRepository) {
        this._repository = repository;
    }

    public call(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        idUser?: string,
        listableForClients?: boolean
    ): Promise<ListPaginationEntity<SectorEntity>> {
        return this._repository.list(page, searchParam, limit, listOnlyDeleted, idUser, listableForClients);
    }
}

