import styled from "styled-components";

type ContainerType = {
    background: string;
};

type FormType = {
    background: string;
    border: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
  border-radius: 5px;
`;

export const ContainerForm = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px);
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
`;

export const ContentForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 16px;
`;

export const FormImport = styled.div<FormType>`
  display: flex;
  min-width: 768px;
  flex-direction: column;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-radius: 5px;
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`;
export const ContentFormImport = styled.div<ContainerType>`
  display: flex;
  min-width: 768px;
  width: 500px;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`;


export const ContentImportClient = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Padding = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
`;
export const ContainerRevert = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
`;

export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
  flex: 1;
`;
export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
export const ContentFooter = styled.div<ContainerType>`
  background: ${props => props.background};
  border-radius: 10px;
`;

export const ContentRevert = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
