import {
    ContentCheckBoxCommentPrivate,
    ContentCommentPrivate
} from "./styled";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import React from "react";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const CheckBoxPrivateComment = (
    {
        checked,
        disabled,
        onChange
    }: {
        checked: boolean
        disabled: boolean,
        onChange: (event: CheckboxChangeEvent) => void,
    }) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentCommentPrivate>
            <ContentCheckBoxCommentPrivate>
                <CheckBoxWithLabel
                    label={`Comentário interno`}
                    checked={checked}
                    bordercolor={colors.text}
                    name={'isPrivate'}
                    marginLeft={0}
                    disabled={disabled}
                    onChange={onChange}
                    tooltip={'A mensagem enviada fica como uma mensagem interna da equipe e não é enviada para o seus contatos.'}
                />

            </ContentCheckBoxCommentPrivate>
        </ContentCommentPrivate>
    )
}
export default CheckBoxPrivateComment;
