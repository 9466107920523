import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
    bordercolor: string;
};


export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  min-height: 530px;
  height: 530px;
  padding: 16px;
  border-radius: 10px;
  flex-direction: column;
  background: ${(props) => props.background};
  border: 1px solid ${props => props.bordercolor}
`;
