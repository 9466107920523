import {observer} from "mobx-react";
import React from "react";
import {SliderTimeRange} from "@dropDesk/presentation/components/slider_time_range";
import {
    ContainerHourAndTimeRange,
    ContainerInputRange,
    ContainerSecondTimeRange,
    ContentHourAndTimeRange,
    ContentInputRange,
    ContentSecondHourAndTimeRange,
    ContentSecondTimeRange,
    TextAs,
    TextSecondHour
} from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/wednesday/styled";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    useValidatorHours
} from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/validator";
import {TextError} from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/styled";


const Wednesday = observer(
    ({
         loading,
         onUpdate,
         companyConfiguration
     }: {
        loading: boolean,
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
        companyConfiguration: CompanyConfigurationEntity
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }, initialHour?: string | null, endHour?: string | null, isSecondDate: boolean = false) => {

            let _configuration = companyConfiguration.copyWith({

                businessOperationHours: companyConfiguration.businessOperationHours.copyWith({

                    wednesday: companyConfiguration.businessOperationHours.wednesday.copyWith({
                        [event.target.name]:
                            (event.target.type === "checkbox" || event.target.type === "switch") ?
                                event.target.checked : event.target.value
                    })
                })
            });

            if (initialHour && endHour) {
                _configuration = setDate(initialHour, endHour, isSecondDate);
            }

            onUpdate(_configuration);
        };

        const setDate = (initialDate: string, endDate: string, isSecondDate: boolean): CompanyConfigurationEntity => {
            return companyConfiguration.copyWith({

                businessOperationHours: companyConfiguration.businessOperationHours.copyWith({

                    wednesday: companyConfiguration.businessOperationHours.wednesday.copyWith({
                        initialFirstHour: isSecondDate ? companyConfiguration.businessOperationHours.wednesday.initialFirstHour : initialDate,
                        endFirstHour: isSecondDate ? companyConfiguration.businessOperationHours.wednesday.endFirstHour : endDate,
                        initialSecondHour: isSecondDate ? initialDate : companyConfiguration.businessOperationHours.wednesday.initialSecondHour,
                        endSecondHour: isSecondDate ? endDate : companyConfiguration.businessOperationHours.wednesday.endSecondHour,
                    })
                })
            })

        }

        const {errorWednesday} = useValidatorHours(companyConfiguration.businessOperationHours);

        return (
            <div>
                <ContainerHourAndTimeRange>
                    <ContentHourAndTimeRange>
                        <CheckBoxWithLabel
                            label={
                                companyConfiguration.businessOperationHours.wednesday.open ?
                                    `Quarta-feira (${companyConfiguration.businessOperationHours.wednesday.initialFirstHour ?? '09:00'} - ${companyConfiguration.businessOperationHours.wednesday.endFirstHour ?? '18:00'})`
                                    :
                                    `Quarta-feira (Fechado o dia inteiro)`}
                            checked={companyConfiguration.businessOperationHours.wednesday.open ?? false}
                            marginLeft={10}
                            disabled={loading}
                            onChange={(event) => {
                                handleAllChanges({
                                    target:
                                        {
                                            name: 'open',
                                            value: event.target.value,
                                            checked: event.target.checked,
                                            type: 'switch'
                                        }
                                });

                            }}
                        />
                    </ContentHourAndTimeRange>
                    {companyConfiguration.businessOperationHours.wednesday.open && <ContainerInputRange>
                        <ContentInputRange>
                            < SliderTimeRange
                                disabled={loading}
                                value={{
                                    start: companyConfiguration.businessOperationHours.wednesday.initialFirstHour ?? '09:00',
                                    end: companyConfiguration.businessOperationHours.wednesday.endFirstHour ?? '18:00'
                                }}
                                onChange={({start, end}) => {
                                    handleAllChanges({
                                        target:
                                            {
                                                name: 'date',
                                                value: start,
                                                checked: false,
                                                type: 'input'
                                            }
                                    }, start, end, false);
                                }}
                            />
                        </ContentInputRange>
                        {companyConfiguration.businessOperationHours.usesTwoOperationHours &&
                            <ContainerSecondTimeRange>
                                <TextAs color={colors.text}>até as</TextAs>
                                <ContentSecondTimeRange style={{marginTop: -40}}>
                                    <ContentSecondHourAndTimeRange>
                                        <TextSecondHour
                                            color={colors.text}>({companyConfiguration.businessOperationHours.wednesday.initialSecondHour} - {companyConfiguration.businessOperationHours.wednesday.endSecondHour})
                                            {!!errorWednesday && <TextError
                                                color={colors.textError}>{errorWednesday}</TextError>}</TextSecondHour>
                                    </ContentSecondHourAndTimeRange>
                                    <ContentInputRange>
                                        < SliderTimeRange
                                            disabled={loading}
                                            value={{
                                                start: companyConfiguration.businessOperationHours.wednesday.initialSecondHour ?? '09:00',
                                                end: companyConfiguration.businessOperationHours.wednesday.endSecondHour ?? '18:00'
                                            }}
                                            onChange={({start, end}) => {

                                                handleAllChanges({
                                                    target:
                                                        {
                                                            name: 'date',
                                                            value: start,
                                                            checked: false,
                                                            type: 'input'
                                                        }
                                                }, start, end, true);
                                            }}
                                        />
                                    </ContentInputRange>
                                </ContentSecondTimeRange>
                            </ContainerSecondTimeRange>}
                    </ContainerInputRange>
                    }
                </ContainerHourAndTimeRange>
            </div>
        )
    })
export default Wednesday;
