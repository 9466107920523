import {useEffect, useState} from "react";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {validateAddress} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/validate_address";

export function useValidatorInputAddress(address: AddressEntity) {

  const [errorMessageCity, setErrorMessageCity] = useState('');
  const [errorMessageNeighborhood, setErrorMessageNeighborhood] = useState('');
  const [errorMessageNumber, setErrorMessageNumber] = useState('');
  const [errorMessageShortState, setErrorMessageShortState] = useState('');
  const [errorMessageState, setErrorMessageState] = useState('');
  const [errorMessageStreet, setErrorMessageStreet] = useState('');
  const [errorMessageZipCode, setErrorMessageZipCode] = useState('');
  const [isValidDataFormAddress, setIsValidDataFormAddress] = useState<boolean>(false);

  useEffect(() => {
    validate();
  }, [address]);

  const validate = () => {
    validateAddress(address, setErrorMessageCity, setErrorMessageNeighborhood, setErrorMessageNumber, setErrorMessageShortState, setErrorMessageState,
      setErrorMessageStreet, setErrorMessageZipCode, setIsValidDataFormAddress);
  }

  return {
    errorMessageCity, setErrorMessageCity,
    errorMessageNeighborhood, setErrorMessageNeighborhood,
    errorMessageNumber, setErrorMessageNumber,
    errorMessageShortState, setErrorMessageShortState,
    errorMessageState, setErrorMessageState,
    errorMessageStreet, setErrorMessageStreet,
    errorMessageZipCode,
    isValidDataFormAddress, setIsValidDataFormAddress,
    validate,
  }
};
