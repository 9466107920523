import {observer} from "mobx-react";
import React, {useContext} from "react";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import {Popover, Tooltip} from "antd";
import {ButtonUndo, ContainerAction, ContainerInfo} from "./styled";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {useBetween} from "use-between";
import {useModalImportClients} from "@dropDesk/presentation/pages/client/ui/import/search/modals/visible_modal_search";
import {Progress} from "@dropDesk/presentation/components/loadings/progress_bar";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


const TableImports = observer(
    ({
         loading,
         tableImports,
         getDataFromPage,
         idRevert
     }: {
        loading: boolean,
        tableImports: ListPaginationEntity<ImportData>,
        getDataFromPage: (page: number) => void,
        idRevert: string | null
    }) => {

        const collapsed = useContext(MenuCollapsedContext);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const {setVisibleModalUndoImport, visibleModalUndoImport} = useBetween(useModalImportClients);


        const translationStatus = (status: StatusImport, revertStatus?: StatusImport) => {
            if (revertStatus === StatusImport.done) {
                return <span style={{color: colors.success}}>Desfeito com sucesso</span>
            } else if (revertStatus === StatusImport.inProgress) {
                return <span style={{color: colors.text}}>Desfazendo ...</span>
            } else if (revertStatus === StatusImport.stoppedWithError) {
                return <span style={{color: colors.textError}}>Erro ao desfazer</span>
            } else if (status === StatusImport.done) {
                return <span style={{color: colors.success}}>Importado com sucesso</span>
            } else if (status === StatusImport.inProgress) {
                return <span style={{color: colors.text}}>Importando ...</span>
            } else {
                return <span style={{color: colors.textError}}>Erro ao importar</span>
            }
        }


        const tableColumn = [
            {
                title: 'Importado em',
                ellipsis: true,
                dataIndex: 'createdAt',
                render: (text: string, record: ImportData) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },

            {
                title: 'Status', dataIndex: 'status', ellipsis: true, render: (text: string, record: ImportData) =>
                    translationStatus(record.status, record.revertStatus)
            },
            {
                title: 'Total de empresas',
                ellipsis: true,
                dataIndex: 'rows',
                render: (text: string, record: ImportData) => (
                    <span>{text}</span>
                ),
            },

            {
                title: '', key: 'importId', width: 125, render: (text: string, record: ImportData) => (
                    <ContainerAction>


                        {
                            record.status === StatusImport.done &&
                            !record.revertStatus &&
                            idRevert === null &&
                            <Tooltip title={'Desfazer importação'} placement="left">
                                <ButtonUndo
                                    background={colors.textError}
                                    color={SharedColors.white}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setVisibleModalUndoImport({visible: true, id: record.importId});
                                    }}
                                >
                                    Desfazer
                                </ButtonUndo>
                            </Tooltip>

                        }

                        {record.revertStatus === StatusImport.inProgress &&
                            <Progress progress={record.revertProgress ?? 0} width={35} type={'circle'} size={'small'}/>
                        }

                        {record.revertStatus === StatusImport.done &&
                            <Popover
                                placement="left"
                                trigger="click"
                                color={colors.backgroundInput}
                                title=""
                                content={
                                    <ContainerInfo color={colors.text}
                                                   background={colors.onBackground}>
                                        Essa importação foi desfeita
                                        em {displayDateToLocale(record.revertedAt ?? '')} com o total
                                        de {record.revertedRows ?? 0} empresas removidas.
                                    </ContainerInfo>
                                }>
                                <ButtonUndo
                                    background={colors.accent}
                                    color={SharedColors.white}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                >
                                    Informações
                                </ButtonUndo>
                            </Popover>
                        }
                    </ContainerAction>
                ),
            },
        ];


        return (
            <TableGeneric<ImportData>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableImports.data}
                onSelectedRow={(importation: ImportData) => {

                }}
                rowKey={'importId'}
                loading={loading}
                labelNoResults={'Nenhuma importação encontrado na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableImports.totalRows}
                rowSelection={null}
                page={tableImports.page}
                pageSize={tableImports.limit}
                onChange={(page) => getDataFromPage(page - 1)}
            />
        )
    })
export default TableImports;
