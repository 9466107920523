export type IconPropsTypes = {
    icon: string;
    size?: number;
    color?: string;
    hoverColor?: string;
    useDarkenInHover?: boolean;
    onClick?: () => void;
    style?: React.CSSProperties;
    removeColor?: boolean;
}

export const ICONS_DROPDESK = {
    megaphone: "megaphone",
    configurations: "configurations",
    person: "person",
    camera: "camera",
    send: "send",
    next: "next",
    settings: "settings",
    menu: "menu",
    menuOutline: "menu-outline",
    headset: "headset",
    account: "account",
    eyeClosed: "eye-closed",
    home: "home",
    search: "search",
    eye: "eye",
    close: "close",
    notepad: "notepad",
    pieChart: "pie-chart",
    noMessage: "no-message",
    emptyFolder: "empty-folder",
    lastMonth: "last-month",
    lastWeek: "last-week",
    yesterday: "yesterday",
    calendar: "calendar",
    today: "today",
    calendarCustom: "calendar-custom",
    theme: "theme",
    image: "image",
    business: "business",
    bellOutlined: "bell-outlined",
    whatsapp: "whatsapp",
    sitemap: "sitemap",
    operator: "operator",
    customer: "customer",
    delete: "delete",
    threeDots: "three-dots",
    threeDotsFill: "three-dots-fill",
    error: "error",
    cameraFilled: "camera-filled",
    users: "users",
    form: "form",
    location: "location",
    restore: "restore",
    stretch: "stretch",
    reduce: "reduce",
    growth: "growth",
    review: "review",
    bulbOff: "bulb-off",
    bulbOn: "bulb-on",
    socialNetwork: "social-network",
    logout: "logout",
    schedule: "schedule",
    staticProblem: "static-problem",
    responses: "responses",
    dashboard: "dashboard",
    padlock: "padlock",
    arrowLeft: "arrow-left",
    add: "add",
    microphone: "microphone",
    warning: "warning",
    setting: "setting",
    mail: "mail",
    cancel: "cancel",
    cancelOutline: "cancel-outline",
    plusFill: "plus-fill",
    chatting: "chatting",
    downArrow: "down-arrow",
    download: "download",
    atSign: "at-sign",
    edit: "edit",
    paperclip: "paperclip",
    copy: "copy",
    numbers: "numbers",
    star: "star",
    starOutline: "star-outline",
    starHalf: "star-half",
    happyFaceOutline: "happy-face-outline",
    happyFace: "happy-face",
    interrogationOutline: "interrogation-outline",
    interrogation: "interrogation",
    excel: "excel",
    pdf: "pdf",
    check: "check",
    working: "working",
    warningFill: "warning-fill",
    transfer: "transfer",
    mute: "mute",
    speaker: "speaker",
    upload: "upload",
    select: "select",
    stop: "stop",
    document: "document",
    documentOutline: "document-outline",
    doubleTick: "double-tick",
    tick: "tick",
    clock: "clock",
    sendFill: "send-fill",
    refresh: "refresh",
    block: "block",
    chat: "chat",
    neutral: "neutral",
    userChecked: "user-checked",
    thunder: "thunder",
    userCancel: "user-cancel",
    creditCard: "credit-card",
    pix: "pix",
    billet: "billet",
    dollar: "dollar",
    stretchFill: "stretch-fill",
    reduceFill: "reduce-fill",
    startFlag: "start-flag",
    info: "info",
    infoFill: "info-fill",
    timeline: "timeline",
    segment: "segment",
    subscription: "subscription",
    megaphoneHorizontal: "megaphone-horizontal",
    newUser: "new-user",
    smileyFill: "smiley-fill",
    alarmClock: "alarm-clock",
    filter: "filter",
}
