import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContainerDescriptions,
    ContentDescriptions,
    Text,
    TextBold,
} from "../styled";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import React from "react";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {observer} from "mobx-react";


const Body = observer((
    {
        valuePlanOnDemand,
        newSubscription,
        labelFirstPayment,
        totalFirstPayment,
        daysCharged

    }: {
        valuePlanOnDemand?: number;
        newSubscription: PaymentSubscriptionEntity
        labelFirstPayment?: string
        totalFirstPayment: number
        daysCharged?: number
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (

        <ContainerDescriptions>
            <ContentDescriptions>
                <TextBold size={14} color={colors.hintInverse}>
                    Recorrência do pagamento:
                </TextBold>
                <Text size={14} color={colors.text}>mensal</Text>
            </ContentDescriptions>

            <ContentDescriptions>
                <TextBold size={14} color={colors.hintInverse}>
                    Quantidade de atendentes:
                </TextBold>
                <Text size={14} color={colors.text}>{newSubscription.planUserMultiplier}</Text>
            </ContentDescriptions>

            <ContentDescriptions>
                <TextBold size={14} color={colors.hintInverse}>
                    Melhor dia para pagamento:
                </TextBold>
                <Text size={14} color={colors.text}>dia&nbsp;{newSubscription.dayDue}</Text>
            </ContentDescriptions>

            {valuePlanOnDemand && <ContentDescriptions>
                <TextBold size={14} color={colors.hintInverse}>
                    Valor por atendente:
                </TextBold>
                <Text size={14}
                      color={colors.text}>{formatCurrency(valuePlanOnDemand)}</Text>
            </ContentDescriptions>}

            {daysCharged && <ContentDescriptions>
                <TextBold size={14} color={colors.hintInverse}>
                    Dias faturado:
                </TextBold>
                <Text size={14}
                      color={colors.text}>{daysCharged}</Text>
            </ContentDescriptions>}


            <ContentDescriptions>
                <TextBold size={14} color={colors.hintInverse}>
                    {labelFirstPayment}
                </TextBold>
                <Text size={14} color={colors.text}>{formatCurrency(totalFirstPayment)}</Text>
            </ContentDescriptions>

        </ContainerDescriptions>
    );
});
export default Body;
