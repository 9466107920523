import {observer} from "mobx-react";
import React, {forwardRef} from "react";
import {ContentData, ContentDataLogin, ContentInputs, ContentSelectAndCheckBox, SubContentInputs} from "./styled";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import SelectDateAndTime from "@dropDesk/presentation/components/dates/select_date_and_time";
import {convertDateToFormatBR, convertSQLISOToDate} from "@dropDesk/utils/helpers/date_helper";
import {useValidatorInputNotices} from "@dropDesk/presentation/pages/notices/ui/form/inputs/validator";
import {useBetween} from "use-between";
import {useModalNotices} from "@dropDesk/presentation/pages/notices/ui/form/modals/handle_change_visible";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {destinyMap} from "@dropDesk/domain/entities/notices/notices_maps";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const InputsFormNotices = observer(
    ({
         onUpdate,
         notice,
         loading,
     }: {
         onUpdate: (notice: NoticesEntity) => void,
         notice?: NoticesEntity,
         loading: boolean,
     }
    ) => {

        const {
            validate,
            errorMessageUser,
            errorMessageClient,
            errorMessageDateStarted,
            errorMessageDateEnd,
            errorMessageDescription,
        } = useValidatorInputNotices(notice);

        const {
            setVisibleModalSearchClient,
            setVisibleModalSearchUser
        } = useBetween(useModalNotices);

        const resetUsersDestiny = (notice: NoticesEntity): NoticesEntity => {
            return notice.copyWith({
                idUser: null,
                idClient: null,
                user: null,
                client: null,
                replaceUserIfNull: true,
                replaceClientIfNull: true,
                replaceIdClientIfNull: true,
                replaceIdUserIfNull: true,
            })
        }

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string }
        }) => {

            const _notice = notice!.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,

                replaceDateStartedIfNull: event.target.name === "dateStarted",
                replaceDateEndIfNull: event.target.name === "dateEnd",
            });

            onUpdate(event.target.name === 'destiny' ? resetUsersDestiny(_notice) : _notice);

        };

        return (
            <ContentInputs>
                <SubContentInputs>
                    <ContentData>
                        <SelectSearchMain<NoticeDestiny>
                            required={true}
                            marginTop={10}
                            marginBottom={25}
                            size={35}
                            label={"Destinatário"}
                            options={destinyMap}
                            onChange={(value) => {

                                handleAllChanges(
                                    {
                                        target:
                                            {
                                                name: 'destiny',
                                                value: value,
                                                checked: false,
                                                type: 'input'
                                            }
                                    })
                            }}
                            value={notice?.destiny ?? '' as any}
                        />

                        {notice?.destiny === NoticeDestiny.client && <ContentSelectAndCheckBox>
                            <div style={{display: 'flex', flex: 1}}>
                                <InputWithBorder
                                    required={true}
                                    name='client'
                                    size={35}
                                    onClick={() => setVisibleModalSearchClient(true)}
                                    readOnly={true}
                                    disabled={loading ? true : false}
                                    placeHolder={"Selecione a empresa"}
                                    nameIconRight={ICONS_DROPDESK.downArrow}
                                    sizeRightIcons={14}
                                    marginRightRightIcons={10}
                                    messageError={errorMessageClient}
                                    label={"Empresa"}
                                    value={notice.client?.name ?? ''}
                                    onChange={handleAllChanges}
                                />
                            </div>

                        </ContentSelectAndCheckBox>}

                        {notice?.destiny === NoticeDestiny.user && <ContentSelectAndCheckBox>
                            <div style={{display: 'flex', flex: 1}}>
                                <InputWithBorder
                                    required={true}
                                    name='user'
                                    size={35}
                                    onClick={() => setVisibleModalSearchUser(true)}
                                    readOnly={true}
                                    disabled={loading ? true : false}
                                    placeHolder={"Selecione o atendente"}
                                    nameIconRight={ICONS_DROPDESK.downArrow}
                                    sizeRightIcons={14}
                                    marginRightRightIcons={10}
                                    messageError={errorMessageUser}
                                    label={"Atendente"}
                                    value={notice.user?.name ?? ''}
                                    onChange={handleAllChanges}
                                />
                            </div>
                        </ContentSelectAndCheckBox>}


                        <InputWithBorder
                            useEmojiPicker={true}
                            required={true}
                            name='description'
                            size={35}
                            disabled={loading}
                            placeHolder={"Descrição do aviso"}
                            messageError={errorMessageDescription}
                            label={"Descrição"}
                            value={notice?.description ?? ''}
                            onChange={handleAllChanges}
                        />

                        {notice?.hasTemporary &&
                            <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
                                <div style={{flex: 1, display: 'flex'}}>
                                    <SelectDateAndTime
                                        value={convertSQLISOToDate(notice.dateStarted)}
                                        placeHolder={'Selecione a data inicial'}
                                        label={'Data Inicial'}
                                        messageError={errorMessageDateStarted}
                                        size={35}
                                        required={true}
                                        minDateFromNow={true}
                                        onChange={(date) => {
                                            const dateToString = date ? date.toISOString() : null;
                                            handleAllChanges(
                                                {
                                                    target:
                                                        {
                                                            name: 'dateStarted',
                                                            value: dateToString,
                                                            checked: false,
                                                            type: 'input'
                                                        }
                                                })
                                        }}
                                    />
                                </div>
                                <div style={{flex: 1, display: 'flex', marginLeft: 10}}>
                                    <SelectDateAndTime
                                        value={convertSQLISOToDate(notice.dateEnd)}
                                        label={'Data Final'}
                                        placeHolder={'Selecione a data final'}
                                        messageError={errorMessageDateEnd}
                                        required={true}
                                        minDateFromNow={true}
                                        size={35}
                                        onChange={(date) => {

                                            const dateToString = date ? date.toISOString() : null;
                                            handleAllChanges(
                                                {
                                                    target:
                                                        {
                                                            name: 'dateEnd',
                                                            value: dateToString,
                                                            checked: false,
                                                            type: 'input'
                                                        }
                                                });
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </ContentData>
                </SubContentInputs>


                <ContentDataLogin>


                </ContentDataLogin>
            </ContentInputs>
        )
    })
export default InputsFormNotices;
