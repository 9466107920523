import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {SegmentsRemoteDataSource} from "@dropDesk/data/data_source/common/segments/segments.datasource";
import {SegmentsRepository} from "@dropDesk/domain/repositories/common/segments/segments.repository";
import {OptionType} from "@dropDesk/presentation/components/inputs/select_search_main/select_search";

@injectable()
export class SegmentsRepositoryImpl implements SegmentsRepository {
  private _dataSource: SegmentsRemoteDataSource;

  constructor(@inject(SegmentsRemoteDataSource) dataSource: SegmentsRemoteDataSource) {
    this._dataSource = dataSource;
  }

  public getSegments(): Promise<Array<OptionType<string>>> {
    try {
      return this._dataSource.getSegments();
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }

}
