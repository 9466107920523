import {observer} from "mobx-react";
import {Container, Content} from "./styled";
import React, {useEffect, useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {TextTab} from "@dropDesk/presentation/pages/company_configurations/ui/styled";
import Tabs from "@dropDesk/presentation/components/tabs/background_style";
import ReportTicketsRate
    from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui";
import Evaluation from "@dropDesk/presentation/pages/ticket/evaluation/ui";


export enum TabsEvaluation {
    default = 'default',
    aggregate = 'aggregate',
}

const ReportTicketEvaluationInit = observer(
    ({}: {}) => {

        useEffect(() => {

        }, []);

        const [tabsReportTicket, setTabsReportTicket] = useState<TabsEvaluation>(TabsEvaluation.default);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container>
                <Content background={colors.onBackground} border={colors.border}>
                    <Tabs
                        defaultActiveKey={tabsReportTicket}
                        withMarginInTabs={true}
                        destroyInactiveTabPane={false}
                        height={50}
                        onChange={(key) => setTabsReportTicket(key as TabsEvaluation)}
                        items={[
                            {
                                label:
                                    <TextTab>
                                        Avaliações
                                    </TextTab>,
                                key: TabsEvaluation.default,
                                children: <Evaluation/>,
                            },
                            {
                                label:
                                    <TextTab>
                                        Visão Geral
                                    </TextTab>,
                                key: TabsEvaluation.aggregate,
                                children: <ReportTicketsRate/>,
                            },
                        ]}
                    />

                </Content>
            </Container>
        )
    })
export default ReportTicketEvaluationInit;
