import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import {TicketEvaluationRepository} from "@dropDesk/domain/repositories/ticket/evaluation/ticket_evaluation.repository";

@injectable()
export class ListTicketEvaluationUseCase {
  private _repository: TicketEvaluationRepository;

  constructor(@inject(TicketEvaluationRepository) repository: TicketEvaluationRepository) {
    this._repository = repository;
  }

  public call(
    page: number,
    searchParam: string,
    limit: number,
    searchEvaluationValue: number | null,
  ): Promise<ListPaginationEntity<TicketEvaluationEntity>> {
    return this._repository.list(page, searchParam, limit,searchEvaluationValue);
  }
}

