import {observer} from "mobx-react";
import React from "react";
import {ContentData, ContentInput, ContentInputs, SubContentInputs} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {useValidatorInputSectors} from "@dropDesk/presentation/pages/sector/ui/form/inputs/validator";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const InputsFormSectors = observer(
    ({
         onUpdate,
         sector,
         loading,
     }: {
        onUpdate: (sector: SectorEntity) => void,
        sector?: SectorEntity,
        loading: boolean,
    }) => {

        const appController = useInjection(AppController);

        const {
            errorMessageName, errorMessageChatLabel
        } = useValidatorInputSectors(sector);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string }
        }) => {

            const _sector = sector!.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,

            });
            onUpdate(_sector);

        };

        return (
            <ContentInputs>
                <SubContentInputs>
                    <ContentData autoComplete='off'>
                        <InputWithBorder
                            autoFocus={true}
                            required={true}
                            name='name'
                            size={35}
                            disabled={loading}
                            messageError={errorMessageName}
                            label={"Nome"}
                            value={sector?.realName(appController.user!) ?? ''}
                            onChange={handleAllChanges}
                        />
                        <ContentInput>
                            <InputWithBorder
                                useEmojiPicker={true}
                                name='chatLabel'
                                size={35}
                                disabled={loading}
                                placeHolder={"Descrição amigável do setor para ser mostrado no whatsapp"}
                                label={"Nome amigável"}
                                value={sector?.chatLabel ?? ''}
                                messageError={errorMessageChatLabel}
                                onChange={handleAllChanges}
                            />
                        </ContentInput>
                        <ContentInput style={{marginBottom: 20}}>
                            <InputWithBorder
                                name='note'
                                size={35}
                                disabled={loading}
                                placeHolder={"Observação"}
                                label={"Observação"}
                                value={sector?.note ?? ''}
                                onChange={handleAllChanges}
                            />
                        </ContentInput>
                    </ContentData>
                </SubContentInputs>

            </ContentInputs>
        )
    })
export default InputsFormSectors;
