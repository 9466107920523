import styled from "styled-components";
import {TextType} from "@dropDesk/presentation/pages/select_role/ui/body/card/styled";

type BackgroundType = {
    background: string;
}
export const Container = styled.div<BackgroundType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${props => props.background};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
`;

export const ContainerRating = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  margin: 16px 0;
`;

export const ContentRating = styled.div`
  width: 360px;
  min-width: 360px;
  max-width: 360px;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 60px;
  min-height: 60px;
  align-items: center;
  justify-content: center;
`;

export const ContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100px;
  min-height: 100px;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;
