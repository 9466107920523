import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContentImage, Header, Image, TextBold,
} from "../styled";
import React from "react";
import logo from "@dropDesk/storage/images/logo_dropdesk_portrait.png";

const HeaderResumeValue = (
    {
        planName
    }: {
        planName: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Header>
            <ContentImage>
                <Image src={logo} alt={"Logo DropDesk"}/>
            </ContentImage>
            {/*<TextBold size={16} color={colors.text}>{planName}</TextBold>*/}
        </Header>
    );
}
export default HeaderResumeValue;
