import styled from "styled-components";

export type ContainerType = {
    background: string;
}

export type TextType = {
    color: string;
    size: number;
}

type IconType = {
    color: string;
    fontsize: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  border-radius: 7px;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 1px 1px 5px 5px;
  padding: 16px;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:not(:last-of-type) {
    margin: 0 10px 0 0;
  }
`;
