import HeaderModal from "../../headers/header_modal";
import {Container, Content} from "./styled";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import React, {useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import ModalSearchTicketDescriptionStatic
    from "@dropDesk/presentation/components/modals/modal_search_ticket_description_static";
import InputsProblemDescription
    from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/inputs_problem_description";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {removeMaskStaticTelephoneNumber} from "@dropDesk/utils/helpers/string_helper";
import {useValidatorInputTickets} from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/validator";
import {useBetween} from "use-between";
import {useModalCreateTicket} from "@dropDesk/presentation/pages/ticket/ui/form_create/modals/handle_change_visible";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalChangeProblemDescription = (
    {
        visible,
        onClose,
        useProblemStatic,
        handleChangeDescription,
        loading,
        ticket
    }: {
        visible: boolean
        loading: boolean
        useProblemStatic: boolean
        onClose: () => void
        handleChangeDescription: (description?: string, descriptionStatic?: TicketsDescriptionStaticEntity) => void
        ticket: TicketEntity
    }
) => {

    const [cloneTicket, setCloneTicket] = useState<TicketEntity>(new TicketEntity({
        ...ticket
    }));

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const handleAllChanges = (event: {
        target: { name: string; value: any; checked?: any; type: string }
    }) => {

        const _ticket = cloneTicket.copyWith({
            [event.target.name]:
                (event.target.type === "checkbox" || event.target.type === "switch") ?
                    event.target.checked : event.target.name === "telephone" ?
                        removeMaskStaticTelephoneNumber(event.target.value.replace(/\D/g, "")) :
                        event.target.value,

            replaceDateDueIfNull: event.target.name === "dateDue",
        });
        setCloneTicket(_ticket);

    };

    const {
        errorMessageDescription
    } = useValidatorInputTickets(cloneTicket);

    const {
        setVisibleModalTicketDescriptionStatic, visibleModalTicketDescriptionStatic
    } = useBetween(useModalCreateTicket);

    return (
        <ModalMain
            open={visible}
            onRequestClose={onClose}
            width={400}
            renderComponent={<Container background={colors.onBackground}>
                <HeaderModal
                    disabled={loading}
                    letter={"Alterar problema"}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={onClose}
                />
                <Content>
                    <InputsProblemDescription
                        ticket={cloneTicket}
                        useProblemStatic={useProblemStatic}
                        loading={loading}
                        handleAllChanges={handleAllChanges}
                        onOpenModalTicketDescriptionStatic={() => setVisibleModalTicketDescriptionStatic(true)}
                        errorMessageDescription={errorMessageDescription}
                        autoFocus={true}
                    />
                </Content>
                <FooterModal
                    disabled={loading || !!errorMessageDescription}
                    loading={loading}
                    type={"submit"}
                    letter={"Confirmar"}
                    onSubmit={() => {
                        if (useProblemStatic && cloneTicket.descriptionStatic) {
                            handleChangeDescription(undefined, cloneTicket.descriptionStatic);
                        } else {
                            handleChangeDescription(cloneTicket.description, undefined);
                        }
                    }}
                    onRequestClose={onClose}
                />
                {visibleModalTicketDescriptionStatic && <ModalSearchTicketDescriptionStatic
                    visible={visibleModalTicketDescriptionStatic}
                    onClose={() => setVisibleModalTicketDescriptionStatic(false)}
                    handleSelectTicketDescriptionStatic={(ticketsDescriptionStatic) => {
                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'descriptionStatic',
                                        value: ticketsDescriptionStatic,
                                        checked: false,
                                        type: 'input'
                                    }
                            })
                        setVisibleModalTicketDescriptionStatic(false);
                    }}
                    textHeaderModal={"Selecione o problema"}
                    labelNoResults={"Nenhum problema encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise pelo problema"}
                    useFooter={!!appController.user?.permissionAdmin}
                    labelFooter={'Novo problema'}
                />}
            </Container>
            }
        />
    );
}
export default ModalChangeProblemDescription;
