import {
    BackgroundFullScreenBlackNotification,
} from "./styled";
import {useEffect, useState} from "react";
import PanelNoticesNotification from "@dropDesk/presentation/components/headers/header/notices/panel_notices";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Badge} from "antd";
import {NoticesController} from "@dropDesk/presentation/pages/notices/controller/notices.controller";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const NoticesNotification = (observer(() => {
    const [focusedNotification, setFocusedNotification] = useState(false);
    const controller = useInjection(NoticesController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const extraHeight = appController.heightShowingHeaderInfo;

    const initialize = () => {
        controller.listNoticesByUser();
    }

    useEffect(() => {
        initialize();
        return () => {
            controller.dispose();
        }
    }, []);

    return (
        <>
            <Badge count={controller.tableNoticesByUser.totalRows} overflowCount={99} size="small"
                   offset={[-9, -4]}>
                <DropDeskIcon
                    onClick={() => {
                        setFocusedNotification(!focusedNotification);
                        controller.listNoticesByUser(true);
                    }}
                    size={22}
                    icon={ICONS_DROPDESK.megaphoneHorizontal}
                    color={colors.text}
                    style={{cursor: 'pointer'}}
                />
            </Badge>
            {!!focusedNotification && <PanelNoticesNotification
                loading={controller.loading}
                tableNotices={controller.tableNoticesByUser}
                getDataFromPage={controller.getDataFromPageByUser}
            />}
            {!!focusedNotification && (
                <BackgroundFullScreenBlackNotification
                    bordercolor={colors.border}
                    onClick={() => setFocusedNotification(!focusedNotification)}
                    extraHeight={extraHeight}
                />
            )}
        </>
    );
}));
export default NoticesNotification;
