import React, {useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ButtonArrowMenu,
    Container,
    PopOver,
} from "@dropDesk/presentation/components/messages/message_box/arrow_menu_popover/styled";
import './override_styles_arrow_menu.css';
import {ContentArrowDown} from "@dropDesk/presentation/components/messages/message_box/styled";
import InputReactMessage from "@dropDesk/presentation/components/messages/message_box/react_message/input";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import FullScreenOverlay from "@dropDesk/presentation/components/overlay/full_screen_overlay";
import ModalsArrowMenuPopover from "@dropDesk/presentation/components/messages/message_box/arrow_menu_popover/modals";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const ArrowMenuPopover = (
    {
        position,
        isDarkTheme,
        onClickReply,
        canReply,
        canEdit,
        canDelete,
        canReact,
        lastUsedReactions,
        handleAddReaction,
        message,
        userClient,
        handleDeleteMessage,
        handleEditMessage,
        color,
        reactions,
        attendant,
        userLogged
    }: {
        position: 'left' | 'right'
        isDarkTheme: boolean
        onClickReply: () => void
        canReply: boolean;
        canEdit: boolean;
        canDelete: boolean;
        canReact: boolean;
        lastUsedReactions: string[]
        handleAddReaction: (reaction: string) => Promise<void>
        message: TicketMessageEntity
        userClient: UserEntity
        handleDeleteMessage: (message: TicketMessageEntity) => void
        handleEditMessage: (editText: string, currentMessage: TicketMessageEntity) => void
        color: string
        reactions: TicketMessageEntity[]
        attendant?: UserEntity,
        userLogged: UserEntity
    }
) => {
    const [visiblePopover, setVisiblePopover] = useState<boolean>(false);
    const [visibleModalDeleteMessage, setVisibleModalDeleteMessage] = useState<{
        visible: boolean,
        message: TicketMessageEntity | null
    }>({
        visible: false,
        message: null
    });
    const [visibleModalEditMessage, setVisibleModalEditMessage] = useState<{
        visible: boolean,
        message: TicketMessageEntity | null
    }>({visible: false, message: null});

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const handleClickChange = (open: boolean) => {
        setVisiblePopover(open);
    };

    const items: { key: string, label: React.ReactNode, visible: boolean }[] = [
        {
            key: '1',
            label: (
                <ButtonArrowMenu
                    onClick={() => {
                        handleClickChange(false);
                        onClickReply();
                    }}
                    color={colors.text}
                    accent={colors.accent}
                >
                    Responder
                </ButtonArrowMenu>
            ),
            visible: canReply

        },
        {
            key: '2',
            label: (
                <InputReactMessage
                    position={position}
                    isDarkTheme={isDarkTheme}
                    handleAddReaction={(reaction) => handleAddReaction(reaction)}
                    lastUsedReactions={lastUsedReactions}
                    reactions={reactions}
                    userLoggedId={userLogged.id}

                    reactComponent={
                        <ButtonArrowMenu
                            onClick={() => {
                                handleClickChange(false);
                            }}
                            color={colors.text}
                            accent={colors.accent}
                        >
                            Reagir
                        </ButtonArrowMenu>
                    }
                />

            ),
            visible: canReact
        },
        {
            key: '3',
            label: (
                <ButtonArrowMenu
                    onClick={() => {
                        handleClickChange(false);
                        setVisibleModalEditMessage({visible: true, message});
                    }}
                    color={colors.text}
                    accent={colors.accent}
                >
                    Editar
                </ButtonArrowMenu>
            ),
            visible: canEdit,
        },
        {
            key: '4',
            label: (
                <ButtonArrowMenu
                    onClick={() => {
                        handleClickChange(false);
                        setVisibleModalDeleteMessage({visible: true, message});
                    }}
                    color={colors.text}
                    accent={colors.accent}
                >
                    Deletar
                </ButtonArrowMenu>
            ),
            visible: canDelete
        },
    ];


    return (
        <PopOver
            color={colors.backgroundInput}
            style={{padding: 0, margin: 0}}
            position={position}
            marginSize={35}
            placement={'bottomLeft'}
            trigger={["click"]}
            title=""
            showArrow={false}
            open={visiblePopover}
            content={
                <>
                    <Container
                        isDarkTheme={isDarkTheme}
                        background={colors.backgroundInput}
                        hint={colors.hint}
                    >
                        {items.filter((item) => item.visible === true)
                            .map((item) => <div style={{position: 'relative'}} key={item.key}>{item.label}</div>)}
                    </Container>
                    {visiblePopover && <FullScreenOverlay onClick={() => handleClickChange(false)}/>}
                    <ModalsArrowMenuPopover
                        userClient={userClient}
                        handleDeleteMessage={handleDeleteMessage}
                        handleEditMessage={handleEditMessage}
                        visibleModalDeleteMessage={visibleModalDeleteMessage}
                        visibleModalEditMessage={visibleModalEditMessage}
                        setVisibleModalDeleteMessage={setVisibleModalDeleteMessage}
                        setVisibleModalEditMessage={setVisibleModalEditMessage}
                        attendant={attendant}
                        userLogged={userLogged}
                    />
                </>
            }>
            <ContentArrowDown onClick={() => handleClickChange(true)}>
                <DropDeskIcon icon={ICONS_DROPDESK.downArrow} color={color}
                />
            </ContentArrowDown>
        </PopOver>
    )
}

export default ArrowMenuPopover;
