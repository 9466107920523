import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {
    PaymentSubscriptionEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";

@injectable()
export class PaySubscriptionUseCase {
    private _repository: SubscriptionRepository;

    constructor(@inject(SubscriptionRepository) repository: SubscriptionRepository) {
        this._repository = repository;
    }

    public call(subscription: PaymentSubscriptionEntity, paymentMethod?: PaymentMethodEntity): Promise<PaymentSubscriptionEntity> {
        return this._repository.pay(subscription, paymentMethod);
    }
}

