import {observer} from "mobx-react";
import React from "react";
import ModalLogoutByAdmin from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {
    useModalConnectionsActive
} from "@dropDesk/presentation/pages/company_active_connections/search/modals/handle_change_visible";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const ModalsActiveConnections = observer(
    ({
         logoutByAdmin,
         loading,
     }: {
        logoutByAdmin: () => Promise<void>,
        loading: boolean
    }) => {

        const {
            visibleModalLogoutByAdmin,
            setVisibleModalLogoutByAdmin
        } = useBetween(useModalConnectionsActive);

        return (
            <>

                <ModalLogoutByAdmin
                    open={visibleModalLogoutByAdmin}
                    onRequestClose={() => {
                        setVisibleModalLogoutByAdmin(false);
                    }}
                    textHeaderModal={'Desconectar atendente'}
                    textButton={'Desconectar'}
                    onRequestSave={() => {
                        logoutByAdmin();
                        setVisibleModalLogoutByAdmin(false);
                    }}
                    nameIcon={ICONS_DROPDESK.userCancel}
                    sizeIcon={130}
                    textInformation={
                        `Ao remover a conexão deste usuário, o mesmo será desconectado forçadamente do sistema.`
                    }
                    loading={loading}
                    isActionDelete={true}
                />
            </>
        )
    })
export default ModalsActiveConnections;
