import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {Observable} from "rxjs";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";

@injectable()
export class SubscribeChatConnectionUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(): Observable<ChatIntegrationConnectionEntity> {
        return this._repository.subscribeConnection();
    }
}

