import styled from "styled-components";
import {ButtonType, LabelInputType} from "@dropDesk/presentation/components/inputs/input_with_border/input_with_border";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


export const ButtonRight = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.letterColor};
  font-size: 11px;
  padding: 0 5px 2px 5px;
  height: 17px;
  min-height: 17px;
  border-radius: 5px;
  border: 0;
  flex-direction: row;
  border: 1px solid ${props => props.border};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:disabled {
    filter: brightness(80%);
  }
`;
