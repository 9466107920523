import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export class TaskEntity {
    id!: string;
    idCompany!: string;
    description!: string;
    done!: boolean;
    idUser!: string;
    doneAt?: string;
    createdAt?: string;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    constructor({
                    id,
                    idCompany,
                    description,
                    done,
                    idUser,
                    doneAt,
                    createdAt,
                }: {
        id: string;
        idCompany: string;
        description: string;
        done: boolean;
        idUser: string;
        doneAt?: string;
        createdAt?: string;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            description,
            done,
            idUser,
            doneAt,
            createdAt,
        });
    }

    copyWith({
                 id,
                 idCompany,
                 description,
                 done,
                 idUser,
                 doneAt,
                 createdAt,
             }: {
        id?: string;
        idCompany?: string;
        description?: string;
        done?: boolean;
        idUser?: string;
        doneAt?: string;
        createdAt?: string;

    }): TaskEntity {
        return new TaskEntity({

            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            description: description ?? this.description,
            done: done ?? this.done,
            idUser: idUser ?? this.idUser,
            doneAt: doneAt ?? this.doneAt,
            createdAt: createdAt ?? this.createdAt,
        })
    }
}
