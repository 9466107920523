import {observer} from "mobx-react";
import React from "react";
import {Box, ContainerPermission, ContentDataLogin, ContentPermission, TextPermission, TextTitle} from "./styled";
import Switch from "@dropDesk/presentation/components/switch";
import {useModalUsers} from "@dropDesk/presentation/pages/user/ui/form/modals/handle_change_visible";
import {useBetween} from "use-between";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const Permissions = observer(
    ({
         changePermissionAdmin,
         user,
         disableOnChangeCheckBox,
         handleAllChanges
     }: {
        changePermissionAdmin: (value: boolean) => void,
        user?: UserEntity,
        disableOnChangeCheckBox: boolean,
        handleAllChanges: (event: {
            target: { name: string; value: any; checked: any; type: string };
        }) => void
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            setVisibleModalAdmin,
            setVisibleModalClients,
            setVisibleModalUsers,
            setVisibleModalSectors,
            setVisibleModalWarnings,
            setVisibleModalCancelTickets,
            setVisibleModalReports,
            setVisibleModalDeleteData,
            setVisibleModalListJustTicket
        } = useBetween(useModalUsers);


        return (
            <ContentDataLogin>
                <TextTitle color={colors.text}>Configuração de Permissões</TextTitle>
                <ContainerPermission>
                    <Box>
                        <ContentPermission>
                            {user && <Switch
                                disabled={user.userMain ?? false}
                                id={'checkboxadmin'}
                                name={'checkboxadmin'}
                                checked={user.permissionAdmin ?? false}
                                onChange={() => user.permissionAdmin ? changePermissionAdmin(false) : setVisibleModalAdmin(true)}
                            />}
                            <TextPermission color={colors.text}>Administrador</TextPermission>
                        </ContentPermission>
                        <ContentPermission>
                            {user && <Switch
                                disabled={disableOnChangeCheckBox}
                                id={'permissionScreenClients'}
                                name={'permissionScreenClients'}
                                checked={user.permissionScreenClients ?? false}
                                onChange={(event) => user.permissionScreenClients ? handleAllChanges(event) : setVisibleModalClients(true)}
                            />}
                            <TextPermission color={colors.text}>Menu Empresas</TextPermission>
                        </ContentPermission>
                        <ContentPermission>
                            {user && <Switch
                                disabled={disableOnChangeCheckBox}
                                id={'permissionScreenUsers'}
                                name={'permissionScreenUsers'}
                                checked={user.permissionScreenUsers ?? false}
                                onChange={(event) => user.permissionScreenUsers ? handleAllChanges(event) : setVisibleModalUsers(true)}
                            />}
                            <TextPermission color={colors.text}>Menu Atendentes</TextPermission>
                        </ContentPermission>
                    </Box>
                    <Box>
                        <ContentPermission>
                            {user && <Switch
                                disabled={disableOnChangeCheckBox}
                                id={'permissionScreenSectors'}
                                name={'permissionScreenSectors'}
                                checked={user.permissionScreenSectors ?? false}
                                onChange={(event) => user.permissionScreenSectors ? handleAllChanges(event) : setVisibleModalSectors(true)}
                            />}
                            <TextPermission color={colors.text}>Menu Setores</TextPermission>
                        </ContentPermission>


                        <ContentPermission>
                            {user && <Switch
                                disabled={disableOnChangeCheckBox}
                                id={'permissionScreenWarnings'}
                                name={'permissionScreenWarnings'}
                                checked={user.permissionScreenWarnings ?? false}
                                onChange={(event) => user.permissionScreenWarnings ? handleAllChanges(event) : setVisibleModalWarnings(true)}
                            />}
                            <TextPermission color={colors.text}>Menu Avisos</TextPermission>
                        </ContentPermission>
                        <ContentPermission>
                            {user && <Switch
                                disabled={disableOnChangeCheckBox}
                                id={'permissionScreenReports'}
                                name={'permissionScreenReports'}
                                checked={user.permissionScreenReports ?? false}
                                onChange={(event) => user.permissionScreenReports ? handleAllChanges(event) : setVisibleModalReports(true)}
                            />}
                            <TextPermission color={colors.text}>Menu Relatórios</TextPermission>
                        </ContentPermission>

                    </Box>
                    <Box>
                        <ContentPermission>
                            {user && <Switch
                                disabled={disableOnChangeCheckBox}
                                id={'permissionDeleteData'}
                                name={'permissionDeleteData'}
                                checked={user.permissionDeleteData ?? false}
                                onChange={(event) => user.permissionDeleteData ? handleAllChanges(event) : setVisibleModalDeleteData(true)}
                            />}
                            <TextPermission color={colors.text}>Excluir dados</TextPermission>
                        </ContentPermission>
                        <ContentPermission>
                            {user && <Switch
                                disabled={disableOnChangeCheckBox}
                                id={'permissionCancelTicket'}
                                name={'permissionCancelTicket'}
                                checked={user.permissionCancelTicket ?? false}
                                onChange={(event) => user.permissionCancelTicket ? handleAllChanges : setVisibleModalCancelTickets(true)}
                            />}
                            <TextPermission color={colors.text}>Cancelar chamados</TextPermission>
                        </ContentPermission>
                        <ContentPermission>
                            {user && <Switch
                                disabled={disableOnChangeCheckBox}
                                id={'extraInfo.permissionJustListUserTicket'}
                                name={'extraInfo.permissionJustListUserTicket'}
                                checked={user.extraInfo?.permissionJustListUserTicket ?? false}
                                onChange={(event) => user.extraInfo?.permissionJustListUserTicket ? handleAllChanges(event) : setVisibleModalListJustTicket(true)}
                            />}
                            <TextPermission color={colors.text}> Acesso limitado aos próprios
                                chamados</TextPermission>
                        </ContentPermission>
                    </Box>
                </ContainerPermission>
            </ContentDataLogin>
        )
    })
export default Permissions;
