import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import DateRangePicker from "@dropDesk/presentation/components/dates/select_range_date/create_range_date_picker";


type ContainerType = {
    marginTop: number;
}
type ButtonType = {
    color: string;
    background: string;
    border: string;
    accent: string;
    width: number;
}
type LabelInputType = {
    color: string;
}
type DatePickerType = {
    color: string;
    focuscolor: string;
    placeholdercolor: string;
    background: string;
    shadowcolor: string;
    backgroundmainbutton: string;
    hint: string;
    border: string;
    accent: string;
}

export const DateRangePickerStyled = styled(DateRangePicker)<DatePickerType>`
  .ant-picker-input > input {
    color: ${(props) => props.color};
    font-size: 14px;

    &::placeholder {
      color: ${(props) => props.placeholdercolor};
    }
  }

  a {
    color: ${(props) => props.backgroundmainbutton};
  }

  .ant-picker-cell-in-view {
    color: ${(props) => props.color} !important;
  }

  .ant-picker-cell {
    color: ${props => props.hint};
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-suffix {
    color: ${(props) => props.hint};
  }

  .ant-picker-separator {
    color: ${(props) => props.color};
  }

  .ant-picker-clear {
    background: ${(props) => props.background};
    color: ${(props) => props.color};
  }

  .ant-picker-panel {
    border-color: ${(props) => props.color};
  }

  .ant-picker-header {
    border-color: ${(props) => props.color};
  }

  .ant-picker-datetime-panel {
    border-color: ${(props) => props.color};
  }

  .ant-picker-time-panel {
    display: none;
    border-color: ${(props) => props.color};
  }

  .ant-picker-panel .ant-picker-footer {
    border-top-color: ${(props) => props.color};
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    color: ${(props) => props.color};
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  // td.ant-picker-cell.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start.ant-picker-cell-selected + td::after {
    //   border-color: ${(props) => props.backgroundmainbutton} !important;
  // }

  .ant-picker-header-view button:hover {
    color: ${(props) => props.color} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: ${(props) => props.backgroundmainbutton} !important;
  }

  .ant-picker-footer-extra {
    padding: 0px !important;
  }

  .ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.color} !important;
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-color: ${(props) => props.backgroundmainbutton} !important;
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-color: ${(props) => props.backgroundmainbutton} !important;
  }

  .ant-picker-time-panel-column:not(:first-child) {
    border-color: ${(props) => props.color};
  }

  .ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    display: none;
  }

  .ant-picker-panel-container {
    background: ${(props) => props.background} !important;
    border: 1px solid ${props => props.border};

    box-shadow: 0 3px 6px -4px ${(props) => hexToRGBA(props.shadowcolor, 0.1)},
    0 6px 16px 0 ${(props) => hexToRGBA(props.shadowcolor, 0.1)},
    0 9px 28px 8px ${(props) => hexToRGBA(props.shadowcolor, 0.1)} !important;

    .ant-picker-header {
      color: ${(props) => props.color} !important;
    }

    .ant-picker-header-super-prev-btn {
      color: ${(props) => props.color} !important;
    }

    .ant-picker-header-prev-btn {
      color: ${(props) => props.color} !important;
    }

    .ant-picker-header-super-next-btn {
      color: ${(props) => props.color} !important;
    }

    .ant-picker-header-next-btn {
      color: ${(props) => props.color} !important;
    }

  }


  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
    border-color: ${(props) => props.backgroundmainbutton} !important;
  }


  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
    border-color: ${(props) => props.backgroundmainbutton} !important;
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: ${props => hexToRGBA(props.backgroundmainbutton, 0.5)} !important;
    color: ${(props) => props.background} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
    background: ${props => hexToRGBA(props.backgroundmainbutton, 0.6)} !important;
    color: ${(props) => props.background} !important;
  }

  &.ant-picker-range .ant-picker-active-bar {
    background: ${(props) => props.accent} !important;
    bottom: 3px !important;
  }

  .ant-picker-range-arrow::before {
    background: ${(props) => props.background} !important;
  }

  .ant-btn-primary {
    background-color: ${(props) => props.backgroundmainbutton} !important;
    border-color: ${(props) => props.backgroundmainbutton} !important;
    color: ${(props) => props.background} !important;

    &:disabled {
      color: ${(props) => props.background} !important;
    }
  }

  .ant-picker-content th {
    color: ${(props) => props.color} !important;
  }

  .ant-picker-cell-disabled {
    color: ${SharedColors.disabled} !important;
  }

  .ant-picker-cell-disabled::before {
    background: ${props => hexToRGBA(props.hint, 0.5)} !important;
  }

}


&.ant-picker-focused {
  box-shadow: none !important;
}


`;
export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => `${props.marginTop}px`};
  min-width: 100%;
  position: relative;
`;
export const Label = styled.span<LabelInputType>`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.color};
  margin: 0 0 1px 2px;
`;
export const ButtonPickerDate = styled.button<ButtonType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  height: 24px;
  padding: 3px;
  font-size: 11px;
  border-radius: 2px;
  cursor: pointer;
  color: ${props => props.color};
  margin-right: 5px;
  width: ${(props) => `${props.width}px`};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const ContainerButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 0;
  overflow: hidden;
  height: 78px;
  text-align: left;
  list-style: none;
`;
export const ContentButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0px 4px 4px 4px;
`;
export const ContentButtonsUp = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 4px 4px 0px 4px;
`;

