import styled from "styled-components";

type ButtonType = {
    background: string;
    letterColor: string;
};

type ContainerType = {
    background: string;
    border: string;

};

type TextType = {
    color: string;
}
type PickerType = {
    background: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  overflow: hidden;
  flex: 1;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
  min-width: 600px;
  border: 1px solid ${props => props.border};
`;
export const ContainerPermission = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 1;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentDarkOrLightTheme = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
  min-width: 200px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const TextTheme = styled.span<TextType>`
  font-size: 15px;
  color: ${(props) => props.color};
`;

export const TextLogo = styled.span<TextType>`
  font-size: 13px;
  margin-top: 10px;
  color: ${(props) => props.color};
`;

export const Button = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.letterColor};
  font-size: 16px;
  font-weight: bold;
  width: 110px;
  height: 35px;
  min-height: 35px;
  border-radius: 5px;
  letter-spacing: 1px;
  border: 0;

  &:hover {
    filter: brightness(80%);
  }
`;
export const ContentButtonReset = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: flex-start;
`;
export const ContainerSelectLogo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ContentImage = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
`;
