import {observer} from "mobx-react";
import React from "react";
import ModalRestore from "@dropDesk/presentation/components/modals/modal_information";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {
    useModalSearchResponseStandard
} from "@dropDesk/presentation/pages/response_standard/modals/handle_change_visible";
import ModalCrudResponseStandard from "@dropDesk/presentation/pages/response_standard/modals/modal_crud";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsResponseStandard = observer(
    ({
         responseStandard,
         onUpdate,
         restoreResponseStandard,
         deleteResponseStandard,
         removeLineSelectRow,
         loading,
         handleVisibleModalNewResponseStandard,
         set
     }: {
        responseStandard?: ResponseStandardEntity
        onUpdate: (responseStandard: ResponseStandardEntity) => void,
        restoreResponseStandard: () => Promise<void>,
        deleteResponseStandard: () => Promise<void>,
        removeLineSelectRow: () => void,
        loading: boolean,
        handleVisibleModalNewResponseStandard: (visible: boolean, id: string) => void,
        set: (onSuccess: (key: string) => void) => Promise<void>
    }) => {

        const {
            visibleModalDelete,
            setVisibleModalDelete,
            visibleModalRestore,
            setVisibleModalRestore,
        } = useBetween(useModalSearchResponseStandard);

        return (
            <>
                <ModalRestore
                    open={visibleModalRestore}
                    onRequestClose={() => {
                        setVisibleModalRestore(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Restaurar Resposta'}
                    textButton={'Restaurar'}
                    onRequestSave={async () => {
                        restoreResponseStandard();
                        setVisibleModalRestore(false);
                    }}
                    nameIcon={ICONS_DROPDESK.restore}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a restauração? Após a restauração a resposta passa a ser listada novamente.`
                    }
                    loading={loading}
                />
                <ModalDelete
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        setVisibleModalDelete(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Excluir Resposta'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        deleteResponseStandard();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a exclusão da resposta rápida?`
                    }
                    loading={loading}
                    isActionDelete={true}
                />
                <ModalCrudResponseStandard
                    onUpdate={onUpdate}
                    responseStandard={responseStandard}
                    loading={loading}
                    handleVisibleModalNewResponseStandard={handleVisibleModalNewResponseStandard}
                    set={set}
                />

            </>
        )
    })
export default ModalsResponseStandard;
