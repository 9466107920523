import styled from "styled-components";

import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {
    ContainerButtonHeaderType
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/addresses";

type BorderType = {
    borderColor: string;
};

type IconOpsType = {
    background: string;
    color: string;
};

export const ContentData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContentDataInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtonNewAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
`;

export const ContentButtonNewAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
`;
export const ContainerDataInputs = styled.div<BorderType>`
  display: flex;
  border: 1px solid ${(props) => props.borderColor};
  padding: 16px;
  border-radius: 5px;
  flex-direction: column;
  margin: 15px 0;
`;
export const ContainerListAddress = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const ContentButtonDelete = styled.div`
  display: flex;
  height: 20px;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-top: -5px;
`;

export const Icon = styled.i<IconOpsType>`
  color: ${props => props.color};
  font-size: 19px;
  padding: 2px 0;
  cursor: pointer;
  background: ${(props) => props.background};
  border-radius: 3px;

  &:hover {
    filter: brightness(80%);
  }

`;

export const ContainerButtonHeader = styled.div<ContainerButtonHeaderType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  margin: 0 2px;
  background: ${props => props.background};


  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;


