import styled from "styled-components";

type TextType = {
    color: string;
}

export const ContainerLocalizationMessage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnchorLocalizationMessage = styled.a`
  position: relative;
  width: 250px;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

export const ContentCaption = styled.span<TextType>`
  color: ${props => props.color};
  padding: 5px 0;
  width: 250px;
`;


