import {Tabs} from "antd";
import styled from "styled-components";


type TabStyledType = {
    color: string;
    focusedcolor: string;
    background: string;
    backgrounddropdown: string;
    colordropdown: string;
    usemargin?: string;
    backgroundfocused: string;
}

export const TabsStyled = styled(Tabs).attrs({})<TabStyledType>`
  color: ${props => props.color};

  .ant-tabs-tab + .ant-tabs-tab {
    margin: ${props => props.usemargin === 'true' ? `0 0 0 5px !important` : `0px !important`};
  }

  &.ant-tabs-top > .ant-tabs-nav {
    background: ${props => props.background};
  }


  &.ant-tabs-top > .ant-tabs-nav::before {
    border: none !important;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    border: none !important;
  }

  .ant-tabs-tab-btn {
    width: 170px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => props.focusedcolor} !important;
    background: ${props => props.backgroundfocused} !important;
    width: 170px;

    i {
      color: ${props => props.focusedcolor} !important;
    }
  }


  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: ${props => props.color} !important;
  }

  .ant-tabs-tab:hover {
    color: ${props => props.color} !important;
    background: ${props => props.backgroundfocused};
  }

  .ant-tabs-ink-bar {
    background-color: transparent;
  }

  .ant-tabs-dropdown-menu {
    background: ${props => props.backgrounddropdown} !important;
    color: ${props => props.colordropdown} !important;

    i {
      color: ${props => props.colordropdown} !important;
    }
  }

  .ant-tabs-dropdown-menu-item {
    background: ${props => props.backgrounddropdown} !important;
    color: ${props => props.colordropdown} !important;

    i {
      color: ${props => props.colordropdown} !important;
    }
  }

  .ant-tabs-dropdown-menu-item:hover {
    filter: brightness(80%);
  }
`;
