import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";

export const newTask = () => new TaskEntity({
  id: '',
  idCompany: getIdCompanyByLocalStorage(),
  description: '',
  done: false,
  idUser: '',
  doneAt: undefined,
  createdAt: undefined,
});
