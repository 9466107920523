import {observer} from "mobx-react";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    BusinessOperationHoursConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {
    Container,
    CotentText,
    Text,
} from "@dropDesk/presentation/components/chat_view/panel_inform_ticket_waiting/styled";
import {TextWeight} from "@dropDesk/presentation/routes/header_confirmation_account/styled";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {Popover} from "antd";
import PanelHours from "@dropDesk/presentation/components/chat_view/panel_inform_ticket_waiting/panel_hours";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const PanelInformTicketWaiting = observer(
    ({
         businessOperation,
     }: {
        businessOperation: BusinessOperationHoursConfigurationEntity
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const operatingCompany = businessOperation.companyInOperation();
        return (
            <Container>
                {!operatingCompany && <CotentText>
                    <Text><TextWeight>Informação importante:&nbsp; </TextWeight>
                        <Text>Estamos <TextWeight style={{color: SharedColors.errorOnPrimary}}>fechado</TextWeight> no
                            momento,
                            consulte os horários clicando no
                            icone ao lado&nbsp;
                            <Popover
                                color={colors.backgroundInput}
                                placement="bottom"
                                trigger={['hover', 'click']}
                                title=""
                                content={<PanelHours businessOperation={businessOperation}/>}>
                                <DropDeskIcon
                                    icon={ICONS_DROPDESK.clock}
                                    size={15}
                                    color={SharedColors.black}
                                    useDarkenInHover={true}
                                    style={{cursor: 'pointer'}}
                                />
                            </Popover>
                        </Text>
                    </Text>
                </CotentText>}
                <CotentText>
                    <Text>Em breve você será atendido. Aguarde só mais um momento que estamos encontrando um atendente
                        para
                        atendê-lo.</Text>
                </CotentText>
            </Container>
        );
    });
export default PanelInformTicketWaiting;
