import styled from "styled-components";
import {Spin} from 'antd';


type ProgressBarType = {
    color: string;
}

export const SpinStyled = styled(Spin)<ProgressBarType>`

  .ant-spin-dot-item {
    background-color: ${(props) => props.color}
  }

  .ant-spin-text {
    color: ${(props) => props.color};
    font-size: 14px;
    margin-top: 5px;
`;
