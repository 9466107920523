import styled, {keyframes} from 'styled-components';
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ColorType = {
  color: string;
}

const audioWave = (color: string) => keyframes`
  0% {
    height: 5px;
    transform: translateY(0px);
    background: ${hexToRGBA(color, 1)};
  }
  25% {
    height: 40px;
    transform: translateY(20px);
    background: ${hexToRGBA(color, .8)};
  }

  50% {
    height: 5px;
    transform: translateY(0px);
    background: ${hexToRGBA(color, .7)};
  }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: ${hexToRGBA(color, .6)};
  }

`;

export const Container = styled.div`
  position: relative;
  width: 85px;
  margin: 0 10px;
`;
export const Span = styled.span<ColorType>`
  display: block;
  bottom: 0px;
  width: 7px;
  height: 5px;
  background: ${props => props.color};
  position: absolute;
  animation: ${props => audioWave(props.color)} 1.5s infinite ease-in-out;

  :nth-child(2) {
    left: 11px;
    animation-delay: 0.2s;
  }

  :nth-child(3) {
    left: 22px;
    animation-delay: 0.4s;
  }

  :nth-child(4) {
    left: 33px;
    animation-delay: 0.6s;
  }

  :nth-child(5) {
    left: 44px;
    animation-delay: 0.8s;
  }

  :nth-child(6) {
    left: 55px;
    animation-delay: 0.9s;
  }

  :nth-child(7) {
    left: 66px;
    animation-delay: 1.0s;
  }

  :nth-child(8) {
    left: 77px;
    animation-delay: 1.1s;
  }


`;

