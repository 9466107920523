export class FileProgress {
  progress!: number;
  inProgress!: boolean;
  url!: string;

  constructor({
                progress,
                inProgress,
                url
              }: {
    progress: number;
    inProgress: boolean;
    url: string;

  }) {
    Object.assign(this, {
      progress,
      inProgress,
      url
    });
  }
}
