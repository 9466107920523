import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import FormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form_client";
import {ContentFormCreate} from "@dropDesk/presentation/pages/ticket/ui/form_create/modals/styled";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {useModalChat} from "@dropDesk/presentation/pages/chat/ui/contact_list/modals/handle_change_visible";
import ModalInfoNewTicket from "@dropDesk/presentation/components/modals/modal_information";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import FormCreateTicket from "@dropDesk/presentation/pages/ticket/ui/form_create";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const ModalsChat = observer(
    ({
         handleSubmitEditClient,
         handleSubmitNewChat
     }: {
        handleSubmitEditClient: () => void
        handleSubmitNewChat: (ticket: TicketEntity) => void

    }) => {

        const {
            visibleModalEditClient, setVisibleModalEditClient,
            visibleModalInfoNewTicket, setVisibleModalInfoNewTicket,
            visibleModalNewTicket, setVisibleModalNewTicket
        } = useBetween(useModalChat);

        return (
            <>
                <ModalMain
                    open={visibleModalEditClient.visible}
                    onRequestClose={() => setVisibleModalEditClient({visible: false, idClient: null})}
                    width={1060}
                    renderComponent={
                        <ContentFormCreate>
                            <FormClients
                                isEdit={true}
                                isNewChatContact={!visibleModalEditClient.idClient}
                                idClientExternal={visibleModalEditClient.idClient}
                                handleSubmitExternalScreen={(client) => {
                                    handleSubmitEditClient();
                                    const isCreateNewClient = !visibleModalEditClient.idClient;
                                    if (isCreateNewClient) {
                                        const hasOnlyOneContact: boolean = client.users?.filter((entry) => entry.action !== BackendAction.delete).length === 1;
                                        setVisibleModalInfoNewTicket({
                                            visible: true,
                                            client: client,
                                            contact: hasOnlyOneContact ? client.users![0] : null
                                        });
                                    }
                                }}
                                backPreviousScreenExternalScreen={() => setVisibleModalEditClient({
                                    visible: false,
                                    idClient: null
                                })}
                            />
                        </ContentFormCreate>}
                />

                {visibleModalInfoNewTicket.client && <ModalMain
                    open={visibleModalNewTicket}
                    onRequestClose={() => {
                        setVisibleModalNewTicket(false);
                        setVisibleModalInfoNewTicket({
                            visible: false,
                            client: null,
                            contact: null
                        });
                    }}
                    width={1060}
                    renderComponent={
                        <ContentFormCreate>
                            <FormCreateTicket
                                isNewChat={true}
                                isNewChatBody={{
                                    client: visibleModalInfoNewTicket.client,
                                    contact: visibleModalInfoNewTicket.contact,
                                }}
                                handleSubmitExternalScreen={(ticket) => {
                                    handleSubmitNewChat(ticket);
                                    setVisibleModalInfoNewTicket({
                                        visible: false,
                                        client: null,
                                        contact: null
                                    });
                                }}
                                backPreviousScreenExternalScreen={() => {
                                    setVisibleModalNewTicket(false);
                                    setVisibleModalInfoNewTicket({
                                        visible: false,
                                        client: null,
                                        contact: null
                                    });
                                }}
                            />
                        </ContentFormCreate>
                    }
                />}

                <ModalInfoNewTicket
                    open={visibleModalInfoNewTicket.visible}
                    onRequestClose={() => {
                        setVisibleModalInfoNewTicket({
                            visible: false,
                            client: null,
                            contact: null
                        });
                    }}
                    textHeaderModal={'Novo atendimento'}
                    textButton={'Confirmar'}
                    onRequestSave={() => {
                        setVisibleModalNewTicket(true);
                    }}
                    nameIcon={ICONS_DROPDESK.chat}
                    sizeIcon={130}
                    textInformation={
                        `Deseja abrir um novo atendimento para o cliente ${visibleModalInfoNewTicket.contact ? visibleModalInfoNewTicket.contact.name : visibleModalInfoNewTicket.client?.name}?`
                    }
                    loading={false}
                />
            </>
        )
    })
export default ModalsChat;
