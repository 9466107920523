import styled from "styled-components";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


type IconOpsType = {
    color: string;
}

export type ButtonType = {
    background: string;
    color: string;
    border: string;
    accent: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 0px;
  width: 100%;
  height: calc(100vh - 176px);
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 16px;
`;
export const ContentData = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const ContentCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  min-height: 40px;
  width: 100%;
`;
export const ContentPortalClient = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 55px;
  min-height: 55px;
  width: 100%;
`;

export const Button = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: 14px;
  width: 40px;
  height: 25px;
  min-height: 25px;
  border-radius: 5px;
  letter-spacing: 1px;
  border: 1px solid ${props => props.border};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const TextCheckBox = styled.span<IconOpsType>`
  font-size: ${`${ConstantsStyles.inputFontSize}px`};
  color: ${props => props.color};
  cursor: pointer;
  padding-left: 10px;
`;

export const ContentMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;


