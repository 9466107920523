import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ReportTicketAggregateRepository} from "@dropDesk/domain/repositories/reports/report_ticket_aggregate/report_ticket_aggregate.repository";

@injectable()
export class ExportPdfReportTicketAggregateUseCase {
  private _repository: ReportTicketAggregateRepository;

  constructor(@inject(ReportTicketAggregateRepository) repository: ReportTicketAggregateRepository) {
    this._repository = repository;
  }

  public call(period: { startDate: string, endDate: string }): Promise<void> {
    return this._repository.exportPdf(period);
  }
}
