import {observer} from "mobx-react";
import React from "react";
import {ContentFilter, ContentInput, ContentInputAndFilter, ContentSelectAndCheckBox,} from "./styled";
import SelectRangeDate from "@dropDesk/presentation/components/dates/select_range_date";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {useBetween} from "use-between";
import {
    useModalsReportTicketRate
} from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui/modals/handle_change_visible";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {PeriodEnum} from "@dropDesk/utils/helpers/common";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const SearchBar = observer((
    {
        loading,
        user,
        removeFilter,
        getReportTicketRate,
        setPeriod,
        period,
        labelPeriodCustom
    }: {
        loading: boolean,
        user: UserEntity | null,
        removeFilter: () => void,
        getReportTicketRate: () => void,
        period: Array<Date> | null;
        setPeriod: (period: Array<Date> | null, label: PeriodEnum) => void
        labelPeriodCustom: PeriodEnum
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const removeAllFilters = () => {
        removeFilter();
    }

    const {
        setVisibleModalSearchUser,
    } = useBetween(useModalsReportTicketRate);


    return (
        <ContentFilter background={colors.onBackground}>
            <ContentInputAndFilter>
                <ContentInput>
                    <SelectRangeDate
                        size={37}
                        label={'Período'}
                        value={period}
                        allowClear={true}
                        onChange={(arrayDate, label) => {
                            setPeriod(arrayDate, label);
                            getReportTicketRate();
                        }}
                        labelPeriodCustom={labelPeriodCustom}
                    />
                </ContentInput>


                <ContentSelectAndCheckBox>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        <InputWithBorder
                            name='user'
                            size={37}
                            onClick={() => setVisibleModalSearchUser(true)}
                            readOnly={true}
                            disabled={loading}
                            placeHolder={"Selecione o atendente"}
                            nameIconRight={ICONS_DROPDESK.downArrow}
                            sizeRightIcons={14}
                            marginRightRightIcons={10}
                            label={"Filtrar por atendente"}
                            value={user?.name ?? ''}
                            useButtonRight={!!user}
                            labelButtonRight={"Remover filtro"}
                            onClickButtonRight={() => {
                                removeAllFilters();
                                getReportTicketRate();
                            }}
                            onChange={() => {
                            }}
                        />
                    </div>

                </ContentSelectAndCheckBox>

            </ContentInputAndFilter>
        </ContentFilter>
    )
})
export default SearchBar;
