import styled from "styled-components";
import {
    ContainerTypeNotFound,
    IconOpsType
} from "@dropDesk/presentation/components/generic_screen_not_found/generic_screen_not_found";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const ContentNotFound = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerNotFound = styled.div<ContainerTypeNotFound>`
  display: flex;
  flex: 1;
  min-width: 768px;
  width: 768px;
  height: 500px;
  max-height: 500px;
  flex-direction: column;
  overflow: auto;
  margin: 15px 0px 15px 0px;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-radius: 5px;
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
  padding: 0 0 16px 0;
`;

export const TextNotFound = styled.span<IconOpsType>`
  font-size: 20px;
  color: ${props => props.color};
  font-weight: bold;
`;
