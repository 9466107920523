import styled from "styled-components";

type ContainerType = {
    background: string;
    heightShowingHeaderInfo: number;
};
type TextType = {
    color: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 101px - ${props => `${props.heightShowingHeaderInfo}px`});
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: auto;
  padding-right: 2px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;


export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

