import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {CNPJRemoteDataSource} from "@dropDesk/data/data_source/common/cnpj/cnpj.datasource";
import {CNPJResponseEntity} from "@dropDesk/domain/entities/common/cnpj_response.entity";
import {CNPJRepository} from "@dropDesk/domain/repositories/common/cnpj/cnpj.repository";


@injectable()
export class CNPJRepositoryImpl implements CNPJRepository {
  private _dataSource: CNPJRemoteDataSource;

  constructor(@inject(CNPJRemoteDataSource) dataSource: CNPJRemoteDataSource) {
    this._dataSource = dataSource;
  }

  public searchCNPJ(cnpj: string): Promise<CNPJResponseEntity> {
    try {
      return this._dataSource.searchCNPJ(cnpj);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }

}
