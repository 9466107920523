import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {
    useModalsSubscriptionExpired
} from "@dropDesk/presentation/pages/subscription_expired_default_user/ui/modals/visible_modal_subscription";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsSubscriptionExpired = observer(
    ({
         logout
     }: {
        logout: () => Promise<void>
    }) => {
        const {
            visibleModalLogout, setVisibleModalLogout
        } = useBetween(useModalsSubscriptionExpired);

        return (
            <ModalInformation
                open={visibleModalLogout}
                onRequestClose={() => {
                    setVisibleModalLogout(false);
                }}
                textHeaderModal={'Sair do sistema'}
                textButton={'Sair'}
                onRequestSave={async () => {
                    await logout();
                    setVisibleModalLogout(false);
                }}
                nameIcon={ICONS_DROPDESK.logout}
                sizeIcon={130}
                textInformation={`Deseja realmente sair do sistema?`}
                loading={false}
            />
        )
    })
export default ModalsSubscriptionExpired;
