import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import {useModalCreateTicket} from "@dropDesk/presentation/pages/ticket/ui/form_create/modals/handle_change_visible";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import ModalSearchUser from "@dropDesk/presentation/components/modals/modal_search_user";
import ModalSearchClient from "@dropDesk/presentation/components/modals/modal_search_client";
import ModalSearchSector from "@dropDesk/presentation/components/modals/modal_search_sector";
import ModalSearchTicketDescriptionStatic
    from "@dropDesk/presentation/components/modals/modal_search_ticket_description_static";
import FormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form_client";
import {ContentFormCreate} from "@dropDesk/presentation/pages/ticket/ui/form_create/modals/styled";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ModalsOfSearch = observer(
    ({
         handleSelectClientAndDependOpenModal,
         handleSelectContact,
         handleSelectUserAttendant,
         handleSelectSector,
         handleSelectDescriptionStatic,
         onCreateClient,
         onEditClient,
         client,
         isEdit,
         idSector,
         idUserLogged
     }: {
        handleSelectClientAndDependOpenModal: (client: ClientEntity) => Promise<boolean>,
        handleSelectContact: (contact: UserEntity) => void,
        handleSelectUserAttendant: (user: UserEntity) => void,
        handleSelectSector: (sector: SectorEntity) => void,
        handleSelectDescriptionStatic: (descriptionStatic: TicketsDescriptionStaticEntity) => void,
        onCreateClient: (client: ClientEntity) => boolean,
        onEditClient: (client: ClientEntity) => void,
        client: ClientEntity | undefined,
        idSector?: string,
        isEdit?: boolean,
        idUserLogged?: string
    }) => {
        const appController = useInjection(AppController);

        const {
            visibleModalSearchContacts, setVisibleModalSearchContacts,
            visibleModalSearchClient, setVisibleModalSearchClient,
            visibleModalSearchSector, visibleModalSearchUser,
            setVisibleModalSearchUser, setVisibleModalSearchSector,
            visibleModalTicketDescriptionStatic, setVisibleModalTicketDescriptionStatic,
            visibleModalCreateClient, setVisibleModalCreateClient
        } = useBetween(useModalCreateTicket);

        return (
            <>
                <ModalMain
                    open={visibleModalCreateClient}
                    onRequestClose={() => setVisibleModalCreateClient(false)}
                    width={1060}
                    renderComponent={
                        <ContentFormCreate>
                            <FormClients
                                isEdit={true}
                                idClientExternal={client?.id ?? ''}
                                handleSubmitExternalScreen={async (client) => {
                                    if (isEdit) {
                                        onEditClient(client);
                                    } else {
                                        const isOpenModal = onCreateClient(client);
                                        setVisibleModalCreateClient(false);
                                        if (isOpenModal) {
                                            setVisibleModalSearchContacts(true);
                                        }
                                    }
                                }}
                                backPreviousScreenExternalScreen={() => setVisibleModalCreateClient(false)}
                            />
                        </ContentFormCreate>}
                />

                {visibleModalSearchClient && <ModalSearchClient
                    visible={visibleModalSearchClient}
                    onClose={() => setVisibleModalSearchClient(false)}
                    handleSelectClient={async (client) => {
                        const isOpenModal = await handleSelectClientAndDependOpenModal(client);
                        setVisibleModalSearchClient(false);

                        if (isOpenModal) {
                            setVisibleModalSearchContacts(true);
                        }
                    }}
                    textHeaderModal={"Selecione a empresa"}
                    labelNoResults={"Nenhuma empresa encontrada na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por uma empresa"}
                />}

                {visibleModalSearchUser && <ModalSearchUser
                    visible={visibleModalSearchUser}
                    onClose={() => setVisibleModalSearchUser(false)}
                    role={UserRole.attendant}
                    idSector={idSector}
                    handleSelectUser={async (user) => {
                        handleSelectUserAttendant(user);
                        setVisibleModalSearchUser(false);
                    }}
                    textHeaderModal={"Pesquisa de Atendentes"}
                    labelNoResults={"Nenhum usuário encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por atendentes"}
                />}

                {visibleModalSearchContacts && <ModalSearchUser
                    visible={visibleModalSearchContacts}
                    onClose={() => setVisibleModalSearchContacts(false)}
                    role={UserRole.userClient}
                    handleSelectUser={(user) => {
                        handleSelectContact(user);
                        setVisibleModalSearchContacts(false);
                    }}
                    idClient={client?.id}
                    textHeaderModal={"Pesquisa de Contatos"}
                    labelNoResults={"Nenhum contato encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por contatos"}
                />}

                {visibleModalSearchSector && <ModalSearchSector
                    visible={visibleModalSearchSector}
                    onClose={() => setVisibleModalSearchSector(false)}
                    handleSelectSector={(sector) => {
                        handleSelectSector(sector);
                        setVisibleModalSearchSector(false);
                    }}
                    textHeaderModal={"Pesquisa de Setores"}
                    idUser={idUserLogged}
                    labelNoResults={"Nenhum setor encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por setores"}
                />}

                {visibleModalTicketDescriptionStatic && <ModalSearchTicketDescriptionStatic
                    visible={visibleModalTicketDescriptionStatic}
                    onClose={() => setVisibleModalTicketDescriptionStatic(false)}
                    handleSelectTicketDescriptionStatic={(ticketsDescriptionStatic) => {
                        handleSelectDescriptionStatic(ticketsDescriptionStatic);
                        setVisibleModalTicketDescriptionStatic(false);
                    }}
                    textHeaderModal={"Selecione o problema"}
                    labelNoResults={"Nenhum problema encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise pelo problema"}
                    useFooter={!!appController.user?.permissionAdmin}
                    labelFooter={'Novo problema'}
                />}
            </>
        )
    })
export default ModalsOfSearch;
