import styled from "styled-components";

type ContainerType = {
  background: string;
};
export const ContentData = styled.form`
  display: flex;
  flex-direction: column;
`;
export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 16px;
`;
export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentInput = styled.div`
  display: flex;
  flex:1;
`;


export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;
export const ContainerModalNewTicketsDescriptionStatic = styled.div<ContainerType>`
  display: flex;
  width: 670px;
  overflow: auto;
  flex-direction: column;
  border-radius: 7px;
  background: ${(props) => props.background};
  height:280px;
  min-height:280px;

`;
export const ContentModalNewTicketsDescriptionStatic = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  justify-content: center;

`;
