import {observer} from "mobx-react";
import React, {useContext} from "react";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import {Tooltip} from "antd";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ContainerAction} from "./styled";
import ButtonActionTable from "@dropDesk/presentation/components/buttons/button_action_table";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import {Actions} from "@dropDesk/domain/entities/task/task_enum";
import {useBetween} from "use-between";
import {useModalTask} from "@dropDesk/presentation/pages/task/ui/modals/handle_change_visible";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const TableTasks = observer(
    ({
         handleDoneAndUndoneTask,
         loading,
         tableTask,
         getDataFromPage,
         setInputFocus,
         handleVisibleModalEditTask
     }: {
        handleDoneAndUndoneTask: (id: string, action: Actions) => void
        loading: boolean,
        tableTask: ListPaginationEntity<TaskEntity>,
        getDataFromPage: (page: number) => void,
        setInputFocus: () => void
        handleVisibleModalEditTask: (visible: boolean, task?: TaskEntity) => void
    }) => {

        const collapsed = useContext(MenuCollapsedContext);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            setVisibleModalDelete
        } = useBetween(useModalTask);

        const handleDoneUndone = (id: string, lastAction: boolean) => {
            handleDoneAndUndoneTask(id, lastAction === true ? Actions.undone : Actions.done);
        }

        const tableColumn = [

            {
                title: 'Tarefa',
                dataIndex: 'description',
                ellipsis: true,
                key: 'id',
                render: (text: string, record: TaskEntity) => (
                    <>
                        <CheckBoxWithLabel
                            label={''}
                            tooltip={''}
                            checked={record.done}
                            name={'done'}
                            marginLeft={0}
                            disabled={false}
                            labelWithLineThrough={false}
                            useDisplayInLineBlock={true}
                            height={20}
                            onChange={(event) => {
                            }}
                        />
                        <Tooltip placement="topLeft" title={record.description}>
                            <span style={{
                                textDecoration: record.done ? 'line-through' : "auto",
                                color: record.done ? colors.hint : colors.text
                            }}>{text}</span>
                        </Tooltip>
                    </>
                ),
            },
            {
                title: 'Feito em',
                width: 200,
                dataIndex: 'doneAt',
                key: 'id',
                render: (text: string, record: TaskEntity) => (
                    <>
                        {!!text && record.done === true &&
                            <span
                                style={{color: record.done ? colors.hint : colors.text}}>{displayDateToLocale(text)}</span>}
                    </>
                ),
            },
            {
                title: 'Criado em',
                width: 200,
                dataIndex: 'createdAt',
                key: 'id',
                render: (text: string, record: TaskEntity) => (
                    <span style={{color: record.done ? colors.hint : colors.text}}>{displayDateToLocale(text)}</span>
                ),
            },
            {
                title: '', key: 'action', width: 55, render: (text: string, record: TaskEntity) => (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <ContainerAction>
                            <Tooltip placement="left" title={'Editar Tarefa'}
                            > <ButtonActionTable
                                disabled={loading}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleVisibleModalEditTask(true, record);
                                }}
                                nameIcon={ICONS_DROPDESK.edit}
                                isActionDelete={false}
                                sizeIcon={16}
                            /> </Tooltip>

                        </ContainerAction>
                    </div>
                ),
            },
            {
                title: '', key: 'action', width: 55, render: (text: string, record: TaskEntity) => (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <ContainerAction>

                            <Tooltip placement="left" title={'Excluir Tarefa'}
                            > <ButtonActionTable
                                disabled={loading}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setVisibleModalDelete({visible: true, id: record.id})
                                }}
                                nameIcon={ICONS_DROPDESK.delete}
                                isActionDelete={true}
                            /> </Tooltip>

                        </ContainerAction>
                    </div>
                ),
            },
        ];


        return (
            <div style={{}}>
                <TableGeneric<TaskEntity>
                    size={'small'}
                    sticky={true}
                    tableColumns={tableColumn}
                    tableDataSource={tableTask.data}
                    background={colors.background}
                    onSelectedRow={(task: TaskEntity) => {
                        handleDoneUndone(task.id, task.done);
                    }}
                    loading={loading}
                    labelNoResults={'Nenhuma tarefa encontrada na pesquisa'}
                    collapsedMenu={collapsed}
                    defaultCurrentPage={1}
                    totalRows={tableTask.totalRows}
                    page={tableTask.page}
                    pageSize={tableTask.limit}
                    rowSelection={null}
                    onChange={(page) => getDataFromPage(page - 1)}
                />
            </div>
        )
    })
export default TableTasks;
