import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {CompanyEntity, CompanyExtraInfoEntity} from "@dropDesk/domain/entities/company/company.entity";

@injectable()
export class SetCompanyExtraInfoUseCase {
    private _repository: CompanyRepository;

    constructor(@inject(CompanyRepository) repository: CompanyRepository) {
        this._repository = repository;
    }

    public call(extraInfo: CompanyExtraInfoEntity): Promise<CompanyEntity> {
        return this._repository.setCompanyExtraInfo(extraInfo);
    }
}

