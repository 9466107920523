import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";

@injectable()
export class ImportClientUseCase {
  private _repository: ClientRepository;

  constructor(@inject(ClientRepository) repository: ClientRepository) {
    this._repository = repository;
  }

  public call(file: File): Promise<Record<string, unknown>> {
    return this._repository.import(file);
  }
}

