export class TicketConfigurationEntity {
  useRatingTicket!: boolean;
  informReasonCancellation!: boolean;
  informResolutionTicket!: boolean;
  useProblemStatic!: boolean;

  constructor({
                useRatingTicket,
                informResolutionTicket,
                informReasonCancellation,
                useProblemStatic
              }: {
    useRatingTicket: boolean;
    informReasonCancellation: boolean;
    informResolutionTicket: boolean;
    useProblemStatic: boolean;
  }) {
    Object.assign(this, {
      useRatingTicket,
      informResolutionTicket,
      informReasonCancellation,
      useProblemStatic
    });
  }

  copyWith({
             useRatingTicket,
             informResolutionTicket,
             informReasonCancellation,
             useProblemStatic
           }: {
    useRatingTicket?: boolean;
    informReasonCancellation?: boolean;
    informResolutionTicket?: boolean;
    useProblemStatic?: boolean;
  }): TicketConfigurationEntity {
    return new TicketConfigurationEntity({
      useRatingTicket: useRatingTicket ?? this.useRatingTicket,
      informResolutionTicket: informResolutionTicket ?? this.informResolutionTicket,
      informReasonCancellation: informReasonCancellation ?? this.informReasonCancellation,
      useProblemStatic: useProblemStatic ?? this.useProblemStatic,
    })
  }
}
