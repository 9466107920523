import {observer} from "mobx-react";
import {Container, Content} from "./styled";
import React, {useEffect, useState} from "react";
import MessagesWhatsAppConfiguration
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/messages";
import ConnectionWhatsAppConfiguration
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/connection";
import FooterWhatsApp from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/footer";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalsWhatsApp from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";
import {TextTab} from "@dropDesk/presentation/pages/company_configurations/ui/styled";
import Tabs from "@dropDesk/presentation/components/tabs/background_style";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";


export enum TabsWhatsApp {
    default = 'default',
    messages = 'messages',
}

const WhatsAppConfiguration = observer(
    ({
         loading,
         handleSubmit,
         companyConfiguration,
         onUpdate,
         logout,
         chatConnection,
         startConnection,
         getConnectionState,
         dispose,
         resetMessages
     }: {
        loading: boolean,
        handleSubmit: () => void,
        logout: () => void,
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
        companyConfiguration: CompanyConfigurationEntity
        chatConnection: ChatIntegrationConnectionEntity,
        startConnection: () => Promise<void>
        getConnectionState: () => Promise<void>
        dispose: () => void
        resetMessages: () => void
    }) => {

        useEffect(() => {
            getConnectionState().then();
            return () => {
                dispose();
            }
        }, []);

        const handleAllChangesWhatsAppConfiguration = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const _configurationChat = companyConfiguration.copyWith({
                chat: companyConfiguration.chat.copyWith({
                    [event.target.name]:
                        (event.target.type === "checkbox" || event.target.type === "switch") ?
                            event.target.checked : event.target.value
                })
            });
            onUpdate(_configurationChat);
        };

        const handleSelectSector = (sector: SectorEntity) => {
            handleAllChangesWhatsAppConfiguration({
                target:
                    {
                        name: 'sector',
                        value: sector,
                        checked: false,
                        type: 'input'
                    }
            });
        }

        const [tabsWhatsApp, setTabsWhatsApp] = useState<TabsWhatsApp>(TabsWhatsApp.default);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>
                <Content background={colors.onBackground} border={colors.border}>

                    <Tabs
                        defaultActiveKey={tabsWhatsApp}
                        withMarginInTabs={true}
                        destroyInactiveTabPane={true}
                        height={50}
                        onChange={(key) => setTabsWhatsApp(key as TabsWhatsApp)}
                        items={[
                            {
                                label:
                                    <TextTab>
                                        Configurações Gerais
                                    </TextTab>,
                                key: TabsWhatsApp.default,
                                children: <ConnectionWhatsAppConfiguration
                                    handleAllChangesWhatsAppConfiguration={handleAllChangesWhatsAppConfiguration}
                                    companyConfiguration={companyConfiguration}
                                    loading={loading}
                                    chatConnection={chatConnection}
                                    startConnection={startConnection}
                                />,
                            },
                            {
                                label:
                                    <TextTab>
                                        Mensagens
                                    </TextTab>,
                                key: TabsWhatsApp.messages,
                                children: <MessagesWhatsAppConfiguration
                                    handleAllChangesWhatsAppConfiguration={handleAllChangesWhatsAppConfiguration}
                                    companyConfiguration={companyConfiguration}
                                    loading={loading}
                                />,
                            },
                        ]}
                    />

                    <FooterWhatsApp
                        companyConfiguration={companyConfiguration}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        activeKey={tabsWhatsApp}
                    />

                    <ModalsWhatsApp
                        logout={logout}
                        loading={loading}
                        chatConnection={chatConnection}
                        dispose={dispose}
                        startConnection={startConnection}
                        resetMessages={resetMessages}
                        handleSelectSector={(sector) => handleSelectSector(sector)}
                    />
                </Content>
            </Container>
        )
    })
export default WhatsAppConfiguration;
