import styled from "styled-components";
import {
  ButtonActionTableType,
  IconType
} from "@dropDesk/presentation/components/buttons/button_action_table/button_action_table";


export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: 17px;
`;
export const ContentAction = styled.button<ButtonActionTableType>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: ${props => props.background};
  border-radius: 5px;
  padding:2px 5px;
  margin: 0 5px;
  &:hover {
    filter: brightness(80%);
  }
  border:0;
`;

