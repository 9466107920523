export enum PaymentType {
    creditCard = 'creditCard',
    free = 'free',
    pix = 'pix',
    boleto = 'boleto',
}

export enum PaymentStatus {
    paid = 'paid',
    pending = 'pending',
    canceled = 'canceled',
    overdue = 'overdue',
    reproved = 'reproved',
    refundRequested = 'refundRequested',
    refundInProgress = 'refundInProgress',
    refunded = 'refunded',
}
