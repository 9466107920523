import React from "react";
import HeaderNotification from "@dropDesk/presentation/pages/chat/ui/header_notification";
import ChatView from "@dropDesk/presentation/components/chat_view/chat_view";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {ImageEditorController} from "@dropDesk/presentation/pages/image_editor/controller/image_editor.controller";


const ContentChat = observer((
    {
        chatId,
        widthContainerListChats
    }: {
        chatId?: string | null,
        widthContainerListChats?: number
    }
) => {

    const imageEditorController = useInjection(ImageEditorController);
    return (
        <div style={{display: 'table', width: '100%'}}>
            {imageEditorController.filesEdition.length === 0 &&
                <HeaderNotification/>
            }
            {chatId &&
                <ChatView chatId={chatId} widthContainerListChats={widthContainerListChats}/>
            }
        </div>
    )
});
export default ContentChat;
