import styled from "styled-components";

type IconOpsType = {
    color: string;
}

export const ContentDataLogin = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContentPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 33px;
  flex: 1;
`;
export const TextPermission = styled.span<IconOpsType>`
  font-size: 15px;
  color: ${props => props.color};
  padding-left: 5px;
  border: none;
  background: transparent;
`;
export const ContainerPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
export const TextTitle = styled.h3<IconOpsType>`
  color: ${props => props.color};
  //margin: 0 0 24px;
  font-weight: bold;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;


