import {PaymentStatus, PaymentType,} from "@dropDesk/domain/entities/payment/payment.enum";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {PaymentProvider} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {
    addDays,
    displayDateToLocale,
    formatDateToDDMM,
    formatDateToDDMMYYYY,
    isBeforeNow
} from "@dropDesk/utils/helpers/date_helper";

export class PaymentEntity {
    id!: string;
    idCompany?: string;
    idPaymentSubscription!: string;
    idPlan?: string;
    idExternal!: string;
    provider!: PaymentProvider;
    value!: number;
    status!: PaymentStatus;
    billingType!: PaymentType;
    paymentDate?: string;
    createdAt?: string;
    updatedAt?: string;
    paymentSubscription?: PaymentSubscriptionEntity;
    boleto?: PaymentBoletoEntity
    pix?: PaymentPixEntity;
    expireDate?: string;
    secureCode!: string;
    period!: string;


    public get periodFormatted(): string {
        const period = this.period.split(';');
        return `${formatDateToDDMM(period[0])} a ${formatDateToDDMM(period[1])}`;
    }

    public get urlDocument(): string | undefined {
        return this.pix?.document ?? this.boleto?.document;
    }

    public get expired(): boolean {
        if (this.expireDate) {
            const newDate = addDays(new Date(this.expireDate), 3);
            return isBeforeNow(newDate, false) || this.status === PaymentStatus.overdue;
        }
        return this.status === PaymentStatus.overdue;
    }

    public get paymentExpirationImminent(): boolean {
        if (this.expireDate) {
            return isBeforeNow(new Date(this.expireDate), false) || this.status === PaymentStatus.overdue;
        }
        return this.status === PaymentStatus.overdue;
    }


    public titlePanel(currentSubscription: PaymentSubscriptionEntity): string {
        return this.rejected ? 'Pagamento rejeitado' : this.paymentExpirationImminent ? 'Fatura em atraso' : this.isCreditCard && currentSubscription.isPremiumActiveSubscription ? 'Pagamento próximo' : 'Fatura em aberto';
    }

    public get messageTimeProcessPaymentPix(): string {
        return `O pagamento via pix é processado em instantes, contudo,
                 em casos específicos podemos levar até 24 horas para processar seu pagamento.`;
    }

    public get messageTimeProcessPaymentBoleto(): string {
        return `O pagamento via boleto é processado em até 72 horas. 
                 Caso já tenha feito o pagamento, apenas aguarde o prazo de liberação e desconsidere esta mensagem.`;
    }

    public messagePanel(currentSubscription: PaymentSubscriptionEntity): string {
        const message = {
            [PaymentType.creditCard]:
                (this.paymentExpirationImminent || this.rejected)
                    ?
                    `Pagamento em atraso, evite o cancelamento dos serviços. Não conseguimos efetuar a cobrança no seu cartão de crédito salvo,
                 mude o cartão de crédito salvo em "Forma de pagamento" para um novo cartão ou pague agora mesmo com
                 pix clicando em`
                    :
                    this.isCreditCard && currentSubscription.isPremiumActiveSubscription && this.expireDate ?
                        `O débito da assinatura está agendado para o dia ${formatDateToDDMMYYYY(new Date(this.expireDate))} e não requer nenhuma ação de
                         sua parte neste momento. Fique tranquilo, você receberá um e-mail de confirmação assim que o processo for concluído.`
                        :
                        `Aguardando pagamento. O pagamento via cartão de crédito é processado em instantes, contudo,
                 em casos específicos podemos levar até 24 horas para processar seu pagamento. 
                 Caso já tenha feito o pagamento, apenas aguarde o prazo de liberação e desconsidere esta mensagem.`,
            [PaymentType.pix]:
                `${this.paymentExpirationImminent ? 'Pagamento em atraso, evite o cancelamento dos serviços' : 'Aguardando pagamento'}.
                 ${this.messageTimeProcessPaymentPix} 
                 Caso já tenha feito o pagamento, apenas aguarde o prazo de liberação e desconsidere esta mensagem. 
                 Caso deseje realizar o pagamento, clique em `,
            [PaymentType.boleto]: `${this.paymentExpirationImminent ? 'Pagamento em atraso, evite o cancelamento dos serviços' : 'Aguardando pagamento'}.
             ${this.messageTimeProcessPaymentBoleto} 
                 Caso deseje realizar o pagamento, clique em`,
            [PaymentType.free]: ''
        }
        return message[this.billingType] ?? '';
    }

    public get canPay(): boolean {
        return (this.isPending && !this.isCreditCard) || (this.rejected && this.isCreditCard && !!this.pix);
    }

    public get isProcessingPaymentCreditCard(): boolean {
        return (
            this.status === PaymentStatus.pending && this.isCreditCard);
    }

    public statusLabel(currentSubscription: PaymentSubscriptionEntity): string {
        const title = {
            [PaymentStatus.pending]: this.isCreditCard && !currentSubscription.isPremiumActiveSubscription ? 'Processando' : 'Em aberto',
            [PaymentStatus.overdue]: 'Vencido',
            [PaymentStatus.paid]: 'Pago',
            [PaymentStatus.refunded]: 'Estornado',
            [PaymentStatus.refundRequested]: 'Estorno requisitado',
            [PaymentStatus.reproved]: 'Reprovado',
            [PaymentStatus.refundInProgress]: 'Estorno em andamento',
            [PaymentStatus.canceled]: 'Cancelado',
        }
        return title[this.status] ?? 'Desconhecido';
    }

    public get isPending(): boolean {
        return this.status === PaymentStatus.pending || this.status === PaymentStatus.overdue;
    }

    public get isReproved(): boolean {
        return this.status === PaymentStatus.reproved;
    }

    public get isPaid(): boolean {
        return this.status === PaymentStatus.paid;
    }

    public get rejected(): boolean {
        return this.status == PaymentStatus.reproved ||
            this.status === PaymentStatus.refundRequested || this.status == PaymentStatus.refunded;
    }

    public get canceled(): boolean {
        return this.status === PaymentStatus.canceled;
    }

    public get isPix(): boolean {
        return this.billingType === PaymentType.pix;
    }

    public get isBoleto(): boolean {
        return this.billingType === PaymentType.boleto;
    }

    public get isCreditCard(): boolean {
        return this.billingType === PaymentType.creditCard;
    }


    public get paymentLabel(): string {
        if (this.isCreditCard) {
            return `Cartão de crédito`;
        }
        if (this.isPix) {
            return `Pix`;
        }
        return `Boleto`;
    }


    constructor({
                    id,
                    idCompany,
                    idPaymentSubscription,
                    idPlan,
                    idExternal,
                    provider,
                    value,
                    status,
                    billingType,
                    paymentDate,
                    createdAt,
                    updatedAt,
                    expireDate,
                    boleto,
                    pix,
                    paymentSubscription,
                    secureCode,
                    period
                }: {
        id: string;
        idCompany?: string;
        idPaymentSubscription: string;
        idPlan?: string;
        idExternal: string;
        provider: PaymentProvider;
        value: number;
        status: PaymentStatus;
        billingType: PaymentType;
        paymentDate?: string;
        createdAt?: string;
        updatedAt?: string;
        expireDate?: string;
        paymentSubscription?: PaymentSubscriptionEntity;
        pix?: PaymentPixEntity;
        boleto?: PaymentBoletoEntity;
        secureCode: string;
        period: string;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            idPaymentSubscription,
            idPlan,
            idExternal,
            provider,
            value,
            status,
            billingType,
            paymentDate,
            createdAt,
            updatedAt,
            expireDate,
            boleto,
            pix,
            paymentSubscription,
            secureCode,
            period
        });
    }

    public static get table(): string {
        return 'payments';
    }

    static fromJson(json: Record<string, any>): PaymentEntity {
        return new PaymentEntity({
            id: json["id"],
            idCompany: json["idCompany"],
            idPaymentSubscription: json["idPaymentSubscription"],
            idPlan: json["idPlan"],
            idExternal: json["idExternal"],
            provider: json["provider"],
            value: json["value"],
            status: json["status"],
            billingType: json["billingType"],
            paymentDate: json["paymentDate"],
            createdAt: json["createdAt"],
            updatedAt: json["updatedAt"],
            expireDate: json["expireDate"],
            boleto: json["boleto"] ? PaymentBoletoEntity.fromJson(json["boleto"]) : undefined,
            paymentSubscription: json["paymentSubscription"] ? PaymentSubscriptionEntity.fromJson(json["paymentSubscription"]) : undefined,
            pix: json["pix"] ? PaymentPixEntity.fromJson(json["pix"]) : undefined,
            secureCode: json["secureCode"],
            period: json["period"],
        });
    }
}

export class PaymentBoletoEntity {
    digitableLine!: string;
    barcodeData!: string;
    barcode?: string;
    document!: string;

    constructor({
                    digitableLine,
                    barcodeData,
                    document,
                    barcode,
                }: {
        digitableLine: string;
        barcodeData: string;
        barcode?: string;
        document?: string;
    }) {
        Object.assign(this, {
            digitableLine,
            barcodeData,
            barcode,
            document
        });
    }

    static fromJson(json: Record<string, any>): PaymentBoletoEntity {
        return new PaymentBoletoEntity({
            digitableLine: json["digitableLine"],
            barcodeData: json["barcodeData"],
            barcode: json["barcode"],
            document: json["document"],
        });
    }
}

export class PaymentPixEntity {
    qrcode!: string;
    qrcodeText!: string;
    document!: string;

    constructor({
                    qrcode,
                    qrcodeText,
                    document,
                }: {
        qrcode: string;
        qrcodeText: string;
        document: string;
    }) {
        Object.assign(this, {
            qrcode,
            qrcodeText,
            document,
        });
    }

    static fromJson(json: Record<string, any>): PaymentPixEntity {
        return new PaymentPixEntity({
            qrcode: json["qrcode"],
            qrcodeText: json["qrcodeText"],
            document: json["document"],
        });
    }
}
