import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {
    TicketsDescriptionStaticRemoteDataSource
} from "@dropDesk/data/data_source/ticket_description_static/ticket_description_static_remote.datasource";
import {
    TicketsDescriptionStaticRepository
} from "@dropDesk/domain/repositories/ticket_description_static/ticket_description_static.repository";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";

@injectable()
export class TicketsDescriptionStaticRepositoryImpl implements TicketsDescriptionStaticRepository {
    private _dataSource: TicketsDescriptionStaticRemoteDataSource;

    constructor(@inject(TicketsDescriptionStaticRemoteDataSource) dataSource: TicketsDescriptionStaticRemoteDataSource) {
        this._dataSource = dataSource;
    }

    list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean, listableForClients?: boolean): Promise<ListPaginationEntity<TicketsDescriptionStaticEntity>> {
        try {
            return this._dataSource.list(page, searchParam, limit, listOnlyDeleted, listableForClients);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    set(ticketsDescriptionStatic: TicketsDescriptionStaticEntity): Promise<TicketsDescriptionStaticEntity> {
        try {
            return this._dataSource.set(ticketsDescriptionStatic);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public findByPK(id: string): Promise<TicketsDescriptionStaticEntity> {
        try {
            return this._dataSource.findByPK(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    restore(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]): Promise<void> {
        try {
            return this._dataSource.restore(ticketsDescriptionStatic);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    delete(ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]): Promise<void> {
        try {
            return this._dataSource.delete(ticketsDescriptionStatic);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
