import styled, {css, keyframes} from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContentType = {
    position: 'left' | 'right';
    background: string;
    isDarkTheme: boolean;
    focus: boolean;
    maxWidth?: number;
    isEdit?: boolean;
    isCompactMode: boolean;
    hint: string;
    shadow: string;
}
type ButtonActionMessageType = {
    position: 'left' | 'right';
    background: string;
    isDarkTheme: boolean;
    spacePosition: number;
    hint: string;
}

type ContentFooterType = {
    position: 'left' | 'right';
}

type TitleType = {
    color: string;
}

type TextType = {
    color: string;
}

type ContainerMessageType = {
    backgroundArrow: string;
}

const animationBackgroundHighLightOnFocus = (background: string, hint: string) => keyframes`
  from {
    background-color: ${hint};
  }
  to {
    background-color: ${background};
  }
`;

export const Content = styled.div<ContentType>`
  position: relative;
  background: ${props => props.background};
  border-radius: ${props => props.isEdit ? '5px' : '1.15rem'};
  margin: ${props => props.isEdit ? '0px 0px 0px 0px' : props.position === 'left' ? '3px 5px 3px 30px' : '3px 30px 3px 5px'} !important;
  flex-direction: column;
  padding: ${props => props.isCompactMode ? `0.6rem 0.875rem 0.3rem 0.875rem` : `0.3rem 0.875rem`};
  float: ${props => props.position};
  min-width: 120px;
  max-width: ${props => props.isEdit ? 'auto' : props.maxWidth ? `${props.maxWidth}px` : '70%'};
  animation: ${props => props.focus ? css`${animationBackgroundHighLightOnFocus(props.background, props.hint)} 3s infinite ease-in-out` : 'none'};
  animation-duration: 3s;

  &::before {
    content: "";
    position: absolute;
    bottom: 5px;
    visibility: ${props => props.focus ? 'hidden' : 'visible'};
    left: ${props => props.position === 'left' ? '-21px' : `auto`};
    right: ${props => props.position === 'right' ? '-21px' : `auto`};
    width: 30px;
    height: 25px;
    background: radial-gradient(25px at top ${props => props.position}, #0000 99%, ${props => props.background} 102%);
  }
`;

export const Body = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
`;
export const ButtonActionChatMessage = styled.div<ButtonActionMessageType>`
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 20px;
  background: ${props => props.background};
  position: absolute;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: visible;
  margin: auto;
  left: ${props => props.position === 'left' ? 'auto' : `-${props.spacePosition}px`};
  right: ${props => props.position === 'left' ? `-${props.spacePosition}px` : 'auto'};


  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.background, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const Container = styled.div`

`;


export const ContentArrowDown = styled.div`
  cursor: pointer;
  opacity: 0;
  visibility: visible;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  //width: 25px;
  // height: 25px;
  display: flex;
  justify-content: center;
`;

export const ContainerMessage = styled.div<ContainerMessageType>`
  flex-direction: column;
  display: block;
  overflow-x: hidden;

  &:hover ${ButtonActionChatMessage} {
    opacity: 1;
    visibility: visible;
  }

  &:hover ${ContentArrowDown} {
    opacity: 1;
    visibility: visible;
    background: ${props => `linear-gradient(15deg, ${hexToRGBA(props.backgroundArrow, 0)}, ${hexToRGBA(props.backgroundArrow, 0)} 45%, ${hexToRGBA(props.backgroundArrow, .12)} 70%, ${hexToRGBA(props.backgroundArrow, .33)})`};
    border-radius: 50%;
  }
`;
export const ContentTitle = styled.div<TitleType>`
  color: ${props => props.color};
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  user-select: none;
  display: flex;
  align-items: center;
`;
export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;


export const TextTitle = styled.span`
  margin-left: 5px;
  font-size: 13px;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 20px;
`;


export const TextMessageDeleted = styled.span<TextType>`
  text-align: right;
  font-size: 14px;
  color: ${props => props.color};
  font-style: italic;
`;


export const TextFooter = styled.span<TextType>`
  font-size: 14px;
  color: ${props => props.color};
  font-style: italic;
`;
export const ContentFooter = styled.div<ContentFooterType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  float: ${props => props.position};
  margin: ${props => props.position === 'right' ? '0 20px 3px 0' : '0 0 3px 20px'};
  justify-content: center;
  align-items: flex-end;
`;

export const ContentProgress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 15px;
`;


export const TextError = styled.span<TextType>`
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.color};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;
export const ContentError = styled.div`
  cursor: pointer;
`;

export const ContentPrivate = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -5px;
`;
