import {
    ButtonAction,
    ButtonDropDown,
    Container,
    ContainerActionButtons, ContainerCompactMode, ContainerPopover,
    ContentButton, ContentIconMenu,
} from "./styled";
import {observer} from "mobx-react";
import React, {useState} from "react";
import {Tooltip} from "antd";
import {useBetween} from "use-between";
import {useModalViewTickets} from "@dropDesk/presentation/components/chat_view/modals/visible_modal_view_ticket";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import PopOver from "@dropDesk/presentation/components/popup/pop_confirm";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const HeaderActionButtons = observer(
    ({
         canChange,
         permissionCancelTicket,
         findByPK,
         useButtonStartChat,
         hasInactiveStatus,
         hasClosedStatus,
         hasCanceledStatus,
         compactModeMessages,
         setCompactModeMessage,
         userLogged,
         useButtonTicketRating,
         closedByInactiveTime,
         isEligibleRating,
         transferIfEmptySector
     }: {
        canChange: boolean
        permissionCancelTicket: boolean
        findByPK: () => Promise<TicketEntity | undefined>
        useButtonStartChat: boolean
        hasInactiveStatus: boolean
        hasClosedStatus: boolean
        closedByInactiveTime: boolean
        hasCanceledStatus: boolean
        compactModeMessages: boolean,
        useButtonTicketRating: boolean,
        transferIfEmptySector: boolean,
        isEligibleRating: boolean,
        setCompactModeMessage: (value: boolean) => void
        userLogged: UserEntity
    }) => {

        const [visiblePopover, setVisiblePopover] = useState<boolean>(false);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const tooltipDisabled = canChange ? "" : hasInactiveStatus ?
            "Não pode ser feita nenhuma alteração, chamado já foi encerrado." : userLogged.isUserClient ? "" : "Somente o atendente deste chamado pode fazer alterações.";

        const tooltipCompactModeMessages = compactModeMessages ?
            "Ao trocar para modo completo as mensagens vão ser disponibilizadas com detalhamento completo." :
            "Ao trocar para modo resumido as mensagens vão ser disponibilizadas com menos informações, de uma forma mais compacta.";

        const labelButton = compactModeMessages ? "Resumido" : "Completo";
        const {
            setVisibleModalTransferTicket, setVisibleModalTimeSpent, setVisibleModalPriority,
            setVisibleModalCancellationTicket, setVisibleModalTicketClosed,
            setVisibleModalActivities, setVisibleModalMoreInformation, setVisibleModalChangeDateDue,
            setVisibleModalAttendChat, setVisibleModalExportPdf,
            setVisibleModalTicketRating, setVisibleModalChangeResolution
        } = useBetween(useModalViewTickets);

        const handleClickChange = (open: boolean) => {
            setVisiblePopover(open);
        };

        return (
            <Container background={"transparent"}>

                <ContainerActionButtons>

                    {userLogged.isAttendant && useButtonStartChat && !hasInactiveStatus &&
                        <Tooltip title={tooltipDisabled}>
                            <ContentButton disable={!canChange}>
                                <ButtonAction
                                    border={colors.border}
                                    accent={colors.accent}
                                    onClick={() => setVisibleModalAttendChat(true)}
                                    isDarkTheme={colors.brightness === Brightness.dark}
                                    disabled={!canChange}
                                    width={175}
                                    color={SharedColors.white}
                                    background={colors.accent}
                                >
                                    <DropDeskIcon icon={ICONS_DROPDESK.userChecked} color={SharedColors.white}/>
                                    Iniciar atendimento
                                </ButtonAction>
                            </ContentButton>
                        </Tooltip>}

                    {userLogged.isAttendant && !hasInactiveStatus && <Tooltip title={tooltipDisabled}>
                        <ContentButton disable={!canChange}>
                            <ButtonAction
                                border={colors.border}
                                accent={colors.accent}
                                onClick={() => {
                                    setVisibleModalTransferTicket(true);
                                }}
                                isDarkTheme={colors.brightness === Brightness.dark}
                                disabled={!canChange}
                                width={105}
                                color={colors.text}
                                background={colors.backgroundInput}
                            >

                                <DropDeskIcon icon={ICONS_DROPDESK.transfer}/>
                                Transferir
                            </ButtonAction>
                        </ContentButton>
                    </Tooltip>}

                    {userLogged.isAttendant && !hasInactiveStatus && !transferIfEmptySector &&
                        <Tooltip title={tooltipDisabled}>
                            <ContentButton disable={!canChange}>
                                <ButtonAction
                                    border={colors.border}
                                    accent={colors.accent}
                                    onClick={() => setVisibleModalPriority(true)}
                                    isDarkTheme={colors.brightness === Brightness.dark}
                                    disabled={!canChange}
                                    width={105}
                                    color={colors.text}
                                    background={colors.backgroundInput}
                                >
                                    <DropDeskIcon icon={ICONS_DROPDESK.warning}/>
                                    Prioridade
                                </ButtonAction>
                            </ContentButton>
                        </Tooltip>}

                    {(hasClosedStatus || !hasCanceledStatus) && <Tooltip title={tooltipDisabled}>
                        <ContentButton
                            disable={userLogged.isUserClient ? (hasCanceledStatus || hasClosedStatus) : !canChange}>
                            <ButtonAction
                                border={colors.border}
                                accent={colors.accent}
                                onClick={() => isEligibleRating ? setVisibleModalTicketRating(true) : setVisibleModalTicketClosed(true)}
                                isDarkTheme={colors.brightness === Brightness.dark}
                                disabled={userLogged.isUserClient ? (hasCanceledStatus || hasClosedStatus) : !canChange}
                                width={closedByInactiveTime ? 210 : 95}
                                color={hasClosedStatus ? SharedColors.white : colors.text}
                                background={hasClosedStatus ? colors.success : colors.backgroundInput}
                            >
                                <DropDeskIcon icon={ICONS_DROPDESK.check}
                                              color={hasClosedStatus ? SharedColors.white : colors.text}/>
                                {closedByInactiveTime ? 'Fechado por inatividade' : hasClosedStatus ? "Fechado" : "Fechar"}
                            </ButtonAction>
                        </ContentButton>
                    </Tooltip>}

                    {((permissionCancelTicket || hasCanceledStatus) && (hasCanceledStatus || !hasClosedStatus)) &&
                        <Tooltip title={tooltipDisabled}>
                            <ContentButton disable={!canChange}>
                                <ButtonAction
                                    border={colors.border}
                                    accent={colors.accent}
                                    onClick={() => setVisibleModalCancellationTicket(true)}
                                    isDarkTheme={colors.brightness === Brightness.dark}
                                    disabled={!canChange} width={110}
                                    color={hasCanceledStatus ? SharedColors.white : colors.text}
                                    background={hasCanceledStatus ? colors.textError : colors.backgroundInput}
                                >
                                    <DropDeskIcon icon={ICONS_DROPDESK.cancelOutline}
                                                  color={hasCanceledStatus ? SharedColors.white : colors.text}/>
                                    {hasCanceledStatus ? "Cancelado" : "Cancelar"}
                                </ButtonAction>
                            </ContentButton>
                        </Tooltip>}

                    {hasInactiveStatus && !appController.isUserClient &&
                        <Tooltip title={'Informar resolução do atendimento'}>
                            <ContentButton disable={false}>
                                <ButtonAction
                                    border={colors.border}
                                    accent={colors.accent}
                                    onClick={() => setVisibleModalChangeResolution(true)}
                                    isDarkTheme={colors.brightness === Brightness.dark}
                                    disabled={false}
                                    width={110}
                                    color={colors.text}
                                    background={colors.backgroundInput}
                                >
                                    <DropDeskIcon icon={ICONS_DROPDESK.edit}/>
                                    Resolução
                                </ButtonAction>
                            </ContentButton>
                        </Tooltip>}

                    {useButtonTicketRating && hasInactiveStatus && <Tooltip title={'Avaliar atendimento'}>
                        <ContentButton disable={false}>
                            <ButtonAction
                                border={colors.border}
                                accent={colors.accent}
                                onClick={() => setVisibleModalTicketRating(true)}
                                isDarkTheme={colors.brightness === Brightness.dark}
                                disabled={false}
                                width={85}
                                color={colors.text}
                                background={colors.backgroundInput}
                            >
                                <DropDeskIcon icon={ICONS_DROPDESK.starOutline}/>
                                Avaliar
                            </ButtonAction>
                        </ContentButton>
                    </Tooltip>}

                    <ContentButton disable={false}>
                        <PopOver
                            open={visiblePopover}
                            onOpenChange={(value) => handleClickChange(value)}
                            placement="right"
                            trigger="click"
                            content={
                                <ContainerPopover>
                                    <ButtonDropDown
                                        accent={colors.accent}
                                        color={colors.text}
                                        onClick={() => {
                                            handleClickChange(false);
                                            setVisibleModalMoreInformation(true);
                                            findByPK();
                                        }}
                                    >
                                        <DropDeskIcon style={{marginRight: 7}} icon={ICONS_DROPDESK.info}/>
                                        Informações do atendimento
                                    </ButtonDropDown>

                                    {!appController.isUserClient &&
                                        <ButtonDropDown
                                            accent={colors.accent}
                                            color={colors.text}
                                            onClick={() => {
                                                handleClickChange(false);
                                                setVisibleModalActivities(true);
                                                findByPK();
                                            }}
                                        >
                                            <DropDeskIcon style={{marginRight: 7}} icon={ICONS_DROPDESK.timeline}/>
                                            Atividades no atendimento
                                        </ButtonDropDown>}

                                    {canChange && !transferIfEmptySector && !appController.isUserClient &&
                                        <ButtonDropDown
                                            accent={colors.accent}
                                            onClick={() => {
                                                handleClickChange(false);
                                                setVisibleModalTimeSpent(true);
                                            }}
                                            disabled={!canChange}
                                            color={colors.text}>
                                            <DropDeskIcon style={{marginRight: 7}} icon={ICONS_DROPDESK.working}/>
                                            Adicionar tempo gasto
                                        </ButtonDropDown>}

                                    {canChange && !transferIfEmptySector && !appController.isUserClient &&
                                        <ButtonDropDown
                                            accent={colors.accent}
                                            onClick={() => {
                                                handleClickChange(false);
                                                setVisibleModalChangeDateDue(true);
                                            }}
                                            disabled={!canChange}
                                            color={colors.text}>
                                            <DropDeskIcon style={{marginRight: 7}} icon={ICONS_DROPDESK.calendar}/>
                                            Alterar vencimento
                                        </ButtonDropDown>}

                                    <ButtonDropDown
                                        accent={colors.accent}
                                        color={colors.text}
                                        onClick={() => {
                                            setVisibleModalExportPdf(true);
                                            handleClickChange(false);
                                        }}
                                    >
                                        <DropDeskIcon style={{marginRight: 7}} size={14} icon={ICONS_DROPDESK.pdf}/>
                                        Exportar para pdf
                                    </ButtonDropDown>

                                </ContainerPopover>}
                        >

                            <ContentIconMenu
                                background={colors.backgroundInput}
                                isDarkTheme={colors.isDarkTheme}
                                border={colors.border}
                                accent={colors.accent}
                            >
                                <DropDeskIcon
                                    size={18}
                                    icon={ICONS_DROPDESK.threeDotsFill}
                                    color={colors.text}
                                />
                            </ContentIconMenu>
                        </PopOver>
                    </ContentButton>

                </ContainerActionButtons>
                <ContainerCompactMode>
                    <Tooltip title={tooltipCompactModeMessages}>
                        <ContentButton disable={false}>
                            <ButtonAction
                                border={colors.border}
                                accent={colors.accent}
                                onClick={() => setCompactModeMessage(!compactModeMessages)}
                                isDarkTheme={colors.brightness === Brightness.dark}
                                disabled={false}
                                width={110}
                                color={colors.text}
                                background={colors.backgroundInput}
                            >
                                <DropDeskIcon
                                    icon={`${compactModeMessages ? ICONS_DROPDESK.reduce : ICONS_DROPDESK.stretch}`}
                                    size={12}
                                />
                                {labelButton}
                            </ButtonAction>
                        </ContentButton>
                    </Tooltip>
                </ContainerCompactMode>
            </Container>
        );
    });
export default HeaderActionButtons;
