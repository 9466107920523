import React, {useEffect} from 'react';
import {
    Container,
    SubContentList,
    ContentList,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {SectorController} from "@dropDesk/presentation/pages/sector/controller/sector.controller";
import SearchBar from "@dropDesk/presentation/pages/sector/ui/search/search_bar";
import ModalsSector from "@dropDesk/presentation/pages/sector/ui/search/modals";
import TableSectors from "@dropDesk/presentation/pages/sector/ui/search/table";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {AppController} from "@dropDesk/presentation/app/app.controller";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import FooterGarbage from "@dropDesk/presentation/components/footer/footer_garbage";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";

const Sectors = (observer(() => {

    const {t} = useTranslation();
    const controller = useInjection(SectorController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const heightGarbage = appController.user?.permissionDeleteData ? 0 : ConstantsKeys.defaultHeightGarbage;

    useEffect(() => {
        controller.initialize(heightGarbage - appController.heightShowingHeaderInfo);
    }, []);

    const onSuccessDeleteOrRestore = (key: string) => {
        controller.list(controller.searchParam);
        toastMessage.success(t(key));
    }

    const onSearch = (searchParam: string): void => {
        controller.list(searchParam);
    }

    return (
        <Container background={colors.onBackground}>

            <ContentList>
                <SearchBar
                    onSearch={onSearch}
                    visibleInputSearch={controller.rowSelectionSectors.length === 0}
                    loading={controller.loading}
                    visibleButtonsOfActions={!controller.listOnlyDeleted && controller.rowSelectionSectors.length === 0}
                    visibleButtonDeleted={!controller.listOnlyDeleted && controller.rowSelectionSectors.length > 0}
                    visibleButtonRestore={controller.listOnlyDeleted && controller.rowSelectionSectors.length > 0}
                    filterListableForClients={controller.filterListableForClients}
                    setFilterListableForClients={(filter) => {
                        controller.setFilterListableForClients(filter);
                        onSearch(controller.searchParam);
                    }}
                />

                <SubContentList>

                    <TableSectors
                        setSector={(sector) => controller.setSector(sector)}
                        rowSelectionSectors={controller.rowSelectionSectors}
                        setRowSelectionSectors={(sectors: SectorEntity[]) => controller.setRowSelectionSectors(sectors)}
                        getDataFromPage={(page) => controller.getDataFromPage(page)}
                        tableSectors={controller.tableSectors}
                        searchParam={controller.searchParam}
                        loading={controller.loading}
                        permitSelectionRow={controller.listOnlyDeleted}
                        heightGarbage={heightGarbage}
                        permissionDeleteData={appController.user?.permissionDeleteData ?? false}
                    />

                    {appController.user?.permissionDeleteData && <FooterGarbage
                        setListOnlyDeleted={(value) => controller.setListOnlyDeleted(value)}
                        visibleListOnlyDeleted={controller.listOnlyDeleted}
                        loading={controller.loading}
                        text={'Setores ativos'}
                        textDelete={'Setores excluídos'}
                    />}

                    <ModalsSector
                        removeLineSelectRow={() => controller.removeLineSelectRow()}
                        restoreSector={() => controller.restore(onSuccessDeleteOrRestore)}
                        deleteSector={() => controller.delete(onSuccessDeleteOrRestore)}
                        loading={controller.loading}/>

                </SubContentList>
            </ContentList>

            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}

        </Container>
    )

}))
export default Sectors;
