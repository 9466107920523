import {observer} from "mobx-react";
import {Container, Content} from "./styled";
import React from "react";
import HeaderConfiguration from "@dropDesk/presentation/components/headers/header_configuration";
import ConfigurationPortalClient
    from "@dropDesk/presentation/pages/company_configurations/ui/portal_client/configurations";
import FooterPortalClient from "@dropDesk/presentation/pages/company_configurations/ui/portal_client/footer";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const PortalClient = observer(
    ({
         loading,
         handleSubmit,
         companyConfiguration,
         onUpdate
     }: {
        loading: boolean,
        handleSubmit: () => void,
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
        companyConfiguration: CompanyConfigurationEntity,
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container>
                <Content background={colors.onBackground} border={colors.border}>
                    <HeaderConfiguration
                        letter={"Configurações de Portal do Cliente"}
                        disabled={loading}
                    />

                    <ConfigurationPortalClient
                        companyConfiguration={companyConfiguration}
                        onUpdate={onUpdate}
                        loading={loading}
                    />

                    <FooterPortalClient
                        handleSubmit={handleSubmit}
                        loading={loading}
                    />

                </Content>
            </Container>
        )
    })
export default PortalClient;
