import {PinFieldType} from "@dropDesk/presentation/components/pin_field/pin";
import React from "react";
import PinInput from "@dropDesk/presentation/components/pin_field/pin_input";


export const PinField = ((props: PinFieldType) => {

  return (
    <PinInput
      autoFocus={props.autoFocus}
      onChange={props.onChange}
      noOfBoxes={props.noOfBoxes}
      disabled={props.disabled}
      defaultValue={props.defaultValue}
      useButtonClear={props.useButtonClear}
      acceptOnlyNumbers={props.acceptOnlyNumbers}
    />
  )
});

