import styled from "styled-components";


export const ContentData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContentSelectAndCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 16px;
`;
export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
`;
