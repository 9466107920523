import {observer} from "mobx-react";
import React from "react";
import {ReportRateValueEntity} from "@dropDesk/domain/entities/reports/report_ticket_rate/report_ticket_rate.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Container, ContainerStar, ContainerBar, ContainerValue, TextRate, Bar
} from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui/panel_rating/count_stars/styled";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


const CountStars = observer((
    {
        countStar,
        rating,
    }: {
        countStar: number,
        rating: ReportRateValueEntity
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const color = SharedColors.rate;

    const getStartByValue = (countStart: number): React.ReactNode => {
        if (countStart === 1) {
            return (
                <ContainerStar>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color}/>
                </ContainerStar>
            )
        } else if (countStart === 2) {
            return (
                <ContainerStar>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color}/>
                </ContainerStar>
            )
        } else if (countStart === 3) {
            return (
                <ContainerStar>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color}/>
                </ContainerStar>
            )
        } else if (countStart === 4) {
            return (
                <ContainerStar>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color}/>
                </ContainerStar>
            )
        } else {
            return (
                <ContainerStar>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color} style={{marginRight: 5}}/>
                    <DropDeskIcon icon={ICONS_DROPDESK.star} color={color}/>
                </ContainerStar>
            )
        }
    }


    return (
        <Container>
            {getStartByValue(countStar)}
            <ContainerBar>
                <Bar background={colors.accent} style={{width: `${rating?.percentage.toFixed(1) ?? 0}%`}}/>
            </ContainerBar>
            <ContainerValue>
                <TextRate color={colors.text}>{rating?.value ?? 0}</TextRate>
                <TextRate color={colors.text}>[ {rating?.percentage.toFixed(1) ?? 0}% ]</TextRate>
            </ContainerValue>
        </Container>
    )
})
export default CountStars;
