import DetailSubscription from "@dropDesk/presentation/components/detail_subscription";
import React from "react";
import {
    ContentDetail,
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/styled";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import ActiveSubscription
    from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/cancel_subscription/active";
import {observer} from "mobx-react";

const CancelSubscription = observer((
    {
        disabled,
        loading,
        currentSubscription,
        onClickCancelSubscription,
    }: {
        disabled: boolean;
        loading: boolean;
        currentSubscription: PaymentSubscriptionEntity,
        onClickCancelSubscription: () => void
    }
) => {

    return (
        <DetailSubscription
            title={'Cancelar assinatura'}
            content={
                <ContentDetail>
                    <ActiveSubscription
                        disabled={disabled}
                        loading={loading}
                        nextDueDateDDMMYYYY={currentSubscription.nextDueDateDDMMYYYY}
                        onClickCancelSubscription={onClickCancelSubscription}
                    />
                </ContentDetail>
            }
        />
    );
});
export default CancelSubscription;
