import {FormEvent} from "react";
import {observer} from "mobx-react";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import {SectionButton} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";


const ButtonChangePassword = observer(
    ({
         handleSubmit,
         loading,
         isValidForm,
         textButton,
     }: {
        loading: boolean
        handleSubmit: (event: FormEvent) => Promise<void>
        isValidForm: boolean
        textButton: string
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <SectionButton>
                <ButtonMain
                    height={50}
                    letterFontSize={ConstantsStyles.buttonMainFontSize}
                    widthPercentage={100}
                    disabled={loading || !isValidForm}
                    loading={loading}
                    color={colors.accent}
                    type={"submit"}
                    letter={textButton}
                    onClick={handleSubmit}
                />
            </SectionButton>
        );
    });

export default ButtonChangePassword;
