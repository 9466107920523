import React from "react";
import {ContainerStatus,} from "../styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {MessageBoxStatus} from "@dropDesk/presentation/components/messages/message_box";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const Status = observer((
    {
        status,
        containerStyle,
        hint
    }: {
        status?: MessageBoxStatus
        containerStyle?: React.CSSProperties;
        hint: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (

        <ContainerStatus style={containerStyle}>
            {status === MessageBoxStatus.waiting && <DropDeskIcon icon={ICONS_DROPDESK.clock} color={hint} size={16}/>}

            {status === MessageBoxStatus.sent &&
                <DropDeskIcon icon={ICONS_DROPDESK.tick} color={hint}/>}

            {status === MessageBoxStatus.received &&
                <DropDeskIcon icon={ICONS_DROPDESK.doubleTick} color={hint} size={16}/>}

            {status === MessageBoxStatus.read &&
                <DropDeskIcon
                    icon={ICONS_DROPDESK.doubleTick}
                    color={colors.readMessage}
                    size={16}
                />}
        </ContainerStatus>

    )
});
export default Status;
