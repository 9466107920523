import {Container, ContentAverageTime, ContentTextHeader, TextHeader} from "./styled";
import React from "react";
import {PanelAverageTimeType} from "@dropDesk/presentation/components/graphics/panel_average_time/panel_average_time";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


export default function PanelAverageTime(props: PanelAverageTimeType) {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Container bordercolor={colors.border} background={colors.background}>
            <ContentTextHeader>
                <div></div>
                <TextHeader color={props.color}>{props.description}</TextHeader>
                {props.showIconInformation &&
                    <DropDeskIcon
                        onClick={props.onClickIconInformation}
                        icon={ICONS_DROPDESK.info}
                        color={props.color}
                        size={16}
                        useDarkenInHover={true}
                        style={{cursor: 'pointer'}}
                    />}
                {!props.showIconInformation && <div></div>}
            </ContentTextHeader>
            <ContentAverageTime>
                {props.renderComponent()}
            </ContentAverageTime>
        </Container>
    );
}
