import {
    Navigate, RouteProps, useNavigate
} from "react-router-dom";
import {useInjection} from "inversify-react";
import {AppController} from "../app/app.controller";
import Header from "@dropDesk/presentation/components/headers/header";
import React, {useEffect, useState} from "react";
import {routeProtector} from "@dropDesk/presentation/routes/route_protector";
import HeaderConfirmationAccount from "src/presentation/routes/header_confirmation_account";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {getActiveRoute} from "@dropDesk/utils/helpers/common";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import Menu from "@dropDesk/presentation/components/menu";
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import HeaderSubscriptionInfo from "@dropDesk/presentation/routes/header_subscription_info";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import UpdateCompanyInfoFromMigration from "src/presentation/routes/update_company_info_from_migration";

export const MenuCollapsedContext = React.createContext(false);

const PrivateRoute = observer((props: RouteProps) => {
    const appController = useInjection(AppController);
    const loginController = useInjection(LoginController);
    const userController = useInjection(UserController);
    const currentUser = appController.user;

    const collapsedMenuLocalStorage = localStorage.getItem(process.env.COLLAPSED_MENU as string);
    const [collapsed, setCollapsed] = useState(collapsedMenuLocalStorage ? JSON.parse(collapsedMenuLocalStorage) : false);
    const [visibleHeaderSubscriptionInfo, setVisibleHeaderSubscriptionInfo] = useState(true);
    const [visibleHeaderConfirmationAccount, setVisibleHeaderConfirmationAccount] = useState(true);
    const colors = appController.theme.colorScheme;
    const {t} = useTranslation();
    const activeRoute = getActiveRoute();
    const navigator = useNavigate();
    const canAccess = (
        !!currentUser ||
        (loginController.getUserByDecodedToken()?.role === UserRole.attendant && loginController.getUserByDecodedToken()?.admin === true)
    );

    const collapsedMenu = () => {
        const value = !collapsed;
        localStorage.setItem(process.env.COLLAPSED_MENU as string, value.toString());
        setCollapsed(value);
    }

    const onSuccessLogout = () => {
        appController.setUser(null);
    }

    const logout = async () => {
        await loginController.logout(onSuccessLogout);
        navigator(RoutesEnum.login, {replace: true});
    }

    useEffect(() => {

        if (currentUser && currentUser.permissionAdmin &&
            visibleHeaderSubscriptionInfo && currentUser.isAttendant &&
            currentUser.company.newSubs.daysToExpireSubs(currentUser.company.oldSubs) <= ConstantsKeys.deadlineToGenerateBillingInDays
            && currentUser.company.newSubs.isEligiblePayment
        ) {
            appController.setShowingHeaderSubscriptionInfo(true);
        } else {
            appController.setShowingHeaderSubscriptionInfo(false);
        }
    }, [currentUser, visibleHeaderSubscriptionInfo]);

    useEffect(() => {

        if (
            currentUser && visibleHeaderConfirmationAccount &&
            !currentUser.verified && currentUser.isUserClient
        ) {
            appController.setShowingHeaderConfirmationAccount(true);
        } else {
            appController.setShowingHeaderConfirmationAccount(false);
        }
    }, [currentUser, visibleHeaderConfirmationAccount]);

    return canAccess ? (
            <div style={{display: "flex", flex: 1, height: "100vh", background: colors.onBackground, overflow: "hidden"}}>
                {currentUser && <Menu
                    collapsed={collapsed}
                />}
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    {appController.showingHeaderSubscriptionInfo && currentUser &&
                        <HeaderSubscriptionInfo
                            currentUser={currentUser}
                            onClickClose={() => setVisibleHeaderSubscriptionInfo(false)}
                        />
                    }
                    {appController.showingHeaderConfirmationAccount && currentUser &&
                        <HeaderConfirmationAccount
                            currentUser={currentUser}
                            onClickClose={() => setVisibleHeaderConfirmationAccount(false)}
                        />
                    }
                    {currentUser && <Header
                        routeName={t(`menu.${activeRoute}`)}
                        onRequestCollapsedMenu={collapsedMenu}
                        userName={appController.user?.name ?? ''}
                        userImage={appController.user?.urlImageProfile ?? null}
                        userEmail={appController.user?.email ?? ''}
                        currentUserId={appController.user!.id}
                        permissionScreenUsers={appController.user?.permissionScreenUsers ?? false}
                        logout={() => logout()}
                        setUserTheme={(theme) => userController.setTheme(theme)}
                        user={currentUser}
                        onUpdateUser={(user) => appController.setUser(user)}
                    />}
                    {currentUser && !currentUser?.company.document && !!currentUser?.permissionAdmin &&
                        <UpdateCompanyInfoFromMigration
                            currentUser={currentUser}
                        />}
                    <MenuCollapsedContext.Provider
                        value={collapsed ? true : false}
                    >
                        {(routeProtector(currentUser, activeRoute) || (!currentUser && !!loginController.getUserByDecodedToken())) ? props.children :
                            <Navigate to={RoutesEnum.tickets}/>}
                    </MenuCollapsedContext.Provider>
                </div>

                {
                    (appController.loading || userController.loading || loginController.loading) && <DropDeskLoading
                        height={250}
                        description={appController.loadingMessage ?? userController.loadingMessage ?? loginController.loadingMessage}
                    />
                }
            </div>
        ) :
        (
            <Navigate to={RoutesEnum.login} replace={true}/>
        );
});

export default PrivateRoute;
