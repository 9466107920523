import React, {useEffect} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, ContentButtons, InputTextArea} from "@dropDesk/presentation/components/inputs/input_chat/styled";


const InputChat = (
    {
        leftButtons,
        rightButtons,
        reference,
        placeholder,
        defaultValue,
        inputStyle,
        onChange,
        minHeight,
        maxHeight,
        autoFocus,
        onFocus,
        onKeyDown,
        value,
        onPaste,
        visibleInput
    }: {
        leftButtons?: React.ReactNode
        rightButtons?: React.ReactNode
        reference?: any
        placeholder?: string
        defaultValue?: string
        value: string
        inputStyle?: React.CSSProperties
        onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
        minHeight: number
        maxHeight: number
        autoFocus?: boolean
        onFocus?: React.FocusEventHandler
        onKeyDown?: React.KeyboardEventHandler
        onPaste?: (event: ClipboardEvent) => void
        visibleInput: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    useEffect(() => {

        if (reference && reference.current) {
            if (reference.current.style.height !== minHeight + 'px') {
                reference.current.style.height = minHeight + 'px';
            }
            let height;
            if (reference.current.scrollHeight <= maxHeight) {
                height = reference.current.scrollHeight + 'px';
            } else {
                height = maxHeight + 'px';
            }

            if (reference.current.style.height !== height) {
                reference.current.style.height = height;
            }
            if (reference.current.value !== "/") {
                reference.current.focus();
            }
        }

    }, [value, visibleInput]);

    return (
        <Container background={colors.backgroundInputChatBox}>
            {leftButtons && <ContentButtons>{leftButtons}</ContentButtons>}
            <InputTextArea
                accent={colors.accent}
                autoFocus={autoFocus ?? false}
                ref={reference}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                style={inputStyle}
                onChange={onChange}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                color={colors.text}
                background={colors.backgroundInput}
                onPaste={(event) => {
                    if (onPaste) onPaste(event as any as ClipboardEvent);
                }}
            />
            {rightButtons && <ContentButtons>{rightButtons}</ContentButtons>}
        </Container>
    )
}

export default InputChat;
