import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {Actions, Orders, Status, Type} from "@dropDesk/domain/entities/task/task_enum";

@injectable()
export abstract class TaskRepository {

  public abstract list(
    type: Type, order: Orders, status: Status, page: number, limit: number
  ): Promise<ListPaginationEntity<TaskEntity>>;

  public abstract set(task: TaskEntity): Promise<TaskEntity>;

  public abstract delete(id: string): Promise<void>;

  public abstract markAsDoneAndUndone(id: string, action: Actions): Promise<Record<string, unknown>>;
}
