import {
    Container, Image, Content, ContentImage, Title, ContentTitle, ContentDescription, Description, ContentButton,
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import blockAccess from "@dropDesk/storage/images/access_blocked.png"
import React, {useEffect, useState} from "react";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useNavigate, useParams} from "react-router-dom";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";

const InvoiceCheck = observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const {idCompany, idSubscription, idPayment} = useParams();

    useEffect(() => {
        if (idCompany === getIdCompanyByLocalStorage()) {
            navigate(RoutesEnum.subscription, {replace: true});
        }
        setLoading(false);
    }, []);

    return (
        <>
            {!loading && <Container background={colors.onBackground}>
                <Content>
                    <ContentTitle>
                        <Title color={colors.text}>Você não tem permissão pra acessar essa fatura</Title>
                    </ContentTitle>

                    <ContentImage>
                        <Image
                            src={blockAccess}
                            alt={'not authorized'}
                        />
                    </ContentImage>

                    <ContentDescription>
                        <Description color={colors.text} size={19}>
                            Ops! Você está tentando acessar uma fatura de outra conta, por favor verifique.
                        </Description>
                    </ContentDescription>
                    <ContentButton>
                        <ButtonNew
                            marginLeft={0}
                            isButtonMain={true}
                            label={'Ir para assinaturas'}
                            onClick={() => navigate(RoutesEnum.subscription, {replace: true})}
                            useIcon={false}
                            disabled={false}
                        />
                    </ContentButton>
                </Content>
            </Container>}

            {loading && <DropDeskLoading
                height={250}
                description={'Carregando ...'}
            />}
        </>

    );
});
export default InvoiceCheck;
