import {
    ContentList, TextList
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const ListExplication = () => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentList>
            <TextList color={colors.text}>1 - Abra o Whatsapp no seu Celular</TextList>
            <TextList color={colors.text}>2 - Toque em <b>"Menu"</b>
                <DropDeskIcon
                    icon={ICONS_DROPDESK.threeDotsFill}
                    size={14}
                /> ou <b>"Configurações"</b>
                <DropDeskIcon
                    icon={ICONS_DROPDESK.setting}
                    size={14}
                /> e selecione <b>"WhatsApp
                    Web"</b></TextList>
            <TextList color={colors.text}>3 - Aponte
                o seu celular para essa tela
                para capturar o <b>QR code</b></TextList>
        </ContentList>
    )
}
export default ListExplication;
