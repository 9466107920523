import {
    Bold, ContentButton,
    ContentDescription, ContentSubDescription,
    ContentSubTitle, Description, StatusSubscription, SubTitle
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/styled";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {formatCurrency} from "@dropDesk/utils/helpers/common";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const DetailCanceledPlan = (
    {
        disabled,
        loading,
        onClickReactivePlan,
        planName,
        isLegacy,
        nextDueDateDDMMYYYY,
        onClickSelectPlan,
        planValue,
    }: {
        disabled: boolean;
        loading: boolean;
        isLegacy: boolean;
        onClickReactivePlan: () => void
        onClickSelectPlan: () => void
        planName: string
        nextDueDateDDMMYYYY: string
        planValue: number
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <>
            <ContentDescription>
                <ContentSubTitle>
                    <SubTitle color={colors.text}>{planName}</SubTitle>
                    <StatusSubscription
                        background={colors.textError}
                        color={SharedColors.white}
                    >Cancelamento agendado</StatusSubscription>
                </ContentSubTitle>
                <ContentSubDescription>
                    <Description size={15} color={colors.text}>Vigência do plano:
                        <Bold>&nbsp;Sua assinatura está cancelada, mas você pode usar a assinatura
                            até {nextDueDateDDMMYYYY}</Bold></Description>
                    <Description size={15} color={colors.text}>Valor:
                        <Bold>&nbsp;{formatCurrency(planValue)}</Bold></Description>
                </ContentSubDescription>
            </ContentDescription>
            {!isLegacy && <ContentButton>
                <ButtonMain
                    style={{paddingLeft: 5, paddingRight: 5}}
                    widthPercentage={100}
                    height={35}
                    letterFontSize={15}
                    disabled={disabled}
                    loading={loading}
                    color={colors.accent}
                    type={"submit"}
                    letter={'Reativar assinatura'}
                    onClick={() => onClickReactivePlan()}
                />
            </ContentButton>}
            {isLegacy &&
                <ContentButton>
                    <ButtonMain
                        style={{paddingLeft: 5, paddingRight: 5}}
                        widthPercentage={100}
                        letterFontSize={15}
                        height={35}
                        disabled={disabled}
                        loading={loading}
                        color={colors.accent}
                        type={"submit"}
                        letter={'Assinar agora'}
                        onClick={() => onClickSelectPlan()}
                    />
                </ContentButton>
            }
        </>
    )
}
export default DetailCanceledPlan;
