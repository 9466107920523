import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import ExportDataToExcel from "@dropDesk/presentation/components/export_data_to_excel";
import {
    getEndHourCurrentDate,
    getEndHourUTC, getInitialHourCurrentDate,
    getInitialHourUTC
} from "@dropDesk/utils/helpers/date_helper";
import {TicketController} from "@dropDesk/presentation/pages/ticket/controller/ticket.controller";
import {getPeriodAndLabelDisplay} from "@dropDesk/utils/helpers/common";
import {useLocation} from "react-router-dom";

const ExportDataTicket = (observer(() => {

    const controller = useInjection(TicketController);
    const location = useLocation();
    const startDate: Date = location?.state?.startDate ?? getPeriodAndLabelDisplay().range[0] ?? new Date() as Date;
    const endDate: Date = location?.state?.endDate ?? getPeriodAndLabelDisplay().range[1] ?? new Date() as Date;

    const initialize = () => {
        controller.export({
            startDate: getInitialHourCurrentDate(startDate),
            endDate: getEndHourCurrentDate(endDate)
        }).then();

        return () => {
            controller.dispose();
        }
    }

    useEffect(() => {
        initialize();
    }, []);


    return (
        <ExportDataToExcel
            tableName={'atendimentos'}
            loading={controller.loading}
            loadingMessage={controller.loadingMessage}
            initExport={() => controller.export({
                startDate: getInitialHourCurrentDate(startDate),
                endDate: getEndHourCurrentDate(endDate)
            })}
            exportation={controller.exportation}
            period={{
                startDate: getInitialHourUTC(startDate),
                endDate: getEndHourUTC(endDate)
            }}
        />
    )

}));
export default ExportDataTicket;
