import {useEffect, useState} from "react";
import {isValidCNPJ, isValidCPF, isValidEmail, isValidName, isValidPassword} from "@dropDesk/utils/helpers/validators";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";

export const
    useValidatorCreateCompany = (company?: CompanyEntity, sector?: SectorEntity, user?: UserEntity) => {

        const [passwordVisible, setPasswordVisible] = useState(false);
        const [isValidData, setIsValidData] = useState(false);
        const [errorMessageCompanyName, setErrorMessageCompanyName] = useState('');
        const [errorMessageCompanySegment, setErrorMessageCompanySegment] = useState('');
        const [errorMessageName, setErrorMessageName] = useState('');
        const [errorMessageEmail, setErrorMessageEmail] = useState('');
        const [errorMessagePassword, setErrorMessagePassword] = useState('');
        const [errorMessageNameSector, setErrorMessageNameSector] = useState('');
        const [errorMessageContract, setErrorMessageContract] = useState('');
        const [errorMessageDocument, setErrorMessageDocument] = useState('');

        useEffect(() => {
            validate();
        }, [company, sector, user]);


        const validate = (): boolean => {

            setErrorMessageName('');
            setErrorMessagePassword('');
            setErrorMessageEmail('');
            setErrorMessageCompanyName('');
            setErrorMessageCompanySegment('');
            setErrorMessageNameSector('');
            setErrorMessageContract('');
            setErrorMessageDocument('');

            if (!company || !sector || !user) {
                setIsValidData(false);
                return false;
            }

            const isCPF = company.document.length <= 11;
            const isCNPJ = !isCPF;

            if (!isValidName(company.name)) {
                setErrorMessageCompanyName('O nome da empresa deve possuir mais de 2 letra');
                setIsValidData(false);
                return false;
            } else if (isCPF && !isValidCPF(company.document)) {
                setErrorMessageDocument('CPF inválido');
                setIsValidData(false);
                return false;
            } else if (isCNPJ && !isValidCNPJ(company.document)) {
                setErrorMessageDocument('CNPJ inválido');
                setIsValidData(false);
                return false;
            } else if (!company.segment) {
                setErrorMessageCompanySegment('Selecione o segmento da sua empresa');
                setIsValidData(false);
                return false;
            } else if (!isValidName(user.name)) {
                setErrorMessageName('O nome do atendente deve possuir mais de 2 letra');
                setIsValidData(false);
                return false;
            } else if (!isValidName(sector.name)) {
                setErrorMessageNameSector('O nome do setor deve possuir mais de 2 letra');
                setIsValidData(false);
                return false;
            } else if (!isValidEmail(user.email ?? '')) {
                setErrorMessageEmail('Informe um email válido');
                setIsValidData(false);
                return false;
            } else if (!isValidPassword(user.password ?? '')) {
                setErrorMessagePassword('Senha de acesso deve conter pelo menos 6 digitos.');
                setIsValidData(false);
                return false;
            } else if (company.contract === false) {
                setErrorMessageContract('leia e aceita o contrato.');
                setIsValidData(false);
                return false;
            } else {
                setIsValidData(true);
                return true;
            }

        };

        return {
            passwordVisible, setPasswordVisible,
            errorMessageCompanyName, setErrorMessageCompanyName,
            errorMessageCompanySegment, setErrorMessageCompanySegment,
            errorMessageName, setErrorMessageName,
            errorMessageEmail, setErrorMessageEmail,
            errorMessagePassword, setErrorMessagePassword,
            errorMessageNameSector, setErrorMessageNameSector,
            errorMessageDocument, setErrorMessageDocument,
            validate,
            isValidData
        }
    };
