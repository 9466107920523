import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Tooltip} from "antd";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ButtonRight} from "@dropDesk/presentation/components/inputs/input_with_border/button_top_one/styled";

const ButtonTopTwo = (
    {
        tooltipTwoButtonRight,
        onClickTwoButtonRight,
        disabledButtonRight,
        nameIconButtonRight,
        labelTwoButtonRight

    }: {
        tooltipTwoButtonRight?: string,
        onClickTwoButtonRight?: () => void;
        disabledButtonRight?: boolean;
        nameIconButtonRight?: string;
        labelTwoButtonRight?: string;

    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Tooltip title={tooltipTwoButtonRight ?? ''}>
            <div>
                <ButtonRight
                    style={{marginRight: 7}}
                    onClick={(event) => {
                        event.preventDefault();
                        if (onClickTwoButtonRight) {
                            onClickTwoButtonRight();
                        }
                    }}
                    accent={colors.accent}
                    border={colors.border}
                    background={colors.buttonOnBackground}
                    letterColor={colors.text}
                    disabled={disabledButtonRight}
                >
                    {nameIconButtonRight && <DropDeskIcon
                        icon={nameIconButtonRight}
                        size={11}
                        color={colors.text}
                        style={{
                            cursor: disabledButtonRight ? "no-drop" : "pointer",
                            marginRight: 0,
                        }}
                    />}
                    {labelTwoButtonRight ?? ''}
                </ButtonRight></div>
        </Tooltip>
    )
}
export default ButtonTopTwo;
