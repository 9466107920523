import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";

export class ChatConfigurationEntity {
    welcomeMessage!: string;
    chooseDepartmentMessage!: string;
    queueTicketMessage!: string;
    closedTicketMessage!: string;
    closedIdleTicketMessage!: string;
    outTimeOperatingMessage!: string;
    transferSectorMessage!: string;
    transferUserMessage!: string;
    successRatingTicketMessage!: string;
    displayNameUserInMessage!: boolean;
    useWelcomeMessage!: boolean;
    useQueueTicketMessage!: boolean;
    useClosedTicketMessage!: boolean;
    useOutTimeOperatingMessage!: boolean;
    useTransferSectorMessage!: boolean;
    useTransferUserMessage!: boolean;
    inactivityTime!: number;
    useClosedIdleTicketMessage!: boolean;
    useNotifyBeforeCloseIdleTicketMessage!: boolean;
    notificationBeforeCloseIdleTicketMessage!: string;
    autoCloseInactiveAttending!: boolean;
    autoCloseInactiveWaiting!: boolean;
    inactivityTransferSectorTime!: number;
    idSectorToTransfer?: string;
    sector?: SectorEntity;

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = [
            'sector',
        ];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    static fromJson(json: Record<string, any>): ChatConfigurationEntity {
        return new ChatConfigurationEntity({
            welcomeMessage: json['welcomeMessage'],
            chooseDepartmentMessage: json['chooseDepartmentMessage'],
            queueTicketMessage: json['queueTicketMessage'],
            closedTicketMessage: json['closedTicketMessage'],
            closedIdleTicketMessage: json['closedIdleTicketMessage'],
            outTimeOperatingMessage: json['outTimeOperatingMessage'],
            transferSectorMessage: json['transferSectorMessage'],
            transferUserMessage: json['transferUserMessage'],
            successRatingTicketMessage: json['successRatingTicketMessage'],
            displayNameUserInMessage: json['displayNameUserInMessage'],
            useWelcomeMessage: json['useWelcomeMessage'],
            useQueueTicketMessage: json['useQueueTicketMessage'],
            useClosedTicketMessage: json['useClosedTicketMessage'],
            useOutTimeOperatingMessage: json['useOutTimeOperatingMessage'],
            useTransferSectorMessage: json['useTransferSectorMessage'],
            useTransferUserMessage: json['useTransferUserMessage'],
            inactivityTime: json['inactivityTime'],
            useClosedIdleTicketMessage: json['useClosedIdleTicketMessage'],
            useNotifyBeforeCloseIdleTicketMessage: json['useNotifyBeforeCloseIdleTicketMessage'],
            notificationBeforeCloseIdleTicketMessage: json['notificationBeforeCloseIdleTicketMessage'],
            autoCloseInactiveAttending: json['autoCloseInactiveAttending'],
            autoCloseInactiveWaiting: json['autoCloseInactiveWaiting'],
            inactivityTransferSectorTime: json['inactivityTransferSectorTime'],
            idSectorToTransfer: json['idSectorToTransfer'],
            sector: json['sector'] ? SectorEntity.fromJson(json['sector']) : undefined,
        })
    }


    constructor({
                    displayNameUserInMessage,
                    welcomeMessage,
                    chooseDepartmentMessage,
                    queueTicketMessage,
                    closedTicketMessage,
                    closedIdleTicketMessage,
                    outTimeOperatingMessage,
                    transferSectorMessage,
                    transferUserMessage,
                    successRatingTicketMessage,
                    useWelcomeMessage,
                    useQueueTicketMessage,
                    useClosedTicketMessage,
                    useOutTimeOperatingMessage,
                    useTransferSectorMessage,
                    useClosedIdleTicketMessage,
                    useTransferUserMessage,
                    inactivityTime,
                    useNotifyBeforeCloseIdleTicketMessage,
                    notificationBeforeCloseIdleTicketMessage,
                    autoCloseInactiveAttending,
                    autoCloseInactiveWaiting,
                    inactivityTransferSectorTime,
                    idSectorToTransfer,
                    sector
                }: {
        displayNameUserInMessage: boolean;
        welcomeMessage: string;
        chooseDepartmentMessage: string;
        queueTicketMessage: string;
        closedTicketMessage: string;
        closedIdleTicketMessage: string;
        outTimeOperatingMessage: string;
        transferSectorMessage: string;
        transferUserMessage: string;
        successRatingTicketMessage: string;
        useWelcomeMessage: boolean;
        useQueueTicketMessage: boolean;
        useClosedTicketMessage: boolean;
        useOutTimeOperatingMessage: boolean;
        useTransferSectorMessage: boolean;
        useTransferUserMessage: boolean;
        useClosedIdleTicketMessage: boolean;
        inactivityTime: number;
        useNotifyBeforeCloseIdleTicketMessage: boolean;
        notificationBeforeCloseIdleTicketMessage: string;
        autoCloseInactiveAttending: boolean;
        autoCloseInactiveWaiting: boolean;
        inactivityTransferSectorTime: number;
        idSectorToTransfer?: string;
        sector?: SectorEntity;
    }) {
        Object.assign(this, {
            displayNameUserInMessage,
            welcomeMessage,
            chooseDepartmentMessage,
            queueTicketMessage,
            closedTicketMessage,
            closedIdleTicketMessage,
            outTimeOperatingMessage,
            transferSectorMessage,
            transferUserMessage,
            successRatingTicketMessage,
            useWelcomeMessage,
            useQueueTicketMessage,
            useClosedTicketMessage,
            useOutTimeOperatingMessage,
            useTransferSectorMessage,
            useClosedIdleTicketMessage,
            useTransferUserMessage,
            inactivityTime,
            useNotifyBeforeCloseIdleTicketMessage,
            notificationBeforeCloseIdleTicketMessage,
            autoCloseInactiveAttending,
            autoCloseInactiveWaiting,
            inactivityTransferSectorTime,
            idSectorToTransfer,
            sector
        });
    }

    copyWith({
                 displayNameUserInMessage,
                 welcomeMessage,
                 chooseDepartmentMessage,
                 queueTicketMessage,
                 closedTicketMessage,
                 closedIdleTicketMessage,
                 outTimeOperatingMessage,
                 transferSectorMessage,
                 transferUserMessage,
                 successRatingTicketMessage,
                 useWelcomeMessage,
                 useQueueTicketMessage,
                 useClosedTicketMessage,
                 useOutTimeOperatingMessage,
                 useTransferSectorMessage,
                 useClosedIdleTicketMessage,
                 useTransferUserMessage,
                 inactivityTime,
                 useNotifyBeforeCloseIdleTicketMessage,
                 notificationBeforeCloseIdleTicketMessage,
                 autoCloseInactiveAttending,
                 autoCloseInactiveWaiting,
                 inactivityTransferSectorTime,
                 sector
             }: {
        displayNameUserInMessage?: boolean;
        welcomeMessage?: string;
        chooseDepartmentMessage?: string;
        queueTicketMessage?: string;
        closedTicketMessage?: string;
        closedIdleTicketMessage?: string;
        outTimeOperatingMessage?: string;
        transferSectorMessage?: string;
        transferUserMessage?: string;
        successRatingTicketMessage?: string;
        useWelcomeMessage?: boolean;
        useQueueTicketMessage?: boolean;
        useClosedTicketMessage?: boolean;
        useOutTimeOperatingMessage?: boolean;
        useTransferSectorMessage?: boolean;
        useClosedIdleTicketMessage?: boolean;
        useTransferUserMessage?: boolean;
        inactivityTime?: number;
        useNotifyBeforeCloseIdleTicketMessage?: boolean;
        notificationBeforeCloseIdleTicketMessage?: string;
        autoCloseInactiveAttending?: boolean;
        autoCloseInactiveWaiting?: boolean;
        inactivityTransferSectorTime?: number;
        sector?: SectorEntity;
    }): ChatConfigurationEntity {
        return new ChatConfigurationEntity({
            displayNameUserInMessage: displayNameUserInMessage ?? this.displayNameUserInMessage,
            welcomeMessage: welcomeMessage ?? this.welcomeMessage,
            chooseDepartmentMessage: chooseDepartmentMessage ?? this.chooseDepartmentMessage,
            queueTicketMessage: queueTicketMessage ?? this.queueTicketMessage,
            closedTicketMessage: closedTicketMessage ?? this.closedTicketMessage,
            closedIdleTicketMessage: closedIdleTicketMessage ?? this.closedIdleTicketMessage,
            outTimeOperatingMessage: outTimeOperatingMessage ?? this.outTimeOperatingMessage,
            transferSectorMessage: transferSectorMessage ?? this.transferSectorMessage,
            transferUserMessage: transferUserMessage ?? this.transferUserMessage,
            successRatingTicketMessage: successRatingTicketMessage ?? this.successRatingTicketMessage,
            useWelcomeMessage: useWelcomeMessage ?? this.useWelcomeMessage,
            useQueueTicketMessage: useQueueTicketMessage ?? this.useQueueTicketMessage,
            useClosedTicketMessage: useClosedTicketMessage ?? this.useClosedTicketMessage,
            useOutTimeOperatingMessage: useOutTimeOperatingMessage ?? this.useOutTimeOperatingMessage,
            useTransferSectorMessage: useTransferSectorMessage ?? this.useTransferSectorMessage,
            useClosedIdleTicketMessage: useClosedIdleTicketMessage ?? this.useClosedIdleTicketMessage,
            useTransferUserMessage: useTransferUserMessage ?? this.useTransferUserMessage,
            inactivityTime: inactivityTime ?? this.inactivityTime,
            useNotifyBeforeCloseIdleTicketMessage: useNotifyBeforeCloseIdleTicketMessage ?? this.useNotifyBeforeCloseIdleTicketMessage,
            notificationBeforeCloseIdleTicketMessage: notificationBeforeCloseIdleTicketMessage ?? this.notificationBeforeCloseIdleTicketMessage,
            autoCloseInactiveAttending: autoCloseInactiveAttending ?? this.autoCloseInactiveAttending,
            autoCloseInactiveWaiting: autoCloseInactiveWaiting ?? this.autoCloseInactiveWaiting,
            inactivityTransferSectorTime: inactivityTransferSectorTime ?? this.inactivityTransferSectorTime,
            idSectorToTransfer: sector ? sector.id : this.idSectorToTransfer,
            sector: sector ?? this.sector,
        })
    }
}
