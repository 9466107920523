import {observer} from "mobx-react";
import {Tooltip} from "antd";
import {
    Button,
    ContainerRightButtons,
    ContentButton,
    ContentIcon
} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import EmojiPicker from "@dropDesk/presentation/components/emoji_picker";
import {Popover} from 'antd';
import PickFileGeneric from "@dropDesk/presentation/components/drop_zone/pick_file_generic";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ContainerButtonInput} from "@dropDesk/presentation/components/chat_view/chat_message/styled";


const LeftButtons = observer(
    ({
         handleSelectEmoji,
         handlePickFile,
         visible
     }: {
         handleSelectEmoji: (emoji: string) => void
         handlePickFile: (files: File[]) => void
         visible: boolean
     }
    ) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <>
                {visible && <ContainerRightButtons>
                    <Tooltip title={`Enviar arquivos`}>
                        <ContainerButtonInput
                            accent={colors.accent}
                            background={colors.buttonOnBackground}
                        >
                            <ContentButton>
                                <PickFileGeneric
                                    maxFiles={20}
                                    maxSizeInMegaBytes={16}
                                    onPick={(files: File[]) => handlePickFile(files)}
                                    noClick={false}
                                    isMultiple={true}
                                    noDrag={true}
                                    disabled={false}
                                    renderComponent={

                                        <DropDeskIcon
                                            style={{cursor: 'pointer'}}
                                            icon={ICONS_DROPDESK.paperclip}
                                            size={21}
                                        />

                                    }
                                />
                            </ContentButton>
                        </ContainerButtonInput>
                    </Tooltip>
                    <Popover
                        color={colors.backgroundInput}
                        placement="top"
                        trigger="click"
                        title=""
                        content={<EmojiPicker onEmojiSelect={(emoji) => {
                            handleSelectEmoji(emoji)
                        }}/>}>
                        <ContainerButtonInput
                            accent={colors.accent}
                            background={colors.buttonOnBackground}
                        >
                            <ContentButton>
                                <Button
                                    onClick={() => {
                                    }}
                                >

                                    <DropDeskIcon
                                        icon={ICONS_DROPDESK.smileyFill}
                                        size={21}
                                    />
                                </Button>
                            </ContentButton>
                        </ContainerButtonInput>
                    </Popover>


                </ContainerRightButtons>}
            </>
        )
    });
export default LeftButtons
