import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import ExportDataToExcel from "@dropDesk/presentation/components/export_data_to_excel";
import {SectorController} from "@dropDesk/presentation/pages/sector/controller/sector.controller";

const ExportDataSector = (observer(() => {

  const controller = useInjection(SectorController);

  const initialize = () => {
    controller.export().then();

    return () => {
      controller.dispose();
    }
  }

  useEffect(() => {
    initialize();
  }, []);

  return (
    <ExportDataToExcel
      tableName={'setores'}
      loading={controller.loading}
      loadingMessage={controller.loadingMessage}
      initExport={() => controller.export()}
      exportation={controller.exportation}
    />
  )

}));
export default ExportDataSector;
