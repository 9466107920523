import styled from "styled-components";
import {
    ContainerType,
    TextInformationType
} from "@dropDesk/presentation/components/modals/modal_information_report/modal_information_report";


export const Container = styled.div<ContainerType>`
  display: flex;
  width: 400px;
  height: 450px;
  min-height: 450px;
  overflow: auto;
  flex-direction: column;
  border-radius: 7px;
  background: ${(props) => props.background};
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`;
export const TextInformation = styled.span<TextInformationType>`
  font-size: 16px;
  text-align: left;
  color: ${props => props.color};
`;
export const ContentIcon = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ContentInformation = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
