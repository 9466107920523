import {Button, Container, ButtonBack} from "./styled";
import React from "react";
import {
    FooterConfigurationProps
} from "@dropDesk/presentation/components/footer/footer_configuration/footer_configuration";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export default function FooterConfiguration(props: FooterConfigurationProps) {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (

        <Container>

            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Button
                    disabled={props.disabled}
                    background={colors.accent}
                    shadow={colors.accent}
                    border={colors.border}
                    type={props.type}
                    letterColor={SharedColors.white}
                    onClick={props.disabled ? () => {
                    } : props.onSubmit}>
                    {!props.loading && props.letter}
                    {props.loading &&
                        <SpinLoading
                            color={SharedColors.white}
                            size={"small"}
                        />}
                </Button>
            </div>

            {props.letterLeftButton && props.osRequestLeftButton && <div>
                <ButtonBack
                    disabled={false}
                    background={colors.onBackground}
                    type={props.type}
                    letterColor={colors.text}
                    border={colors.border}
                    shadow={colors.accent}
                    onClick={() => props.osRequestLeftButton!()}>
                    {props.letterLeftButton}
                </ButtonBack>
            </div>}

        </Container>
    );
}
