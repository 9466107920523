import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {
    CompanyConfigurationRepository
} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";

@injectable()
export class SetThemeCompanyUseCase {
    private _repository: CompanyConfigurationRepository;

    constructor(@inject(CompanyConfigurationRepository) repository: CompanyConfigurationRepository) {
        this._repository = repository;
    }

    public call(name?: string, fileLogoBrand?: File, removeLogo?: boolean): Promise<Record<string, boolean>> {
        return this._repository.setTheme(name, fileLogoBrand, removeLogo);
    }
}

