import {AddressesType} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/addresses";
import {
    ContainerListAddress,
    ContentDataInput,
    ContentInput,
    ContainerDataInputs,
    ContentButtonDelete,
    ContainerButtonHeader
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import React from "react";
import {isValidCEP} from "@dropDesk/utils/helpers/validators";
import {Tooltip} from "antd";

import {
    useValidatorInputAddress
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/validator";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {getCitiesFromState, statesMaps} from "@dropDesk/domain/entities/common/common_maps";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export default function ListInputsAddresses(
    {address, onChange, disabled, onSearchZipCode, onDeleteAddress}:
        AddressesType,
) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const _updateAddress = (event: {
        target: { name: string; value: any; checked: any; type: string };
    }) => {
        const _address = address.copyWith({
            [event.target.name]:
                (event.target.type === "checkbox" || event.target.type === "switch") ?
                    event.target.checked : event.target.name === "telephone" ?
                        event.target.value.replace(/\D/g, "") : event.target.value,
            replacePinIfNull: event.target.name === 'pin',
            replaceComplementIfNull: event.target.name === 'complement',
            replaceAliasIfNull: event.target.name === 'alias',
            city: event.target.name === 'state' ? '' : event.target.name === 'city' ? event.target.value : address.city
        });
        onChange(_address);
    };

    const {
        errorMessageCity, errorMessageNeighborhood,
        errorMessageNumber, errorMessageShortState,
        errorMessageState, errorMessageStreet,
        errorMessageZipCode
    } = useValidatorInputAddress(address);

    return (
        <ContainerDataInputs key={address.id} borderColor={colors.border}>
            <ContainerListAddress>
                <ContentButtonDelete>
                    <Tooltip title={'Excluir endereço'}>
                        <ContainerButtonHeader
                            onClick={() => onDeleteAddress(address)}
                            background={colors.buttonOnBackground}
                            accent={colors.accent}
                        >
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.delete}
                                color={colors.textError}
                                size={18}
                            />
                        </ContainerButtonHeader>
                    </Tooltip>
                </ContentButtonDelete>
                <ContentDataInput>
                    <ContentInput style={{marginBottom: 25}}>
                        <InputWithBorder
                            name='alias'
                            size={35}
                            disabled={disabled}
                            placeHolder={"Matriz, Filial ..."}
                            label={"Identificação"}
                            value={address.alias ?? ''}
                            onChange={_updateAddress}
                        />
                    </ContentInput>
                    <ContentInput style={{marginLeft: 10, marginRight: 10}}>
                        <InputWithBorder
                            mask={'cep'}
                            required={true}
                            name='zipCode'
                            size={35}
                            placeHolder={'00000-000'}
                            removeMaskOnCopy={true}
                            disabled={disabled}
                            messageError={errorMessageZipCode}
                            label={"CEP"}
                            useButtonRight={true}
                            onClickButtonRight={() => onSearchZipCode(address)}
                            labelButtonRight={'Buscar'}
                            nameIconButtonRight={ICONS_DROPDESK.search}
                            tooltipButtonRight={isValidCEP(address.zipCode) ? '' : 'Informe um CEP válido para busca'}
                            disabledButtonRight={!isValidCEP(address.zipCode)}
                            //value={applyUnderscoreMask('_____-___', address.zipCode ?? '')}
                            value={address.zipCode ?? ''}
                            onChange={_updateAddress}
                        />

                    </ContentInput>
                    <ContentInput>
                        <InputWithBorder
                            required={true}
                            name='street'
                            size={35}
                            disabled={disabled}
                            messageError={errorMessageStreet}
                            label={"Logradouro"}
                            value={address.street ?? ''}
                            onChange={_updateAddress}
                        />
                    </ContentInput>
                </ContentDataInput>

                <ContentDataInput>
                    <ContentInput>
                        <InputWithBorder
                            required={true}
                            name='number'
                            size={35}
                            disabled={disabled}
                            messageError={errorMessageNumber}
                            label={"Número"}
                            value={address.number ?? ''}
                            onChange={_updateAddress}
                        />
                    </ContentInput>
                    <ContentInput style={{marginLeft: 10, marginRight: 10}}>
                        <InputWithBorder
                            required={true}
                            name='neighborhood'
                            size={35}
                            disabled={disabled}
                            messageError={errorMessageNeighborhood}
                            label={"Bairro"}
                            value={address.neighborhood}
                            onChange={_updateAddress}
                        />

                    </ContentInput>
                    <ContentInput style={{marginBottom: 25}}>
                        <InputWithBorder
                            name='complement'
                            size={35}
                            disabled={disabled}
                            label={"Complemento"}
                            value={address.complement ?? ''}
                            onChange={_updateAddress}
                        />
                    </ContentInput>
                </ContentDataInput>

                <ContentDataInput>

                    <ContentInput style={{marginRight: 10}}>
                        <SelectSearchMain<string>
                            required={true}
                            name='state'
                            placeHolder={"Selecione o estado"}
                            label={"Estado"}
                            messageError={errorMessageState}
                            size={35}
                            search={true}
                            options={statesMaps()}
                            onChange={(value) => {
                                _updateAddress(
                                    {
                                        target:
                                            {
                                                name: 'state',
                                                value: value,
                                                checked: false,
                                                type: 'input'
                                            }
                                    })
                            }}
                            value={address.state}
                        />

                    </ContentInput>
                    <ContentInput>
                        <SelectSearchMain<string>
                            required={true}
                            disabled={!address.state}
                            name='city'
                            placeHolder={"Selecione a cidade"}
                            label={"Cidade"}
                            messageError={errorMessageCity}
                            size={35}
                            search={true}
                            options={getCitiesFromState(address.state) ?? []}
                            onChange={(value) => {
                                _updateAddress(
                                    {
                                        target:
                                            {
                                                name: 'city',
                                                value: value,
                                                checked: false,
                                                type: 'input'
                                            }
                                    })
                            }}
                            value={address.city}
                        />

                    </ContentInput>
                </ContentDataInput>
            </ContainerListAddress>
        </ContainerDataInputs>

    );
}
