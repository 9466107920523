import styled from "styled-components";
import {Pagination, Table} from 'antd';
import {
    EmptyType,
    TableSearchType,
    ContainerType,
    PaginationType,
    ButtonGoType, ContentTableType,
} from "@dropDesk/presentation/components/tables/table_generic/table_generic";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;

`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
export const ContentTable = styled.div<ContentTableType>`
  max-height: calc(100vh - 205px + ${props => `${props.extraLayout}px`} + 0);
  display: flex;
  flex: 1;
`;
export const Empty = styled.span<EmptyType>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 500px;
  width: 100%;
`;

export const TableSearch = styled(Table)<TableSearchType<any>>`

  .ant-table-row-expand-icon {
    border: 1px solid ${props => props.border};
    background: ${props => props.backgroundlistableitems};
  }

  .ant-table-row-expand-icon:hover {
    border-color: ${props => props.backgroundmainbutton};
    color: ${props => props.backgroundmainbutton};

  }

  tr.ant-table-expanded-row > td {
    background: ${props => props.backgroundhover};
  }

  .table-wrapper table {
    max-height: calc(100vh - 240px + ${props => `${props.extraLayout}px`} + 0);
    overflow: auto;
    display: flex;

  }

  .table-wrapper {
    max-height: calc(100vh - 240px + ${props => `${props.extraLayout}px`} + 0);
    overflow: auto;
    display: flex;
  }

  .ant-table table {
    //border-collapse: separate;
    //border-spacing: 0 12px;
  }

  .ant-table {
    max-height: calc(100vh - 240px + ${props => `${props.extraLayout}px`} + 0);
    display: flex;
    background: transparent;
  }

  .ant-table-wrapper {
    display: flex;
    flex: 1;
    max-height: calc(100vh - 240px + ${props => `${props.extraLayout}px`} + 0);
    overflow: auto;
  }

  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    padding: 11px 8px;

  }

  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    padding: 0px 8px;
    height: 20px;
    border: none;
  }

  .ant-table-thead > tr > th {
    border: none;
  }

  .ant-spin-container::after {
    background: transparent;
  }

  .ant-table-container {
    max-height: calc(100vh - 240px + ${props => `${props.extraLayout}px`} + 0);
    overflow: auto;
    display: flex;
    flex: 1;
    flex-direction: column;

  }

  .ant-table-tbody {
    max-height: calc(100vh - 300px + ${props => `${props.extraLayout}px`} + 0);
    overflow: auto;
  }

  .ant-table-body {
    max-height: calc(100vh - 300px + ${props => `${props.extraLayout}px`} + 0);
    overflow: auto;
    display: flex;
    max-width: calc(100vw - ${props => `${props.decreaseWidth}px`});
    background: ${props => props.backgroundlistableitems};
  }

  .ant-table-content {
    max-height: calc(100vh - 240px + ${props => `${props.extraLayout}px`} + 0);
    overflow-x: auto !important;
    display: flex;
  }

  .ant-spin-nested-loading {
    display: flex;
    flex: 1;
    height: 100%;
      // height: calc(100vh - 220px - 10px + ${props => `${props.extraLayout}px`} + 0px);
    background: transparent;
  }

  .ant-spin-container {
    background: transparent;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-spin-dot-item {
    background: ${props => props.backgroundmainbutton};
  }

  .ant-spin-nested-loading > div > .ant-spin {
    // display: flex;
    max-height: calc(100vh - 220px - 10px + ${props => `${props.extraLayout}px`} + 0px);
    height: calc(100vh - 220px - 10px + ${props => `${props.extraLayout}px`} + 0px);
    height: 100% !important;
  }


  .ant-table-cell {
    color: ${props => props.lettermaincolor};
      //background: ${props => props.backgroundsecondarycolor};
    //height: 60px;

  }

  .ant-table.ant-table-middle .ant-table-tbody > tr > td:hover {
    //filter: brightness(80%);
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    //filter: brightness(80%);
    background: none !important;
  }

  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    height: ${props => `${props.heightTableRow}px`};
    max-height: ${props => `${props.heightTableRow}px`};
    min-height: ${props => `${props.heightTableRow}px`};
    background: ${props => props.backgroundsecondarycolor};
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${props => props.border};
  }

  .ant-table.ant-table-middle .ant-table-tbody > tr > td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .ant-table.ant-table-middle .ant-table-tbody > tr > td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: ${props => props.backgroundlistableitems};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${props => props.backgroundhover};
    //filter: brightness(80%);
  }

  .ant-table-thead th {
    background: ${props => props.backgroundtitle};
    color: ${props => props.hintInverse};
    //border: 0;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
  }

  .ant-table-thead {
    height: 40px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead td {
    background: ${props => props.backgroundlistableitems};
    color: ${props => props.lettermaincolor};
    // border: 0;
  }

  .ant-table {
      //height: calc(100vh - 240px + ${props => `${props.extraLayout}px`});
    overflow: auto;
  }

  .ant-table-sticky-holder {
    background: ${props => props.backgroundlistableitems};
    z-index: 0 !important;
    display: ${props => props.hideColumn ? 'none' : 'block'};
  }

  .ant-table-tbody > tr.ant-table-row:hover td .ant-table-tbody > tr.ant-table-row-selected > td {
    filter: brightness(80%);
    background: ${props => props.backgroundlistableitems};
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    filter: brightness(80%);
    background: ${props => props.backgroundlistableitems};
  }

  .ant-table-row td {
    font-size: 15px;
    color: ${props => props.lettermaincolor};
    // border: 0;
    border-color: ${props => props.border};
    border-width: 1px;
    cursor: pointer;
  }

  .ant-table-row td::-webkit-scrollbar {
    display: none;
  }


  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${(props) => props.backgroundmainbutton};
    border-color: ${(props) => props.backgroundmainbutton};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${(props) => props.checkedlettercolor};
  }

  .ant-checkbox-checked::after {
    border-color: ${(props) => props.checkedlettercolor};
  }


  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.backgroundmainbutton, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.letterbuttoncolor};
  }

  .ant-checkbox-inner {
    border-color: ${props => props.letterbuttoncolor};
    background: transparent;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background: ${(props) => props.backgroundmainbutton};
  }

  .ant-checkbox + span {
    padding-right: 0;
  }
`;
export const PaginationTable = styled(Pagination)<PaginationType>`
  margin-top: 7px;

  &.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
    height: 28px;
    line-height: 28px;
  }

  &.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
    height: 28px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${props => props.colorellipsis};
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${props => props.colorellipsis};
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: ${props => props.colorellipsis};
  }

  .ant-pagination-options-quick-jumper input {
    background: ${props => props.backgroundlistableitems};
    color: ${props => props.colorellipsis};
    border-color: ${props => props.border};
  }

  .ant-pagination-options-quick-jumper {
  }

  .ant-pagination-jump-prev .ant-pagination-item-container, .ant-pagination-jump-next .ant-pagination-item-container {
    color: ${props => props.lettermaincolor};
  }

  .ant-pagination-item {
    background: ${props => props.backgroundsecondarycolor};
    color: ${props => props.lettermaincolor};
  }


  .ant-pagination-item a {
    background: ${props => props.backgroundsecondarycolor};
    color: ${props => props.lettermaincolor};
    font-weight: bold;
    border: 1px solid ${props => props.border};
  }

  .ant-pagination-item-active a {
    color: ${props => props.backgroundmainbutton};
    border-color: ${props => props.backgroundmainbutton};
    background: ${props => props.backgroundsecondarycolor};
  }

  .ant-pagination-item-active {
    color: ${props => props.backgroundmainbutton};
    border-color: ${props => props.backgroundmainbutton};
    background: ${props => props.backgroundsecondarycolor};
  }

  .ant-pagination-item:hover a {
    color: ${props => props.backgroundmainbutton};
    border-color: ${props => props.backgroundmainbutton};
  }

  .ant-pagination-item:hover {
    color: ${props => props.backgroundmainbutton};
    border-color: ${props => props.backgroundmainbutton};
  }


  .ant-pagination-item-link {
    background: ${props => props.colorellipsis};
    color: ${props => props.colorellipsis};
  }

  .ant-pagination-item-link:hover {
    background: ${props => props.backgroundsecondarycolor};
    color: ${props => props.backgroundmainbutton};
    border-color: ${props => props.backgroundmainbutton};
  }

  .ant-pagination-options-quick-jumper input:hover {
    border-color: ${props => props.backgroundmainbutton};
  }

  .ant-pagination-options-quick-jumper input:focus, .ant-pagination-options-quick-jumper input-focused {
    border-color: ${props => props.backgroundmainbutton};
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.backgroundmainbutton, 0.20)}
  }

`;

export const ButtonGo = styled.button<ButtonGoType>`
  background: ${props => props.backgroundsecondarybutton};
  color: ${props => props.colorletterbutton};
  font-weight: bold;
  margin-left: 10px;
  padding: 0px 7px;
  border-radius: 3px;
  height: 28px;
  line-height: 28px;
  width: 44px;
  border: 1px solid ${props => props.border};
  letter-spacing: 1px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
