import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export type ContainerType = {
    background: string;
}

export type TextType = {
    color: string;
    size: number;
}

type IconType = {
    color: string;
    fontsize: number;
}

type ContainerButtonCopyPix = {
    background: string;
    accent: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  border-radius: 7px;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 1px 1px 5px 5px;
  padding: 0 16px 16px 16px;
  max-height: calc(100vh - 60px);
  overflow: auto;
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const TextArea = styled.textarea<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  width: 100%;
  background: transparent;
  border: none;
  resize: none;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 8px 0;
`;
export const ContentSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1px;
  margin: 8px 0;
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

export const ContainerButtonHeader = styled.div<ContainerButtonCopyPix>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ContainerPaymentInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
`;

export const ContainerPayment = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`;
