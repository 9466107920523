import styled from "styled-components";

export type TextType = {
    color: string;
    size: number;
}

export const ContentInfos = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;
