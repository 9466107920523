export class FileMessageEntity {
    id!: string;
    file!: File;
    description?: string;
    blobURL!: string;
    base64Url!: string;
    isPrivate!: boolean;
    loadableDesignState?: any;

    constructor({
                    id,
                    file,
                    description,
                    blobURL,
                    isPrivate,
                    base64Url,
                    loadableDesignState
                }: {
        id: string;
        file: File;
        description?: string;
        blobURL: string;
        isPrivate: boolean;
        base64Url: string;
        loadableDesignState?: any;
    }) {
        Object.assign(this, {
            id,
            file,
            description,
            blobURL,
            isPrivate,
            base64Url,
            loadableDesignState,
        });
    }

    copyWith({
                 id,
                 file,
                 description,
                 blobURL,
                 isPrivate,
                 base64Url,
                 loadableDesignState,
             }: {
        id?: string;
        file?: File;
        description?: string;
        blobURL?: string;
        isPrivate?: boolean;
        base64Url?: string;
        loadableDesignState?: any;

    }): FileMessageEntity {
        return new FileMessageEntity({
            id: id ?? this.id,
            file: file ?? this.file,
            description: description ?? this.description,
            blobURL: blobURL ?? this.blobURL,
            isPrivate: isPrivate ?? this.isPrivate,
            base64Url: base64Url ?? this.base64Url,
            loadableDesignState: loadableDesignState ?? this.loadableDesignState,
        })
    }
}
