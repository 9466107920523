import styled, {css, keyframes} from "styled-components";
import {
    AnimationType,
    ContainerHasPreviewImageType,
    ContainerType,
    TextType
} from "@dropDesk/presentation/components/avatar/avatar";
import {Image} from 'antd';
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
`;
const avatarLazy = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const ImagePreview = styled(Image)<AnimationType>`
  animation: ${props => props.islazyloading === 'true' ? css`${avatarLazy} normal 1s infinite ease-in-out` : 'none'};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ContainerHasPreviewImage = styled.div<ContainerHasPreviewImageType>`
  animation: ${props => props.alertPulseAnimation === 'true' ? css`${pulseAnimation} 2s infinite` : 'none'};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ContainerImage = styled.img<ContainerType>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => `${props.size}px`};
  max-height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.width ?? props.size}px`};
  border: ${(props) => props.withBorder ? `1px solid ${props.bordercolor}` : 'none'};
  border-radius: ${(props) => props.round ? '50%' : '5px'};
  background: ${(props) => props.background};
  cursor: ${(props) => props.useCursorPointer ? 'pointer' : 'auto'};
  animation: ${props => props.islazyloading === 'true' ? css`${avatarLazy} normal 1s infinite ease-in-out` : props.alertPulseAnimation === 'true' ? css`${pulseAnimation} 2s infinite` : 'none'};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const ContainerLetters = styled.button<ContainerType>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => `${props.size}px`};
  max-height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  border: ${(props) => props.withBorder ? `1px solid ${props.bordercolor}` : 'none'};
  border-radius: ${(props) => props.round ? '50%' : '5px'};
  background: ${(props) => props.background};
  cursor: ${(props) => props.useCursorPointer ? 'pointer' : 'auto'};
  animation: ${props => props.alertPulseAnimation === 'true' ? css`${pulseAnimation} 2s infinite` : 'none'};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
export const Letter = styled.span<TextType>`
  font-size: ${(props) => props.letterSize ? `${props.letterSize}px` : 'auto'};
  color: ${(props) => props.color};
  letter-spacing: ${(props) => props.useLetterSpace ? '1px' : 'auto'};
  display: contents;
`;

