import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {copyToClipboard} from "@dropDesk/utils/helpers/common";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import {
    Container,
    ContainerQrCodeText,
    Text
} from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/copy_and_paste/styled";

const CopyAndPastePix = (
    {
        qrcodeText,
        isPix
    }: {
        qrcodeText: string
        isPix: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container style={{marginTop: isPix ? 0 : 16}}>
            <ButtonMain
                height={35}
                letterFontSize={14}
                widthPercentage={100}
                disabled={false}
                borderRadius={20}
                loading={false}
                color={colors.accent}
                type={"submit"}
                letter={"Copiar código Pix"}
                onClick={() => {
                    copyToClipboard(qrcodeText);
                    toastMessage.success('Copiado para área de transferência');
                }}
            />
            {isPix && <ContainerQrCodeText>
                <Text size={11} color={colors.text}>
                    {qrcodeText}
                </Text>
            </ContainerQrCodeText>}
        </Container>
    )
}
export default CopyAndPastePix;
