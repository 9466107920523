import {ColorScheme} from "@dropDesk/domain/entities/theme/color_scheme";
import {Brightness, CustomColors} from "@dropDesk/domain/entities/theme/colors.entity";
import {AppCustomColors} from "@dropDesk/domain/entities/theme/app_cutom_colors";
import {ColorsType} from "@dropDesk/domain/entities/theme/colors_enum";

export class ThemeEntity {
    colorScheme!: ColorScheme;

    constructor(
        colors: CustomColors
    ) {

        if (colors.brightness == Brightness.light) {
            Object.assign(this, {colorScheme: ThemeEntity.light(colors)});
        } else {
            Object.assign(this, {colorScheme: ThemeEntity.dark(colors)});
        }
    }

    public static getThemeByString(value: string): ThemeEntity {
        let brightness: Brightness = Brightness.light;
        if (value.includes('dark')) {
            brightness = Brightness.dark;
        }

        const themeName = value.split('-')[1];

        if (themeName) {
            let themeColors: CustomColors = AppCustomColors.getColor(themeName as ColorsType);
            themeColors = themeColors.setBrightness(brightness);
            return new ThemeEntity(themeColors);
        }

        return new ThemeEntity(AppCustomColors.getColor(ColorsType.default));
    }

    private static light(colors: CustomColors): ColorScheme {
        return new ColorScheme({
            accent: colors.accent,
            background: colors.backgroundLight,
            backgroundInput: colors.backgroundInputLight,
            backgroundInputChat: colors.backgroundInputChatLight,
            border: colors.borderLight,
            buttonOnBackground: colors.buttonOnBackgroundLight,
            delicateBackground: colors.delicateBackgroundLight,
            disabled: colors.disabled,
            hint: colors.hintLight,
            hintInverse: colors.hintInverseLight,
            info: colors.info,
            onBackground: colors.onBackgroundLight,
            readMessage: colors.readMessage,
            hintTertiary: colors.hintTertiaryLight,
            success: colors.success,
            tertiary: colors.tertiaryLight,
            text: colors.textLight,
            textError: colors.textError,
            errorOnPrimary: colors.errorOnPrimary,
            textLink: colors.textLink,
            textOnAccent: colors.textOnAccentBackgroundLight,
            warning: colors.warning,
            name: colors.name,
            brightness: Brightness.light
        });
    }

    private static dark(colors: CustomColors): ColorScheme {
        return new ColorScheme({
            accent: colors.accent,
            background: colors.backgroundDark,
            backgroundInput: colors.backgroundInputDark,
            backgroundInputChat: colors.backgroundInputChatDark,
            border: colors.borderDark,
            buttonOnBackground: colors.buttonOnBackgroundDark,
            delicateBackground: colors.delicateBackgroundDark,
            disabled: colors.disabled,
            hint: colors.hintDark,
            hintInverse: colors.hintInverseDark,
            hintTertiary: colors.hintTertiaryDark,
            info: colors.info,
            onBackground: colors.onBackgroundDark,
            readMessage: colors.readMessageDark,
            success: colors.success,
            tertiary: colors.tertiaryDark,
            text: colors.textDark,
            textError: colors.textError,
            errorOnPrimary: colors.errorOnPrimary,
            textLink: colors.textLink,
            textOnAccent: colors.textOnAccentBackgroundDark,
            warning: colors.warning,
            name: colors.name,
            brightness: Brightness.dark
        });
    }

}
