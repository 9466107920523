import styled from "styled-components";

type TextType = {
    color: string;
};

type ContentLoadFile = {
    color: string;
    background: string;
};
export const ContentIconUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const TextUpload = styled.span<TextType>`
  color: ${(props) => props.color};
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ContentLoadFile = styled.div<ContentLoadFile>`
  display: flex;
  background: ${(props) => props.background};
  border: ${(props) => `1px solid ${props.color}`};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 8px 0;
  border-radius: 5px;
  padding: 8px 0;
`;
export const TextFileAttached = styled.span<TextType>`
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.color};
  text-align: center;
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 2px;
  padding: 5px;
`;
export const DescriptionFile = styled.span<TextType>`
  color: ${(props) => props.color};
`;
