import styled from "styled-components";

type ContainerType = {
    background: string;
    border: string;
};

type TextType = {
    color: string;
};


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
  overflow: auto;
  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
  }
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  background: ${(props) => props.background};
  overflow: auto;
  flex-direction: column;
  padding: 32px;
`;

export const ContentWelcomeAndClosedButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
`;

export const TextWelcome = styled.span<TextType>`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 25px;
  color: ${props => props.color};
`;
export const ButtonClosed = styled.button<TextType>`
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${props => props.color};

  &:hover {
    filter: brightness(80%);
  }
`;
export const TextClosed = styled.span`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const IconClosed = styled.i`
  font-size: 13px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 2px;
`;

export const ContentSubTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TextSubTitle = styled.span<TextType>`
  display: flex;
  font-size: 16px;
  color: ${props => props.color};
`;

export const ContentConfirmEmail = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  margin: 42px 0;
  background: ${props => props.background};
  border-radius: 5px;
  align-items: flex-start;
  justify-content: space-around;
  min-height: 130px;
  border: 1px solid ${props => props.border};
`;
export const TitleConfirmEmail = styled.span<TextType>`
  display: flex;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.color};
`;
export const TitleInformToken = styled.span<TextType>`
  display: flex;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.color};
  margin: 20px 0 10px 0;
`;


export const SubTitleConfirmEmail = styled.span<TextType>`
  font-size: 14px;
  color: ${props => props.color};
  margin: 5px 0;
`;

export const TextWeight = styled.span`
  font-weight: bold;
`;
export const TextLink = styled.button<TextType>`
  font-weight: bold;
  color: ${props => props.color};
  text-decoration: underline;
  border: 0;
  background: transparent;

  &:hover {
    filter: brightness(80%);
  }
`;

export const ContentReSend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  align-items: flex-start;
  justify-content: space-between;
  height: 60px;
`;
export const ContentPin = styled.div`
  margin: 17px 0;
`;

export const ContentButtonSend = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DivButtonSend = styled.div`
  width: 250px;
`;

export const Section = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;
