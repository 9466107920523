import {UserEntity, UserExtraInfoEntity} from "@dropDesk/domain/entities/user/user.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {InfoOrigin} from "@dropDesk/domain/entities/generic/info_origin.enum";

export const newUser = (): UserEntity => new UserEntity({
    id: '',
    idCompany: getIdCompanyByLocalStorage(),
    name: '',
    email: '',
    userMain: false,
    telephone: null,
    permissionAdmin: false,
    permissionScreenReports: false,
    permissionScreenUsers: false,
    permissionScreenWarnings: false,
    permissionScreenClients: false,
    permissionScreenSectors: false,
    permissionCancelTicket: false,
    permissionDeleteData: false,
    isMyContact: false,
    deleted: false,
    blocked: false,
    fcmToken: null,
    urlImageProfile: null,
    role: UserRole.attendant,
    origin: InfoOrigin.system,
    idClient: null,
    client: null,
    identity: '',
    createdAt: undefined,
    password: '',
    sectors: [],
    renewToken: undefined,
    renewedAt: undefined,
    verified: false,
    theme: 'light-green',
    extraInfo: new UserExtraInfoEntity({
        permissionJustListUserTicket: false
    })
});
