import {
    Container, ContainerInput,
    Content,
    ContentInput,
    ContentProtocol, TextProtocol, TextTicketNumber
} from "@dropDesk/presentation/components/chat_view/modals/inputs_more_information/styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import React from "react";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {timeSpentMap} from "@dropDesk/domain/entities/ticket/ticket_maps";

import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import DropDeskLoadingDefault from "@dropDesk/presentation/components/loadings/loading_table";


const InputsMoreInformation = observer((
    {
        ticket,
        loading
    }: {
        ticket?: TicketEntity,
        loading: boolean
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            {(!ticket || loading) &&
                <DropDeskLoadingDefault
                    height={80}
                    description={"Carregando ..."}
                />
            }
            {!!ticket && !loading &&
                <Content>
                    <ContentProtocol>
                        <TextProtocol color={colors.text}>Protocolo: </TextProtocol>
                        <TextTicketNumber color={colors.text}>#{ticket.number}</TextTicketNumber>
                    </ContentProtocol>
                    <ContainerInput>
                        <ContentInput>
                            <InputWithBorder
                                name='client'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Empresa"}
                                value={ticket.client?.socialName ?? ''}
                                useButtonRight={false}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                        <ContentInput>
                            <InputWithBorder
                                name='contact'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Contato"}
                                value={ticket.userClient?.name ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContainerInput>

                    <ContainerInput>
                        <ContentInput>
                            <InputWithBorder
                                name='userAttendant'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Atendente"}
                                value={ticket.attendant?.name ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                        <ContentInput>
                            <InputWithBorder
                                name='sector'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Setor"}
                                value={ticket.sector?.realName(appController.user!) ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContainerInput>
                    {!appController.isUserClient &&
                        <ContainerInput>
                            <ContentInput>
                                <InputWithBorder
                                    name='dateDue'
                                    size={37}
                                    readOnly={true}
                                    sizeRightIcons={12}
                                    marginRightRightIcons={10}
                                    label={`Data de vencimento${ticket.overdue ? ' (Prazo vencido)' : ''}`}
                                    color={ticket.overdue ? colors.textError : undefined}
                                    value={ticket.dateDue ? displayDateToLocale(ticket.dateDue ?? '') : ''}
                                    onChange={() => {
                                    }}
                                />
                            </ContentInput>
                            <ContentInput>
                                <InputWithBorder
                                    name='priority'
                                    size={37}
                                    readOnly={true}
                                    sizeRightIcons={12}
                                    marginRightRightIcons={10}
                                    label={"Prioridade"}
                                    value={ticket.getPriority}
                                    onChange={() => {
                                    }}
                                />
                            </ContentInput>
                        </ContainerInput>
                    }
                    <ContainerInput>
                        <ContentInput>
                            <InputWithBorder
                                name='status'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Status do atendimento"}
                                value={ticket.getStatus}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                        <ContentInput>
                            <InputWithBorder
                                name='openedBy'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Atendimento criado por"}
                                value={ticket.openedByUser?.name ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContainerInput>
                    {!appController.isUserClient &&
                        <ContainerInput>
                            <ContentInput>
                                <InputWithBorder
                                    name='origin'
                                    size={37}
                                    readOnly={true}
                                    sizeRightIcons={12}
                                    marginRightRightIcons={10}
                                    label={"Canal de abertura"}
                                    value={ticket.getOrigin}
                                    onChange={() => {
                                    }}
                                />
                            </ContentInput>

                            <ContentInput>
                                <InputWithBorder
                                    name='timeSpent'
                                    size={37}
                                    readOnly={true}
                                    sizeRightIcons={12}
                                    marginRightRightIcons={10}
                                    label={"Tempo gasto no atendimento"}
                                    value={!!ticket.timeSpent ? timeSpentMap.find((timeSpentMap => timeSpentMap.value === ticket.timeSpent))?.label.replace("+", "") ?? '' : ""}
                                    onChange={() => {
                                    }}
                                />
                            </ContentInput>
                        </ContainerInput>
                    }
                    <ContainerInput>
                        <ContentInput>
                            <InputWithBorder
                                name='createdAt'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={"Data de abertura"}
                                value={displayDateToLocale(ticket.createdAt ?? '')}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                        <ContentInput>
                            <InputWithBorder
                                name='dateClosed'
                                size={37}
                                readOnly={true}
                                sizeRightIcons={12}
                                marginRightRightIcons={10}
                                label={ticket.hasCanceledStatus ? "Data de cancelamento" : "Data de fechamento"}
                                value={ticket.dateClosed ? displayDateToLocale(ticket.dateClosed ?? '') : ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContainerInput>

                    <ContainerInput>
                        <ContentInput>
                            <InputTextAreaWithBorder
                                name='description'
                                size={65}
                                readOnly={true}
                                disabled={false}
                                label={"Descrição do problema"}
                                value={ticket.realDescription(appController.user?.company.configurations.ticket.useProblemStatic ?? false)}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContainerInput>

                    {ticket.descriptionClosed && !appController.isUserClient && <ContainerInput>
                        <ContentInput>
                            <InputTextAreaWithBorder
                                name='descriptionClosed'
                                size={65}
                                label={ticket.hasCanceledStatus ? "Motivo do cancelamento" : "Descrição da resolução"}
                                value={ticket.realDescriptionClosed ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContainerInput>}
                </Content>}
        </Container>

    )
});
export default InputsMoreInformation;
