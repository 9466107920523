import {FileMessageEntity} from "@dropDesk/domain/entities/common/file_message";
import firebase from "firebase/storage";
import UploadTask = firebase.UploadTask;

export class UploadOptionsEntity {
    progress!: number;
    file!: FileMessageEntity;
    referenceUploadTask?: UploadTask;
    urlDownload?: string;

    constructor(
        {
            progress,
            file,
            referenceUploadTask,
            urlDownload
        }: {
            progress: number;
            file: FileMessageEntity;
            referenceUploadTask?: UploadTask;
            urlDownload?: string;
        }) {
        Object.assign(this, {
            progress,
            file,
            referenceUploadTask,
            urlDownload
        });
    }

    copyWith({
                 progress,
                 file,
                 referenceUploadTask,
                 urlDownload
             }: {
        progress?: number;
        file?: FileMessageEntity;
        referenceUploadTask?: UploadTask;
        urlDownload?: string;
    }): UploadOptionsEntity {
        return new UploadOptionsEntity({
            progress: progress ?? this.progress,
            file: file ?? this.file,
            referenceUploadTask: referenceUploadTask ?? this.referenceUploadTask,
            urlDownload: urlDownload ?? this.urlDownload,
        })
    }
}
