import {useState} from "react";

export const useModalImportClients = () => {

  const [visibleModalUndoImport, setVisibleModalUndoImport] = useState<{ visible: boolean, id: string }>({visible: false, id: ''});


  return {
    visibleModalUndoImport, setVisibleModalUndoImport
  }
}
