import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {Actions, Orders, Status, Type} from "@dropDesk/domain/entities/task/task_enum";

@injectable()
export abstract class TaskRemoteDataSource {

    public abstract set(task: TaskEntity): Promise<TaskEntity>;

    public abstract list(
        type: Type, order: Orders, status: Status, page: number, limit: number
    ): Promise<ListPaginationEntity<TaskEntity>>;

    public abstract delete(id: string): Promise<void>;

    public abstract markAsDoneAndUndone(id: string, action: Actions): Promise<Record<string, unknown>>;

}

@injectable()
export class TaskRemoteDataSourceImpl implements TaskRemoteDataSource {

    constructor() {
    }

    baseUrl: string = 'tasks';
    setUrl: string = `${this.baseUrl}/set`;


    public async list(
        type: Type, order: Orders, status: Status, page: number, limit: number
    ): Promise<ListPaginationEntity<TaskEntity>> {
        return new Promise<ListPaginationEntity<TaskEntity>>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.baseUrl}/${type ?? 'orderbydate'}/${order ?? 'desc'}/${status ?? 'all'}/${page}/${limit}`);

                const result = new ListPaginationEntity<TaskEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: TaskEntity) => new TaskEntity({...entry})),
                });

                return resolve(result);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public markAsDoneAndUndone(id: string, action: Actions): Promise<Record<string, unknown>> {
        return new Promise<Record<string, unknown>>(async (resolve, reject) => {
            try {

                const response = await api.put(`${this.baseUrl}/mark/${id}/${action}`);
                return resolve(response.data);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public set(task: TaskEntity): Promise<TaskEntity> {
        return new Promise<TaskEntity>(async (resolve, reject) => {
            try {

                const response = await api.post(this.setUrl, task);
                return resolve(response.data);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public delete(id: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                await api.delete(this.baseUrl + `/${id}`);
                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

}
