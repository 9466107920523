import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, ContentButton} from "./styled";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import React from "react";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";


const Footer = (observer((
    {
        onClickConfirm
    }: {
        onClickConfirm: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (

        <Container>
            <ContentButton>
                <ButtonMain
                    style={{paddingLeft: 5, paddingRight: 5}}
                    widthPercentage={100}
                    letterFontSize={ConstantsStyles.inputFontSize}
                    disabled={false}
                    loading={false}
                    color={colors.accent}
                    type={"submit"}
                    letter={'Continuar'}
                    onClick={() => onClickConfirm()}
                />
            </ContentButton>
        </Container>

    );
}))
export default Footer;
