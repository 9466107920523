import {observer} from "mobx-react";
import React, {useContext, useState} from "react";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {Tooltip} from "antd";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ContainerAction} from "./styled";
import ButtonActionTable from "@dropDesk/presentation/components/buttons/button_action_table";
import {useNavigate} from "react-router-dom";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {useBetween} from "use-between";
import {useModalSearchSector} from "@dropDesk/presentation/pages/sector/ui/search/modals/handle_change_visible";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import Tag from "@dropDesk/presentation/components/tag";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const TableSectors = observer(
    ({
         setSector,
         searchParam,
         loading,
         permitSelectionRow,
         tableSectors,
         getDataFromPage,
         setRowSelectionSectors,
         rowSelectionSectors,
         heightGarbage,
         permissionDeleteData
     }: {
        setSector: (sector: SectorEntity) => void,
        searchParam: string,
        loading: boolean,
        permitSelectionRow: boolean,
        tableSectors: ListPaginationEntity<SectorEntity>,
        getDataFromPage: (page: number) => void,
        setRowSelectionSectors: (sector: SectorEntity[]) => void,
        rowSelectionSectors: SectorEntity[]
        permissionDeleteData: boolean,
        heightGarbage: number
    }) => {


        const navigate = useNavigate();
        const collapsed = useContext(MenuCollapsedContext);

        const {
            setVisibleModalDelete,
            setVisibleModalRestore,
        } = useBetween(useModalSearchSector);

        const setRestore = (sector: SectorEntity) => {
            setRowSelectionSectors([sector]);
            setVisibleModalRestore(true);
        }

        const tableColumn = [

            {
                title: 'Nome', ellipsis: true, dataIndex: 'name', key: 'id', render: (text: string) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Observação', ellipsis: true, dataIndex: 'note', key: 'id', render: (text: string) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Listável para clientes',
                key: 'id',
                dataIndex: 'listableForClients',
                render: (text: string, record: SectorEntity) => {
                    return (
                        <Tag
                            label={!record.listableForClients ? 'Não' : 'Sim'}
                            width={44}
                        />
                    )
                }
            },
            {
                title: 'Criado em', ellipsis: true, dataIndex: 'createdAt', key: 'id', render: (text: string) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },
            {
                title: '',
                key: 'action',
                width: permissionDeleteData ? 65 : 40,
                render: (text: string, record: SectorEntity) => (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <ContainerAction>
                            {!record.deleted && permissionDeleteData &&
                                <Tooltip placement="left" title={'Excluir Setor'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRowSelectionSectors([record]);
                                        setVisibleModalDelete(true)
                                    }}
                                    nameIcon={ICONS_DROPDESK.delete}
                                    isActionDelete={true}
                                /> </Tooltip>}
                            {record.deleted &&
                                <Tooltip placement="left" title={'Restaurar Setor'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRestore(record);
                                    }}
                                    nameIcon={ICONS_DROPDESK.restore}
                                /> </Tooltip>

                            }
                        </ContainerAction>
                    </div>
                ),
            },
        ];
        const rowSelection = {
            selectedRowKeys: rowSelectionSectors.map((sector) => sector.id),
            onChange: (selectedRowKeys: React.Key[], selectedRows: SectorEntity[]) => {
                setRowSelectionSectors(selectedRows)
            },
            getCheckboxProps: (record: SectorEntity) => ({
                disabled: false,
            }),
            preserveSelectedRowKeys: true
        };


        return (
            <TableGeneric<SectorEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableSectors.data}
                onSelectedRow={(sector: SectorEntity) => {
                    if (!sector.deleted) {
                        navigate(`${RoutesEnum.sectors}/${sector.id}`);
                    } else {
                        setRestore(sector);
                    }
                }}
                loading={loading}
                labelNoResults={'Nenhum setor encontrado na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableSectors.totalRows}
                page={tableSectors.page}
                pageSize={tableSectors.limit}
                rowSelection={permitSelectionRow ? rowSelection : null}
                onChange={(page) => getDataFromPage(page - 1)}
                extraLayout={heightGarbage}
            />
        )
    })
export default TableSectors;
