import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
    border: string;
    isDarkTheme: boolean;
}

type TextType = {
    color: string;
    size: number;
}

type BorderType = {
    color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3px 0px 16px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 700px;
`;
export const ContainerPaymentInfo = styled.div<ContainerType>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background: ${props => props.background};
  border-radius: 5px;
  padding: 8px 16px;
  border: 1px solid ${props => props.border};
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const ContentText = styled.div`
  display: flex;
  margin: 1px 0;
`;

export const ContentCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const CheckBox = styled.div<BorderType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  border-radius: 3px;
  border: 1px solid ${props => props.color};
  margin-right: 5px;
`;


export const ContentPaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContentEditPayment = styled.div`
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.button<TextType>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  background: transparent;
  border: 0;
  font-size: ${(props) => props.size}px;
  cursor: pointer;
  font-weight: 1000;
  color: ${props => props.color};
  text-decoration: underline;

  &:hover {
    filter: brightness(80%);
  }
`;
