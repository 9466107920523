import React, {useEffect} from "react";
import {
    Container, ContainerAverageTimes, Content,
    ContentTextRateScale, TextAverageTime,
} from "./styled";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import {minutesToHours} from "@dropDesk/utils/helpers/date_helper";
import {
    ReportTicketAggregateController
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/controller/report_ticket_aggregate.controller";
import PanelAverageTime from "@dropDesk/presentation/components/graphics/panel_average_time";
import {useBetween} from "use-between";
import {
    useModalsReportTicketAggregate
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/ui/modals/handle_change_visible";
import ModalsReportTicketAggregate
    from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/ui/modals";
import SearchBar from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/ui/search_bar";
import GraphicsReportTicketAggregate
    from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/ui/graphics";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {t} from "i18next";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import TagEvaluation from "@dropDesk/presentation/components/tag/tag_evaluation";

const ReportTicketsAggregate = (observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const controller = useInjection(ReportTicketAggregateController);
    const hasDataInGraphics: boolean = controller.hasData(controller.lineChartClientsAggregate) || controller.hasData(controller.lineChartUsersAggregate) ||
        controller.hasData(controller.lineChartSectorsAggregate) || controller.hasData(controller.lineChartDescriptionsStaticAggregate);

    useEffect(() => {
        controller.initialize();
    }, []);

    const {
        setTextHeaderModalInformationReport,
        setTextInformationBody,
        setTextInformationHeader,
        setVisibleModalInformationReport
    } = useBetween(useModalsReportTicketAggregate);

    const renderTextRateScale = (rate: number) => {
        if (rate >= 5) {
            return <TextAverageTime color={colors.hintInverse}>Excelente</TextAverageTime>

        } else if (rate >= 4 && rate < 5) {
            return <TextAverageTime color={colors.hintInverse}>Ótimo</TextAverageTime>

        } else if (rate >= 3 && rate < 4) {
            return <TextAverageTime color={colors.hintInverse}>Bom</TextAverageTime>

        } else if (rate >= 2 && rate < 3) {
            return <TextAverageTime color={colors.hintInverse}>Regular</TextAverageTime>

        } else {
            return <TextAverageTime color={colors.hintInverse}>Ruim</TextAverageTime>
        }
    }

    const onSuccess = (key: string) => {
        toastMessage.success(t(key));
    }

    return (

        <Container background={colors.onBackground} heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>
            <SearchBar
                getReportTicketsAggregate={controller.getDataReportTicketAggregate}
                loading={controller.loading}
                period={controller.period}
                setPeriod={(dateArray, label) => controller.setPeriod(dateArray, label)}
                hasDataInGraphics={hasDataInGraphics}
                labelPeriodCustom={controller.labelPeriod}
            />
            <Content>
                <GraphicsReportTicketAggregate
                    lineChartClientsAggregate={controller.lineChartClientsAggregate}
                    lineChartDescriptionsStaticAggregate={controller.lineChartDescriptionsStaticAggregate}
                    lineChartSectorsAggregate={controller.lineChartSectorsAggregate}
                    lineChartUsersAggregate={controller.lineChartUsersAggregate}
                    hasData={(chart) => controller.hasData(chart)}
                    loading={controller.loading && !controller.loadingMessage}
                />

                <ModalsReportTicketAggregate
                    period={controller.period}
                    loading={controller.loading}
                    exportPdf={() => controller.exportPdf(onSuccess)}
                />
                {!controller.loading &&
                    <ContainerAverageTimes>
                        <PanelAverageTime
                            renderComponent={() => {
                                if (controller.timeFirstResponseAggregate) {
                                    return <TextAverageTime
                                        color={colors.hintInverse}>{minutesToHours(controller.timeFirstResponseAggregate)}</TextAverageTime>
                                }
                                return <TextAverageTime
                                    color={colors.hintInverse}>Sem dados</TextAverageTime>

                            }}
                            color={colors.text}
                            description={"Tempo Médio da Primeira Resposta"}
                            showIconInformation={true}
                            onClickIconInformation={() => {
                                setTextHeaderModalInformationReport('Tempo Médio Primeira Resposta')
                                setTextInformationHeader('O tempo de primeira resposta diz respeito a agilidade do retorno de um chamado que o seu cliente abre para o suporte da empresa. Serão contados os minutos entre o horário que o chamado é criado e a primeira resposta ao cliente.')
                                setTextInformationBody('Quando existe a análise correta constante desse tempo, é possível verificar se o atendimento consegue alcançar as soluções de forma ágil. Aqui, é importante observar que o tempo de primeira resposta no atendimento deve considerar se o problema do cliente foi resolvido.')
                                setVisibleModalInformationReport(true)
                            }}

                        />
                        <PanelAverageTime
                            renderComponent={() => {
                                if (controller.averageRating) {
                                    return (
                                        <ContentTextRateScale>
                                            <TagEvaluation
                                                value={controller.averageRating}
                                                starSize={20}
                                                fontSize={20}
                                            />
                                        </ContentTextRateScale>
                                    )
                                }
                                return <TextAverageTime color={colors.hintInverse}>Sem Avaliações</TextAverageTime>

                            }}
                            color={colors.text}
                            description={"Média Geral Satisfação"}
                            showIconInformation={true}
                            onClickIconInformation={() => {
                                setTextHeaderModalInformationReport('Média Geral Satisfação')
                                setTextInformationHeader('A satisfação do consumidor talvez seja a métrica mais importante. Esta é a métrica que irá dar uma ideia geral de todas as métricas.')
                                setTextInformationBody('A satisfação irá idealizar como a sua equipe está agindo por inteiro. Caso todas as outras métricas estejam alinhadas a satisfação será grande.')
                                setVisibleModalInformationReport(true)
                            }}
                        />
                    </ContainerAverageTimes>}
            </Content>
            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    );
}))
export default ReportTicketsAggregate;
