import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, ContentImage, Icon, Text} from "./styled";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";


const Card = (observer((
    {
        description,
        nameIcon,
        isActive,
        value,
        onClickCard
    }: {
        description: string
        nameIcon: string
        isActive: boolean
        onClickCard: (role: UserRole) => void
        value: UserRole
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (

        <Container
            isActive={isActive}
            isDarkTheme={colors.isDarkTheme}
            onClick={() => onClickCard(value)}
            borderColor={colors.border}
            accent={colors.accent}
        >
            <ContentImage
                isActive={isActive}
                isDarkTheme={colors.isDarkTheme}
                borderColor={colors.border}
                accent={colors.accent}
            >
                <DropDeskIcon
                    color={colors.text}
                    size={70}
                    icon={nameIcon}
                />
            </ContentImage>
            <Text size={ConstantsStyles.titleLargeFontSize} color={colors.text}>{description}</Text>
        </Container>

    );
}))
export default Card;
