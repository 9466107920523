import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ReportTicketRepository} from "@dropDesk/domain/repositories/reports/report_ticket/report_ticket.repository";
import {
    ReportTicketRemoteDataSource
} from "@dropDesk/data/data_source/reports/report_ticket/report_ticket_remote.datasource";
import {ReportTicketEntity} from "@dropDesk/domain/entities/reports/report_ticket/report_ticket_entity";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";

@injectable()
export class ReportTicketRepositoryImpl implements ReportTicketRepository {
    private _dataSource: ReportTicketRemoteDataSource;

    constructor(
        @inject(ReportTicketRemoteDataSource)
            dataSource: ReportTicketRemoteDataSource
    ) {
        this._dataSource = dataSource;
    }

    public get(
        period: { startDate: string, endDate: string },
        idUser: string | null,
        idSector: string | null,
        idClient: string | null
    ): Promise<ReportTicketEntity> {
        try {
            return this._dataSource.get(period, idUser, idSector, idClient);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public exportPdf(
        period: { startDate: string, endDate: string },
        idUser: string | null,
        idSector: string | null,
        idClient: string | null
    ): Promise<void> {
        try {
            return this._dataSource.exportPdf(period, idUser, idSector, idClient);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

}
