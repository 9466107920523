type vcardValueType = {
  meta: Array<Record<string, string>>
  value: string[]
}
export type vcardType = {
  version: string,
  fn?: string,
  tel?: vcardValueType[]
}

export const vcardParser = (input: string): vcardType => {
  const Re1 = /^(version|fn|title|org):(.+)$/i;
  const Re2 = /^([^:;]+);([^:]+):(.+)$/;
  const ReKey = /item\d{1,2}\./;
  const fields: Record<string, any> = {};

  input.split(/\r\n|\r|\n/).forEach(function (line) {
    let results: RegExpMatchArray | null = null;
    let key: string = '';

    if (Re1.test(line)) {
      results = line.match(Re1);
      if (results) {
        key = results[1].toLowerCase();
        fields[key] = results[2];
      }
    } else if (Re2.test(line)) {
      results = line.match(Re2);
      if (results) {
        key = results[1].replace(ReKey, '').toLowerCase();
      }

      let meta: Record<string, unknown> = {};
      if (results) {
        results[2].split(';')
          .map(function (p, i) {
            const match = p.match(/([a-z]+)=(.*)/i);
            if (match) {
              return [match[1], match[2]];
            } else {
              return ["TYPE" + (i === 0 ? "" : i), p];
            }
          })
          .forEach(function (p) {
            meta[p[0]] = p[1];
          });
      }

      if (!fields[key]) fields[key] = [];

      if (results) {
        fields[key].push({
          meta: meta,
          value: results[3].split(';')
        })
      }
    }
  });
  return fields as vcardType;
}
