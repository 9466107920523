import styled from "styled-components";
import {ButtonType} from "@dropDesk/presentation/components/footer/footer_modal/footer_modal";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
};

type TextType = {
    color: string;
};

export const ContentExportImport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const ContentFilter = styled.div<ContainerType>`
  display: flex;
  background: ${(props) => props.background};
  height: 61px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  margin-top: 1px;
`;
export const ContentInput = styled.div`
  display: flex;
  width: 350px;
`;


export const ContainerRestoreContacts = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 10px;
`;

export const ContentRestoreContacts = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`;
export const ContainerTitlePopover = styled.div<TextType>`
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: ${(props) => props.color};
`;
export const ContainerFooterRestoreContacts = styled.div<TextType>`
  display: flex;
  padding-top: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  border-top: 1px solid ${(props) => props.color};
`;
export const ButtonBack = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: bold;
  width: 170px;
  height: 35px;
  min-height: 35px;
  border-radius: 5px;
  letter-spacing: 1px;
  margin-right: 15px;
  border: ${(props) => `1px solid ${props.border}`};


  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.shadow, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

`;
