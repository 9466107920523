import styled from "styled-components";

export type ContainerType = {
    background: string;
}

export type TextType = {
    color: string;
}

export type IconType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 0 8px;
`;


export const Text = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  font-weight: normal;
`;

export const TextBold = styled.span<TextType>`
  font-size: 15px;
  color: ${props => props.color};
  font-weight: bold;
`;


export const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 0;
`;
export const ContentTimeZone = styled.div`
`;

