import {ContentConfirmPlan, Container} from "./styled";
import ConfirmPlan from "@dropDesk/presentation/pages/subscription/ui/select_plan/confirm_plan";
import TotalPlan from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan";
import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {observer} from "mobx-react";
import {useEffect} from "react";


const SelectPlan = observer((
    {
        setActiveRoute,
        valuePlanOnDemand,
        disabled,
        loading,
        onClickConfirmPlan,
        currentSubscription,
        newSubscription,
        onUpdate,
        makeNewSubscription,
        hasDowngrade,
        labelFirstPayment,
        totalFirstPayment,
        disabledText,
        daysCharged
    }: {
        setActiveRoute: (route: RoutesSubscription) => void
        valuePlanOnDemand?: number;
        disabled: boolean;
        loading: boolean;
        onClickConfirmPlan: () => void,
        currentSubscription: PaymentSubscriptionEntity
        newSubscription: PaymentSubscriptionEntity
        onUpdate: (newSubscription: PaymentSubscriptionEntity) => void
        makeNewSubscription: () => void,
        hasDowngrade: boolean
        labelFirstPayment: string
        totalFirstPayment: number
        disabledText: string;
        daysCharged?: number
    }
) => {

    useEffect(() => {
        makeNewSubscription();
    }, []);

    const handleAllChanges = (event: {
        target: {
            name: string;
            value: any;
            checked: any;
            type: string
        }
    }) => {

        const infoSubscription = newSubscription.copyWith({
            [event.target.name]:
                (event.target.type === "checkbox" || event.target.type === "switch") ?
                    event.target.checked : event.target.name === "planUserMultiplier" ?
                        event.target.value ? parseInt(event.target.value.replace(/\D/g, "")) : 0 : event.target.value,
        });
        onUpdate(infoSubscription);
    };

    return (
        <Container>

            <ContentConfirmPlan>
                <ConfirmPlan
                    setActiveRoute={setActiveRoute}
                    loading={loading}
                    currentSubscription={currentSubscription}
                    newSubscription={newSubscription}
                    handleAllChanges={handleAllChanges}
                    hasDowngrade={hasDowngrade}
                />
                <TotalPlan
                    valuePlanOnDemand={valuePlanOnDemand}
                    loading={loading}
                    disabled={disabled}
                    onClickConfirmPlan={onClickConfirmPlan}
                    newSubscription={newSubscription}
                    labelFirstPayment={labelFirstPayment}
                    totalFirstPayment={totalFirstPayment}
                    hasDowngrade={hasDowngrade}
                    disabledText={disabledText}
                    daysCharged={daysCharged}
                />
            </ContentConfirmPlan>
        </Container>
    );
});
export default SelectPlan;
