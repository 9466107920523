import {UserRemoteDataSource} from "@dropDesk/data/data_source/user/user_remote.datasource";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {ChangePasswordResponse} from "@dropDesk/domain/entities/common/account_action.entity";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export class UserRepositoryImpl implements UserRepository {
    private _dataSource: UserRemoteDataSource;

    constructor(
        @inject(UserRemoteDataSource)
            dataSource: UserRemoteDataSource
    ) {
        this._dataSource = dataSource;
    }


    public list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        role: UserRole,
        idClient?: string,
        idSector?: string,
        listOnlyContactWithWhatsApp?: boolean,
    ): Promise<ListPaginationEntity<UserEntity>> {
        try {
            return this._dataSource.list(page, searchParam, limit, listOnlyDeleted, role, idClient, idSector, listOnlyContactWithWhatsApp);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    public set(user: UserEntity, isNew: boolean): Promise<UserEntity> {
        try {
            return this._dataSource.set(user, isNew);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public findByPK(id: string, role?: UserRole): Promise<UserEntity> {
        try {
            return this._dataSource.findByPK(id, role);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public restore(users: UserEntity[]): Promise<void> {
        try {
            return this._dataSource.restore(users);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public delete(users: UserEntity[]): Promise<void> {
        try {
            return this._dataSource.delete(users);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public changePassword(renewToken: string, password?: string): Promise<ChangePasswordResponse> {
        try {
            return this._dataSource.changePassword(renewToken, password);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public forgot(email: string): Promise<void> {
        try {
            return this._dataSource.forgot(email);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public activateAccount(email: string): Promise<void> {
        try {
            return this._dataSource.activateAccount(email);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public transferUser(idNewClient: string, idUser: string): Promise<void> {
        try {
            return this._dataSource.transferUser(idNewClient, idUser);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public changeEmail(oldEmail: string, newEmail: string, isCurrentUserChanged: boolean): Promise<void> {
        try {
            return this._dataSource.changeEmail(oldEmail, newEmail, isCurrentUserChanged);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public setTheme(theme: string): Promise<void> {
        try {
            return this._dataSource.setTheme(theme);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public export(role: UserRole): Promise<ExportDataEntity> {
        try {
            return this._dataSource.export(role);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public exportSubscription(): Observable<ExportDataEntity> {
        try {
            return this._dataSource.exportSubscription();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
