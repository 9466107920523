import styled from "styled-components";


export type IconType = {
    color: string;
};

export type ContainerType = {
    background: string;
};

export const ContainerAction = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentTwoLineClamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ContentOneLineClamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CirclePriorityDateExpired = styled.span<ContainerType>`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  background: ${(props) => props.background};
  margin: 0 0 2px 3px;
`;

export const ContainerExpanded = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
`;

export const ContentOptionExpanded = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0;
`;

export const TextExpanded = styled.span<IconType>`
  display: flex;
  font-size: 14px;
  color: ${(props) => props.color};

`;

export const TitleExpanded = styled.span<IconType>`
  display: flex;
  font-size: 14px;
  margin-right: 5px;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  color: ${(props) => props.color};
`;
