import {
    Container, Content, ContentImage, ContentTitle,
    Image, SubTitle, Title,
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const AnimatedImage = (
    {
        imageSource,
        title,
        subTitle,
    }: {
        imageSource: string
        title: string
        subTitle: string
    }) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container background={colors.onBackground}>

            <Content>

                <ContentImage>
                    <Image src={imageSource} alt={"Imagem Team Chat DropDesk"}/>
                </ContentImage>

                <ContentTitle>
                    <Title color={colors.text}>{title}</Title>
                    <SubTitle color={colors.text}>{subTitle}</SubTitle>
                </ContentTitle>

            </Content>


        </Container>
    );
};

export default AnimatedImage;
