import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Container,
    ContentDataInput,
    ContentInput
} from "./styled";
import React from "react";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";

import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {isValidCEP} from "@dropDesk/utils/helpers/validators";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {getCitiesFromState, statesMaps} from "@dropDesk/domain/entities/common/common_maps";
import {
    useValidatorInputAddress
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/validator";
import {
    useValidatorInputCompanyPaymentInfo
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/validator";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const InputAddress = (
    {
        onUpdate,
        loading,
        onSearchZipCode,
        newPaymentInfo
    }: {
        onUpdate: (newPaymentInfo: CompanyPaymentInfo) => void
        loading: boolean
        onSearchZipCode: (zipCode: string) => void,
        newPaymentInfo: CompanyPaymentInfo
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const {
        errorMessageCity, errorMessageNeighborhood,
        errorMessageNumber, errorMessageShortState,
        errorMessageState, errorMessageStreet,
        errorMessageZipCode
    } = useValidatorInputCompanyPaymentInfo(newPaymentInfo);

    const handleAllChanges = (event: {
        target: { name: string; value: any; checked: any; type: string }
    }) => {

        const infoAddress = newPaymentInfo.billingAddress.copyWith({
            [event.target.name]:
                (event.target.type === "checkbox" || event.target.type === "switch") ?
                    event.target.checked : event.target.name === "telephone" ?
                        event.target.value.replace(/\D/g, "") : event.target.value,
            replacePinIfNull: event.target.name === 'pin',
            replaceComplementIfNull: event.target.name === 'complement',
            replaceAliasIfNull: event.target.name === 'alias',
            city: event.target.name === 'state' ? '' : event.target.name === 'city' ? event.target.value : newPaymentInfo.billingAddress.city
        });
        const _newPaymentInfo = newPaymentInfo.copyWith({
            billingAddress: infoAddress
        });
        onUpdate(_newPaymentInfo);
    };

    return (
        <Container>
            <ContentDataInput>
                <ContentInput style={{marginBottom: 25}}>
                    <InputWithBorder
                        name='alias'
                        size={35}
                        disabled={loading}
                        placeHolder={"Matriz, Filial ..."}
                        label={"Identificação"}
                        value={newPaymentInfo.billingAddress.alias ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentInput>
                <ContentInput style={{marginLeft: 10, marginRight: 10}}>
                    <InputWithBorder
                        mask={'cep'}
                        required={true}
                        name='zipCode'
                        size={35}
                        placeHolder={'00000-000'}
                        removeMaskOnCopy={true}
                        disabled={loading}
                        messageError={errorMessageZipCode}
                        label={"CEP"}
                        useButtonRight={true}
                        onClickButtonRight={() => onSearchZipCode(newPaymentInfo.billingAddress.zipCode)}
                        labelButtonRight={'Buscar'}
                        nameIconButtonRight={ICONS_DROPDESK.search}
                        tooltipButtonRight={isValidCEP(newPaymentInfo.billingAddress.zipCode) ? '' : 'Informe um CEP válido para busca'}
                        disabledButtonRight={!isValidCEP(newPaymentInfo.billingAddress.zipCode)}
                        value={newPaymentInfo.billingAddress.zipCode ?? ''}
                        onChange={handleAllChanges}
                    />

                </ContentInput>
                <ContentInput>
                    <InputWithBorder
                        required={true}
                        name='street'
                        size={35}
                        disabled={loading}
                        messageError={errorMessageStreet}
                        label={"Logradouro"}
                        value={newPaymentInfo.billingAddress.street ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentInput>
            </ContentDataInput>

            <ContentDataInput>
                <ContentInput>
                    <InputWithBorder
                        required={true}
                        name='number'
                        size={35}
                        disabled={loading}
                        messageError={errorMessageNumber}
                        label={"Número"}
                        value={newPaymentInfo.billingAddress.number ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentInput>
                <ContentInput style={{marginLeft: 10, marginRight: 10}}>
                    <InputWithBorder
                        required={true}
                        name='neighborhood'
                        size={35}
                        disabled={loading}
                        messageError={errorMessageNeighborhood}
                        label={"Bairro"}
                        value={newPaymentInfo.billingAddress.neighborhood}
                        onChange={handleAllChanges}
                    />

                </ContentInput>
                <ContentInput style={{marginBottom: 25}}>
                    <InputWithBorder
                        name='complement'
                        size={35}
                        disabled={loading}
                        label={"Complemento"}
                        value={newPaymentInfo.billingAddress.complement ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentInput>
            </ContentDataInput>

            <ContentDataInput>

                <ContentInput style={{marginRight: 10}}>
                    <SelectSearchMain<string>
                        required={true}
                        name='state'
                        placeHolder={"Selecione o estado"}
                        label={"Estado"}
                        messageError={errorMessageState}
                        size={35}
                        search={true}
                        options={statesMaps()}
                        onChange={(value) => {
                            handleAllChanges(
                                {
                                    target:
                                        {
                                            name: 'state',
                                            value: value,
                                            checked: false,
                                            type: 'input'
                                        }
                                })
                        }}
                        value={newPaymentInfo.billingAddress.state}
                    />

                </ContentInput>
                <ContentInput>
                    <SelectSearchMain<string>
                        required={true}
                        disabled={!newPaymentInfo.billingAddress.state}
                        name='city'
                        placeHolder={"Selecione a cidade"}
                        label={"Cidade"}
                        messageError={errorMessageCity}
                        size={35}
                        search={true}
                        options={getCitiesFromState(newPaymentInfo.billingAddress.state) ?? []}
                        onChange={(value) => {
                            handleAllChanges(
                                {
                                    target:
                                        {
                                            name: 'city',
                                            value: value,
                                            checked: false,
                                            type: 'input'
                                        }
                                })
                        }}
                        value={newPaymentInfo.billingAddress.city}
                    />

                </ContentInput>
            </ContentDataInput>
        </Container>
    );
}
export default InputAddress;
