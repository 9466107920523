import "reflect-metadata";
import {inject, injectable} from "inversify";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";

@injectable()
export class ExportTicketsSubscriptionUseCase {
  private _repository: TicketRepository;

  constructor(@inject(TicketRepository) repository: TicketRepository) {
    this._repository = repository;
  }

  public call(): Observable<ExportDataEntity> {
    return this._repository.exportSubscription();
  }
}

