import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {
    ContentExportImport,
    ContentFilter,
    ContentInput,
    ContentInputAndFilter,
    ContentSelectAndCheckBox,
    PaddingFilters
} from "./styled";
import SelectRangeDate from "@dropDesk/presentation/components/dates/select_range_date";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useBetween} from "use-between";
import {
    useModalsReportTicket
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/ui/modals/handle_change_visible";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {filterMap} from "@dropDesk/domain/entities/reports/report_ticket/report_ticket_maps";
import {filtersEnum} from "@dropDesk/domain/entities/reports/report_ticket/report_ticket_enum";
import {Tooltip} from "antd";
import {PeriodEnum} from "@dropDesk/utils/helpers/common";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const SearchBar = observer(
    (
        {
            removeFilter,
            loading,
            client,
            user,
            sector,
            getReportTicket,
            setPeriod,
            period,
            hasDataInGraphics,
            setFilterPerson,
            filterPerson,
            labelPeriodCustom
        }: {
            removeFilter: () => void,
            loading: boolean,
            client: ClientEntity | null,
            user: UserEntity | null,
            sector: SectorEntity | null,
            getReportTicket: () => void,
            period: Array<Date> | null;
            hasDataInGraphics: boolean;
            setFilterPerson: (person: filtersEnum | null) => void
            filterPerson: filtersEnum | null
            setPeriod: (period: Array<Date> | null, label: PeriodEnum) => void
            labelPeriodCustom: PeriodEnum
        }
    ) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            setVisibleModalSearchUser,
            setVisibleModalSearchClient,
            setVisibleModalSearchSector,
            setVisibleModalSearchContacts,
            setVisibleModalExportPdf,
            setVisibleModalExport
        } = useBetween(useModalsReportTicket);

        const removeAllFilters = () => {
            removeFilter();
            setFilterPerson(null);
        }


        return (
            <ContentFilter background={colors.onBackground}>
                <ContentInputAndFilter>
                    <ContentInput>
                        <SelectRangeDate
                            size={37}
                            label={'Período'}
                            value={period}
                            allowClear={true}
                            onChange={(arrayDate, label) => {
                                setPeriod(arrayDate, label);
                                getReportTicket();
                            }}
                            labelPeriodCustom={labelPeriodCustom}
                        />
                    </ContentInput>
                    <PaddingFilters>
                        <SelectSearchMain<filtersEnum>
                            size={37}
                            width={120}
                            label={"Filtrar por"}
                            placeHolder={"Selecione"}
                            options={filterMap}
                            onChange={(value) => {
                                removeFilter();
                                setFilterPerson(value);
                            }}
                            value={filterPerson ?? null}
                        />
                    </PaddingFilters>
                    {filterPerson === filtersEnum.client && <ContentSelectAndCheckBox>
                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <InputWithBorder
                                name='client'
                                size={37}
                                onClick={() => setVisibleModalSearchClient(true)}
                                readOnly={true}
                                disabled={loading}
                                placeHolder={"Selecione a empresa"}
                                nameIconRight={ICONS_DROPDESK.downArrow}
                                sizeRightIcons={14}
                                marginRightRightIcons={10}
                                label={"Empresa"}
                                value={client?.name ?? ''}
                                useButtonRight={true}
                                labelButtonRight={"Remover filtro"}
                                onClickButtonRight={() => {
                                    removeAllFilters();
                                    getReportTicket();
                                }}
                                onChange={() => {
                                }}
                            />
                        </div>
                    </ContentSelectAndCheckBox>}

                    {filterPerson === filtersEnum.user && <ContentSelectAndCheckBox>
                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <InputWithBorder
                                name='user'
                                size={37}
                                onClick={() => setVisibleModalSearchUser(true)}
                                readOnly={true}
                                disabled={loading}
                                placeHolder={"Selecione o atendente"}
                                nameIconRight={ICONS_DROPDESK.downArrow}
                                sizeRightIcons={14}
                                marginRightRightIcons={10}
                                label={"Atendente"}
                                value={user?.name ?? ''}
                                useButtonRight={true}
                                labelButtonRight={"Remover filtro"}
                                onClickButtonRight={() => {
                                    removeAllFilters();
                                    getReportTicket();
                                }}
                                onChange={() => {
                                }}
                            />
                        </div>

                    </ContentSelectAndCheckBox>}

                    {filterPerson === filtersEnum.contacts && <ContentSelectAndCheckBox>
                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <InputWithBorder
                                name='user'
                                size={37}
                                onClick={() => {
                                    setVisibleModalSearchContacts(true);
                                }}
                                readOnly={true}
                                disabled={loading}
                                placeHolder={"Selecione o contato"}
                                nameIconRight={ICONS_DROPDESK.downArrow}
                                sizeRightIcons={14}
                                marginRightRightIcons={10}
                                label={"Contato"}
                                value={user?.name ?? ''}
                                useButtonRight={true}
                                labelButtonRight={"Remover filtro"}
                                onClickButtonRight={() => {
                                    removeAllFilters();
                                    getReportTicket();
                                }}
                                onChange={() => {
                                }}
                            />
                        </div>

                    </ContentSelectAndCheckBox>}

                    {filterPerson === filtersEnum.sector && <ContentSelectAndCheckBox>
                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <InputWithBorder
                                name='sector'
                                size={37}
                                onClick={() => setVisibleModalSearchSector(true)}
                                readOnly={true}
                                disabled={loading}
                                placeHolder={"Selecione o setor"}
                                nameIconRight={ICONS_DROPDESK.downArrow}
                                sizeRightIcons={14}
                                marginRightRightIcons={10}
                                label={"Setor"}
                                value={sector?.realName(appController.user!) ?? ''}
                                useButtonRight={true}
                                labelButtonRight={"Remover filtro"}
                                onClickButtonRight={() => {
                                    removeAllFilters();
                                    getReportTicket();
                                }}
                                onChange={() => {
                                }}
                            />
                        </div>

                    </ContentSelectAndCheckBox>}
                </ContentInputAndFilter>

                <ContentExportImport>
                    <Tooltip title={!hasDataInGraphics ? 'Não possui dados' : ''}>
                        <div>
                            <ButtonNew
                                marginLeft={10}
                                size={37}
                                label={'Exportar gráficos'}
                                onClick={() => {
                                    setVisibleModalExportPdf(true);
                                }}
                                nameLeftIcon={ICONS_DROPDESK.pdf}
                                sizeLeftIcon={15}
                                disabled={loading || !hasDataInGraphics}
                            />
                        </div>
                    </Tooltip>
                    {appController.user?.permissionAdmin &&
                        <Tooltip title={!hasDataInGraphics ? 'Não possui dados' : ''}>
                            <div>
                                <ButtonNew
                                    marginLeft={10}
                                    size={37}
                                    label={'Exportar dados'}
                                    onClick={() => setVisibleModalExport(true)}
                                    nameLeftIcon={ICONS_DROPDESK.excel}
                                    sizeLeftIcon={15}
                                    disabled={loading || !hasDataInGraphics}
                                />
                            </div>
                        </Tooltip>}
                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBar;
