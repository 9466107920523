import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DetailSubscription from "@dropDesk/presentation/components/detail_subscription";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {observer} from "mobx-react";
import {
    Button,
    Container, ContainerButtons, ContainerDescription,
    ContainerValue, ContentStatus, ContentValue
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/last_pending_payment/styled";
import {copyToClipboard, formatCurrency} from "@dropDesk/utils/helpers/common";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import React from "react";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";


const LastPendingPayment = observer((
    {
        lastPayment,
        handleClickPayWithPix,
        onClickDownloadInvoice,
        onClickViewInvoice,
        currentSubscription
    }: {
        lastPayment: PaymentEntity
        handleClickPayWithPix: (payment: PaymentEntity) => void
        onClickDownloadInvoice: (url: string) => void
        onClickViewInvoice: (url: string) => void
        currentSubscription: PaymentSubscriptionEntity
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <DetailSubscription
            title={'Última fatura'}
            style={{marginTop: 26}}
            content={
                <Container>
                    <ContainerValue>
                        <ContentValue color={colors.text}>
                            {formatCurrency(lastPayment.value)}
                        </ContentValue>
                        <ContentStatus
                            background={lastPayment.paymentExpirationImminent || lastPayment.rejected ? SharedColors.onCancelStatus : SharedColors.onAttendingStatus}
                            color={lastPayment.paymentExpirationImminent || lastPayment.rejected ? SharedColors.textCancelStatus : SharedColors.textAttendingStatus}
                        >
                            {lastPayment.titlePanel(currentSubscription)}
                        </ContentStatus>
                    </ContainerValue>

                    <ContainerDescription color={colors.text}>
                        {lastPayment.messagePanel(currentSubscription)}
                    </ContainerDescription>

                    {lastPayment.canPay &&
                        <ContainerButtons>
                            <Button
                                onClick={() => {
                                    handleClickPayWithPix(lastPayment);
                                }}
                                size={12}
                                color={SharedColors.white}
                                background={colors.accent}
                                accent={colors.accent}
                                style={{marginRight: 10}}
                            >
                                <DropDeskIcon size={18} icon={ICONS_DROPDESK.pix} color={SharedColors.white}/>
                                Pagar com pix
                            </Button>

                            {lastPayment.isBoleto &&
                                lastPayment.boleto?.digitableLine &&
                                lastPayment.boleto?.document &&
                                lastPayment.pix?.qrcodeText &&

                                <>
                                    <Button
                                        onClick={() => {
                                            copyToClipboard(lastPayment.boleto!.digitableLine);
                                            toastMessage.success('Código de barras copiado para área de transferência.');
                                        }}
                                        size={12}
                                        color={colors.accent}
                                        background={'transparent'}
                                        accent={colors.accent}
                                        style={{marginRight: 10}}
                                    >
                                        <DropDeskIcon size={18} icon={ICONS_DROPDESK.billet} color={colors.accent}/>
                                        Copiar cód. de barras
                                    </Button>
                                </>

                            }
                            {!!lastPayment.urlDocument && <Button
                                onClick={() => {
                                    onClickDownloadInvoice(lastPayment.urlDocument!);
                                    onClickViewInvoice(lastPayment.urlDocument!);
                                    toastMessage.success('Fatura baixado com sucesso.');
                                }}
                                size={12}
                                color={colors.accent}
                                background={'transparent'}
                                accent={colors.accent}
                                style={{marginRight: 10}}
                            >
                                <DropDeskIcon size={18} icon={ICONS_DROPDESK.download} color={colors.accent}/>
                                Baixar fatura
                            </Button>}
                        </ContainerButtons>
                    }

                </Container>
            }
        />
    );
});
export default LastPendingPayment;
