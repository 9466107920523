import React, {useEffect} from "react";
import {useInjection} from "inversify-react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Container, ContentInputs, ContentFormEvaluation, FormEvaluation
} from "./styled";
import {observer} from "mobx-react";
import GenericScreenNotFound from "@dropDesk/presentation/components/generic_screen_not_found";
import HeaderFormEvaluations from "@dropDesk/presentation/pages/ticket/evaluation/ui/form/header_form";
import FooterFormEvaluation from "@dropDesk/presentation/pages/ticket/evaluation/ui/form/footer_form";
import InputsForm from "@dropDesk/presentation/pages/ticket/evaluation/ui/form/inputs";
import {
    TicketEvaluationController
} from "@dropDesk/presentation/pages/ticket/evaluation/controller/ticket_evaluation.controller";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ChatController} from "@dropDesk/presentation/pages/chat/controller/chat.controller";

const FormEvaluations = observer(() => {
    const controller = useInjection(TicketEvaluationController);
    const appController = useInjection(AppController);
    const chatController = useInjection(ChatController);
    const colors = appController.theme.colorScheme;
    const {id} = useParams();
    const navigate = useNavigate();

    const initialize = async () => {
        await controller.getEvaluation(id ?? '');
    }

    useEffect(() => {
        initialize();
    }, []);

    const handleSubmit = async () => {
        navigate(`${RoutesEnum.ticketsView}/${controller.ticketEvaluation!.idTicket}`);
        if (controller.ticketEvaluation?.ticket) {
            chatController.setChat(controller.ticketEvaluation.ticket);
        }
    };

    return (
        <Container background={colors.onBackground} border={colors.border} heightShowingHeaderInfo={0}>

            <ContentFormEvaluation>

                {!controller.ticketEvaluationNotFound &&
                    <FormEvaluation background={colors.onBackground} border={colors.border}>

                        <HeaderFormEvaluations
                            loading={controller.loading}
                        />
                        <ContentInputs>
                            <InputsForm ticketEvaluation={controller.ticketEvaluation}/>
                        </ContentInputs>
                        <FooterFormEvaluation
                            handleSubmit={handleSubmit}
                            loading={controller.loading}
                            createdAt={controller.ticketEvaluation?.date ? `Avaliado em ${displayDateToLocale(controller.ticketEvaluation.date)}` : undefined}
                        />

                    </FormEvaluation>}

                {controller.ticketEvaluationNotFound && <GenericScreenNotFound
                    textNotFound={'Oops, Avaliação não encontrada ...'}
                    textHeaderForm={"Voltar"}
                />}
            </ContentFormEvaluation>

            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}

        </Container>
    );
});

export default FormEvaluations;
