import {observer} from "mobx-react";
import React from "react";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {useModalSector} from "@dropDesk/presentation/pages/sector/ui/form/modals/handle_change_visible";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const ModalsFormSector = observer(
    ({
         onDeleteSector,
         loading,
     }: {
        onDeleteSector: () => Promise<void>,
        loading: boolean,
    }) => {

        const {
            visibleModalDelete,
            setVisibleModalDelete
        } = useBetween(useModalSector);

        return (
            <ModalInformation
                open={visibleModalDelete}
                onRequestClose={() => {
                    setVisibleModalDelete(false);
                }}
                textHeaderModal={'Excluir Setor'}
                textButton={'Excluir'}
                onRequestSave={async () => {
                    onDeleteSector();
                    setVisibleModalDelete(false);
                }}
                nameIcon={ICONS_DROPDESK.delete}
                sizeIcon={115}
                textInformation={`A exclusão deste setor, não apaga seus atendimentos, avaliações e nenhuma outra ação já feita por ele, apenas o setor é excluído não sendo mais listado e sem acesso ao painel do setor.`}
                loading={loading}
                isActionDelete={true}
            />
        )
    })
export default ModalsFormSector;
