import {useState} from "react";

export const useModalConnectionsActive = () => {

  const [visibleModalLogoutByAdmin, setVisibleModalLogoutByAdmin] = useState<boolean>(false);

  return {
    visibleModalLogoutByAdmin,
    setVisibleModalLogoutByAdmin,
  }
}
