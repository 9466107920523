import styled from "styled-components";

type ContainerType = {
    background: string;
};

type TitleType = {
    color: string;
};

type TextType = {
    color: string;
    size: number;
};


export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow: auto;
  background: ${props => props.background};
  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  padding: 32px;
`;

export const ContentImage = styled.div`
  width: 500px;
`;
export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
`;

export const Title = styled.span<TitleType>`
  color: ${props => props.color};
  font-weight: bold;
  letter-spacing: 1.5px;
  font-size: 23px;
`;

export const ContentDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

export const Description = styled.span<TextType>`
  color: ${props => props.color};
  font-size: ${props => `${props.size}px`};
  text-align: center;
`;

export const Image = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 10px;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtonClosed = styled.div<ContainerType>`
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 32px;
  background: ${(props) => props.background};
`;

export const ButtonClosed = styled.button<TextType>`
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${props => props.color};

  &:hover {
    filter: brightness(80%);
  }
`;
export const TextClosed = styled.span`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const IconClosed = styled.i`
  font-size: 13px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 2px;
`;
