import styled from "styled-components";


type TitleType = {
    color: string;
}

export const ContainerTime = styled.div<TitleType>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  color: ${props => props.color};
  font-size: 11px;
  right: -4px;
  bottom: -5px;
  height: 22px;
  max-height: 22px;
`;

export const TextItalic = styled.span`
  font-style: italic;
`;

export const Text = styled.span`
  font-style: normal;
`;

export const ContainerStatus = styled.span`
  //font-size: 15px;
  //display: flex;
  //align-items: flex-end;
  //justify-items: flex-end;
  margin-left: -.2em;
`;
