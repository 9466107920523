import "reflect-metadata";
import {inject, injectable} from "inversify";
import {NoticesRepository} from "@dropDesk/domain/repositories/notices/notices.repository";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";

@injectable()
export class ListNoticesByUserUseCase {
    private _repository: NoticesRepository;

    constructor(@inject(NoticesRepository) repository: NoticesRepository) {
        this._repository = repository;
    }

    public call(page: number, limit: number): Promise<ListPaginationEntity<NoticesEntity>> {
        return this._repository.listNoticesByUser(page, limit);
    }
}

