import {observer} from "mobx-react";
import React from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";

const FooterFormCreateTicket = observer(
  ({
     isValidDataForm,
     loading,
     handleSubmit,
     backPreviousScreen
   }: {
     handleSubmit: () => void,
     backPreviousScreen: () => void,
     loading: boolean
     isValidDataForm: boolean
   }
  ) => {

    return (
      <FooterForm
        disabled={loading || !isValidDataForm}
        loading={loading}
        type={"submit"}
        letter={"Salvar"}
        onSubmit={handleSubmit}
        onRequestClose={backPreviousScreen}
        useCheckBox={false}
      />
    )
  })
export default FooterFormCreateTicket;
