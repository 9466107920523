import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";

export const newSector = () => new SectorEntity({
  id: '',
  idCompany: getIdCompanyByLocalStorage(),
  name: '',
  note: '',
  chatLabel: '',
  listableForClients: true,
  deleted: false,
});
