import React, {useEffect} from 'react';
import {
    Container,
    SubContentList,
    ContentList,
} from "./styled";
import {observer} from "mobx-react";
import {useBetween} from "use-between";
import {useInjection} from "inversify-react";
import {
    TicketsDescriptionStaticController
} from "@dropDesk/presentation/pages/ticket_description_static/controller/ticket_description_static.controller";
import SearchBar from "@dropDesk/presentation/pages/ticket_description_static/ui/search_bar";
import TableTicketsDescriptionStatic from "@dropDesk/presentation/pages/ticket_description_static/ui/table";
import ModalsTicketsDescriptionStatic from "@dropDesk/presentation/pages/ticket_description_static/ui/modals";

import {
    useModalSearchTicketsDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/handle_change_visible";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import {
    useValidatorInputTicketsDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/modal_crud/validator";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import FooterGarbage from "@dropDesk/presentation/components/footer/footer_garbage";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const isNewRegister = 'new';
const TicketsDescriptionStatic = observer(
    ({}: {}) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const controller = useInjection(TicketsDescriptionStaticController);
        const {t} = useTranslation();

        useEffect(() => {
            controller.initialize(appController.heightShowingHeaderInfo);
        }, []);

        const {validate} = useValidatorInputTicketsDescriptionStatic(controller.ticketsDescriptionStatic, controller.loading);
        const {setVisibleModalNewTicketsDescriptionStatic} = useBetween(useModalSearchTicketsDescriptionStatic);

        const onSuccess = (key: string) => {
            controller.list(controller.searchParam);
            toastMessage.success(t(key));
        }

        const handleVisibleModalNewTicketsDescriptionStatic = (visible: boolean, id: string) => {
            if (visible) {

                if (id === isNewRegister) {
                    controller.makeTicketDescriptionStatic();
                } else {
                    controller.getTicketsDescriptionStatic(id);
                }

            } else {
                controller.resetTable();
                controller.list(controller.searchParam);
            }

            setVisibleModalNewTicketsDescriptionStatic(visible);
        }

        const onSearch = (searchParam: string): void => {
            controller.list(searchParam);
        }

        return (
            <Container background={colors.onBackground} heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>
                <ContentList>

                    <SearchBar
                        onSearch={(searchParam) => onSearch(searchParam)}
                        visibleInputSearch={controller.rowSelectionTicketsDescriptionStatic.length === 0}
                        loading={controller.loading}
                        visibleButtonsOfActions={!controller.listOnlyDeleted && controller.rowSelectionTicketsDescriptionStatic.length === 0}
                        visibleButtonDeleted={!controller.listOnlyDeleted && controller.rowSelectionTicketsDescriptionStatic.length > 0}
                        visibleButtonRestore={controller.listOnlyDeleted && controller.rowSelectionTicketsDescriptionStatic.length > 0}
                        handleVisibleModalNewTicketsDescriptionStatic={handleVisibleModalNewTicketsDescriptionStatic}
                        filterListableForClients={controller.filterListableForClients}
                        setFilterListableForClients={(filter) => {
                            controller.setFilterListableForClients(filter);
                            onSearch(controller.searchParam);
                        }}
                    />

                    <SubContentList>

                        <TableTicketsDescriptionStatic
                            setRowSelectionTicketsDescriptionStatic={(ticketDescriptionStatics: TicketsDescriptionStaticEntity[]) => controller.setRowSelectionTicketsDescriptionStatic(ticketDescriptionStatics)}
                            getDataFromPage={(page) => controller.getDataFromPage(page)}
                            handleVisibleModalNewTicketsDescriptionStatic={handleVisibleModalNewTicketsDescriptionStatic}
                            rowSelectionTicketsDescriptionStatic={controller.rowSelectionTicketsDescriptionStatic}
                            tableTicketsDescriptionStatic={controller.tableTicketsDescriptionStatic}
                            searchParam={controller.searchParam}
                            loading={controller.loading}
                            permitSelectionRow={controller.listOnlyDeleted}
                        />

                        <FooterGarbage
                            setListOnlyDeleted={(value) => controller.setListOnlyDeleted(value)}
                            visibleListOnlyDeleted={controller.listOnlyDeleted}
                            loading={controller.loading}
                            text={'Problemas ativos'}
                            textDelete={'Problemas excluídos'}
                        />

                    </SubContentList>
                </ContentList>

                <ModalsTicketsDescriptionStatic
                    onUpdate={(ticketDescriptionStatic) => controller.setTicketsDescriptionStatic(ticketDescriptionStatic)}
                    removeLineSelectRow={() => controller.removeLineSelectRow()}
                    restoreTicketsDescriptionStatic={() => controller.restore(onSuccess)}
                    deleteTicketsDescriptionStatic={() => controller.delete(onSuccess)}
                    ticketsDescriptionStatic={controller.ticketsDescriptionStatic}
                    loading={controller.loading}
                    handleVisibleModalNewTicketsDescriptionStatic={handleVisibleModalNewTicketsDescriptionStatic}
                    set={(onSuccess) => controller.set(onSuccess)}
                />

                {((controller.loading && controller.loadingMessage))
                    && <DropDeskLoading
                        height={250}
                        description={controller.loadingMessage}
                    />}

            </Container>
        )

    }
)
export default TicketsDescriptionStatic;
