import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ImageEditorDataSource} from "@dropDesk/data/data_source/image_editor/image_editor_remote.datasource";
import {ImageEditorRepository} from "@dropDesk/domain/repositories/image_editor/image_editor.repository";
import {FiltersEditorEntity} from "@dropDesk/domain/entities/image_editor/filters_editor.entity";
import {RotateEditorEntity} from "@dropDesk/domain/entities/image_editor/rotate_editor.entity";
import {CropEditorEntity} from "@dropDesk/domain/entities/image_editor/crop_editor.entity";
import {PencilEditorEntity} from "@dropDesk/domain/entities/image_editor/pencil_editor.entity";

@injectable()
export class ImageEditorRepositoryImpl implements ImageEditorRepository {
  private _dataSource: ImageEditorDataSource;

  constructor(@inject(ImageEditorDataSource) dataSource: ImageEditorDataSource) {
    this._dataSource = dataSource;
  }

}
