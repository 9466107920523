import {Container, Text, Content} from "./styled";
import {HeaderProps} from "@dropDesk/presentation/components/headers/header_form/head_form";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const HeaderForm = observer((props: HeaderProps) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <Content onClick={props.disabled ? () => {
            } : props.onRequestClose}>
                {!props.hideIcon &&
                    <DropDeskIcon
                        icon={ICONS_DROPDESK.arrowLeft}
                        size={23}
                        style={{cursor: 'pointer', marginRight: 5}}
                    />}
                <Text color={colors.text}>{props.letter}</Text>
            </Content>
        </Container>
    );
})
export default HeaderForm;
