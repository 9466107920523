import styled from "styled-components";


type TextType = {
    color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  overflow: auto;
`;
export const ContentProtocol = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  align-items: center;
  justify-content: center;
`;

export const TextProtocol = styled.span<TextType>`
  font-size: 17px;
  color: ${(props) => props.color};
`;
export const TextTicketNumber = styled.span<TextType>`
  font-size: 18px;
  color: ${(props) => props.color};
  font-weight: bold;
  margin-left: 7px;
`;

export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:not(:last-of-type) {
    margin: 0 10px 0 0;
  }
`;
