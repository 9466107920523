import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {getActiveRoute} from "@dropDesk/utils/helpers/common";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@dropDesk/data/clients/firebase.client";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import History from "@dropDesk/presentation/routes/history";
import {Helmet} from "react-helmet";
import {ChatController} from "@dropDesk/presentation/pages/chat/controller/chat.controller";
import {observer} from "mobx-react";

export const AuthContext = React.createContext<UserEntity | null | undefined>(null);

export const AuthGuardProvider = observer(({children}: any) => {

    const [pending, setPending] = useState(true);
    const appController = useInjection(AppController);
    const chatController: ChatController = useInjection(ChatController);
    const navigate = useNavigate();
    const activeRoute = getActiveRoute();
    const {search} = useLocation();
    History.navigate = useNavigate();

    const getQueryRef = (): string | null => {
        return new URLSearchParams(search).get("ref");
    }

    const resolveUser = async () => {
        const observer = onAuthStateChanged(auth, async function (user) {
            try {
                appController.getLocalTheme();
                await appController.initialize(getQueryRef());

                if (!appController.user) {
                    await appController.getCurrentUser();
                }

                if (appController.user) {
                    if (
                        activeRoute === RoutesEnum.login ||
                        activeRoute === RoutesEnum.companyCreate ||
                        activeRoute === RoutesEnum.loginDefault
                    ) {
                        navigate(appController.user.role === UserRole.attendant ? RoutesEnum.dashboard : RoutesEnum.tickets, {replace: true});
                    }
                }

                observer();
                setPending(false);
            } catch (e) {
                observer();
                setPending(false);
            }
        });
    }

    useEffect(() => {
        resolveUser().then();
    }, []);

    if (pending) {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: appController.theme.colorScheme.delicateBackground,
                    height: "100%",
                }}>
                <DropDeskLoading
                    height={250}
                    description={appController.loadingMessage ?? "signIn.default"}
                />
            </div>
        );
    }

    return (
        <AuthContext.Provider
            value={
                appController.user
            }>
            <Helmet defer={false}>
                <meta charSet="utf-8"/>
                {chatController.countNotificationDocumentHead > 0 &&
                    <title>({chatController.countNotificationDocumentHead.toString()}) DropDesk</title>}
                {chatController.countNotificationDocumentHead <= 0 && <title>DropDesk</title>}
            </Helmet>
            {children}
        </AuthContext.Provider>
    );
});
