import React from 'react';
import ReactDOM from 'react-dom';
import {
    FullScreenOverlayStyled
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const FullScreenOverlay = (
    {
        onClick,
        children,
        style,
        background,
        opacity
    }: {
        onClick: () => void
        children?: React.ReactNode
        style?: React.CSSProperties;
        background?: string;
        opacity?: number;
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const realOpacity: number = opacity ?? 0.6;
    const realBackground: string = background ?? SharedColors.loading;

    return ReactDOM.createPortal(
        <FullScreenOverlayStyled
            background={realBackground}
            opacity={realOpacity}
            style={style}
            onClick={() => onClick()}
        >
            {children}
        </FullScreenOverlayStyled>,
        document.body
    );
};

export default FullScreenOverlay;
