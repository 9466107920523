import styled from "styled-components";

type ContainerType = {
    background: string;
};
type TextType = {
    color: string;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 126px);
  flex-direction: column;
  background: ${(props) => props.background};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;


export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

