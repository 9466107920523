import {Button, Container, ButtonBack, Text} from "./styled";
import {FooterFormProps} from "@dropDesk/presentation/components/footer/footer_form/footer_form";
import {Tooltip} from 'antd';
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


export default function FooterForm(props: FooterFormProps) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container
            useCheckBox={props.useCheckBox ?? false}
            useButtonDownload={props.useButtonDownload}
            useCreatedAt={!!props.createdAt}
            borderColor={colors.border}
            removePadding={props.removePadding}
        >

            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Button
                    widthButton={props.widthButton}
                    disabled={props.disabled}
                    background={colors.accent}
                    border={colors.border}
                    type={props.type}
                    shadow={colors.accent}
                    letterColor={SharedColors.white}
                    onClick={props.disabled ? () => {
                    } : props.onSubmit}>
                    {!props.loading && props.letter}
                    {props.loading &&
                        <SpinLoading size={"small"} color={SharedColors.white}/>
                    }
                </Button>

                {!props.hideBackButton && <ButtonBack
                    disabled={false}
                    background={colors.onBackground}
                    type={props.type}
                    letterColor={colors.text}
                    border={colors.border}
                    shadow={colors.accent}
                    onClick={props.onRequestClose}>
                    Voltar
                </ButtonBack>}
            </div>

            {!!props.useCheckBox &&
                <CheckBoxWithLabel
                    label={props.labelCheckBox ?? ''}
                    checked={props.checkedCheckBox ?? false}
                    disabled={props.disabledCheckBox ?? false}
                    onChange={props.onChangeCheckBox ? props.onChangeCheckBox : () => {
                    }}
                    name={props.checkBoxName ?? ''}
                    marginLeft={16}
                />
            }

            {props.createdAt &&
                <Text style={{marginLeft: 16}} color={colors.text}>{props.createdAt}</Text>
            }

            {props.useButtonDownload &&
                <div style={{marginLeft: 16}}>
                    <ButtonBack
                        disabled={false}
                        background={colors.onBackground}
                        type={props.type}
                        letterColor={colors.text}
                        border={colors.border}
                        shadow={colors.accent}
                        onClick={props.onRequestDownload}>
                        Download
                    </ButtonBack>
                </div>
            }
        </Container>
    );
}
