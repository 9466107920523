import styled from 'styled-components';
import FilerobotImageEditor from "react-filerobot-image-editor";

type IconType = {
    color: string;
    fontsize: number;
}

type ContainerType = {
    background: string;
    width: number;
}


export const ContainerImageEditor = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  z-index: 100;
  background: ${(props) => props.background};
  overflow: hidden;
  flex-direction: column;
  position: absolute;
  width: calc(100vw - ${props => `${props.width}px`}) !important;
  padding-top: 15px;
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;


