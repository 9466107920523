import React, {useEffect} from "react";
import {useInjection} from "inversify-react";
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import {useNavigate, useParams} from "react-router-dom";
import {
    Container,
    FormClient,
    ContentFormClient,
    ContentInputs
} from "./styled";
import {observer} from "mobx-react";
import GenericScreenNotFound from "@dropDesk/presentation/components/generic_screen_not_found";
import HeaderFormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form/header_form";
import FooterFormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form/footer_form";
import ModalsFormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form/modals";
import InputsFormAddress from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address";
import {Divider} from "antd";
import InputsFormContacts from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_contacts";
import InputsFormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_clients";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {
    useValidatorInputClients
} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_clients/validator";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {AppController} from "@dropDesk/presentation/app/app.controller";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";

const FormClients = observer((
    {
        isEdit,
        handleSubmitExternalScreen,
        backPreviousScreenExternalScreen,
        idClientExternal,
        idContactExternal,
        isNewChatContact
    }: {
        handleSubmitExternalScreen?: (client: ClientEntity) => void,
        backPreviousScreenExternalScreen?: () => void,
        isEdit?: boolean,
        isNewChatContact?: boolean,
        idClientExternal?: string | null,
        idContactExternal?: string | null,

    }) => {


    const controller = useInjection(ClientController);
    const userController = useInjection(UserController);
    const appController = useInjection(AppController);
    const userLogged = appController.user!;
    const colors = appController.theme.colorScheme;
    const {id} = useParams();
    const isNewRegister = 'new';
    const navigate = useNavigate();
    const isNewClient: boolean = id === isNewRegister;
    const {t} = useTranslation();

    const initialize = async () => {
        if ((id === isNewRegister) || isNewChatContact) {
            controller.makeClient(isNewChatContact);
        } else {
            await controller.getClient(idClientExternal ?? id ?? isNewRegister, idContactExternal);
        }
    }

    useEffect(() => {
        initialize();
        controller.tableClients.limit = Math.floor((window.innerHeight - 210 - 25) / 52);
    }, []);


    const {isValidDataForm} = useValidatorInputClients(controller.client, isNewChatContact);

    const onSuccess = (key: string) => {
        if (handleSubmitExternalScreen && controller.client) {
            handleSubmitExternalScreen(controller.client);
        }
        toastMessage.success(t(key));
        backPreviousScreen();
    }

    const onSuccessSendEmail = (key: string) => {
        toastMessage.success(t(key));
    }

    const handleSubmit = async () => {

        if (isValidDataForm) {
            await controller.set(onSuccess);
        }
    };

    const backPreviousScreen = () => {
        if (!backPreviousScreenExternalScreen) {
            navigate(-1);
        } else {
            backPreviousScreenExternalScreen();
        }
    };

    return (
        <Container background={isEdit ? 'transparent' : colors.onBackground}>

            <ContentFormClient style={{padding: isEdit ? 0 : 16}}>
                {!controller.clientNotFound &&
                    <FormClient background={colors.onBackground} border={colors.border}>

                        <HeaderFormClients
                            loading={controller.loading}
                            backPreviousScreen={backPreviousScreen}
                            isUnsaved={id === isNewRegister}
                        />

                        <ContentInputs
                            heightShowingHeaderInfo={appController.heightShowingHeaderInfo}
                        >

                            <InputsFormClients
                                onUpdate={(client) => controller.setClient(client)}
                                onSearchCNPJ={(document) => controller.onSearchCNPJ(document)}
                                createObjectURLBlobUseCase={(file) => controller.createObjectURLBlobUseCase(file)}
                                clearUrlImage={() => controller.clearUrlImage()}
                                client={controller.client}
                                loading={controller.loading}
                            />

                            <Divider style={{
                                color: colors.hint,
                                borderColor: colors.border
                            }}>Endereços</Divider>

                            <InputsFormAddress
                                onNewAddress={controller.onNewAddress}
                                onDeleteAddress={() => controller.onDeleteAddress(controller.address?.id ?? '')}
                                onSearchZipCode={(address) => controller.onSearchZipCode(address)}
                                onChangeAddress={(address) => controller.onChangeAddress(address)}
                                setAddress={(address) => controller.setAddress(address)}
                                addresses={controller.client?.addresses ?? []}
                                loading={controller.loading}
                            />

                            <Divider style={{color: colors.hint, borderColor: colors.border}}/>
                            <Divider style={{
                                color: colors.hint,
                                borderColor: colors.border
                            }}>Contatos</Divider>

                            <InputsFormContacts
                                onNewUser={() => controller.onNewUser(isNewChatContact)}
                                onChangeUser={(user) => controller.onChangeUser(user)}
                                onDeleteUser={(id) => controller.onDeleteUser(id)}
                                contacts={controller.client?.users ?? []}
                                loading={controller.loading || userController.loading}
                                createObjectURLBlobUseCase={(file) => controller.createObjectURLBlobUseCase(file)}
                                backPreviousScreen={backPreviousScreen}
                                canTransfer={!isNewClient}
                                transferUserContact={(idNewClient, idUser, onSuccessTransfer) => userController.transferUser(idNewClient, idUser, onSuccessTransfer)}
                                userIsAdmin={userLogged.permissionAdmin}
                                forgot={(email) => userController.forgot(email, onSuccessSendEmail)}
                                activateAccount={(email) => userController.activateAccount(email, onSuccessSendEmail)}
                                idContactFocused={controller.idContactFocused}
                                setIdContactFocused={(id) => controller.setIdContactFocused(id)}
                                isNewChatContact={isNewChatContact}
                            />

                        </ContentInputs>

                        <FooterFormClients
                            handleSubmit={handleSubmit}
                            loading={controller.loading}
                            backPreviousScreen={backPreviousScreen}
                            isValidDataForm={isValidDataForm}
                            createdAt={controller.client?.createdAt ? `${controller.client.getOrigin} em ${displayDateToLocale(controller.client.createdAt)}` : undefined}
                        />

                        <ModalsFormClients
                            loading={controller.loading}
                            onDeleteClient={async () => {
                                controller.setRowSelectionClients([controller.client!]);
                                await controller.delete(onSuccess);
                            }}
                            client={controller.client}
                        />

                    </FormClient>}
                {controller.clientNotFound &&
                    <GenericScreenNotFound
                        textNotFound={'Oops, Empresa não encontrada ...'}
                        textHeaderForm={"Voltar"}
                        backPreviousScreen={backPreviousScreen}
                    />}
            </ContentFormClient>

            {controller.loading && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    );
});

export default FormClients;
