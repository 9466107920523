import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";

export class ImportData {
  idCompany!: string;
  importId!: string;
  status!: StatusImport;
  progress!: number;
  rows!: number;
  createdAt?: string;
  revertedRows?: number;
  revertStatus?: StatusImport;
  revertedAt?: string;
  revertProgress?: number;

  constructor({
                idCompany,
                importId,
                status,
                progress,
                rows,
                createdAt,
                revertedRows,
                revertStatus,
                revertedAt,
                revertProgress,
              }: {
    idCompany: string;
    importId: string;
    status: StatusImport;
    progress: number;
    rows: number;
    createdAt?: string;
    revertedRows?: number;
    revertStatus?: StatusImport;
    revertedAt?: string;
    revertProgress?: number;
  }) {
    Object.assign(this, {
      idCompany,
      importId,
      status,
      progress,
      rows,
      createdAt,
      revertedRows,
      revertStatus,
      revertedAt,
      revertProgress,
    });
  }

  copyWith({
             idCompany,
             importId,
             status,
             progress,
             rows,
             createdAt,
             revertedRows,
             revertStatus,
             revertedAt,
             revertProgress,
           }: {
    idCompany?: string;
    importId?: string;
    status?: StatusImport;
    progress?: number;
    rows?: number;
    createdAt?: string;
    revertedRows?: number;
    revertStatus?: StatusImport;
    revertedAt?: string;
    revertProgress?: number;
  }): ImportData {
    return new ImportData({
      idCompany: idCompany ?? this.idCompany,
      importId: importId ?? this.importId,
      status: status ?? this.status,
      progress: progress ?? this.progress,
      rows: rows ?? this.rows,
      createdAt: createdAt ?? this.createdAt,
      revertedRows: revertedRows ?? this.revertedRows,
      revertStatus: revertStatus ?? this.revertStatus,
      revertedAt: revertedAt ?? this.revertedAt,
      revertProgress: revertProgress ?? this.revertProgress,
    })
  }
}
