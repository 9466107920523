import {observer} from "mobx-react";
import React from "react";

import PickFileGeneric from "@dropDesk/presentation/components/drop_zone/pick_file_generic";
import {
    ContentIconUpload, ContentLoadFile, DescriptionFile,
    TextFileAttached,
    TextUpload
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const PickFile = observer(
    ({
         handleSelectFile,
         loading,
         removeFileAttatch,
         fileName
     }: {
        handleSelectFile: (files: File[]) => void,
        loading: boolean
        removeFileAttatch: () => void,
        fileName: string,
    }) => {


        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;


        return (
            <ContentLoadFile color={colors.text} background={colors.delicateBackground}>

                <PickFileGeneric
                    maxSizeInMegaBytes={5}
                    maxFiles={1}
                    onPick={(files: File[]) => {
                        handleSelectFile(files)
                    }}
                    disabled={loading}
                    typeFileAccept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                    isMultiple={false}
                    noDrag={true}
                    noClick={false}
                    renderComponent={<ContentIconUpload>
                        <DropDeskIcon icon={ICONS_DROPDESK.upload} color={colors.text} size={25}
                                      style={{cursor: 'pointer'}}/>
                        <TextUpload color={colors.text}>Carregue um arquivo</TextUpload>
                    </ContentIconUpload>
                    }
                />

                {fileName &&
                    <TextFileAttached color={colors.text}>
                        Arquivo anexado( <DescriptionFile color={colors.text}>{fileName}</DescriptionFile>)
                        <DropDeskIcon
                            onClick={() => removeFileAttatch()}
                            icon={ICONS_DROPDESK.close}
                            color={colors.textError}
                            size={12}
                            style={{marginLeft: 10, cursor: 'pointer'}}/>

                    </TextFileAttached>}
            </ContentLoadFile>
        )
    })
export default PickFile;
