import {observer} from "mobx-react";
import React, {useContext, useState} from "react";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {Tooltip} from "antd";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ContainerAction} from "./styled";
import ButtonActionTable from "@dropDesk/presentation/components/buttons/button_action_table";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import {useBetween} from "use-between";
import {
    useModalSearchTicketsDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/handle_change_visible";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import Tag from "@dropDesk/presentation/components/tag";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const TableTicketsDescriptionStatic
    = observer(
    ({
         searchParam,
         loading,
         permitSelectionRow,
         tableTicketsDescriptionStatic,
         getDataFromPage,
         setRowSelectionTicketsDescriptionStatic,
         rowSelectionTicketsDescriptionStatic,
         handleVisibleModalNewTicketsDescriptionStatic
     }:
         {
             searchParam: string,
             loading: boolean,
             permitSelectionRow: boolean,
             tableTicketsDescriptionStatic: ListPaginationEntity<TicketsDescriptionStaticEntity>,
             getDataFromPage: (page: number) => void,
             setRowSelectionTicketsDescriptionStatic: (ticketsDescriptionStatic: TicketsDescriptionStaticEntity[]) => void,
             rowSelectionTicketsDescriptionStatic: TicketsDescriptionStaticEntity[],
             handleVisibleModalNewTicketsDescriptionStatic: (visible: boolean, id: string) => void
         }) => {

        const collapsed = useContext(MenuCollapsedContext);
        const {
            setVisibleModalDelete,
            setVisibleModalRestore,
        } = useBetween(useModalSearchTicketsDescriptionStatic);

        const setRestore = (ticketsDescriptionStatic: TicketsDescriptionStaticEntity) => {
            setRowSelectionTicketsDescriptionStatic([ticketsDescriptionStatic]);
            setVisibleModalRestore(true);
        }

        const tableColumn = [

            {
                title: 'Descrição do problema',
                dataIndex: 'description',
                ellipsis: true,
                key: 'id',
                render: (text: string, record: TicketsDescriptionStaticEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Listável para clientes',
                key: 'id',
                dataIndex: 'listableForClients',
                render: (text: string, record: TicketsDescriptionStaticEntity) => {
                    return (
                        <Tag
                            label={!record.listableForClients ? 'Não' : 'Sim'}
                            width={44}
                        />
                    )
                }
            },

            {
                title: 'Criado em', ellipsis: true, dataIndex: 'createdAt', key: 'id', render: (text: string) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },
            {
                title: '', key: 'action', width: 65, render: (text: string, record: TicketsDescriptionStaticEntity) => (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <ContainerAction>
                            {!record.deleted &&
                                <Tooltip placement="left" title={'Excluir Problema'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRowSelectionTicketsDescriptionStatic([record]);
                                        setVisibleModalDelete(true);
                                    }}
                                    nameIcon={ICONS_DROPDESK.delete}
                                    isActionDelete={true}
                                /> </Tooltip>}
                            {record.deleted &&
                                <Tooltip placement="left" title={'Restaurar Problema'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRowSelectionTicketsDescriptionStatic([record]);
                                        setVisibleModalRestore(true);
                                    }}
                                    nameIcon={ICONS_DROPDESK.restore}
                                /> </Tooltip>

                            }
                        </ContainerAction>
                    </div>
                ),
            },
        ];
        const rowSelection = {
            selectedRowKeys: rowSelectionTicketsDescriptionStatic.map((ticketsDescriptionStatic) => ticketsDescriptionStatic.id),
            onChange: (selectedRowKeys: React.Key[], selectedRows: TicketsDescriptionStaticEntity[]) => {
                setRowSelectionTicketsDescriptionStatic(selectedRows)
            },
            getCheckboxProps: (record: TicketsDescriptionStaticEntity) => ({
                disabled: false,
            }),
            preserveSelectedRowKeys: true
        };

        return (
            <TableGeneric<TicketsDescriptionStaticEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableTicketsDescriptionStatic.data}
                onSelectedRow={(ticketsDescriptionStatic: TicketsDescriptionStaticEntity) => {

                    if (!ticketsDescriptionStatic.deleted) {
                        handleVisibleModalNewTicketsDescriptionStatic(true, ticketsDescriptionStatic.id);
                    } else {
                        setRestore(ticketsDescriptionStatic);
                    }

                }}
                loading={loading}
                labelNoResults={'Nenhum problema encontrado na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableTicketsDescriptionStatic.totalRows}
                page={tableTicketsDescriptionStatic.page}
                pageSize={tableTicketsDescriptionStatic.limit}
                rowSelection={permitSelectionRow ? rowSelection : null}
                onChange={(page) => getDataFromPage(page - 1)}
                extraLayout={-40}
            />
        )
    })
export default TableTicketsDescriptionStatic;
;
