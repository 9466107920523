import styled from "styled-components";
import {Slider} from "antd";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type RangerSliderType = {
  trackcolor: string;
  buttoncolor: string;
}

export const RangeTimeSliderStyled = styled(Slider)<RangerSliderType>`
  .ant-slider-rail {
    background: ${(props) => props.trackcolor};
  }

  .ant-slider:hover .ant-slider-rail {
    background: ${(props) => props.trackcolor};
  }

  .ant-slider-handle:focus {
    box-shadow: 0 0 0 5px ${(props) => hexToRGBA(props.buttoncolor, 0.5)};
  }
`;
