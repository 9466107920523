import React, {FormEvent, useEffect, useRef, useState} from "react";
import {Container, Input, Content} from "./styled";
import {
    InputProps
} from "@dropDesk/presentation/components/inputs/input_textarea_with_border/input_textarea_with_border";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import LabelInput from "@dropDesk/presentation/components/inputs/input_with_border/label";
import {ContentLabel} from "@dropDesk/presentation/components/inputs/input_with_border/styled";
import LeftIcon from "@dropDesk/presentation/components/inputs/input_with_border/left_icon";
import EmojiSelect from "@dropDesk/presentation/components/inputs/input_with_border/emoji_picker";
import RightIcon from "@dropDesk/presentation/components/inputs/input_with_border/right_icon";
import MessageError from "@dropDesk/presentation/components/inputs/input_with_border/message_error";

export default function InputTextAreaWithBorder(props: InputProps) {

    const ref = useRef<HTMLTextAreaElement | null>(null);
    useEffect(() => {
        if (ref.current && props.autoFocus) {
            ref.current?.focus();
        }
    }, []);

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const useBorder = props.usedBorder === false ? false : true;

    return (
        <Container marginLeft={props.marginLeft ?? 0} marginTop={props.marginTop ?? 0}>

            <ContentLabel>
                {!!props.label && <LabelInput required={props.required} label={props.label}/>}
            </ContentLabel>
            <Content
                onClick={props.onClick && !props.disabled ? props.onClick : () => {
                }}
                background={props.backgroundColor ?? colors.backgroundInput}
                usedBorder={useBorder}
                size={props.size}
                border={colors.border}
                accent={colors.accent}
            >
                {props.nameIconLeft &&
                    <LeftIcon
                        nameIconLeft={props.nameIconLeft}
                        onClickLeftIcons={props.onClickLeftIcons}
                        disabled={props.disabled}
                    />}
                {props.useEmojiPicker && !props.disabled &&
                    <EmojiSelect<React.ChangeEvent<HTMLTextAreaElement>>
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        disabled={props.disabled}
                    />
                }
                <Input
                    ref={ref}
                    autoFocus={props.autoFocus ?? false} //not working in modal antd
                    name={props.name}
                    onBlur={() => {
                        if (props.onBlur) {
                            props.onBlur();
                        }
                    }}
                    style={{cursor: props.readOnly && !props.disabled ? 'text' : props.disabled ? 'no-drop' : 'auto'}}
                    readOnly={props.readOnly ?? false}
                    color={colors.text}
                    placeholdercolor={colors.hint}
                    disabled={props.disabled}
                    placeholder={props.placeHolder}
                    value={props.value}
                    onChange={props.onChange}
                    autoComplete='off' //valor passado para não pegar preenchimento automatico do navegador
                />

                {props.nameIconRight && (
                    <RightIcon
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        onClick={props.onClick}
                        onClickRightIcons={props.onClickRightIcons}
                        size={props.size}
                        nameIconRight={props.nameIconRight}
                        sizeRightIcons={props.sizeRightIcons}
                    />
                )}

            </Content>
            {(!!props.messageError || props.messageError === "") && <MessageError messageError={props.messageError}/>}
        </Container>
    );
}
