import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import ModalInformationReport from "@dropDesk/presentation/components/modals/modal_information_report";
import {
    useModalsReportTicketAggregate
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/ui/modals/handle_change_visible";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import ModalExport from "@dropDesk/presentation/components/modals/modal_information";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useNavigate} from "react-router-dom";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsReportTicketAggregate = observer(
    ({
         loading,
         exportPdf,
         period
     }: {
         loading: boolean
         exportPdf: () => void,
         period: Array<Date> | null;

     }
    ) => {

        const {
            visibleModalInformationReport, setVisibleModalInformationReport,
            textHeaderModalInformationReport,
            textInformationHeader,
            textInformationBody,
            visibleModalExportPdf, setVisibleModalExportPdf,
            visibleModalExport, setVisibleModalExport
        } = useBetween(useModalsReportTicketAggregate);

        const navigate = useNavigate();

        return (
            <>
                <ModalInformationReport
                    open={visibleModalInformationReport}
                    onRequestClose={() => {
                        setVisibleModalInformationReport(false)
                    }}
                    textHeaderModal={textHeaderModalInformationReport}
                    textButton={'OK'}
                    onRequestSave={async () => {
                        setVisibleModalInformationReport(false);
                    }}
                    textInformationHeader={textInformationHeader}
                    textInformationBody={textInformationBody}
                    loading={loading}
                />
                <ModalInformation
                    open={visibleModalExportPdf}
                    onRequestClose={() => {
                        setVisibleModalExportPdf(false);
                    }}
                    textHeaderModal={'Exportar gráficos'}
                    textButton={'Exportar'}
                    onRequestSave={async () => {
                        exportPdf();
                        setVisibleModalExportPdf(false);
                    }}
                    nameIcon={ICONS_DROPDESK.pdf}
                    sizeIcon={115}
                    textInformation={`Deseja realmente exportar os gráficos da tela para um arquivo PDF?`}
                    loading={loading}
                />
                <ModalExport
                    open={visibleModalExport}
                    onRequestClose={() => {
                        setVisibleModalExport(false);
                    }}
                    textHeaderModal={'Exportar atendimentos'}
                    textButton={'Exportar'}
                    onRequestSave={async () => {
                        if (period && period[0] && period[1]) {
                            navigate(`${RoutesEnum.exportreporttickets}`);
                        } else {
                            toastMessage.error("Você deve informar um período de exportação.")
                        }
                        setVisibleModalExport(false);
                    }}
                    nameIcon={ICONS_DROPDESK.excel}
                    sizeIcon={120}
                    textInformation={
                        `Deseja realmente exportar todos os dados dos atendimentos para uma planilha?`
                    }
                    loading={loading}
                />
            </>
        )
    })
export default ModalsReportTicketAggregate;
