import styled from "styled-components";

export const ContentData = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 8px 16px 16px 16px;
`;

export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentInput = styled.div`
  display: flex;
`;
