import HeaderModal from "../../headers/header_modal";
import {Container, Content, ContentIcon, TextInformation, ContentInformation} from "./styled";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import {ModalProps} from "@dropDesk/presentation/components/modals/modal_information/modal_information";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


export default function ModalInformation(props: ModalProps) {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (

        <ModalMain
            onRequestClose={props.onRequestClose}
            open={props.open}
            width={400}
            renderComponent={
                <Container background={colors.onBackground}>
                    <HeaderModal
                        disabled={props.loading}
                        letter={props.textHeaderModal}
                        nameIcon={ICONS_DROPDESK.close}
                        onClick={props.onRequestClose}
                    />
                    <Content>
                        <ContentIcon>
                            <DropDeskIcon
                                icon={props.nameIcon}
                                size={props.sizeIcon ?? 130}
                                color={colors.hintInverse}
                            />
                        </ContentIcon>
                        <ContentInformation>
                            <TextInformation color={colors.text}>{props.textInformation}</TextInformation>
                        </ContentInformation>

                    </Content>
                    <FooterModal
                        disabled={props.loading}
                        loading={props.loading}
                        type={"submit"}
                        letter={props.textButton}
                        onSubmit={props.onRequestSave}
                        onRequestClose={props.onRequestClose}
                        isActionDelete={props.isActionDelete}
                        contentPopover={props.contentPopover}
                    />
                </Container>}
        />
    );
}
