import styled from "styled-components";
import {
    ButtonType,
    ContainerType,
    ContentType,
    InputType,
    LabelInputType,
    ButtonDisabledType,
    IconType
} from "@dropDesk/presentation/components/inputs/input_textarea_with_border/input_textarea_with_border";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => `${props.marginLeft}px`};
  margin-top: ${(props) => `${props.marginTop}px`};
  min-width: 100%;
`;
export const Content = styled.div<ContentType>`
    //height: ${(props) => `${props.size}px`};
  min-height: ${(props) => `${props.size}px`};
  min-width: 100%;
  background: ${(props) => props.background};
  display: flex;
  border-radius: ${(props) => props.usedBorder ? `5px` : 0};
  border: ${props => props.usedBorder ? `1px solid ${props.border}` : 'auto'};
  border-bottom: ${props => !props.usedBorder ? `1px solid ${props.border}` : 'auto'};

  &:hover {
    border-color: transparent;
    box-shadow: ${props => props.usedBorder ? `0 0 0 2px ${hexToRGBA(props.accent, .58)}` : `0 4px 2px -2px ${hexToRGBA(props.accent, 0.58)}`};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:focus-within {
    border-color: transparent;
    box-shadow: ${props => props.usedBorder ? `0 0 0 2px ${hexToRGBA(props.accent, .58)}` : `0 4px 2px -2px ${hexToRGBA(props.accent, 0.58)}`};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const Input = styled.textarea<InputType>`
  display: flex;
  flex: 1;
  color: ${(props) => props.color};
  border: 0;
  background-color: transparent;
  padding: 0 5px 0 5px;
  font-size: 15px;
  //resize: none;

  &::placeholder {
    color: ${(props) => props.placeholdercolor};
  }

`;
export const Label = styled.span<LabelInputType>`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.color};
  margin: 0 0 1px 2px;
`;

export const ButtonRight = styled.button<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.letterColor};
  font-size: 12px;
  padding: 0 5px 2px 5px;
  height: 15px;
  min-height: 15px;
  border-radius: 2px;
  border: 0;

  &:hover {
    filter: brightness(80%);
  }
`;

export const ContentButton = styled.div<ButtonDisabledType>`
  display: flex;
  cursor: ${props => props.disable ? 'no-drop' : 'auto'};
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  cursor: ${props => props.disabled ? 'no-drop' : 'pointer'};

  &:hover {
    filter: brightness(80%);
  }
`;

export const Button = styled.button`
  display: flex;
  border: 0;
  background: transparent;
  margin: 0 3px;
  align-items: center;
  justify-content: center;

  &:disabled {
    pointer-events: none;
  }
`;
