import styled from "styled-components";

type ContainerType = {
    background: string;
};

type TextType = {
    color: string;
};

type DescriptionType = {
    color: string;
    size: number;
};

type StatusType = {
    background: string;
    color: string;
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentFormSubscription = styled.div<ContainerType>`
  width: 100%;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
`;
export const ContentData = styled.div`
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 26px 0;
  overflow: auto;
`;

export const ContentDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: center;
`;
export const ContentDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentSubTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

export const ContentSubDescription = styled.div`
  display: flex;
  flex-direction: column;
`;


export const StatusSubscription = styled.div<StatusType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  color: ${(props) => props.color};
  padding: 0px 6px;
  border-radius: 5px;
  height: 16px;
  margin-left: 10px;
  font-weight: bold;
  letter-spacing: .5px;
  font-size: 10px;
`;

export const SubTitle = styled.span<TextType>`
  font-size: 15px;
  font-weight: bold;
  color: ${(props) => props.color};
  letter-spacing: .5px;
`;

export const Description = styled.span<DescriptionType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const ContentPaymentMethod = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
`;

export const ContentInvoicesPayment = styled.div`
  display: flex;
  margin-top: 25px;
`;

export const ContentCancelText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
