import {ApolloProvider} from "@apollo/client";
import "./app.css";
import "@dropDesk/storage/fontello/css/fontello.css";
import {AuthGuardProvider} from "./../guards/route_auth_guardian";
import {BrowserRouter as Router, useNavigate} from "react-router-dom";
import RoutesApp from "../routes/routes_app";
import {apolloClient} from "@dropDesk/data/clients/apollo.client";
import ptBR from "antd/lib/locale/pt_BR";
import {ConfigProvider} from "antd";

const App = () => {
    window.Buffer = window.Buffer || require("buffer").Buffer;
    return (
        <Router>
            <ConfigProvider
                locale={ptBR}
            >

                <AuthGuardProvider>
                    <ApolloProvider client={apolloClient}>
                        <RoutesApp/>
                    </ApolloProvider>
                </AuthGuardProvider>
            </ConfigProvider>

        </Router>
    );
}

export default App;
