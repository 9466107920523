import {observer} from "mobx-react";
import React from "react";
import {
  ContentExportImport, ContentFilter, ContentInput, ContentInputAndListableSectorDeleted
} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";

const SearchBar = observer(
  ({
     onSearch,
     visibleInputSearch,
     loading,
   }: {
    onSearch: (searchParam: string) => void,
    visibleInputSearch: boolean,
    loading: boolean
  }) => {

    return (
      <ContentFilter>
        <ContentInputAndListableSectorDeleted>
          <ContentInput>
            {visibleInputSearch &&
                <InputSearchGeneric
                    inputSearchPlaceholder="Pesquise por todos os atendentes"
                    loading={loading}
                    onSearch={(value) => onSearch(value)}
                />
            }
          </ContentInput>

        </ContentInputAndListableSectorDeleted>
      </ContentFilter>
    )
  })
export default SearchBar;
