import {observer} from "mobx-react";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import PopOver from "@dropDesk/presentation/components/popup/pop_confirm";
import React, {useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const FilterButton = observer(
    ({
         loading,
         content,
         hasFilter,
         open,
         onOpenChange,
         zIndex
     }: {
        loading: boolean
        content: React.ReactNode;
        hasFilter: boolean
        setVisiblePopoverExternal?: () => {}
        open?: boolean;
        onOpenChange?: ((value: boolean) => void) | undefined
        zIndex?: number
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <PopOver
                open={open}
                onOpenChange={onOpenChange}
                placement="bottomLeft"
                trigger="click"
                showArrow={false}
                overlayInnerStyle={{marginTop: -52, marginLeft: -10}}
                content={content}
            >
                <ButtonNew
                    marginLeft={10}
                    label={'filtros'}
                    onClick={() => {
                    }}
                    nameLeftIcon={ICONS_DROPDESK.filter}
                    sizeLeftIcon={14}
                    disabled={loading}
                    radius={25}
                    zIndex={zIndex}
                    color={hasFilter ? colors.accent : undefined}
                    focusBorder={open}
                />
            </PopOver>

        )
    })
export default FilterButton;
