import {Container, Content, ContentCreate, ContentImage, Section, Title,} from "./styled";
import HeaderSecondary from "@dropDesk/presentation/components/headers/header_secondary";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import {FormEvent, useEffect} from "react";
import Inputs from "src/presentation/pages/company/ui/form_company/inputs";
import ButtonCreate from "src/presentation/pages/company/ui/form_company/button_create";
import {useValidatorCreateCompany} from "@dropDesk/presentation/pages/company/ui/form_company/inputs/validator";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import Carousel from "@dropDesk/presentation/pages/login/ui/carousel";
import logo from "@dropDesk/storage/images/logo_dropdesk_portrait.png";
import {Image} from "@dropDesk/presentation/pages/login/ui/styled";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {SectorController} from "@dropDesk/presentation/pages/sector/controller/sector.controller";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {OptionType} from "@dropDesk/presentation/components/inputs/select_search_main/select_search";

const FormCompany = (observer((
    {
        onSuccessCreateAccount,
        segments
    }: {
        onSuccessCreateAccount: () => Promise<void>
        segments: Array<OptionType<string>>
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const controller = useInjection(CompanyController);
    const sectorController = useInjection(SectorController);
    const userController = useInjection(UserController);

    useEffect(() => {
        controller.makeCompany();
        userController.makeUser();
        sectorController.makeSector();
    }, []);

    const {isValidData} = useValidatorCreateCompany(controller.company, sectorController.sector, userController.user);
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (isValidData) {
            await controller.createAccount(onSuccessCreateAccount, sectorController.sector, userController.user);
        }
    };


    return (
        <Container>
            <Carousel/>
            <Section background={colors.delicateBackground}>
                <HeaderSecondary background={"transparent"} color={colors.text} height={40}/>
                <Content>
                    <ContentCreate onSubmit={handleSubmit}>
                        <ContentImage>
                            <Image src={logo} alt={"Logo DropDesk"}/>
                        </ContentImage>
                        <Title color={colors.text}>Precisamos apenas de alguns dados.</Title>
                        <Inputs
                            company={controller.company}
                            sector={sectorController.sector}
                            user={userController.user}
                            onUpdate={(company) => controller.setCompany(company)}
                            onUpdateUser={(user) => userController.setUser(user)}
                            onUpdateSector={(sector) => sectorController.setSector(sector)}
                            loading={controller.loading}
                            segments={segments}
                        />
                        <ButtonCreate
                            loading={controller.loading}
                            isValidData={isValidData}
                            handleSubmit={handleSubmit}
                        />
                    </ContentCreate>
                </Content>
            </Section>

            {controller.loading && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}

        </Container>
    );
}))
export default FormCompany;
