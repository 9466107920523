import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {CompanyCreateAccount, CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ClientCreateAccountEntity} from "@dropDesk/domain/entities/client/client_create_account_entity";

@injectable()
export class CreateAccountCompanyUseCase {
    private _repository: CompanyRepository;

    constructor(@inject(CompanyRepository) repository: CompanyRepository) {
        this._repository = repository;
    }

    public call(
        company?: CompanyEntity,
        sector?: SectorEntity,
        user?: UserEntity,
        clientCreateAccount?: ClientCreateAccountEntity
    ): Promise<CompanyCreateAccount> {
        return this._repository.createAccount(company, sector, user, clientCreateAccount);
    }
}

