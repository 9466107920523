import {observer} from "mobx-react";
import {Container, ContainerPermission, Content} from "./styled";
import React from "react";
import HeaderConfiguration from "@dropDesk/presentation/components/headers/header_configuration";
import FooterConfiguration from "@dropDesk/presentation/components/footer/footer_configuration";
import InputsNotification from "@dropDesk/presentation/pages/company_configurations/ui/notifications/inputs";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";


const Notifications = observer(
    ({
         loading,
         handleSubmit,
         onUpdate,
         companyConfiguration,
     }: {
        loading: boolean,
        handleSubmit: () => void,
        companyConfiguration: CompanyConfigurationEntity
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container>
                <Content background={colors.onBackground} border={colors.border}>
                    <HeaderConfiguration
                        letter={"Configurações de Notificações"}
                        disabled={loading}
                    />

                    <ContainerPermission>
                        <InputsNotification
                            companyConfiguration={companyConfiguration}
                            loading={loading}
                            onUpdate={onUpdate}
                        />
                    </ContainerPermission>
                    <FooterConfiguration
                        disabled={loading}
                        loading={loading}
                        type={"submit"}
                        letter={"Salvar"}
                        onSubmit={handleSubmit}
                    />
                </Content>
            </Container>
        )
    })
export default Notifications;
