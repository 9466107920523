import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ContractsRepository} from "@dropDesk/domain/repositories/common/contracts/contracts.repository";

@injectable()
export class GetSignUpContractUseCase {
    private _repository: ContractsRepository;

    constructor(@inject(ContractsRepository) repository: ContractsRepository) {
        this._repository = repository;
    }

    public call(): Promise<string> {
        return this._repository.getSignUpContract();
    }
}

