import {Tabs} from "antd";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import styled from "styled-components";


type TabStyledType = {
    color: string;
    focusedcolor: string;
    background: string;
    backgrounddropdown: string;
    colordropdown: string;
    hint: string;
}

export const TabsStyled = styled(Tabs).attrs({})<TabStyledType>`
  color: ${props => props.color} !important;

  &.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
    margin: 0px;
    padding: 8px 10px;
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: ${props => `1px solid ${props.hint}`};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => props.focusedcolor} !important;

    i {
      color: ${props => props.focusedcolor} !important;
    }
  }

  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: ${props => props.focusedcolor} !important;
  }

  .ant-tabs-tab:hover {
    color: ${props => props.focusedcolor} !important;
  }

  .ant-tabs-ink-bar {
    color: ${props => props.focusedcolor} !important;
    background-color: ${props => props.focusedcolor} !important;
  }
`;
