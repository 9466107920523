import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContainerDetailAndTitle,
    ContainerDetail,
    ContentTitle,
    Title
} from "./styled";
import React from "react";

const DetailSubscription = (
    {
        title,
        content,
        style
    }: {
        title: string;
        content: React.ReactNode
        style?: React.CSSProperties
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerDetailAndTitle background={colors.delicateBackground} style={style}>
            <ContentTitle>
                <ContentTitle>
                    <Title color={colors.text}>{title}</Title>
                </ContentTitle>
            </ContentTitle>
            <ContainerDetail background={colors.onBackground}>
                {content}
            </ContainerDetail>
        </ContainerDetailAndTitle>
    );
}
export default DetailSubscription;
