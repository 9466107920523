export class StorageKeys {
    static lastTimeChatSubscription: string = 'lastTimeChatSubscription';
}

export class SecureStorageKeys {
    static hash: string = 'hash';
    static certKey: string = 'certKey';
    static publicKey: string = 'publicKey';
}

export class FirebaseStoragePaths {
    static publicKey = 'secrets/public.pem';
    static certKey = 'secrets/cert.pem';
}
