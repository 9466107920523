import styled, {keyframes} from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
};

type ColorType = {
    color: string;
};


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  max-height: 40px;
  flex: 1;
  margin-bottom: 16px;
`;
export const ContainerStar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  max-width: 120px;
`;
export const ContainerBar = styled.div`
  display: flex;
  flex: 1;
  margin: 0 10px;
`;
export const Bar = styled.div<ContainerType>`
  display: flex;
  background: ${props => hexToRGBA(props.background, .5)};
  border-radius: 0 5px 5px 0;
`;

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 90px;
`;

export const TextRate = styled.span<ColorType>`
  font-size: 15px;
  color: ${props => props.color};
`;

