import {observer} from "mobx-react";
import React from "react";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";
import {useNavigate} from "react-router-dom";

const HeaderFormUsers = observer(
    ({
         loading,
         title,
         isUserClient
     }: {
        loading: boolean,
        isUserClient: boolean,
        title: string
    }) => {

        const navigate = useNavigate();

        return (
            <HeaderForm
                letter={title}
                onRequestClose={() => navigate(-1)}
                disabled={loading}
                hideIcon={isUserClient}
            />
        )
    })
export default HeaderFormUsers;
