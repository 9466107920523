import {
    CarouselItem,
    CarouselStyled, ContentCarousel,
    ContentImageCarousel,
    ContentTitleCarousel,
    Image, SubTitleCarousel,
    TitleCarousel
} from "@dropDesk/presentation/pages/login/ui/carousel/styled";
import workDraw from "@dropDesk/storage/images/work_draw.png";
import mobileDraw from "@dropDesk/storage/images/interaction_draw.png";
import teamChat from "@dropDesk/storage/images/team_chat.png";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const Carousel = () => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentCarousel background={colors.onBackground}>
            <CarouselStyled autoplay shapeColor={colors.text}>

                <CarouselItem>

                    <ContentImageCarousel>
                        <Image src={workDraw} alt={"Imagem Team Chat DropDesk"}/>
                    </ContentImageCarousel>

                    <ContentTitleCarousel>
                        <TitleCarousel color={colors.text}>Ajudamos empresas a ter um jeito único de atender
                            seus clientes.</TitleCarousel>
                        <SubTitleCarousel color={colors.text}>Com o DropDesk você cadastra atendentes,
                            organiza por setores e no fim
                            de todo atendimento seu cliente pode avaliar o atendimento que recebeu.</SubTitleCarousel>
                    </ContentTitleCarousel>

                </CarouselItem>

                <CarouselItem>

                    <ContentImageCarousel>
                        <Image src={mobileDraw} alt={"Imagem Team Chat DropDesk"}/>
                    </ContentImageCarousel>

                    <ContentTitleCarousel>
                        <TitleCarousel color={colors.text}>Simplificamos e organizamos todos os seus
                            atendimentos em um único lugar.</TitleCarousel>
                        <SubTitleCarousel color={colors.text}>O DropDesk centraliza todos os atendimento
                            além de te possibilitar atender por meio do
                            computador ou pelo celular.</SubTitleCarousel>
                    </ContentTitleCarousel>

                </CarouselItem>

                <CarouselItem>

                    <ContentImageCarousel>
                        <Image src={teamChat} alt={"Imagem Team Chat DropDesk"}/>
                    </ContentImageCarousel>

                    <ContentTitleCarousel>
                        <TitleCarousel color={colors.text}>Descomplicamos o universo do atendimento por
                            mensagens para toda sua equipe.</TitleCarousel>
                        <SubTitleCarousel color={colors.text}>Os consumidores preferem e compram de empresas que
                            utilizam aplicativos de mensagens para se
                            relacionar. O DropDesk ajuda a empresas a ter uma melhor gestão dessas mensagens para
                            consultas e análises futuras do
                            time.</SubTitleCarousel>
                    </ContentTitleCarousel>

                </CarouselItem>
            </CarouselStyled>
        </ContentCarousel>
    );
};

export default Carousel;
