import {observer} from "mobx-react";
import React from "react";
import ModalRestore from "@dropDesk/presentation/components/modals/modal_information";
import ModalExport from "@dropDesk/presentation/components/modals/modal_information";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {useModalSearchSector} from "@dropDesk/presentation/pages/sector/ui/search/modals/handle_change_visible";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useNavigate} from "react-router-dom";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsSector = observer(
    ({
         restoreSector,
         deleteSector,
         removeLineSelectRow,
         loading
     }: {
        restoreSector: () => Promise<void>,
        deleteSector: () => Promise<void>,
        removeLineSelectRow: () => void,
        loading: boolean
    }) => {

        const {
            setVisibleModalDelete,
            setVisibleModalRestore,
            visibleModalDelete,
            visibleModalRestore,
            visibleModalExport, setVisibleModalExport
        } = useBetween(useModalSearchSector);

        const navigate = useNavigate();

        return (
            <>
                <ModalRestore
                    open={visibleModalRestore}
                    onRequestClose={() => {
                        setVisibleModalRestore(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Restaurar Setor'}
                    textButton={'Restaurar'}
                    onRequestSave={async () => {
                        restoreSector();
                        setVisibleModalRestore(false);
                    }}
                    nameIcon={ICONS_DROPDESK.restore}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente fazer a restauração? Após a restauração, o setor volta a ser listado em todo o sistema.`
                    }
                    loading={loading}
                />
                <ModalDelete
                    open={visibleModalDelete}
                    onRequestClose={() => {
                        setVisibleModalDelete(false);
                        removeLineSelectRow();
                    }}
                    textHeaderModal={'Excluir Setor'}
                    textButton={'Excluir'}
                    onRequestSave={async () => {
                        deleteSector();
                        setVisibleModalDelete(false);
                    }}
                    nameIcon={ICONS_DROPDESK.delete}
                    sizeIcon={130}
                    textInformation={
                        `A exclusão deste setor, não excluí suas relações com os atendimentos,
         apenas o setor é excluído não sendo mais listado.`
                    }
                    loading={loading}
                    isActionDelete={true}
                />
                <ModalExport
                    open={visibleModalExport}
                    onRequestClose={() => {
                        setVisibleModalExport(false);
                    }}
                    textHeaderModal={'Exportar setores'}
                    textButton={'Exportar'}
                    onRequestSave={async () => {
                        navigate(`${RoutesEnum.exportsectors}`);
                        setVisibleModalExport(false);
                    }}
                    nameIcon={ICONS_DROPDESK.excel}
                    sizeIcon={120}
                    textInformation={
                        `Deseja realmente exportar todos os dados de setores para uma planilha?`
                    }
                    loading={loading}
                />
            </>
        )
    })
export default ModalsSector;
