import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
    isDarkTheme: boolean;
}

type TextType = {
    color: string;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div<ContainerType>`
  position: relative;
  background: ${props => props.background};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
  padding: 6px 9px 8px 9px;
  float: left;
  max-width: 70%;
`;

export const ContentText = styled.div`
  text-align: center;
  display: inline-block;
`;

export const Text = styled.span<TextType>`
  text-align: center;
  display: inline-block;
  font-size: 14px;
  color: ${props => props.color};
`;

export const ContainerTime = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 5px;
`;

export const TextItalic = styled.span<TextType>`
  font-style: italic;
  font-size: 11px;
  color: ${props => props.color};
`;

