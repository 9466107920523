import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";

@injectable()
export class ListChatsAttendingUseCase {
  private _repository: ChatRepository;

  constructor(@inject(ChatRepository) repository: ChatRepository) {
    this._repository = repository;
  }

  public call(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>> {
    return this._repository.listChatsAttending(searchParam, page);
  }
}

