import styled from "styled-components";

export const ContentExportImport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ContentFilter = styled.div`
  display: flex;
  height: 75px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  position: relative;
  margin-top: 1px;
`;

export const ContentInputAndFilter = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
