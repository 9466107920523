import {
    BusinessOperationDayEntity,
    BusinessOperationHoursConfigurationEntity
} from "./business_operation_hours_configuration.entity";
import {PortalClientConfigurationEntity} from "./portal_client_configuration.entity";
import {TicketConfigurationEntity} from "./ticket_configuration.entity";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import {ThemeConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/theme_configuration.entity";
import {ChatConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/chat_configuration.entity";
import {
    NotificationsConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/notifications_configuration.entity";

export class CompanyConfigurationEntity {
    id!: string;
    idCompany!: string;
    company!: CompanyEntity;
    ticket!: TicketConfigurationEntity;
    portalClient!: PortalClientConfigurationEntity;
    businessOperationHours!: BusinessOperationHoursConfigurationEntity;
    theme!: ThemeConfigurationEntity;
    chat!: ChatConfigurationEntity;
    notifications!: NotificationsConfigurationEntity;
    createdAt?: string;


    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = ['company'];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    static fromJson(json: Record<string, any>): CompanyConfigurationEntity {
        const configurations = new CompanyConfigurationEntity({
            id: json.id,
            idCompany: json.idCompany,
            company: json.company,
            ticket: new TicketConfigurationEntity(json.ticket),
            theme: new ThemeConfigurationEntity(json.theme),
            businessOperationHours: new BusinessOperationHoursConfigurationEntity({
                monday: new BusinessOperationDayEntity(json.businessOperationHours.monday),
                tuesday: new BusinessOperationDayEntity(json.businessOperationHours.tuesday),
                wednesday: new BusinessOperationDayEntity(json.businessOperationHours.wednesday),
                thursday: new BusinessOperationDayEntity(json.businessOperationHours.thursday),
                friday: new BusinessOperationDayEntity(json.businessOperationHours.friday),
                saturday: new BusinessOperationDayEntity(json.businessOperationHours.saturday),
                sunday: new BusinessOperationDayEntity(json.businessOperationHours.sunday),
                usesTwoOperationHours: json.businessOperationHours.usesTwoOperationHours,
                timezone: json.businessOperationHours.timezone,
            }),
            portalClient: new PortalClientConfigurationEntity(json.portalClient),
            chat: ChatConfigurationEntity.fromJson(json.chat),
            notifications: new NotificationsConfigurationEntity(json.notifications),
            createdAt: json['createdAt'],
        });

        return configurations;
    }

    constructor({
                    id,
                    idCompany,
                    ticket,
                    company,
                    portalClient,
                    businessOperationHours,
                    theme,
                    chat,
                    notifications,
                    createdAt
                }: {
        id: string;
        idCompany: string;
        company?: CompanyEntity;
        ticket: TicketConfigurationEntity;
        portalClient: PortalClientConfigurationEntity;
        businessOperationHours: BusinessOperationHoursConfigurationEntity;
        theme: ThemeConfigurationEntity;
        chat: ChatConfigurationEntity;
        notifications: NotificationsConfigurationEntity;
        createdAt?: string;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            company,
            ticket,
            portalClient,
            businessOperationHours,
            theme,
            chat,
            notifications,
            createdAt
        });
    }

    copyWith({
                 id,
                 idCompany,
                 ticket,
                 company,
                 portalClient,
                 businessOperationHours,
                 theme,
                 chat,
                 notifications,
                 createdAt
             }: {
        id?: string;
        idCompany?: string;
        company?: CompanyEntity;
        ticket?: TicketConfigurationEntity;
        portalClient?: PortalClientConfigurationEntity;
        businessOperationHours?: BusinessOperationHoursConfigurationEntity;
        theme?: ThemeConfigurationEntity;
        chat?: ChatConfigurationEntity;
        notifications?: NotificationsConfigurationEntity;
        createdAt?: string;
    }): CompanyConfigurationEntity {
        return new CompanyConfigurationEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            company: company ?? this.company,
            ticket: ticket ?? this.ticket,
            portalClient: portalClient ?? this.portalClient,
            businessOperationHours: businessOperationHours ?? this.businessOperationHours,
            theme: theme ?? this.theme,
            chat: chat ?? this.chat,
            notifications: notifications ?? this.notifications,
            createdAt: createdAt ?? this.createdAt,
        })
    }
}
