import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";
import {CancelTokenSource} from "axios";

@injectable()
export class FindByPKTicketsUseCase {
    private _repository: TicketRepository;

    constructor(@inject(TicketRepository) repository: TicketRepository) {
        this._repository = repository;
    }

    public call(id: string, listMessages: boolean, cancelTokenSource?: CancelTokenSource): Promise<TicketEntity> {
        return this._repository.findByPK(id, listMessages, cancelTokenSource);
    }
}

