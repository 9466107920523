import {ContentDataInput, ContentInput} from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/styled";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import React from "react";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const InputsProblemDescription = (
    {
        useProblemStatic,
        loading,
        ticket,
        handleAllChanges,
        onOpenModalTicketDescriptionStatic,
        errorMessageDescription,
        autoFocus
    }: {
        useProblemStatic: boolean
        loading: boolean
        ticket: TicketEntity,
        handleAllChanges: (event: { target: { name: string; value: any; checked?: any; type: string } }) => void
        onOpenModalTicketDescriptionStatic: () => void
        errorMessageDescription: string
        autoFocus?: boolean;
    }
) => {

    return (
        <ContentDataInput>
            <ContentInput>
                {!useProblemStatic && <InputTextAreaWithBorder
                    required={true}
                    autoFocus={autoFocus ?? false}
                    name='description'
                    size={65}
                    disabled={loading}
                    placeHolder={"Informe uma descrição do problema"}
                    messageError={errorMessageDescription}
                    label={"Descrição do problema"}
                    value={ticket.description}
                    onChange={handleAllChanges}
                />}
                {useProblemStatic &&
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        <InputWithBorder
                            required={true}
                            name='descriptionStatic'
                            size={37}
                            onClick={onOpenModalTicketDescriptionStatic}
                            readOnly={true}
                            disabled={loading}
                            placeHolder={"Selecione o problema"}
                            nameIconRight={ICONS_DROPDESK.downArrow}
                            sizeRightIcons={14}
                            marginRightRightIcons={10}
                            messageError={errorMessageDescription}
                            label={"Problema estático"}
                            value={ticket.descriptionStatic?.description ?? ''}
                            onChange={() => {
                            }}
                        />
                    </div>
                }
            </ContentInput>
        </ContentDataInput>
    )
}
export default InputsProblemDescription;
