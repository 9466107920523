export enum PaymentSubscriptionStatus {
    active = 'active',
    canceled = 'canceled',
    waitingPayment = 'waitingPayment',
}

export enum PaymentSubscriptionOrigin {
    owner = 'owner',
    androidPay = 'android_pay',
    applePay = 'apple_pay'
}
