import {
    AnchorLocalizationMessage,
    ContainerLocalizationMessage,
    ContentCaption
} from "@dropDesk/presentation/components/messages/message_box/localization_message/styled";
import {observer} from "mobx-react";

const LocalizationMessage = observer((
    {
        caption,
        latitude,
        longitude,
        color
    }: {
        latitude: number
        longitude: number
        caption?: string | null
        color: string;
    }) => {

    const STATIC_URL = 'https://maps.googleapis.com/maps/api/staticmap?markers=color:MARKER_COLOR|LATITUDE,LONGITUDE&zoom=ZOOM&size=270x200&scale=2&key=KEY';
    const MAP_URL = 'https://www.google.com/maps/search/?api=1&query=LATITUDE,LONGITUDE&zoom=ZOOM';

    const buildURL = (url: string) => {
        return url
            .replace(/LATITUDE/g, latitude.toString())
            .replace(/LONGITUDE/g, longitude.toString())
            .replace('MARKER_COLOR', 'red')
            .replace('ZOOM', '14')
            .replace('KEY', process.env.APIKEY_LOCALIZATION_GOOGLE as string)
    }

    return (
        <ContainerLocalizationMessage>
            <AnchorLocalizationMessage
                // onClick={props.onOpen}
                target={'_blank'}
                href={buildURL(MAP_URL)}
            >
                <img
                    alt={'localização'}
                    src={buildURL(STATIC_URL)}
                    style={{width: '100%'}}
                />
            </AnchorLocalizationMessage>
            {caption && <ContentCaption color={color}>{caption}</ContentCaption>}
        </ContainerLocalizationMessage>
    )
});

export default LocalizationMessage;
