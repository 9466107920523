import OneSignal from "react-onesignal";
import {NotificationData} from "@dropDesk/domain/entities/notifications/notification_data";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {FeatureToggle} from "@dropDesk/data/clients/firebase_remote_configs_client";
import {navigate} from "@dropDesk/utils/helpers/navigation";


export class NotificationServiceHandler {

    private static eventListener(event: any) {
        const data = NotificationData.fromJson(event.notification.additionalData);

        if (data.hasTicketNotification) {
            navigate(`${RoutesEnum.ticketsView}/${data.targetId}` as RoutesEnum);
        }

        if (data.hasChatNotification) {
            navigate(`${RoutesEnum.chat}/${data.targetId}` as RoutesEnum);
        }

        if (data.hasExportNotification && data.parseTableNameToRoute) {
            let extras;
            if (data.parseExtraToReport) {
                extras = data.parseExtraToReport;
            }
            navigate(data.parseTableNameToRoute, extras);
        }
    }

    static async setup(): Promise<void> {
        try {
            const oneSignalToggle = FeatureToggle.getOneSignalToggleState();
            const mode = process.env.MODE;
            if (oneSignalToggle === mode) {
                await OneSignal.init({
                    appId: process.env.ONESIGNAL_APP_ID as string,
                    allowLocalhostAsSecureOrigin: mode === 'development',
                    serviceWorkerParam: {scope: '/push/onesignal/'},
                    serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
                });
                await OneSignal.Slidedown.promptPush();
                OneSignal.Notifications.addEventListener('click', NotificationServiceHandler.eventListener);
            }
        } catch (error) {
            return Promise.resolve();
        }
    }
}
