import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {RemoteStorageDatasource} from "@dropDesk/data/data_source/remote_storage/remote_storage_datasource";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";

@injectable()
export abstract class ResponseStandardRemoteDataSource {
  public abstract set(responseStandard: ResponseStandardEntity): Promise<ResponseStandardEntity>;

  public abstract list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean): Promise<ListPaginationEntity<ResponseStandardEntity>>;

  public abstract restore(responseStandard: ResponseStandardEntity[]): Promise<void>;

  public abstract delete(responseStandard: ResponseStandardEntity[]): Promise<void>;

  public abstract findByPK(id: string): Promise<ResponseStandardEntity>;

}

@injectable()
export class ResponseStandardRemoteDataSourceImpl implements ResponseStandardRemoteDataSource {
  private _remoteDataSource: RemoteStorageDatasource;

  constructor(
    @inject(RemoteStorageDatasource) dataSource: RemoteStorageDatasource,
  ) {
    this._remoteDataSource = dataSource;
  }

  baseResponseStandardUrl: string = 'responseStandard/';
  baseSetResponseStandardUrl: string = `${this.baseResponseStandardUrl}set`;
  baseRestoreResponseStandardUrl: string = `${this.baseResponseStandardUrl}restore/`;


  public async list(
    page: number,
    searchParam: string,
    limit: number,
    listOnlyDeleted: boolean
  ): Promise<ListPaginationEntity<ResponseStandardEntity>> {
    return new Promise<ListPaginationEntity<ResponseStandardEntity>>(async (resolve, reject) => {
      try {

        const response = await api.get(
          `${this.baseResponseStandardUrl}?searchParam=${searchParam ?? ''}&page=${page ?? ''}&limit=${limit ?? ''}&type=${listOnlyDeleted ? 'deleted' : ''}`
        );

        const result = new ListPaginationEntity<ResponseStandardEntity>({
          page,
          limit,
          totalRows: response.data.totalRows,
          pages: response.data.pages,
          data: response.data.data.map((entry: ResponseStandardEntity) => new ResponseStandardEntity({...entry})),
        });

        return resolve(result);

      } catch (error) {
        return reject(parseServerError(error));
      }
    })
  }


  public set(responseStandard: ResponseStandardEntity): Promise<ResponseStandardEntity> {
    return new Promise<ResponseStandardEntity>(async (resolve, reject) => {
      try {

        const response = await api.post(this.baseSetResponseStandardUrl, responseStandard);
        return resolve(response.data);

      } catch (error) {
        return reject(parseServerError(error));
      }
    })
  }

  public restore(responseStandard: ResponseStandardEntity[]): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {

        for (let i = 0; i < responseStandard.length; i++) {
          await api.patch(this.baseRestoreResponseStandardUrl + responseStandard[i].id);
        }

        return resolve();

      } catch (error) {
        return reject(parseServerError(error));
      }
    })
  }

  public delete(responseStandard: ResponseStandardEntity[]): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {

        for (let i = 0; i < responseStandard.length; i++) {
          await api.delete(this.baseResponseStandardUrl + responseStandard[i].id);
        }

        return resolve();

      } catch (error) {
        return reject(parseServerError(error));
      }
    })
  }

  public findByPK(id: string): Promise<ResponseStandardEntity> {
    return new Promise<ResponseStandardEntity>(async (resolve, reject) => {
      try {

        const response = await api.get(this.baseResponseStandardUrl + id);
        const responseStandard = new ResponseStandardEntity({
          ...response.data
        })

        return resolve(responseStandard);

      } catch (error) {
        return reject(parseServerError(error));
      }
    })
  }


}
