import styled from "styled-components";
import {Popover} from "antd";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type PopOverType = {
    borderColor: string;
    shadow: string;
}
export const PopOverStyled = styled(Popover)<PopOverType>`

  .ant-popover-title {
    border-bottom: 1px solid ${props => hexToRGBA(props.borderColor, 0.18)};
  }
`;
