import {ContainerPaymentMethod} from "./styled";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {TabsPayment} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import TabsLeftStyle from "@dropDesk/presentation/components/tabs/left_style";
import {TextTab} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/styled";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import CreditCardPaymentMethod
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card";
import PixPaymentMethod from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/pix";
import InvoicePaymentMethod
    from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/invoice";
import {TokenizeCardEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";


const PaymentMethod = observer((
    {
        newTokenizeCard,
        onUpdateTokenizeCard,
        loading,
        activeTabPayment,
        setActiveTabPayment
    }: {
        newTokenizeCard: TokenizeCardEntity | null
        onUpdateTokenizeCard: (newTokenizeCard: TokenizeCardEntity) => void
        loading: boolean
        activeTabPayment: TabsPayment | null,
        setActiveTabPayment: (value: TabsPayment) => void
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const tabs = [
        {
            label:
                <TextTab>
                    <DropDeskIcon icon={ICONS_DROPDESK.creditCard} size={15} style={{marginLeft: 5, marginRight: 5}}/>
                    Cartão de Crédito
                </TextTab>,
            key: TabsPayment.creditCard,
            children: <CreditCardPaymentMethod
                newTokenizeCard={newTokenizeCard!}
                onUpdateTokenizeCard={(card) => onUpdateTokenizeCard(card)}
                loading={loading}
                pay={() => {
                }}
                onClickPayCreditCardSaved={() => {
                }}
                isEditExternal={false}
                hideButtonPay={true}
            />,
        },
        {
            label:
                <TextTab>
                    <DropDeskIcon icon={ICONS_DROPDESK.pix} size={15} style={{marginLeft: 5, marginRight: 5}}/>
                    Pix
                </TextTab>,
            key: TabsPayment.pix,
            children: <PixPaymentMethod
                loading={loading}
                pay={() => {
                }}
                isEditExternal={false}
                hideButtonPay={true}
            />,
        },
        {
            label:

                <TextTab>
                    <DropDeskIcon
                        color={colors.text}
                        icon={ICONS_DROPDESK.billet} size={15}
                        style={{marginLeft: 5, marginRight: 5}}
                    />
                    Boleto
                </TextTab>
            ,
            key: TabsPayment.boleto,
            disabled: false,
            children:
                <InvoicePaymentMethod
                    loading={loading}
                    pay={() => {
                    }}
                    isEditExternal={false}
                    disabled={false}
                    hideButtonPay={true}
                />
        },
    ];

    return (
        <ContainerPaymentMethod border={colors.delicateBackground}>
            <TabsLeftStyle
                defaultActiveKey={activeTabPayment ?? undefined}
                withMarginInTabs={true}
                destroyInactiveTabPane={true}
                onChange={(key) => setActiveTabPayment(key as TabsPayment)}
                items={tabs}
            />
        </ContainerPaymentMethod>

    );
});
export default PaymentMethod;
