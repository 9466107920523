import styled from "styled-components";

type ContainerType = {
    background: string;
};
type ContainerTypeFormSector = {
    background: string;
    border: string;
};

type SquareColoredType = {
    color: string;
}


export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px);
  flex-direction: column;
  background: ${(props) => props.background};
  border-radius: 5px;
  overflow: auto;
`;
export const ContentFormSector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;

export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
  flex: 1;
  overflow: auto;
`;
export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;

export const FormSector = styled.div<ContainerTypeFormSector>`
  display: flex;
  min-width: 650px;
  flex-direction: column;
  overflow: auto;
  background: ${props => props.background};
  border-radius: 5px;
  border: 1px solid ${props => props.border};
  max-width: 768px;
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`;

export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;
export const ContentExplicationColor = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-start;
`;
export const ContentButtonGarbage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-right: 15px;

`;
export const ContentColorSquare = styled.div`
  display: flex;
  flex-direction: row;
  height: 33px;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 0 0;
`;
export const SquareColored = styled.div<SquareColoredType>`
  display: flex;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;
export const TextColored = styled.span<SquareColoredType>`
  display: flex;
  color: ${(props) => props.color};
  font-weight: bold;
  margin-left: 5px;
  font-size: 15px;
`;
export const ContainerColorSquare = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;
