import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";

@injectable()
export class NavigateToMessageUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(id: string, ticketNumber: number): Promise<ListPaginationEntity<TicketMessageEntity>> {
        return this._repository.navigateToMessage(id, ticketNumber);
    }
}

