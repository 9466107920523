import React, {useEffect} from 'react';
import {Container, ContentList, SubContentList,} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import SearchBar from "@dropDesk/presentation/pages/client/ui/contacts/search/search_bar";
import TableContacts from "@dropDesk/presentation/pages/client/ui/contacts/search/table";
import ModalContacts from "@dropDesk/presentation/pages/client/ui/contacts/search/modals";
import FooterGarbage from "@dropDesk/presentation/components/footer/footer_garbage";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

const Contacts = (observer(() => {

    const controller = useInjection(UserController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const {t} = useTranslation();
    const heightGarbage = appController.user?.permissionDeleteData ? 0 : 50;

    useEffect(() => {
        controller.initializeContacts(heightGarbage - appController.heightShowingHeaderInfo);
    }, []);

    const onSuccess = (key: string) => {
        controller.list(controller.searchParam);
        toastMessage.success(t(key));
    }

    return (
        <Container background={colors.onBackground}>
            <ContentList>

                <SearchBar
                    onSearch={(searchParam) => controller.list(searchParam)}
                    visibleInputSearch={controller.rowSelectionUsers.length === 0}
                    loading={controller.loading}
                    visibleButtonsOfActions={!controller.listOnlyDeleted && controller.rowSelectionUsers.length === 0}
                    visibleButtonRestore={controller.listOnlyDeleted && controller.rowSelectionUsers.length > 0}
                />

                <SubContentList>

                    <TableContacts
                        getDataFromPage={(page) => controller.getDataFromPage(page)}
                        tableUsers={controller.tableUsers}
                        searchParam={controller.searchParam}
                        loading={controller.loading}
                        permissionDeleteData={appController.user?.permissionDeleteData ?? false}
                        permitSelectionRow={false}
                        setRowSelectionUsers={(users: UserEntity[]) => controller.setRowSelectionUsers(users)}
                        rowSelectionUsers={controller.rowSelectionUsers}
                        heightGarbage={heightGarbage}

                    />

                    {appController.user?.permissionDeleteData && <FooterGarbage
                        setListOnlyDeleted={(value) => controller.setListOnlyDeleted(value)}
                        visibleListOnlyDeleted={controller.listOnlyDeleted}
                        loading={controller.loading}
                        text={'Contatos ativos'}
                        textDelete={'Contatos excluídos'}
                    />}

                    <ModalContacts
                        listContacts={() => controller.list(controller.searchParam)}
                        loading={controller.loading}
                        removeLineSelectRow={() => controller.removeLineSelectRow()}
                        restoreUser={() => controller.restore(onSuccess)}
                    />

                </SubContentList>
            </ContentList>

            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    )

}))
export default Contacts;
