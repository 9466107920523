import React, {useEffect} from "react";
import {Container, ContentList, SubContentList} from "./styled";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import SearchBar from "@dropDesk/presentation/pages/ticket/evaluation/ui/search/search_bar";
import TableEvaluations from "@dropDesk/presentation/pages/ticket/evaluation/ui/search/table";
import {
    TicketEvaluationController
} from "@dropDesk/presentation/pages/ticket/evaluation/controller/ticket_evaluation.controller";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const Evaluation = (observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const controller = useInjection(TicketEvaluationController);
    const userController = useInjection(UserController);

    useEffect(() => {
        controller.initialize(appController.heightShowingHeaderInfo);
    }, []);

    return (

        <Container background={colors.onBackground} border={colors.border}
                   heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>

            <ContentList>
                <SearchBar
                    onSearch={(searchParam) => controller.list(searchParam)}
                    setSearchEvaluationValue={(value) => controller.setSearchEvaluationValue(value)}
                    loading={controller.loading}
                    searchEvaluationValue={controller.searchEvaluationValue}
                />
                <SubContentList>
                    <TableEvaluations
                        setUser={(user) => userController.setUser(user)}
                        getDataFromPage={(page) => controller.getDataFromPage(page)}
                        tableTicketEvaluation={controller.tableTicketEvaluation}
                        searchParam={controller.searchParam}
                        loading={controller.loading}
                    />
                </SubContentList>
            </ContentList>

        </Container>
    );
}))
export default Evaluation;
