import styled from "styled-components";
import {Popover} from "antd";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


type PopoverType = {
    position: 'left' | 'right';
    marginSize: number;
}

type ContainerArrowType = {
    color: string;
    accent: string;
}

type ContainerType = {
    background: string;
    isDarkTheme: boolean;
    hint: string;
}

export const PopOver = styled(Popover)<PopoverType>`
  .ant-popover-inner {
    margin-right: ${props => props.position === 'left' ? 'auto' : `${props.marginSize}px`};
    margin-left: ${props => props.position === 'left' ? `${props.marginSize}px` : 'auto'};
    border-radius: 8px;
    padding: 0px !important;
  }

  .ant-popover-inner-content {
    padding: 0px !important;
  }
`;

export const Container = styled.div<ContainerType>`
  margin: 0;
  padding: 0;
  width: 150px;
  padding: 4px;
  box-shadow: ${props => props.isDarkTheme ? `0px 1px 2px 0px ${hexToRGBA(props.hint, 1)}` : `none`};
  border-radius: 10px;
  background: ${props => props.background};
`;


export const ButtonArrowMenu = styled.button<ContainerArrowType>`
  padding: 16px;
  display: flex;
  flex-direction: row;
  border: 0;
  height: 35px;
  min-height: 35px;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: none;
  font-size: 14px;
  width: 100%;
  color: ${props => props.color};
  border-radius: 10px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:disabled {
    cursor: no-drop;
  }
`;
export const ArrowDown = styled.i<ContainerArrowType>`
  font-size: 14px;
  color: ${props => props.color};
`;

