import {observer} from "mobx-react";
import React from "react";
import Monday from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/monday";
import Tuesday from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/tuesday";
import Wednesday from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/wednesday";
import Thursday from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/thursday";
import Friday from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/friday";
import Saturday from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/saturday";
import Sunday from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/sunday";
import {CompanyConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {ContainerHours} from "@dropDesk/presentation/pages/company_configurations/ui/business_hour_operator/hours/styled";

const Hours = observer(
  ({
     loading,
     onUpdate,
     companyConfiguration
   }: {
    loading: boolean,
    onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
    companyConfiguration: CompanyConfigurationEntity
  }) => {
    return (
      <ContainerHours>

        <Monday
          onUpdate={onUpdate}
          loading={loading}
          companyConfiguration={companyConfiguration}
        />
        <Tuesday
          onUpdate={onUpdate}
          loading={loading}
          companyConfiguration={companyConfiguration}
        />
        <Wednesday
          onUpdate={onUpdate}
          loading={loading}
          companyConfiguration={companyConfiguration}
        />
        <Thursday
          onUpdate={onUpdate}
          loading={loading}
          companyConfiguration={companyConfiguration}
        />
        <Friday
          onUpdate={onUpdate}
          loading={loading}
          companyConfiguration={companyConfiguration}
        />
        <Saturday
          onUpdate={onUpdate}
          loading={loading}
          companyConfiguration={companyConfiguration}
        />
        <Sunday
          onUpdate={onUpdate}
          loading={loading}
          companyConfiguration={companyConfiguration}
        />
      </ContainerHours>

    )
  })
export default Hours;
