import styled from "styled-components";


export const Container = styled.div`
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  border-radius: 5px;
  max-height: 500px;
  height: 300px;
  width: 300px;
`;



