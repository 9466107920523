import {injectable} from "inversify";
import {PlanEntity} from "@dropDesk/domain/entities/plan/plan_entity";
import {
    PaymentSubscriptionEntity,
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {Observable} from "rxjs";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {PaymentStatus} from "@dropDesk/domain/entities/payment/payment.enum";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {
    PaymentSubscriptionSimulateEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_simulate.entity";

@injectable()
export abstract class SubscriptionRepository {

    public abstract listPlan(listPlansLegacy: boolean): Promise<PlanEntity[]>;

    public abstract pay(subscription: PaymentSubscriptionEntity, paymentMethod?: PaymentMethodEntity): Promise<PaymentSubscriptionEntity>;

    public abstract getPaymentSubscription(id: string): Observable<PaymentEntity>;

    public abstract list(page: number, limit: number, status?: PaymentStatus): Promise<ListPaginationEntity<PaymentEntity>>;

    public abstract cancel(idSubscription: string, cancellationReason: string, isFutureSubscription: boolean): Promise<PaymentSubscriptionEntity>;

    public abstract reactivate(idSubscription: string): Promise<PaymentSubscriptionEntity>;

    public abstract findByPK(id: string): Promise<PaymentSubscriptionEntity>;

    public abstract simulate(subscription: PaymentSubscriptionEntity): Promise<PaymentSubscriptionSimulateEntity>;
}
