import styled from "styled-components";

type ContainerType = {
    background: string;
};

type ContainerShowType = {
    heightShowingHeaderInfo: number;
};

type ContainerTypeFormClient = {
    background: string;
    border: string;
};
export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${(props) => props.background};
  overflow: hidden;
  border-radius: 5px;
`;
export const ContentFormClient = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const SubContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
  flex: 1;
  overflow: auto;
`;
export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;
export const FormClient = styled.div<ContainerTypeFormClient>`
  width: 100%;
  max-width: 1060px;
  min-height: 400px;
  flex-direction: column;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 1060px) {
    flex-direction: column;
    position: relative;
  }
`;
export const ContentInputs = styled.div<ContainerShowType>`
  display: flex;
  height: calc(100vh - 210px - ${props => `${props.heightShowingHeaderInfo}px`});
  flex-direction: column;
  padding: 16px;
  margin: 0 auto;
  max-width: 1060px;
  min-height: 400px;
  overflow: auto;

`;
export const SubContentInputs = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentImage = styled.div`
  display: flex;
  width: 170px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
`;
export const ContentData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const ContentDataLogin = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

