export enum StatusTypes {
    ERROR = 'ERROR',
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
    REPLACED = 'REPLACED',
    CONNECTING = 'CONNECTING',
    UNAUTHENTICATED = 'UNAUTHENTICATED',
    CRASHED = 'CRASHED',
    RECONNECTING = 'RECONNECTING',
    LOGGED_OUT = 'LOGGED_OUT',
    TIMED_OUT = 'TIMED_OUT',
    UPSERT = 'UPSERT',
    UPDATE = 'UPDATE',
    DUPLICATE = 'DUPLICATE',
}

export enum TabsChat {
    waiting = 'waiting',
    attending = 'attending',
    contacts = 'contacts'
}

