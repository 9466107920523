import styled from "styled-components";
import {Link} from "react-router-dom";

type TextType = {
    color: string;
}
type ContainerType = {
    background: string;
}

export const SectionRememberPassword = styled.div`
  display: flex;
  height: 40px;
  min-height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LinkButton = styled.button<TextType>`
  display: flex;
  color: ${props => props.color};
  font-size: 14px;
  border: 0;
  transition: background-color 0.2s;
  background: transparent;

  &:hover {
    filter: brightness(80%);
    text-decoration: underline;

  }
`;
export const LinkCreateAccount = styled(Link)<TextType>`
  display: flex;
  background-color: transparent;
  color: ${(props) => props.color};
  font-size: 15px;
  font-weight: bold;
  flex-direction: row;
  border: 0;
  transition: background-color 0.2s;

  &:hover {
    text-decoration: underline;
    filter: brightness(80%);
  }
`;
export const Divider = styled.div<ContainerType>`
  width: 1px;
  min-width: 1px;
  min-height: 15px;
  background: ${(props) => props.background};
  margin: 0 10px;
`;
