import {inject, injectable} from "inversify";
import {action, computed, configure, makeObservable, observable} from "mobx";
import {FileMessageEntity} from "@dropDesk/domain/entities/common/file_message";
import {
    base64ToBuffer,
    fileToBase64,
    dataBase64UrlToBlob,
    isFileImage,
    transformBytesToMegaBytes
} from "@dropDesk/utils/helpers/files";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {CreateObjectUrlBlobUseCase} from "@dropDesk/domain/use_case/io/create_object_url_blob.usecase";
import {FileEditionEntity, ImageEditorEntity} from "@dropDesk/domain/entities/image_editor/image_editor.entity";
import {ReferenceImageEdited} from "@dropDesk/domain/entities/image_editor/image_editor_enum.entity";
import {MutableRefObject} from "react";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";

configure({
    enforceActions: "always",
});


@injectable()
export class ImageEditorController {

    private readonly _createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase;

    constructor(
        @inject(CreateObjectUrlBlobUseCase) createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase,
    ) {
        makeObservable(this);
        this._createObjectURLBlobUseCase = createObjectURLBlobUseCase;
    }

    @observable
    cacheFilesEdition: Record<string, ImageEditorEntity> = {};

    @computed
    get filesEdition(): FileEditionEntity[] {
        return this.cacheFilesEdition[this.idTicket]?.filesEdition ?? [];
    }

    @computed
    get currentFileEdition(): FileEditionEntity | null {
        return this.cacheFilesEdition[this.idTicket]?.currentFileEdition ?? null;
    }

    @observable
    idTicket: string = '';

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    setIdTicket(idTicket: string) {
        this.idTicket = idTicket;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setCacheChat(
        filesEdition?: FileEditionEntity[],
        currentFileEdition?: FileEditionEntity | null
    ): void {
        const cache: ImageEditorEntity | undefined = this.cacheFilesEdition[this.idTicket];
        this.cacheFilesEdition[this.idTicket] =
            cache ?
                cache.copyWith({
                    filesEdition: filesEdition ?? cache.filesEdition,
                    currentFileEdition: currentFileEdition,
                    replaceCurrentFileEditionIfNull: currentFileEdition !== undefined
                }) : new ImageEditorEntity({
                    filesEdition: filesEdition ?? [],
                    currentFileEdition: currentFileEdition,
                });
    }

    @action
    deleteCacheCurrentTicket() {
        delete this.cacheFilesEdition[this.idTicket];
    }

    @action
    deleteAllCache() {
        this.cacheFilesEdition = {};
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    setFiles(filesEdition: FileEditionEntity[]) {
        this.setCacheChat(filesEdition)
    }

    @action
    clearFiles() {
        this.deleteCacheCurrentTicket();
        this.setFiles([]);
        this.setCurrentFileEdition(null);
    }

    @action
    setLoadableDesignState(reference: MutableRefObject<ReferenceImageEdited | undefined>) {
        if (this.currentFileEdition && isFileImage(this.currentFileEdition.fileMessage.file) && reference) {
            const newState = this.getLastImageEdited(reference);
            if (newState?.state && newState.base64Url) {
                this.currentFileEdition.fileMessage.loadableDesignState = newState.state;
                this.currentFileEdition.fileMessage.base64Url = newState.base64Url;
            }

        }
    }

    getLastImageEdited = (reference: MutableRefObject<ReferenceImageEdited | undefined>): {
        state: any,
        base64Url: any
    } | undefined => {
        if (reference.current) {
            const result = reference.current?.call({
                imageFileInfo: {
                    quality: 1,
                }, pixelRatio: 4
            });
            return {state: result.designState, base64Url: result?.imageData.imageBase64};
        }
    };


    @action
    setCurrentFileEdition(currentFileEdition: FileEditionEntity | null) {
        this.setCacheChat(undefined, currentFileEdition);
        if (currentFileEdition) {
            const index = this.getIndexByFileMessage(currentFileEdition);
            const files = this.filesEdition;
            files[index] = currentFileEdition;
            this.setFiles(files);
        }
    }

    @action
    isCurrentFile(fileEdition: FileEditionEntity): boolean {
        return fileEdition.fileMessage.id === this.currentFileEdition?.fileMessage.id;
    }

    @action
    getNameFileSelected = (): string => {
        return this.currentFileEdition?.fileMessage.file.name ?? 'Nome indisponível';
    }

    @action
    getIndexByFileMessage = (fileEdition: FileEditionEntity | null): number => {
        if (!fileEdition) {
            return 0;
        }
        return this.filesEdition.findIndex((_fileEdition) => _fileEdition.fileMessage.id == fileEdition.fileMessage.id);
    }


    @action
    async save(): Promise<FileEditionEntity[]> {
        const filesInEdition = this.filesEdition;
        const files = [];
        for (let i = 0; i < filesInEdition.length; i++) {
            if (isFileImage(filesInEdition[i].fileMessage.file)) {

                const base64Url = filesInEdition[i].fileMessage.base64Url;
                if (base64Url) {
                    const mimeType = 'image/png';
                    const blob = dataBase64UrlToBlob(base64Url, mimeType);
                    const file = new File([blob], filesInEdition[i].fileMessage.file.name, {type: mimeType});
                    filesInEdition[i].fileMessage.file = file;
                    filesInEdition[i].fileMessage.blobURL = this._createObjectURLBlobUseCase.call(file);

                }

            }

            files.push(filesInEdition[i]);
        }
        this.clearFiles();
        this.stopLoading();
        return files;
    }

    @action
    handleRemoveFile = (fileEdition: FileEditionEntity): void => {

        let indexMessage = this.getIndexByFileMessage(fileEdition);
        let _files = [...this.filesEdition];
        _files.splice(indexMessage, 1);
        this.setFiles(_files);

        let nextIndex: number;

        if (this.isCurrentFile(fileEdition)) {
            nextIndex = indexMessage === 0 ? 0 : --indexMessage;
        } else {
            nextIndex = this.getIndexByFileMessage(this.currentFileEdition);
        }

        this.setCurrentFileEdition(this.filesEdition[nextIndex]);
    }

    getSumFilesAttached(files: File[]): number {
        const sizeFilesAttached = files.map((entry) => entry.size).concat(this.filesEdition.map((entry) => entry.fileMessage.file.size));
        const sum = sizeFilesAttached.reduce(function (previous, next) {
            return previous + next;
        }, 0);
        return sum;
    }

    @action
    handlePickFile = async (files: File[]): Promise<void> => {

        try {
            if (files.length > 0) {
                const sumFiles = this.getSumFilesAttached(files);
                const totalFileSize = transformBytesToMegaBytes(sumFiles);

                if (totalFileSize > ConstantsKeys.limitUploadFile) {
                    throw new Error("Error ao Anexar, O total dos arquivos ultrapassa o limite de 16MB e total de 20 arquivos.");
                }

                let filesMessages: FileMessageEntity[] = [];
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const base64 = await fileToBase64(file);
                    const blobURL = this._createObjectURLBlobUseCase.call(file);
                    const fileMessage = new FileMessageEntity({
                        id: generateUUIDV4(),
                        file,
                        description: '',
                        blobURL,
                        isPrivate: false,
                        base64Url: base64,
                    });
                    filesMessages.push(fileMessage);
                }

                const filesEditions = filesMessages.map((fileMessage) => new FileEditionEntity({
                    fileMessage,
                }));

                if (this.filesEdition.length === 0) {
                    this.setCurrentFileEdition(filesEditions[0]);
                }

                this.setFiles(this.filesEdition.concat(filesEditions));

            } else {
                throw new Error("Error ao Anexar, O total dos arquivos ultrapassa o limite de 16MB e total de 20 arquivos.");
            }
        } catch (e: any) {
            toastMessage.error("Error ao Anexar, O total dos arquivos ultrapassa o limite de 16MB e total de 20 arquivos.");
        }

    };

}
