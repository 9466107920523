import React from 'react';
import {
    ButtonDownload,
    Container,
    ContainerDescriptions,
    ContainerDone,
    ContainerNewExport,
    ContainerTitle,
    Content,
    ContentForm,
    ContentInformation,
    Description,
    DescriptionList,
    TextDownload,
    TextExport,
    Title,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";
import {useNavigate} from "react-router-dom";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import LoadingExport from "@dropDesk/presentation/components/export_data_to_excel/loading_export_client";
import {convertDateToFormatBR, displayDateToLocale, displayDateToLocaleUTC} from "@dropDesk/utils/helpers/date_helper";
import {downloadFile} from "@dropDesk/utils/helpers/files";
import {CommonController} from "@dropDesk/presentation/pages/common/common.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const ExportDataToExcel = (observer((
    {
        tableName,
        period,
        loading,
        loadingMessage,
        initExport,
        exportation
    }: {
        tableName: string
        period?: { startDate: string, endDate: string }
        loading: boolean
        loadingMessage?: string | null
        initExport: () => void
        exportation: ExportDataEntity,
    }
) => {

    const appController = useInjection(AppController);
    const commonController = useInjection(CommonController);
    const colors = appController.theme.colorScheme;
    const navigate = useNavigate();

    const convertFileToBlobAndDownload = async () => {
        const exportUrl = await commonController.decrypt(exportation.url ?? '');
        const fileName = `${tableName}_exportacao_${convertDateToFormatBR(new Date())}.xlsx`;
        const blob = await fetch(exportUrl).then(response => response.blob());
        const url = URL.createObjectURL(blob);
        downloadFile(url, fileName);
    }

    return (
        <Container background={colors.onBackground} border={colors.border}>
            <Content>
                <ContentForm background={colors.onBackground} border={colors.border}>
                    <HeaderForm
                        letter={`Exportar ${tableName}`}
                        onRequestClose={() => navigate(-1)}
                        disabled={false}
                    />
                    <ContentInformation>
                        {exportation.isInit &&
                            <>
                                <DropDeskIcon
                                    icon={ICONS_DROPDESK.excel}
                                    size={100}
                                    color={colors.hintInverse}

                                />
                                <TextExport color={colors.text}>
                                    Deseja realmente exportar todos os dados
                                    de {tableName} {period ? `no período de ${period.startDate} até ${period.endDate}` : ''} para
                                    arquivo Excel?
                                </TextExport>
                            </>
                        }

                        {exportation.inProgress &&
                            <LoadingExport progress={exportation.progress}/>
                        }

                        {exportation.done &&
                            <ContainerDone>

                                <ContainerTitle>
                                    <Title color={colors.success}>Exportação feita com sucesso!</Title>
                                </ContainerTitle>

                                <ContainerDescriptions>

                                    <Description color={colors.text}>
                                        A exportação de {tableName} feita&nbsp;
                                        {exportation.period ? 'no período de ' : 'em '} {exportation.period ?? displayDateToLocaleUTC(exportation.createdAt ?? '')} foi
                                        concluída com sucesso, para baixar o arquivo
                                        excel clique no botão download.
                                    </Description>

                                    <ButtonDownload
                                        onClick={() => convertFileToBlobAndDownload()}
                                        background={colors.accent}
                                        border={colors.border}
                                    >
                                        <DropDeskIcon
                                            style={{padding: 5}}
                                            icon={ICONS_DROPDESK.excel}
                                            color={SharedColors.white}
                                        />
                                        <TextDownload color={SharedColors.white}>Download</TextDownload>
                                        <DropDeskIcon
                                            style={{padding: 5}}
                                            icon={ICONS_DROPDESK.download}
                                            color={SharedColors.white}
                                        />
                                    </ButtonDownload>

                                    <ContainerNewExport>
                                        <DescriptionList color={colors.text}> ao realizar uma nova
                                            exportação, este arquivo será
                                            apagado.</DescriptionList>
                                        {!exportation.hasCache &&
                                            <DescriptionList color={colors.text}> Para realizar uma nova
                                                exportação, clique em
                                                Exportar. </DescriptionList>}

                                        {exportation.hasCache && <DescriptionList color={colors.text}>
                                            essa exportação possuí um cache de 12 horas, só poderá refazer a
                                            exportação após 12 horas, última exportação feita
                                            em {displayDateToLocale(exportation.createdAt ?? '')}.
                                        </DescriptionList>}
                                    </ContainerNewExport>
                                </ContainerDescriptions>

                            </ContainerDone>
                        }


                    </ContentInformation>

                    <FooterForm
                        disabled={loading || exportation.status === StatusImport.inProgress || exportation.hasCache}
                        loading={loading}
                        type={"submit"}
                        letter={"Exportar"}
                        onSubmit={() => initExport()}
                        onRequestClose={() => navigate(-1)}
                        useButtonDownload={!!exportation.url}
                        onRequestDownload={() => convertFileToBlobAndDownload()}

                    />

                    {(loading && loadingMessage) && <DropDeskLoading
                        height={250}
                        description={loadingMessage}
                    />}
                </ContentForm>
            </Content>
        </Container>
    )

}));
export default ExportDataToExcel;
