import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export type IconType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${SharedColors.info};
  width: 100%;
  padding: 6px 16px;
`;

export const CotentText = styled.div`
  padding: 3px 0;
`;

export const Text = styled.span`
  font-size: 15px;
  color: ${SharedColors.black};
  font-weight: normal;
`;

export const TextBold = styled.span`
  font-size: 15px;
  color: ${SharedColors.black};
  font-weight: bold;
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;
