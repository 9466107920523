import {inject} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {CreateObjectUrlBlobUseCase} from "@dropDesk/domain/use_case/io/create_object_url_blob.usecase";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import {
    ListTicketEvaluationUseCase
} from "@dropDesk/domain/use_case/ticket/evaluation/list_ticket_evaluation_pagination.usecase";
import {SetTicketEvaluationUseCase} from "@dropDesk/domain/use_case/ticket/evaluation/set_ticket_evaluation.usecase";
import {
    FindByPKTicketEvaluationUseCase
} from "@dropDesk/domain/use_case/ticket/evaluation/findbypk_ticket_evaluation.usecase";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {
    getIdCompanyByLocalStorage,
    initializeConfigurationLocalStorage,
    preventMissingPage, setGenericFiltersLocalStorage,
    setSearchParamLocalStorage
} from "@dropDesk/utils/helpers/common";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";


const table = new ListPaginationEntity<TicketEvaluationEntity>({
    pages: 0,
    page: 0,
    limit: 10,
    totalRows: 0,
    data: observable.array([])
});
configure({
    enforceActions: "always",
});

@injectable()
export class TicketEvaluationController {

    private readonly _listTicketEvaluationUseCase: ListTicketEvaluationUseCase;
    private readonly _setTicketEvaluationUseCase: SetTicketEvaluationUseCase;
    private readonly _findByPKTicketEvaluationUseCase: FindByPKTicketEvaluationUseCase;

    constructor(
        @inject(ListTicketEvaluationUseCase) listTicketEvaluationUseCase: ListTicketEvaluationUseCase,
        @inject(SetTicketEvaluationUseCase) setTicketEvaluationUseCase: SetTicketEvaluationUseCase,
        @inject(FindByPKTicketEvaluationUseCase) findByPKTicketEvaluationUseCase: FindByPKTicketEvaluationUseCase,
        @inject(CreateObjectUrlBlobUseCase) createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase,
    ) {
        makeObservable(this);
        this._listTicketEvaluationUseCase = listTicketEvaluationUseCase;
        this._setTicketEvaluationUseCase = setTicketEvaluationUseCase;
        this._findByPKTicketEvaluationUseCase = findByPKTicketEvaluationUseCase;
    }

    @observable
    searchEvaluationValue: number | null = null;

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    tableTicketEvaluation: ListPaginationEntity<TicketEvaluationEntity> = table;

    @observable
    pagesData: Record<number, TicketEvaluationEntity[]> = {};

    @observable
    ticketEvaluation?: TicketEvaluationEntity;

    @observable
    searchParam = '';

    @observable
    ticketEvaluationNotFound: boolean = false;


    @action
    setSearchEvaluationValue(value: number | null) {
        this.searchEvaluationValue = value;
        setGenericFiltersLocalStorage({ticketEvaluationFilterValue: value});
        this.resetTable();
        this.list(this.searchParam);
    }


    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }


    @action
    setTicketEvaluation(ticketEvaluation: TicketEvaluationEntity) {
        this.ticketEvaluation = ticketEvaluation;
    }

    @action
    resetTable() {
        this.pagesData = {};
        this.setTableTicketEvaluation(table);
    }


    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableTicketEvaluation(this.tableTicketEvaluation.copyWith({
                ...this.tableTicketEvaluation,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableTicketEvaluation(this.tableTicketEvaluation.copyWith({
                ...this.tableTicketEvaluation,
                page: page,
            }))
            return this.list(this.searchParam);
        }
    }


    @action
    setTicketEvaluationNotFound(value: boolean) {
        this.ticketEvaluationNotFound = value;
    }

    @action
    initialize(heightShowingHeaderInfo: number) {
        const routeName = RoutesEnum.reportrate;
        const {lastPage, initSearchParam, filters} = initializeConfigurationLocalStorage(routeName);
        this.tableTicketEvaluation.limit = Math.floor((window.innerHeight - 300 - heightShowingHeaderInfo) / 57);
        this.tableTicketEvaluation.page = lastPage;
        this.searchEvaluationValue = filters.ticketEvaluationFilterValue!;
        this.searchParam = initSearchParam;
        this.list(initSearchParam);
    }

    @action
    set = async (onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("evaluation.loadingSave");
            if (this.ticketEvaluation?.isUnsaved()) {
                this.setTicketEvaluation(this.ticketEvaluation!.copyWith({
                    id: generateUUIDV4()
                }))
            }
            await this._setTicketEvaluationUseCase.call(this.ticketEvaluation!);
            onSuccess("evaluation.success.save");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getEvaluation = async (id: string) => {
        try {
            this.startLoading("evaluation.loadingInit")
            const response = await this._findByPKTicketEvaluationUseCase.call(id);
            this.setTicketEvaluation(response);
            this.stopLoading()
        } catch (err: any) {
            this.setTicketEvaluationNotFound(true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    makeEvaluation = (ticket: TicketEntity) => {
        this.setTicketEvaluation(new TicketEvaluationEntity({
            id: generateUUIDV4(),
            idCompany: getIdCompanyByLocalStorage(),
            idTicket: ticket.id,
            ticket
        }));
    }

    @action
    setTableTicketEvaluation(value: ListPaginationEntity<TicketEvaluationEntity>) {
        this.tableTicketEvaluation = value;
        const isNecessaryReload = preventMissingPage(this.tableTicketEvaluation);
        if (isNecessaryReload) {
            this.list('');
        } else {
            this.pagesData[this.tableTicketEvaluation.page] = this.tableTicketEvaluation.data;
        }
    }


    @action
    list = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listTicketEvaluationUseCase.call(this.tableTicketEvaluation.page, this.searchParam, this.tableTicketEvaluation.limit, this.searchEvaluationValue);
            this.setTableTicketEvaluation(response)
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };
}
