import "reflect-metadata";
import {inject, injectable} from "inversify";
import {IoRepository} from "@dropDesk/domain/repositories/io/io.repository";
import {AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";

@injectable()
export class GetAudioFromMediaRecorderUseCase {
  private _repository: IoRepository;

  constructor(@inject(IoRepository) repository: IoRepository) {
    this._repository = repository;
  }

  public call(audioRecorder: AudioRecordEntity): Promise<AudioRecordEntity> {
    return this._repository.getAudioFromMediaRecorder(audioRecorder);
  }
}
