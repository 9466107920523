import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    opacity: number;
    background: string;
}

export const FullScreenOverlayStyled = styled.div<ContainerType>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1020;
  background: ${props => hexToRGBA(props.background, props.opacity)};
  cursor: auto;
`;
