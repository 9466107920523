import {observer} from "mobx-react";
import React from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {useValidatorInputUsers} from "@dropDesk/presentation/pages/user/ui/form/inputs/validator";
import {useNavigate} from "react-router-dom";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";


const FooterFormUsers = observer(
    ({
         user,
         handleSubmit,
         loading,
         createdAt,
         isUserClient
     }: {
        user?: UserEntity,
        handleSubmit: () => void,
        loading: boolean,
        isUserClient: boolean,
        createdAt?: string,
    }) => {

        const navigate = useNavigate();

        const {
            isValidDataForm
        } = useValidatorInputUsers(user);


        return (
            <FooterForm
                disabled={loading || !isValidDataForm}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={() => handleSubmit()}
                onRequestClose={() => navigate(-1)}
                createdAt={createdAt}
                hideBackButton={isUserClient}
            />
        )
    })
export default FooterFormUsers;
