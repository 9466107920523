import {useEffect, useState} from "react";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {validateContacts} from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_contacts/validate_user";

export const useValidatorInputContacts = (
    user: UserEntity,
    isNewChatContact?: boolean
) => {

    const [errorMessageName, setErrorMessageName] = useState('');
    const [errorMessageEmail, setErrorMessageEmail] = useState('');
    const [errorMessageTelephone, setErrorMessageTelephone] = useState('');
    const [isValidDataFormContacts, setIsValidDataFormContacts] = useState<boolean>(false);


    useEffect(() => {
        validate();
    }, [user]);

    const validate = () => {
        validateContacts(user, setErrorMessageName, setErrorMessageEmail, setErrorMessageTelephone, setIsValidDataFormContacts, isNewChatContact);
    }

    return {
        errorMessageName, setErrorMessageName,
        errorMessageEmail, setErrorMessageEmail,
        errorMessageTelephone, isValidDataFormContacts,
        validate
    }
};
