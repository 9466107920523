import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


export type TextType = {
    color: string;
    size: number;
}

type ContainerType = {
    background: string;
    border: string;
    isDarkTheme: boolean;
};

export const Container = styled.div<ContainerType>`
  display: flex;
  width: 100%;
  max-width: 768px;
  height: min-content;
  flex-direction: column;
  background: ${props => props.background};
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${props => props.border};

  @media (max-width: 768px) {
    flex-direction: column;
    position: relative;
  }
`;

