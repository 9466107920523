import styled from "styled-components";
import {Checkbox} from "antd";
import {
    CheckBoxStyledType
} from "@dropDesk/presentation/components/check_box/check_box_with_label/check_box_with_label";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


export const CheckBoxStyled = styled(Checkbox)<CheckBoxStyledType>`

  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${(props) => props.background};
    border-color: ${(props) => props.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${(props) => props.color};
  }

  .ant-checkbox + span {
    padding-right: 0;
  }

  .ant-checkbox-checked::after {
    border-color: ${(props) => props.color};
  }


  &.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.border};
  }

  .ant-checkbox-inner {
    border-color: ${props => props.border};
    background: ${(props) => props.background};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background: ${(props) => props.background};
  }

`;
