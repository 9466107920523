import {useState} from "react";

export const useModalSectorsLinked = () => {

  const [visibleModalSearchSector, setVisibleModalSearchSector] = useState<boolean>(false);
  const [visibleModalUnlinked, setVisibleModalUnlinked] = useState<boolean>(false);
  const [visibleModalCreateSector, setVisibleModalCreateSector] = useState<boolean>(false);

  return {
    visibleModalSearchSector,
    setVisibleModalSearchSector,
    visibleModalUnlinked,
    setVisibleModalUnlinked,
    visibleModalCreateSector,
    setVisibleModalCreateSector
  }
}
