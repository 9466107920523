import "reflect-metadata";
import {inject, injectable} from "inversify";
import {NoticesRepository} from "@dropDesk/domain/repositories/notices/notices.repository";
import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";

@injectable()
export class FindByPKNoticesUseCase {
  private _repository: NoticesRepository;

  constructor(@inject(NoticesRepository) repository: NoticesRepository) {
    this._repository = repository;
  }

  public call(id: string): Promise<NoticesEntity> {
    return this._repository.findByPK(id);
  }
}

