import {observer} from "mobx-react";
import React from "react";
import {
    ContentData, ContentInput, ContentInputsAndImage, ContentValueAndDate, SubContentInputs,
    ContentImage, Spacer
} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import ImageProfile from "@dropDesk/presentation/components/drop_zone/pick_image_profile";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const InputsForm = observer(
    ({
         ticketEvaluation
     }: {
        ticketEvaluation?: TicketEvaluationEntity
    }) => {

        const appController = useInjection(AppController);

        return (
            <SubContentInputs>
                <ContentData autoComplete='off'>
                    <ContentInputsAndImage>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <Spacer>
                                <InputWithBorder
                                    name='name'
                                    size={35}
                                    readOnly={true}
                                    placeHolder={"Nome da Empresa"}
                                    label={"Nome da Empresa"}
                                    value={ticketEvaluation?.ticket?.userClient?.name ?? ''}
                                    onChange={() => {
                                    }}
                                />
                            </Spacer>
                            <Spacer>
                                <InputWithBorder
                                    name='socialName'
                                    size={35}
                                    readOnly={true}
                                    placeHolder={"Nome Fantasia"}
                                    label={"Nome Fantasia"}
                                    value={ticketEvaluation?.ticket?.client?.socialName ?? ''}
                                    onChange={() => {
                                    }}
                                />
                            </Spacer>
                            <Spacer>
                                <InputWithBorder
                                    name='attendant'
                                    size={35}
                                    readOnly={true}
                                    placeHolder={"Nome do Atendente"}
                                    label={"Nome do Atendente"}
                                    value={ticketEvaluation?.ticket?.attendant?.name ?? ''}
                                    onChange={() => {
                                    }}
                                />
                            </Spacer>
                        </div>
                        <ContentImage>
                            <ImageProfile
                                useGarbage={false}
                                radiusImage={false}
                                sizeImage={145}
                                onPick={() => {
                                }}
                                disabled={true}
                                name={''}
                                urlImageProfile={ticketEvaluation?.ticket?.userClient?.urlImageProfile ?? ''}
                                onRemoveProfileImage={() => {

                                }}
                                marginTopButtonChangeImage={45}
                            />
                        </ContentImage>
                    </ContentInputsAndImage>

                    <ContentValueAndDate>
                        <ContentInput>
                            <InputWithBorder
                                name='value'
                                size={35}
                                readOnly={true}
                                placeHolder={"Valor da avaliação"}
                                label={"Valor da avaliação"}
                                value={ticketEvaluation?.value?.toString() ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                        <ContentInput style={{marginLeft: 10}}>
                            <InputWithBorder
                                name='descriptionEvaluation'
                                size={35}
                                readOnly={true}
                                placeHolder={"Comentário da Avaliação"}
                                label={"Comentário da Avaliação"}
                                value={ticketEvaluation?.description ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContentValueAndDate>
                    <ContentValueAndDate>
                        <ContentInput>
                            <InputWithBorder
                                name='problem'
                                size={35}
                                readOnly={true}
                                placeHolder={"Descrição Problema"}
                                label={"Descrição Problema"}
                                value={ticketEvaluation?.ticket?.realDescription(appController.user?.company.configurations.ticket.useProblemStatic ?? false) ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                        <ContentInput style={{marginLeft: 10}}>
                            <InputWithBorder
                                name='solution'
                                size={35}
                                readOnly={true}
                                placeHolder={"Descrição Solução"}
                                label={"Descrição Solução"}
                                value={ticketEvaluation?.ticket?.realDescriptionClosed ?? ''}
                                onChange={() => {
                                }}
                            />
                        </ContentInput>
                    </ContentValueAndDate>

                </ContentData>

            </SubContentInputs>
        )
    })
export default InputsForm;
