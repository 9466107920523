import styled, {css} from "styled-components";
import {ButtonNewTypeContainer, ContentIconType} from "@dropDesk/presentation/components/buttons/button_new/button_new";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

const hoverAndFocusStyles = (props: ButtonNewTypeContainer) => css`
  border-color: transparent;
  box-shadow: 0 0 0 2px ${hexToRGBA(props.accent, .58)};
  outline: none;
  transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
`;

export const ButtonAddNew = styled.button<ButtonNewTypeContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: ${props => props.marginLeft}px;
  height: ${props => props.size}px;
  min-height: ${props => props.size}px;
  max-height: ${props => props.size}px;
  width: ${props => props.widthButton ? `${props.widthButton}px` : 'auto'};
  min-width: ${props => props.widthButton ? `${props.widthButton}px` : 'auto'};
  max-width: ${props => props.widthButton ? `${props.widthButton}px` : 'auto'};
  border-radius: ${props => props.realRadius}px;
  padding: 10px;
  border: ${props => props.isButtonMain ? 0 : `1px solid ${props.border}`};
  background: ${props => props.background};
  text-decoration: none;
  z-index: ${props => props.zIndex ?? 'auto'};
  //cursor: pointer;

  &:hover {
    ${hoverAndFocusStyles}
  }

  ${props => props.focusBorder && hoverAndFocusStyles}
}
`;

export const ContentIcon = styled.div<ContentIconType>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
  margin-right: 5px;
`;
export const ContentText = styled.div<ContentIconType>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  text-decoration: none;
`;
