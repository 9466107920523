import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ResponseStandardRepository} from "@dropDesk/domain/repositories/response_standard/response_standard.repository";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";

@injectable()
export class RestoreResponseStandardUseCase {
  private _repository: ResponseStandardRepository;

  constructor(@inject(ResponseStandardRepository) repository: ResponseStandardRepository) {
    this._repository = repository;
  }

  public call(responseStandard: ResponseStandardEntity[]): Promise<void> {
    return this._repository.restore(responseStandard);
  }
}
