import {useState} from "react";

export const useModalCreateTicket = () => {

  const [visibleModalSearchClient, setVisibleModalSearchClient] = useState<boolean>(false);
  const [visibleModalSearchContacts, setVisibleModalSearchContacts] = useState<boolean>(false);
  const [visibleModalSearchUser, setVisibleModalSearchUser] = useState<boolean>(false);
  const [visibleModalSearchSector, setVisibleModalSearchSector] = useState<boolean>(false);
  const [visibleModalTicketDescriptionStatic, setVisibleModalTicketDescriptionStatic] = useState<boolean>(false);
  const [visibleModalCreateClient, setVisibleModalCreateClient] = useState<boolean>(false);

  return {
    visibleModalSearchClient,setVisibleModalSearchClient,
    visibleModalSearchContacts,setVisibleModalSearchContacts,
    visibleModalSearchUser,setVisibleModalSearchUser,
    visibleModalSearchSector,setVisibleModalSearchSector,
    visibleModalTicketDescriptionStatic, setVisibleModalTicketDescriptionStatic,
    visibleModalCreateClient, setVisibleModalCreateClient
  }
}
