import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {OptionType} from "@dropDesk/presentation/components/inputs/select_search_main/select_search";

@injectable()
export abstract class SegmentsRemoteDataSource {
    public abstract getSegments(): Promise<Array<OptionType<string>>>;
}

@injectable()
export class SegmentsRemoteDataSourceImpl implements SegmentsRemoteDataSource {

    constructor() {
    }

    baseUrl: string = 'segments/';

    public async getSegments(): Promise<Array<OptionType<string>>> {
        return new Promise<Array<OptionType<string>>>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.baseUrl}`);
                const result: Array<OptionType<string>> = (response.data as Array<Record<string, string>>).map((entry) => {
                    return {
                        value: entry['description'],
                        label: entry['description'],
                    }
                });
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

}
