import {observer} from "mobx-react";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    BusinessOperationHoursConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {
    Body,
    Container, ContentTimeZone,
    ContentTitle, Text, TextBold
} from "@dropDesk/presentation/components/chat_view/panel_inform_ticket_waiting/panel_hours/styled";
import Hour from "@dropDesk/presentation/components/chat_view/panel_inform_ticket_waiting/panel_hours/hour";


const PanelHours = observer(
    ({
         businessOperation,
     }: {
        businessOperation: BusinessOperationHoursConfigurationEntity
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Container>
                <ContentTitle>
                    <TextBold color={colors.text}>Horário de funcionamento</TextBold>
                    <ContentTimeZone>
                        <Text color={colors.hint}>({businessOperation.timezoneToHuman()})</Text>
                    </ContentTimeZone>
                </ContentTitle>
                <Body>
                    {Object.keys(businessOperation.getBusinessOperationDays).map((nameOperationDay, index) => {
                        const businessOperationDay = businessOperation.getBusinessOperationDays[nameOperationDay];
                        return (
                            <Hour
                                key={nameOperationDay}
                                usesTwoOperationHours={businessOperation.usesTwoOperationHours}
                                businessOperationDayEntity={businessOperationDay}
                                day={businessOperation.dayNameToHuman(nameOperationDay)}
                            />
                        )
                    })}
                </Body>
            </Container>
        );
    });
export default PanelHours;
