import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";

const showToastRouteBlocked = (showToasBlocked = true) => {
    if (showToasBlocked) {
        toastMessage.error("Acesso não autorizado.");
    }
}

export const routeProtector = (currentUser: UserEntity | null, activeRoute: string, showToasBlocked = true): boolean => {

    if (!currentUser) {
        return true;
    }

    if ((activeRoute !== RoutesEnum.tickets && activeRoute !== RoutesEnum.profile) && currentUser.role === UserRole.userClient) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    if (activeRoute === RoutesEnum.profile && currentUser.role === UserRole.attendant) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    if (!currentUser.permissionScreenWarnings && activeRoute === RoutesEnum.notices) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    if (
        (!currentUser.permissionScreenReports && activeRoute === RoutesEnum.reportrate) ||
        (!currentUser.permissionScreenReports && activeRoute === RoutesEnum.reportticketsrate) ||
        (!currentUser.permissionScreenReports && activeRoute === RoutesEnum.reporttickets) ||
        (!currentUser.permissionScreenReports && activeRoute === RoutesEnum.reportticketsaggregate)
    ) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    if ((!currentUser.permissionScreenUsers && activeRoute === RoutesEnum.users) ||
        (!currentUser.permissionScreenUsers && activeRoute === RoutesEnum.exportusers)
    ) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    if ((!currentUser.permissionScreenClients && activeRoute === RoutesEnum.clients) ||
        (!currentUser.permissionScreenClients && activeRoute === RoutesEnum.importclients) ||
        (!currentUser.permissionScreenClients && activeRoute === RoutesEnum.exportclients) ||
        (!currentUser.permissionScreenClients && activeRoute === RoutesEnum.exportcontacts) ||
        (!currentUser.permissionScreenClients && activeRoute === RoutesEnum.contacts)
    ) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    if ((!currentUser.permissionScreenSectors && activeRoute === RoutesEnum.sectors) ||
        (!currentUser.permissionScreenSectors && activeRoute === RoutesEnum.exportsectors)) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    if (
        !currentUser.permissionAdmin && activeRoute === RoutesEnum.configuration
        || !currentUser.permissionAdmin && activeRoute === RoutesEnum.subscription
        || !currentUser.permissionAdmin && activeRoute === RoutesEnum.activeConnections
    ) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    if (currentUser.isUserClient && activeRoute === RoutesEnum.invoices) {
        showToastRouteBlocked(showToasBlocked);
        return false;
    }

    return true;

}
