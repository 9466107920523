import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type BorderType = {
    bordercolor: string;
    extraHeight: number;
}


export const BackgroundFullScreenBlackNotification = styled.div<BorderType>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 199;
  background-color: ${hexToRGBA(SharedColors.loading, 0.6)};
  cursor: auto;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${(props) => props.bordercolor};
    top: ${props => 52 + props.extraHeight}px;
    right: 97px;
    position: absolute;
    z-index: 199;
  }
`;
