import {observer} from "mobx-react";
import React from "react";
import {
    ContainerColorSquare,
    ContentButtonGarbage,
    ContentColorSquare,
    ContentExplicationColor, SquareColored, TextColored,
    ContainerFooterGarbage
} from "./styled";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const FooterGarbage = observer(
    ({
         setListOnlyDeleted,
         loading,
         visibleListOnlyDeleted,
         text,
         textDelete,
     }: {
        visibleListOnlyDeleted: boolean,
        loading: boolean,
        setListOnlyDeleted: (value: boolean) => void,
        text: string,
        textDelete: string,
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <ContainerFooterGarbage>
                <ContentExplicationColor>
                    <ContentButtonGarbage>
                        {!visibleListOnlyDeleted && <ButtonNew
                            marginLeft={0}
                            label={'Ver Excluídos'}
                            onClick={() => {
                                setListOnlyDeleted(true);
                            }}
                            nameLeftIcon={ICONS_DROPDESK.delete}
                            sizeLeftIcon={19}
                            disabled={loading}
                        />}
                        {visibleListOnlyDeleted && <ButtonNew
                            isDeleted={true}
                            marginLeft={0}
                            label={'Sair Excluídos'}
                            onClick={() => {
                                setListOnlyDeleted(false);
                            }}
                            nameLeftIcon={ICONS_DROPDESK.restore}
                            sizeLeftIcon={19}
                            disabled={loading}
                        />}
                    </ContentButtonGarbage>
                    <ContainerColorSquare>
                        <ContentColorSquare>
                            <SquareColored color={colors.text}></SquareColored>
                            <TextColored color={colors.text}>{text}</TextColored>
                        </ContentColorSquare>
                        <ContentColorSquare>
                            <SquareColored color={colors.textError}></SquareColored>
                            <TextColored color={colors.textError}>{textDelete}</TextColored>
                        </ContentColorSquare>
                    </ContainerColorSquare>
                </ContentExplicationColor>
            </ContainerFooterGarbage>
        )
    })
export default FooterGarbage;
