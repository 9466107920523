import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";

@injectable()
export class ChangeProblemDescriptionTicketUseCase {
    private _repository: TicketRepository;

    constructor(@inject(TicketRepository) repository: TicketRepository) {
        this._repository = repository;
    }

    public call(idTicket: string, description: string): Promise<TicketEntity> {
        return this._repository.changeProblemDescription(idTicket, description);
    }
}

