import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";
import {TicketConfig} from "@dropDesk/domain/entities/ticket/ticket_config";

@injectable()
export class GetTicketsGlobalConfigsUseCase {
  private _repository: TicketRepository;

  constructor(@inject(TicketRepository) repository: TicketRepository) {
    this._repository = repository;
  }

  public call(): Promise<TicketConfig[]> {
    return this._repository.getConfigs();
  }
}

