import {Container, ContainerButtonHeader, Text} from "./styled";
import {HeaderProps} from "@dropDesk/presentation/components/headers/header_modal/header_modal";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskIcon from "@dropDesk/storage/icons";


export default function HeaderModal(props: HeaderProps) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container background={props.background ?? colors.onBackground}>
            <Text color={props.letterColor ?? colors.text}>{props.letter}</Text>

            <ContainerButtonHeader
                accent={colors.accent}
                background={colors.buttonOnBackground}
                onClick={props.disabled ? () => {
                } : props.onClick}
            >
                <DropDeskIcon
                    style={{cursor: props.disabled ? 'no-drop' : 'pointer'}}
                    color={props.letterColor ?? colors.text}

                    icon={props.nameIcon}
                    size={20}
                    useDarkenInHover={true}
                />
            </ContainerButtonHeader>
        </Container>
    );
}
