export const removeDiacritics = (input: string): string => {
    if (!input) return '';

    const diacritics = [
        {char: 'A', base: /[\300-\306]/g},
        {char: 'a', base: /[\340-\346]/g},
        {char: 'E', base: /[\310-\313]/g},
        {char: 'e', base: /[\350-\353]/g},
        {char: 'I', base: /[\314-\317]/g},
        {char: 'i', base: /[\354-\357]/g},
        {char: 'O', base: /[\322-\330]/g},
        {char: 'o', base: /[\362-\370]/g},
        {char: 'U', base: /[\331-\334]/g},
        {char: 'u', base: /[\371-\374]/g},
        {char: 'N', base: /[\321]/g},
        {char: 'n', base: /[\361]/g},
        {char: 'C', base: /[\307]/g},
        {char: 'c', base: /[\347]/g}
    ]

    diacritics.forEach(function (letter) {
        input = input.replace(letter.base, letter.char);
    });

    return input;
};

export const removeSpecialChars = (input: string, removeSpaces = false): string => {
    const result = input.replace(new RegExp(/[^\w\s]/gi), '');
    if (removeSpaces) return result.replace(/\s/g, '');
    return result;
}

export const removeSpaces = (input: string): string => {
    return input.replace(/\s/g, '');
}

export const removeLineBreaks = (input: string): string => {
    return input.replace(/[\r\n]+/g, '');
}

export const getInitials = (fullName?: string): string => {
    if (!fullName) {
        return '?';
    }
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
        const firstLetter = curr.charAt(0).toUpperCase();
        if (index === 0 || index === allNames.length - 1) {
            if (/^[A-Z]$/i.test(firstLetter)) { // Verifica se é uma letra do alfabeto
                acc = `${acc}${firstLetter}`;
            }
        }
        return acc;
    }, '');
    return !!initials ? initials : '?';
}


export const removeMask = (value: string): string => {
    if (value) return value.replace(/[^\d]+/g, '');
    return '';
}

export const removeLetters = (value: string): string => {
    if (value) return value.replace(/[^0-9]+/g, '');
    return '';
}

export const removeUnderscore = (value: string): string => {
    if (value) return value.replace(/_/g, "");
    return '';
}


export const removeMaskStaticTelephoneNumber = (value: string): string => {
    if (value) {
        const telephoneNumber = value.replace(/[^\d]+/g, '');
        return telephoneNumber === '55' ? '' : telephoneNumber;
    }
    return '';
}

export const applyUnderscoreMask = (mask: string, value: string): string => {
    const myMask = mask;
    const myText = value;
    const myNumbers = [];
    let myOutPut = "";
    let theLastPos = 1;
    //get numbers
    for (let i = 0; i < myText.length; i++) {
        if (!isNaN(parseInt(myText.charAt(i))) && myText.charAt(i) != " ") {
            myNumbers.push(myText.charAt(i));
        }
    }
    //write over mask
    for (let j = 0; j < myMask.length; j++) {
        if (myMask.charAt(j) == "_") { //replace "_" by a number
            if (myNumbers.length == 0) myOutPut = myOutPut + myMask.charAt(j);
            else {
                myOutPut = myOutPut + myNumbers.shift();
                theLastPos = j + 1; //set caret position
            }
        } else {
            myOutPut = myOutPut + myMask.charAt(j);
        }
    }
    return myOutPut;
}

export const converterMinutesToTemplateString = (minutes: number): string => {
    const days = Math.floor(minutes / 1440); // 1 dia = 1440 minutos
    const hours = Math.floor((minutes % 1440) / 60);
    const minutesLeft = minutes % 60;

    let result = '';

    if (days > 0) {
        result += `${days} dia${days > 1 ? 's' : ''} `;
    }

    if (hours > 0) {
        result += `${hours} hora${hours > 1 ? 's' : ''} `;
    }

    if (minutesLeft > 0) {
        result += `${minutesLeft} minuto${minutesLeft > 1 ? 's' : ''}`;
    }

    return result.trim();
}
