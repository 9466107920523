import styled from "styled-components";

type TextType = {
    color: string;
}
export const ContentNoData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const ContentIconNoData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ContentTitleNoData = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextNoData = styled.span<TextType>`
  font-size: 15px;
  color: ${(props) => props.color};
  margin-top: 10px;
`;
export const TitleNoData = styled.span<TextType>`
  font-size: 14px;
  color: ${(props) => props.color};
  margin-top: 7px;
`;
