import {filtersEnum} from "@dropDesk/domain/entities/reports/report_ticket/report_ticket_enum";

type Map = {
  value: string,
  label: string
}
export const filterMap: Map[] = [
  {value: filtersEnum.user, label: "Atendente"},
  {value: filtersEnum.client, label: "Empresa"},
  {value: filtersEnum.contacts, label: "Contatos"},
  {value: filtersEnum.sector, label: "Setor"},
]
