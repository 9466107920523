import {Area} from "react-easy-crop/types";
import {RotateEditorEntity} from "@dropDesk/domain/entities/image_editor/rotate_editor.entity";
import {useDebouncedCallback} from "use-debounce";


export const getURIfromCanvas = (canvas: HTMLCanvasElement): string => {
  return canvas.toDataURL(undefined, 0.8);
}

export const getFileFromCanvas = (canvas: HTMLCanvasElement, fileName: string): Promise<File> => {
  return new Promise<File>((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], `${fileName}`, {type: "image/png"});
          resolve(file);
        } else {
          reject(null);
        }
      }, 'image/png');
    } catch (e) {
      reject(e);
    }
  })
}
export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  flip = {horizontal: false, vertical: false}
) {
  if (!imageSrc || !pixelCrop) {
    return null;
  }
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const {width: bBoxWidth, height: bBoxHeight} = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )
  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);
  // As a blob
  const date = new Date();
  const file = await getFileFromCanvas(canvas, date.getTime().toString())
  return file;
}

export const getIndexAnchorPoints = (mouseX: number, mouseY: number, anchorSize: number, anchors: { x: number, y: number }[]): number | null => {
  let index = null;

  for (let i = 0; i < anchors.length; i++) {
    let distance = calculateDistance(mouseX, mouseY, anchors[i].x, anchors[i].y);
    if (distance < anchorSize) {
      index = i;
      break;
    }
  }
  return index;
}

const calculateDistance = (x1: number, y1: number, x2: number, y2: number) => {
  let xDiff = x2 - x1;
  let yDiff = y2 - y1;
  return Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2));
}

export const getRatioFromCanvas = (canvas: HTMLCanvasElement, image: HTMLImageElement, rotate: RotateEditorEntity): number => {
  const canvasRect = canvas.getBoundingClientRect();
  const {width} = getDimensionsFromRotate(rotate, image);
  const canvasRatio = width / canvasRect.width;
  return canvasRatio;
}

export const getMouseXMouseYFromCanvasRect = (canvas: HTMLCanvasElement, event: MouseEvent, image: HTMLImageElement, rotate: RotateEditorEntity): { mouseX: number, mouseY: number } => {

  const canvasRect = canvas.getBoundingClientRect();
  const mouseX = event.clientX - canvasRect.left;
  const mouseY = event.clientY - canvasRect.top;
  const canvasRatio = getRatioFromCanvas(canvas, image, rotate);

  return {mouseX: (mouseX * canvasRatio), mouseY: (mouseY * canvasRatio)};
}

export const getDimensionsFromRotate = (rotate: RotateEditorEntity, image: HTMLImageElement): { width: number, height: number } => {

  return (rotate.degrees === -90 || rotate.degrees === 90) ? {
    width: image.height, height: image.width
  } : {
    width: image.width, height: image.height
  };
}

export const isRotate = (rotate: RotateEditorEntity): boolean => {
  return rotate.degrees === 90 || rotate.degrees === -90;
}

export const resetTranslate = (context: CanvasRenderingContext2D) => {
  context.setTransform(1, 0, 0, 1, 0, 0);
}

