import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TaskRepository} from "@dropDesk/domain/repositories/task/task.repository";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {Orders, Status, Type} from "@dropDesk/domain/entities/task/task_enum";

@injectable()
export class ListTaskUseCase {
  private _repository: TaskRepository;

  constructor(@inject(TaskRepository) repository: TaskRepository) {
    this._repository = repository;
  }

  public call(
    type: Type, order: Orders, status: Status, page: number, limit: number
  ): Promise<ListPaginationEntity<TaskEntity>> {
    return this._repository.list(type, order, status, page, limit);
  }
}

