import {observer} from "mobx-react";
import {ContentCheckbox, ContentInputs, ContentInputsWithBorder} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {useValidatorCreateCompany} from "@dropDesk/presentation/pages/company/ui/form_company/inputs/validator";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import React from "react";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {OptionType} from "@dropDesk/presentation/components/inputs/select_search_main/select_search";
import ContractInput from "@dropDesk/presentation/components/inputs/input_contract";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";


const Inputs = observer(
    ({
         company,
         sector,
         user,
         onUpdate,
         onUpdateUser,
         onUpdateSector,
         loading,
         segments
     }: {
         company?: CompanyEntity,
         sector?: SectorEntity,
         user?: UserEntity,
         onUpdate: (company: CompanyEntity) => void,
         onUpdateUser: (user: UserEntity) => void,
         onUpdateSector: (company: SectorEntity) => void,
         loading: boolean,
         segments: Array<OptionType<string>>
     }
    ) => {
        const appController = useInjection(AppController);

        const {
            passwordVisible, setPasswordVisible,
            errorMessageCompanyName,
            errorMessageCompanySegment,
            errorMessageName,
            errorMessageEmail,
            errorMessagePassword,
            errorMessageNameSector,
            errorMessageDocument
        } = useValidatorCreateCompany(company, sector, user);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string };
        }) => {
            onUpdate(
                company!.copyWith({
                    [event.target.name]:
                        (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked :
                            event.target.name === "document" ? removeMask(event.target.value.replace(/\D/g, "")) :
                                event.target.value,
                })
            );
        };

        const handleAllChangesUser = (event: {
            target: { name: string; value: any; checked: any; type: string };
        }) => {
            onUpdateUser(
                user!.copyWith({
                    [event.target.name]: (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,
                    replaceEmailIfNull: event.target.name === "email",
                    replacePasswordIfNull: event.target.name === "password",
                })
            );
        };

        const handleAllChangesSector = (event: {
            target: { name: string; value: any; checked: any; type: string };
        }) => {
            onUpdateSector(
                sector!.copyWith({
                    [event.target.name]: (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,
                })
            );
        };

        return (
            <ContentInputs>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        autoFocus={true}
                        size={42}
                        marginTop={10}
                        nameIconLeft={ICONS_DROPDESK.business}
                        name='name'
                        disabled={loading}
                        placeHolder={"Nome Empresa"}
                        messageError={errorMessageCompanyName}
                        value={company?.name ?? ''}
                        onChange={handleAllChanges}
                    />
                </ContentInputsWithBorder>
                <InputWithBorder
                    mask={company && company?.document.length <= 11 ? 'cpf' : 'cnpj'}
                    autoFocus={false}
                    name='document'
                    size={42}
                    disabled={loading}
                    removeMaskOnCopy={true}
                    nameIconLeft={ICONS_DROPDESK.numbers}
                    messageError={errorMessageDocument}
                    placeHolder={'CNPJ / CPF'}
                    maxLength={18}
                    value={company?.document ?? ''}
                    onChange={handleAllChanges}
                />
                <ContentInputsWithBorder>
                    <SelectSearchMain<string>
                        search={true}
                        name='segment'
                        placeHolder={"Segmento"}
                        iconLeftName={ICONS_DROPDESK.segment}
                        messageError={errorMessageCompanySegment}
                        size={42}
                        options={segments}
                        onChange={(value) => {
                            handleAllChanges(
                                {
                                    target:
                                        {
                                            name: 'segment',
                                            value: value,
                                            checked: false,
                                            type: 'input'
                                        }
                                })
                        }}
                        value={company?.segment ?? null}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        size={42}
                        nameIconLeft={ICONS_DROPDESK.account}
                        name={'name'}
                        disabled={loading}
                        placeHolder={"Seu Nome"}
                        messageError={errorMessageName}
                        value={user?.name ?? ''}
                        onChange={handleAllChangesUser}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        size={42}
                        nameIconLeft={ICONS_DROPDESK.sitemap}
                        name={'name'}
                        disabled={loading}
                        placeHolder={"Seu Setor, Ex: Suporte, Financeiro..."}
                        messageError={errorMessageNameSector}
                        value={sector?.realName(appController.user!) ?? ''}
                        onChange={handleAllChangesSector}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        size={42}
                        nameIconLeft={ICONS_DROPDESK.atSign}
                        name={"email"}
                        disabled={loading}
                        placeHolder={"E-mail"}
                        messageError={errorMessageEmail}
                        value={user?.email ?? ''}
                        onChange={handleAllChangesUser}
                    />
                </ContentInputsWithBorder>
                <ContentInputsWithBorder>
                    <InputWithBorder
                        size={42}
                        nameIconLeft={ICONS_DROPDESK.padlock}
                        disabled={loading}
                        type={passwordVisible ? "text" : "password"}
                        placeHolder={"Senha"}
                        name={'password'}
                        nameIconRight={passwordVisible ? ICONS_DROPDESK.eye : ICONS_DROPDESK.eyeClosed}
                        messageError={errorMessagePassword}
                        value={user?.password ?? ''}
                        onChange={handleAllChangesUser}
                        onClickRightIcons={() => {
                            setPasswordVisible(!passwordVisible);
                        }}
                    />
                </ContentInputsWithBorder>
                <ContractInput
                    contract={appController.signUpContract}
                    acceptContract={company?.contract ?? false}
                    onClickAcceptContract={(value) => {
                        handleAllChanges(
                            {
                                target:
                                    {
                                        name: 'contract',
                                        value: '',
                                        checked: value,
                                        type: 'checkbox'
                                    }
                            })
                    }}
                />
            </ContentInputs>
        );
    });

export default Inputs;
