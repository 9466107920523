import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TaskRepository} from "@dropDesk/domain/repositories/task/task.repository";

@injectable()
export class DeleteTaskUseCase {
  private _repository: TaskRepository;

  constructor(@inject(TaskRepository) repository: TaskRepository) {
    this._repository = repository;
  }

  public call(id: string): Promise<void> {
    return this._repository.delete(id);
  }
}

