import {ChartReportTicketEntity} from "@dropDesk/domain/entities/reports/report_ticket/chart_ticket.entity";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";

export class LineChartReportTicketAggregate {

    x!: string;
    y!: number;

    static fromJson(json: LineChartReportTicketAggregate): LineChartReportTicketAggregate {
        return new LineChartReportTicketAggregate({
            x: json['x'] as string,
            y: json['y'] as number,
        })
    }

    toReportTicketEntity(): ChartReportTicketEntity {
        return new ChartReportTicketEntity({
            value: this.y,
            label: this.x,
            color: "",
            id: generateUUIDV4(),
            grouped: false,
            defaultValue: false,
            mainGrouped: false
        })
    }


    constructor({
                    x,
                    y,

                }: {
        x: string;
        y: number;
    }) {
        Object.assign(this, {
            x,
            y,
        });
    }
}
