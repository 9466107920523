import {ThemeEntity} from "@dropDesk/domain/entities/theme/theme.entity";
import {getLocalTheme} from "@dropDesk/utils/helpers/common";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";

export const getColors = () => {
    const theme: ThemeEntity = getLocalTheme();
    const isDarkTheme: boolean = theme.colorScheme.brightness === Brightness.dark;
    //const background = isDarkTheme ? theme.colorScheme.primary : theme.colorScheme.onPrimary;
    const background = theme.colorScheme.delicateBackground;
    return {
        theme,
        isDarkTheme,
        background
    }
}
