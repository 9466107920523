import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, Content, ContentDescriptions, TextBold, Text, ContainerDescriptions} from "./styled";
import React from "react";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {formatCurrency} from "@dropDesk/utils/helpers/common";


const CurrentSubscription = (
    {
        currentSubscription
    }: {
        currentSubscription: PaymentSubscriptionEntity
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <TextBold size={17} color={colors.text}>Plano Atual:</TextBold>

            <Content>
                <ContainerDescriptions>
                    <ContentDescriptions>
                        <TextBold size={15} color={colors.hintInverse}>
                            Nome:
                        </TextBold>
                        <Text color={colors.text}
                              size={15}>&nbsp;&nbsp;{currentSubscription.planName}</Text>
                    </ContentDescriptions>
                    <ContentDescriptions>
                        <TextBold size={15} color={colors.hintInverse}>
                            Valor:
                        </TextBold>
                        <Text color={colors.text}
                              size={15}>&nbsp;&nbsp;{formatCurrency(currentSubscription.planValue())}</Text>
                    </ContentDescriptions>
                </ContainerDescriptions>

                <ContainerDescriptions>
                    <ContentDescriptions>
                        <TextBold size={15} color={colors.hintInverse}>
                            Quantidade atendentes:
                        </TextBold>
                        <Text color={colors.text}
                              size={15}>&nbsp;&nbsp;{currentSubscription.quantityOfUsers}</Text>
                    </ContentDescriptions>
                    <ContentDescriptions>
                        <TextBold size={15} color={colors.hintInverse}>
                            Dia vencimento:
                        </TextBold>
                        <Text color={colors.text} size={15}>&nbsp;&nbsp;{currentSubscription.dayDue}</Text>
                    </ContentDescriptions>
                </ContainerDescriptions>
            </Content>
        </Container>
    );
}
export default CurrentSubscription;
