import "reflect-metadata";
import {inject, injectable} from "inversify";
import {AddressRepository} from "@dropDesk/domain/repositories/common/localization/localization.repository";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";

@injectable()
export class SearchAddressUseCase {
  private _repository: AddressRepository;

  constructor(@inject(AddressRepository) repository: AddressRepository) {
    this._repository = repository;
  }

  public call(address: string): Promise<AddressEntity> {
    return this._repository.searchAddress(address);
  }
}

