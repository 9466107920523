import {observer} from "mobx-react";
import React from "react";
import {
    Container,
    Content,
    Header,
    ShapeColor,
    Text
} from "@dropDesk/presentation/pages/company_configurations/ui/theme/colors/styled";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {
    BlueAppColors,
    BrownAppColors,
    GreenAppColors,
    GreyAppColors,
    OrangeAppColors,
    PinkAppColors,
    PurpleAppColors,
    RedAppColors,
    SharedColors
} from "@dropDesk/domain/entities/theme/app_colors";
import {ColorsType} from "@dropDesk/domain/entities/theme/colors_enum";
import {ColorScheme} from "@dropDesk/domain/entities/theme/color_scheme";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const Colors = observer(
    (
        {
            handleChangeTheme,
            colors
        }: {
            handleChangeTheme: (name: ColorsType) => void
            colors: ColorScheme
        }
    ) => {

        return (
            <Container>
                <Header>
                    <Text color={colors.text}>Escolha a cor predominante do tema da sua empresa</Text>
                </Header>
                <Content>
                    <ShapeColor
                        onClick={() => {
                            handleChangeTheme(ColorsType.orange);
                        }}
                        background={OrangeAppColors.accent}
                        bordercolor={colors.brightness === Brightness.dark ? SharedColors.white : colors.border}
                        boxshadowcolor={colors.hint}>
                        {colors.accent === OrangeAppColors.accent &&
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.tick}
                                color={SharedColors.white}
                                size={24}
                            />}
                    </ShapeColor>
                    <ShapeColor
                        onClick={() => {
                            handleChangeTheme(ColorsType.default);
                        }}
                        background={GreenAppColors.accent}
                        bordercolor={colors.brightness === Brightness.dark ? SharedColors.white : colors.border}
                        boxshadowcolor={colors.hint}>
                        {colors.accent === GreenAppColors.accent &&
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.tick}
                                color={SharedColors.white}
                                size={24}
                            />}
                    </ShapeColor>
                    <ShapeColor
                        onClick={() => {
                            handleChangeTheme(ColorsType.blue);
                        }}
                        background={BlueAppColors.accent}
                        bordercolor={colors.brightness === Brightness.dark ? SharedColors.white : colors.border}
                        boxshadowcolor={colors.hint}>
                        {colors.accent === BlueAppColors.accent &&
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.tick}
                                color={SharedColors.white}
                                size={24}
                            />}
                    </ShapeColor>
                    <ShapeColor
                        onClick={() => {
                            handleChangeTheme(ColorsType.red);
                        }}
                        background={RedAppColors.accent}
                        bordercolor={colors.brightness === Brightness.dark ? SharedColors.white : colors.border}
                        boxshadowcolor={colors.hint}>
                        {colors.accent === RedAppColors.accent &&
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.tick}
                                color={SharedColors.white}
                                size={24}
                            />
                        }
                    </ShapeColor>


                </Content>

                <Content>
                    <ShapeColor
                        onClick={() => {
                            handleChangeTheme(ColorsType.grey);
                        }}
                        background={GreyAppColors.accent}
                        bordercolor={colors.brightness === Brightness.dark ? SharedColors.white : colors.border}
                        boxshadowcolor={colors.hint}>
                        {colors.accent === GreyAppColors.accent &&
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.tick}
                                color={SharedColors.white}
                                size={24}
                            />
                        }
                    </ShapeColor>
                    <ShapeColor
                        onClick={() => {
                            handleChangeTheme(ColorsType.pink);
                        }}
                        background={PinkAppColors.accent}
                        bordercolor={colors.brightness === Brightness.dark ? SharedColors.white : colors.border}
                        boxshadowcolor={colors.hint}>
                        {colors.accent === PinkAppColors.accent &&
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.tick}
                                color={SharedColors.white}
                                size={24}
                            />
                        }
                    </ShapeColor>
                    <ShapeColor
                        onClick={() => {
                            handleChangeTheme(ColorsType.brown);
                        }}
                        background={BrownAppColors.accent}
                        bordercolor={colors.brightness === Brightness.dark ? SharedColors.white : colors.border}
                        boxshadowcolor={colors.hint}>
                        {colors.accent === BrownAppColors.accent &&
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.tick}
                                color={SharedColors.white}
                                size={24}
                            />
                        }
                    </ShapeColor>
                    <ShapeColor
                        onClick={() => {
                            handleChangeTheme(ColorsType.purple);
                        }}
                        background={PurpleAppColors.accent}
                        bordercolor={colors.brightness === Brightness.dark ? SharedColors.white : colors.border}
                        boxshadowcolor={colors.hint}>
                        {colors.accent === PurpleAppColors.accent &&
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.tick}
                                color={SharedColors.white}
                                size={24}
                            />
                        }
                    </ShapeColor>
                </Content>
            </Container>
        )
    })
export default Colors;
