import {observer} from "mobx-react";
import ModalSearchResponseStandard from "@dropDesk/presentation/components/modals/modal_search_response_standard";
import React from "react";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ModalsInput = observer((
    {
        visibleModalResponseStandard,
        setVisibleModalResponseStandard,
        handleSelectResponseStandard,
        handleOnCloseModalResponseStandard
    }: {
        visibleModalResponseStandard: boolean
        setVisibleModalResponseStandard: (value: boolean) => void
        handleSelectResponseStandard: (responseStandard: ResponseStandardEntity) => void
        handleOnCloseModalResponseStandard: () => void
    }
) => {

    const appController = useInjection(AppController);

    return (
        <>
            {visibleModalResponseStandard && <ModalSearchResponseStandard
                visible={visibleModalResponseStandard}
                onClose={() => {
                    setVisibleModalResponseStandard(false);
                    handleOnCloseModalResponseStandard();
                }}
                handleSelectResponseStandard={async (responseStandard) => {
                    handleSelectResponseStandard(responseStandard);
                    setVisibleModalResponseStandard(false);
                }}
                textHeaderModal={"Respostas rápidas"}
                labelNoResults={"Nenhum resposta encontrada na pesquisa"}
                inputSearchPlaceholder={"Pesquise por todas as respostas"}
                useFooter={!!appController.user?.permissionAdmin}
                labelFooter={'Nova Resposta'}
            />}
        </>
    )
});

export default ModalsInput;
