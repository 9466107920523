import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {
    PaymentSubscriptionEntity,
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {PlanEntity} from "@dropDesk/domain/entities/plan/plan_entity";
import {ListPlansSubscriptionUseCase} from "@dropDesk/domain/use_case/subscription/list_plans_subscription.usecase";
import {PaySubscriptionUseCase} from "@dropDesk/domain/use_case/subscription/pay_subscription.usecase";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {
    convertDateToFormatBR, formatDateToDDMMYYYY,
} from "@dropDesk/utils/helpers/date_helper";
import {downloadFile} from "@dropDesk/utils/helpers/files";
import {GetPaymentSubscriptionUseCase} from "@dropDesk/domain/use_case/subscription/get_payment_subscription.usecase";
import {Subscription} from "rxjs";
import {newSubscription} from "@dropDesk/presentation/pages/subscription/controller/new_subscription";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {
    ListInvoicesSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/list_invoices_subscription.usecase";
import {ReactivateSubscriptionUseCase} from "@dropDesk/domain/use_case/subscription/reactivate_subscription.usecase";
import {CancelSubscriptionUseCase} from "@dropDesk/domain/use_case/subscription/cancel_subscription.usecase";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {FindByPKSubscriptionUseCase} from "@dropDesk/domain/use_case/subscription/findbypk_subscription.usecase";
import {DropDeskError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";
import {
    ListCompanySubscriptionLogsUseCase
} from "@dropDesk/domain/use_case/company/list_company_subscription_logs_company.usecase";
import {
    PaymentSubscriptionSimulateEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_simulate.entity";
import {GetSimulateSubscriptionUseCase} from "@dropDesk/domain/use_case/subscription/get_simulate_subscription.usecase";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

configure({
    enforceActions: "always",
});

export enum RoutesSubscription {
    detailSubscription = 'detailSubscription',
    selectPlan = 'selectPlan',
    checkout = 'checkout'
}

@injectable()
export class SubscriptionController {

    private readonly _listPlansSubscriptionUseCase: ListPlansSubscriptionUseCase;
    private readonly _paySubscriptionUseCase: PaySubscriptionUseCase;
    private readonly _lastPaymentSubscriptionUseCase: GetPaymentSubscriptionUseCase;
    private readonly _listInvoicesSubscriptionUseCase: ListInvoicesSubscriptionUseCase;
    private readonly _reactivateSubscriptionUseCase: ReactivateSubscriptionUseCase;
    private readonly _cancelSubscriptionUseCase: CancelSubscriptionUseCase;
    private readonly _findByPKSubscriptionUseCase: FindByPKSubscriptionUseCase;
    private readonly _listCompanySubscriptionLogsUseCase: ListCompanySubscriptionLogsUseCase;
    private readonly _getSimulateSubscriptionUseCase: GetSimulateSubscriptionUseCase;

    constructor(
        @inject(ListPlansSubscriptionUseCase) listPlansSubscriptionUseCase: ListPlansSubscriptionUseCase,
        @inject(PaySubscriptionUseCase) changePlanSubscriptionUseCase: PaySubscriptionUseCase,
        @inject(GetPaymentSubscriptionUseCase) lastPaymentSubscriptionUseCase: GetPaymentSubscriptionUseCase,
        @inject(ListInvoicesSubscriptionUseCase) listInvoicesSubscriptionUseCase: ListInvoicesSubscriptionUseCase,
        @inject(ReactivateSubscriptionUseCase) reactivateSubscriptionUseCase: ReactivateSubscriptionUseCase,
        @inject(CancelSubscriptionUseCase) cancelSubscriptionUseCase: CancelSubscriptionUseCase,
        @inject(FindByPKSubscriptionUseCase) findByPKSubscriptionUseCase: FindByPKSubscriptionUseCase,
        @inject(ListCompanySubscriptionLogsUseCase) listCompanySubscriptionLogsUseCase: ListCompanySubscriptionLogsUseCase,
        @inject(GetSimulateSubscriptionUseCase) getSimulateSubscriptionUseCase: GetSimulateSubscriptionUseCase,
    ) {
        makeObservable(this);
        this._listPlansSubscriptionUseCase = listPlansSubscriptionUseCase;
        this._paySubscriptionUseCase = changePlanSubscriptionUseCase;
        this._lastPaymentSubscriptionUseCase = lastPaymentSubscriptionUseCase;
        this._listInvoicesSubscriptionUseCase = listInvoicesSubscriptionUseCase;
        this._reactivateSubscriptionUseCase = reactivateSubscriptionUseCase;
        this._cancelSubscriptionUseCase = cancelSubscriptionUseCase;
        this._findByPKSubscriptionUseCase = findByPKSubscriptionUseCase;
        this._listCompanySubscriptionLogsUseCase = listCompanySubscriptionLogsUseCase;
        this._getSimulateSubscriptionUseCase = getSimulateSubscriptionUseCase;
    }

    initialSubscription = newSubscription();

    table = new ListPaginationEntity<PaymentEntity>({
        pages: 0,
        page: 0,
        limit: 5,
        totalRows: 0,
        data: observable.array([])
    });

    @observable
    loadingMessage?: string | null = null;

    @observable
    loading = false;

    @observable
    activeRoute: RoutesSubscription = RoutesSubscription.detailSubscription;

    @observable
    plans: PlanEntity[] = [];

    @observable
    newSubscription: PaymentSubscriptionEntity = this.initialSubscription;

    @observable
    subscriptionLastPayment?: Subscription;

    @observable
    tableInvoices: ListPaginationEntity<PaymentEntity> = this.table;

    @observable
    tableSubscriptionLogs: ListPaginationEntity<CompanyLogsEntity> = new ListPaginationEntity<CompanyLogsEntity>({
        pages: 0,
        page: 0,
        limit: 5,
        totalRows: 0,
        data: observable.array([])
    });

    @observable
    pagesData: Record<number, PaymentEntity[]> = {};

    @observable
    pagesDataLogs: Record<number, CompanyLogsEntity[]> = {};

    @observable
    cancellationReason?: string;

    @observable
    companyLocal: CompanyEntity | null | undefined = undefined;

    @observable
    log: CompanyLogsEntity | undefined = undefined;

    @observable
    simulate: PaymentSubscriptionSimulateEntity | undefined = undefined;

    @observable
    timeoutSubscriptionLastPayment: ReturnType<typeof setTimeout> | undefined;

    @action
    setSimulate(simulate: PaymentSubscriptionSimulateEntity | undefined) {
        this.simulate = simulate;
    }

    @action
    setLog(log: CompanyLogsEntity | undefined) {
        this.log = log;
    }

    @action
    setCompanyLocal(companyLocal: CompanyEntity | null | undefined) {
        this.companyLocal = companyLocal;
    }

    @action
    setCancellationReason(cancellationReason?: string) {
        this.cancellationReason = cancellationReason;
    }

    @action
    setTableInvoices(value: ListPaginationEntity<PaymentEntity>) {
        this.tableInvoices = value;
        this.pagesData[this.tableInvoices.page] = this.tableInvoices.data;
    }

    @action
    setTableSubscriptionLogs(value: ListPaginationEntity<CompanyLogsEntity>) {
        this.tableSubscriptionLogs = value;
        this.pagesDataLogs[this.tableSubscriptionLogs.page] = this.tableSubscriptionLogs.data;

    }

    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableInvoices(this.tableInvoices.copyWith({
                ...this.tableInvoices,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableInvoices(this.tableInvoices.copyWith({
                ...this.tableInvoices,
                page: page,
            }))
            return this.listInvoices();
        }
    }

    @action
    getDataFromPageLogs = async (page: number): Promise<void> => {
        if (this.pagesDataLogs[page]) {
            this.setTableSubscriptionLogs(this.tableSubscriptionLogs.copyWith({
                ...this.tableSubscriptionLogs,
                page: page,
                data: this.pagesDataLogs[page],
            }))

        } else {
            this.setTableSubscriptionLogs(this.tableSubscriptionLogs.copyWith({
                ...this.tableSubscriptionLogs,
                page: page,
            }))
            return this.listSubscriptionLogs();
        }
    }

    @action
    async makeNewSubscription(newSubs: PaymentSubscriptionEntity) {
        this.listPlans(newSubs).then();
    }

    @action
    setNewPaymentSubscription(subscription: PaymentSubscriptionEntity, olderSubs: PaymentSubscriptionEntity) {
        if (olderSubs.hasDowngrade(subscription) && olderSubs.dayDue !== subscription.dayDue) {
            toastMessage.error(`No processo de downgrade de plano, não é possível realizar alterações na data de
             vencimento, estamos restaurando o vencimento para dia ${olderSubs.dayDue}, por favor verifique.`);
            subscription.dayDue = olderSubs.dayDue;
        }

        this.newSubscription = subscription;
        if (this.activeRoute === RoutesSubscription.selectPlan) {
            this.getSimulate(olderSubs);
        }
    }

    @action
    getSimulate = async (olderSubs: PaymentSubscriptionEntity): Promise<void> => {
        try {
            if (olderSubs.hasUpgrade(this.newSubscription)) {
                this.startLoading("subscription.getSimulate");
                const simulate = await this._getSimulateSubscriptionUseCase.call(this.newSubscription);
                this.setSimulate(simulate);
                this.stopLoading();
            } else {
                this.setSimulate(undefined);
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    getValuePlanOnDemand(): number | undefined {
        if (this.plans.length > 0) {
            return this.plans[0].value;
        }
        return;
    }

    @action
    setActiveRoute(route: RoutesSubscription) {
        this.activeRoute = route;
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setPlans(plans: PlanEntity[]) {
        this.plans = plans;
    }

    @action
    listPlans = async (newSubs: PaymentSubscriptionEntity): Promise<void> => {
        try {
            this.startLoading("subscription.listPlan");
            const plans = await this._listPlansSubscriptionUseCase.call(false);
            this.setPlans(plans);
            const plan = plans[0];
            this.setNewPaymentSubscription(this.newSubscription?.copyWith({
                plan,
                idPlan: plan.id,
            }), newSubs);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    listInvoices = async (): Promise<void> => {
        try {
            this.startLoading();
            const response = await this._listInvoicesSubscriptionUseCase.call(
                this.tableInvoices.page,
                this.tableInvoices.limit,
                undefined
            );
            this.setTableInvoices(response)
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    listSubscriptionLogs = async (): Promise<void> => {
        try {
            this.startLoading();
            const response = await this._listCompanySubscriptionLogsUseCase.call(
                this.tableSubscriptionLogs.page,
                this.tableSubscriptionLogs.limit,
            );
            this.setTableSubscriptionLogs(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    initialize(
        newSubs: PaymentSubscriptionEntity
    ) {
        this.setNewPaymentSubscription(this.newSubscription.copyWith({
            dayDue: newSubs.plan.legacy ? 10 : newSubs.dayDue,
            planUserMultiplier: newSubs.isFree || newSubs.plan.legacy ? 1 : newSubs.planUserMultiplier,
        }), newSubs);
        Promise.all([
            this.listInvoices(),
            this.listSubscriptionLogs()
        ]);
    }


    @action
    clearTimeOutSubscriptionLastPayment() {
        if (this.timeoutSubscriptionLastPayment) {
            clearTimeout(this.timeoutSubscriptionLastPayment);
        }
    }

    @action
    dispose(): void {
        this.subscriptionLastPayment?.unsubscribe();
        this.clearTimeOutSubscriptionLastPayment();
    }

    @action
    cancel = async (
        newSubs: PaymentSubscriptionEntity,
        isFutureSubscription: boolean,
        onSuccessCancel: (subscription: PaymentSubscriptionEntity, key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading(isFutureSubscription ? "subscription.cancelFutureSubscription" : "subscription.cancelSubscription");
            const response = await this._cancelSubscriptionUseCase.call(newSubs.id, this.cancellationReason ?? '', isFutureSubscription);
            onSuccessCancel(response, isFutureSubscription ? 'subscription.success.cancelFutureSubscription' : 'subscription.success.cancelSubscription');
            await Promise.all([
                this.listInvoices(),
                this.listSubscriptionLogs()
            ]);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    reactivate = async (
        newSubs: PaymentSubscriptionEntity,
        onSuccessReactive: (subscription: PaymentSubscriptionEntity, key: string) => void,
    ): Promise<void> => {
        try {
            this.startLoading("subscription.reactiveSubscription");
            const response = await this._reactivateSubscriptionUseCase.call(newSubs.id);
            onSuccessReactive(response, 'subscription.success.reactiveSubscription');
            await Promise.all([
                this.listInvoices(),
                this.listSubscriptionLogs()
            ]);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getSubscription = async (id: string): Promise<PaymentSubscriptionEntity> => {
        try {
            const response = await this._findByPKSubscriptionUseCase.call(id);
            return response;
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            throw new DropDeskError('payment_subscription_not_found_exception');
        }
    }

    @action
    pay = async (
        onSuccessPay: (
            paymentSubscription: PaymentSubscriptionEntity,
            paymentMethod: PaymentMethodEntity,
            key: string
        ) => void,
        currentPaymentMethod?: PaymentMethodEntity,
        getPaymentMethod?: () => Promise<PaymentMethodEntity | undefined>,
    ): Promise<void> => {
        try {
            this.startLoading("subscription.changePlan");
            let paymentMethod: PaymentMethodEntity | undefined;
            if (getPaymentMethod) {
                paymentMethod = await getPaymentMethod();
            }

            if ((!!currentPaymentMethod?.pix && !!paymentMethod?.pix) || (!!currentPaymentMethod?.boleto && !!paymentMethod?.boleto)) {
                paymentMethod = undefined;
            }

            const paymentSubscription = await this._paySubscriptionUseCase.call(this.newSubscription, paymentMethod);

            paymentMethod = new PaymentMethodEntity({
                ...paymentSubscription.company!.paymentMethod!
            });
            onSuccessPay(paymentSubscription, paymentMethod, 'subscription.success.pay');
            this.setActiveRoute(RoutesSubscription.detailSubscription);
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    observerPaymentSubscription = (
        id: string,
        onUpdateSubscriptionLocal: (subscription: PaymentSubscriptionEntity) => void,
        timeoutToUnsubscribeInMinutes?: number
    ): void => {
        this.subscriptionLastPayment?.unsubscribe();
        this.subscriptionLastPayment = this._lastPaymentSubscriptionUseCase.call(id).subscribe({
            next: (payment) => {
                if (payment.paymentSubscription) {
                    payment.paymentSubscription.payments = [payment];
                    onUpdateSubscriptionLocal(payment.paymentSubscription);
                }

                this.clearTimeOutSubscriptionLastPayment();
                if (timeoutToUnsubscribeInMinutes) {
                    this.timeoutSubscriptionLastPayment = setTimeout(() => {
                        this.subscriptionLastPayment?.unsubscribe();
                    }, 60000 * timeoutToUnsubscribeInMinutes)
                }
            },
            error: (err: any) => {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            }
        });

    };

    @action
    onClickViewInvoice(barCodeData: string) {
        window.open(barCodeData, '_blank');
    }

    @action
    onClickViewBill(payment: PaymentEntity) {
        window.open(payment.idExternal, '_blank');
    }

    @action
    onClickDownloadInvoice(barCodeData: string) {
        const fileName = `fatura_dropdesk_${convertDateToFormatBR(new Date())}.pdf`;
        downloadFile(barCodeData, fileName);
    }

    @action
    onClickConfirmPlan() {
        this.setActiveRoute(RoutesSubscription.checkout);
    }

    @action
    isValidDataConfirmPlan(olderSubs: PaymentSubscriptionEntity): boolean {
        if ((this.simulate?.total === undefined || this.simulate?.total === null) && olderSubs.hasUpgrade(this.newSubscription)) {
            return false;
        }
        const isValidUpgrade =
            olderSubs.hasUpgrade(this.newSubscription)
                ?
                this.simulate!.total > 0 || this.simulate!.canSubscribe
                :
                (
                    olderSubs.quantityOfUsers !== this.newSubscription.quantityOfUsers
                    ||
                    olderSubs.dayDue !== this.newSubscription.dayDue
                );
        return !!this.newSubscription?.plan.id && !!this.newSubscription?.planUserMultiplier && PaymentSubscriptionEntity.daysValidForDue(process.env.MODE).includes(this.newSubscription?.dayDue) && !!isValidUpgrade;
    }

    @action
    getDisabledText(olderSubs: PaymentSubscriptionEntity) {
        if (!this.isValidDataConfirmPlan(olderSubs)) {
            if (this.simulate?.upgradeAllowedAfter) {
                const messageError = `Não é possível fazer um novo aumento de plano até ${formatDateToDDMMYYYY(new Date(this.simulate.upgradeAllowedAfter))}, entre em contato com o suporte DropDesk, caso deseja fazer um novo aumento de plano.`;
                toastMessage.error(messageError);
                return messageError;
            }
            return 'Informe a nova quantidade de atendentes.';
        }
        return '';
    }

}
