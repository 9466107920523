import {observer} from "mobx-react";
import {
    Container,
    Content, ContentDescription, ContentIcon, ContentSubTitle, TextBold,
    Text
} from "./styled";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import React from "react";
import {useBetween} from "use-between";
import {useModalsSubscription} from "@dropDesk/presentation/pages/subscription/ui/modals/visible_modal_subscription";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const PaySuccess = observer(
    ({}: {}) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            setVisibleModalPaymentSuccess
        } = useBetween(useModalsSubscription);


        return (
            <Container>
                <HeaderModal
                    disabled={false}
                    letter={'Pagamento Realizado'}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={() => setVisibleModalPaymentSuccess(false)}
                />
                <Content background={colors.onBackground}>

                    <ContentIcon>
                        <DropDeskIcon icon={ICONS_DROPDESK.check} size={130} color={colors.success}/>
                    </ContentIcon>

                    <ContentSubTitle>
                        <TextBold size={20} color={colors.success}>Pagamento realizado com sucesso!</TextBold>
                    </ContentSubTitle>

                    <ContentDescription>
                        <Text size={16} color={colors.text}>Seu pagamento foi aceito e registrado com êxito
                            em nosso sistema. Queremos aproveitar esta oportunidade para agradecer por sua contínua
                            confiança em nossos serviços. Esperamos continuar a atender às suas necessidades da melhor
                            maneira possível, seu apoio é o que nos impulsiona a oferecer um serviço excepcional.</Text>
                    </ContentDescription>
                </Content>
            </Container>
        )
    })
export default PaySuccess;
