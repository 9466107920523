import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type TextType = {
    color: string;
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100vh;
  height: 100%;
  width: 100%;
    // background: ${hexToRGBA(SharedColors.loading, 0.7)};
  z-index: 1009;
  position: fixed;
  align-items: center;
  justify-content: center;
  center: 0;
  left: 0;
  right: 0;
  top: 0;
`;
export const ContentDescription = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
`;
export const Description = styled.h2<TextType>`
  color: ${props => props.color};
  font-weight: bold;
  letter-spacing: 1.5px;
`;

