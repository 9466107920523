export enum PixType {
    static = 'static',
    dynamic = 'dynamic'
}

export enum CardType {
    credit = 'credit'
}

export enum PaymentProvider {
    safe2Pay = 'safe_2_pay',
    iugu = 'iugu'
}

export enum TabsPayment {
    creditCard = 'creditCard',
    pix = 'pix',
    boleto = 'boleto'
}

export enum CardBrand {
    visa = 'visa',
    mastercard = 'mastercard',
    elo = 'elo',
    amex = 'amex',
    diners = 'diners',
    discover = 'discover',
    unknown = 'unknown',
}
