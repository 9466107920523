import {Container, TextCommon} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const Footer = () => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <TextCommon color={colors.text}>
                ©2019–2024 Todos os direitos reservados. DropDesk® é uma marca registrada da própria DropDesk.
                <a style={{marginLeft: 3, marginRight: 3, color: colors.text, textDecoration: 'underline'}}
                   target="blank"
                   href={'https://www.dropdesk.com.br/termos'}> Política de privacidade</a>
                ,
                <a style={{marginLeft: 3, marginRight: 3, color: colors.text, textDecoration: 'underline'}}
                   target="blank"
                   href={'https://www.dropdesk.com.br/termos-de-uso'}>Termos de
                    uso</a>
                e
                <a style={{marginLeft: 3, marginRight: 3, color: colors.text, textDecoration: 'underline'}}
                   target="blank"
                   href={'https://www.dropdesk.com.br/termos-de-servico'}>Termos
                    de serviço</a>.
            </TextCommon>
        </Container>
    );
};

export default Footer;
