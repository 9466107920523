import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";

@injectable()
export class ListActiveConnectionsCompanyUseCase {
  private _repository: CompanyRepository;

  constructor(@inject(CompanyRepository) repository: CompanyRepository) {
    this._repository = repository;
  }

  public call(page: number, limit: number, searchParam: string): Promise<ListPaginationEntity<CompanyConnectionsEntity>> {
    return this._repository.listActiveConnections(page, limit, searchParam);
  }
}

