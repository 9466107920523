import styled from "styled-components";
import {ContainerType, TextClosedType} from "@dropDesk/presentation/components/modals/modal_closed_ticket/modal_closed_ticket";


export const Container = styled.div<ContainerType>`
  display: flex;
  width: 400px;
  min-height: 300px;
  overflow: auto;
  flex-direction: column;
  border-radius: 7px;
  background: ${(props) => props.background};
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`;

export const TextClosed = styled.strong<TextClosedType>`
  font-size: 22px;
  text-align: center;
  color: ${(props) => props.color};
`;
