import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export class SectorEntity {
    id!: string;
    idCompany!: string;
    name!: string;
    note?: string;
    chatLabel?: string;
    listableForClients!: boolean;
    deleted!: boolean;
    createdAt?: string;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    public toCreateAccount(): Record<string, unknown> {
        return {
            name: this.name,
        }
    }

    public realName(userLogged?: UserEntity): string {
        return userLogged && userLogged.isUserClient && !!this.chatLabel ? this.chatLabel : this.name;
    }

    public static fromJson(json: Record<string, any>): SectorEntity {
        return new SectorEntity({
            id: json['id'],
            idCompany: json['idCompany'],
            name: json['name'],
            note: json['note'],
            chatLabel: json['chatLabel'],
            listableForClients: json['listableForClients'] ?? true,
            deleted: json['deleted'] ?? false,
            createdAt: json['createdAt'],
        });
    }

    constructor({
                    id,
                    idCompany,
                    name,
                    note,
                    chatLabel,
                    listableForClients,
                    deleted,
                    createdAt,
                }: {
        id?: string;
        idCompany: string;
        name: string;
        note?: string;
        chatLabel?: string;
        listableForClients: boolean;
        deleted?: boolean;
        createdAt?: string;

    }) {
        Object.assign(this, {
            id,
            idCompany,
            name,
            note,
            chatLabel,
            listableForClients,
            deleted,
            createdAt,

        });
    }

    copyWith({
                 id,
                 idCompany,
                 name,
                 note,
                 chatLabel,
                 listableForClients,
                 deleted,
                 createdAt,
             }: {
        id?: string;
        idCompany?: string;
        name?: string;
        note?: string;
        chatLabel?: string;
        listableForClients?: boolean;
        deleted?: boolean;
        createdAt?: string;

    }): SectorEntity {
        return new SectorEntity({

            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            name: name ?? this.name,
            note: note ?? this.note,
            chatLabel: chatLabel ?? this.chatLabel,
            deleted: deleted ?? this.deleted,
            listableForClients: listableForClients ?? this.listableForClients,
            createdAt: createdAt ?? this.createdAt,
        })
    }
}


