import {observer} from "mobx-react";
import React from "react";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";
import {ContentData} from "@dropDesk/presentation/pages/response_standard/modals/modal_crud/styled";
import {
    useValidatorInputResponseStandard
} from "@dropDesk/presentation/pages/response_standard/modals/modal_crud/inputs/validator";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";


const InputsFormResponseStandard = observer(
    ({
         responseStandard,
         onUpdate,
         loading,
     }: {
        responseStandard?: ResponseStandardEntity
        onUpdate: (responseStandard: ResponseStandardEntity) => void,
        loading: boolean,
    }) => {

        const {
            errorMessageTitle,
            errorMessageDescription,
        } = useValidatorInputResponseStandard(responseStandard, loading);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked?: any; type: string };
        }) => {
            const _responseStandard = responseStandard!.copyWith({
                [event.target.name]: (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,
            });
            onUpdate(_responseStandard);

        };

        return (
            <ContentData>
                <div style={{display: 'flex', height: 80, alignItems: 'center', justifyContent: 'center'}}>
                    <InputWithBorder
                        autoFocus={true}
                        required={true}
                        name='title'
                        size={35}
                        disabled={loading}
                        placeHolder={loading && responseStandard?.isUnsaved() ? "Carregando..." : "Título de identificação"}
                        messageError={errorMessageTitle}
                        label={"Título"}
                        value={responseStandard?.title ?? ''}
                        onChange={handleAllChanges}
                    />
                </div>
                <div style={{display: 'flex', height: 110, alignItems: 'center', justifyContent: 'center'}}>
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        marginTop={10}
                        name='description'
                        size={65}
                        disabled={loading}
                        placeHolder={loading && responseStandard?.isUnsaved() ? "Carregando..." : "Descrição da mensagem"}
                        messageError={errorMessageDescription}
                        label={"Mensagem"}
                        value={responseStandard?.description ?? ''}
                        onChange={handleAllChanges}
                    />

                </div>
            </ContentData>
        )
    })
export default InputsFormResponseStandard;
