import React, {useState} from "react";
import {TooltipPlacement} from "antd/lib/tooltip";
import {PopOverStyled} from "@dropDesk/presentation/components/popup/pop_confirm/styled";
import type {ActionType} from 'rc-trigger/lib/interface';
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const PopOver = (
    {
        placement,
        trigger,
        children,
        content,
        open,
        onOpenChange,
        style,
        showArrow,
        overlayInnerStyle
    }: {
        placement: TooltipPlacement
        trigger: ActionType | ActionType[]
        children: React.ReactNode
        content: React.ReactNode
        open?: boolean
        showArrow?: boolean
        onOpenChange?: (value: boolean) => void
        style?: React.CSSProperties;
        overlayInnerStyle?: React.CSSProperties;
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <PopOverStyled
            style={style}
            open={open}
            onOpenChange={(value) => onOpenChange ? onOpenChange(value) : undefined}
            placement={placement}
            trigger={trigger}
            //title={title}
            content={content}
            //getPopupContainer={(element) => element}
            color={colors.backgroundInput}
            borderColor={colors.border}
            shadow={colors.isDarkTheme ? SharedColors.white : SharedColors.black}
            showArrow={showArrow}
            overlayInnerStyle={overlayInnerStyle}
        >
            {children}
        </PopOverStyled>
    )
}
export default PopOver;
