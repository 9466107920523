import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {VoidSuccess} from "@dropDesk/domain/entities/response/response";

@injectable()
export class ReactionMessageUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(
        messageId: string,
        reactionId: string,
        reaction: string,
    ): Promise<VoidSuccess> {
        return this._repository.reactMessage(messageId, reactionId, reaction);
    }
}

