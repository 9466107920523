import HeaderModal from "../../headers/header_modal";
import {Container, Content, ContentForm, ContentTable} from "./styled";
import {ModalProps} from "@dropDesk/presentation/components/modals/modal_search/modal_search";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

export default function ModalSearch<T extends object>(props: ModalProps<T>) {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ModalMain
            open={props.open}
            onRequestClose={props.onRequestClose}
            width={800}
            renderComponent={<Container>
                <HeaderModal
                    disabled={props.loading}
                    letter={props.textHeaderModal}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={props.onRequestClose}
                />
                <Content background={colors.onBackground}>
                    <InputSearchGeneric
                        inputSearchPlaceholder={props.inputSearchPlaceholder}
                        loading={props.loading}
                        onSearch={props.onSearch}
                        defaultValue={props.inputValue}
                        autoFocus={true}
                    />
                    <ContentTable>
                        <TableGeneric<T>
                            sticky={true}
                            size={'small'}
                            tableColumns={props.tableColumns}
                            tableDataSource={props.tableDataSource}
                            onSelectedRow={props.onSelectedRow}
                            loading={props.loading}
                            labelNoResults={props.labelNoResults}
                            collapsedMenu={false}
                            defaultCurrentPage={1}
                            totalRows={props.totalRows}
                            page={props.page}
                            pageSize={props.pageSize}
                            rowSelection={props.rowSelection ? {...props.rowSelection} : null}
                            onChange={props.onChange}
                            extraLayout={props.extraLayout}
                        />
                        {props.useFooter &&
                            <ContentForm>
                                <FooterForm
                                    disabled={props.loading}
                                    loading={props.loading}
                                    removePadding={true}
                                    type={"submit"}
                                    letter={props.labelFooter ?? "Salvar"}
                                    onSubmit={() => props.onSave ? props.onSave() : () => {
                                    }}
                                    onRequestClose={props.onRequestClose}
                                />
                            </ContentForm>
                        }
                    </ContentTable>
                </Content>
            </Container>}
        />
    );
}
