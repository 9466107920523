import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import {TicketEvaluationRepository} from "@dropDesk/domain/repositories/ticket/evaluation/ticket_evaluation.repository";

@injectable()
export class SetTicketEvaluationUseCase {
  private _repository: TicketEvaluationRepository;

  constructor(@inject(TicketEvaluationRepository) repository: TicketEvaluationRepository) {
    this._repository = repository;
  }

  public call(ticketsDescriptionStatic: TicketEvaluationEntity): Promise<TicketEvaluationEntity> {
    return this._repository.set(ticketsDescriptionStatic);
  }
}

