import {
    ButtonConnection, ContainerConnection,
    ContentConnectionStatus, ContentImageApproved,
    ContentTextStatus, Image,
    TextConnectionStatus
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/connection/status_whatsapp/styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {GreenAppColors, SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const ImageStatus = (
    {
        status,
        imageUrl,
        textButton,
        isDisconnected,
        onClickButton,
        loading
    }: {
        status: string
        imageUrl: string | null
        textButton: string
        isDisconnected?: boolean
        loading: boolean
        onClickButton: () => void
    }) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const getTextLoading = (): React.ReactNode => {
        if (loading) {
            return (
                <TextConnectionStatus color={colors.text}>&nbsp;Carregando...</TextConnectionStatus>
            )

        } else {
            return (
                <TextConnectionStatus
                    style={{fontWeight: 'bold'}}
                    color={isDisconnected ? colors.accent : colors.textError}>&nbsp;{status}</TextConnectionStatus>
            )
        }

    }

    return (
        <ContainerConnection>
            <ContentTextStatus>
                <TextConnectionStatus color={colors.text}>Status:</TextConnectionStatus>
                {getTextLoading()}
            </ContentTextStatus>

            <ContentConnectionStatus>
                <ContentImageApproved
                    border={colors.border}
                    background={colors.onBackground}>
                    {imageUrl && <DropDeskIcon style={{display: 'flex'}} icon={ICONS_DROPDESK.whatsapp} size={106}
                                               color={colors.hintInverse}/>}
                    {!imageUrl &&
                        <DropDeskIcon style={{display: 'flex'}} icon={ICONS_DROPDESK.check} size={106}
                                      color={colors.success}/>}
                </ContentImageApproved>
                <ButtonConnection
                    onClick={onClickButton}
                    background={isDisconnected ? colors.errorOnPrimary : colors.accent}
                    color={SharedColors.white}>
                    {loading ? 'Carregando' : textButton}
                </ButtonConnection>
            </ContentConnectionStatus>
        </ContainerConnection>
    )
}
export default ImageStatus;
