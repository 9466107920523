import {observer} from "mobx-react";
import {
    ContentExportImport, ContentFilter, ContentInput, ContentInputAndFilter
} from "./styled";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import React from "react";
import SelectRangeDate from "@dropDesk/presentation/components/dates/select_range_date";
import {PeriodEnum} from "@dropDesk/utils/helpers/common";


const SearchBar = observer(
    ({
         visibleButtonsOfActions,
         loading,
         period,
         listImports,
         setPeriod,
         navigateToImport,
         labelPeriodCustom
     }: {
        visibleButtonsOfActions: boolean,
        loading: boolean,
        period: Array<Date> | null
        listImports: () => void
        navigateToImport: () => void
        setPeriod: (period: Array<Date> | null, label: PeriodEnum) => void
        labelPeriodCustom: PeriodEnum
    }) => {

        return (
            <ContentFilter>
                <ContentInputAndFilter>
                    <ContentInput>
                        <SelectRangeDate
                            size={37}
                            label={'Período'}
                            allowClear={true}
                            value={period}
                            onChange={(arrayDate, label) => {
                                setPeriod(arrayDate, label);
                                listImports();
                            }}
                            minDateInDays={91}
                            labelPeriodCustom={labelPeriodCustom}
                        />
                    </ContentInput>
                </ContentInputAndFilter>
                <ContentExportImport>
                    {visibleButtonsOfActions && <ButtonNew
                        marginLeft={0}
                        isButtonMain={true}
                        label={'Nova importação'}
                        onClick={() => navigateToImport()}
                        sizeLeftIcon={16}
                        disabled={loading}
                    />}
                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBar;
