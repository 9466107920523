import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";

@injectable()
export abstract class TicketEvaluationRepository {

  public abstract set(ticketEvaluation: TicketEvaluationEntity): Promise<TicketEvaluationEntity>;

  public abstract list(
    page: number,
    searchParam: string,
    limit: number,
    searchEvaluationValue: number | null,
  ): Promise<ListPaginationEntity<TicketEvaluationEntity>>;

  public abstract findByPK(id: string): Promise<TicketEvaluationEntity>;
}
