import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export type ButtonType = {
    color: string;
    size: number;
    background: string;
}

export type ContainerType = {
    background: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.background};
  text-align: center;
  width: 100%;
  padding: 4px 16px;
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ContentButton = styled.span<ButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  padding: 2px 22px;
  margin-right: 20px;
  height: 28px;
  border: 1px solid ${props => props.color};
  border-radius: 5px;
  background: ${(props) => props.background};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

export const Button = styled.button`
  display: flex;
  border: 0;
  background: transparent;
  margin: 0 3px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(80%);
  }
`;
