import {observer} from "mobx-react";
import React from "react";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {Text} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/invoices_history/table/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {PaymentStatus} from "@dropDesk/domain/entities/payment/payment.enum";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";


const LabelStatus = observer(
    ({
         payment,
         currentSubscription
     }: {

        payment: PaymentEntity,
        currentSubscription: PaymentSubscriptionEntity
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const getColor = (payment: PaymentEntity): string => {
            if (payment.isPaid) {
                return colors.success;
            }
            if (payment.rejected || payment.canceled) {
                return colors.textError;
            }
            if (payment.status === PaymentStatus.pending) {
                return colors.text;
            }
            return colors.text;
        }

        return (
            <Text size={16} color={getColor(payment)}>{payment.statusLabel(currentSubscription)}</Text>
        )
    })
export default LabelStatus;
