import {inject, injectable} from "inversify";
import {IoRepository} from "@dropDesk/domain/repositories/io/io.repository";
import {IoRemoteDataSource} from "@dropDesk/data/data_source/io/io_remote.datasource";
import {Observable} from "rxjs";
import {FileProgress} from "@dropDesk/domain/entities/common/file_progress";
import {AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";

@injectable()
export class IoRepositoryImpl implements IoRepository {
    private _dataSource: IoRemoteDataSource;

    constructor(
        @inject(IoRemoteDataSource)
            dataSource: IoRemoteDataSource
    ) {
        this._dataSource = dataSource;
    }

    public createObjectURLBlob(file: File | Blob): string {
        return this._dataSource.createObjectURLBlob(file)
    }

    public urlToBlob(url: string): Promise<Blob> {
        return this._dataSource.urlToBlob(url);
    }

    public downloadFile(url: string, fileName: string): Observable<FileProgress> {
        return this._dataSource.downloadFile(url, fileName);
    }

    public startRecordVoiceListener(audioRecorder: AudioRecordEntity, stream: MediaStream): Observable<AudioRecordEntity> {
        return this._dataSource.startRecordVoiceListener(audioRecorder, stream);
    }

    public getMediaStream(): Promise<MediaStream> {
        return this._dataSource.getMediaStream();
    }

    public getAudioFromMediaRecorder(audioRecorder: AudioRecordEntity): Promise<AudioRecordEntity> {
        return this._dataSource.getAudioFromMediaRecorder(audioRecorder);
    }

    public stopRecord(mediaRecorder?: MediaRecorder, stream?: MediaStream): void {
        return this._dataSource.stopRecord(mediaRecorder, stream);
    }

}
