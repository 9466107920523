import { fetchAndActivate, getValue } from "firebase/remote-config";
import {remoteConfig} from "@dropDesk/data/clients/firebase.client";

export const firebaseRemoteConfigDefaultsValue = {
  'ft_onesignal_mode': 'off',
}

export class FeatureToggle {
  static async initialize(): Promise<void> {
    await fetchAndActivate(remoteConfig);
  }

  static getOneSignalToggleState(): string {
    const val = getValue(remoteConfig, 'ft_onesignal_mode');
    return val.asString();
  }


}