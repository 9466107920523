import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ContainerImageEditorNoPreview, Content, TextNoPreview} from "./styled";
import {observer} from "mobx-react";
import Header from "@dropDesk/presentation/pages/image_editor/ui/body/no_preview/header";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const NoPreview = observer((
    {
        getNameFileSelected
    }: {
        getNameFileSelected: () => string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;


    return (
        <ContainerImageEditorNoPreview>
            <Header getNameFileSelected={getNameFileSelected}/>
            <Content>
                <DropDeskIcon
                    size={75}
                    icon={ICONS_DROPDESK.documentOutline}
                    color={colors.hintInverse}
                />
                <TextNoPreview color={colors.hintInverse}>Pré-visualização indisponível</TextNoPreview>
            </Content>
        </ContainerImageEditorNoPreview>
    )
});

export default NoPreview;
