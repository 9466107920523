import styled from "styled-components";

export type TextType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  height: 170px;
  justify-content: center;
  align-items: center;
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
  letter-spacing: .7px;
`;
