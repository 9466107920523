import styled from "styled-components";

export const ContainerFormSector = styled.div`
  display: flex;
  min-width: 650px;
  flex-direction: column;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`;

