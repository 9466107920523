import styled from "styled-components";

export const ContentFilter = styled.div`
  display: flex;
  height: 61px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  margin-top: 1px;
`;

export const ContentExportImport = styled.div`
  display: flex;
`;
export const ContentInputAndListableNoticesDeleted = styled.div`
  display: flex;
  flex: 1;

  flex-direction: row;
`;

export const ContentInput = styled.div`
  display: flex;
  width: 100%;
`;

export const ContentInputSearch = styled.div`
  max-width: 350px;
  width: 100%;
`;
export const ContentInputSearchAndFilter = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;
export const PaddingFilters = styled.div`
  padding: 0 32px;
`;
