import React from "react";
import {
    ContainerButtonHeader,
} from "@dropDesk/presentation/components/inputs/input_with_border/remove_filter/styled";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const RemoveFilter = (
    {
        onClickRemoveFilter
    }: {
        onClickRemoveFilter?: () => void;
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerButtonHeader
            background={colors.buttonOnBackground}
            accent={colors.accent}
            onClick={(event) => {
                onClickRemoveFilter ? onClickRemoveFilter() : () => {
                }
                event.stopPropagation();
            }}>
            <DropDeskIcon icon={ICONS_DROPDESK.cancel}/>
        </ContainerButtonHeader>
    )
}
export default RemoveFilter;
