import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export type ContainerType = {
    bordercolor: string;
}

export type TextType = {
    color: string;
    size: number;
}

type ContentType = {
    accent: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 60px;
  max-height: 60px;
  border-radius: 7px;
  border: 1px solid ${props => props.bordercolor};
  padding: 5px 10px;
  margin: 8px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 1px 1px 5px 5px;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
`;

export const TextBold = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const Text = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const ContentDisplayPlan = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentValue = styled.div`
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const ContainerLinkPortal = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 20px 0;
  flex: 1;
`;
export const ContentCopyText = styled.div<ContentType>`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  height: 23px;
  align-items: flex-end;
  cursor: pointer;
  width: 110px;
  border-radius: 5px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

