import CreditCard from "@dropDesk/presentation/components/credit_card";
import {
    TokenizeCardEntity,
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {Focused} from "react-credit-cards-2";
import {observer} from "mobx-react";

const Card = observer((
    {
        newTokenizeCard,
        focused
    }: {
        newTokenizeCard: TokenizeCardEntity
        focused?: Focused;
    }
) => {
    return (
        <CreditCard
            focused={focused}
            cvc={newTokenizeCard.cvv}
            expiry={newTokenizeCard.expirationDate}
            name={newTokenizeCard.holderName}
            number={newTokenizeCard.number}
            placeholders={{name: 'Nome do titular'}}
            locale={{valid: 'Valido até'}}
        />
    );
});
export default Card;
