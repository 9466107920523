import styled from 'styled-components';
import {hexToRGBA, shadeOrLighten} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
}

type ButtonAddType = {
    bordercolor: string;
    background: string;
    disabled?: boolean;
}

type BorderType = {
    bordercolor: string;
    disabled?: boolean;
}

type ContentImageType = {
    bordercolor: string;
    hasfocus: boolean
    background: string;
    disabled: boolean;
}

type IconType = {
    color: string;
    fontsize: number;
}

type IconRemoveType = {
    disabled: boolean;
}

export const Container = styled.div<BorderType>`
  display: flex;
  min-height: 80px;
  height: 80px;
  align-items: center;
  justify-items: center;
  border-top: 1px solid ${(props) => props.bordercolor};
  padding: 0 16px;
`;

export const ContainerScrollImage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;


export const ContentScroll = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  max-width: 768px;

  .wrapper::-webkit-scrollbar {
    display: block;
    height: 5px;

  }
`;

export const ContainerButton = styled.button<ContainerType>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  position: relative;
  border: none;
  width: 60px;
  min-width: 60px;
  background: ${(props) => props.background};
  border-radius: 50%;
  margin-right: 8px;

  &:hover {
    filter: brightness(80%);
  }
`;

export const ButtonAddFile = styled.div<ButtonAddType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 2px;
  margin: 0px 5px;
  border: 1px solid ${props => props.bordercolor};
  background: ${props => props.background};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    filter: brightness(80%);
  }
`;


export const IconRemoveImage = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  z-index: 101;
  display: none;
  pointer-events: none;

`;
export const ContainerIconRemoveImage = styled.span<IconRemoveType>`
  position: absolute;
  cursor: ${props => props.disabled ? 'no-drop' : 'pointer'};
  display: flex;
  align-items: center;
  justify-items: center;
  background: transparent;
  height: 16px;
  width: 15px;
  z-index: 102;
  top: 3px;
  right: 7px;

  &:hover {
    background: ${hexToRGBA(SharedColors.black, .5)};
  }
`;

export const ContentImage = styled.div<ContentImageType>`
  position: relative;
  display: flex;
  top: 0;
  z-index: 2;
  margin: 0 5px;
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  border-radius: 2px;
  cursor: ${props => props.disabled ? 'no-drop' : 'pointer'};
  align-items: center;
  justify-content: center;
  border: ${props => `${props.hasfocus ? `2px solid ${props.bordercolor}` : `1px solid ${props.bordercolor}`}`};
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: ${(props) => props.background};

  &:hover {
    filter: brightness(80%);
  }
`;


export const ContainerImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  &:hover ${IconRemoveImage} {
    display: inline;
  }
`;

export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontsize}px`};
  pointer-events: none;
`;


export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

export const ContentButton = styled.div`
  display: flex;
  overflow: auto;
  height: 80px;
  widht: 61px;
  align-items: center;
  justify-items: center;
`;
