import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import React, {useState} from "react";
import {ContentIcon} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import PopOver from "@dropDesk/presentation/components/popup/pop_confirm";
import {
    Button,
    Container,
    Content, ContentTitle,
    Footer,
    Text
} from "@dropDesk/presentation/pages/chat/ui/header_notification/popconfirm_list_chat/styled";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const PopConfirmListChats = (
    {}: {}
) => {
    const readChatRulesLocalStorage = localStorage.getItem(ConstantsKeys.permitReadChatRules);
    const permitReadChatRules: boolean = readChatRulesLocalStorage ? JSON.parse(readChatRulesLocalStorage) : true;
    const [open, setOpen] = useState<boolean>(permitReadChatRules);
    const [displayPopOver, setDisplayPopOver] = useState<boolean>(permitReadChatRules);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <>
            {displayPopOver && <div style={{display: 'flex', marginRight: 10, position: 'relative'}}>
                <PopOver
                    trigger={'click'}
                    content={
                        <Container>
                            <ContentTitle bordercolor={colors.border}>
                                <Text
                                    style={{fontStyle: 'italic'}}
                                    size={15}
                                    bold={true}
                                    color={colors.text}>Regra dos chats</Text>
                            </ContentTitle>
                            <Content>

                                <Text size={15} color={colors.text}>
                                    Os atendimentos em <span style={{fontWeight: 'bold'}}>“atendendo”</span>: &nbsp; é
                                    importante
                                    observar que serão encerrados automaticamente
                                    em
                                    duas
                                    circunstâncias: caso fique sem interatividade por um período de 30
                                    minutos(configurável) ou quando
                                    atingirem a
                                    data
                                    de
                                    vencimento previamente estabelecida. Estas medidas visam otimizar a eficiência e
                                    assegurar
                                    um
                                    atendimento
                                    ágil e oportuno.
                                </Text>
                                <Text style={{marginTop: 10}} size={15} color={colors.text}>
                                    Os atendimentos em <span
                                    style={{fontWeight: 'bold'}}>“espera”</span>:&nbsp;
                                    são listados exclusivamente dos últimos 3 meses.
                                </Text>
                            </Content>
                            <Footer>
                                <Button
                                    style={{marginRight: 10}}
                                    disabled={false}
                                    background={'transparent'}
                                    shadow={colors.accent}
                                    color={colors.text}
                                    border={colors.border}
                                    onClick={() => setOpen(false)}>
                                    Fechar
                                </Button>
                                <Button
                                    disabled={false}
                                    background={colors.accent}
                                    shadow={colors.accent}
                                    color={SharedColors.white}
                                    border={colors.border}
                                    onClick={() => {
                                        setOpen(false);
                                        localStorage.setItem(ConstantsKeys.permitReadChatRules, JSON.stringify(false));
                                        setDisplayPopOver(false);
                                    }}>
                                    Confirmar
                                </Button>
                            </Footer>
                        </Container>
                    }
                    open={open}
                    placement="bottom"
                    onOpenChange={(value) => setOpen(value)}
                    children={
                        <ContentIcon
                            onClick={() => setOpen(!open)}
                            disabled={false}
                        >
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.info}
                                size={17}
                                color={colors.text}
                            />
                        </ContentIcon>
                    }
                />
            </div>}
        </>
    )
}
export default PopConfirmListChats;
