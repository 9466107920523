import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";


@injectable()
export abstract class TicketEvaluationRemoteDataSource {

    public abstract set(ticketEvaluation: TicketEvaluationEntity): Promise<TicketEvaluationEntity>;

    public abstract list(
        page: number,
        searchParam: string,
        limit: number,
        searchEvaluationValue: number | null,
    ): Promise<ListPaginationEntity<TicketEvaluationEntity>>;

    public abstract findByPK(id: string): Promise<TicketEvaluationEntity>;

}

@injectable()
export class TicketEvaluationRemoteDataSourceImpl implements TicketEvaluationRemoteDataSource {


    constructor() {

    }

    baseTicketEvaluationUrl: string = `tickets/evaluations`;
    baseEvaluationFindByPk: string = `tickets/evaluation`;
    baseSetTicketEvaluationEntityUrl: string = `tickets/setEvaluation`;


    public async list(
        page: number,
        searchParam: string,
        limit: number,
        searchEvaluationValue: number | null,
    ): Promise<ListPaginationEntity<TicketEvaluationEntity>> {
        return new Promise<ListPaginationEntity<TicketEvaluationEntity>>(async (resolve, reject) => {
            try {

                const response = await api.get(`${this.baseTicketEvaluationUrl}?searchParam=${searchParam ?? ''}&page=${page ?? ''}&limit=${limit ?? ''}&evaluationValue=${searchEvaluationValue ?? ''}`);

                const result = new ListPaginationEntity<TicketEvaluationEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: Record<string, any>) => TicketEvaluationEntity.fromJson(entry)),
                });

                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public set(ticketEvaluation: TicketEvaluationEntity): Promise<TicketEvaluationEntity> {
        return new Promise<TicketEvaluationEntity>(async (resolve, reject) => {
            try {
                const response = await api.post(this.baseSetTicketEvaluationEntityUrl, ticketEvaluation.toJson());
                return resolve(response.data);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public findByPK(id: string): Promise<TicketEvaluationEntity> {
        return new Promise<TicketEvaluationEntity>(async (resolve, reject) => {
            try {
                const response = await api.get(this.baseEvaluationFindByPk + `/${id}`);
                const ticketEvaluation = new TicketEvaluationEntity({
                    ...response.data,
                    ticket: new TicketEntity({
                        ...response.data.ticket,
                    })
                })
                return resolve(ticketEvaluation);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

}
