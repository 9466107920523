import {TicketStatusType} from "@dropDesk/domain/entities/ticket/ticket_maps";

export enum TicketConfigType {
    location = 'location',
    priority = 'priority',
    status = 'status',
    resolutionTime = 'resolutionTime',
}

export enum DescriptionType {
    opened = 'opened',
    inProgress = 'inProgress',
    closed = 'closed',
    canceled = 'canceled',
}

export class TicketConfig {
    id!: string;
    description!: string;
    type?: TicketConfigType;
    extra?: Record<string, any>;
    defaultValue!: boolean;

    constructor({
                    id,
                    description,
                    type,
                    extra,
                    defaultValue,
                }: {
        id?: string;
        description?: string;
        type?: TicketConfigType;
        extra?: Record<string, unknown>;
        defaultValue: boolean;
    }) {
        Object.assign(this, {
            id,
            description,
            type,
            extra,
            defaultValue,
        });
    }

    public static fromJson(json: Record<string, any>): TicketConfig {
        return new TicketConfig({
            id: json['id'],
            description: json['description'],
            type: json['type'],
            extra: json['extra'],
            defaultValue: json['defaultValue'],
        });
    }

    public static translateStatusToHuman(label?: string): string {
        const types: Record<string, string> = {
            ['Aberto']: 'Aberto',
            ['Atendendo']: 'Atendendo',
            ['AguardandoAvaliacao']: 'Aguardando avaliação',
            ['FechadoAssistenteVirtual']: 'Fechado por assistente virtual',
            ['FechadoCliente']: 'Fechado por cliente',
            ['Fechado']: 'Fechado',
            ['FechadoAssistenteVirtualInatividade']: 'Fechado por inatividade',
            ['unknown']: 'unknown',
        };
        const key: string = label ?? 'unknown';
        return types[key] ?? label;
    }

    public toOptionType() {
        return {value: this.id, label: TicketConfig.translateStatusToHuman(this.description)};
    }

    public get isDefaultPriority() {
        return this.defaultValue && this.type === TicketConfigType.priority;
    }

    public get isDefaultStatus() {
        return this.defaultValue && this.type === TicketConfigType.status;
    }

    public get isAttendingStatus() {
        return this.type === TicketConfigType.status && this.extra?.type === TicketStatusType.inProgress;
    }
}
