import styled from "styled-components";

type TextType = {
    color: string;
    accent: string;
}

type IconType = {
    color: string;
    size: number;
}


export const TextSound = styled.span`
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

`;

