import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
}

type TextType = {
    color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
  overflow: auto;
`;

export const Content = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: ${props => props.background};
`;
export const ContainerSelectRole = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  width: 768px;
  max-width: 768px;
  height: 500px;
  max-height: 500px;
  border-radius: 5px;
  background: ${props => props.background};
`;

