import {HighLightWordsType} from "@dropDesk/presentation/components/high_light_words/high_light_words";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {removeDiacritics} from "@dropDesk/utils/helpers/string_helper";
import React from "react";
import {observer} from "mobx-react";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const HighLightWords = observer((props: HighLightWordsType) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const getRealHighlight = (): string => {

        let realHighlight = props.searchWords;
        const textHighlight = props.textToHighlight;
        if (!!realHighlight && textHighlight) {

            const _pos = removeDiacritics(textHighlight.toLowerCase()).indexOf(removeDiacritics(props.searchWords.toLowerCase()));
            if (_pos > -1) {
                realHighlight = textHighlight.substring(_pos, _pos + props.searchWords.length);
                return realHighlight;
            }
        }
        return realHighlight;

    }

    const getHighlightedText = (): React.ReactNode => {
        const realHighlight = getRealHighlight();
        const text = props.textToHighlight;
        const parts = text?.split(new RegExp(`(${realHighlight})`, "gi"));
        return <span> {parts?.map((part, i) =>
            <span key={i} style={part.toLowerCase() === realHighlight.toLowerCase() ?
                {background: colors.accent, color: SharedColors.white, fontSize: 15} :
                {fontSize: 15, color: props.color ?? colors.text}
            }>
            {part}
        </span>)
        } </span>;
    }


    return (
        <>
            {getHighlightedText()}
        </>
    )
});

export default HighLightWords;
