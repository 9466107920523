import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    ContainerButtonNewAddress, ContentButtonNewAddress,
    ContentData,
    SubContentInputs
} from "./styled";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import ListInputsAddresses from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_address/list_inputs";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const InputsFormAddress = observer(
    ({
         onNewAddress,
         setAddress,
         onChangeAddress,
         onSearchZipCode,
         onDeleteAddress,
         loading,
         addresses
     }: {
        onNewAddress: () => void,
        setAddress: (address: AddressEntity | null) => void,
        onChangeAddress: (address: AddressEntity) => void,
        onSearchZipCode: (address: AddressEntity) => void,
        onDeleteAddress: () => void,
        addresses: AddressEntity[]
        loading: boolean

    }) => {

        const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);

        return (

            <SubContentInputs>
                <ContentData>
                    <ContainerButtonNewAddress>
                        <ContentButtonNewAddress>
                            <ButtonNew
                                marginLeft={0}
                                label={'Novo endereço'}
                                onClick={onNewAddress}
                                nameLeftIcon={ICONS_DROPDESK.location}
                                sizeLeftIcon={16}
                                disabled={loading}
                                isButtonMain={true}
                            />
                        </ContentButtonNewAddress>
                    </ContainerButtonNewAddress>
                    {addresses.filter((address) => address.action !== BackendAction.delete).map((address) => {
                        return (

                            <div key={address.id}>
                                <ListInputsAddresses
                                    address={address}
                                    disabled={loading}
                                    onChange={(address) => onChangeAddress(address)}
                                    onSearchZipCode={(address) => onSearchZipCode(address)}
                                    onDeleteAddress={(address) => {
                                        setAddress(address);
                                        setVisibleModalDelete(true);
                                    }}
                                />
                            </div>
                        )
                    })}

                    <ModalDelete
                        open={visibleModalDelete}
                        onRequestClose={() => {
                            setAddress(null);
                            setVisibleModalDelete(false)
                        }}
                        textHeaderModal={'Excluir Endereço'}
                        textButton={'Excluir'}
                        onRequestSave={async () => {
                            onDeleteAddress();
                            setVisibleModalDelete(false);
                        }}
                        nameIcon={ICONS_DROPDESK.delete}
                        sizeIcon={130}
                        textInformation={`Deseja realmente fazer a exclusão deste endereço?`}
                        loading={loading}
                        isActionDelete={true}
                    />
                </ContentData>
            </SubContentInputs>
        )
    })
export default InputsFormAddress;
