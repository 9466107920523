import React, {useEffect, useRef} from 'react';
import {
    Container,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";

import {TaskController} from "@dropDesk/presentation/pages/task/controller/task.controller";
import Header from "@dropDesk/presentation/pages/task/ui/header";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import TableTasks from "@dropDesk/presentation/pages/task/ui/table";
import ModalsTask from "@dropDesk/presentation/pages/task/ui/modals";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {useBetween} from "use-between";
import {useModalTask} from "@dropDesk/presentation/pages/task/ui/modals/handle_change_visible";

const Task = (observer(() => {


    const {t} = useTranslation();
    const controller = useInjection(TaskController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const inputRef = useRef<HTMLInputElement>(null);

    const initialize = () => {
        controller.tableTask.limit = 6;
        controller.list();
    }

    useEffect(() => {
        initialize();
    }, []);

    const onSuccessCreate = (key: string) => {
        setTimeout(() => {
            controller.makeTask(appController.user!);
            toastMessage.success(t(key));
            handleVisibleModalEditTask(false);
        }, 0);

    }

    const onSuccess = (key: string) => {
        toastMessage.success(t(key));
    }
    const setInputFocus = () => {
        inputRef.current?.focus();
    }

    const handleVisibleModalEditTask = (visible: boolean, task?: TaskEntity) => {

        if (visible && task) {
            controller.setTask(task);
        } else {
            controller.makeTask(appController.user!);
            controller.list();
        }
        setVisibleModalEditTask(visible);
    }

    const {
        setVisibleModalEditTask,
    } = useBetween(useModalTask);

    return (
        <Container background={colors.background} bordercolor={colors.border}>
            <Header
                totalTasks={controller.tableTask.totalRows}
                task={controller.task}
                disabled={controller.loading}
                onUpdate={(task) => controller.setTask(task)}
                onCreate={() => controller.set(onSuccessCreate)}
                referenceInput={inputRef}
                filterStatusValue={controller.status}
                filterTypeValue={controller.type}
                filterOrderValue={controller.orders}
                setFilterStatus={(status) => {
                    controller.setFilterStatus(status);
                    controller.list();
                }}
                setOrder={(order) => {
                    controller.setOrder(order);
                    controller.list();
                }}
                setType={(type) => {
                    controller.setType(type);
                    controller.list();
                }}
            />

            <TableTasks
                handleDoneAndUndoneTask={(id, action) => controller.handleDoneAndUndoneTask(id, action)}
                loading={controller.loading && !controller.loadingMessage}
                tableTask={controller.tableTask}
                getDataFromPage={(page) => controller.getDataFromPage(page)}
                setInputFocus={setInputFocus}
                handleVisibleModalEditTask={handleVisibleModalEditTask}
            />
            <ModalsTask
                handleDeleteTask={async (id) => {
                    await controller.delete(id, onSuccess);
                    controller.list();
                }}
                task={controller.task}
                handleVisibleModalEditTask={handleVisibleModalEditTask}
                onUpdate={(task) => controller.setTask(task)}
                loading={controller.loading}
                set={() => controller.set(onSuccessCreate)}
            />

            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={270}
                description={controller.loadingMessage}
            />}
        </Container>
    )

}));
export default Task;
