import {useEffect, useState} from "react";
import {isValidDescription, isValidName} from "@dropDesk/utils/helpers/validators";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";

export const useValidatorInputResponseStandard = (responseStandard?: ResponseStandardEntity, loading?: boolean) => {

    const [errorMessageTitle, setErrorMessageTitle] = useState('');
    const [errorMessageDescription, setErrorMessageDescription] = useState('');


    useEffect(() => {
        validate();
    }, [responseStandard]);

    const validate = (): boolean => {
        setErrorMessageTitle('');
        setErrorMessageDescription('');

        if (!responseStandard) {
            return false;
        }

        if (!isValidDescription(responseStandard.title) && !loading) {
            setErrorMessageTitle('O título deve possuir mais de 2 letras')
            return false;
        } else if (!isValidDescription(responseStandard.description) && !loading) {
            setErrorMessageDescription('A mensagem deve possuir mais de 2 letras')
            return false;
        } else {
            return true;
        }
    }

    return {
        errorMessageTitle,
        setErrorMessageTitle,
        errorMessageDescription,
        setErrorMessageDescription,
        validate
    }
};
