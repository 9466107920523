import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container,} from "./styled";
import React from "react";
import Header from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan/header";
import Body from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan/body";
import Footer from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan/footer";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {observer} from "mobx-react";


const TotalPlan = observer((
    {
        newSubscription,
        valuePlanOnDemand,
        disabled,
        loading,
        onClickConfirmPlan,
        labelFirstPayment,
        totalFirstPayment,
        hasDowngrade,
        disabledText,
        daysCharged
    }: {
        newSubscription: PaymentSubscriptionEntity
        valuePlanOnDemand?: number;
        disabled: boolean;
        loading: boolean;
        onClickConfirmPlan: () => void
        labelFirstPayment: string
        totalFirstPayment: number
        hasDowngrade: boolean
        disabledText: string;
        daysCharged?: number
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container
            background={colors.onBackground}
            isDarkTheme={colors.brightness === Brightness.dark}
            border={colors.border}
        >
            <Header totalValue={newSubscription.totalValue}/>
            <Body
                valuePlanOnDemand={valuePlanOnDemand}
                newSubscription={newSubscription}
                labelFirstPayment={labelFirstPayment}
                totalFirstPayment={totalFirstPayment}
                daysCharged={daysCharged}
            />
            <Footer
                disabled={disabled}
                loading={loading}
                onClickConfirmPlan={onClickConfirmPlan}
                hasDowngrade={hasDowngrade}
                disabledText={disabledText}
            />
        </Container>
    );
});
export default TotalPlan;
