import {NavigateFunction} from "react-router-dom";

type History = {
    navigate: NavigateFunction | null,
}

const History: History = {
    navigate: null,
}

export default History;
