import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export const Container = styled.div`
  display: flex;
  width: 600px;
  height: calc(100vh - 200px);
  flex-direction: column;
  border-radius: 10px;
  background: ${SharedColors.white};
  overflow: auto;
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
`;
