import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


type ContainerType = {
    background: string
}

type ContainerLoadingType = {
    background: string
    border: string
    isDarkTheme: boolean
}

type ContainerMoveScrollToFinal = {
    background: string
    isDarkTheme: boolean
}

type TextType = {
    color: string
}

type BorderType = {
    bordercolor: string
}

export type IconType = {
    color: string;
    size: number;
}

type ContainerButtonHeaderType = {
    background: string;
    accent: string;
};


export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 2;
  overflow: hidden;
`;
export const ContainerChatMessageInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const ContainerScreenChat = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 2;
  background: ${props => props.background};
  overflow: hidden;
`;


export const ContainerIsDragActive = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  padding: 16px;
  background: ${(props) => props.background};
  z-index: 100;
  overflow: hidden;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
`;

export const Content = styled.div<BorderType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${props => props.bordercolor};
  border-radius: 2px;
`;

export const TextDragFile = styled.span<TextType>`
  text-align: center;
  font-size: 22px;
  color: ${props => props.color};
`;

export const TextMaxSize = styled.span<TextType>`
  text-align: center;
  font-size: 22px;
  color: ${props => props.color};
`;

export const ContentChatMessageAndInput = styled.div<BorderType>`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  //flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-anchor: auto;

  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    filter: brightness(80%);
  }

  &::-webkit-scrollbar-thumb:hover {
    filter: brightness(80%);
  }

`;

export const ContainerLoadingLoadMessage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: 5px;
`;
export const LoadingMoreMessageTop = styled.div<ContainerLoadingType>`
  z-index: 100;
  //position: absolute;
  //top: 5px;
  // left: 50%;
  display: flex;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  box-shadow: 2px 2px 2px 2px ${hexToRGBA(SharedColors.black, 0.1)};
`;

export const LoadingMoreMessageBottom = styled.div<ContainerLoadingType>`
  z-index: 100;
  //position: absolute;
  //right: 50%;
  // left: 50%;
  display: flex;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  box-shadow: 2px 2px 2px 2px ${hexToRGBA(SharedColors.black, 0.1)};
`;

export const ContainerButtonMoveScrollToBottom = styled.div<ContainerMoveScrollToFinal>`
  z-index: 100;
  position: absolute;
  right: 20px;
  bottom: 100px;
  display: flex;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};

  &:hover {
    filter: brightness(80%);
  }
`;
export const Icon = styled.i<IconType>`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
`;

export const ContentLoading = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtonInput = styled.div<ContainerButtonHeaderType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:disabled {
    pointer-events: none;
  }
`;
