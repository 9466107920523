import styled from "styled-components";
import {IconType} from "@dropDesk/presentation/components/graphics/graphic_horizontal_bar/graphic_horizontal_bar";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type ContainerType = {
    background: string;
    border: string;
}

type ContainerButtonHeaderType = {
    background: string;
    accent: string;
    border: string;
};

export const Container = styled.div<ContainerType>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.background};
  height: calc(50vh - 103px);
  min-height: calc(50vh - 103px);
  width: 50%;
  max-width: 50%;

  border: 1px solid ${props => props.border};
  border-radius: 10px;
  margin-bottom: 16px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;

export const ContainerButton = styled.div<ContainerButtonHeaderType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.background};
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;
