import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {
    ReportTicketAggregateEntity
} from "@dropDesk/domain/entities/reports/report_ticket_aggregate/report_ticket_aggregate.entity";
import {downloadFile, openFileInNewTab} from "@dropDesk/utils/helpers/files";

@injectable()
export abstract class ReportTicketAggregateRemoteDataSource {
    public abstract get(period: { startDate: string, endDate: string }): Promise<ReportTicketAggregateEntity>;

    public abstract exportPdf(period: { startDate: string, endDate: string }): Promise<void>;
}

@injectable()
export class ReportTicketAggregateRemoteDataSourceImpl implements ReportTicketAggregateRemoteDataSource {
    baseUrl: string = 'reportTickets/aggregate/data';
    baseUrlExportPdf: string = 'reportTickets/aggregate/pdf';

    async get(period: { startDate: string; endDate: string }): Promise<ReportTicketAggregateEntity> {
        return new Promise<ReportTicketAggregateEntity>(async (resolve, reject) => {

            try {
                const url = `${this.baseUrl}?startDate=${period.startDate}&endDate=${period.endDate}`;

                const response = await api.get(url);
                const reportTicketsAggregate = ReportTicketAggregateEntity.fromJson({
                    lineChartClientsAggregate: response.data.lineChartClientsAggregate,
                    lineChartSectorsAggregate: response.data.lineChartSectorsAggregate,
                    lineChartUsersAggregate: response.data.lineChartUsersAggregate,
                    lineChartDescriptionsStaticAggregate: response.data.lineChartDescriptionsStaticAggregate,
                    timeFirstResponseAggregate: response.data.timeFirstResponseAggregate,
                    averageRating: response.data.averageRating,
                });
                return resolve(reportTicketsAggregate);

            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    async exportPdf(period: { startDate: string, endDate: string }): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const url = `${this.baseUrlExportPdf}?startDate=${period.startDate}&endDate=${period.endDate}`;
                const response = await api.get(url, {responseType: 'blob'});
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                openFileInNewTab(file, 'Carregando pdf...');
                downloadFile(fileURL, `relatório_agregados_${period.startDate}-${period.endDate}.pdf`);
                return resolve();
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

}
