import {Container, ContentBack, ContentPlan, TextBack, TextPlan} from "./styled";
import {ContentIcon} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const Header = (
    {
        setActiveRoute,
    }: {
        setActiveRoute: (route: RoutesSubscription) => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container onClick={() => setActiveRoute(RoutesSubscription.selectPlan)}>
            <ContentBack>
                <ContentIcon>
                    <DropDeskIcon icon={ICONS_DROPDESK.arrowLeft} size={23} color={colors.text}/>
                </ContentIcon>
            </ContentBack>
            <ContentPlan>
                <TextPlan size={19} color={colors.text}>Finalizar Compra</TextPlan>
            </ContentPlan>
        </Container>
    );
}
export default Header;
