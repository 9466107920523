import {
    Button, ContainerReply,
    ContentLineClampTwo, ContentRemoveReply,
    ContentReply,
    ContentTextReply,
    IdentityDisplay, TextReply,
    TextTitleReply
} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import {ReplyEntity} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ContainerButtonInput} from "@dropDesk/presentation/components/chat_view/chat_message/styled";

const MessageReplyInfo = observer((
    {
        messageReply,
        idUserLogged,
        removeReplyingMessage
    }: {
        messageReply: ReplyEntity,
        idUserLogged: string,
        removeReplyingMessage: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const currentUser = appController.user!;
    const color = messageReply.participant?.id === currentUser?.id || (messageReply.participant?.isAttendant && currentUser.isAttendant) ? colors.accent : colors.text;

    return (
        <ContainerReply background={colors.backgroundInputChatBox}>
            <ContentReply
                background={colors.backgroundInput}>
                <IdentityDisplay background={color}></IdentityDisplay>
                <ContentTextReply>
                    <TextTitleReply color={color}>
                        {messageReply.participant?.id === idUserLogged ? "Você" : messageReply.participant?.name}
                    </TextTitleReply>
                    <ContentLineClampTwo>
                        <TextReply color={colors.hintInverse}>{messageReply.socialMessage}</TextReply>
                    </ContentLineClampTwo>
                </ContentTextReply>
            </ContentReply>
            <ContentRemoveReply>
                <ContainerButtonInput
                    accent={colors.accent}
                    background={colors.buttonOnBackground}
                    onClick={() => {
                        removeReplyingMessage();
                    }}
                >
                    <DropDeskIcon icon={ICONS_DROPDESK.close} size={16}/>
                </ContainerButtonInput>
            </ContentRemoveReply>
        </ContainerReply>
    )
});

export default MessageReplyInfo;
