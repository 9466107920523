import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";

class PinEntity {
    lat!: number;
    lon!: number;

    constructor({
                    lat, lon
                }: {
        lat: number;
        lon: number;
    }) {

        Object.assign(this, {
            lat, lon
        });
    }
}

export class AddressEntity {
    id!: string;
    alias?: string | null;
    zipCode!: string;
    street!: string;
    number!: string;
    neighborhood!: string;
    city!: string;
    state!: string;
    shortState!: string;
    complement?: string | null;
    pin?: PinEntity | null;
    action!: BackendAction | null;
    createdAt?: string;

    public static fromJson(json: Record<string, any>): AddressEntity {
        return new AddressEntity({
            id: json['id'],
            alias: json['alias'],
            zipCode: json['zipCode'],
            street: json['street'],
            number: json['number'],
            complement: json['complement'],
            neighborhood: json['neighborhood'],
            city: json['city'],
            state: json['state'],
            shortState: json['shortState'],
            pin: json['pin'],
            action: json['action'],
            createdAt: json['createdAt']
        });
    }

    constructor({
                    id,
                    zipCode,
                    street,
                    number,
                    neighborhood,
                    city,
                    state,
                    shortState,
                    alias,
                    complement,
                    pin,
                    action,
                    createdAt
                }: {
        id: string;
        alias?: string | null;
        zipCode: string;
        street: string;
        number: string;
        neighborhood: string;
        city: string;
        state: string;
        shortState: string;
        complement?: string | null;
        pin?: PinEntity | null;
        action?: BackendAction | null;
        createdAt?: string;
    }) {
        Object.assign(this, {
            id,
            zipCode,
            street,
            number,
            neighborhood,
            city,
            state,
            shortState,
            alias,
            complement,
            pin,
            action,
            createdAt
        });
    }

    copyWith({
                 id,
                 zipCode,
                 street,
                 number,
                 neighborhood,
                 city,
                 state,
                 shortState,
                 alias,
                 complement,
                 pin,
                 action,
                 replaceAliasIfNull,
                 replaceComplementIfNull,
                 replacePinIfNull,
                 replaceActionIfNull,
                 createdAt
             }: {
        id?: string;
        zipCode?: string;
        street?: string;
        number?: string;
        neighborhood?: string;
        city?: string;
        state?: string;
        shortState?: string;
        alias?: string | null;
        complement?: string | null;
        pin?: PinEntity | null;
        action?: BackendAction | null;
        replaceAliasIfNull?: boolean | null,
        replaceComplementIfNull?: boolean | null,
        replacePinIfNull?: boolean | null,
        replaceActionIfNull?: boolean | null,
        createdAt?: string;
    }): AddressEntity {
        return new AddressEntity({
            id: id ?? this.id,
            zipCode: zipCode ?? this.zipCode,
            street: street ?? this.street,
            number: number ?? this.number,
            neighborhood: neighborhood ?? this.neighborhood,
            city: city ?? this.city,
            state: state ?? this.state,
            shortState: shortState ?? this.shortState,
            alias: replaceAliasIfNull ? alias : this.alias,
            complement: replaceComplementIfNull ? complement : this.complement,
            pin: replacePinIfNull ? pin : this.pin,
            action: replaceActionIfNull ? action : this.action,
            createdAt: createdAt ?? this.createdAt,
        })
    }
}
