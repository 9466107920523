import {
    CompanyCreateAccount,
    CompanyEntity,
    CompanyExtraInfoEntity
} from "@dropDesk/domain/entities/company/company.entity";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {ThemeConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/theme_configuration.entity";
import {ClientCreateAccountEntity} from "@dropDesk/domain/entities/client/client_create_account_entity";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";

@injectable()
export abstract class CompanyRepository {

    public abstract set(company: CompanyEntity): Promise<CompanyEntity>;

    public abstract findByPK(): Promise<CompanyEntity>;

    public abstract createAccount(
        company?: CompanyEntity,
        sector?: SectorEntity,
        user?: UserEntity,
        clientCreateAccount?: ClientCreateAccountEntity
    ): Promise<CompanyCreateAccount>;

    public abstract listActiveConnections(page: number, limit: number, searchParam: string): Promise<ListPaginationEntity<CompanyConnectionsEntity>>;

    public abstract setCompanyExtraInfo(extraInfo: CompanyExtraInfoEntity): Promise<CompanyEntity>;

    public abstract updateLegacy(body: Record<string, any>): Promise<void>;

    public abstract setPaymentMethod(input: PaymentMethodEntity): Promise<PaymentMethodEntity>;

    public abstract getThemeByUniqueCode(uniqueCode: number): Promise<ThemeConfigurationEntity | null>;

    public abstract listCompanySubscriptionLogs(page: number, limit: number): Promise<ListPaginationEntity<CompanyLogsEntity>>;
}
