import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Container, Content} from "./styled";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import {GreenAppColors, SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ContentCheckbox} from "@dropDesk/presentation/pages/company/ui/form_company/inputs/styled";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";


const ContractInput = observer(
    ({
         contract,
         acceptContract,
         onClickAcceptContract
     }: {
         contract: string | null
         onClickAcceptContract: (value: boolean) => void
         acceptContract: boolean
     }
    ) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        useEffect(() => {
            if (!acceptContract) {
                setContractRead(false);
            }
        }, [acceptContract]);

        const [visibleModal, setVisibleModal] = useState(false);
        const [contractRead, setContractRead] = useState(false);

        return (
            <>
                <ContentCheckbox>
                    <CheckBoxWithLabel
                        label={`Estou de acordo com este`}
                        checked={acceptContract}
                        name={'contract'}
                        marginLeft={0}
                        height={10}
                        disabled={false}
                        onChange={(event) => {
                            if (!acceptContract) {
                                setVisibleModal(true);
                            } else {
                                onClickAcceptContract(false);
                            }
                        }}
                        tooltip={'Leia o contrato, ao criar esta conta você está de acordo com este contrato.'}
                    />
                    <a
                        href={`https://www.dropdesk.com.br/contrato-de-servico`}
                        style={{
                            color: colors.textLink,
                            fontWeight: 'bold',
                            fontSize: ConstantsStyles.inputFontSize,
                            textDecoration: 'underline'
                        }}
                        target="_blank">
                        &nbsp;contrato de serviço.
                    </a>
                </ContentCheckbox>
                <ModalMain
                    open={visibleModal}
                    onRequestClose={() => setVisibleModal(false)}
                    width={400}
                    renderComponent={
                        <Container>
                            <HeaderModal
                                disabled={false}
                                letter={"Contrato DropDesk"}
                                nameIcon={ICONS_DROPDESK.close}
                                onClick={() => setVisibleModal(false)}
                            />
                            <Content>
                                {contract &&
                                    <>
                                        <div dangerouslySetInnerHTML={{__html: contract}}/>
                                        <div style={{width: 'fit-content'}}>
                                            <CheckBoxWithLabel
                                                label={`Eu declaro que li e aceito o contrato`}
                                                checked={contractRead}
                                                name={'contractRead'}
                                                marginLeft={0}
                                                disabled={false}
                                                onChange={(event) => setContractRead(event.target.checked)}
                                            />
                                        </div>
                                    </>
                                }
                                {!contract && <div>Houve um erro ao obter o contrato</div>}
                            </Content>
                            <FooterModal
                                background={SharedColors.white}
                                backgroundButton={colors.accent}
                                disabled={!contractRead}
                                loading={false}
                                type={"submit"}
                                letter={"Aceito"}
                                onSubmit={() => {
                                    setVisibleModal(false);
                                    onClickAcceptContract(true);
                                }}
                                onRequestClose={() => setVisibleModal(false)}
                            />
                        </Container>
                    }
                />
            </>
        )
    })
export default ContractInput;
