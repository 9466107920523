import {observer} from "mobx-react";
import {Container, Content, ContentCheckBox, ContentCheckBoxAndInput, ContentMessages, LabelInputChat,} from "./styled";
import React from "react";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";
import {
    useValidatorWhatsApp
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/messages/validator";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import CheckBoxWithLabel from "@dropDesk/presentation/components/check_box/check_box_with_label";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Tooltip} from "antd";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const MessagesWhatsAppConfiguration = observer((
    {
        loading,
        companyConfiguration,
        handleAllChangesWhatsAppConfiguration
    }: {
        loading: boolean,
        handleAllChangesWhatsAppConfiguration: (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => void
        companyConfiguration: CompanyConfigurationEntity
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const {
        errorWelcomeMessage,
        errorClosedTicketMessage,
        errorOutTimeOperatingMessage,
        errorRatingMessage,
        errorQueueTicketMessage,
        errorTransferSectorMessage,
        errorTransferUserMessage,
        errorChooseDepartmentMessage,
        errorClosedIdleTicketMessage,
        errorAlertInactivityMessage
    } = useValidatorWhatsApp(companyConfiguration);

    return (
        <Container heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>
            <ContentMessages>
                <ContentCheckBoxAndInput style={{marginRight: 10}}>
                    <CheckBoxWithLabel
                        label={"Mensagem bem-vindo"}
                        color={colors.hintInverse}
                        checked={companyConfiguration.chat.useWelcomeMessage ?? false}
                        disabled={loading}
                        onChange={(event) => {
                            handleAllChangesWhatsAppConfiguration({
                                target:
                                    {
                                        name: 'useWelcomeMessage',
                                        value: event.target.checked,
                                        checked: event.target.checked,
                                        type: 'switch'
                                    }
                            });
                        }}
                        useInformationIcon={true}
                        tooltip={`Ao desmarcar a assistente virtual não irá enviar esta mensagem.
                {{nome_cliente}} representa o Nome do Cliente do Atendimento.
                {{nome_empresa}} representa o Nome da sua Empresa.
                {{protocolo}} representa o Número do Atendimento.
                {{data_abertura}} representa a data de abertura do atendimento.
                 *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp.`}
                        name={'welcomeMessage'}
                    />

                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='welcomeMessage'
                        size={75}
                        disabled={loading || !companyConfiguration.chat.useWelcomeMessage}
                        placeHolder={"Mensagem bem-vindo"}
                        messageError={errorWelcomeMessage}
                        value={companyConfiguration.chat.welcomeMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
                <ContentCheckBoxAndInput>
                    <Tooltip title={`{{nome_empresa}} representa o Nome da sua Empresa.
                    
                    *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp`}>
                        <ContentCheckBox>
                            <LabelInputChat color={colors.hintInverse}>Mensagem escolha de setor</LabelInputChat>
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.info}
                                style={{marginLeft: 3, pointerEvents: 'none'}}
                                color={colors.hintInverse}
                            />
                        </ContentCheckBox>
                    </Tooltip>
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='chooseDepartmentMessage'
                        size={75}
                        disabled={loading}
                        placeHolder={"Mensagem escolha de setor"}
                        messageError={errorChooseDepartmentMessage}
                        value={companyConfiguration.chat.chooseDepartmentMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
            </ContentMessages>
            <ContentMessages>
                <ContentCheckBoxAndInput style={{marginRight: 10}}>
                    <CheckBoxWithLabel
                        color={colors.hintInverse}
                        label={"Mensagem finalização chamado"}
                        checked={companyConfiguration.chat.useClosedTicketMessage ?? false}
                        disabled={loading}
                        onChange={(event) => {
                            handleAllChangesWhatsAppConfiguration({
                                target:
                                    {
                                        name: 'useClosedTicketMessage',
                                        value: event.target.checked,
                                        checked: event.target.checked,
                                        type: 'switch'
                                    }
                            });
                        }}
                        useInformationIcon={true}
                        tooltip={`Ao desmarcar a assistente virtual não irá enviar esta mensagem.
              {{protocolo}} representa o Número do Atendimento.
              *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp.`}
                        name={'useClosedTicketMessage'}
                    />
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='closedTicketMessage'
                        size={75}
                        disabled={loading || !companyConfiguration.chat.useClosedTicketMessage}
                        placeHolder={"Mensagem finalização chamado"}
                        messageError={errorClosedTicketMessage}
                        value={companyConfiguration.chat.closedTicketMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
                <ContentCheckBoxAndInput>
                    <CheckBoxWithLabel
                        label={"Mensagem fora horário operação"}
                        checked={companyConfiguration.chat.useOutTimeOperatingMessage ?? false}
                        color={colors.hintInverse}
                        disabled={loading}
                        onChange={(event) => {
                            handleAllChangesWhatsAppConfiguration({
                                target:
                                    {
                                        name: 'useOutTimeOperatingMessage',
                                        value: event.target.checked,
                                        checked: event.target.checked,
                                        type: 'switch'
                                    }
                            });
                        }}
                        useInformationIcon={true}
                        tooltip={`Ao desmarcar a assistente virtual não irá enviar esta mensagem.
               *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp.`}
                        name={'useOutTimeOperatingMessage'}
                    />
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='outTimeOperatingMessage'
                        size={75}
                        disabled={loading || !companyConfiguration.chat.useOutTimeOperatingMessage}
                        placeHolder={"Mensagem fora horário operação"}
                        messageError={errorOutTimeOperatingMessage}
                        value={companyConfiguration.chat.outTimeOperatingMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
            </ContentMessages>
            <ContentMessages>
                <ContentCheckBoxAndInput style={{marginRight: 10}}>
                    <CheckBoxWithLabel
                        label={"Mensagem fila atendimento"}
                        checked={companyConfiguration.chat.useQueueTicketMessage ?? false}
                        color={colors.hintInverse}
                        disabled={loading}
                        onChange={(event) => {
                            handleAllChangesWhatsAppConfiguration({
                                target:
                                    {
                                        name: 'useQueueTicketMessage',
                                        value: event.target.checked,
                                        checked: event.target.checked,
                                        type: 'switch'
                                    }
                            });
                        }}
                        useInformationIcon={true}
                        tooltip={`Ao desmarcar a assistente virtual não irá enviar esta mensagem.
              {{nome_empresa}} representa o Nome da sua Empresa.
               *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp.`}
                        name={'useQueueTicketMessage'}
                    />
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='queueTicketMessage'
                        size={75}
                        disabled={loading || !companyConfiguration.chat.useQueueTicketMessage}
                        placeHolder={"Mensagem fila atendimento"}
                        messageError={errorQueueTicketMessage}
                        value={companyConfiguration.chat.queueTicketMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
                <ContentCheckBoxAndInput>
                    <CheckBoxWithLabel
                        label={"Mensagem transferência de setor"}
                        checked={companyConfiguration.chat.useTransferSectorMessage ?? false}
                        color={colors.hintInverse}
                        disabled={loading}
                        onChange={(event) => {
                            handleAllChangesWhatsAppConfiguration({
                                target:
                                    {
                                        name: 'useTransferSectorMessage',
                                        value: event.target.checked,
                                        checked: event.target.checked,
                                        type: 'switch'
                                    }
                            });
                        }}
                        useInformationIcon={true}
                        tooltip={`Ao desmarcar a assistente virtual não irá enviar esta mensagem.
                {{nome_setor}} representa o Nome do setor que foi transferido.
                *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp.`}
                        name={'useTransferSectorMessage'}
                    />
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='transferSectorMessage'
                        size={75}
                        disabled={loading || !companyConfiguration.chat.useTransferSectorMessage}
                        placeHolder={"Mensagem transferência de setor"}
                        messageError={errorTransferSectorMessage}
                        value={companyConfiguration.chat.transferSectorMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
            </ContentMessages>
            <ContentMessages>
                <ContentCheckBoxAndInput style={{marginRight: 10}}>
                    <CheckBoxWithLabel
                        label={"Mensagem transferência de atendente"}
                        checked={companyConfiguration.chat.useTransferUserMessage ?? false}
                        color={colors.hintInverse}
                        disabled={loading}
                        onChange={(event) => {
                            handleAllChangesWhatsAppConfiguration({
                                target:
                                    {
                                        name: 'useTransferUserMessage',
                                        value: event.target.checked,
                                        checked: event.target.checked,
                                        type: 'switch'
                                    }
                            });
                        }}
                        useInformationIcon={true}
                        tooltip={`Ao desmarcar a assistente virtual não irá enviar esta mensagem.
                {{nome_atendente}} representa o Nome do Atendente que foi transferido.
              *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp.`}
                        name={'useTransferUserMessage'}
                    />
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='transferUserMessage'
                        size={75}
                        disabled={loading || !companyConfiguration.chat.useTransferUserMessage}
                        placeHolder={"Mensagem transferência de atendente"}
                        messageError={errorTransferUserMessage}
                        value={companyConfiguration.chat.transferUserMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
                <ContentCheckBoxAndInput>
                    <Tooltip title={`*(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp`}>
                        <ContentCheckBox>
                            <LabelInputChat color={colors.hintInverse}>Mensagem sucesso avaliação</LabelInputChat>
                            <DropDeskIcon
                                icon={ICONS_DROPDESK.info}
                                style={{marginLeft: 3, pointerEvents: 'none'}}
                                color={colors.hintInverse}
                            />
                        </ContentCheckBox>
                    </Tooltip>
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='successRatingTicketMessage'
                        size={75}
                        disabled={loading}
                        placeHolder={"Mensagem sucesso avaliação"}
                        messageError={errorRatingMessage}
                        value={companyConfiguration.chat.successRatingTicketMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
            </ContentMessages>

            <ContentMessages>
                <ContentCheckBoxAndInput style={{marginRight: 10}}>
                    <CheckBoxWithLabel
                        label={"Mensagem alerta de inatividade"}
                        checked={companyConfiguration.chat.useNotifyBeforeCloseIdleTicketMessage ?? false}
                        disabled={loading}
                        color={colors.hintInverse}
                        onChange={(event) => {
                            handleAllChangesWhatsAppConfiguration({
                                target:
                                    {
                                        name: 'useNotifyBeforeCloseIdleTicketMessage',
                                        value: event.target.checked,
                                        checked: event.target.checked,
                                        type: 'switch'
                                    }
                            });
                        }}
                        useInformationIcon={true}
                        tooltip={`Ao desmarcar a assistente virtual não irá enviar esta mensagem de alerta 
                        antes de fechar o atendimento por inatividade.
                        {{protocolo}} representa o Número do Atendimento.
              *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp.`}
                        name={'useNotifyBeforeCloseIdleTicketMessage'}
                    />
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='notificationBeforeCloseIdleTicketMessage'
                        size={75}
                        disabled={loading || !companyConfiguration.chat.useNotifyBeforeCloseIdleTicketMessage}
                        placeHolder={"Mensagem alerta de inatividade"}
                        messageError={errorAlertInactivityMessage}
                        value={companyConfiguration.chat.notificationBeforeCloseIdleTicketMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>

                <ContentCheckBoxAndInput style={{marginRight: 10}}>
                    <CheckBoxWithLabel
                        label={"Mensagem finalização por inatividade"}
                        checked={companyConfiguration.chat.useClosedIdleTicketMessage ?? false}
                        disabled={loading}
                        color={colors.hintInverse}
                        onChange={(event) => {
                            handleAllChangesWhatsAppConfiguration({
                                target:
                                    {
                                        name: 'useClosedIdleTicketMessage',
                                        value: event.target.checked,
                                        checked: event.target.checked,
                                        type: 'switch'
                                    }
                            });
                        }}
                        useInformationIcon={true}
                        tooltip={`Ao desmarcar a assistente virtual não irá enviar esta mensagem.
              {{protocolo}} representa o Número do Atendimento.
              *(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp.`}
                        name={'useClosedIdleTicketMessage'}
                    />
                    <InputTextAreaWithBorder
                        useEmojiPicker={true}
                        required={true}
                        name='closedIdleTicketMessage'
                        size={75}
                        disabled={loading || !companyConfiguration.chat.useClosedIdleTicketMessage}
                        placeHolder={"Mensagem finalização chamado por inatividade"}
                        messageError={errorClosedIdleTicketMessage}
                        value={companyConfiguration.chat.closedIdleTicketMessage}
                        onChange={handleAllChangesWhatsAppConfiguration}
                    />
                </ContentCheckBoxAndInput>
            </ContentMessages>
        </Container>
    )
})
export default MessagesWhatsAppConfiguration;
