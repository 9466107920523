import styled from "styled-components";

type TextType = {
    color: string
}


export const ContentMessageDescription = styled.div<TextType>`
  // display: flex;
  flex-direction: row;
  font-size: 14px;
  color: ${props => props.color};
  word-break: break-word;
  white-space: pre-wrap;
  //width:100%;
  margin-top: -5px;
  margin-bottom: -3px;

  &:after {
    content: '\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0';
  }
`;
