import styled from "styled-components";
import {Link} from "react-router-dom";

type ContainerType = {
    background: string;
}

type TextType = {
    color: string;
}


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
  overflow: auto;
`;

export const Section = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  background: ${(props) => props.background};
  overflow: auto;
  flex-direction: column;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


export const ContainerLogin = styled.form`
  width: 430px;
  max-width: 430px;
  flex-direction: column;
  display: flex;
  padding: 16px;
`;
export const Image = styled.img`
  display: flex;
  width: 100%;
  pointer-events: none;
  margin-right: 20px;
`;
export const Title = styled.span<TextType>`
  font-size: 16px;
  margin-bottom: 2px;
  color: ${(props) => props.color};
`;

export const ContentImage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 235px;
  height: 50px;
  margin-bottom: 16px;
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;
export const ContentSubTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextCreateAccount = styled(Link)<TextType>`
  color: ${(props) => props.color};
  font-size: 16px;
  margin: 0;
  padding: 0;

  &:hover {
    color: ${(props) => props.color};
  }

`;

export const TextCreateFree = styled.span<TextType>`
  color: ${(props) => props.color};
  margin: 0 3px;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    filter: brightness(80%);
  }
`;
