import {useEffect, useState} from "react";
import {isValidName} from "@dropDesk/utils/helpers/validators";
import {SectorController} from "@dropDesk/presentation/pages/sector/controller/sector.controller";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";

export const useValidatorInputSectors = (sector?: SectorEntity) => {

    const [errorMessageName, setErrorMessageName] = useState('');
    const [errorMessageChatLabel, setErrorMessageChatLabel] = useState('');
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);


    useEffect(() => {
        validate();
    }, [sector]);

    const validate = (): boolean => {
        setErrorMessageName('');
        setErrorMessageChatLabel('');
        if (!sector) {
            setIsValidDataForm(false);
            return false;
        }
        if (!isValidName(sector.name)) {
            setErrorMessageName('O nome do setor deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;

        } else if (sector.chatLabel && !isValidName(sector.chatLabel)) {
            setErrorMessageChatLabel('O nome amigável do setor deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else {
            setIsValidDataForm(true);
            return true;
        }
    }

    return {
        errorMessageName,
        setErrorMessageName,
        errorMessageChatLabel,
        validate,
        isValidDataForm
    }
};
