import styled from "styled-components";
export const ContentCheckBoxCommentPrivate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-left:10px;
`;


export const ContentCommentPrivate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
