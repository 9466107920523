import HeaderModal from "../../headers/header_modal";
import {Container, Content, ContentItem} from "./styled";
import React from "react";
import {
    ModalTimeSpentProps,
} from "@dropDesk/presentation/components/modals/modal_time_spent/modal_time_spent";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


export default function ModalTimeSpent(props: ModalTimeSpentProps) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ModalMain
            open={props.visible}
            onRequestClose={props.onClose}
            width={600}
            renderComponent={
                <Container background={colors.onBackground}>
                    <HeaderModal
                        disabled={props.loading}
                        letter={"Adicione o tempo gasto no atendimento"}
                        nameIcon={ICONS_DROPDESK.close}
                        onClick={props.onClose}
                    />
                    <Content>
                        {props.listTimeSpent.map((timeSpent) => {
                            return (
                                <ContentItem
                                    key={timeSpent.value}
                                    disabled={props.loading}
                                    color={colors.text}
                                    background={colors.onBackground}
                                    onClick={() => props.onSelectTimeSpent(timeSpent.value)}
                                    border={colors.border}
                                    accent={colors.accent}
                                >
                                    {timeSpent.label}
                                </ContentItem>
                            );
                        })}
                    </Content>
                </Container>}
        />
    );
}
