import {useDropzone} from 'react-dropzone'
import {DropzoneType} from "@dropDesk/presentation/components/drop_zone/pick_file_generic/pick_generic";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {transformBytesToMegaBytes} from "@dropDesk/utils/helpers/files";
import IsDragActive from "@dropDesk/presentation/components/chat_view/chat_message/is_drag_active";

export default function PickFileGeneric(props: DropzoneType) {

    const handlePasteEvent = (event: ClipboardEvent) => {
        try {
            if (event.clipboardData && !props.noDrag && props.isMultiple && !props.disabled) {

                const files = event.clipboardData.items;
                const newFiles: File[] = [];
                let totalFileSize = 0;

                for (const index in files) {
                    const item = files[index];
                    if (item.kind === 'file') {
                        const file: File | null = item.getAsFile();
                        if (file) {
                            newFiles.push(file);
                            totalFileSize += transformBytesToMegaBytes(file.size);
                        }

                    }
                }

                if (newFiles.length <= 20 && newFiles.length > 0 && totalFileSize <= 16) {
                    props.onPick(newFiles);
                } else {
                    if (newFiles.length > 0) {
                        toastMessage.error("Erro, limite maximo permitido de 20 arquivos e total de 16MB");
                    }
                }
            }
        } catch (e) {
            toastMessage.error("Erro, limite maximo permitido de 20 arquivos e total de 16MB");
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: props.typeFileAccept,
        multiple: props.isMultiple,
        noKeyboard: true,
        maxSize: props.maxSizeInMegaBytes * 1000000, //1MB
        noDrag: props.noDrag,
        noClick: props.noClick,
        disabled: props.disabled,
        maxFiles: props.maxFiles,
        onDrop: (acceptedFiles: File[]) => {
            props.onPick(props.noDrag ? acceptedFiles : acceptedFiles.reverse())
        },
    });

    return (
        <div
            onPaste={(event) => handlePasteEvent(event as any as ClipboardEvent)}
            style={{display: 'flex', flex: 1, overflow: 'hidden', position: 'relative'}}
            {...getRootProps()}
        >
            <input  {...getInputProps()} />
            {isDragActive && <IsDragActive/>}
            {props.renderComponent}
        </div>
    )
}
