import Cropper from 'react-easy-crop'
import {ChangeEvent, useCallback, useState} from "react";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import {Container, ContentCrop, ContainerCrop, ContentSlider, TextZoom, SubContainerCrop} from './styled';
import {getCroppedImg} from "@dropDesk/utils/canvas";
import {CropType} from "@dropDesk/presentation/components/crop/crop";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import {Slider} from "@dropDesk/presentation/components/slider";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {Area} from "react-easy-crop/types";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


export const Crop = (props: CropType) => {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;


    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const onCropToFile = useCallback(async () => {
        try {
            const fileCroppedImage = await getCroppedImg(
                props.image!,
                croppedAreaPixels!,
                0
            )
            if (fileCroppedImage) {
                props.onRequestCrop(fileCroppedImage!);
            } else {
                toastMessage.error('Blob null');
            }
        } catch (e: any) {
            toastMessage.error(e);
        }
    }, [props.image, croppedAreaPixels, 0]);

    return (
        <ModalMain
            open={props.image ? true : false}
            onRequestClose={props.onRequestClose}
            width={'60vh'}
            renderComponent={
                <Container background={'transparent'}>

                    <HeaderModal
                        disabled={false}
                        letter={'Recortar Imagem'}
                        nameIcon={ICONS_DROPDESK.close}
                        onClick={props.onRequestClose}
                    />
                    <ContainerCrop background={colors.onBackground}>
                        <SubContainerCrop>
                            <ContentCrop>
                                <Cropper
                                    image={props.image ?? undefined}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={props.aspect ?? 1}
                                    // cropShape={'round'}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                    objectFit={props.objectFit ?? 'contain'}

                                />
                            </ContentCrop>
                        </SubContainerCrop>
                        <ContentSlider>
                            <TextZoom color={colors.text}>Zoom</TextZoom>
                            <Slider
                                width={150}
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                onChange={(value: number) => setZoom(value)}/>
                        </ContentSlider>
                    </ContainerCrop>
                    <FooterModal
                        disabled={false}
                        loading={false}
                        type={"submit"}
                        letter={"Salvar"}
                        onSubmit={onCropToFile}
                        onRequestClose={props.onRequestClose}
                    />
                </Container>}
        />
    )
}
