import styled from "styled-components";
import {Progress} from "antd";

type ProgressBarType = {
  color: string;
}

export const ProgressBarStyled = styled(Progress)<ProgressBarType>`
  .ant-progress-text {
    color: ${(props) => props.color}
  }
`;
