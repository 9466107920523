import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {GetCurrentUserUseCase} from "@dropDesk/domain/use_case/user/get_current_user";
import {LogoutUseCase} from "@dropDesk/domain/use_case/auth/logout.usecase";
import {inject, injectable} from "inversify";
import {action, computed, configure, makeObservable, observable} from "mobx";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {LogoutByAdminUseCase} from "@dropDesk/domain/use_case/auth/logout_by_admin.usecase";
import {ThemeEntity} from "@dropDesk/domain/entities/theme/theme.entity";
import {getLocalTheme} from "@dropDesk/utils/helpers/common";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {
    GetThemeByUniqueCodeCompanyUseCase
} from "@dropDesk/domain/use_case/company/get_theme_by_unique_code_company.usecase";
import logo from "@dropDesk/storage/images/logo_dropdesk_portrait.png";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {NavigateFunction} from "react-router-dom";
import {OptionType} from "@dropDesk/presentation/components/inputs/select_search_main/select_search";
import {SegmentsUseCase} from "@dropDesk/domain/use_case/common/segments/segments.usecase";
import {
    GetSignUpContractUseCase
} from "@dropDesk/domain/use_case/common/contracts/get_sign_up_contract.usecase";
import {GetTicketsGlobalConfigsUseCase} from "@dropDesk/domain/use_case/ticket/get_tickets_global_configs.usecase";
import {TicketConfig, TicketConfigType} from "@dropDesk/domain/entities/ticket/ticket_config";
import {NotificationServiceHandler} from "@dropDesk/presentation/app/notification_service_handler";
import {FeatureToggle} from "@dropDesk/data/clients/firebase_remote_configs_client";
import {PaymentServiceHandler} from "@dropDesk/presentation/app/iugu_service_handler";

configure({
    enforceActions: "always",
});

@injectable()
export class AppController {
    private readonly _getCurrentUserUseCase: GetCurrentUserUseCase;
    private readonly _getTicketsGlobalConfigsUseCase: GetTicketsGlobalConfigsUseCase;
    private readonly _logoutByAdminUseCase: LogoutByAdminUseCase;
    private readonly _logoutUseCase: LogoutUseCase;
    private readonly _getThemeByUniqueCodeCompanyUseCase: GetThemeByUniqueCodeCompanyUseCase;
    private readonly _segmentsUseCase: SegmentsUseCase;
    private readonly _getSignUpContractUseCase: GetSignUpContractUseCase;

    constructor(
        @inject(GetCurrentUserUseCase) getCurrentUserUseCase: GetCurrentUserUseCase,
        @inject(GetTicketsGlobalConfigsUseCase) getTicketsGlobalConfigsUseCase: GetTicketsGlobalConfigsUseCase,
        @inject(LogoutByAdminUseCase) logoutByAdminUseCase: LogoutByAdminUseCase,
        @inject(LogoutUseCase) logoutUseCase: LogoutUseCase,
        @inject(GetThemeByUniqueCodeCompanyUseCase) getThemeByUniqueCodeCompanyUseCase: GetThemeByUniqueCodeCompanyUseCase,
        @inject(SegmentsUseCase) segmentsUseCase: SegmentsUseCase,
        @inject(GetSignUpContractUseCase) getSignUpContractUseCase: GetSignUpContractUseCase,
    ) {
        makeObservable(this);
        this._getCurrentUserUseCase = getCurrentUserUseCase;
        this._getTicketsGlobalConfigsUseCase = getTicketsGlobalConfigsUseCase;
        this._logoutUseCase = logoutUseCase;
        this._logoutByAdminUseCase = logoutByAdminUseCase;
        this._getThemeByUniqueCodeCompanyUseCase = getThemeByUniqueCodeCompanyUseCase;
        this._segmentsUseCase = segmentsUseCase;
        this._getSignUpContractUseCase = getSignUpContractUseCase;
    }

    @observable
    theme: ThemeEntity = getLocalTheme();

    @observable
    user!: UserEntity | null;

    @observable
    loading: boolean = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    audioPlaybackRate: number = parseFloat(localStorage.getItem(process.env.PLAYBACKRATE as string) ?? "1");

    @observable
    uniqueCode: string | null = null;

    @observable
    role: UserRole | null = null;

    @observable
    logoBrand: string = logo;

    @observable
    ticketsGlobalConfigs: TicketConfig[] = [];

    @observable
    notificationServiceInitialized: boolean = false;

    @observable
    paymentServiceInitialized: boolean = false;

    @observable
    showingHeaderSubscriptionInfo: boolean = false;

    @observable
    showingHeaderConfirmationAccount: boolean = false;

    @computed
    get ticketPriorityConfigs(): TicketConfig[] {
        return this.orderBy(
            this.ticketsGlobalConfigs.filter((config) => config.type === TicketConfigType.priority)
        );
    }

    @computed
    get ticketStatusConfigs(): TicketConfig[] {
        return this.orderBy(
            this.ticketsGlobalConfigs.filter((config) => config.type === TicketConfigType.status)
        );
    }

    @computed
    get ticketStatusContactConfigs(): TicketConfig[] {
        return this.orderBy(
            this.ticketsGlobalConfigs.filter((config) => config.type === TicketConfigType.status && config.extra?.visibleToContacts)
        );
    }

    @computed
    get heightShowingHeaderInfo(): number {
        return (this.showingHeaderConfirmationAccount || this.showingHeaderSubscriptionInfo) ? 36 : 0;
    }

    @observable
    segments: Array<OptionType<string>> | [] = [];

    @observable
    signUpContract: string | null = null;

    navigate?: NavigateFunction;

    @computed
    get isUserClient(): boolean {
        return !!this.uniqueCode || this.role === UserRole.userClient || this.user?.role == UserRole.userClient;
    }

    @action
    private orderBy(configs: TicketConfig[]) {
        return configs.sort((a, b) => (((a.extra?.order as number | undefined) ?? 0) - ((b.extra?.order as number | undefined) ?? 0)));
    }

    @action
    setSignUpContract(contract: string | null) {
        this.signUpContract = contract;
    }

    @action
    setShowingHeaderSubscriptionInfo(value: boolean) {
        this.showingHeaderSubscriptionInfo = value;
    }

    @action
    setShowingHeaderConfirmationAccount(value: boolean) {
        this.showingHeaderConfirmationAccount = value;
    }


    @action
    setNotificationServiceInitialized(value: boolean) {
        this.notificationServiceInitialized = value;
    }

    @action
    setPaymentServiceInitialized(value: boolean) {
        this.paymentServiceInitialized = value;
    }

    @action
    setTicketsGlobalConfigs(configs: TicketConfig[]) {
        this.ticketsGlobalConfigs = configs;
    }

    @action
    setSegments(segments: Array<OptionType<string>>) {
        this.segments = segments;
    }

    @action
    setPlayBlackRate(rate: number) {
        this.audioPlaybackRate = rate;
        localStorage.setItem(process.env.PLAYBACKRATE as string, rate.toString());
    }

    @action
    setRole(role: UserRole | null) {
        this.role = role;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    setTheme = (value: ThemeEntity) => {
        this.theme = value;
    }

    @action
    setUniqueCode = (uniqueCode: string | null) => {
        this.uniqueCode = uniqueCode;
    }

    @action
    setLogoBrand = (value: string | null) => {
        this.logoBrand = value ?? logo;
    }

    @action
    setUser = (value: UserEntity | null) => {
        this.user = value;
        if (value && value.themeObject) {
            this.setTheme(value.themeObject);
            localStorage.setItem(process.env.THEME as string, value.theme);
        }
    };

    @action
    async getTicketGlobalConfigs() {
        const configs = await this._getTicketsGlobalConfigsUseCase.call();
        this.setTicketsGlobalConfigs(configs);
    }

    @action
    private async initializeOneSignal() {
        try {
            if (!this.notificationServiceInitialized) {
                await NotificationServiceHandler.setup();
                this.setNotificationServiceInitialized(true);
            }
        } catch (error) {
            this.setNotificationServiceInitialized(true);
        }

    }

    @action
    private async initializePaymentService() {
        try {
            if (!this.paymentServiceInitialized) {
                await PaymentServiceHandler.setup();
                this.setPaymentServiceInitialized(true);
            }
        } catch (error) {
            console.error('error iugu', error);
            this.setNotificationServiceInitialized(true);
        }
    }

    @action
    async initialize(uniqueCode: string | null) {
        this.setUniqueCode(uniqueCode ?? this.uniqueCode);
        await FeatureToggle.initialize();

        await Promise.all([
            this.getTicketGlobalConfigs(),
            this.getThemeByUniqueCodeDependant(uniqueCode),
            this.initializeOneSignal(),
            this.initializePaymentService()
        ]);
    }

    @action
    async validateTicketGlobalConfigs() {
        if (this.ticketStatusConfigs.length === 0) {
            await this.getTicketGlobalConfigs();
        }
    }

    @action
    getThemeByUniqueCodeDependant(uniqueCode: string | null) {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (uniqueCode) {
                    this.startLoading("signIn.loading");
                    const theme = await this._getThemeByUniqueCodeCompanyUseCase.call(parseInt(uniqueCode));
                    if (theme) {
                        this.setTheme(ThemeEntity.getThemeByString(`light-${theme.name}`));
                        this.setLogoBrand(theme.logoBrandUrl);
                    }
                    this.setRole(UserRole.userClient);
                    this.stopLoading();
                }
                return resolve();
            } catch (err: any) {
                if (uniqueCode) {
                    this.setUniqueCode(null);
                }
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                this.stopLoading();
                return reject(err);
            }
        });

    }

    @action
    getCurrentUser = async (): Promise<RoutesEnum> => {
        return new Promise<RoutesEnum>(async (resolve, reject) => {
            try {
                this.startLoading("signIn.loading");
                const user = await this._getCurrentUserUseCase.call();
                if (!this.ticketsGlobalConfigs.length) {
                    await this.getTicketGlobalConfigs();
                }
                this.setUser(user);
                this.setRole(user ? user.role : this.role);
                this.stopLoading();
                return resolve(user?.verified ? user.role === UserRole.userClient ? RoutesEnum.tickets : RoutesEnum.dashboard : RoutesEnum.welcome);
            } catch (err: any) {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                this.stopLoading();
                return reject(err);
            }
        });
    };

    @action
    getSegments = async (): Promise<Array<OptionType<string>>> => {
        return new Promise<Array<OptionType<string>>>(async (resolve, reject) => {
            try {
                const segments = await this._segmentsUseCase.call();
                this.setSegments(segments);
                return resolve(segments);
            } catch (err: any) {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                return reject(err);
            }
        });
    };

    @action
    getSignUpContract = async (): Promise<string> => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const signUpContract = await this._getSignUpContractUseCase.call();
                this.setSignUpContract(signUpContract);
                return resolve(signUpContract);
            } catch (err: any) {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                return reject(err);
            }
        });
    };

    @action
    initializeCreateAccount = async (): Promise<void> => {
        try {
            if (this.segments.length === 0 && !this.signUpContract) {
                this.startLoading("signIn.default");
                const response = await Promise.all([
                    this._getSignUpContractUseCase.call(),
                    this._segmentsUseCase.call()
                ]);
                this.setSignUpContract(response[0]);
                this.setSegments(response[1]);
                this.stopLoading();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getLocalTheme = (): void => {
        const localTheme = getLocalTheme();
        this.setTheme(localTheme);
    }

}
