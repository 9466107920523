import {observer} from "mobx-react";
import React from "react";
import {
    BodyData,
    ContainerRating,
    ContentDataDescriptions,
    ContentDataIcons, ContentDataTotalRating, ContentFooter,
    ContentHeader,
    ContentRating, Footer,
    Header, TextDescriptionRate, TextNso,
    TextRating, TextTotalRating, Title
} from "./styled";
import {ReportRateValueEntity} from "@dropDesk/domain/entities/reports/report_ticket_rate/report_ticket_rate.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import CountStars
    from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui/panel_rating/count_stars";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";


const PanelRating = observer((
    {
        totalCountValue,
        average,
        fiveRating,
        fourRating,
        threeRating,
        twoRating,
        oneRating,
        loading
    }:
        {
            totalCountValue: number,
            average: number,
            fiveRating: ReportRateValueEntity | undefined,
            fourRating: ReportRateValueEntity | undefined,
            threeRating: ReportRateValueEntity | undefined,
            twoRating: ReportRateValueEntity | undefined,
            oneRating: ReportRateValueEntity | undefined,
            loading: boolean
        }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;


    return (
        <ContainerRating>
            <ContentRating background={colors.onBackground} border={colors.border}>

                <Header>
                    <Title color={colors.text}>Avaliações por período ({totalCountValue})</Title>
                </Header>

                <BodyData>
                    {loading &&
                        <div style={{display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center'}}>
                            <SpinLoading
                                size={"large"}
                                color={colors.hintInverse}
                                tip={"Carregando"}
                            />
                        </div>
                    }
                    {!loading && fiveRating && fourRating && threeRating && twoRating && oneRating &&
                        <>
                            <CountStars countStar={5} rating={fiveRating}/>
                            <CountStars countStar={4} rating={fourRating}/>
                            <CountStars countStar={3} rating={threeRating}/>
                            <CountStars countStar={2} rating={twoRating}/>
                            <CountStars countStar={1} rating={oneRating}/>
                        </>
                    }

                </BodyData>


            </ContentRating>
        </ContainerRating>
    )
})
export default PanelRating;
