import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserEntity} from "../../entities/user/user.entity";
import {AuthRepository} from "@dropDesk/domain/repositories/auth/auth.repository";


@injectable()
export class LoginServerUseCase {
    private _repository: AuthRepository;

    constructor(
        @inject(AuthRepository) repository: AuthRepository
    ) {
        this._repository = repository;
    }

    public call(): Promise<Record<string, boolean>> {
        return this._repository.loginServer();
    }
}
