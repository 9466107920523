import React, {useEffect, useRef, useState} from "react";
import {
    Container, Content, PhoneInputNumber
} from "./styled";

import 'react-phone-number-input/style.css';
import {InputProps} from "@dropDesk/presentation/components/inputs/input_phone_number/input_phone_number";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import LabelInput from "@dropDesk/presentation/components/inputs/input_with_border/label";
import {ContentButtons, ContentLabel} from "@dropDesk/presentation/components/inputs/input_with_border/styled";
import ButtonTopTwo from "@dropDesk/presentation/components/inputs/input_with_border/button_top_two";
import ButtonTopOne from "@dropDesk/presentation/components/inputs/input_with_border/button_top_one";
import LeftIcon from "@dropDesk/presentation/components/inputs/input_with_border/left_icon";
import RightIcon from "@dropDesk/presentation/components/inputs/input_with_border/right_icon";
import MessageError from "@dropDesk/presentation/components/inputs/input_with_border/message_error";
import RemoveFilter from "@dropDesk/presentation/components/inputs/input_with_border/remove_filter";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";

export default function InputPhoneNumber(props: InputProps) {

    const [focused, setFocused] = useState(false);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const useBorder = props.usedBorder === false ? false : true;

    const ref = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (ref.current && props.autoFocus) {
            ref.current?.focus();
        }
    }, []);

    return (
        <Container marginLeft={props.marginLeft ?? 0} marginTop={props.marginTop ?? 0}>

            <ContentLabel>
                {!!props.label && <LabelInput required={props.required} label={props.label}/>}
                <ContentButtons>

                    {props.useTwoButtonRight && props.nameIconButtonRight &&
                        <ButtonTopTwo
                            tooltipTwoButtonRight={props.tooltipTwoButtonRight}
                            onClickTwoButtonRight={props.onClickTwoButtonRight}
                            disabledButtonRight={props.disabledButtonRight}
                            nameIconButtonRight={props.nameIconButtonRight}
                            labelTwoButtonRight={props.labelTwoButtonRight}
                        />}

                    {props.useButtonRight && props.nameIconButtonRight &&
                        <ButtonTopOne
                            tooltipButtonRight={props.tooltipButtonRight}
                            onClickButtonRight={props.onClickButtonRight}
                            disabledButtonRight={props.disabledButtonRight}
                            nameIconButtonRight={props.nameIconButtonRight}
                            labelButtonRight={props.labelButtonRight}
                        />}
                </ContentButtons>
            </ContentLabel>
            <Content
                onClick={props.onClick && !props.disabled ? props.onClick : () => {
                }}
                background={props.backgroundColor ?? colors.backgroundInput}
                usedBorder={useBorder}
                size={props.size}
                accent={colors.accent}
                border={colors.border}
            >
                {props.nameIconLeft &&
                    <LeftIcon
                        nameIconLeft={props.nameIconLeft}
                        iconLeftIsMain={props.iconLeftIsMain}
                        onClickLeftIcons={props.onClickLeftIcons}
                        sizeLeftIcons={props.sizeLeftIcons}
                        disabled={props.disabled}
                    />}

                <PhoneInputNumber
                    ref={ref}
                    defaultCountry="BR"
                    international={true}
                    size={props.size}
                    autoFocus={props.autoFocus ?? false}
                    name={props.name}
                    onFocus={() => {
                        setFocused(true);
                    }}
                    onBlur={() => {
                        setFocused(false);
                        if (props.onBlur) {
                            props.onBlur();
                        }
                    }}
                    onCopy={(event: any) => {
                        const value = event.target?.value ?? '';
                        event.clipboardData.setData('text/plain', removeMask(value));
                        event.preventDefault();
                    }}
                    style={{
                        cursor: props.readOnly && !props.disabled ? 'pointer' : props.disabled ? 'no-drop' : 'auto',
                        color: colors.text
                    }}
                    readOnly={props.readOnly ?? false}
                    color={colors.text}
                    placeholdercolor={colors.hint}
                    disabled={props.disabled}
                    type={props.type ?? 'text'}
                    placeholder={props.placeHolder}
                    value={props.value}
                    onChange={(value?: string) => props.onChange(value)}
                    autoComplete='off' //valor passado para não pegar preenchimento automatico do navegador
                />

                {props.useRemoveFilter && !props.disabled &&
                    <RemoveFilter
                        onClickRemoveFilter={props.onClickRemoveFilter}
                    />}

                {props.nameIconRight && (
                    <RightIcon
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        onClick={props.onClick}
                        onClickRightIcons={props.onClickRightIcons}
                        size={props.size}
                        nameIconRight={props.nameIconRight}
                        sizeRightIcons={props.sizeRightIcons}
                    />
                )}


            </Content>
            <MessageError messageError={props.messageError}/>


        </Container>
    );
}
