import styled from "styled-components";

type ContainerType = {
    heightShowingHeaderInfo: number;
};

type IconOpsType = {
    color: string;
}


export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  position: relative;
  overflow: auto;
  height: calc(100vh - 249px - ${props => `${props.heightShowingHeaderInfo}px`});
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
`;

export const ContentCheckBoxAndInput = styled.div`
  display: flex;
  width: 100%;
  heigh: 125px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContainerPermission = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;
  align-items: flex-start;
`;
export const ContentPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
`;
export const TextPermission = styled.span<IconOpsType>`
  font-size: 16px;
  color: ${props => props.color};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;
export const ContentMessages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const LabelInputChat = styled.span<IconOpsType>`
  color: ${props => props.color};
  font-size: 14px;
  min-height: 40px;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const ContentCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
