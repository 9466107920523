import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

@injectable()
export class RestoreUserUseCase {
  private _repository: UserRepository;

  constructor(@inject(UserRepository) repository: UserRepository) {
    this._repository = repository;
  }

  public call(users: UserEntity[]): Promise<void> {
    return this._repository.restore(users);
  }
}
