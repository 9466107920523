import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type TextType = {
    color: string;
}
type ContainerType = {
    opacity: number;
    background: string;
}
export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100% !important;
  width: 100%;
  z-index: 99999;
  align-items: center;
  justify-content: center;
  background: ${props => hexToRGBA(props.background, props.opacity)};
`;
export const ContentDescription = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
`;
export const Description = styled.span<TextType>`
  color: ${props => props.color};
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 1px;
`;

