import {observer} from "mobx-react";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Body,
    Container,
    Footer,
    PaddingFilters
} from "@dropDesk/presentation/pages/ticket/ui/search/search_bar/filters/styled";
import FilterButton from "@dropDesk/presentation/components/filters/filter_button";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useRef, useState} from "react";

const Filters = observer(
    ({
         filterStatus,
         filterPriority,
         setFilterStatus,
         setFilterPriority,
         loading,
         onSearch,
         setListOnlySectorEmpty,
         listOnlySectorEmpty
     }: {
        setFilterStatus: (filter: string | null, withList?: boolean) => void,
        setFilterPriority: (filter: string | null, withList?: boolean) => void,
        filterStatus: string | null,
        filterPriority: string | null,
        onSearch: (searchParam?: string) => void,
        loading: boolean,
        setListOnlySectorEmpty: (value: boolean, withList?: boolean) => void,
        listOnlySectorEmpty: boolean
    }) => {

        const [visible, setVisible] = useState<boolean>(false);
        const [zIndex, setZIndex] = useState<number>(0);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const timeoutRef = useRef<number | undefined>(undefined);

        const handleClickChange = (open: boolean) => {
            if (open) {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                setZIndex(1300);
            } else {
                timeoutRef.current = window.setTimeout(() => {
                    setZIndex(0);
                }, 200);
            }
            setVisible(open);
        };

        return (
            <FilterButton
                hasFilter={(!!filterStatus || !!filterPriority || !!listOnlySectorEmpty)}
                loading={loading}
                open={visible}
                onOpenChange={handleClickChange}
                zIndex={zIndex}
                content={
                    <Container border={colors.border}>
                        <Body>
                            <PaddingFilters>
                                <SelectSearchMain<string>
                                    size={40}
                                    width={250}
                                    useRemoveFilter={!!filterStatus}
                                    label={'Filtrar por Status'}
                                    placeHolder={"Selecione um status"}
                                    options={appController.isUserClient ? appController.ticketStatusContactConfigs.map((item) => item.toOptionType()) : appController.ticketStatusConfigs.map((item) => item.toOptionType())}
                                    removeSort={true}
                                    onClickRemoveFilter={() => {
                                        setFilterStatus(null);
                                    }}
                                    onChange={(value) => {
                                        setFilterStatus(value);
                                    }}
                                    value={filterStatus ?? null}
                                />
                            </PaddingFilters>
                            {!appController.isUserClient &&
                                <PaddingFilters>
                                    <SelectSearchMain<string>
                                        size={40}
                                        width={250}
                                        useRemoveFilter={!!filterPriority}
                                        label={'Filtrar por Prioridade'}
                                        placeHolder={"Selecione uma prioridade"}
                                        options={appController.ticketPriorityConfigs.map((item) => item.toOptionType())}
                                        removeSort={true}
                                        onClickRemoveFilter={() => {
                                            setFilterPriority(null);
                                        }}
                                        onChange={(value) => {
                                            setFilterPriority(value);
                                        }}
                                        value={filterPriority ?? null}

                                    />
                                </PaddingFilters>
                            }
                            {!appController.isUserClient && appController.user?.permissionAdmin && <PaddingFilters>
                                <SelectSearchMain<string>
                                    size={40}
                                    width={250}
                                    useRemoveFilter={false}
                                    label={'Somente atendimentos sem setor'}
                                    options={[{value: 'false', label: 'Não'}, {value: 'true', label: 'Sim'}]}
                                    removeSort={true}
                                    onChange={(value) => {
                                        setListOnlySectorEmpty(value === 'true');
                                    }}
                                    value={String(listOnlySectorEmpty)}
                                    textTooltip={`Filtro utilizado para pesquisar os contatos que entraram em 
                                    contato pelo whatsapp e não selecionaram um setor`}
                                />
                            </PaddingFilters>}
                        </Body>
                        <Footer>
                            <ButtonNew
                                label={'limpar filtros'}
                                onClick={() => {
                                    setFilterStatus(null, false);
                                    setFilterPriority(null, false);
                                    setListOnlySectorEmpty(false, false);
                                    onSearch();
                                    setVisible(false);
                                }}
                                useIcon={false}
                                sizeLeftIcon={15}
                                disabled={loading}
                                radius={25}
                            />
                        </Footer>
                    </Container>
                }/>

        )
    })
export default Filters;
