import {useState} from "react";

export const useModalWhatsApp = () => {

    const [visibleModalLogout, setVisibleModalLogout] = useState<boolean>(false);
    const [visibleModalRestoreMessage, setVisibleModalRestoreMessage] = useState<boolean>(false);
    const [visibleModalConnect, setVisibleModalConnect] = useState<boolean>(false);
    const [visibleModalSearchSector, setVisibleModalSearchSector] = useState<boolean>(false);
    const [visibleModalDuplicateConnection, setVisibleModalDuplicateConnection] = useState<boolean>(false);

    return {
        visibleModalLogout, setVisibleModalLogout,
        visibleModalConnect, setVisibleModalConnect,
        visibleModalRestoreMessage, setVisibleModalRestoreMessage,
        visibleModalSearchSector, setVisibleModalSearchSector,
        visibleModalDuplicateConnection, setVisibleModalDuplicateConnection
    }
}
