import {observer} from "mobx-react";
import React from "react";
import {
    ContentExportImport, ContentFilter,
    ContentInput,
    ContentInputAndListableResponseStandardDeleted
} from "./styled";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useBetween} from "use-between";
import {
    useModalSearchResponseStandard
} from "@dropDesk/presentation/pages/response_standard/modals/handle_change_visible";
import {ContentInputSearch} from "@dropDesk/presentation/pages/ticket/ui/search/search_bar/styled";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const SearchBar = observer(
    ({
         handleVisibleModalNewResponseStandard,
         onSearch,
         visibleInputSearch,
         visibleButtonsOfActions,
         visibleButtonDeleted,
         visibleButtonRestore,
         loading,
     }: {
        onSearch: (searchParam: string) => void,
        handleVisibleModalNewResponseStandard: (visible: boolean, id: string) => void
        visibleInputSearch: boolean,
        visibleButtonsOfActions: boolean,
        visibleButtonDeleted: boolean,
        visibleButtonRestore: boolean,
        loading: boolean,
    }) => {

        const isNewRegister = 'new';

        const {
            setVisibleModalDelete,
            setVisibleModalRestore,
        } = useBetween(useModalSearchResponseStandard);

        return (
            <ContentFilter>
                <ContentInputAndListableResponseStandardDeleted>
                    <ContentInput>
                        {visibleInputSearch &&
                            <ContentInputSearch>
                                <InputSearchGeneric
                                    inputSearchPlaceholder="Pesquise pela resposta"
                                    loading={loading}
                                    onSearch={(value) => onSearch(value)}
                                    routeName={RoutesEnum.rapidresponse}
                                />
                            </ContentInputSearch>}
                        {visibleButtonDeleted && <ButtonNew
                            marginLeft={0}
                            label={'Excluir'}
                            onClick={() => setVisibleModalDelete(true)}
                            nameLeftIcon={ICONS_DROPDESK.delete}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}
                        {visibleButtonRestore && <ButtonNew
                            marginLeft={0}
                            label={'Restaurar'}
                            onClick={() => setVisibleModalRestore(true)}
                            nameLeftIcon={ICONS_DROPDESK.restore}
                            sizeLeftIcon={16}
                            disabled={loading}
                        />}

                    </ContentInput>

                </ContentInputAndListableResponseStandardDeleted>
                <ContentExportImport>
                    {visibleButtonsOfActions && <ButtonNew
                        marginLeft={0}
                        label={'Nova Resposta'}
                        onClick={() => {
                            handleVisibleModalNewResponseStandard(true, isNewRegister)
                        }}
                        nameLeftIcon={''}
                        sizeLeftIcon={16}
                        disabled={loading}
                        isButtonMain={true}
                    />}
                </ContentExportImport>
            </ContentFilter>
        )
    })
export default SearchBar;
