import React, {useEffect} from "react";
import {useInjection} from "inversify-react";
import {SectorController} from "@dropDesk/presentation/pages/sector/controller/sector.controller";
import {useNavigate, useParams} from "react-router-dom";
import {
    Container, FormSector, ContentFormSector
} from "./styled";
import {observer} from "mobx-react";
import InputsFormSectors from "@dropDesk/presentation/pages/sector/ui/form/inputs";
import HeaderFormSectors from "@dropDesk/presentation/pages/sector/ui/form/header_form";
import ModalsFormSector from "@dropDesk/presentation/pages/sector/ui/form/modals";
import FooterFormSectors from "@dropDesk/presentation/pages/sector/ui/form/footer_form";
import GenericScreenNotFound from "@dropDesk/presentation/components/generic_screen_not_found";
import {useValidatorInputSectors} from "@dropDesk/presentation/pages/sector/ui/form/inputs/validator";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {AppController} from "@dropDesk/presentation/app/app.controller";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";

const FormSectors = observer((
    {
        handleSubmitExternalScreen,
        backPreviousScreenExternalScreen,
    }: {
        handleSubmitExternalScreen?: (sector: SectorEntity) => void,
        backPreviousScreenExternalScreen?: () => void

    }) => {

    const controller = useInjection(SectorController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const {id} = useParams();
    const isNewRegister = 'new';
    const navigate = useNavigate();
    const {t} = useTranslation();

    const initialize = async () => {
        if (id === isNewRegister || !!handleSubmitExternalScreen) {
            controller.makeSector();
        } else {
            controller.getSector(id ?? isNewRegister);
        }
    }


    useEffect(() => {
        initialize();
    }, []);

    const {isValidDataForm} = useValidatorInputSectors(controller.sector);

    const onSuccess = (key: string) => {
        if (handleSubmitExternalScreen && controller.sector) {
            handleSubmitExternalScreen(controller.sector);
        }
        toastMessage.success(t(key));
        backPreviousScreen();
    }

    const handleSubmit = async () => {

        if (isValidDataForm) {
            await controller.set(onSuccess);
        }

    };

    const backPreviousScreen = () => {
        if (!backPreviousScreenExternalScreen) {
            navigate(-1)
        } else {
            backPreviousScreenExternalScreen()
        }
    };


    return (
        <Container background={colors.onBackground}>

            <ContentFormSector style={{padding: handleSubmitExternalScreen ? 0 : 16}}>

                {!controller.sectorNotFound &&
                    <FormSector background={colors.onBackground} border={colors.border}>

                        <HeaderFormSectors
                            loading={controller.loading}
                            backPreviousScreen={backPreviousScreen}
                            isUnsaved={id === isNewRegister}
                        />

                        <InputsFormSectors
                            onUpdate={(sector) => controller.setSector(sector)}
                            sector={controller.sector}
                            loading={controller.loading}
                        />

                        <FooterFormSectors
                            onUpdate={(sector) => controller.setSector(sector)}
                            sector={controller.sector}
                            disabled={!isValidDataForm}
                            loading={controller.loading}
                            handleSubmit={handleSubmit}
                            backPreviousScreen={backPreviousScreen}
                            createdAt={controller.sector?.createdAt ? `Criado em ${displayDateToLocale(controller.sector.createdAt)}` : undefined}
                        />

                        <ModalsFormSector
                            loading={controller.loading}
                            onDeleteSector={async () => {
                                controller.setRowSelectionSectors([controller.sector!]);
                                await controller.delete(onSuccess)
                            }}
                        />
                    </FormSector>}

                {controller.sectorNotFound &&
                    <GenericScreenNotFound
                        textNotFound={'Oops, Setor não encontrado ...'}
                        textHeaderForm={"Voltar"}
                    />
                }


            </ContentFormSector>
            {controller.loading && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}

        </Container>
    );
});

export default FormSectors;
