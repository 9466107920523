import {observer} from "mobx-react";
import React from "react";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";
import {useNavigate} from "react-router-dom";

const HeaderFormEvaluations = observer(
  ({
     loading
   }: {
    loading: boolean
  }) => {

    const navigate = useNavigate();

    return (
      <HeaderForm
        letter={"Dados da avaliação"}
        onRequestClose={() => navigate(-1)}
        disabled={loading}
      />
    )
  })
export default HeaderFormEvaluations;
