import {
    ContainerListTask,
    TextTask,
    ContainerButtonHeader
} from "./styled";
import {ListSectorType} from "@dropDesk/presentation/components/lists/list_sectors_linked/list_sectors";
import {Tooltip} from "antd";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

export default function ListSectorsLinked(props: ListSectorType) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerListTask borderColor={props.letterSecondary}>
            <Tooltip overlayStyle={{whiteSpace: 'pre-line'}} title={'Remover do setor'}>
                <ContainerButtonHeader
                    background={colors.buttonOnBackground}
                    accent={colors.accent}
                >
                    <DropDeskIcon
                        color={props.colorLetterDeleted}
                        onClick={() => props.onClickUnlinked(props.sector)}
                        icon={ICONS_DROPDESK.delete}
                        style={{cursor: 'pointer'}}
                        useDarkenInHover={true}
                    />
                </ContainerButtonHeader>
            </Tooltip>
            <TextTask color={props.letterMain}>
                {props.sector.name}
            </TextTask>


        </ContainerListTask>
    );
}
