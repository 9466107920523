import styled from "styled-components";

type SquareColoredType = {
    color: string;
}

export const ContainerFooterGarbage = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
`;

export const ContentExplicationColor = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const ContainerColorSquare = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const ContentButtonGarbage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-right: 15px;

`;
export const ContentColorSquare = styled.div`
  display: flex;
  flex-direction: row;
  height: 33px;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 0 0;
`;
export const SquareColored = styled.div<SquareColoredType>`
  display: flex;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

export const TextColored = styled.span<SquareColoredType>`
  display: flex;
  color: ${(props) => props.color};
  margin-left: 5px;
  font-size: 15px;
`;
