import {observer} from "mobx-react";
import React from "react";
import {ContentData, ContentInputs, SubContentInputs, ContentDataInput, ContentInput} from "./styled";
import {useValidatorInputTickets} from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/validator";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {useBetween} from "use-between";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {removeMaskStaticTelephoneNumber} from "@dropDesk/utils/helpers/string_helper";
import {useModalCreateTicket} from "@dropDesk/presentation/pages/ticket/ui/form_create/modals/handle_change_visible";
import {Tooltip} from "antd";
import InputsProblemDescription
    from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/inputs_problem_description";
import InputDateDue from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/input_date_due";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const InputsFormCreateTickets = observer(
    ({
         ticket,
         onUpdate,
         loading,
         isEdit,
         handleRemoveClient,
         useProblemStatic,
         permissionScreenClients,
         isNewChat
     }: {
        ticket: TicketEntity,
        onUpdate: (ticket: TicketEntity) => void,
        loading: boolean,
        useProblemStatic: boolean,
        isEdit?: boolean,
        permissionScreenClients?: boolean,
        handleRemoveClient: () => void
        isNewChat?: boolean,
    }) => {

        const appController = useInjection(AppController);

        const {
            setVisibleModalSearchContacts,
            setVisibleModalSearchClient,
            setVisibleModalSearchUser,
            setVisibleModalSearchSector,
            setVisibleModalTicketDescriptionStatic,
            setVisibleModalCreateClient,
        } = useBetween(useModalCreateTicket);

        const {
            errorMessageClient, errorMessageContact, errorMessageSector, errorMessageDateDue, errorMessageAttendant,
            errorMessageDescription
        } = useValidatorInputTickets(ticket);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const _ticket = ticket.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ?
                        event.target.checked : event.target.name === "telephone" ?
                            removeMaskStaticTelephoneNumber(event.target.value.replace(/\D/g, "")) :
                            event.target.value,

                replaceDateDueIfNull: event.target.name === "dateDue",
            });
            onUpdate(_ticket);

        };

        return (
            <ContentInputs>
                <SubContentInputs>
                    <ContentData>
                        {!appController.isUserClient &&
                            <ContentDataInput>
                                <ContentInput style={{marginRight: 20}}>
                                    <InputWithBorder
                                        required={true}
                                        name='client'
                                        size={37}
                                        onClick={() => {
                                            if (!isEdit && !isNewChat) {
                                                setVisibleModalSearchClient(true);
                                            }
                                        }}
                                        useRemoveFilter={(!!ticket.userClient && !isNewChat)}
                                        onClickRemoveFilter={handleRemoveClient}
                                        readOnly={true}
                                        disabled={(loading || !!isEdit || isNewChat)}
                                        disabledButtonRight={loading}
                                        placeHolder={"Selecione a empresa"}
                                        nameIconRight={ICONS_DROPDESK.downArrow}
                                        sizeRightIcons={14}
                                        marginRightRightIcons={10}
                                        messageError={errorMessageClient}
                                        label={"Empresa"}
                                        value={ticket.client?.socialName ?? ''}
                                        useButtonRight={isNewChat ? false : permissionScreenClients}
                                        labelButtonRight={isEdit ? "Editar" : "Nova empresa"}
                                        onClickButtonRight={() => {
                                            setVisibleModalCreateClient(true);
                                        }}
                                        onChange={() => {
                                        }}
                                    />
                                </ContentInput>
                                <ContentInput>
                                    <InputWithBorder
                                        required={true}
                                        name='contact'
                                        size={37}
                                        onClick={() => setVisibleModalSearchContacts(true)}
                                        readOnly={true}
                                        disabled={loading || !!isEdit}
                                        placeHolder={"Selecione o contato"}
                                        nameIconRight={ICONS_DROPDESK.downArrow}
                                        messageError={errorMessageContact}
                                        sizeRightIcons={14}
                                        marginRightRightIcons={10}
                                        label={"Contato"}
                                        value={ticket.userClient?.name ?? ''}
                                        useButtonRight={false}
                                        onChange={() => {
                                        }}
                                    />
                                </ContentInput>
                            </ContentDataInput>}
                        <ContentDataInput>
                            <ContentInput style={{marginRight: appController.isUserClient ? 0 : 20}}>
                                <InputWithBorder
                                    required={true}
                                    name='sector'
                                    size={37}
                                    onClick={() => setVisibleModalSearchSector(true)}
                                    readOnly={true}
                                    disabled={loading || !!isEdit}
                                    placeHolder={"Selecione o setor"}
                                    nameIconRight={ICONS_DROPDESK.downArrow}
                                    messageError={errorMessageSector}
                                    sizeRightIcons={14}
                                    marginRightRightIcons={10}
                                    label={"Setor"}
                                    value={ticket.sector?.realName(appController.user!) ?? ''}
                                    useButtonRight={false}
                                    onChange={() => {
                                    }}
                                />
                            </ContentInput>
                            {!appController.isUserClient && <Tooltip
                                title={!ticket.sector ? "Selecione um setor para poder selecionar um atendente." : ""}>
                                <ContentInput>
                                    <InputWithBorder
                                        name='userAttendant'
                                        size={37}
                                        onClick={() => setVisibleModalSearchUser(true)}
                                        readOnly={true}
                                        messageError={errorMessageAttendant}
                                        disabled={(loading || !!isEdit || !ticket.sector)}
                                        placeHolder={"Selecione o atendente"}
                                        nameIconRight={ICONS_DROPDESK.downArrow}
                                        sizeRightIcons={14}
                                        marginRightRightIcons={10}
                                        label={"Atendente"}
                                        value={ticket.attendant?.name ?? ''}
                                        onChange={() => {
                                        }}
                                    />
                                </ContentInput>
                            </Tooltip>}
                        </ContentDataInput>

                        {!appController.isUserClient && <ContentDataInput>
                            <ContentInput style={{marginRight: 20, marginBottom: 25}}>
                                <SelectSearchMain<string>
                                    required={true}
                                    size={37}
                                    name={'priority'}
                                    label={"Prioridade"}
                                    options={appController.ticketPriorityConfigs.map((item) => item.toOptionType())}
                                    removeSort={true}
                                    onChange={(value) => {
                                        handleAllChanges({
                                            target: {
                                                name: 'priority',
                                                value: value,
                                                checked: false,
                                                type: 'input'
                                            }
                                        })
                                    }}
                                    value={ticket.priority ?? null}
                                />
                            </ContentInput>
                            <ContentInput>
                                <InputDateDue
                                    ticket={ticket}
                                    errorMessageDateDue={errorMessageDateDue}
                                    handleAllChanges={handleAllChanges}
                                    minDate={new Date()}
                                />
                            </ContentInput>
                        </ContentDataInput>}
                        <InputsProblemDescription
                            ticket={ticket}
                            useProblemStatic={useProblemStatic}
                            loading={loading}
                            handleAllChanges={handleAllChanges}
                            onOpenModalTicketDescriptionStatic={() => setVisibleModalTicketDescriptionStatic(true)}
                            errorMessageDescription={errorMessageDescription}
                        />
                    </ContentData>
                </SubContentInputs>

            </ContentInputs>
        )
    })
export default InputsFormCreateTickets;
