import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {ChartReportTicketEntity} from "@dropDesk/domain/entities/reports/report_ticket/chart_ticket.entity";
import {GetReportTicketUseCase} from "@dropDesk/domain/use_case/reports/report_ticket/get_report_ticket.usecase";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {getEndHourCurrentDate, getInitialHourCurrentDate} from "@dropDesk/utils/helpers/date_helper";
import {
    ExportPdfReportTicketUseCase
} from "@dropDesk/domain/use_case/reports/report_ticket/export_pdf_report_ticket.usecase";
import {filtersEnum} from "@dropDesk/domain/entities/reports/report_ticket/report_ticket_enum";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {
    getPeriodAndLabelDisplay,
    initializeConfigurationLocalStorage,
    PeriodEnum,
    setGenericFiltersLocalStorage, setPeriodAndLabelDisplay
} from "@dropDesk/utils/helpers/common";

configure({
    enforceActions: "always",
});

@injectable()
export class ReportTicketController {
    private readonly _getReportTicket: GetReportTicketUseCase;
    private readonly _exportPdfReportTicketUseCase: ExportPdfReportTicketUseCase;

    constructor(
        @inject(GetReportTicketUseCase) getReportTicket: GetReportTicketUseCase,
        @inject(ExportPdfReportTicketUseCase) exportPdfReportTicketUseCase: ExportPdfReportTicketUseCase,
    ) {
        makeObservable(this);
        this._getReportTicket = getReportTicket;
        this._exportPdfReportTicketUseCase = exportPdfReportTicketUseCase;
    }

    @observable
    chartPriority: ChartReportTicketEntity[] | undefined

    @observable
    chartResolutionTime: ChartReportTicketEntity[] | undefined

    @observable
    chartLocation: ChartReportTicketEntity[] | undefined

    @observable
    chartStatus: ChartReportTicketEntity[] | undefined

    @observable
    filterPerson: filtersEnum | null = null;

    @observable
    user: UserEntity | null = null;

    @observable
    client: ClientEntity | null = null;

    @observable
    sector: SectorEntity | null = null;

    @observable
    period: Array<Date> | null = getPeriodAndLabelDisplay().range;

    @observable
    labelPeriod: PeriodEnum = getPeriodAndLabelDisplay().label;

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    showGroupedData = true;

    @action
    setShowGroupedData() {
        this.showGroupedData = !this.showGroupedData;
    }

    @action
    setPeriod(period: Array<Date> | null, label: PeriodEnum) {
        this.period = period;
        this.labelPeriod = label;
        setPeriodAndLabelDisplay({
            range: period!,
            label: label
        });
    }

    @action
    hasData(chart?: ChartReportTicketEntity[]): boolean {
        return !!chart && chart.filter(entry => entry.value > 0).length > 0;
    }

    @action
    setIdUser(user: UserEntity | null) {
        setGenericFiltersLocalStorage({reportTicketFilterUser: user});
        this.user = user;
    }

    @action
    setIdClient(client: ClientEntity | null) {
        setGenericFiltersLocalStorage({reportTicketFilterClient: client});
        this.client = client;
    }

    @action
    setIdSector(sector: SectorEntity | null) {
        setGenericFiltersLocalStorage({reportTicketFilterSector: sector});
        this.sector = sector;
    }

    @action
    setFilterPerson(person: filtersEnum | null) {
        setGenericFiltersLocalStorage({reportTicketFilterPerson: person});
        this.filterPerson = person;
    }

    @action
    removeFilter() {
        this.setIdUser(null);
        this.setIdClient(null);
        this.setIdSector(null);
    }

    @action
    removeAllData() {
        this.setChartStatus([]);
        this.setChartResolutionTime([]);
        this.setChartLocation([]);
        this.setChartPriority([])
    }


    @action
    setChartPriority(chartPriority: ChartReportTicketEntity[]) {
        this.chartPriority = chartPriority;
    }


    @action
    setChartResolutionTime(chartResolutionTime: ChartReportTicketEntity[]) {
        this.chartResolutionTime = chartResolutionTime;
    }

    @action
    setChartLocation(chartLocation: ChartReportTicketEntity[]) {
        this.chartLocation = chartLocation;
    }

    @action
    setChartStatus(chartStatus: ChartReportTicketEntity[]) {
        this.chartStatus = chartStatus;
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    initialize() {
        const routeName = RoutesEnum.reporttickets;
        const {filters} = initializeConfigurationLocalStorage(routeName, false);
        this.filterPerson = filters.reportTicketFilterPerson!;
        this.user = filters.reportTicketFilterUser!;
        this.client = filters.reportTicketFilterClient!;
        this.sector = filters.reportTicketFilterSector!;
        this.getReportTicket();
    }

    @action
    initializeDashboard() {
        this.getReportTicket();
    }

    @action
    setAllDataReportTicket(
        chartTicketsPriority: ChartReportTicketEntity[], chartTicketsResolutionTime: ChartReportTicketEntity[],
        chartTicketsLocation: ChartReportTicketEntity[], chartTicketsStatus: ChartReportTicketEntity[]
    ) {
        this.setChartPriority(chartTicketsPriority);
        this.setChartResolutionTime(chartTicketsResolutionTime);
        this.setChartLocation(chartTicketsLocation);
        this.setChartStatus(chartTicketsStatus);
    }

    @action
    getReportTicket = async (): Promise<void> => {
        try {
            if (this.period && this.period[0] && this.period[1]) {
                this.removeAllData();
                this.startLoading();
                const response = await this._getReportTicket.call(
                    {
                        startDate: getInitialHourCurrentDate(this.period[0]),
                        endDate: getEndHourCurrentDate(this.period[1])
                    },
                    this.user?.id ?? null,
                    this.sector?.id ?? null,
                    this.client?.id ?? null,
                );
                this.setAllDataReportTicket(response.chartTicketsPriority, response.chartTicketsResolutionTime, response.chartTicketsLocation, response.chartTicketsStatus)
                this.stopLoading();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };


    @action
    exportPdf = async (onSucess: (key: string) => void): Promise<void> => {
        try {
            if (this.period && this.period[0] && this.period[1]) {
                this.startLoading("report_ticket.export_pdf");
                await this._exportPdfReportTicketUseCase.call(
                    {
                        startDate: getInitialHourCurrentDate(this.period[0]),
                        endDate: getEndHourCurrentDate(this.period[1])
                    },
                    this.user?.id ?? null,
                    this.sector?.id ?? null,
                    this.client?.id ?? null,
                );
                onSucess("report_ticket.success.export_pdf");
                this.stopLoading();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

}
