import Cards, {CallbackArgument, Focused} from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

const CreditCard = (
    {
        number,
        expiry,
        cvc,
        name,
        focused,
        locale,
        placeholders,
        callback
    }: {
        cvc: string | number;
        expiry: string | number;
        focused?: Focused | undefined;
        name: string;
        number: string | number;
        locale?: {
            valid: string;
        } | undefined;
        placeholders?: {
            name: string;
        } | undefined;
        callback?: ((type: CallbackArgument, isValid: boolean) => void) | undefined;
    }
) => {

    return (
        <Cards
            number={number}
            expiry={expiry}
            cvc={cvc}
            name={name}
            focused={focused}
            placeholders={placeholders}
            locale={locale}
            callback={callback}
        />
    );
}
export default CreditCard;
