import {CompanyRemoteDataSource} from "@dropDesk/data/data_source/company/company_remote.datasource";
import {
    CompanyCreateAccount,
    CompanyEntity,
    CompanyExtraInfoEntity
} from "@dropDesk/domain/entities/company/company.entity";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {ThemeConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/theme_configuration.entity";
import {ClientCreateAccountEntity} from "@dropDesk/domain/entities/client/client_create_account_entity";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";

@injectable()
export class CompanyRepositoryImpl implements CompanyRepository {
    private _dataSource: CompanyRemoteDataSource;

    constructor(@inject(CompanyRemoteDataSource) dataSource: CompanyRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public set(company: CompanyEntity): Promise<CompanyEntity> {
        try {
            return this._dataSource.set(company);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }


    public findByPK(): Promise<CompanyEntity> {
        try {
            return this._dataSource.findByPK();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public createAccount(
        company?: CompanyEntity,
        sector?: SectorEntity,
        user?: UserEntity,
        clientCreateAccount?: ClientCreateAccountEntity
    ): Promise<CompanyCreateAccount> {
        try {
            return this._dataSource.createAccount(company, sector, user, clientCreateAccount);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public listActiveConnections(page: number, limit: number, searchParam: string): Promise<ListPaginationEntity<CompanyConnectionsEntity>> {
        try {
            return this._dataSource.listActiveConnections(page, limit, searchParam);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public listCompanySubscriptionLogs(page: number, limit: number): Promise<ListPaginationEntity<CompanyLogsEntity>> {
        try {
            return this._dataSource.listCompanySubscriptionLogs(page, limit);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    public setCompanyExtraInfo(extraInfo: CompanyExtraInfoEntity): Promise<CompanyEntity> {
        try {
            return this._dataSource.setCompanyExtraInfo(extraInfo);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public setPaymentMethod(input: PaymentMethodEntity): Promise<PaymentMethodEntity> {
        try {
            return this._dataSource.setPaymentMethod(input);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public getThemeByUniqueCode(uniqueCode: number): Promise<ThemeConfigurationEntity | null> {
        try {
            return this._dataSource.getThemeByUniqueCode(uniqueCode);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public updateLegacy(body: Record<string, any>): Promise<void> {
        try {
            return this._dataSource.updateLegacy(body);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

}
