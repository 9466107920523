import {
    ButtonMenu,
    Container,
    ContainerAvatar,
    ContainerData, ContentButtonMenu,
    ContentLineClamp, ContentMenu,
    ContentName, PopOver, TitlePopover,
} from "./styled";
import {Tooltip} from "antd";

import AvatarImage from "@dropDesk/presentation/components/avatar";
import React, {useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ContainerButtonHeader} from "@dropDesk/presentation/pages/chat/ui/contact_list/styled";

const ContactList = (
    {
        contact,
        searchParam,
        onClick,
        idContactSelected,
        onClickEditClient,
        onClickNewTicketChat,
        maxWidthContainer
    }: {
        contact: UserEntity
        searchParam: string,
        onClick: () => void,
        idContactSelected: string | null
        onClickEditClient: (idClient: string) => void
        onClickNewTicketChat: () => void
        maxWidthContainer: number
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const [visiblePopover, setVisiblePopover] = useState<boolean>(false);
    const containerAvatarWidth = 70;
    const containerThreeDots = 22;
    const containerLineClampName = maxWidthContainer - containerAvatarWidth - containerThreeDots;

    const handleClickChange = (open: boolean) => {
        setVisiblePopover(open);
    };

    return (
        <Container
            onClick={onClick}
            background={colors.onBackground}
            isDarkenBackground={contact.id === idContactSelected}
        >
            <ContainerAvatar width={containerAvatarWidth}>
                <AvatarImage
                    useCursorPointer={!!contact.urlImageProfile}
                    title={contact.name}
                    size={49}
                    url={contact.urlImageProfile}
                    round={true}
                    hasPreviewImage={true}
                    withBorder={true}
                    letterSize={18}
                />
            </ContainerAvatar>
            <ContainerData bordercolor={colors.border}>

                <ContentName>
                    <ContentLineClamp color={colors.text} height={25} width={containerLineClampName}>
                        <Tooltip placement="topLeft" title={contact.name}>
                            <>
                                <HighLightWords
                                    searchWords={searchParam}
                                    textToHighlight={contact.name}
                                />
                            </>
                        </Tooltip>
                    </ContentLineClamp>
                    <ContentLineClamp color={colors.hintInverse} height={25} width={containerLineClampName}>
                        <Tooltip placement="topLeft" title={contact.telephone ?? ''}>
                            <>
                                <HighLightWords
                                    searchWords={searchParam}
                                    textToHighlight={contact.telephone ?? ''}
                                    color={colors.hintInverse}
                                />
                            </>
                        </Tooltip>
                    </ContentLineClamp>
                </ContentName>

                <ContentMenu onClick={(event) => {
                    event.stopPropagation();
                }}>
                    <PopOver
                        bordercolor={colors.border}
                        shadow={colors.isDarkTheme ? SharedColors.white : SharedColors.black}
                        color={colors.backgroundInput}
                        placement="right"
                        trigger="click"
                        getPopupContainer={(element) => element}
                        title={<TitlePopover color={colors.text}>{contact.name}</TitlePopover>}
                        open={visiblePopover}
                        onOpenChange={(value) => handleClickChange(value)}
                        content={
                            <ContentButtonMenu>
                                <ButtonMenu
                                    background={'transparent'}
                                    color={colors.text}
                                    accent={colors.accent}
                                    onClick={(event) => {
                                        onClickNewTicketChat();
                                        handleClickChange(false);
                                        event.stopPropagation();
                                    }}
                                >
                                    Iniciar um atendimento
                                </ButtonMenu>
                                <ButtonMenu
                                    background={'transparent'}
                                    color={colors.text}
                                    accent={colors.accent}
                                    onClick={(event) => {
                                        onClickEditClient(contact.idClient!)
                                        handleClickChange(false);
                                        event.stopPropagation();
                                    }}
                                >
                                    Editar contato
                                </ButtonMenu>
                            </ContentButtonMenu>
                        }>
                        <ContainerButtonHeader
                            background={colors.buttonOnBackground}
                            accent={colors.accent}
                        >
                            <DropDeskIcon icon={ICONS_DROPDESK.threeDotsFill} size={18} color={colors.hint}/>
                        </ContainerButtonHeader>
                    </PopOver>
                </ContentMenu>

            </ContainerData>
        </Container>
    )
}
export default ContactList;
