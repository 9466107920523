import React, {useEffect} from 'react';
import {
    Container,
    ContentList,
    SubContentList,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import SearchBar from "@dropDesk/presentation/pages/client/ui/import/search/search_bar";
import TableImports from "@dropDesk/presentation/pages/client/ui/import/search/table";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useNavigate} from "react-router-dom";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalsRevertImportClient from "@dropDesk/presentation/pages/client/ui/import/search/modals";

const ImportClients = (observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const controller = useInjection(ClientController);
    const navigate = useNavigate();


    useEffect(() => {
        controller.initializeImports();
    }, []);

    return (
        <Container background={colors.onBackground}>

            <ContentList>

                <SearchBar
                    loading={controller.loading}
                    visibleButtonsOfActions={controller.rowSelectionClients.length === 0}
                    period={controller.period}
                    setPeriod={(dateArray, label) => controller.setPeriod(dateArray, label)}
                    listImports={() => controller.listImportsSubscription()}
                    navigateToImport={() => navigate(`${RoutesEnum.importclients}/new`)}
                    labelPeriodCustom={controller.labelPeriod}
                />

                <SubContentList>
                    <TableImports
                        loading={controller.loading}
                        tableImports={controller.tableImports}
                        getDataFromPage={(page) => controller.getDataFromPageImports(page)}
                        idRevert={controller.idRevert}
                    />

                </SubContentList>

                <ModalsRevertImportClient
                    loading={controller.loading}
                    revertImport={(id) => controller.revertImport(id)}
                />
            </ContentList>
            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    );
}))
export default ImportClients;
