import {ButtonActionChatMessage} from "@dropDesk/presentation/components/messages/message_box/styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

const ButtonActionMessage = (
    {
        onClick,
        nameIcon,
        position,
        isDarkTheme,
        spacePosition,
    }
        :
        {

            onClick: (event: React.MouseEvent<HTMLElement>) => void
            nameIcon: string,
            position: 'left' | 'right',
            isDarkTheme: boolean
            spacePosition: number;
        }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;


    return (
        <ButtonActionChatMessage
            onClick={(event) => onClick(event)}
            position={position}
            background={colors.accent}
            isDarkTheme={isDarkTheme}
            spacePosition={spacePosition}
            hint={colors.hint}
        >
            <DropDeskIcon icon={nameIcon} size={14} color={SharedColors.white}/>
        </ButtonActionChatMessage>
    )
}

export default ButtonActionMessage;
