import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {
    Container,
    ContainerChangePassword,
    ContainerInputs,
    Content,
    Section
} from "@dropDesk/presentation/pages/change_password/ui/styled";
import forgotImage from "@dropDesk/storage/images/forgot.png";
import activateImage from "@dropDesk/storage/images/activate.png";
import Header from "@dropDesk/presentation/components/headers/header_secondary";
import {FormEvent, useEffect, useRef, useState} from "react";
import HeaderForm from "@dropDesk/presentation/pages/change_password/ui/header_form";
import Inputs from "@dropDesk/presentation/pages/change_password/ui/inputs";
import ButtonChangePassword from "@dropDesk/presentation/pages/change_password/ui/button_change_password";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useInjection} from "inversify-react";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {useTranslation} from "react-i18next";
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {isValidPassword} from "@dropDesk/utils/helpers/validators";
import {observer} from "mobx-react";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";

import AnimatedImage from "@dropDesk/presentation/pages/change_password/ui/animate_image";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";

const ChangePassword = (observer(() => {


    const [password, setPassword] = useState<string | undefined>(undefined);
    const [token, setToken] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessagePassword, setErrorMessagePassword] = useState("");
    const [errorMessageConfirmPassword, setErrorMessageConfirmPassword] = useState("");
    const [errorMessageToken, setErrorMessageToken] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);


    const userController = useInjection(UserController);
    const loginController = useInjection(LoginController);
    const appController = useInjection(AppController);

    const {pathname} = useLocation();
    const [searchParams] = useSearchParams();
    const tokenParams = searchParams.get("token");
    const isConfirmation = !!searchParams.get("confirmation");
    const isFirstAccess: boolean = pathname.includes("first-access");
    const tokenInputVisible: boolean = !tokenParams;

    const colors = appController.theme.colorScheme;
    const navigate = useNavigate();
    const {t} = useTranslation();


    const initialize = () => {
        if (isConfirmation) {
            const token = tokenParams ? window.atob(tokenParams) : "";
            setToken(token);
        }
    }

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        validate();
    }, [password, confirmPassword, passwordVisible, token]);

    const setVerifyCurrentUser = () => {
        const userLogged = appController.user!.copyWith({
            verified: true
        });
        appController.setUser(userLogged);
    }

    function validate() {
        const validPassword = isValidPassword(password ?? '');
        const validConfirmPassword = isValidPassword(confirmPassword) && confirmPassword === password;
        setErrorMessagePassword("");
        setErrorMessageConfirmPassword("");
        setErrorMessageToken("");

        if (!token && tokenInputVisible) {
            setErrorMessageToken("Informe o código");
            setIsValidDataForm(false);
        } else if (token.length < 6 && tokenInputVisible) {
            setErrorMessageToken("Código inválido");
            setIsValidDataForm(false);
        } else if (!validPassword && !isConfirmation) {
            setErrorMessagePassword(!password ? "Informe uma senha" : "Senha menor que 6 dígitos");
            setIsValidDataForm(false);
        } else if (validPassword && !validConfirmPassword && !isConfirmation) {
            setErrorMessageConfirmPassword(!confirmPassword ? "Informe uma senha" : confirmPassword !== password ? "Senhas diferentes" : "Senha menor que 6 dígitos");
            setIsValidDataForm(false);
        } else {
            setIsValidDataForm(true);
        }
    };

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();
        if (isValidDataForm) {
            const renewToken = !!tokenParams ? window.atob(tokenParams) : token.trim();
            if (isFirstAccess) {
                await userController.firstAccess(renewToken, onSuccess, password);
            } else {
                await userController.changePassword(renewToken, password ?? '', onSuccess);
            }
        }
    }

    const onSuccess = async (key: string, email: string) => {
        if (email && password) {
            await loginController.login({email, password}, onSuccessLogin);
        } else {
            setVerifyCurrentUser();
            navigate(RoutesEnum.dashboard, {replace: true});
        }
        toastMessage.success(t(key));
    }

    const onSuccessLogin = async () => {
        const route = await appController.getCurrentUser();
        navigate(route, {replace: true});
    }


    return (
        <Container>

            <AnimatedImage
                imageSource={isFirstAccess ? activateImage : forgotImage}
                title={isFirstAccess ? "Ativação de conta" : "Esqueceu sua senha?"}
                subTitle={isFirstAccess ?
                    `Para que você tenha toda segurança dentro de nossa plataforma, precisamos nesse primeiro acesso,
           criar uma senha para você, informe sua nova senha.` :
                    "Para recuperar seu acesso á conta, preencha os campos."}
            />

            <Section background={colors.delicateBackground}>
                <Header background={colors.delicateBackground} color={colors.text}/>

                <Content background={colors.delicateBackground}>

                    <ContainerChangePassword onSubmit={handleSubmit} background={colors.delicateBackground}>

                        <HeaderForm
                            isConfirmation={isConfirmation}
                            textHeader={isFirstAccess ? "Ativação de conta" : "Recuperar Senha"}
                            hasDefaultToken={!!tokenParams}
                        />

                        <ContainerInputs>
                            <Inputs
                                validate={validate}
                                tokenEntity={{token, setToken, errorMessageToken}}
                                passwordEntity={{password, setPassword, errorMessagePassword}}
                                confirmPasswordEntity={{
                                    confirmPassword,
                                    setConfirmPassword,
                                    errorMessageConfirmPassword
                                }}
                                visiblePasswordEntity={{passwordVisible, setPasswordVisible}}
                                loading={(loginController.loading || appController.loading || userController.loading)}
                                tokenInputVisible={(tokenInputVisible || isConfirmation)}
                                isConfirmation={isConfirmation}
                                defaultValue={tokenParams ? window.atob(tokenParams) : ""}
                            />
                            <ButtonChangePassword
                                handleSubmit={handleSubmit}
                                loading={(loginController.loading || appController.loading || userController.loading)}
                                isValidForm={isValidDataForm}
                                textButton={isFirstAccess ? "Ativar conta" : "Alterar senha"}
                            />
                        </ContainerInputs>

                    </ContainerChangePassword>

                </Content>
            </Section>
            {(loginController.loading || appController.loading || userController.loading) && <DropDeskLoading
                height={250}
                description={loginController.loadingMessage ?? appController.loadingMessage ?? userController.loadingMessage}
            />}
        </Container>
    );
}));

export default ChangePassword;
