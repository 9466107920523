import "reflect-metadata";
import {injectable} from "inversify";

@injectable()
export abstract class ImageEditorDataSource {

}

@injectable()
export class ImageEditorDataSourceImpl implements ImageEditorDataSource {

  constructor() {
  }

}

