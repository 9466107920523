import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ReportTicketRateRepository} from "@dropDesk/domain/repositories/reports/report_ticket_rate/report_ticket_rate.repository";
import {ReportTicketRateEntity} from "@dropDesk/domain/entities/reports/report_ticket_rate/report_ticket_rate.entity";

@injectable()
export class GetReportTicketRateUseCase {
  private _repository: ReportTicketRateRepository;

  constructor(@inject(ReportTicketRateRepository) repository: ReportTicketRateRepository) {
    this._repository = repository;
  }

  public call(
    period: { startDate: string, endDate: string },
    idUser: string | null,
  ): Promise<ReportTicketRateEntity> {
    return this._repository.get(period, idUser);
  }
}
