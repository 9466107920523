import styled from "styled-components";
import {Carousel} from "antd";

type TextType = {
    color: string;
}

type CarouselType = {
    shapeColor: string;
}
type ContainerType = {
    background: string;
}

export const CarouselStyled = styled(Carousel)<CarouselType>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 768px;
  max-height: 1024px;

  .slick-dots li button {
    //width: 6px;
    //height: 6px;
    border-radius: 100%;
    background: ${(props) => props.shapeColor};
  }

  .slick-dots li.slick-active button {
    //width: 7px;
    //height: 7px;
    border-radius: 100%;
    background: ${(props) => props.shapeColor};
  }

`;
export const CarouselItem = styled.div`
  display: flex;
  flex: 1;
  min-height: 600px;
  line-height: 600px;
  height: 100%;
  width: 100%;
`;
export const ContentImageCarousel = styled.div`
  display: flex;
  flex: 1;
  height: 450px;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const ContentTitleCarousel = styled.div`
  display: flex;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;
export const TitleCarousel = styled.span<TextType>`
  width: 100%;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.color};
`;
export const SubTitleCarousel = styled.span<TextType>`
  width: 100%;
  text-align: center;
  margin-top: 15px;
  line-height: 20px;
  font-size: 16px;
  color: ${(props) => props.color};
`;

export const Image = styled.img`
  display: flex;
  width: auto;
  height: 450px;
  pointer-events: none;
`;

export const ContentCarousel = styled.div<ContainerType>`
  display: flex;
  flex: 1.5;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  overflow: auto;
  @media screen and (max-width: 700px) {
    display: none !important;
  }
`;
