import {observer} from "mobx-react";

import React from "react";
import {ContainerDescriptions, ContentLoadingImportClient, Text, TextDescription} from "./styled";

import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Progress} from "@dropDesk/presentation/components/loadings/progress_bar";


const LoadingImportClients = observer(
    ({
         progress,
     }: {
        progress: number,
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <ContentLoadingImportClient>
                <Text color={colors.text}>Importação em andamento ...</Text>
                <Progress progress={progress} width={350} type={'line'}/>
                <ContainerDescriptions>
                    <TextDescription color={colors.text}>
                        Enquanto a importação está em andamento, você pode acessar outros menus normalmente...
                    </TextDescription>
                    <TextDescription color={colors.text}>
                        Ao concluir a importação, o status concluído será informado na tela de listagem, e você já
                        poderá utilizar os contatos/empresas importados.
                    </TextDescription>
                </ContainerDescriptions>
            </ContentLoadingImportClient>
        )
    })
export default LoadingImportClients;
