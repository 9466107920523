import React from "react";
import {Container, ContainerNotFound, ContentNotFound, TextNotFound} from "./styled";
import {
    GenericScreenNotFoundType
} from "@dropDesk/presentation/components/generic_screen_not_found/generic_screen_not_found";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";
import {useNavigate} from "react-router-dom";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const GenericScreenNotFound = (props: GenericScreenNotFoundType) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const navigate = useNavigate();


    return (
        <Container>
            <ContainerNotFound background={colors.onBackground} border={colors.border}>
                <HeaderForm
                    letter={props.textHeaderForm}
                    onRequestClose={() => props.backPreviousScreen ? props.backPreviousScreen() : navigate(-1)}
                    disabled={false}
                />
                <ContentNotFound>
                    <DropDeskIcon
                        icon={ICONS_DROPDESK.neutral}
                        size={155}
                        color={colors.hintInverse}
                    />
                    <TextNotFound color={colors.text}>{props.textNotFound}</TextNotFound>
                </ContentNotFound>

            </ContainerNotFound>
        </Container>
    )
}
export default GenericScreenNotFound;
