import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    TextBold,
} from "../styled";
import React from "react";
import {
    ContainerDescriptions,
    ContentDescriptions,
    Text
} from "@dropDesk/presentation/pages/subscription/ui/select_plan/total_plan/styled";
import {ContentResumeValueText} from "@dropDesk/presentation/pages/subscription/ui/checkout/styled";
import {formatCurrency} from "@dropDesk/utils/helpers/common";

const DescriptionGeneric = (
    {
        label,
        value,
        isFormatCurrency
    }: {
        label: string
        value: number
        isFormatCurrency?: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerDescriptions>

            <ContentDescriptions>
                <TextBold size={13} color={colors.hintInverse}>
                    {label}
                </TextBold>
                <ContentResumeValueText>
                    <Text size={13} color={colors.text}>{isFormatCurrency ? formatCurrency(value) : value}</Text>
                </ContentResumeValueText>
            </ContentDescriptions>

        </ContainerDescriptions>
    );
}
export default DescriptionGeneric;
