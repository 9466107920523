import {observer} from "mobx-react";
import {
    Container,
    ContainerPermission,
    ContainerSelectLogo,
    Content,
    ContentDarkOrLightTheme,
    ContentHeader,
    ContentImage, TextLogo,
    TextTheme
} from "./styled";
import React, {useEffect, useState} from "react";
import HeaderConfiguration from "@dropDesk/presentation/components/headers/header_configuration";
import FooterConfiguration from "@dropDesk/presentation/components/footer/footer_configuration";
import {Divider} from "antd";
import ImageProfile from "@dropDesk/presentation/components/drop_zone/pick_image_profile";
import {Crop} from "@dropDesk/presentation/components/crop";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import Colors from "@dropDesk/presentation/pages/company_configurations/ui/theme/colors";
import {getDifferentTheme, getThemeEntityByString} from "@dropDesk/utils/helpers/common";
import {ColorsType} from "@dropDesk/domain/entities/theme/colors_enum";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const Theme = observer((
    {
        loading,
        imageLogoBrand,
        setTheme,
        setUserTheme,
        user,
        onUpdateUser,
        setFileLogoBrand,
        createObjectURLBlobUseCase,
        listUserThemeConfiguration
    }: {
        loading: boolean,
        imageLogoBrand: string | null,
        createObjectURLBlobUseCase: (file: File) => string,
        setTheme: (name?: string) => void,
        setUserTheme: (theme: string) => void,
        user?: UserEntity,
        onUpdateUser: (user: UserEntity) => void,
        setFileLogoBrand: (file?: File | null) => void
        listUserThemeConfiguration: () => void
    }) => {
    const [imageSourceCrop, setImageSourceCrop] = useState<string | null>(null);

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;


    const initialize = async () => {
        listUserThemeConfiguration();
    }

    useEffect(() => {
        initialize();
    }, []);


    const handleChangeTheme = (name: ColorsType): void => {
        const newTheme = getThemeEntityByString(name, appController.theme.colorScheme.brightness);
        appController.setTheme(newTheme);
        setTheme(name);
    };

    const handleDarkOrLightTheme = (): void => {
        const newTheme = getDifferentTheme(appController.theme);
        const theme = `${newTheme.colorScheme.brightness}-${newTheme.colorScheme.name}`;
        const _user = user!.copyWith({
            theme: theme,
            themeObject: newTheme
        });
        onUpdateUser(_user);
        appController.setTheme(newTheme);
        setUserTheme(theme);
    }

    const handleOnCropper = (file: File | null): void => {
        setFileLogoBrand(file);
        setImageSourceCrop(null);
    };

    const handleChangeImageLogoBrand = (files: File[] | []): void => {
        if (files && files.length > 0) {
            const path = createObjectURLBlobUseCase(files[0]);
            setImageSourceCrop(path);
        } else {
            toastMessage.error("Error ao Anexar, Selecione uma imagem com no máximo 1MB");
        }
    };


    return (
        <Container>
            <Content background={colors.onBackground} border={colors.border}>
                <ContentHeader>
                    <HeaderConfiguration
                        letter={"Configurações de Tema"}
                        disabled={loading}
                    />
                    <ContentDarkOrLightTheme
                        onClick={() => handleDarkOrLightTheme()}
                    >
                        <DropDeskIcon
                            icon={colors.brightness === Brightness.dark ? ICONS_DROPDESK.bulbOn : ICONS_DROPDESK.bulbOff}
                            size={20}
                            color={colors.text}
                        />
                        <TextTheme
                            color={colors.text}>{colors.brightness === Brightness.dark ? 'Tema claro' : 'Tema escuro'}</TextTheme>
                    </ContentDarkOrLightTheme>
                </ContentHeader>
                <ContainerPermission>

                    <Colors
                        colors={colors}
                        handleChangeTheme={(name) => handleChangeTheme(name)}
                    />

                    <div>
                        <Divider
                            style={{color: colors.hint, borderColor: colors.border, fontSize: 15}}>LogoMarca</Divider>
                    </div>
                    <ContainerSelectLogo>
                        <ContentImage>
                            <ImageProfile
                                useGarbage={!!imageLogoBrand}
                                radiusImage={false}
                                useLogoPlaceHolder={true}
                                sizeImage={61}
                                width={180}
                                onPick={handleChangeImageLogoBrand}
                                disabled={loading}
                                name={''}
                                urlImageProfile={imageLogoBrand}
                                onRemoveProfileImage={() => {
                                    setImageSourceCrop(null);
                                    setFileLogoBrand(null);
                                }}
                                marginTopButtonChangeImage={16}
                            />
                            {imageSourceCrop ? (
                                <Crop
                                    image={imageSourceCrop}
                                    onRequestCrop={handleOnCropper}
                                    onRequestClose={() => setImageSourceCrop(null)}
                                    aspect={35 / 9}
                                />
                            ) : null}
                        </ContentImage>
                        <TextLogo color={colors.hint}>Formato JPG ou PNG, dimensão 60 x 180px</TextLogo>
                    </ContainerSelectLogo>

                </ContainerPermission>
                <FooterConfiguration
                    disabled={loading}
                    loading={loading}
                    type={"submit"}
                    letter={"Salvar"}
                    onSubmit={() => setTheme()}
                />
            </Content>
        </Container>
    )
})
export default Theme;
