import styled from "styled-components";
import {TextType} from "@dropDesk/presentation/components/headers/header_form/head_form";


export const Container = styled.div`
  padding: 2px 0px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Text = styled.span<TextType>`
  font-size: 17px;
  color: ${(props) => props.color};
  font-weight: bold;
  letter-spacing: .8px;
`;

export const TextLink = styled.button<TextType>`
  font-weight: bold;
  color: ${props => props.color};
  text-decoration: underline;
  border: 0;
  background: transparent;
  font-size: 16px;

  &:hover {
    filter: brightness(80%);
  }
`;
