import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container,} from "./styled";
import React from "react";
import DividerDropDesk from "@dropDesk/presentation/components/divider";
import HeaderResumeValue from "@dropDesk/presentation/pages/subscription/ui/checkout/resume_value/header";
import BodyResumeValue from "@dropDesk/presentation/pages/subscription/ui/checkout/resume_value/body";
import FooterResumeValue from "@dropDesk/presentation/pages/subscription/ui/checkout/resume_value/footer";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import TotalFirstPayment from "@dropDesk/presentation/pages/subscription/ui/checkout/resume_value/total_first_payment";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {
    PaymentSubscriptionSimulateEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_simulate.entity";
import DescriptionGeneric
    from "@dropDesk/presentation/pages/subscription/ui/checkout/resume_value/days_to_first_payment";

const ResumeValue = (
    {

        valuePlanOnDemand,
        newSubscription,
        totalFirstPayment,
        totalOthersPayment,
        labelFirstPayment,
        showSelectPaymentMethod,
        simulate,
        oldSubs
    }: {

        valuePlanOnDemand?: number;
        newSubscription: PaymentSubscriptionEntity
        totalFirstPayment: number
        totalOthersPayment: number
        labelFirstPayment: string
        showSelectPaymentMethod: boolean
        simulate?: PaymentSubscriptionSimulateEntity
        oldSubs: PaymentSubscriptionEntity
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container
            background={colors.onBackground}
            isDarkTheme={colors.brightness === Brightness.dark}
            marginTop={showSelectPaymentMethod ? 38 : 0}
            border={colors.border}
        >
            <HeaderResumeValue planName={newSubscription.planName}/>
            <DividerDropDesk style={{marginTop: 10, marginBottom: 10}}/>
            <BodyResumeValue
                valuePlanOnDemand={valuePlanOnDemand}
                newSubscription={newSubscription}
            />
            <DividerDropDesk style={{marginTop: 10, marginBottom: 10}}/>
            {simulate?.changes.map((simulate, index) => (
                <DescriptionGeneric
                    key={index}
                    label={simulate.description}
                    value={simulate.value}
                    isFormatCurrency={true}
                />
            ))}
            {simulate?.users && !oldSubs.isFree &&
                <DescriptionGeneric
                    label={simulate.userDescription}
                    value={simulate.users.to}/>
            }

            {simulate?.dueDay &&
                <DescriptionGeneric
                    label={simulate.dueDayDescription}
                    value={simulate.dueDay.to}/>
            }

            {simulate?.changes?.find(item => 'days' in item)?.days &&
                <DescriptionGeneric
                    label={'Dias faturado'}
                    value={simulate!.changes!.find(item => 'days' in item)!.days}/>
            }

            <DividerDropDesk style={{marginTop: 10, marginBottom: 10}}/>
            < TotalFirstPayment
                totalFirstPayment={totalFirstPayment}
                labelFirstPayment={labelFirstPayment}
            />
            <DividerDropDesk style={{marginTop: 10, marginBottom: 10}}/>
            <FooterResumeValue
                totalOthersPayment={totalOthersPayment}
                simulate={simulate}
            />
        </Container>
    );
}
export default ResumeValue;
