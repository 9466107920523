import {Container, ContainerSelectRole, Content} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {useNavigate} from "react-router-dom";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import Header from "src/presentation/pages/select_role/ui/header";
import Body from "@dropDesk/presentation/pages/select_role/ui/body";
import Footer from "@dropDesk/presentation/pages/select_role/ui/footer";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useEffect} from "react";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";


const SelectRole = (observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const navigate = useNavigate();

    useEffect(() => {
        if (!appController.role) {
            appController.setRole(UserRole.attendant);
        }
    }, []);

    return (
        <Container>
            <Content background={colors.onBackground}>
                <ContainerSelectRole background={colors.delicateBackground}>
                    <Header/>
                    <Body onClickCardRole={(role) => appController.setRole(role)}/>
                    <Footer onClickConfirm={() => navigate(RoutesEnum.companyCreate)}/>
                </ContainerSelectRole>
            </Content>
        </Container>
    );
}))
export default SelectRole;
