import {NoticesEntity} from "@dropDesk/domain/entities/notices/notices.entity";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {NoticeDestiny} from "@dropDesk/domain/entities/notices/notices_enum";
import {Observable} from "rxjs";

@injectable()
export abstract class NoticesRepository {

    public abstract list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        filterByDestiny: NoticeDestiny | null
    ): Promise<ListPaginationEntity<NoticesEntity>>;

    public abstract set(notice: NoticesEntity): Promise<NoticesEntity>;

    public abstract restore(notices: NoticesEntity[]): Promise<void>;

    public abstract delete(notices: NoticesEntity[]): Promise<void>;

    public abstract findByPK(id: string): Promise<NoticesEntity>;

    public abstract listNoticesByUser(page: number, limit: number): Promise<ListPaginationEntity<NoticesEntity>>;

}
