import {Container, TextDescription} from './styled'
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {TicketStatusType} from "@dropDesk/domain/entities/ticket/ticket_maps";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export default function Tag(
    {
        label,
        isDeleted,
        priority,
        status,
        width
    }: {
        label: string,
        isDeleted?: boolean
        priority?: string
        status?: string
        width?: number
    }
) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    const getBackgroundColorTagPriorityAndStatus = (status: string): string => {
        const statusObj = appController.ticketStatusConfigs.find((item) => item.id === status);
        const statusType: string | null = statusObj?.extra?.type ?? null;

        if (statusType === TicketStatusType.opened) {
            return SharedColors.onOpenStatus;
        }
        if (statusType === TicketStatusType.canceled) {
            return SharedColors.onCancelStatus;
        }
        if (statusType === TicketStatusType.inProgress) {
            return SharedColors.onAttendingStatus;
        }
        return SharedColors.onCloseStatus;
    }


    const getColorTagStatus = (status: string): string => {
        const statusObj = appController.ticketStatusConfigs.find((item) => item.id === status);
        const statusType: string | null = statusObj?.extra?.type ?? null;

        if (statusType === TicketStatusType.opened) {
            return SharedColors.textOpenStatus;
        }
        if (statusType === TicketStatusType.inProgress) {
            return SharedColors.textAttendingStatus;
        }
        if (statusType === TicketStatusType.canceled) {
            return SharedColors.textCancelStatus;
        }
        return SharedColors.textCloseStatus;
    }

    const background = isDeleted ? SharedColors.onCancelStatus : !!status ? getBackgroundColorTagPriorityAndStatus(status) : colors.background;
    const color = isDeleted ? SharedColors.textCancelStatus : !!status ? getColorTagStatus(status) : colors.text;

    return (
        <Container background={background} width={width}
                   border={!!priority || !!status || isDeleted ? 'transparent' : colors.border}>
            <TextDescription color={color}>{isDeleted ? 'Excluído' : label}</TextDescription>
        </Container>
    )
}
