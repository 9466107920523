import "reflect-metadata";
import {inject, injectable} from "inversify";
import {IoRepository} from "@dropDesk/domain/repositories/io/io.repository";
import {Observable} from "rxjs";
import {FileProgress} from "@dropDesk/domain/entities/common/file_progress";

@injectable()
export class DownloadFileUseCase {
  private _repository: IoRepository;

  constructor(@inject(IoRepository) repository: IoRepository) {
    this._repository = repository;
  }

  public call(url: string, fileName: string): Observable<FileProgress> {
    return this._repository.downloadFile(url, fileName);
  }
}
