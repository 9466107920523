import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

export type TextType = {
    color: string;
    size: number;
}

export type ContainerInputType = {
    background: string;
    accent: string;
    bordercolor: string;
}

export type ContainerSelectType = {
    background: string;
    bordercolor: string;
}


export type InputType = {
    color: string;
}


export const BodyInput = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0 27px 0;
`;

export const TextField = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentInput = styled.div<ContainerInputType>`
  display: flex;
  flex-direction: row;
  height: 35px;
  background: ${(props) => props.background};
  border: 1px solid ${props => props.bordercolor};
  border-radius: 5px;

  &:hover {
    border: none;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:focus-within {
    border: none;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const ContentSelectSearch = styled.div<ContainerSelectType>`
  display: flex;
  flex-direction: row;
  height: 35px;
  background: ${(props) => props.background};
  border-radius: 5px;
`;

export const TextIdentityInput = styled.span<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  margin: auto;
  padding: 0 10px 0 0;
`;

export const Input = styled.input<InputType>`
  display: flex;
  flex: 1;
  color: ${(props) => props.color};
  border: 0;
  background-color: transparent;
  padding: 0 15px;
  font-size: 15px;
  width: 100px;
`;
export const ContentInputAndMessageError = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextMessageError = styled.p<TextType>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  display: flex;
  position: fixed;
  font-style: italic;
  text-align: left;
`;

export const ContentMessageError = styled.div`
  display: flex;
  flex-direction: column;
`;

