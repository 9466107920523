import {EmojiType} from "@dropDesk/presentation/components/emoji_picker/emoji_picker";
import data from '@emoji-mart/data';
// @ts-ignore
import Picker from '@emoji-mart/react';
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";

export default function EmojiPicker(props: EmojiType) {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <Picker
            data={data}
            onEmojiSelect={({native}: any) => props.onEmojiSelect(native as string)}
            emojiSize={21}
            native
            theme={colors.brightness === Brightness.dark ? 'dark' : 'light'}
            locale={"pt"}
            previewPosition={"none"}
            searchPosition={"none"}
            maxFrequentRows={props.maxFrequentRows ?? 4}
            perLine={props.perLine ?? 9}
        />
    )
}

