import {useEffect, useState} from "react";
import {isValidEmail, isValidName} from "@dropDesk/utils/helpers/validators";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";

export const useValidatorInputUsers = (user?: UserEntity) => {
    const [errorMessageName, setErrorMessageName] = useState('');
    const [errorMessageEmail, setErrorMessageEmail] = useState('');
    const [errorMessageSectorLinked, setErrorMessageSectorLinked] = useState('');
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);

    useEffect(() => {
        validate();
    }, [user]);

    const validate = (): boolean => {

        setErrorMessageName('');
        setErrorMessageEmail('');
        setErrorMessageSectorLinked('');

        if (!user) {
            return false;
        }

        if (!isValidName(user.name)) {
            setErrorMessageName('O nome deve possuir mais de 2 letras');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidEmail(user.email ?? '')) {
            setErrorMessageEmail('Informe um email válido');
            setIsValidDataForm(false);
            return false;
        } else if (
            user.sectors.filter(entry => entry.action !== BackendAction.delete).length === 0 &&
            !user.isUserClient
        ) {
            setErrorMessageSectorLinked('Selecione o setor a qual o usuário pertence.');
            setIsValidDataForm(false);
            return false;
        } else {
            setIsValidDataForm(true);
            return true;
        }

    }

    return {
        errorMessageName,
        setErrorMessageName,
        errorMessageEmail,
        setErrorMessageEmail,
        validate,
        isValidDataForm,
        errorMessageSectorLinked,
        setErrorMessageSectorLinked

    }
};
