import QRCode from "react-qr-code";
import {
    ContainerQRCode, ContentConnectionSuccess, ContentQRCode, ContainerTimeOut, ContentTimeOut
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/styled";
import React from "react";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import approved from "@dropDesk/storage/images/approved.png";
import imageQrCode from "@dropDesk/storage/images/qr-code.png";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const QrCode = (
    {
        chatConnection,
        startConnection
    }: {
        chatConnection: ChatIntegrationConnectionEntity
        startConnection: () => Promise<void>
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const renderQrCode = (): React.ReactNode => {
        if (chatConnection.hasErrorConnection || chatConnection.isTimeout) {
            return (
                <ContainerTimeOut>
                    <img src={imageQrCode} alt="QRCode timeout" style={{height: 200, width: 200, opacity: 0.3}}/>
                    <ContentTimeOut background={colors.onBackground} onClick={() => startConnection()}>
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.refresh}
                            size={25}
                            color={colors.accent}
                            style={{marginBottom: 7}}
                        />
                        <span style={{
                            fontSize: 18,
                            color: colors.accent,
                            fontWeight: 'bold'
                        }}> Gerar QR CODE</span>
                    </ContentTimeOut>
                </ContainerTimeOut>
            )
        } else if (chatConnection.qrCode && !chatConnection.hasActiveConnection) {
            return (
                <ContentQRCode>
                    <QRCode
                        size={230}
                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                        value={chatConnection.qrCode}
                        viewBox={`0 0 230 230`}
                    />
                </ContentQRCode>
            )
        } else if (chatConnection.hasActiveConnection) {
            return (
                <ContentConnectionSuccess color={colors.accent}>
                    Conectado com sucesso!
                    <img src={approved} alt="conectado com sucesso" style={{height: 200, width: 200, marginTop: 25}}/>
                </ContentConnectionSuccess>
            )
        } else {
            return (
                <SpinLoading
                    size={"large"}
                    color={colors.text}
                    tip={"Gerando QR Code ..."}
                />
            )
        }
    }

    return (
        <ContainerQRCode>
            {renderQrCode()}
        </ContainerQRCode>
    )
}
export default QrCode;
