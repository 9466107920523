import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import HeaderForm from "@dropDesk/presentation/components/headers/header_form";
import React from "react";


const Header = (
    {
        setActiveRoute,
        loading
    }: {
        setActiveRoute: (route: RoutesSubscription) => void
        loading: boolean
    }
) => {


    return (
        <HeaderForm
            letter={"Confirmar Plano"}
            onRequestClose={() => setActiveRoute(RoutesSubscription.detailSubscription)}
            disabled={loading}
        />
    );
}
export default Header;
