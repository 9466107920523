import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ReportTicketRateRepository} from "@dropDesk/domain/repositories/reports/report_ticket_rate/report_ticket_rate.repository";
import {ReportTicketRateRemoteDataSource} from "@dropDesk/data/data_source/reports/report_ticket_rate/report_ticket_rate_remote.datasource";
import {ReportTicketRateEntity} from "@dropDesk/domain/entities/reports/report_ticket_rate/report_ticket_rate.entity";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";

@injectable()
export class ReportTicketRateRepositoryImpl implements ReportTicketRateRepository {
  private _dataSource: ReportTicketRateRemoteDataSource;

  constructor(
    @inject(ReportTicketRateRemoteDataSource)
      dataSource: ReportTicketRateRemoteDataSource
  ) {
    this._dataSource = dataSource;
  }

  public get(
    period: { startDate: string, endDate: string },
    idUser: string | null,
  ): Promise<ReportTicketRateEntity> {
    try {
      return this._dataSource.get(period, idUser);
    } catch (e: any) {
      throw new ServerError(e.message);
    }
  }

}
