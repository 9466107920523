import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type TextType = {
    color: string;
}

type IconType = {
    disabled?: boolean;
}

type ContainerType = {
    background: string;
}

type ContainerBorderType = {
    background: string;
    border: string;
    useInModal?: boolean;
    isUserClient: boolean;
    shadow: string;
}

type ButtonDisabledType = {
    disable: boolean;
}

export const Container = styled.div<ContainerBorderType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${props => props.useInModal ? '10px' : props.isUserClient ? '20px 20px 15px 35px' : '20px 0px 15px 35px'};
  border-radius: 7px;
  flex: 1;
  background: ${props => props.background};
  max-width: 1060px;
  border: 1px solid ${props => props.border};
  box-shadow: 0 3px 6px -4px ${props => hexToRGBA(props.shadow, 0.12)}, 0 6px 16px 0 ${props => hexToRGBA(props.shadow, 0.08)}, 0 9px 28px 8px ${props => hexToRGBA(props.shadow, 0.05)};
`;

export const ContentInput = styled.div`
  display: flex;
  flex: 1;
  max-width: 1060px;
  flex-direction: column;
`;

export const ContainerRightButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentIcon = styled.div<IconType>`
  cursor: ${props => props.disabled ? 'no-drop' : 'pointer'};

`;

export const Button = styled.button`
  display: flex;
  border: 0;
  background: transparent;
  margin: 0 3px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:disabled {
    pointer-events: none;
  }

`;
export const ContentButton = styled.div`
  display: flex;
  cursor: auto;
`;

export const ContainerReply = styled.div<ContainerType>`
  display: flex;
  flex-direction: row;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  padding: 12px;
  background: ${(props) => props.background};
  width: 100%;
`;
export const ContentReply = styled.div<ContainerType>`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  background: ${(props) => props.background};
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
`;

export const IdentityDisplay = styled.div<ContainerType>`
  width: 5px;
  min-width: 5px;
  max-width: 5px;
  height: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: ${props => props.background};
`;

export const ContentTextReply = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px;
  flex: 1;
`;

export const TextReply = styled.span<TextType>`
  font-size: 14px;
  color: ${props => props.color};
  text-align: left;
`;
export const TextTitleReply = styled.span<TextType>`
  font-size: 14px;
  color: ${props => props.color};
  text-align: left;
`;
export const ContentLineClampTwo = styled.div`
  display: block;
  display: -webkit-box;
  flex-direction: row;
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

export const ContentRemoveReply = styled.div`
  display: flex;
  width: 40px;
  min-width: 40px;
  align-items: center;
  justify-content: center;
`;

export const ContentInputAndCommentPrivate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;
