import React, {useEffect} from "react";
import {
    Container
} from "./styled";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import {
    ReportTicketController
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/controller/report_ticket.controller";
import ModalsReportTicket from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/ui/modals";
import SearchBar from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/ui/search_bar";
import GraphicsReportTicket from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/ui/graphics";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {t} from "i18next";

const ReportTickets = (observer(() => {

    const controller = useInjection(ReportTicketController);
    const appController = useInjection(AppController);
    const userLogged = appController.user!;
    const colors = appController.theme.colorScheme;
    const hasDataInGraphics: boolean = controller.hasData(controller.chartStatus) || controller.hasData(controller.chartResolutionTime) ||
        controller.hasData(controller.chartLocation) || controller.hasData(controller.chartPriority);

    useEffect(() => {
        controller.initialize();
    }, []);

    const onSuccess = (key: string) => {
        toastMessage.success(t(key));
    }

    return (

        <Container background={colors.onBackground} heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>

            <SearchBar
                removeFilter={() => controller.removeFilter()}
                loading={controller.loading}
                client={controller.client}
                sector={controller.sector}
                user={controller.user}
                getReportTicket={controller.getReportTicket}
                period={controller.period}
                setPeriod={(dateArray, label) => controller.setPeriod(dateArray, label)}
                hasDataInGraphics={hasDataInGraphics}
                filterPerson={controller.filterPerson}
                setFilterPerson={(filterPerson) => controller.setFilterPerson(filterPerson)}
                labelPeriodCustom={controller.labelPeriod}
            />

            <ModalsReportTicket
                onSelectSector={(sector) => controller.setIdSector(sector)}
                onSelectUser={(user) => controller.setIdUser(user)}
                onSelectClient={(client) => controller.setIdClient(client)}
                loading={controller.loading}
                getReportTicket={controller.getReportTicket}
                exportPdf={() => controller.exportPdf(onSuccess)}
                period={controller.period}
                idUserLogged={userLogged.permissionAdmin ? undefined : userLogged.id}
            />

            <GraphicsReportTicket
                chartStatus={controller.chartStatus}
                chartResolutionTime={controller.chartResolutionTime}
                chartLocation={controller.chartLocation}
                chartPriority={controller.chartPriority}
                loading={controller.loading && !controller.loadingMessage}
                hasData={(chart) => controller.hasData(chart)}
                showGroupedData={controller.showGroupedData}
                setShowGroupedData={() => controller.setShowGroupedData()}
            />
            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    );
}));
export default ReportTickets;
