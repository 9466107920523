import {ContainerReplyMessage, ContentDescription, ContentImage, ContentReplyMessage, ReplyTitle,} from "./styled";
import React from "react";
import AvatarImage from "@dropDesk/presentation/components/avatar";

import {observer} from "mobx-react";
import {shadeOrLighten} from "@dropDesk/utils/helpers/colors";
import {ReplyEntity} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {Tooltip} from "antd";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ReplyMessage = observer((
    {
        replyMessage,
        onReplyMessageClick,
        background,
        color
    }: {
        replyMessage: ReplyEntity
        onReplyMessageClick: (message: ReplyEntity) => void
        background: string
        color: string
    }
) => {

    const urlDownload = replyMessage.quotedMessage?.jpegThumbnail;
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerReplyMessage
            onClick={() => onReplyMessageClick(replyMessage)}
            background={shadeOrLighten(background, -30)}
            bordercolor={color}
        >
            <ContentReplyMessage>
                <ReplyTitle color={color}>
                    {replyMessage.participant?.name}
                </ReplyTitle>
                <ContentDescription color={colors.hintInverse}>
                    <Tooltip title={replyMessage.socialMessage} overlayStyle={{position: 'fixed'}}>
                        {replyMessage.socialMessage}
                    </Tooltip>
                </ContentDescription>
            </ContentReplyMessage>

            {!!urlDownload &&
                <ContentImage style={{pointerEvents: 'none'}}>
                    <AvatarImage
                        useCursorPointer={false}
                        title={'Foto'}
                        size={40}
                        letterSize={14}
                        url={urlDownload}
                        round={true}
                        hasPreviewImage={false}
                        onClick={(event) => {
                        }}
                    />
                </ContentImage>
            }
        </ContainerReplyMessage>
    )
});
export default ReplyMessage;
