import {observer} from "mobx-react";
import React from "react";
import FooterConfiguration from "@dropDesk/presentation/components/footer/footer_configuration";

const FooterPortalClient = observer(
    ({
         loading,
         handleSubmit,
     }: {
        loading: boolean,
        handleSubmit: () => void,
    }) => {

        return (
            <FooterConfiguration
                disabled={loading}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={() => {
                    handleSubmit()
                }}
            />
        )
    })
export default FooterPortalClient;
