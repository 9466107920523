import React, {useEffect} from 'react';
import {
    ContainerForm,
    ContentFooter,
    ContentForm,
    ContentFormImport,
    ContentImportClient,
    FormImport,
    Padding
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import {useTranslation} from "react-i18next";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import HeaderImport from "@dropDesk/presentation/pages/client/ui/import/form_import/header_form";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import Header from "@dropDesk/presentation/pages/client/ui/import/form_import/header";
import RequiredFields from "@dropDesk/presentation/pages/client/ui/import/form_import/required_fields";
import Notation from "@dropDesk/presentation/pages/client/ui/import/form_import/notation";
import PickFile from "@dropDesk/presentation/pages/client/ui/import/form_import/pick_file";
import LoadingImportClients from "@dropDesk/presentation/pages/client/ui/import/form_import/loading_import_client";
import FooterFormImports from "@dropDesk/presentation/pages/client/ui/import/form_import/footer_form";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {useNavigate} from "react-router-dom";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {downloadFile} from "@dropDesk/utils/helpers/files";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const FormImports = (observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const {t} = useTranslation();
    const controller = useInjection(ClientController);
    const navigate = useNavigate();

    const initialize = () => {
        controller.observeImport(onSuccess, onError);
    }

    useEffect(() => {
        initialize();
        return () => {
            controller.dispose();
        }
    }, []);

    const handleSelectFile = (files: File[]) => {
        if (files.length > 0) {
            controller.setFileImportClients(files[0]);
        } else {
            toastMessage.error('Selecione somente um arquivo menor que 5MB');
        }
    }

    const onSuccess = (key: string) => {
        navigate(`${RoutesEnum.importclients}`);
        toastMessage.success(t(key));
    }
    const onError = (key: string) => {
        navigate(`${RoutesEnum.importclients}`);
        toastMessage.error(t(key));
    }

    return (
        <ContainerForm background={colors.onBackground}>

            <ContentForm>

                <FormImport background={colors.onBackground} border={colors.border}>

                    <ContentFormImport background={colors.onBackground}>

                        <HeaderImport loading={controller.loading}/>

                        {(controller.importation.status !== StatusImport.inProgress) &&
                            <ContentImportClient>
                                <Padding>
                                    <Header
                                        downloadFileExampleImportClient={() => controller.downloadFileExample()}
                                    />

                                    <RequiredFields/>

                                    <Notation/>

                                    <PickFile
                                        handleSelectFile={handleSelectFile}
                                        loading={controller.loading}
                                        removeFileAttatch={() => controller.setFileImportClients(null)}
                                        fileName={controller.fileImportClients?.name ?? ''}
                                    />
                                </Padding>
                                <ContentFooter background={colors.onBackground}>
                                    <FooterFormImports
                                        handleSubmit={() => controller.import()}
                                        loading={controller.loading}
                                        isValidDataForm={!!controller.fileImportClients}
                                    />
                                </ContentFooter>
                            </ContentImportClient>
                        }

                        {(controller.importation.status === StatusImport.inProgress) &&
                            <LoadingImportClients
                                progress={controller.importation.progress}
                            />
                        }
                    </ContentFormImport>
                </FormImport>

            </ContentForm>
            {((controller.loading && controller.loadingMessage) || (controller.importation.status === StatusImport.init)) &&
                <DropDeskLoading
                    height={250}
                    description={controller.loadingMessage}
                />}
        </ContainerForm>
    );
}))
export default FormImports;
