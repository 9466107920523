import {
    Container
} from "@dropDesk/presentation/pages/subscription/ui/select_plan/confirm_plan/body/styled";
import React from "react";
import {PlanEntity} from "@dropDesk/domain/entities/plan/plan_entity";
import DividerDropDesk from "src/presentation/components/divider";
import Inputs from "@dropDesk/presentation/pages/subscription/ui/select_plan/confirm_plan/body/inputs";
import Advantage from "@dropDesk/presentation/pages/subscription/ui/select_plan/confirm_plan/body/advantage";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import CurrentSubscription
    from "@dropDesk/presentation/pages/subscription/ui/select_plan/confirm_plan/body/current_subscription";


const Body = (
    {
        currentSubscription,
        newSubscription,
        handleAllChanges,
        hasDowngrade,
    }: {
        currentSubscription: PaymentSubscriptionEntity
        newSubscription: PaymentSubscriptionEntity
        handleAllChanges: (event: {
            target: {
                name: string;
                value: any;
                checked: any;
                type: string
            }
        }) => void
        hasDowngrade: boolean
    }
) => {

    return (
        <Container>
            <Inputs
                newSubscription={newSubscription}
                handleAllChanges={handleAllChanges}
                hasDowngrade={hasDowngrade}
            />
            {!currentSubscription.plan.isFreePlan && !currentSubscription.plan.legacy && <> <DividerDropDesk/>
                <CurrentSubscription currentSubscription={currentSubscription}/>
            </>}
            <DividerDropDesk/>
            <Advantage/>
        </Container>
    );
}
export default Body;
