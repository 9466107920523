import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserEntity} from "../../entities/user/user.entity";
import { AuthRepository } from "@dropDesk/domain/repositories/auth/auth.repository";


@injectable()
export class LoginUseCase {
  private _repository: AuthRepository;

  constructor(
    @inject(AuthRepository) repository: AuthRepository
  ) {
    this._repository = repository;
  }

  public call(email: string, password: string): Promise<void> {
    return this._repository.login(email, password);
  }
}
