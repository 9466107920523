import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CryptoRepository} from "@dropDesk/domain/repositories/common/crypto/crypto.repository";

@injectable()
export class DecryptUseCase {
    private _repository: CryptoRepository;

    constructor(@inject(CryptoRepository) repository: CryptoRepository) {
        this._repository = repository;
    }

    public call(value: string): Promise<string> {
        return this._repository.decrypt(value);
    }
}

