import {useState} from "react";

export const useModalsHeader = () => {

    const [visibleModalLogout, setVisibleModalLogout] = useState<boolean>(false);

    return {
        visibleModalLogout, setVisibleModalLogout
    }
}
