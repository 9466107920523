import {SimpleUserInfo, UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {converterMinutesToTemplateString} from "@dropDesk/utils/helpers/string_helper";
import {TicketStatusType} from "@dropDesk/domain/entities/ticket/ticket_maps";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {
    MessageChatType,
    MessageStatus,
    TextMessageEntity
} from "@dropDesk/domain/entities/ticket/message/external/message_entity";

export class TicketLogsContent {
    value: any;
    newValue: any;

    constructor(value: any, newValue: any) {
        this.value = value;
        this.newValue = newValue;
    }
}

export class TicketLogsEntity {
    id!: string;
    idCompany!: string;
    loggedBy!: SimpleUserInfo;
    createdAt!: string;
    changes!: Record<string, TicketLogsContent>;
    description!: string;
    oldTicket?: TicketEntity;

    static fromJson(json: Record<string, any>): TicketLogsEntity {
        return new TicketLogsEntity({
            id: json['id'] as string,
            idCompany: json['idCompany'] as string,
            loggedBy: json['loggedBy'] as SimpleUserInfo,
            createdAt: json['createdAt'] ?? null,
            changes: json['changes'] as Record<string, TicketLogsContent>,
            description: this.setLogDescription(json as TicketLogsEntity)
        });
    }

    static setLogDescription(_log: TicketLogsEntity): string {
        const log = new TicketLogsEntity({
            ..._log
        });
        if (!log.oldTicket || Object.keys(log.oldTicket).length === 0) {
            return `Atendimento criado por #${log.loggedBy.name}#.`;
        } else {
            const arrayChanges: string[] = Object.keys(log.changes);
            let description = `${log.loggedBy.name}`;

            if (arrayChanges.length && arrayChanges.includes('deleted')) {
                const index = arrayChanges.findIndex((entry) => entry === 'deleted');
                const newValue = log.changes[arrayChanges[index]].newValue;
                description += newValue === true ? " #Excluiu# o atendimento." : " #Restaurou# o atendimento."
            }
            for (let i = 0; i < arrayChanges.length; i++) {
                const changeName = arrayChanges[i];
                const isLast: boolean = (i === arrayChanges.length - 1);
                description += ` Alterou #${this.translateChangesToHuman(changeName)}# de #${this.getFieldName(log, false, changeName)}# para #${this.getFieldName(log, true, changeName)}#${isLast ? '.' : ' ,'}`;
            }
            return description;
        }
    }

    static translateChangesToHuman(label: string): string {
        const types: Record<string, string> = {
            ['dateDue']: 'vencimento',
            ['priority']: 'prioridade',
            ['priorityObject']: 'prioridade',
            ['timeSpent']: 'tempo gasto no atendimento',
            ['idSector']: 'setor',
            ['sector']: 'setor',
            ['status']: 'status',
            ['statusObject']: 'status',
            ['dateClosed']: 'data do fechamento',
            ['descriptionStatic']: 'descrição do problema',
            ['description']: 'descrição do problema',
            ['descriptionClosed']: 'descrição do fechamento',
            ['users']: 'atendente',
            ['deleted']: 'excluído',
        };
        return types[label] ?? label;
    }

    static getFieldName(log: TicketLogsEntity, fromNewValue: boolean, changeName: string): string {
        let value: string | null = fromNewValue ? log.changes[changeName].newValue : log.changes[changeName].value;

        const types: Record<string, string | null> = {
            ['urgent']: 'Urgente',
            ['high']: 'Alta',
            ['normal']: 'Normal',
            ['low']: 'Baixa',
            ['AguardandoAvaliacao']: 'Aguardando avaliação',
            ['FechadoAssistenteVirtual']: 'Fechado por assistente virtual',
            ['Fechado']: 'Fechado',
            ['FechadoCliente']: 'Fechado por cliente',
            ['FechadoAssistenteVirtualInatividade']: 'Fechado por inatividade',
            ['idle_ticket_closed_by_assistant']: 'Atendimento encerrado devido à inatividade pela assistente virtual',
            ['cancel_by_delete_ticket']: 'Cancelado devido o atendimento ser deletado',
            ['true']: 'sim',
            ['false']: 'não',
        };

        if (changeName.includes('date') && !!value) {
            value = displayDateToLocale(value);
        } else if (changeName.includes('timeSpent') && !!value) {
            value = converterMinutesToTemplateString(parseInt(value));
        } else {
            value = types[value ?? 'unknown'] ?? value ?? 'Não informado';
        }
        return value;
    }

    public toMessage(): TicketMessageEntity {
        return new TicketMessageEntity({
            deleted: false,
            idTicket: this.oldTicket?.id ?? this.id,
            isPrivate: false,
            senderId: this.loggedBy.id,
            sender: this.loggedBy as unknown as UserEntity,
            type: MessageChatType.textMessage,
            id: this.id,
            idCompany: this.idCompany,
            createdAt: this.createdAt,
            fromMe: true,
            status: MessageStatus.READ,
            textMessage: new TextMessageEntity({
                conversation: this.description,
            }),
            createdAtLocal: this.createdAt ?? new Date().toISOString()
        });
    }

    constructor({
                    id,
                    idCompany,
                    loggedBy,
                    changes,
                    createdAt,
                    description,
                    oldTicket,
                }
                    :
                    {
                        id?: string;
                        idCompany: string;
                        loggedBy: SimpleUserInfo;
                        changes?: Record<string, TicketLogsContent>;
                        createdAt?: string;
                        description: string;
                        oldTicket?: TicketEntity;
                    }
    ) {
        Object.assign(this, {
            id,
            idCompany,
            loggedBy,
            changes,
            createdAt,
            description,
            oldTicket,
        })
    }
}



