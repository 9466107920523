import {observer} from "mobx-react";
import {Container, Content, ContentInput, ContentInputBorder} from "./styled";
import React, {useEffect, useState} from "react";
import HeaderConfiguration from "@dropDesk/presentation/components/headers/header_configuration";
import FooterConfiguration from "@dropDesk/presentation/components/footer/footer_configuration";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";
import {useValidatorInputCompany} from "@dropDesk/presentation/pages/company_configurations/ui/company/validator";


const CompanyConfiguration = observer(
    ({
         onUpdate,
         getConfigCompany,
         company,
         loading,
         handleSubmit,
         fromMigration
     }: {
        onUpdate: (company: CompanyEntity) => void,
        getConfigCompany: () => void,
        handleSubmit: () => void,
        loading: boolean,
        company?: CompanyEntity
        fromMigration?: boolean
    }) => {


        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const _company = company!.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked :
                        event.target.name === "document" ? removeMask(event.target.value.replace(/\D/g, "")) :
                            event.target.value,
            });

            onUpdate(_company);
        };

        useEffect(() => {
            getConfigCompany();
        }, []);

        const {
            errorMessageName,
            errorMessageDocument,
            isValidDataForm
        } = useValidatorInputCompany(company);

        return (
            <Container style={{padding: fromMigration ? 0 : 16, alignItems: fromMigration ? 'flex-start' : 'center'}}>
                <Content background={colors.onBackground} border={fromMigration ? 'transparent' : colors.border}>
                    {!fromMigration && <HeaderConfiguration
                        letter={"Configurações da Empresa"}
                        disabled={loading}
                    />}
                    <ContentInput style={{padding: fromMigration ? 0 : 16}}>
                        <InputWithBorder
                            mask={company && company?.document.length <= 11 ? 'cpf' : 'cnpj'}
                            autoFocus={false}
                            name='document'
                            size={42}
                            disabled={loading}
                            removeMaskOnCopy={true}
                            label={"Identificação Fiscal (CNPJ/CPF)"}
                            placeHolder={"CNPJ / CPF"}
                            nameIconLeft={ICONS_DROPDESK.numbers}
                            messageError={errorMessageDocument}
                            maxLength={18}
                            value={company?.document ?? ''}
                            onChange={handleAllChanges}
                        />
                        <InputWithBorder
                            useEmojiPicker={true}
                            required={true}
                            name='name'
                            size={35}
                            disabled={loading}
                            messageError={errorMessageName}
                            label={"Nome da Empresa"}
                            value={company?.name ?? ''}
                            onChange={handleAllChanges}
                        />
                    </ContentInput>
                    {!fromMigration && <FooterConfiguration
                        disabled={loading || !isValidDataForm}
                        loading={loading}
                        type={"submit"}
                        letter={"Salvar"}
                        onSubmit={() => {
                            if (isValidDataForm) {
                                handleSubmit();
                            }
                        }}
                    />}
                </Content>
            </Container>
        )
    })
export default CompanyConfiguration;
