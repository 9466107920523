import styled from "styled-components";

type ContainerType = {
  background: string;
};

export const ContainerModalPortalClient = styled.div<ContainerType>`
  display: flex;
  background: ${(props) => props.background};
  flex: 1;
  width: 400px;
  flex-direction: column;
  border-radius: 5px;

`;
export const ContentInfoPortal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;
export const ContentLinkPortal = styled.div<ContainerType>`
  background: ${(props) => props.background};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  margin-top: 10px;
`;
