import styled from "styled-components";

type TextType = {
    color: string;
};

export const TextRequiredFields = styled.span<TextType>`
  font-size: 16px;
  color: ${(props) => props.color};
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Fields = styled.li<TextType>`
  font-size: 15px;
  color: ${(props) => props.color};
  // margin-bottom: 3px;
`;


export const ContentNotation = styled.div<TextType>`
  display: flex;
    // border: 1px solid ${(props) => props.color};
  // border-radius: 5px;
  flex-direction: column;
  margin: 8px 0;
  // padding: 16px;
`;
export const ContentNote = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;
export const ContentNoteData = styled.div`
  font-size: 11px;
`;
