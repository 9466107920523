import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export class ExportDataUserUseCase {
  private _repository: UserRepository;

  constructor(@inject(UserRepository) repository: UserRepository) {
    this._repository = repository;
  }

  public call(role: UserRole): Promise<ExportDataEntity> {
    return this._repository.export(role);
  }
}

