import styled from "styled-components";

type ContainerType = {
    border: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  padding: 50px 16px 16px 16px;
  border: .5px solid ${props => props.border};
  border-radius: 4px;
`;

export const PaddingFilters = styled.div`
  padding-top: 16px;

  //&:not(:last-of-type) {
  //  padding: 16px 0;
  //}
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const Footer = styled.div`
  margin-top: 16px;
`;
