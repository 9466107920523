import styled from "styled-components";

export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentInputsWithBorder = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px 0;
`;

