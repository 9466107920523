import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";
import {inject, injectable} from "inversify";
import {ClientRemoteDataSource} from "@dropDesk/data/data_source/client/client_remote.datasource";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export class ClientRepositoryImpl implements ClientRepository {
    private _dataSource: ClientRemoteDataSource;

    constructor(
        @inject(ClientRemoteDataSource)
            dataSource: ClientRemoteDataSource
    ) {
        this._dataSource = dataSource;
    }

    public set(client: ClientEntity): Promise<ClientEntity> {
        try {
            return this._dataSource.set(client);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public findByPK(id: string): Promise<ClientEntity> {
        try {
            return this._dataSource.findByPK(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public listOnlyUsersLinked(id: string): Promise<ClientEntity> {
        try {
            return this._dataSource.listOnlyUsersLinked(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean): Promise<ListPaginationEntity<ClientEntity>> {
        try {
            return this._dataSource.list(page, searchParam, limit, listOnlyDeleted);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    public restore(clients: ClientEntity[], restoreWithUsers: boolean): Promise<void> {
        try {
            return this._dataSource.restore(clients, restoreWithUsers);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public delete(clients: ClientEntity[]): Promise<void> {
        try {
            return this._dataSource.delete(clients);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public listImportSubscription(
        page: number,
        period: { startDate: string; endDate: string; },
        limit: number
    ): Observable<ListPaginationEntity<ImportData>> {
        try {
            return this._dataSource.listImportSubscription(page, period, limit);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public import(file: File): Promise<Record<string, unknown>> {
        try {
            return this._dataSource.import(file);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public export(): Promise<ExportDataEntity> {
        try {
            return this._dataSource.export();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public importSubscription(
        idCompany: string, startDate: string, endDate: string
    ): Observable<ImportData> {
        try {
            return this._dataSource.importSubscription(idCompany, startDate, endDate);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    public revertImport(id: string): Promise<void> {
        try {
            return this._dataSource.revertImport(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public exportSubscription(): Observable<ExportDataEntity> {
        try {
            return this._dataSource.exportSubscription();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public downloadFileExample(): Promise<void> {
        try {
            return this._dataSource.downloadFileExample();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
