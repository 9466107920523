import "reflect-metadata";
import {inject, injectable} from "inversify";
import { UserEntity } from "@dropDesk/domain/entities/user/user.entity";
import { AuthRepository } from "@dropDesk/domain/repositories/auth/auth.repository";

@injectable()
export class GetCurrentUserUseCase {
  private _repository: AuthRepository;

  constructor(
    @inject(AuthRepository) repository: AuthRepository
  ) {
    this._repository = repository;
  }

  public call(): Promise<UserEntity|null> {
    return this._repository.getCurrentUser();
  }
}