import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import React, {useEffect} from "react";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import ModalSearch from "@dropDesk/presentation/components/modals/modal_search";
import {ModalSearchUserType} from "@dropDesk/presentation/components/modals/modal_search_user/modal_search_user";
import {useInjection} from "inversify-react";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {observer} from "mobx-react";
import {setBlockUpdateLocalSearchCache} from "@dropDesk/utils/helpers/common";


const ModalSearchUser = observer((props: ModalSearchUserType) => {

    const userController = useInjection(UserController);

    useEffect(() => {
        setBlockUpdateLocalSearchCache(true);
        onSearch(props.role, userController.searchParam);
        return () => {
            setBlockUpdateLocalSearchCache(false);
        }
    }, []);

    const tableColumnUser = [
        {
            title: 'Nome', dataIndex: 'url', key: 'url', width: 58, render: (text: string, record: UserEntity) => (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <AvatarImage
                        useCursorPointer={record.urlImageProfile ? true : false}
                        title={record.name}
                        size={35}
                        url={record.urlImageProfile ?? ''}
                        round={true}
                        withBorder={true}
                        hasPreviewImage={true}

                    />

                </div>
            ),
        },
        {
            title: '', ellipsis: true, dataIndex: 'name', key: 'id', render: (text: string, record: UserEntity) => (
                <HighLightWords
                    searchWords={userController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Email',
            ellipsis: true,
            dataIndex: 'email',
            key: 'id',
            render: (text: string, record: UserEntity) => (
                <HighLightWords
                    searchWords={userController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Identificação',
            ellipsis: true,
            dataIndex: 'identity',
            key: 'id',
            render: (text: string, record: UserEntity) => (
                <HighLightWords
                    searchWords={userController.searchParam}
                    textToHighlight={text}
                />
            ),
        },

    ];
    const tableColumnContacts = [
        {
            title: 'Nome', dataIndex: 'url', key: 'url', width: 58, render: (text: string, record: UserEntity) => (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <AvatarImage
                        useCursorPointer={record.urlImageProfile ? true : false}
                        title={record.name}
                        size={35}
                        url={record.urlImageProfile ?? ''}
                        round={true}
                        withBorder={true}
                        hasPreviewImage={true}
                    />

                </div>
            ),
        },
        {
            title: '', ellipsis: true, dataIndex: 'name', key: 'id', render: (text: string, record: UserEntity) => (
                <HighLightWords
                    searchWords={userController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Telefone',
            ellipsis: true,
            dataIndex: 'telephone',
            key: 'id',
            render: (text: string, record: UserEntity) => (
                <HighLightWords
                    searchWords={userController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Empresa',
            ellipsis: true,
            dataIndex: 'client.socialName',
            key: 'id',
            render: (text: string, record: UserEntity) => (
                <HighLightWords
                    searchWords={userController.searchParam}
                    textToHighlight={record.client?.socialName ?? ''}
                />
            ),
        },


        {
            title: 'CPF/CNPJ',
            ellipsis: true,
            dataIndex: 'client.document',
            key: 'id',
            render: (text: string, record: UserEntity) => (
                <HighLightWords
                    searchWords={userController.searchParam}
                    textToHighlight={record.client?.document ?? ''}
                />
            ),
        },

    ];

    const onSearch = (role: UserRole, searchParam: string): void => {

        if (userController.role !== role) {
            userController.setSearchParam('');
        }

        userController.setRole(role);
        userController.list(searchParam ?? userController.searchParam, props.idClient, props.idSector);
    }

    return (
        <ModalSearch<UserEntity>
            inputSearchPlaceholder={props.inputSearchPlaceholder}
            tableColumns={props.role === UserRole.attendant ? tableColumnUser : tableColumnContacts}
            open={props.visible}
            onSearch={(value) => {
                onSearch(props.role, value);
            }}
            onSelectedRow={(user: UserEntity) => {
                props.handleSelectUser(user);
            }}
            inputValue={userController.searchParam}
            textHeaderModal={props.textHeaderModal}
            loading={userController.loading}
            onRequestClose={props.onClose}
            tableDataSource={userController.tableUsers.data}
            defaultCurrentPage={1}
            totalRows={userController.tableUsers.totalRows}
            page={userController.tableUsers.page}
            pageSize={userController.tableUsers.limit}
            onChange={(page) => userController.getDataFromPage(page - 1)}
            labelNoResults={props.labelNoResults}
        />
    )
});
export default ModalSearchUser;
