import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {CNPJResponseEntity} from "@dropDesk/domain/entities/common/cnpj_response.entity";

@injectable()
export abstract class CNPJRemoteDataSource {
    public abstract searchCNPJ(cnpj: string): Promise<CNPJResponseEntity>;
}

@injectable()
export class CNPJRemoteDataSourceImpl implements CNPJRemoteDataSource {

    constructor() {
    }

    baseCNPJUrl: string = 'cnpj/';


    public async searchCNPJ(cnpj: string): Promise<CNPJResponseEntity> {
        return new Promise<CNPJResponseEntity>(async (resolve, reject) => {
            try {

                const response = await api.get(
                    `${this.baseCNPJUrl}${cnpj ?? ''}`
                );
                const _cnpj = new CNPJResponseEntity({
                    ...response.data
                })

                return resolve(_cnpj);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

}
