import React from "react";
import {
    CheckboxWithLabelType
} from "@dropDesk/presentation/components/check_box/check_box_with_label/check_box_with_label";
import {CheckBoxStyled} from "@dropDesk/presentation/components/check_box/check_box_with_label/styled";
import {Tooltip} from "antd";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";


export default function CheckBoxWithLabel(props: CheckboxWithLabelType) {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const color = props.color ?? colors.text;

    return (
        <Tooltip overlayStyle={{whiteSpace: 'pre-line'}} title={props.tooltip ?? ''}>
            <div style={{
                height: props.height ?? 40,
                display: props.useDisplayInLineBlock ? 'inline-block' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: props.marginLeft ?? 0
            }}>
                <CheckBoxStyled
                    checked={props.checked}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    background={props.checked ? colors.accent : 'transparent'}
                    color={SharedColors.white}
                    border={props.bordercolor ?? colors.border}
                    name={props.name ?? ''}
                    accent={colors.accent}
                >
          <span style={{
              color: color, textAlign: 'right',
              fontSize: props.labelSize ?? ConstantsStyles.defaultLabel,
              textDecoration: props.labelWithLineThrough ? 'line-through' : 'auto',
          }}>{props.label}</span>
                    {props.useInformationIcon &&
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.info}
                            style={{marginLeft: 3, pointerEvents: 'none'}}
                            color={colors.hint}
                        />}
                </CheckBoxStyled>
            </div>
        </Tooltip>
    )
}
