import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ReportTicketAggregateRepository} from "@dropDesk/domain/repositories/reports/report_ticket_aggregate/report_ticket_aggregate.repository";
import {ReportTicketAggregateEntity} from "@dropDesk/domain/entities/reports/report_ticket_aggregate/report_ticket_aggregate.entity";

@injectable()
export class GetReportTicketAggregateUseCase {
  private _repository: ReportTicketAggregateRepository;

  constructor(@inject(ReportTicketAggregateRepository) repository: ReportTicketAggregateRepository) {
    this._repository = repository;
  }

  public call(period: { startDate: string, endDate: string }): Promise<ReportTicketAggregateEntity> {
    return this._repository.get(period);
  }
}