import AvatarImage from "@dropDesk/presentation/components/avatar";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import React, {useEffect} from "react";
import ModalSearch from "@dropDesk/presentation/components/modals/modal_search";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import {ModalSearchClientType} from "@dropDesk/presentation/components/modals/modal_search_client/modal_search_client";
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {setBlockUpdateLocalSearchCache} from "@dropDesk/utils/helpers/common";


const ModalSearchClient = observer((props: ModalSearchClientType) => {

    const clientController = useInjection(ClientController);

    useEffect(() => {
        setBlockUpdateLocalSearchCache(true);
        onSearch(clientController.searchParam);
        return () => {
            setBlockUpdateLocalSearchCache(false);
        }
    }, []);

    const onSearch = (searchParam: string): void => {
        clientController.list(searchParam ?? clientController.searchParam);
    }

    const tableColumnClient = [
        {
            title: 'Nome', dataIndex: 'url', key: 'url', width: 58, render: (text: string, record: ClientEntity) => (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <AvatarImage
                        useCursorPointer={true}
                        title={record.name}
                        size={35}
                        url={record.urlImageProfile ?? ''}
                        round={true}
                        withBorder={true}
                        hasPreviewImage={true}
                    />

                </div>
            ),
        },
        {
            title: '',
            ellipsis: true,
            dataIndex: 'socialName',
            key: 'id',
            render: (text: string, record: ClientEntity) => (
                <HighLightWords
                    searchWords={clientController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Nome Fantasia',
            ellipsis: true,
            dataIndex: 'name',
            key: 'id',
            render: (text: string, record: ClientEntity) => (
                <HighLightWords
                    searchWords={clientController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'CNPJ/CPF',
            ellipsis: true,
            dataIndex: 'document',
            key: 'id',
            render: (text: string, record: ClientEntity) => (
                <HighLightWords
                    searchWords={clientController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
        {
            title: 'Telefone',
            ellipsis: true,
            dataIndex: 'telephone',
            key: 'id',
            render: (text: string, record: ClientEntity) => (
                <HighLightWords
                    searchWords={clientController.searchParam}
                    textToHighlight={text}
                />
            ),
        },
    ];


    return (
        <ModalSearch<ClientEntity>
            inputSearchPlaceholder={props.inputSearchPlaceholder}
            tableColumns={tableColumnClient}
            open={props.visible}
            onSearch={(value) => {
                onSearch(value)
            }}
            onSelectedRow={(client: ClientEntity) => {
                props.handleSelectClient(client)
            }}
            inputValue={clientController.searchParam}
            textHeaderModal={props.textHeaderModal}
            loading={clientController.loading}
            onRequestClose={props.onClose}
            tableDataSource={clientController.tableClients.data}
            defaultCurrentPage={1}
            totalRows={clientController.tableClients.totalRows}
            page={clientController.tableClients.page}
            pageSize={clientController.tableClients.limit}
            onChange={(page) => clientController.getDataFromPage(page - 1)}
            labelNoResults={props.labelNoResults}
        />
    )
});
export default ModalSearchClient;
