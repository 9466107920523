import "reflect-metadata";
import {inject, injectable} from "inversify";
import {Observable} from "rxjs";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";

@injectable()
export class SubscriptionTicketMessageUseCase {
  private _repository: ChatRepository;

  constructor(@inject(ChatRepository) repository: ChatRepository) {
    this._repository = repository;
  }

  public call(id: string): Observable<TicketEntity[]> {
    return this._repository.subscribe(id);
  }
}

