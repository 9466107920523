import {observer} from "mobx-react";
import {Container, ContentInputsWithBorder, ContentPin, TitleInformToken} from "./styled";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {PinField} from "@dropDesk/presentation/components/pin_field";
import React from "react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const Inputs = observer((
    ({
         loading,
         tokenInputVisible,
         tokenEntity,
         passwordEntity,
         confirmPasswordEntity,
         visiblePasswordEntity,
         validate,
         isConfirmation,
         defaultValue,
     }: {
        loading: boolean,
        isConfirmation: boolean,
        tokenInputVisible: boolean
        tokenEntity: { token: string, setToken: Function, errorMessageToken: string }
        passwordEntity: { password?: string, setPassword: Function, errorMessagePassword: string }
        confirmPasswordEntity: {
            confirmPassword: string,
            setConfirmPassword: Function,
            errorMessageConfirmPassword: string
        }
        visiblePasswordEntity: { passwordVisible: boolean, setPasswordVisible: Function }
        validate: Function,
        defaultValue?: string,
    }) => {

        return (
            <Container>
                {tokenInputVisible && <ContentInputsWithBorder>
                    <ContentPin>
                        <PinField
                            noOfBoxes={6}
                            autoFocus={true}
                            disabled={loading}
                            onChange={(token) => {
                                tokenEntity.setToken(token);
                            }}
                            useButtonClear={false}
                            defaultValue={defaultValue}
                            acceptOnlyNumbers={true}
                        />
                    </ContentPin>
                </ContentInputsWithBorder>}
                {!isConfirmation && <>
                    <ContentInputsWithBorder style={{marginTop: !tokenInputVisible ? 25 : 0}}>
                        <InputWithBorder
                            size={45}
                            nameIconLeft={ICONS_DROPDESK.padlock}
                            disabled={loading}
                            type={visiblePasswordEntity.passwordVisible ? "text" : "password"}
                            placeHolder={"Nova senha"}
                            nameIconRight={visiblePasswordEntity.passwordVisible ? ICONS_DROPDESK.eye : ICONS_DROPDESK.eyeClosed}
                            messageError={passwordEntity.errorMessagePassword}
                            value={passwordEntity.password ?? ''}
                            onChange={(event) => passwordEntity.setPassword(event.target.value)}
                            onClickRightIcons={() => {
                                visiblePasswordEntity.setPasswordVisible(!visiblePasswordEntity.passwordVisible);
                            }}
                            onBlur={() => validate()}
                        />
                    </ContentInputsWithBorder>
                    <ContentInputsWithBorder>
                        <InputWithBorder
                            size={45}
                            nameIconLeft={ICONS_DROPDESK.padlock}
                            disabled={loading}
                            type={visiblePasswordEntity.passwordVisible ? "text" : "password"}
                            placeHolder={"Confirmar nova senha"}
                            nameIconRight={visiblePasswordEntity.passwordVisible ? ICONS_DROPDESK.eye : ICONS_DROPDESK.eyeClosed}
                            messageError={confirmPasswordEntity.errorMessageConfirmPassword}
                            value={confirmPasswordEntity.confirmPassword}
                            onChange={(event) => confirmPasswordEntity.setConfirmPassword(event.target.value)}
                            onBlur={() => validate()}
                            onClickRightIcons={() => {
                                visiblePasswordEntity.setPasswordVisible(!visiblePasswordEntity.passwordVisible);
                            }}
                        />
                    </ContentInputsWithBorder> </>}
            </Container>
        );
    }));

export default Inputs;
