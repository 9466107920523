import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import ExportDataToExcel from "@dropDesk/presentation/components/export_data_to_excel";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useNavigate} from "react-router-dom";

const ExportDataUser = (observer(() => {

    const controller = useInjection(UserController);
    const navigate = useNavigate();

    const initialize = () => {
        controller.export(handleNoContent).then();

        return () => {
            controller.dispose();
        }
    }

    const handleNoContent = () => {
        navigate(RoutesEnum.users);
        toastMessage.info("Não há atendentes para serem exportados");
    }

    useEffect(() => {
        initialize();
    }, []);

    return (
        <ExportDataToExcel
            tableName={'atendentes'}
            loading={controller.loading}
            loadingMessage={controller.loadingMessage}
            initExport={() => controller.export(handleNoContent)}
            exportation={controller.exportation}
        />
    )

}));
export default ExportDataUser;
