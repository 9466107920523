import React, {useEffect} from 'react';
import {
    Container,
    SubContentList,
    ContentList,

} from "./styled";
import {observer} from "mobx-react";
import {
    ResponseStandardController
} from "@dropDesk/presentation/pages/response_standard/controller/response_standard.controller";
import TableResponseStandard from "@dropDesk/presentation/pages/response_standard/table";
import SearchBar from "@dropDesk/presentation/pages/response_standard/search_bar";
import ModalsResponseStandard from "@dropDesk/presentation/pages/response_standard/modals";
import {useBetween} from "use-between";
import {
    useModalSearchResponseStandard
} from "@dropDesk/presentation/pages/response_standard/modals/handle_change_visible";
import {useInjection} from "inversify-react";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import FooterGarbage from "@dropDesk/presentation/components/footer/footer_garbage";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const ResponseStandard = observer(() => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const isNewRegister = 'new';
        const controller = useInjection(ResponseStandardController);
        const {t} = useTranslation();

        useEffect(() => {
            controller.initialize(appController.heightShowingHeaderInfo);
        }, []);

        const {setVisibleModalNewResponseStandard} = useBetween(useModalSearchResponseStandard);

        const onSuccess = (key: string) => {
            controller.list(controller.searchParam);
            toastMessage.success(t(key));
        }

        const handleVisibleModalNewResponseStandard = (visible: boolean, id: string) => {

            if (visible) {
                if (id === isNewRegister) {
                    controller.makeResponseStandard();
                } else {
                    controller.getResponseStandard(id);
                }

            } else {
                controller.resetTable();
                controller.list(controller.searchParam);
            }

            setVisibleModalNewResponseStandard(visible);
        }

        return (
            <Container background={colors.onBackground} heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>
                <ContentList>
                    <SearchBar
                        onSearch={(searchParam) => controller.list(searchParam)}
                        visibleInputSearch={controller.rowSelectionResponseStandard.length === 0}
                        loading={controller.loading}
                        visibleButtonsOfActions={!controller.listOnlyDeleted && controller.rowSelectionResponseStandard.length === 0}
                        visibleButtonDeleted={!controller.listOnlyDeleted && controller.rowSelectionResponseStandard.length > 0}
                        visibleButtonRestore={controller.listOnlyDeleted && controller.rowSelectionResponseStandard.length > 0}
                        handleVisibleModalNewResponseStandard={handleVisibleModalNewResponseStandard}
                    />
                    <SubContentList>
                        <TableResponseStandard
                            rowSelectionResponseStandard={controller.rowSelectionResponseStandard}
                            setRowSelectionResponseStandard={(responseStandards: ResponseStandardEntity[]) => controller.setRowSelectionResponseStandard(responseStandards)}
                            getDataFromPage={(page) => controller.getDataFromPage(page)}
                            tableResponseStandard={controller.tableResponseStandard}
                            searchParam={controller.searchParam}
                            loading={controller.loading}
                            permitSelectionRow={controller.listOnlyDeleted}
                            handleVisibleModalNewResponseStandard={handleVisibleModalNewResponseStandard}
                        />
                        <FooterGarbage
                            setListOnlyDeleted={(value) => controller.setListOnlyDeleted(value)}
                            visibleListOnlyDeleted={controller.listOnlyDeleted}
                            loading={controller.loading}
                            text={'Respostas ativas'}
                            textDelete={'Respostas excluídas'}
                        />
                        <ModalsResponseStandard
                            onUpdate={(responseStandard) => controller.setResponseStandard(responseStandard)}
                            removeLineSelectRow={() => controller.removeLineSelectRow()}
                            restoreResponseStandard={() => controller.restore(onSuccess)}
                            deleteResponseStandard={() => controller.delete(onSuccess)}
                            responseStandard={controller.responseStandard}
                            loading={controller.loading}
                            handleVisibleModalNewResponseStandard={handleVisibleModalNewResponseStandard}
                            set={(onSuccess) => controller.set(onSuccess)}
                        />
                    </SubContentList>
                </ContentList>
                {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                    height={250}
                    description={controller.loadingMessage}
                />}
            </Container>
        )

    }
)
export default ResponseStandard;
