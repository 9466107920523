import {useEffect, useState} from "react";
import {isValidEmail, isValidPassword} from "@dropDesk/utils/helpers/validators";

export const useValidatorLogin = () => {
  //const [loginEntity, setLoginEntity] = useState({email: "jardel@dropdesk.com.br", password: "26592659"});
  const [loginEntity, setLoginEntity] = useState({email: "", password: ""});
  const [isEmail, setIsEmail] = useState<boolean | null>(true);
  const [errorMessageEmail, setErrorMessageEmail] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (isEmail != null) {
      validate(isEmail, !isEmail);
    }
  }, [loginEntity, isEmail]);

  function validate(checkEmail: boolean, checkPass: boolean): boolean {
    const validEmail = isValidEmail(loginEntity.email);
    const validPassword = isValidPassword(loginEntity.password);
    if (checkEmail) {
      setErrorMessageEmail(!isValidEmail(loginEntity.email));
    }

    if (checkPass) {
      setErrorMessagePassword(!isValidPassword(loginEntity.password));
    }

    return validEmail && validPassword;
  };

  return {
    loginEntity, setLoginEntity,
    isEmail, setIsEmail,
    errorMessageEmail, setErrorMessageEmail,
    errorMessagePassword, setErrorMessagePassword,
    passwordVisible, setPasswordVisible,
    validate
  }
};
