import HeaderModal from "../../headers/header_modal";
import {Container, Content} from "./styled";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import React, {useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {removeMaskStaticTelephoneNumber} from "@dropDesk/utils/helpers/string_helper";
import {useValidatorInputTickets} from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/validator";
import InputDateDue from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/input_date_due";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalChangeDateDue = (
    {
        visible,
        onClose,
        handleChangeDateDue,
        loading,
        ticket
    }: {
        visible: boolean
        loading: boolean
        onClose: () => void
        handleChangeDateDue: (dateDue: string) => void
        ticket: TicketEntity
    }
) => {

    const [cloneTicket, setCloneTicket] = useState<TicketEntity>(new TicketEntity({
        ...ticket
    }));

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const handleAllChanges = (event: {
        target: { name: string; value: any; checked?: any; type: string }
    }) => {

        const _ticket = cloneTicket.copyWith({
            [event.target.name]:
                (event.target.type === "checkbox" || event.target.type === "switch") ?
                    event.target.checked : event.target.name === "telephone" ?
                        removeMaskStaticTelephoneNumber(event.target.value.replace(/\D/g, "")) :
                        event.target.value,

            replaceDateDueIfNull: event.target.name === "dateDue",
        });
        setCloneTicket(_ticket);

    };

    const {
        errorMessageDateDue
    } = useValidatorInputTickets(cloneTicket);

    return (
        <ModalMain
            open={visible}
            onRequestClose={onClose}
            width={400}
            renderComponent={<Container background={colors.onBackground}>
                <HeaderModal
                    disabled={loading}
                    letter={"Alterar vencimento"}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={onClose}
                />
                <Content>
                    <InputDateDue
                        ticket={cloneTicket}
                        errorMessageDateDue={errorMessageDateDue}
                        handleAllChanges={handleAllChanges}
                        minDate={new Date(ticket.createdAt ?? new Date())}
                    />
                </Content>
                <FooterModal
                    disabled={loading || !!errorMessageDateDue || !cloneTicket.dateDue}
                    loading={loading}
                    type={"submit"}
                    letter={"Confirmar"}
                    onSubmit={() => {
                        handleChangeDateDue(cloneTicket.dateDue!);
                    }}
                    onRequestClose={onClose}
                />
            </Container>
            }
        />
    );
}
export default ModalChangeDateDue;
