import {observer} from "mobx-react";
import React, {useContext} from "react";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import {ResponseStandardEntity} from "@dropDesk/domain/entities/response_standard/response_standard.entity";
import TableGeneric from "@dropDesk/presentation/components/tables/table_generic";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import {Tooltip} from "antd";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ContainerAction} from "./styled";
import ButtonActionTable from "@dropDesk/presentation/components/buttons/button_action_table";
import {useBetween} from "use-between";
import {
    useModalSearchResponseStandard
} from "@dropDesk/presentation/pages/response_standard/modals/handle_change_visible";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const TableResponseStandard = observer(
    ({
         searchParam,
         loading,
         permitSelectionRow,
         tableResponseStandard,
         getDataFromPage,
         setRowSelectionResponseStandard,
         rowSelectionResponseStandard,
         handleVisibleModalNewResponseStandard
     }:
         {
             searchParam: string,
             loading: boolean,
             permitSelectionRow: boolean,
             tableResponseStandard: ListPaginationEntity<ResponseStandardEntity>,
             getDataFromPage: (page: number) => void,
             setRowSelectionResponseStandard: (user: ResponseStandardEntity[]) => void,
             rowSelectionResponseStandard: ResponseStandardEntity[],
             handleVisibleModalNewResponseStandard: (visible: boolean, id: string) => void
         }) => {


        const collapsed = useContext(MenuCollapsedContext);

        const {
            setVisibleModalDelete,
            setVisibleModalRestore,
        } = useBetween(useModalSearchResponseStandard);

        const setRestore = (responseStandard: ResponseStandardEntity) => {
            setRowSelectionResponseStandard([responseStandard]);
            setVisibleModalRestore(true);
        }

        const tableColumn = [

            {
                title: 'Título',
                ellipsis: true,
                dataIndex: 'title',
                key: 'id',
                render: (text: string, record: ResponseStandardEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Mensagem',
                ellipsis: true,
                dataIndex: 'description',
                key: 'id',
                render: (text: string, record: ResponseStandardEntity) => (
                    <HighLightWords
                        searchWords={searchParam}
                        textToHighlight={text}
                    />
                ),
            },
            {
                title: 'Criado em', ellipsis: true, dataIndex: 'createdAt', key: 'id', render: (text: string) => (
                    <span>{displayDateToLocale(text)}</span>
                ),
            },
            {
                title: '', key: 'action', width: 65, render: (text: string, record: ResponseStandardEntity) => (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <ContainerAction>
                            {!record.deleted &&
                                <Tooltip placement="left" title={'Excluir Resposta'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRowSelectionResponseStandard([record]);
                                        setVisibleModalDelete(true)
                                    }}
                                    nameIcon={ICONS_DROPDESK.delete}
                                    isActionDelete={true}
                                /> </Tooltip>}
                            {record.deleted &&
                                <Tooltip placement="left" title={'Restaurar Resposta'}
                                > <ButtonActionTable
                                    disabled={false}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRestore(record);
                                    }}
                                    nameIcon={ICONS_DROPDESK.restore}
                                /> </Tooltip>

                            }
                        </ContainerAction>
                    </div>
                ),
            },
        ];
        const rowSelection = {
            selectedRowKeys: rowSelectionResponseStandard.map((responseStandard) => responseStandard.id),
            onChange: (selectedRowKeys: React.Key[], selectedRows: ResponseStandardEntity[]) => {
                setRowSelectionResponseStandard(selectedRows)
            },
            getCheckboxProps: (record: ResponseStandardEntity) => ({
                disabled: false,
            }),
            preserveSelectedRowKeys: true
        };


        return (
            <TableGeneric<ResponseStandardEntity>
                sticky={true}
                size={'small'}
                tableColumns={tableColumn}
                tableDataSource={tableResponseStandard.data}
                onSelectedRow={(responseStandard: ResponseStandardEntity) => {
                    if (!responseStandard.deleted) {
                        handleVisibleModalNewResponseStandard(true, responseStandard.id);
                    } else {
                        setRestore(responseStandard);
                    }
                }}
                loading={loading}
                labelNoResults={'Nenhuma resposta rápida encontrada na pesquisa'}
                collapsedMenu={collapsed}
                defaultCurrentPage={1}
                totalRows={tableResponseStandard.totalRows}
                page={tableResponseStandard.page}
                pageSize={tableResponseStandard.limit}
                rowSelection={permitSelectionRow ? rowSelection : null}
                onChange={(page) => getDataFromPage(page - 1)}
                extraLayout={-40}
            />
        )
    })
export default TableResponseStandard;
