import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Container, Text} from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/info_message/styled";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {shadeOrLighten} from "@dropDesk/utils/helpers/colors";

const InfoMessage = (
    {
        text,
    }: {
        text: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const color = colors.isDarkTheme ? SharedColors.warning : shadeOrLighten(SharedColors.warning, -10);
    return (
        <Container>
            <DropDeskIcon icon={ICONS_DROPDESK.info} color={color} style={{marginRight: 10}}/>
            <Text size={12} color={color}>{text}</Text>
        </Container>
    )
}
export default InfoMessage;
