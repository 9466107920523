import {
    Container,
} from "./styled";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import Body from "@dropDesk/presentation/components/chat_view/modals/ticket_rating/body";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    TicketEvaluationController
} from "@dropDesk/presentation/pages/ticket/evaluation/controller/ticket_evaluation.controller";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {t} from "i18next";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const TicketRating =
    observer((
        {
            onClose,
            ticketEvaluation,
            setTicketEvaluation,
            loading,
            loadingMessage,
            makeEvaluation,
            handleEvaluate
        }: {
            onClose: () => void,
            ticketEvaluation?: TicketEvaluationEntity
            setTicketEvaluation: (ticketEvaluation: TicketEvaluationEntity) => void
            loading: boolean
            loadingMessage?: string | null
            makeEvaluation: () => void
            handleEvaluate: () => void
        }
    ) => {


        useEffect(() => {
            makeEvaluation();
        }, []);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const result = ticketEvaluation!.copyWith({
                [event.target.name]: (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value
            });
            setTicketEvaluation(result);
        };

        return (
            <Container>
                <HeaderModal
                    disabled={loading}
                    letter={"Avaliar atendimento"}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={onClose}
                />
                {ticketEvaluation && <Body
                    loading={loading}
                    newEvaluation={ticketEvaluation}
                    handleAllChanges={handleAllChanges}
                />}

                <FooterModal
                    disabled={loading || !ticketEvaluation?.value}
                    loading={loading}
                    type={"submit"}
                    letter={'Avaliar'}
                    onSubmit={async () => {
                        handleEvaluate();
                    }}
                    onRequestClose={onClose}
                />

                {loading && <DropDeskLoading
                    height={250}
                    description={loadingMessage}
                />}
            </Container>
        )
    });
export default TicketRating;
