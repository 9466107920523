import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const MessageError = (
    {
        messageError,
    }: {
        messageError?: string;
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <div style={{height: 25}}>

            {messageError && <p
                style={{
                    display: 'flex',
                    color: colors.textError,
                    fontSize: 13,
                    fontStyle: "italic",
                    textAlign: "left",
                    position: "relative",
                }}>
                {messageError}
            </p>}
        </div>
    )
}
export default MessageError;
