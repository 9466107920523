import {Container, Text, TextLink} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import React from "react";

const Header = observer((
    {
        text,
        onCLickLogout
    }: {
        text: string
        onCLickLogout: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <Text color={colors.text}>{text}</Text>
            {!appController.user &&
                <TextLink
                    onClick={() => onCLickLogout()}
                    color={colors.textLink}>&nbsp;sair do sistema</TextLink>
            }
        </Container>
    );
})
export default Header;
