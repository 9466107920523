import React from "react";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ContentButton
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/pix/styled";

const ButtonPay = (
    {
        loading,
        pay,
        isEditExternal,
        disabled
    }: {
        loading: boolean
        disabled: boolean
        pay: () => void
        isEditExternal?: boolean
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentButton>
            <ButtonMain
                style={{paddingLeft: 5, paddingRight: 5}}
                widthPercentage={100}
                letterFontSize={16}
                disabled={loading || disabled}
                loading={loading}
                color={colors.accent}
                type={"submit"}
                letter={isEditExternal ? 'Trocar' : 'Gerar Boleto'}
                onClick={() => pay()}
            />
        </ContentButton>
    );
}
export default ButtonPay;
