import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {observer} from "mobx-react";
import {
    Container,
    Content,
    ContentAllData
} from "@dropDesk/presentation/routes/update_company_info_from_migration/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import Header from "@dropDesk/presentation/routes/update_company_info_from_migration/header";
import Body from "@dropDesk/presentation/routes/update_company_info_from_migration/body";
import React, {useEffect} from "react";
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {t} from "i18next";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import Footer from "@dropDesk/presentation/routes/update_company_info_from_migration/footer";
import {
    useValidatorInputCompanyPaymentInfo
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info/validator";
import {
    useValidatorInputCreditCard
} from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method/credit_card/inputs_card/validator";
import {useValidatorInputCompany} from "@dropDesk/presentation/pages/company_configurations/ui/company/validator";
import {TabsPayment} from "@dropDesk/domain/entities/payment_method/payment_method.enum";

const UpdateCompanyInfoFromMigration = (observer((
    {
        currentUser,
    }: {
        currentUser: UserEntity
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const companyController = useInjection(CompanyController);

    useEffect(() => {
        companyController.makePaymentMethod();
        getCompany().then();
        return () => {
            companyController.resetPaymentMethod();
        }
    }, []);

    const onUpdateCompanyLocal = (newCompany: CompanyEntity) => {
        if (appController.user) {
            const _userLogged = appController.user.copyWith({
                company: newCompany
            })
            appController.setUser(_userLogged);
        }
    }

    const getCompany = async (): Promise<void> => {
        const newCompany = await companyController.getCompany();
        onUpdateCompanyLocal(newCompany);
    }

    const onSuccessSave = async (key: string) => {
        onSuccess(key);
        await getCompany();
    }

    const onSuccess = (key: string) => {
        toastMessage.success(t(key));
    }

    const handleUpdateLegacy = async () => {
        await companyController.updateLegacy(onSuccessSave);
    }

    const companyPaymentValidator = useValidatorInputCompanyPaymentInfo(companyController.paymentInfo);

    const tokenizeCardValidator = useValidatorInputCreditCard(companyController.newTokenizeCardEntity);

    const companyValidator = useValidatorInputCompany(companyController.company);

    const getValidation = (): boolean => {
        return !companyPaymentValidator.isValidDataForm
            ||
            (companyController.activeTabPayment === TabsPayment.creditCard ? !tokenizeCardValidator.isValidDataForm : false)
            ||
            !companyValidator.isValidDataForm
    }

    return (
        <Container background={colors.onBackground}>
            <Header title={'Atualização de Dados Necessária'}/>
            <Content>
                <ContentAllData>
                    <Body
                        company={companyController.company}
                        loading={companyController.loading}
                        paymentInfo={companyController.paymentInfo}
                        setPaymentInfo={(paymentInfo) => companyController.setPaymentInfo(paymentInfo)}
                        onSearchCNPJ={(cnpj) => companyController.onSearchCNPJ(cnpj)}
                        onSearchZipCode={(zipCode) => companyController.onSearchZipCode(zipCode)}
                        setCompany={(company) => companyController.setCompany(company)}
                        newTokenizeCardEntity={companyController.newTokenizeCardEntity}
                        setTokenizeCard={(card) => companyController.setTokenizeCard(card)}
                        setActiveTabPayment={(key) => companyController.setActiveTabPayment(key)}
                        activeTabPayment={companyController.activeTabPayment}
                    />
                    <Footer
                        loading={companyController.loading}
                        onSubmit={() => handleUpdateLegacy()}
                        disabled={getValidation()}
                    />
                </ContentAllData>
            </Content>
            {(companyController.loading) && (!!companyController.loadingMessage) &&
                <DropDeskLoading
                    height={250}
                    description={companyController.loadingMessage}
                />}
        </Container>
    )
}));
export default UpdateCompanyInfoFromMigration;
