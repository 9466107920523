import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {Divider} from "antd";
import React from "react";


const DividerDropDesk = (
    {
        style
    }: {
        style?: React.CSSProperties
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Divider style={{
            color: hexToRGBA(colors.hint, .2),
            borderColor: hexToRGBA(colors.hint, .2),
            margin: 0,
            borderWidth: 2,
            ...style
        }}/>
    );
}
export default DividerDropDesk;
