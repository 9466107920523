import {
    Button,
    ContentButton,
    ContentIcon,
} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import AudioPlayer from "@dropDesk/presentation/components/audio_player/audio_player";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";
import {ContentAudio} from "@dropDesk/presentation/components/audio_recorder/styled";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ContainerButtonInput} from "../chat_view/chat_message/styled";
import {observer} from "mobx-react";

const Stopped = observer((
    {
        cancelRecording,
        saveRecording,
        audioRecorder,
        messageId,

    }: {
        cancelRecording: () => void
        saveRecording: () => void
        audioRecorder: AudioRecordEntity
        messageId: string
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContentAudio>
            <ContainerButtonInput
                accent={colors.accent}
                background={colors.buttonOnBackground}
            >
                <ContentButton>
                    <Button
                        onClick={() => {
                            cancelRecording();
                        }}
                    >
                        <ContentIcon>
                            <DropDeskIcon icon={ICONS_DROPDESK.delete} size={21} color={colors.textError}/>
                        </ContentIcon>
                    </Button>
                </ContentButton>
            </ContainerButtonInput>
            <ContentAudio>
                <AudioPlayer
                    src={audioRecorder.audioBlob ?? ''}
                    id={messageId}
                />
            </ContentAudio>
            <ContainerButtonInput
                accent={colors.accent}
                background={colors.buttonOnBackground}
            >
                <ContentButton>
                    <Button
                        onClick={() => {
                            saveRecording();
                        }}
                    >
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.sendFill}
                            size={21}
                            color={colors.success}
                            style={{paddingLeft: 4}}
                        />
                    </Button>
                </ContentButton>
            </ContainerButtonInput>
        </ContentAudio>
    )
});
export default Stopped;
