import React, {useEffect} from 'react';

import {observer} from "mobx-react";
import FormUsers from "@dropDesk/presentation/pages/user/ui/form_user";

const Profile = (observer(() => {

    return <FormUsers/>

}));
export default Profile;
