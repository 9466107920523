import {useState} from "react";

export const useModalUsers = () => {

    const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
    const [visibleModalAdmin, setVisibleModalAdmin] = useState<boolean>(false);
    const [visibleModalClients, setVisibleModalClients] = useState<boolean>(false);
    const [visibleModalUsers, setVisibleModalUsers] = useState<boolean>(false);
    const [visibleModalSectors, setVisibleModalSectors] = useState<boolean>(false);
    const [visibleModalWarnings, setVisibleModalWarnings] = useState<boolean>(false);
    const [visibleModalCancelTickets, setVisibleModalCancelTickets] = useState<boolean>(false);
    const [visibleModalReports, setVisibleModalReports] = useState<boolean>(false);
    const [visibleModalDeleteData, setVisibleModalDeleteData] = useState<boolean>(false);
    const [visibleModalActivateAccount, setVisibleModalActivateAccount] = useState<boolean>(false);
    const [visibleModalRememberPassword, setVisibleModalRememberPassword] = useState<boolean>(false);
    const [visibleModalChangeEmail, setVisibleModalChangeEmail] = useState<boolean>(false);
    const [visibleModalListJustTicket, setVisibleModalListJustTicket] = useState<boolean>(false);

    const changeVisibleModalChangeEmail = () => {
        setVisibleModalChangeEmail(!visibleModalChangeEmail)
    }


    return {
        visibleModalDelete,
        setVisibleModalDelete,
        visibleModalAdmin,
        setVisibleModalAdmin,
        visibleModalClients,
        setVisibleModalClients,
        visibleModalUsers,
        setVisibleModalUsers,
        visibleModalSectors,
        setVisibleModalSectors,
        visibleModalWarnings,
        setVisibleModalWarnings,
        visibleModalCancelTickets,
        setVisibleModalCancelTickets,
        visibleModalReports,
        setVisibleModalReports,
        visibleModalDeleteData,
        setVisibleModalDeleteData,
        visibleModalActivateAccount, setVisibleModalActivateAccount,
        visibleModalRememberPassword, setVisibleModalRememberPassword,
        changeVisibleModalChangeEmail, visibleModalChangeEmail,
        visibleModalListJustTicket, setVisibleModalListJustTicket
    }
}
