import {injectable} from "inversify";
import {ReportTicketRateEntity} from "@dropDesk/domain/entities/reports/report_ticket_rate/report_ticket_rate.entity";

@injectable()
export abstract class ReportTicketRateRepository {

  public abstract get(
    period: { startDate: string, endDate: string },
    idUser: string | null,
  ): Promise<ReportTicketRateEntity>

}
