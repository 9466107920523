import React from "react";
import {

    ContainerTime, Text, TextItalic,
} from "./styled";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {MessageBoxStatus} from "@dropDesk/presentation/components/messages/message_box";
import Status from "@dropDesk/presentation/components/messages/message_box/status_message/status";


const StatusMessage = (
    {
        message,
        status,
        useStatus,
        hint
    }: {
        message: TicketMessageEntity
        status?: MessageBoxStatus
        useStatus: boolean;
        hint: string
    }
) => {

    return (
        <ContainerTime color={hint}>
            {message.outOfBusinessHour &&
                <TextItalic>Fora do horário comercial&nbsp;&nbsp;<Text>|</Text>&nbsp;&nbsp;</TextItalic>
            }
            {message.isPrivate &&
                <TextItalic>Comentário interno&nbsp;&nbsp;<Text>|</Text>&nbsp;&nbsp;</TextItalic>
            }
            <>
                {message.edited && !message.deleted &&
                    <TextItalic>Editado&nbsp;&nbsp;<Text>|</Text>&nbsp;&nbsp;</TextItalic>
                }
                <TextItalic>{displayDateToLocale(message.realCreatedAt)}</TextItalic>
                {status && useStatus && !message.deleted && (
                    <Status status={status} containerStyle={{marginLeft: 5}} hint={hint}/>
                )}
            </>

        </ContainerTime>
    )
}
export default StatusMessage;
