import {Button} from "@dropDesk/presentation/components/footer/footer_modal/styled";
import {ButtonBack, ContainerFooterRestoreContacts} from "./styled";
import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export const FooterRestoreContact = (
    {
        onSubmit,
        disabled,
        loading
    }: {
        onSubmit: (restoreWithUser: boolean) => void
        disabled: boolean
        loading: boolean
    }
) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ContainerFooterRestoreContacts color={colors.border}>
            <ButtonBack
                disabled={false}
                background={colors.onBackground}
                type={'submit'}
                border={colors.border}
                color={colors.text}
                shadow={colors.accent}
                onClick={() => onSubmit(false)}>
                Somente a empresa
            </ButtonBack>

            <Button
                disabled={disabled}
                background={colors.accent}
                type={'submit'}
                color={SharedColors.white}
                border={colors.border}
                shadow={colors.accent}
                onClick={disabled ? () => {
                } : () => onSubmit(true)}>
                {!loading && 'Sim'}
                {loading &&
                    <SpinLoading
                        color={colors.delicateBackground}
                        size={"small"}
                    />}
            </Button>
        </ContainerFooterRestoreContacts>
    )
}
