import {observer} from "mobx-react";
import React, {useState} from "react";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import ModalDeleteMessage from "@dropDesk/presentation/components/modals/modal_information";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import EditMessage from "@dropDesk/presentation/components/messages/message_box/arrow_menu_popover/modals/edit_message";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";


const ModalsArrowMenuPopover = observer((
    {
        userClient,
        handleDeleteMessage,
        handleEditMessage,
        setVisibleModalEditMessage,
        visibleModalEditMessage,
        visibleModalDeleteMessage,
        setVisibleModalDeleteMessage,
        attendant,
        userLogged
    }: {
        userClient: UserEntity
        attendant?: UserEntity,
        userLogged: UserEntity
        handleDeleteMessage: (message: TicketMessageEntity) => void
        handleEditMessage: (editText: string, currentMessage: TicketMessageEntity) => void
        setVisibleModalEditMessage: React.Dispatch<React.SetStateAction<{
            visible: boolean,
            message: TicketMessageEntity | null
        }>>
        visibleModalEditMessage: { visible: boolean, message: TicketMessageEntity | null }
        visibleModalDeleteMessage: { visible: boolean, message: TicketMessageEntity | null }
        setVisibleModalDeleteMessage: React.Dispatch<React.SetStateAction<{
            visible: boolean,
            message: TicketMessageEntity | null
        }>>
    }) => {


    return (
        <>
            <ModalDeleteMessage
                open={visibleModalDeleteMessage.visible}
                onRequestClose={() => {
                    setVisibleModalDeleteMessage({visible: false, message: null});
                }}
                textHeaderModal={'Excluir Mensagem'}
                textButton={'Excluir'}
                onRequestSave={() => {
                    handleDeleteMessage(visibleModalDeleteMessage.message!);
                    setVisibleModalDeleteMessage({visible: false, message: null});
                }}
                nameIcon={ICONS_DROPDESK.delete}
                sizeIcon={130}
                textInformation={
                    `Deseja realmente excluir esta mensagem?`
                }
                loading={false}
                isActionDelete={true}
            />


            {visibleModalEditMessage.message &&
                <ModalMain
                    open={visibleModalEditMessage.visible}
                    onRequestClose={() => setVisibleModalEditMessage({visible: false, message: null})}
                    width={600}
                    renderComponent={
                        <EditMessage
                            message={visibleModalEditMessage.message}
                            onClose={() => setVisibleModalEditMessage({visible: false, message: null})}
                            onSend={(editText: string, currentMessage: TicketMessageEntity) => {
                                handleEditMessage(editText, currentMessage);
                                setVisibleModalEditMessage({visible: false, message: null});
                            }}
                            userClient={userClient}
                            attendant={attendant}
                            userLogged={userLogged}
                        />}
                />
            }
        </>

    )
});
export default ModalsArrowMenuPopover;
