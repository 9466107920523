import {isValidPhoneNumber} from 'react-phone-number-input'
import {removeMask} from "@dropDesk/utils/helpers/string_helper";

export const isValidPassword = (password: string): boolean => {
    return !!password && password.trim().length >= 6;
}

export const isValidEmail = (email: string): boolean => {
    const regexValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexValidator.test(email);
}

export const isValidURL = (text: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(text);
}

export const isValidDescription = (text: string): boolean => {
    if (!text || text.trim().length <= 2) {
        return false;
    }
    return true;
}

export const isValidNumberTelephone = (phone?: string | null): boolean => {
    if (!phone) {
        return false;
    }
    let value = phone;
    if (value[0] !== '+') {
        value = `+${value}`;
    }
    return isValidPhoneNumber(value);
}

export const getPhoneValue = (phone?: string | null): string => {
    return phone ? `+${phone}` : '';
}

export const isValidMessageDescriptionOrThrow = (message: string): boolean => {
    if (!message || message.trim().length <= 2) {
        return false;
    }
    return true;
}

export const isValidName = (text: string): boolean => {
    if (!text || text.trim().length <= 2) {
        return false;
    }
    return true;
}

export const isValidExpirationDate = (value: string) => {
    const strippedValue = value.replace(/\D/g, "");
    if (strippedValue.length === 4) {
        const month = parseInt(strippedValue.substring(0, 2), 10);
        const year = parseInt('20' + strippedValue.substring(2), 10);

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adicione 1, pois os meses em JavaScript começam em 0
        const currentYear = currentDate.getFullYear();

        if ((month >= 1 && month <= 12) && (year > currentYear || (year === currentYear && month >= currentMonth))) {
            return true;
        }
    }

    return false;
}

export const isValidCEP = (text: string): boolean => {
    text = removeMask(text);
    const regexValidator = /^[0-9]{8}$/;
    return regexValidator.test(text);
}


export const isInvalidRangeDate = (dateStarted: string, dateEnd: string): boolean => {
    const dateStartedParse = new Date(dateStarted);
    const dateEndParse = new Date(dateEnd);
    return dateStartedParse.getTime() >= dateEndParse.getTime();

}

export const dataTransferIsFile = (files: DataTransferItemList): boolean => {
    let isFile = false;

    for (const index in files) {
        const item = files[index];
        if (item.kind === 'file') {
            const file: File | null = item.getAsFile();
            if (file) {
                isFile = true;
            }

        }
    }
    return isFile;
}

export const isValidCNPJ = (cnpj: string): boolean => {

    cnpj = removeMask(cnpj);

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digit = cnpj.substring(size);
    let sum = 0;
    let position = size - 7;
    for (let i = size; i >= 1; i--) {
        sum += parseInt(numbers.charAt(size - i)) * position--;
        if (position < 2) {
            position = 9;
        }
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != parseInt(digit.charAt(0))) return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    position = size - 7;
    for (let i = size; i >= 1; i--) {
        sum += parseInt(numbers.charAt(size - i)) * position--;
        if (position < 2)
            position = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != parseInt(digit.charAt(1))) return false;

    return true;

}

export const isValidCPF = (cpf: string): boolean => {
    cpf = removeMask(cpf);
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
        return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
        return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
        return false;
    return true;
}

