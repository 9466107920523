import {
    Container, Image, Content, ContentImage, Title, ContentTitle, ContentDescription, Description, ContentButton,
    ButtonClosed, ContainerButtonClosed, IconClosed, TextClosed
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import notify from "@dropDesk/storage/images/notify.png"
import React, {useEffect} from "react";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useNavigate} from "react-router-dom";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";
import ModalsSubscriptionExpired from "@dropDesk/presentation/pages/subscription_expired_default_user/ui/modals";
import {useBetween} from "use-between";
import {
    useModalsSubscriptionExpired
} from "@dropDesk/presentation/pages/subscription_expired_default_user/ui/modals/visible_modal_subscription";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const SubscriptionExpired = observer(() => {

    const appController = useInjection(AppController);
    const loginController = useInjection(LoginController);
    const colors = appController.theme.colorScheme;
    const navigate = useNavigate();

    useEffect(() => {
        preventUnauthorizedAccess();
    }, []);

    const {
        setVisibleModalLogout
    } = useBetween(useModalsSubscriptionExpired);

    const preventUnauthorizedAccess = (): void => {
        if (appController.user) {
            navigate(appController.user.role === UserRole.userClient ? RoutesEnum.tickets : RoutesEnum.dashboard, {replace: true});
        }
        if (!loginController.getUserByDecodedToken()) {
            navigate(RoutesEnum.login, {replace: true});
        }
    }

    const logout = async () => {
        await loginController.logout(onSuccessLogout);
        navigate(RoutesEnum.login, {replace: true});
        setVisibleModalLogout(false);
    }

    const onSuccessLogout = () => {
        appController.setUser(null);
    }

    return (
        <Container background={colors.onBackground}>
            <Content>
                <ContentTitle>
                    <Title color={colors.text}>Sua assinatura expirou :(</Title>
                </ContentTitle>

                <ContentImage>
                    <Image
                        src={notify}
                        alt={'subscription expired'}
                    />
                </ContentImage>

                <ContentDescription>
                    <Description color={colors.text} size={16}>
                        Sua assinatura expirou. Por favor, entre em contato com um administrador para renovar sua
                        assinatura e continuar desfrutando dos nossos serviços.
                    </Description>
                </ContentDescription>
                <ContentButton>
                    <ButtonNew
                        marginLeft={0}
                        isButtonMain={true}
                        label={'Sair do sistema'}
                        onClick={() => setVisibleModalLogout(true)}
                        nameLeftIcon={ICONS_DROPDESK.logout}
                        sizeLeftIcon={16}
                        disabled={false}
                    />
                </ContentButton>
            </Content>
            <ModalsSubscriptionExpired
                logout={() => logout()}
            />
        </Container>
    );
});
export default SubscriptionExpired;
