import {observer} from "mobx-react";
import React from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";

const FooterFormSectors = observer(
    ({
         onUpdate,
         sector,
         loading,
         handleSubmit,
         backPreviousScreen,
         createdAt,
         disabled
     }: {
         onUpdate: (sector: SectorEntity) => void,
         sector?: SectorEntity,
         loading: boolean,
         disabled: boolean,
         handleSubmit: () => void,
         backPreviousScreen: () => void,
         createdAt?: string,
     }
    ) => {

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string }
        }) => {

            const _sector = sector!.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,

            });
            onUpdate(_sector);
        };


        return (
            <FooterForm
                disabled={loading || disabled}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={handleSubmit}
                onRequestClose={backPreviousScreen}
                useCheckBox={!!sector}
                labelCheckBox={'Listar para clientes'}
                checkedCheckBox={sector?.listableForClients ?? false}
                disabledCheckBox={loading}
                checkBoxName={'listableForClients'}
                onChangeCheckBox={(event) => {
                    handleAllChanges({
                        target:
                            {
                                name: 'listableForClients',
                                value: event.target.checked,
                                checked: event.target.checked,
                                type: 'switch'
                            }
                    });
                }}
                createdAt={createdAt}
            />
        )
    })
export default FooterFormSectors;
