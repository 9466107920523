import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    ButtonAddSector,
    ContentButtonSectors,
    ContentDataLogin,
    ContentListSectors,
    ContentTitle,
    ContentTitleAndAddSector, TextError,
    TextTitle
} from "./styled";
import ListSectorsLinked from "@dropDesk/presentation/components/lists/list_sectors_linked/list_sectors_linked";
import ModalsSectorsLinked from "@dropDesk/presentation/pages/user/ui/form/sectors_linked/modals";
import {useBetween} from "use-between";
import {
    useModalSectorsLinked
} from "@dropDesk/presentation/pages/user/ui/form/sectors_linked/modals/handle_change_visible";
import {SectorUsersEntity} from "@dropDesk/domain/entities/sectors_users/sectors_users.entity";
import {useValidatorInputUsers} from "@dropDesk/presentation/pages/user/ui/form/inputs/validator";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const FormSectors = observer(
    ({
         onChangeSectorsLinked,
         onDeleteSectorsLinked,
         user,
         loading,
         permissionScreenSectors,
         usersSectorsEligible,
         usersSectorsDefault
     }: {
        user?: UserEntity,
        loading: boolean,
        onChangeSectorsLinked: (sector: SectorEntity) => void,
        onDeleteSectorsLinked: (id: string) => void,
        permissionScreenSectors: boolean,
        usersSectorsEligible: SectorUsersEntity[];
        usersSectorsDefault: SectorUsersEntity[];
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const [sectorUnlikedSelected, setSectorUnlikedSelected] = useState<SectorEntity | null>(null);
        const [sectorsDefault, setSectorsDefault] = useState<SectorUsersEntity[]>([]);
        const {
            setVisibleModalSearchSector,
            setVisibleModalUnlinked,
            setVisibleModalCreateSector

        } = useBetween(useModalSectorsLinked);

        const {
            errorMessageSectorLinked
        } = useValidatorInputUsers(user);


        return (
            <ContentDataLogin>
                <ContentTitle>
                    <ContentTitleAndAddSector>
                        <TextTitle color={colors.text}>Setores de atuação</TextTitle>
                        <ContentButtonSectors>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}>
                                <ButtonAddSector onClick={() => {
                                    setVisibleModalSearchSector(true);
                                    setSectorsDefault(usersSectorsDefault);
                                }} color={colors.text} accent={colors.accent}>
                                    <DropDeskIcon icon={ICONS_DROPDESK.add} size={16} style={{
                                        marginRight: 6,
                                    }}/>
                                    adicionar setor
                                </ButtonAddSector>

                            </div>
                            {permissionScreenSectors && <ButtonAddSector
                                onClick={() => {
                                    setVisibleModalCreateSector(true);
                                }}
                                color={colors.text}
                                accent={colors.accent}
                                style={{marginLeft: 25}}
                            >
                                <DropDeskIcon icon={ICONS_DROPDESK.add} size={16} style={{
                                    marginRight: 6,
                                }}/>
                                cadastrar novo setor
                            </ButtonAddSector>}

                        </ContentButtonSectors>
                    </ContentTitleAndAddSector>

                </ContentTitle>
                <ContentListSectors height={160 - appController.heightShowingHeaderInfo}>
                    <>
                        {errorMessageSectorLinked ?
                            <TextError color={colors.errorOnPrimary}> {errorMessageSectorLinked} </TextError> : null}
                        {usersSectorsEligible.map((usersSectors) => {
                            return (
                                <ListSectorsLinked
                                    key={usersSectors.idSector}
                                    onClickUnlinked={(sector) => {
                                        setSectorUnlikedSelected(sector);
                                        setVisibleModalUnlinked(true);
                                    }}
                                    sector={usersSectors.sector!}
                                    letterSecondary={colors.delicateBackground}
                                    letterMain={colors.text}
                                    colorLetterDeleted={colors.textError}
                                />
                            )
                        })}
                    </>
                </ContentListSectors>
                <ModalsSectorsLinked
                    sectorsDefault={sectorsDefault}
                    onDeleteSectorsLinked={onDeleteSectorsLinked}
                    onChangeSectorsLinked={onChangeSectorsLinked}
                    user={user}
                    loading={loading}
                    sectorUnlikedSelected={sectorUnlikedSelected}
                    usersSectorsEligible={usersSectorsEligible}
                />
            </ContentDataLogin>
        )
    })
export default FormSectors;
