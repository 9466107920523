import {observer} from "mobx-react";
import React from "react";
import InputTextAreaWithBorder from "@dropDesk/presentation/components/inputs/input_textarea_with_border";
import {ContentData} from "@dropDesk/presentation/pages/response_standard/modals/modal_crud/styled";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";


const InputsEditTask = observer(
    ({
         task,
         onUpdate,
         loading,
     }: {
        task?: TaskEntity
        onUpdate: (task: TaskEntity) => void,
        loading: boolean,
    }) => {


        const handleAllChanges = (event: {
            target: { name: string; value: any; checked?: any; type: string };
        }) => {
            const _task = task!.copyWith({
                [event.target.name]: (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,
            });
            onUpdate(_task);

        };

        return (
            <ContentData>
                <div style={{display: 'flex', height: 110, alignItems: 'center', justifyContent: 'center'}}>
                    <InputTextAreaWithBorder
                        autoFocus={true}
                        useEmojiPicker={false}
                        required={true}
                        marginTop={10}
                        name='description'
                        size={65}
                        disabled={loading}
                        label={"Descrição da tarefa"}
                        value={task?.description ?? ''}
                        onChange={handleAllChanges}
                    />

                </div>
            </ContentData>
        )
    })
export default InputsEditTask;
