import styled from "styled-components";
import {Badge} from "antd";

type ContainerType = {
    background: string;
};

type iconTab = {
    color: string;
}

type BadgeStyle = {
    colorcount: string;
    bordercolor: string;
    textfontsizesm: number;
}

type TabType = {
    width: number;
}

type ContentTabsType = {
    width: number;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex: 1;
  height: calc(100vh - 61px);
  flex-direction: column;
  overflow: hidden;
  background: ${(props) => props.background};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;
export const ContentTabsAndNotifications = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ContentTabs = styled.div<ContentTabsType>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
`;

export const Tab = styled.span<TabType>`
  font-size: 13px;
  letter-spacing: .2px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: ${(props) => `${props.width}px`};
`;


export const IconTab = styled.i<iconTab>`
  font-size: 12px;
  color: ${props => props.color};
  margin-right: 2px;
`;


export const BadgeStyled = styled(Badge)<BadgeStyle>`
  .ant-badge-multiple-words {
    padding: 0 4px;
  }

  .ant-badge-count {
    color: ${props => props.colorcount};
    box-shadow: 0 0 0 1.5px ${props => props.bordercolor};
  }

  .ant-badge-count-sm {
    line-height: ${props => props.textfontsizesm}px;
  }
`;
