export function setMaskCep(value: string): string {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{5})(\d)/, "$1-$2");
    return value;
}

export function setMaskCpf(value: string): string {

    if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d{2})$/, "$1-$2");
    }
    return value;

}


export const setMaskCnpj = (value: string): string => {
    if (!value.match(/^(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})$/)) {
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{2})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1/$2");
        value = value.replace(/(\d{4})(\d{2})$/, "$1-$2");
    }
    return value;
}

export const setMaskExpirationDateCreditCard = (value: string): string => {
    value = value.replace(/\D/g, "");

    if (value.length > 2) {
        value = value.substring(0, 2) + "/" + value.substring(2);
    }
    return value;
}
