import {observer} from "mobx-react";
import React from "react";
import {useBetween} from "use-between";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import {
    ContainerModalNewTicketsDescriptionStatic, ContentData,
    ContentModalNewTicketsDescriptionStatic
} from "./styled";
import {
    useModalSearchTicketsDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/handle_change_visible";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {
    useValidatorInputTicketsDescriptionStatic
} from "@dropDesk/presentation/pages/ticket_description_static/ui/modals/modal_crud/validator";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";


const ModalCrudTicketsDescriptionStatic = observer(
    ({
         onUpdate,
         ticketsDescriptionStatic,
         handleVisibleModalNewTicketsDescriptionStatic,
         loading,
         set
     }: {
        onUpdate: (ticketDescriptionStatic: TicketsDescriptionStaticEntity) => void,
        ticketsDescriptionStatic?: TicketsDescriptionStaticEntity,
        handleVisibleModalNewTicketsDescriptionStatic: (visible: boolean, id: string) => void,
        loading: boolean,
        set: (onSuccess: (key: string) => void) => Promise<void>
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const isNewRegister = 'new';
        const {t} = useTranslation();
        const {
            errorMessageDescription,
            validate
        } = useValidatorInputTicketsDescriptionStatic(ticketsDescriptionStatic, loading);
        const {
            visibleModalNewTicketsDescriptionStatic,
        } = useBetween(useModalSearchTicketsDescriptionStatic);

        const handleAllChanges = (event: {
            target: { name: string; value: any; checked: any; type: string };
        }) => {
            onUpdate(
                ticketsDescriptionStatic!.copyWith({
                    [event.target.name]: (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value,
                })
            );
        };

        const handleSubmit = async () => {
            if (validate()) {
                await set(onSuccessSave);
            }
        }

        const onSuccessSave = (key: string) => {
            toastMessage.success(t(key));
            handleVisibleModalNewTicketsDescriptionStatic(false, isNewRegister);
        }

        return (
            <>

                <ModalMain
                    open={visibleModalNewTicketsDescriptionStatic}
                    onRequestClose={() => handleVisibleModalNewTicketsDescriptionStatic(false, isNewRegister)}
                    width={670}
                    renderComponent={
                        <ContainerModalNewTicketsDescriptionStatic background={colors.onBackground}>
                            <HeaderModal
                                disabled={loading}
                                letter={ticketsDescriptionStatic?.isUnsaved() ? 'Cadastro de problema pré definido' : "Editando problema pré definido"}
                                nameIcon={ICONS_DROPDESK.close}
                                onClick={() => handleVisibleModalNewTicketsDescriptionStatic(false, isNewRegister)}
                            />
                            <ContentModalNewTicketsDescriptionStatic>
                                <ContentData autoComplete='off'>
                                    <InputWithBorder
                                        useEmojiPicker={true}
                                        required={true}
                                        name='description'
                                        autoFocus={true}
                                        size={35}
                                        disabled={loading}
                                        placeHolder={loading && ticketsDescriptionStatic?.isUnsaved() ? "Carregando..." : "Descrição do Problema"}
                                        messageError={errorMessageDescription}
                                        label={"Descrição do Problema"}
                                        value={ticketsDescriptionStatic?.description ?? ''}
                                        onChange={handleAllChanges}
                                    />
                                </ContentData>
                            </ContentModalNewTicketsDescriptionStatic>
                            <FooterModal
                                disabled={loading || !!errorMessageDescription}
                                loading={loading}
                                type={"submit"}
                                letter={'Salvar'}
                                onSubmit={handleSubmit}
                                onRequestClose={() => handleVisibleModalNewTicketsDescriptionStatic(false, isNewRegister)}
                                useCheckBox={!!ticketsDescriptionStatic}
                                labelCheckBox={'Listar para clientes'}
                                checkedCheckBox={ticketsDescriptionStatic?.listableForClients ?? false}
                                disabledCheckBox={loading}
                                onChangeCheckBox={(event) => {

                                    handleAllChanges(
                                        {
                                            target:
                                                {
                                                    name: 'listableForClients',
                                                    value: event.target.checked,
                                                    checked: event.target.checked,
                                                    type: 'switch'
                                                }
                                        })
                                }}
                                createdAt={ticketsDescriptionStatic?.createdAt ? `Criado em ${displayDateToLocale(ticketsDescriptionStatic.createdAt)}` : undefined}
                            />
                        </ContainerModalNewTicketsDescriptionStatic>
                    }
                />
            </>
        )
    })
export default ModalCrudTicketsDescriptionStatic;
