import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";

@injectable()
export class ListUserUseCase {
    private _repository: UserRepository;

    constructor(@inject(UserRepository) repository: UserRepository) {
        this._repository = repository;
    }

    public call(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        role: UserRole,
        idClient?: string,
        idSector?: string,
        listOnlyContactWithWhatsApp?: boolean
    ): Promise<ListPaginationEntity<UserEntity>> {
        return this._repository.list(page, searchParam, limit, listOnlyDeleted, role, idClient, idSector, listOnlyContactWithWhatsApp);
    }
}

