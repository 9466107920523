import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ReportTicketRepository} from "@dropDesk/domain/repositories/reports/report_ticket/report_ticket.repository";

@injectable()
export class ExportPdfReportTicketUseCase {
  private _repository: ReportTicketRepository;

  constructor(@inject(ReportTicketRepository) repository: ReportTicketRepository) {
    this._repository = repository;
  }

  public call(
    period: { startDate: string, endDate: string },
    idUser: string | null,
    idSector: string | null,
    idClient: string | null
  ): Promise<void> {
    return this._repository.exportPdf(period, idUser, idSector, idClient);
  }
}
