export class AuthHashEntity {
    email!: string;
    firebaseId!: string;
    role?: string;
    idCompany?: string;
    id?: string;
    timestamp?: number;

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = [];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    constructor({
                    email,
                    role,
                    idCompany,
                    firebaseId,
                    id,
                    timestamp
                }: {
        email: string;
        firebaseId: string;
        role?: string;
        idCompany?: string;
        id?: string;
        timestamp?: number;
    }) {
        Object.assign(this, {
            email,
            role,
            idCompany,
            firebaseId,
            id,
            timestamp
        });
    }

    copyWith({
                 email,
                 role,
                 idCompany,
                 firebaseId,
                 id,
                 timestamp
             }: {
        email?: string;
        role?: string;
        idCompany?: string;
        firebaseId?: string;
        id?: string;
        timestamp?: number;
    }): AuthHashEntity {
        return new AuthHashEntity({
            firebaseId: firebaseId ?? this.firebaseId,
            email: email ?? this.email,
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            role: role ?? this.role,
            timestamp: timestamp ?? this.timestamp
        })
    }
}
